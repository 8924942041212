import React, { Component } from 'react';
import {
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Card,
    CardBody,
    CardTitle,
} from 'reactstrap';
import axios from 'axios';
import { END_POINT } from '../../../constants/defaultValues';
import { Colxx } from '../../../components/common/CustomBootstrap';
import {
    NotificatioErrController,
    NotificatioSucController,
} from '../../../helpers/Utils';
import { Redirect } from 'react-router-dom';
import ThumbListViewMenu from './ThumbListViewMenu';

class Upgrade extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isLoading: false,
            modal: false,
            valueModal: {
                id: 0,
                username: undefined,
                fullname: undefined,
                companyName: undefined,
                companyUUID: undefined,
            },
        };

        this.dataRender = this.dataRender.bind(this);
        this.handleActionSubmit = this.handleActionSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this.dataRender();
    }

    dataRender() {
        let tokenStr = localStorage.getItem('access_token');
        return axios
            .get(`${END_POINT + '/user-license/group'}`, {
                headers: {
                    Authorization: `Bearer ${tokenStr}`,
                },
            })
            .then((res) => res.data)
            .then((data) => {
                this.setState({
                    data: data.data,
                    isLoading: true,
                });
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    }

    handleActionSubmit() {
        const { companyUUID } = this.state.valueModal;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/user-license/upgrade'}`,
                {
                    companyUUID: companyUUID,
                },
                {
                    headers: {
                        Authorization: `Bearer ${tokenStr}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                NotificatioSucController(data.responseText);

                this.dataRender();
                this.toggle();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    }

    handleToggleModal = (e, f) => {
        this.setState({
            valueModal: {
                ...this.state.valueModal,
                id: f.user.id,
                username: f.user.username,
                fullname: f.user.fullname,
                companyName: f.user.companyName,
                companyUUID: f.user.companyUUID,
            },
        });

        this.toggle();
    };

    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    render() {
        let { isLoading, data, modal, valueModal } = this.state;

        const store = window.location.host.split('.')[0];
        if (store !== 'upgrade') {
            return <Redirect to='/app/dashboards/default' />;
        }

        return !isLoading ? (
            <div className='loading' />
        ) : (
            <Row>
                <Modal isOpen={modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>
                        {'Thông tin'}
                    </ModalHeader>
                    <ModalBody>
                        <h3>Nâng cấp tài khoản</h3>
                        <h6>Mã cửa hàng: {valueModal.companyUUID} </h6>
                        <h6>Tên cửa hàng: {valueModal.companyName} </h6>
                        <div className='text-center'>
                            <Button
                                color='primary'
                                onClick={this.handleActionSubmit}
                            >
                                {'Nâng cấp ngay'}
                            </Button>{' '}
                        </div>
                    </ModalBody>
                </Modal>
                <Colxx xxs='12' lg='12' md='12' className='d-block'>
                    <div className='mb-3 upgrade-page-heading__row'>
                        <div className='d-flex flex-grow-1 min-width-zero'>
                            <div className='align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center card-body'>
                                {/* <p className='list-item-heading mb-0 truncate w-40 w-xs-100' /> */}
                                <p className='mb-0 text-muted w-20 w-xs-100 text-center'>
                                    <span>Họ và tên</span>
                                </p>
                                <p className='mb-0 text-muted w-20 w-xs-100 text-center'>
                                    <span>Tên cửa hàng</span>
                                </p>
                                <p className='mb-0 text-muted w-15 w-xs-100 text-center'>
                                    <span>Tên miền</span>
                                </p>
                                <p className='mb-0 text-muted w-20 w-xs-100 text-center'>
                                    <span>Mã CompanyUUID</span>
                                </p>
                                <p className='mb-0 text-muted w-20 w-xs-100 text-center'>
                                    <span>Ngày hết hạn</span>
                                </p>
                                <p className='mb-0 text-muted w-15 w-xs-100 text-center mr-card-body'>
                                    <span>Chờ nâng cấp</span>
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* CHỜ NÂNG CẤP */}
                    <Card className='mb-4'>
                        <CardBody>
                            <CardTitle>{'YÊU CẦU NÂNG CẤP'}</CardTitle>
                            {data.dataPending.map((user, idx) => {
                                return (
                                    <ThumbListViewMenu
                                        key={idx}
                                        item={user}
                                        handleToggleModal={
                                            this.handleToggleModal
                                        }
                                    />
                                );
                            })}
                        </CardBody>
                    </Card>

                    {/* DÙNG THỬ */}
                    <Card className='mb-4'>
                        <CardBody>
                            <CardTitle>{'DÙNG THỬ'}</CardTitle>
                            {data.dataTrial.map((user, idx) => {
                                return (
                                    <ThumbListViewMenu key={idx} item={user} />
                                );
                            })}
                        </CardBody>
                    </Card>

                    {/* MIỄN PHÍ */}
                    <Card className='mb-4'>
                        <CardBody>
                            <CardTitle>{'MIỄN PHÍ'}</CardTitle>
                            {data.dataTrialExpired.map((user, idx) => {
                                return (
                                    <ThumbListViewMenu key={idx} item={user} />
                                );
                            })}
                        </CardBody>
                    </Card>

                    {/* ĐÃ NÂNG CẤP */}
                    <Card className='mb-4'>
                        <CardBody>
                            <CardTitle>{'ĐÃ NÂNG CẤP'}</CardTitle>
                            {data.dataUpgraded.map((user, idx) => {
                                return (
                                    <ThumbListViewMenu key={idx} item={user} />
                                );
                            })}
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>
        );
    }
}

export default Upgrade;
