import React from 'react'
import SingleLightbox from "../../../components/pages/SingleLightbox";
import { END_POINT } from '../../../constants/defaultValues'
import RawHTML from '../../../helpers/RawContent'

const getSrcFromPath = path => {
  return `${END_POINT}/public/message/${path}`
}

const getExtension = path => {
  let spli = path.split('.');
  return spli[spli.length - 1];
}

const getHrefFromContent = content => {
  return (content || "").replace(
    /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
    function (match, space, url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return space + '<a target="_blank" href="' + hyperlink + '">' + url + '</a>';
    }
  );
}

export const PreviewContent = ({content}) => {
  if (content.indexOf("#file|") >= 0) {
    let src = content.split("|")[2]; // code | real name | hash path

    let extension = getExtension(src);

    if (extension === 'rar' || extension === 'zip' || extension === 'tar') {
      return `Tệp tin`;
    }
    return `Hình ảnh`
  }

  return content.length > 30 ? content.slice(0, 30) + "..." : content;
}

export const ParseContent = ({ content }) => {
  if (content.indexOf("#file|") >= 0) {
    let name = content.split("|")[1]; // code | real name | hash path
    let src = content.split("|")[2]; // code | real name | hash path

    let extension = getExtension(src);

    if (extension === 'rar' || extension === 'zip' || extension === 'tar') {
      return (
        <span className="inline-flex">
          <i className="iconsminds-file-zip" />
          <a href={getSrcFromPath(src)}>{name}</a>
        </span>
      )
    }
    return (
      <SingleLightbox
        thumb={getSrcFromPath(src)}
        large={getSrcFromPath(src)}
        className="responsive border-0 card-img-top mxh-700" />
    )
  }

  if (content.indexOf("http://") >= 0 || content.indexOf("https://") >= 0) {
    let link = getHrefFromContent(content);

    return (
      <RawHTML>{link}</RawHTML>
    )
  }

  return content;
}