export const SetSelectPromotionDiscount = () => {
    return [
        {
            label: 'Tổng giá trị đơn hàng',
            value: 'total-order-value',
        },
        {
            label: 'Từng sản phẩm',
            value: 'one-product',
        },
        {
            label: 'Từng loại sản phẩm',
            value: 'one-type-product',
        },
        {
            label: 'CK theo số lượng sản phẩm',
            value: 'quantity-product',
        },
        {
            label: 'CK theo số lượng loại sản phẩm',
            value: 'quantity-type-product',
        },
        {
            label: 'CK theo mốc tích điểm của khách hàng',
            value: 'customer-accrual-points',
        },
    ];
};

export const GetSelectPromotionDiscount = (time) => {
    if (time === 'total-order-value') {
        return {
            label: 'Tổng giá trị đơn hàng',
            value: 'total-order-value',
        };
    }
    if (time === 'one-product') {
        return {
            label: 'Từng sản phẩm',
            value: 'one-product',
        };
    }
    if (time === 'one-type-product') {
        return {
            label: 'Từng loại sản phẩm',
            value: 'one-type-product',
        };
    }
    if (time === 'quantity-product') {
        return {
            label: 'CK theo số lượng sản phẩm',
            value: 'quantity-product',
        };
    }
    if (time === 'quantity-type-product') {
        return {
            label: 'CK theo số lượng loại sản phẩm',
            value: 'quantity-type-product',
        };
    }
    if (time === 'customer-accrual-points') {
        return {
            label: 'CK theo mốc tích điểm của khách hàng',
            value: 'customer-accrual-points',
        };
    }

    return null;
};

export const SetSelectPromotionGift = () => {
    return [
        {
            label: 'Mua sản phẩm tặng sản phẩm',
            value: 'product-get-product',
        },
        {
            label: 'Tặng quà theo giá trị đơn hàng',
            value: 'total-order-value-get-product',
        },
        {
            label: 'Tặng quà theo mốc điểm của khách hàng',
            value: 'customer-accrual-points-get-product',
        },
    ];
};

export const GetSelectPromotionGift = (time) => {
    if (time === 'product-get-product') {
        return {
            label: 'Mua sản phẩm tặng sản phẩm',
            value: 'product-get-product',
        };
    }
    if (time === 'total-order-value-get-product') {
        return {
            label: 'Tặng quà theo giá trị đơn hàng',
            value: 'total-order-value-get-product',
        };
    }
    if (time === 'customer-accrual-points-get-product') {
        return {
            label: 'Tặng quà theo mốc điểm của khách hàng',
            value: 'customer-accrual-points-get-product',
        };
    }

    return null;
};
