import React, { Component } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import Switch from 'rc-switch';
import { END_POINT } from '../../../constants/defaultValues';
import { NotificatioErrController } from '../../../helpers/Utils';
import axios from 'axios';

class Setting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            // setting: null,
            switchChecked: {
                switchCheckedBill: false,
                switchCheckedBillQr: false,
                switchCheckedBillBar: false,
                switchCheckedTemBar: false,
                switchCheckedTemCashier: false,
                switchCheckedOrderOne: false,
                switchCheckedOrderTwo: false,
                switchCheckedOrderThree: false,
                switchCheckedOrderFour: false,
            },
            // printers: [],
            // selectedPrinter: '',
        };
    }

    componentDidMount() {
        try {
            this.settingRender();
        } catch (error) {
            console.error('Error loading settings:', error);
        }
        // this.getPrinters();
    }

    // getPrinters = async () => {
    //     try {
    //         const printerList = await navigator.print?.getPrinters();
    //         console.log('Printer list:', printerList);
    //         this.setState({
    //             printers: printerList,
    //         });
    //     } catch (error) {
    //         console.error('Error getting printers:', error);
    //     }
    // };

    // handlePrinterSelect = (event) => {
    //     this.setState({
    //         setSelectedPrinter: event.target.value,
    //     });
    // };

    settingRender() {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/setting'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => {
                return res.data;
            })
            .then(async (data) => {
                // console.log('data_setting: ', data);
                const setting = data.data;
                if (data) {
                    await this.setState((prevState) => ({
                        ...prevState,
                        isLoading: true,
                        switchChecked: {
                            ...prevState,
                            switchCheckedBill: setting.bill_cashier,
                            switchCheckedBillQr: setting.bill_qr,
                            switchCheckedBillBar: setting.bill_bar,
                            switchCheckedTemBar: setting.tem_bar,
                            switchCheckedTemCashier: setting.tem_cashier,
                            switchCheckedOrderOne: setting.order_processing_one,
                            switchCheckedOrderTwo: setting.order_processing_two,
                            switchCheckedOrderThree:
                                setting.order_processing_three,
                            switchCheckedOrderFour:
                                setting.order_processing_four,
                        },
                    }));
                } else {
                    NotificatioErrController('Không có dữ liệu');
                }
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    }

    onChange = async (name) => {
        await this.setState((prevState) => {
            const updatedSwitchChecked = {
                ...prevState.switchChecked,
                [name]: !prevState.switchChecked[name],
            };

            // Turn off other switches if the switch is one of the 3 specific switches
            if (
                name === 'switchCheckedOrderOne' ||
                name === 'switchCheckedOrderTwo' ||
                name === 'switchCheckedOrderThree' ||
                name === 'switchCheckedOrderFour'
            ) {
                [
                    'switchCheckedOrderOne',
                    'switchCheckedOrderTwo',
                    'switchCheckedOrderThree',
                    'switchCheckedOrderFour',
                ].forEach((switchName) => {
                    if (switchName !== name) {
                        updatedSwitchChecked[switchName] = false;
                    }
                });
            }

            return { switchChecked: updatedSwitchChecked };
        });

        const {
            switchChecked: {
                switchCheckedBill,
                switchCheckedBillQr,
                switchCheckedBillBar,
                switchCheckedTemBar,
                switchCheckedTemCashier,
                switchCheckedOrderOne,
                switchCheckedOrderTwo,
                switchCheckedOrderThree,
                switchCheckedOrderFour,
            },
        } = this.state;

        let tokenStr = localStorage.getItem('access_token');
        await axios
            .put(
                `${END_POINT + '/setting'}`,
                {
                    bill_cashier: switchCheckedBill,
                    bill_qr: switchCheckedBillQr,
                    bill_bar: switchCheckedBillBar,
                    tem_bar: switchCheckedTemBar,
                    tem_cashier: switchCheckedTemCashier,
                    order_processing_one: switchCheckedOrderOne,
                    order_processing_two: switchCheckedOrderTwo,
                    order_processing_three: switchCheckedOrderThree,
                    order_processing_four: switchCheckedOrderFour,
                },
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    };

    render() {
        // const { printers, selectedPrinter } = this.state;
        // console.log('printers: ', printers);
        // console.log('selectedPrinter: ', selectedPrinter);
        return !this.state.isLoading ? (
            <div className='loading' />
        ) : (
            <React.Fragment>
                <Row>
                    <Colxx xxs='12'>
                        <Breadcrumb
                            heading='menu.setting'
                            match={this.props.match}
                        />
                        <Separator className='mb-5' />
                    </Colxx>
                </Row>
                <Card>
                    <CardBody>
                        <div className='mb-4'>
                            <span className='text-muted'>
                                1. Cài đặt in hoá đơn khách hàng
                            </span>
                            <div className='d-flex align-items-center pl-4 pb-3 pt-3'>
                                <Switch
                                    id='Tooltip-Switch'
                                    className='custom-switch custom-switch-primary custom-switch-small mr-2'
                                    checked={
                                        this.state.switchChecked
                                            .switchCheckedBill
                                    }
                                    onChange={() =>
                                        this.onChange('switchCheckedBill')
                                    }
                                />
                                <span className='mr-2'>
                                    In hoá đơn khi gọi món tại quầy
                                </span>
                            </div>
                        </div>
                        <div className='mb-4'>
                            <span className='text-muted mr-2'>
                                2. Cài đặt in hoá đơn bếp
                            </span>
                            <div className='d-flex align-items-center pl-4 pb-3 pt-3'>
                                <Switch
                                    id='Tooltip-Switch'
                                    className='custom-switch custom-switch-primary custom-switch-small mr-2'
                                    checked={
                                        this.state.switchChecked
                                            .switchCheckedBillQr
                                    }
                                    onChange={() =>
                                        this.onChange('switchCheckedBillQr')
                                    }
                                />
                                <span className='mr-2'>
                                    In hoá đơn bếp khi thu ngân bấm nút "Chuyển
                                    chế biến"
                                </span>
                            </div>
                            {/* <div className='d-flex align-items-center pl-4'>
                                <Switch
                                    id='Tooltip-Switch'
                                    className='custom-switch custom-switch-primary custom-switch-small mr-2'
                                    checked={
                                        this.state.switchChecked
                                            .switchCheckedBillBar
                                    }
                                    onChange={() =>
                                        this.onChange('switchCheckedBillBar')
                                    }
                                />
                                <span className='mr-2'>
                                    In hoá đơn chế biến sau khi đặt món từ quầy
                                    thu ngân
                                </span>
                            </div> */}
                        </div>
                        {/* <div className='mb-4'>
                            <span className='text-muted mr-2'>
                                3. Cài đặt in tem
                            </span>
                            <div className='d-flex align-items-center pl-4 pb-3 pt-3'>
                                <Switch
                                    id='Tooltip-Switch'
                                    className='custom-switch custom-switch-primary custom-switch-small mr-2'
                                    checked={
                                        this.state.switchChecked
                                            .switchCheckedTemBar
                                    }
                                    onChange={() =>
                                        this.onChange('switchCheckedTemBar')
                                    }
                                />
                                <span className='mr-2'>
                                    In tem khi chế biến xong
                                </span>
                            </div>
                        </div> */}
                        <div className='mb-4'>
                            <span className='text-muted'>
                                3. Quy trình chuyển bước khi gọi món tại quầy
                            </span>
                            <div className='d-flex align-items-center pl-4 pb-3 pt-3'>
                                <Switch
                                    id='Tooltip-Switch'
                                    className='custom-switch custom-switch-primary custom-switch-small mr-2'
                                    checked={
                                        this.state.switchChecked
                                            .switchCheckedOrderOne
                                    }
                                    onChange={() =>
                                        this.onChange('switchCheckedOrderOne')
                                    }
                                />
                                <span className='mr-2'>
                                    Order và Hoàn thành ngay
                                </span>
                            </div>
                            <div className='d-flex align-items-center pl-4 pb-3'>
                                <Switch
                                    id='Tooltip-Switch'
                                    className='custom-switch custom-switch-primary custom-switch-small mr-2'
                                    checked={
                                        this.state.switchChecked
                                            .switchCheckedOrderTwo
                                    }
                                    onChange={() =>
                                        this.onChange('switchCheckedOrderTwo')
                                    }
                                />
                                <span className='mr-2'>
                                    Order{' '}
                                    <i className='iconsminds-arrow-forward' />{' '}
                                    Chuyển chế biến{' '}
                                    <i className='iconsminds-arrow-forward' />{' '}
                                    Hoàn thành
                                </span>
                            </div>
                            <div className='d-flex align-items-center pl-4 pb-3'>
                                <Switch
                                    id='Tooltip-Switch'
                                    className='custom-switch custom-switch-primary custom-switch-small mr-2'
                                    checked={
                                        this.state.switchChecked
                                            .switchCheckedOrderThree
                                    }
                                    onChange={() =>
                                        this.onChange('switchCheckedOrderThree')
                                    }
                                />
                                <span className='mr-2'>
                                    Order{' '}
                                    <i className='iconsminds-arrow-forward' />{' '}
                                    Chuyển thanh toán{' '}
                                    <i className='iconsminds-arrow-forward' />{' '}
                                    Hoàn thành
                                </span>
                            </div>
                            <div className='d-flex align-items-center pl-4 pb-3'>
                                <Switch
                                    id='Tooltip-Switch'
                                    className='custom-switch custom-switch-primary custom-switch-small mr-2'
                                    checked={
                                        this.state.switchChecked
                                            .switchCheckedOrderFour
                                    }
                                    onChange={() =>
                                        this.onChange('switchCheckedOrderFour')
                                    }
                                />
                                <span className='mr-2'>
                                    Order{' '}
                                    <i className='iconsminds-arrow-forward' />{' '}
                                    Chuyển chế biến{' '}
                                    <i className='iconsminds-arrow-forward' />{' '}
                                    Chuyển thanh toán{' '}
                                    <i className='iconsminds-arrow-forward' />{' '}
                                    Hoàn thành
                                </span>
                            </div>
                        </div>
                        <div className='mb-4'>
                            <span className='text-muted'>
                                3. Quy trình chuyển bước khi gọi món bằng QrCode
                            </span>
                            <div className='d-flex align-items-center pl-4 py-3'>
                                <Switch
                                    className='custom-switch custom-switch-primary custom-switch-small mr-2'
                                    checked={true}
                                />
                                <span className='mr-2'>
                                    Order{' '}
                                    <i className='iconsminds-arrow-forward' />{' '}
                                    Chuyển chế biến{' '}
                                    <i className='iconsminds-arrow-forward' />{' '}
                                    Chuyển thanh toán{' '}
                                    <i className='iconsminds-arrow-forward' />{' '}
                                    Hoàn thành
                                </span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default Setting;
