import React from 'react';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardTitle,
    Label,
    Row,
} from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';

const ContentDisplay = (props) => {
    // console.log(props);
    return (
        <Card className='position-sticky-custom mb-4'>
            <CardBody>
                <CardTitle>
                    <IntlMessages id='make-product.thumb-list' />
                </CardTitle>
                <Row className='mt-3 '>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='make-product.product-name' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.product.name}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='make-product.number-product-used' />
                        </p>
                        <p className='mb-3 text-lowercase'>
                            {props.values.make.length}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='make-product.price' />
                        </p>
                        <p className='mb-3 text-capitalize'>20.000đ</p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='make-product.cost' />
                        </p>
                        <p className='mb-3 text-lowercase'>5.000đ</p>
                    </Colxx>
                </Row>
                <Row className='mt-3 '>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='make-product.percent-cost' />
                        </p>
                        <p className='mb-3 text-capitalize'>25%</p>
                    </Colxx>
                </Row>
                <Row>
                    {props.values.make.length ? (
                        <ul className={'orders-pending'}>
                            {props.values.make.map((stock) => (
                                <li key={stock.id} className='pr-2'>
                                    <Button color='primary'>
                                        {stock.name}{' '}
                                        <Badge color='light' className='ml-2'>
                                            {stock.count +
                                                ' (' +
                                                stock.unit +
                                                ')'}
                                        </Badge>{' '}
                                    </Button>
                                </li>
                            ))}
                        </ul>
                    ) : null}
                </Row>
            </CardBody>
        </Card>
    );
};

export default ContentDisplay;
