import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import axios from 'axios';
import { END_POINT, END_POINT_SOCKET } from '../../../constants/defaultValues';
import {
    SetItemsSelected,
    NotificatioErrController,
    NotificatioSucController,
    // CheckConfirmPassword,
} from '../../../helpers/Utils';
import io from 'socket.io-client';
import { ContentForm } from '../../../containers/pages/role';
import {
    Add,
    Update,
    PageHeading,
    Pagination,
} from '../../../containers/pages/general';
// import ListItemLeftForm from '../../../containers/pages/ListItemLeftForm';
import LogsHelper from '../../../helpers/Logs';
// import PasswordBox from '../../../components/PasswordBox'
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classnames from 'classnames';
import ContentDisplay from '../../../containers/pages/role/ContentDisplay';
import ListItemFormMobile from '../../../containers/pages/role/ListItemFormMobile';
import { isD, isU } from '../../../helpers/Roles';
import AddRole from '../../../containers/pages/role/AddRole';
import UpdateRole from '../../../containers/pages/role/UpdateRole';
import ListItemLeftForm from '../../../containers/pages/role/ListItemLeftForm';

const DEFAULT_FORM = {
    id: '',
    name: '',
    value: '',
    note: '',
    time: '',
    settings: [],
};

const ROLE_ALIAS = 'role';

class ContentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: {
                pageSizes: [12, 24],
                selectedPageSize: 12,
                currentPage: 1,
                totalPage: 1,
                totalItem: 0,
                search: '',
            },
            selectedItems: [],
            data: [],
            is_loading_data: true,
            show: {
                add: false,
                update: false,
                view: false,
                viewonly: true,
            },
            accordion: [],
            form: DEFAULT_FORM,
        };

        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        this.dataListRender();
    }

    /** Axios func helper */
    dataListRender = () => {
        const { selectedPageSize, currentPage, search } = this.state.page;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(
                `${
                    END_POINT + '/user-role/json'
                }?pageSize=${selectedPageSize}&currentPage=${currentPage}&search=${search}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((data) => {
                let anyAccordion = [];
                let numeroPerguntas = data.data.length;
                for (var i = 0; i < numeroPerguntas; i++) {
                    // if (!i) {
                    //     anyAccordion.push(true);
                    // }
                    anyAccordion.push(false);
                }
                this.setState(
                    (prev) => ({
                        ...prev,
                        data: data.data,
                        is_loading_data: false,
                        page: {
                            ...prev.page,
                            totalPage: data.totalPage,
                            totalItem: data.totalItem,
                        },
                        accordion: anyAccordion,
                    }),
                    () => {
                        if (this.state.form.id) {
                            const { id, name, settings } = this.state.form;
                            this.setState((prev) => ({
                                ...prev,
                                form: {
                                    ...prev.form,
                                    id,
                                    name,
                                    settings,
                                },
                            }));
                        } else {
                            this.setState((prev) => ({
                                ...prev,
                                selectedItems: [],
                                show: {
                                    ...prev.show,
                                    update: false,
                                },
                                form: DEFAULT_FORM,
                            }));
                        }
                    }
                );
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerDelete = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .delete(`${END_POINT + '/user-role'}`, {
                data: {
                    arrayId: this.state.selectedItems,
                },
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['user-role-delete'],
                });

                this.dataListRender();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerUpdate = (e) => {
        const { id, name, settings } = this.state.form;
        e.preventDefault();

        if (!name || !id) {
            NotificatioErrController('Dữ liệu không hợp lệ');
            return;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .put(
                `${END_POINT + '/user-role'}`,
                {
                    id,
                    name,
                    role_aliases: settings,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['user-role-update'],
                });

                this.socket.emit('update-user-role', {
                    user_id: localStorage.getItem('user_id'),
                });

                this.dataListRender();
                this.toggle('update');
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerAdd = (e) => {
        const { name, settings } = this.state.form;
        e.preventDefault();

        if (!name) {
            NotificatioErrController('Tên phân quyền cần được thêm vào');
            return;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/user-role'}`,
                {
                    name,
                    role_aliases: settings,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['user-role-create'],
                });

                this.dataListRender();
                // this.toggle('add');
                this.setState((prev) => ({
                    ...prev,
                    form: {
                        ...prev.form,
                        form: DEFAULT_FORM,
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };
    /** Axios func helper */

    /** State func helper */
    toggle = (name) => {
        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                update: name === 'add' ? false : prev.show.update,
                view: name === 'add' ? false : prev.show.view,
                [name]: !prev.show[name],
            },
            selectedItems: name === 'add' ? [] : prev.selectedItems,
            form: name === 'add' ? DEFAULT_FORM : prev.form,
        }));
    };

    onChangeForm = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [name]: value,
                },
            }));
        } else {
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [_name]: e.value,
                },
            }));
        }
    };

    onChangePermission = ({ alias, permission, permissions }) => {
        const { settings } = this.state.form;
        let new_settings = settings;

        if (this.isCheckedPermission({ alias, permission })) {
            // Tick bỏ quyền F nếu bỏ quyền C, R, U, D
            if (
                permission === 'C' ||
                permission === 'R' ||
                permission === 'U' ||
                permission === 'D'
            ) {
                new_settings = new_settings.filter(
                    (setting) =>
                        setting.role_alias !== alias ||
                        (setting.role_alias === alias &&
                            setting.permission !== 'F')
                );
            }

            // Xóa quyền
            new_settings = new_settings.filter(
                (setting) =>
                    setting.role_alias !== alias ||
                    (setting.role_alias === alias &&
                        setting.permission !== permission)
            );

            // Nếu là R thì xóa C, U, D
            if (permission === 'R' || permission === 'F') {
                new_settings = new_settings.filter(
                    (setting) =>
                        setting.role_alias !== alias ||
                        (setting.role_alias === alias &&
                            setting.permission !== 'C')
                );
                new_settings = new_settings.filter(
                    (setting) =>
                        setting.role_alias !== alias ||
                        (setting.role_alias === alias &&
                            setting.permission !== 'U')
                );
                new_settings = new_settings.filter(
                    (setting) =>
                        setting.role_alias !== alias ||
                        (setting.role_alias === alias &&
                            setting.permission !== 'D')
                );

                // Nếu là quyền F thì bỏ hết các quyền R
                if (permission === 'F') {
                    new_settings = new_settings.filter(
                        (setting) =>
                            setting.role_alias !== alias ||
                            (setting.role_alias === alias &&
                                setting.permission !== 'R')
                    );
                }
            }
        } else {
            // Nếu thêm quyền C, U, D thì phải có quyền R
            if (
                permission === 'C' ||
                permission === 'U' ||
                permission === 'D'
            ) {
                const find_role_R = settings.find(
                    (setting) =>
                        setting.role_alias === alias &&
                        setting.permission === 'R'
                );
                if (!find_role_R) return false;
            }

            // Nếu thêm quyền F thì tick hết C, R, U, D
            if (permission === 'F' && permissions != null) {
                for (let i = 0; i < permissions.length; i++) {
                    let permission = permissions[i];

                    const find_role_R = settings.find(
                        (setting) =>
                            setting.role_alias === alias &&
                            setting.permission === permission
                    );
                    if (find_role_R) continue;

                    let role = {
                        role_alias: alias,
                        permission,
                    };
                    new_settings.push(role);
                }
            } else {
                let role = {
                    role_alias: alias,
                    permission,
                };
                new_settings.push(role);
            }
        }

        this.setState((prev) => ({
            ...prev,
            form: {
                ...prev.form,
                settings: new_settings,
            },
        }));
    };

    isCheckedPermission = ({ alias, permission }) => {
        const { id, settings } = this.state.form;

        const result = settings.find(
            (setting) =>
                setting.role_alias === alias &&
                setting.permission === permission
        );
        if (result === undefined) return false;
        return result;
    };

    onSubmitForm = (type) => {
        if (type === 'update') {
            this.onHandlerUpdate();
        } else if (type === 'add') {
            this.onHandlerAdd();
        }
    };

    onChangePage = (page) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    currentPage: page,
                },
            }),
            () => this.dataListRender()
        );
    };

    onSearchKey = (e) => {
        if (!e) return false;

        if (e.target) {
            let {
                target: { value },
            } = e;
            let v = value;
            if (value === undefined) {
                v = e.target.firstElementChild.value; // parent to child

            //     value = v.toLowerCase();
            //     this.setState(
            //         (prev) => ({
            //             ...prev,
            //             page: {
            //                 ...prev.page,
            //                 search: value,
            //             },
            //         }),
            //         () => this.dataListRender()
            //     );
            // } else {
            //     value = value.toLowerCase();
            //     this.setState(
            //         (prev) => ({
            //             ...prev,
            //             page: {
            //                 ...prev.page,
            //                 search: value,
            //             },
            //         }),
            //         () => this.dataListRender()
            //     );
            }
        }

        if (e.key === 'Enter') {
            let value = e.target.value.toLowerCase();
            this.setState(
                (prev) => ({
                    ...prev,
                    page: {
                        ...prev.page,
                        search: value,
                    },
                }),
                () => this.dataListRender()
            );
        }
    };

    onChangePageSize = (size) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    selectedPageSize: size,
                    currentPage: 1,
                },
            }),
            () => this.dataListRender()
        );
    };

    onClickedItem = (item, callback, args) => {
        if (this.state.form.id !== item.id) {
            const { id, name, settings } = item;

            this.setState(
                (prev) => ({
                    ...prev,
                    show: {
                        ...prev.show,
                        update: false,
                        view: true,
                        viewonly: false,
                    },
                    form: {
                        ...prev.form,
                        id,
                        name,
                        settings,
                    },
                    selectedItems: [item.id],
                }),
                () => {
                    callback(args[0], args[1], args[2]);
                }
            );
        }
    };

    onClickedUpdate = (item) => {
        const { id, name, settings } = item;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                update: true,
            },
            form: {
                ...prev.form,
                id,
                name,
                settings,
            },
        }));
    };

    onClickedDelete = (item) => {
        let selectedSet = SetItemsSelected(
            item.id,
            this.state.selectedItems,
            'require'
        );
        if (window.confirm('Bạn chắc chắn muốn xoá phân quyền này?')) {
            this.setState(
                (prev) => ({
                    ...prev,
                    selectedItems: selectedSet,
                }),
                () => this.onHandlerDelete()
            );
        }
    };

    onClickedCheckbox = (id, callback) => {
        // let selectedSet = SetItemsSelected(id, this.state.selectedItems);

        this.setState((prev) => ({
            ...prev,
            selectedItems: [id],
        }));
    };
    /** State func helper */

    toggleAccordion = (tab) => {
        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => (tab === index ? !x : false));
        this.setState({
            accordion: state,
        });
    };

    render() {
        const { match } = this.props;
        const {
            page,
            accordion,
            selectedItems,
            data,
            is_loading_data,
            show,
            form,
        } = this.state;

        return is_loading_data ? (
            <div className='loading' />
        ) : (
            <div className='disable-text-selection'>
                <PageHeading
                    heading='analytics.role'
                    setting={page}
                    match={match}
                    totalItem={page.totalItem}
                    toggleFunc={() => {
                        this.toggle('add');
                    }}
                    changePageSizeFunc={this.onChangePageSize}
                    onSearchKeyFunc={this.onSearchKey}
                />
                <AddRole
                    title={<IntlMessages id='role.add-new-modal-title' />}
                    isOpen={show.add}
                    toggle={() => {
                        this.toggle('add');
                    }}
                    values={form}
                    setting={show}
                    onChange={this.onChangeForm}
                    onChangePermission={this.onChangePermission}
                    isChecked={this.isCheckedPermission}
                    onSubmit={this.onHandlerAdd}
                >
                    <ContentForm />
                </AddRole>
                <UpdateRole
                    title={<IntlMessages id='role.title' />}
                    isOpen={show.update}
                    toggle={() => this.toggle('update')}
                    setting={show}
                    onSubmit={this.onHandlerUpdate}
                    values={form}
                    onChange={this.onChangeForm}
                    onChangePermission={this.onChangePermission}
                    isChecked={this.isCheckedPermission}
                />
                {data.length === 0 ? (
                    <Card>
                        <CardBody>Chưa có dữ liệu...</CardBody>
                    </Card>
                ) : (
                    <>
                        <Row className='content-page__row d-none d-md-flex'>
                            <Col
                                xs='12'
                                sm='12'
                                md='12'
                                lg={show.view ? 4 : 12}
                                xl={show.view ? 4 : 12}
                            >
                                {data.map((item, idx) => (
                                    <ListItemLeftForm
                                        onClickedItem={this.onClickedItem}
                                        onUpdate={this.onClickedUpdate}
                                        onDelete={this.onClickedDelete}
                                        onChangeCheckbox={
                                            this.onClickedCheckbox
                                        }
                                        selectedItems={selectedItems}
                                        isDisabled={item.pending_table_payment}
                                        key={idx}
                                        idx={idx}
                                        item={item}
                                    />
                                ))}
                                <Pagination
                                    currentPage={page.currentPage}
                                    totalPage={page.totalPage}
                                    onChangePage={(i) => this.onChangePage(i)}
                                />
                            </Col>
                            {show.view ? (
                                <Col xs='12' sm='12' md='12' lg='8' xl='8'>
                                    <ContentDisplay
                                        // data_dynamic_form={data_dynamic_form}
                                        values={form}
                                        setting={show}
                                        onChange={this.onChangeForm}
                                        // onChangePermission={this.onChangePermission}
                                        // isChecked={this.isCheckedPermission}
                                    />
                                </Col>
                            ) : null}
                        </Row>
                        <Row className='content-page__row d-block d-md-none mb-4'>
                            <Col>
                                {data.map((item, idx) => {
                                    // console.log(item);
                                    return (
                                        <div
                                            className='d-flex mb-3 card'
                                            key={item.id}
                                        >
                                            <ListItemFormMobile
                                                onClickedItem={
                                                    this.onClickedItem
                                                }
                                                onUpdate={this.onClickedUpdate}
                                                onDelete={this.onClickedDelete}
                                                onChangeCheckbox={
                                                    this.onClickedCheckbox
                                                }
                                                isDisabledUpdate={
                                                    !isU(ROLE_ALIAS)
                                                }
                                                isDisabledDelete={
                                                    !isD(ROLE_ALIAS)
                                                }
                                                selectedItems={selectedItems}
                                                key={idx}
                                                idx={idx}
                                                item={item}
                                                form={form}
                                                accordion={accordion}
                                                toggleAccordion={
                                                    this.toggleAccordion
                                                }
                                            />
                                        </div>
                                    );
                                })}
                                <Pagination
                                    currentPage={page.currentPage}
                                    totalPage={page.totalPage}
                                    onChangePage={(i) => this.onChangePage(i)}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        );
    }
}

export default ContentComponent;
