import React from "react";
import {
  HandlerGetRequest,
} from '../../../helpers/Axios'
import {
  NotificatioErrController,
} from '../../../helpers/Utils'

class ContentComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labels: [],
      loading: true,
    }
  }

  componentDidMount() {
    return HandlerGetRequest({
      path: "/form-dynamic/by_form_alias?alias=bill"
    })
      .then(data => {
        if (!data.data) return false;

        let labels = [];
        for (let i = 0; i < data.data.length; i++) {
          labels.push(data.data[i].label);
        }

        this.setState({
          loading: false,
          labels,
        })
      })
      .catch(err => {
        NotificatioErrController(err.response.data.responseText)
        return false;
      })
  }

  render() {
    const {
      loading,
      labels
    } = this.state;

    return loading ? <></> : labels.map(_label => <p key={Math.random()}>{_label}</p>)
  }
}

export default ContentComponent