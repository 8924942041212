import React from 'react';
import {
    Badge,
    Card,
    CardBody,
    CardHeader,
    Modal,
    ModalBody,
    ModalHeader,
} from 'reactstrap';

const ContentComponent = ({ isOpen, toggle, recipe }) => {
    // console.log('recipe: ', recipe);
    const nameOrder = recipe[0]?.product.name;
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            // backdrop={true}
        >
            <ModalHeader toggle={toggle}>{nameOrder}</ModalHeader>
            <ModalBody>
                {/* <h5 className='p-3'>{nameOrder}</h5> */}
                <CardBody className='d-flex'>
                    {recipe?.map((r, idx) => {
                        return (
                            <Badge
                                key={idx}
                                color='info'
                                className='d-flex justify-content-center align-items-center p-2 mr-2'
                            >
                                {r.stock.name}
                                <Badge className={'ml-2'}>
                                    {r.count} {r.stock.unit}
                                </Badge>
                            </Badge>
                        );
                    })}
                </CardBody>
            </ModalBody>
        </Modal>
    );
};

export default ContentComponent;
