import React, { Component, Fragment } from 'react';
import { Row, Button } from 'reactstrap';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import { Separator, Colxx } from '../../../components/common/CustomBootstrap';
import { Redirect } from 'react-router';
import axios from 'axios';
import { END_POINT, END_POINT_SOCKET } from '../../../constants/defaultValues';
import io from 'socket.io-client';
import STATUS from '../../../constants/status';
import ReactToPrint from 'react-to-print';
import {
    getDateWithFormat,
    NotificatioErrController,
} from '../../../helpers/Utils';
import LogsHelper from '../../../helpers/Logs';
// import { AddModal as DiscountAddModal } from '../../../containers/pages/discount';
import { GetProductsSelectDiscount } from '../../../helpers/Discount';
import ComponentToPrint from '../../../containers/pages/ComponentToPrint';
import DiscountTaxFeeModal from '../../../containers/pages/DiscountTaxFeeModal';
// import moment from 'moment';

class InvoicePages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: props.location.aboutProps
                ? props.location.aboutProps.orders
                : null,
            discount: props.location.aboutProps
                ? props.location.aboutProps.discount
                : false,
            companyName: '',
            companyAddress: '',
            companyLogo: '',
            companyWebsite: '',
            items: [],
            items_id: [],
            billDate: '',
            discount_form: {
                orders_id: [],
                user_id: '',
                note: '',
                value: '',
                type: 'percent',
                saved: false,
            },
            tax_fee: {
                orders_id: [],
                user_id: '',
                note: '',
                value: '',
                type: 'percent',
                saved: false,
            },
            fee_other: {
                orders_id: [],
                user_id: '',
                note: '',
                value: '',
                type: 'percent',
                saved: false,
            },
            is_open_modal_discount_tax_fee: props.location.aboutProps
                ? props.location.aboutProps.discount
                    ? true
                    : false
                : false,
            // is_open_modal_discount: props.location.aboutProps
            //     ? props.location.aboutProps.discount
            //         ? true
            //         : false
            //     : false,
            products_select_discount: [],
            pin_print: '',
            // pin_print: Math.random().toString(36).replace(/[^a-z1-9]+/g, '').substr(0, 15),
        };

        this.socket = io(END_POINT_SOCKET);
    }

    componentWillUnmount() {
        this.socket.close();
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        if (this.state.orders != null) {
            this.dataOrdersRender();
        } else {
            //console.log("Dữ liệu không hợp lệ");
        }
        this.renderLengthBill();
    }

    renderLengthBill = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/bill/length_bill'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                this.setState((prev) => ({
                    ...prev,
                    pin_print: data.data.length - 1,
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    dataOrdersRender() {
        if (this.state.orders === null) return false;
        // console.log('orders: ', this.state.orders);

        let tokenStr = localStorage.getItem('access_token');

        axios
            .post(
                `${END_POINT + '/bill/invoice_by_orders_id'}`,
                {
                    orders: this.state.orders,
                },
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                // console.log('data:', data);
                // console.log('timeStart:', data.data[0].bill.time);

                const result = [];
                for (let i = 0; i < data.data.length; i++) {
                    const find_item_id = result.find(
                        (_item) =>
                            _item.product_id === data.data[i].product_id &&
                            _item.price === data.data[i].price
                    );

                    if (find_item_id) {
                        // Có thì cộng số lượng lên
                        find_item_id.count = find_item_id.count + 1;
                        // find_item_id.price =
                        //     parseFloat(find_item_id.price) +
                        //     parseFloat(data.data[i].price);
                    } else {
                        result.push(data.data[i]);
                    }
                }

                this.setState({
                    items: result,
                    items_id: data.data,
                    companyName: localStorage.getItem('companyName'),
                    companyAddress: localStorage.getItem('companyAddress'),
                    companyLogo: localStorage.getItem('companyLogo'),
                    companyWebsite: localStorage.getItem('companyWebsite'),
                    billDate: getDateWithFormat(),
                    timeStart: data.data[0].bill.time,
                    bill: data.data[0].bill.id,
                    products_select_discount: GetProductsSelectDiscount(
                        data.data
                    ),
                });
            });
    }

    handleClickedPrintInvoice = (e) => {
        let arrayOrdersId = this.state.items_id.map((i) => i.id);
        // console.log('arrayOrdersId: ', arrayOrdersId);
        let { toggleModal } = this.props;
        toggleModal();

        let tokenStr = localStorage.getItem('access_token');

        return axios
            .put(
                `${END_POINT + '/order/status'}`,
                {
                    arrayId: arrayOrdersId,
                    status_id: STATUS.PAYMENTED,
                    pin_print: this.state.pin_print,
                },
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then(async (data) => {
                this.socket.emit('refresh-recent-order', {
                    user_id: localStorage.getItem('user_id'),
                    floor: 0,
                    user_identify: this.state.items[0]['user_identify'],
                    table_id: this.state.items[0]['table_id'],
                });
                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['invoice-print'],
                });

                // save discount

                if (this.state.discount_form.saved) {
                    await this.handleSaveDiscount();
                }
            })
            .catch((err) => {});
    };

    handleSaveDiscount = async () => {
        let tokenStr = localStorage.getItem('access_token');

        return await axios
            .post(
                `${END_POINT + '/order/discount'}`,
                {
                    orders_id: this.state.discount_form.orders_id,
                    value: this.state.discount_form.value
                        .toString()
                        .replace(/\./g, ''),
                    type: this.state.discount_form.type,
                    note: this.state.discount_form.note,
                },
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .catch((err) => {
                NotificatioErrController('Có lỗi xảy ra khi lưu chiết khấu');
            });
    };

    handleSaveTaxFee = async () => {
        const { tax_fee } = this.state;
        let tokenStr = localStorage.getItem('access_token');

        return await axios
            .post(
                `${END_POINT + '/order/tax_fee'}`,
                {
                    orders_id: tax_fee.orders_id,
                    value: tax_fee.value.toString().replace(/\./g, ''),
                    type: tax_fee.type,
                    bill_id: '',
                    note: tax_fee.note,
                },
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((data) => {
                // NotificationManager.primary(
                //     data.responseText,
                //     '',
                //     3000,
                //     null,
                //     null,
                //     'filled'
                // );
                // this.socket.emit('create logs', {
                //     user_id: localStorage.getItem('user_id'),
                //     message: LogsHelper['tax-fee-create'],
                // });
                // this.setState((prev) => ({
                //     ...prev,
                //     tax_fee: {
                //         orders_id: [],
                //         user_id: '',
                //         note: '',
                //         value: '',
                //         type: '',
                //         saved: false,
                //     },
                // }));
            })
            .catch((err) => {
                NotificatioErrController('Có lỗi xảy ra khi lưu thuế phí');
            });
    };

    handleSaveFeeOther = async () => {
        const { fee_other } = this.state;
        // console.log('fee_other: ', fee_other);
        let tokenStr = localStorage.getItem('access_token');
        return await axios
            .post(
                `${END_POINT + '/order/fee_other'}`,
                {
                    orders_id: fee_other.orders_id,
                    value: fee_other.value.toString().replace(/\./g, ''),
                    type: fee_other.type,
                    bill_id: '',
                    note: fee_other.note,
                },
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((data) => {
                // NotificationManager.primary(
                //     data.responseText,
                //     '',
                //     3000,
                //     null,
                //     null,
                //     'filled'
                // );
                // this.socket.emit('create logs', {
                //     user_id: localStorage.getItem('user_id'),
                //     message: LogsHelper['fee-other-create'],
                // });
                // this.setState((prev) => ({
                //     ...prev,
                //     fee_other: {
                //         orders_id: [],
                //         user_id: '',
                //         note: '',
                //         value: '',
                //         type: '',
                //         saved: false,
                //     },
                // }));
            })
            .catch((err) => {
                NotificatioErrController('Có lỗi xảy ra khi lưu phụ phí');
            });
    };

    handleToggleModalDiscountTaxFee = () => {
        this.setState({
            is_open_modal_discount_tax_fee:
                !this.state.is_open_modal_discount_tax_fee,
        });
    };

    handleSubmitModalDiscountTaxFee = async () => {
        let { discount_form, tax_fee, fee_other, cashsPay } = this.state;

        if (
            discount_form.value !== '' &&
            discount_form.type === 'percent' &&
            Number(discount_form.value) > 100
        ) {
            return NotificatioErrController(
                'Giá trị chiết khấu không được vượt quá 100%'
            );
        }
        if (
            tax_fee.value !== '' &&
            tax_fee.type === 'percent' &&
            Number(tax_fee.value) > 500
        ) {
            return NotificatioErrController(
                'Giá trị thuế không được vượt quá 500%'
            );
        }
        if (
            fee_other.value !== '' &&
            fee_other.type === 'percent' &&
            Number(fee_other.value) > 200
        ) {
            return NotificatioErrController(
                'Giá trị chi phí khác không được vượt quá 200%'
            );
        }

        if (
            discount_form.value !== '' &&
            discount_form.type === 'price' &&
            Number(discount_form.value.toString().replace(/\./g, '')) < 1000
        ) {
            return NotificatioErrController(
                'Giá trị chiết khấu không được nhỏ hơn 1.000 VNĐ'
            );
        }
        if (
            tax_fee.value !== '' &&
            tax_fee.type === 'price' &&
            Number(tax_fee.value.toString().replace(/\./g, '')) < 1000
        ) {
            return NotificatioErrController(
                'Giá trị thuế không được nhỏ hơn 1.000 VNĐ'
            );
        }
        if (
            fee_other.value !== '' &&
            fee_other.type === 'price' &&
            Number(fee_other.value.toString().replace(/\./g, '')) < 1000
        ) {
            return NotificatioErrController(
                'Giá trị chi phí khác không được nhỏ hơn 1.000 VNĐ'
            );
        }

        await this.setState((prevState) => ({
            ...prevState,
            discount_form: {
                ...prevState.discount_form,
                saved: true,
                note: discount_form.note,
                value: discount_form.value.toString().replace(/\./g, ''),
                type: discount_form.type,
            },
            tax_fee: {
                ...prevState.tax_fee,
                saved: true,
                note: tax_fee.note,
                value: tax_fee.value.toString().replace(/\./g, ''),
                type: tax_fee.type,
            },
            fee_other: {
                ...prevState.fee_other,
                saved: true,
                note: fee_other.note,
                value: fee_other.value.toString().replace(/\./g, ''),
                type: fee_other.type,
            },
        }));
        // if (cashsPay) {
        //     this.cashRefundToCustomer();
        // }
        this.handleToggleModalDiscountTaxFee();
    };

    handleOnChangeModalDiscountTaxFee = (e, inputType, valueType) => {
        const { name, value } = e.target;
        const { discount_form, tax_fee, fee_other } = this.state;

        // Tùy thuộc vào loại input (discount, tax, fee), cập nhật state tương ứng
        this.setState((prevState) => ({
            [`${inputType}`]: {
                ...prevState[`${inputType}`],
                [name]:
                    valueType === 'percent'
                        ? 'percent'
                        : valueType === 'price'
                        ? 'price'
                        : value,
            },
        }));
    };

    // handleToggleModalDiscount = () => {
    //     this.setState({
    //         is_open_modal_discount: !this.state.is_open_modal_discount,
    //     });
    // };

    // handleSubmitModalDiscount = () => {
    //     // templ save, when click print will saved
    //     let { orders_id, note, value, type } = this.state.discount_form;

    //     if (orders_id.length < 1) {
    //         orders_id = this.state.products_select_discount.map((d) => d.value);
    //     } else {
    //         orders_id = this.state.items.map((d) => d.id);
    //     }

    //     if (!type || !value) {
    //         return NotificatioErrController('Dữ liệu không hợp lệ');
    //     }

    //     this.setState((prevState) => ({
    //         ...prevState,
    //         discount_form: {
    //             ...prevState.discount_form,
    //             saved: true,
    //             note,
    //             value: value.toString().replace(/\./g, ''),
    //             type,
    //             orders_id,
    //         },
    //     }));
    //     this.handleToggleModalDiscount();
    // };

    // handleOnChangeModalDiscount = (e, _name) => {
    //     let name = '',
    //         value = '';

    //     if (e.target) {
    //         name = e.target.name;
    //         value = e.target.value;

    //         if (!name) {
    //             name = _name;
    //         }
    //     } else {
    //         name = _name;

    //         if (Array.isArray(e)) {
    //             value = e.map((d) => d.value);
    //         } else {
    //             value = e.value;
    //         }
    //     }

    //     this.setState((prevState) => ({
    //         ...prevState,
    //         discount_form: {
    //             ...prevState.discount_form,
    //             [name]: value,
    //         },
    //     }));
    // };

    render() {
        let {
            orders,
            companyAddress,
            companyWebsite,
            companyLogo,
            companyName,
            billDate,
            timeStart,
            items,
            discount,
            discount_form,
            tax_fee,
            fee_other,
            is_open_modal_discount_tax_fee,
            // is_open_modal_discount,
            // products_select_discount,
            pin_print,
        } = this.state;

        // console.log('pin_print: ', pin_print);
        // console.log('orders: ', orders);
        // console.log('items: ', items);

        return !orders ? (
            <Redirect push to='/app/pages/order' />
        ) : (
            <Fragment>
                {this.props.match ? (
                    <Row>
                        <Colxx xxs='12'>
                            <Breadcrumb
                                heading='menu.invoice'
                                match={this.props.match}
                            />
                            <Separator className='mb-5' />
                        </Colxx>
                    </Row>
                ) : null}
                <div className='invoice__container position-sticky-custom'>
                    <Row className='invoice-react'>
                        <Colxx className='mb-5 invoice-print-btn'>
                            <span
                                className=''
                                onClick={this.handleClickedPrintInvoice}
                            >
                                {this.componentRef && (
                                    <ReactToPrint
                                        onClick={this.handleClickedPrintInvoice}
                                        trigger={() => (
                                            <Button color='secondary' outline>
                                                In hóa đơn
                                            </Button>
                                        )}
                                        content={() => this.componentRef}
                                    />
                                )}
                            </span>
                        </Colxx>
                    </Row>
                    <ComponentToPrint
                        items={items}
                        companyName={companyName}
                        companyAddress={companyAddress}
                        companyLogo={companyLogo}
                        companyWebsite={companyWebsite}
                        billDate={billDate}
                        timeStart={timeStart}
                        pin_print={pin_print}
                        ref={(el) => (this.componentRef = el)}
                        discount={discount_form.saved ? discount_form : null}
                        tax_fee={tax_fee.saved ? tax_fee : null}
                        fee_other={fee_other.saved ? fee_other : null}
                    />
                </div>
                <DiscountTaxFeeModal
                    isOpen={is_open_modal_discount_tax_fee}
                    values_discount={discount_form}
                    values_tax={tax_fee}
                    values_fee={fee_other}
                    // products_discount={products_select_discount}
                    // products_tax={products_select_taxfee}
                    // products_fee={products_select_fee_other}
                    onToggle={this.handleToggleModalDiscountTaxFee}
                    onSubmit={this.handleSubmitModalDiscountTaxFee}
                    onChange={this.handleOnChangeModalDiscountTaxFee}
                />
                {/* <DiscountAddModal
                    isOpen={is_open_modal_discount}
                    values={discount_form}
                    products={products_select_discount}
                    onToggle={this.handleToggleModalDiscount}
                    onSubmit={this.handleSubmitModalDiscount}
                    onChange={this.handleOnChangeModalDiscount}
                /> */}
            </Fragment>
        );
    }
}
export default InvoicePages;
