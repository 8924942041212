import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
    UncontrolledDropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';

import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import {
    setContainerClassnames,
    clickOnMobileMenu,
    logoutUser,
    changeLocale,
} from '../../redux/actions';

import { END_POINT, END_POINT_SOCKET } from '../../constants/defaultValues';
import io from 'socket.io-client';

class TopnavPaymenter extends Component {
    constructor(props) {
        super(props);

        this.socket = io(END_POINT_SOCKET);

        this.state = {
            companyName:
                localStorage.getItem('companyName') &&
                localStorage.getItem('companyName') !== 'null'
                    ? localStorage.getItem('companyName')
                    : localStorage.getItem('username'),
        };
    }

    componentDidMount() {
        // ScrollDetect();
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        // Logout
        this.socket.on('logout', () => {
            this.handleLogout();
        });
    }

    handleLogout = () => {
        this.props.logoutUser(this.props.history);
    };

    menuButtonClick = (e) => {
        return <Redirect to='/payment' />;
    };

    render() {
        const store = window.location.host.split('.')[0];
        const pathname = this.props.history.location.pathname;
        const PATH_PROFILE = '/app/applications/profile';
        const PATH_FAQ = '/root/faq/dashboards';
        const PATH_MESSAGE = '/root/message/dashboards';
        const PATH_UPGRADE = '/root/upgrade/dashboards';
        const ROLESETTINGS = localStorage.getItem('roleSettings')
            ? JSON.parse(localStorage.getItem('roleSettings'))
            : null;

        if (
            store === 'faq' &&
            PATH_PROFILE !== pathname &&
            PATH_FAQ !== pathname
        ) {
            return <Redirect to='/root/faq/dashboards' />;
        } else if (
            store === 'message' &&
            PATH_PROFILE !== pathname &&
            PATH_MESSAGE !== pathname
        ) {
            return <Redirect to='/root/message/dashboards' />;
        } else if (
            store === 'upgrade' &&
            PATH_PROFILE !== pathname &&
            PATH_UPGRADE !== pathname
        ) {
            return <Redirect to='/root/upgrade/dashboards' />;
        } else if (ROLESETTINGS && ROLESETTINGS.length) {
            const find_payment = ROLESETTINGS.find(
                (_role) => _role.role_alias === 'table_pending_payment'
            );
            if (typeof find_payment === 'undefined') {
                return <Redirect to='/app' />;
            }
        } else if (ROLESETTINGS && ROLESETTINGS.length) {
            const find_kitchen = ROLESETTINGS.find(
                (_role) => _role.role_alias === 'kitchen'
            );
            if (typeof find_kitchen === 'undefined') {
                return <Redirect to='/app' />;
            }
        } else if (ROLESETTINGS && ROLESETTINGS.length) {
            const find_cashier = ROLESETTINGS.find(
                (_role) => _role.role_alias === 'cashier'
            );
            if (typeof find_cashier === 'undefined') {
                return <Redirect to='/app' />;
            }
        }

        return (
            <nav className='navbar fixed-top'>
                <div className='d-flex align-items-center navbar-left'></div>
                <a className='navbar-logo' href='https://vmass.vn/'>
                    <span className='logo d-none d-xs-block' />
                    <span className='logo d-block d-xs-none' />
                </a>

                <div className='navbar-right'>
                    <div className='user mr-3 d-inline-block'>
                        <UncontrolledDropdown className='dropdown-menu-right'>
                            <DropdownToggle className='p-0' color='empty'>
                                <span className='name mr-1'>
                                    {this.state.companyName}
                                </span>
                                <span>
                                    <img
                                        alt=''
                                        src={
                                            localStorage.getItem('avatar') !==
                                                'null' &&
                                            localStorage.getItem('avatar') !==
                                                'undefined.'
                                                ? END_POINT +
                                                  '/public/' +
                                                  localStorage.getItem('avatar')
                                                : '/assets/img/profile-pic-l.jpg'
                                        }
                                    />
                                </span>
                            </DropdownToggle>
                            <DropdownMenu className='mt-3' right>
                                <DropdownItem
                                    onClick={() => this.handleLogout()}
                                >
                                    Đăng xuất
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = ({ menu, settings }) => {
    const { containerClassnames, menuClickCount, selectedMenuHasSubItems } =
        menu;
    const { locale } = settings;
    return {
        containerClassnames,
        menuClickCount,
        selectedMenuHasSubItems,
        locale,
    };
};
export default injectIntl(
    connect(mapStateToProps, {
        setContainerClassnames,
        clickOnMobileMenu,
        logoutUser,
        changeLocale,
    })(TopnavPaymenter)
);
