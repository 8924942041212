import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';

const ContentComponent = ({ title, isOpen, toggle, onSubmit, children }) => {
    // console.log('render UpdateComponent');
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
        >
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody className='pad-125rem scrool modal-right-add'>
                <PerfectScrollbar
                    options={{
                        suppressScrollX: true,
                        wheelPropagation: false,
                    }}
                    id='modal-right__body'
                >
                    {children}
                </PerfectScrollbar>
            </ModalBody>
            <ModalFooter>
                <Button color='primary' onClick={onSubmit}>
                    <IntlMessages id='general.btn-update' />
                </Button>{' '}
            </ModalFooter>
        </Modal>
    );
};

export default React.memo(ContentComponent);
