import React from 'react';
import ReactTable from 'react-table';
import Pagination from '../../../components/DatatablePagination';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentMultiForm = (props) => {
    const { data } = props;
    // console.log('data: ', data);
    const columns = [
        {
            Header: 'Tên sản phẩm',
            accessor: 'name',
            Cell: (props) => (
                <span className='text-capitalize'>{props.value}</span>
            ),
        },
        {
            Header: 'Khuyên dùng',
            accessor: 'bestter',
            Cell: (props) =>
                props.value === true ? (
                    <i className='simple-icon-check text-primary' />
                ) : (
                    ''
                ),
        },
        {
            Header: 'Giá bán',
            accessor: 'price',
            Cell: (props) => AddCommaNumber(props.value),
        },
        {
            Header: 'Đang khuyến mãi',
            accessor: 'active_sale',
            Cell: (props) =>
                props.value === true ? (
                    <i className='simple-icon-check text-primary' />
                ) : (
                    <i className='simple-icon-close' />
                ),
        },
        {
            Header: 'Giá khuyến mãi',
            accessor: 'price_sale',
            Cell: (props) =>
                props.value === '0' ? (
                    <i className='simple-icon-close' />
                ) : (
                    AddCommaNumber(props.value)
                ),
        },
        {
            Header: 'Ẩn/hiện',
            accessor: 'softHide',
            Cell: (props) => (props.value === true ? 'Đang ẩn' : 'Đang hiện'),
        },
    ];
    return (
        <ReactTable
            defaultPageSize={20}
            data={data}
            columns={columns}
            minRows={0}
            showPageJump={false}
            showPageSizeOptions={true}
            PaginationComponent={Pagination}
            NoDataComponent={() => null}
        />
    );
};

export default ContentMultiForm;
