import React, { memo } from 'react';

const FooterWarehouseExport = () => {
    return (
        <a
            href='https://docs.vmass.vn/quan-ly/warehouse-export'
            target='_blank'
            className='mb-0 pl-2'
        >
            Hướng dẫn
        </a>
    );
};

export default memo(FooterWarehouseExport);
