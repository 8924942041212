import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
    Row,
    Col,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Select from 'react-select';
import CustomSelectInput from '../../../components/common/CustomSelectInput';
import {
    DateGetSalary,
    SalaryBy,
    HourWorkingOnDay,
    GetHourWorkingOnDay,
    GetStatusWorking,
    GetSalaryBy,
    GetDateGetSalary,
    StatusWorking,
    GetWorkingHours,
} from '../../../helpers/UserStaff';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { AddCommaNumber } from '../../../helpers/Utils';
import WorkingHours from './WorkingHours';
import DynamicForm from '../general/DynamicForm';
import TooltipItem from '../../../components/common/TooltipItem';

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    data_dynamic_form,
    values,
    setting,
    onChange,
    onChangeWorkingHours,
    onAddWorkingHours,
    onRemoveWorkingHours,
    onChangeDynamicForm,
}) => {
    // console.log('render UpdateComponent');
    const [state, setState] = useState({
        pending_date: null,
        pending_hour: null,
    });

    const [dateGetSalary] = useState({
        placement: 'top',
        text: '',
        body: 'Là ngày chốt sổ lương và chuyển sang kỳ trả lương của tháng mới.',
    });

    const [dateJoin] = useState({
        placement: 'top',
        text: '',
        body: 'Kỳ lương gần nhất sẽ được tính từ "Ngày tham gia" đến "Ngày thanh toán lương".',
    });

    const [salaryValue] = useState({
        placement: 'top',
        text: '',
        body: 'Nếu lương tính theo Giờ, công thức tính lương là: Mức lương Giờ * Số giờ làm việc * Số ngày tính lương. Nếu lương tính theo Ngày, công thức tính lương là: Mức lương ngày * Số ngày tính lương. Nếu lương tính theo Tháng, thì lương trong kỳ chính là mức lương Tháng.',
    });

    useEffect(() => {
        setState({
            ...state,
            pending_date: values.pending_date,
            pending_hour: values.pending_hour,
        });
    }, [values.pending_date, values.pending_hour]);
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='pad-125rem scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        <Row className='mb-3'>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                {/* NAME */}
                                <FormGroup className='mt-3 has-float-label'>
                                    <Label for='name' className='text-muted'>
                                        <IntlMessages id='user-staff.name' />
                                    </Label>
                                    <Input
                                        type='text'
                                        name='name'
                                        id='name'
                                        disabled={
                                            setting.viewonly && !setting.add
                                        }
                                        value={values.name}
                                        onChange={onChange}
                                        className='text-capitalize'
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                {/* PERMISSION NAME */}
                                <FormGroup className='has-float-label'>
                                    <Label
                                        for='permission_name'
                                        className='text-muted'
                                    >
                                        <IntlMessages id='user-staff.permission_name' />
                                    </Label>
                                    <Input
                                        type='text'
                                        name='permission_name'
                                        id='permission_name'
                                        disabled={
                                            setting.viewonly && !setting.add
                                        }
                                        value={values.permission_name}
                                        onChange={onChange}
                                        className='text-capitalize'
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                {/* EMAIL */}
                                <FormGroup className='has-float-label'>
                                    <Label for='email' className='text-muted'>
                                        <IntlMessages id='email' />
                                    </Label>
                                    <Input
                                        type='email'
                                        name='email'
                                        id='email'
                                        disabled={
                                            setting.viewonly && !setting.add
                                        }
                                        value={values.email}
                                        onChange={onChange}
                                        className='text-lowercase'
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                {/* PHONE */}
                                <FormGroup className='has-float-label'>
                                    <Label for='phone' className='text-muted'>
                                        <IntlMessages id='phone' />
                                    </Label>
                                    <Input
                                        type='text'
                                        name='phone'
                                        id='phone'
                                        disabled={
                                            setting.viewonly && !setting.add
                                        }
                                        value={values.phone}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                {/* ADDRESS */}
                                <FormGroup className='has-float-label'>
                                    <Label for='address' className='text-muted'>
                                        <IntlMessages id='address' />
                                    </Label>
                                    <Input
                                        type='text'
                                        name='address'
                                        id='address'
                                        disabled={
                                            setting.viewonly && !setting.add
                                        }
                                        value={values.address}
                                        onChange={onChange}
                                        className='text-capitalize'
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row className='pt-3 custom-line'></Row>

                        <Row className='mb-3'>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                {/* DATE JOIN */}
                                <FormGroup className='mt-3 has-float-label'>
                                    <Label
                                        for='date_join'
                                        className='text-muted'
                                    >
                                        <IntlMessages id='user-staff.date_join' />
                                        <TooltipItem item={dateJoin} id={1} />
                                    </Label>
                                    <DatePicker
                                        name='date_join'
                                        id='date_join'
                                        // className='mb-3'
                                        onChange={(date) => {
                                            onChange(date, 'date_join');
                                        }}
                                        selected={moment(values.date_join)}
                                        dateFormat='YYYY-MM-DD'
                                        disabled={
                                            setting.viewonly && !setting.add
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                {/* DATE GET SALARY */}
                                <FormGroup className='has-float-label'>
                                    <Label
                                        for='date_get_salary'
                                        className='text-muted'
                                    >
                                        <IntlMessages id='user-staff.date_get_salary' />
                                        <TooltipItem
                                            item={dateGetSalary}
                                            id={2}
                                        />
                                    </Label>
                                    <Select
                                        components={{
                                            Input: CustomSelectInput,
                                        }}
                                        className='react-select'
                                        classNamePrefix='react-select'
                                        name='date_get_salary'
                                        defaultValue={GetDateGetSalary(
                                            values.date_get_salary
                                        )}
                                        isDisabled={
                                            setting.viewonly && !setting.add
                                        }
                                        placeholder={
                                            <IntlMessages id='select-one-time' />
                                        }
                                        options={DateGetSalary()}
                                        onChange={(e) => {
                                            onChange(e, 'date_get_salary');
                                        }}
                                    />
                                    {state.pending_date &&
                                    state.pending_date.date_get_salary !==
                                        values.date_get_salary ? (
                                        <WarningMessage
                                            text='Đang chờ áp dụng: '
                                            value={
                                                state.pending_date
                                                    .date_get_salary
                                            }
                                            at={
                                                state.pending_date
                                                    .date_start_pending_setting
                                            }
                                        />
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                {/* HOUR WORKING ON DAY */}
                                <FormGroup className='has-float-label'>
                                    <Label
                                        for='hour_working_on_day'
                                        className='text-muted'
                                    >
                                        <IntlMessages id='user-staff.hour_working_on_day' />
                                    </Label>
                                    <Select
                                        components={{
                                            Input: CustomSelectInput,
                                        }}
                                        className='react-select'
                                        classNamePrefix='react-select'
                                        name='hour_working_on_day'
                                        defaultValue={GetHourWorkingOnDay(
                                            values.hour_working_on_day
                                        )}
                                        isDisabled={
                                            setting.viewonly && !setting.add
                                        }
                                        placeholder={
                                            <IntlMessages id='select-one-time' />
                                        }
                                        options={HourWorkingOnDay()}
                                        onChange={(e) => {
                                            onChange(e, 'hour_working_on_day');
                                        }}
                                    />
                                    {state.pending_hour &&
                                    state.pending_hour.hour_working_on_day !==
                                        values.hour_working_on_day ? (
                                        <WarningMessage
                                            text='Đang chờ áp dụng: '
                                            value={
                                                state.pending_hour
                                                    .hour_working_on_day
                                            }
                                            at={
                                                state.pending_hour
                                                    .date_start_pending_setting
                                            }
                                        />
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                {/* SALARY BY */}
                                <FormGroup className='has-float-label'>
                                    <Label
                                        for='salary_by'
                                        className='text-muted'
                                    >
                                        <IntlMessages id='user-staff.salary_by' />
                                    </Label>
                                    <Select
                                        components={{
                                            Input: CustomSelectInput,
                                        }}
                                        className='react-select'
                                        classNamePrefix='react-select'
                                        name='salary_by'
                                        defaultValue={GetSalaryBy(
                                            values.salary_by
                                        )}
                                        isDisabled={
                                            setting.viewonly && !setting.add
                                        }
                                        placeholder={
                                            <IntlMessages id='select-one-time' />
                                        }
                                        options={SalaryBy()}
                                        onChange={(e) => {
                                            onChange(e, 'salary_by');
                                        }}
                                    />
                                    {state.pending_date &&
                                    state.pending_date.salary_by !==
                                        values.salary_by ? (
                                        <WarningMessage
                                            text='Đang chờ áp dụng: '
                                            value={
                                                GetSalaryBy(
                                                    state.pending_date.salary_by
                                                ).label
                                            }
                                            at={
                                                state.pending_date
                                                    .date_start_pending_setting
                                            }
                                        />
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                {/* SALARY VALUE */}
                                <FormGroup className='has-float-label'>
                                    <Label
                                        for='salary_value'
                                        className='text-muted'
                                    >
                                        <IntlMessages id='user-staff.salary_value' />{' '}
                                        {`${
                                            GetSalaryBy(values.salary_by).label
                                        }`}
                                        <TooltipItem
                                            item={salaryValue}
                                            id={3}
                                        />
                                    </Label>
                                    <Input
                                        type='text'
                                        name='salary_value'
                                        id='salary_value'
                                        disabled={
                                            setting.viewonly && !setting.add
                                        }
                                        value={AddCommaNumber(
                                            values.salary_value
                                        )}
                                        onChange={onChange}
                                    />
                                    {state.pending_date &&
                                    state.pending_date.salary_value !==
                                        values.salary_value ? (
                                        <WarningMessage
                                            text='Đang chờ áp dụng: '
                                            value={AddCommaNumber(
                                                state.pending_date.salary_value
                                            )}
                                            at={
                                                state.pending_date
                                                    .date_start_pending_setting
                                            }
                                        />
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                {/* WORKING HOURS */}
                                <FormGroup>
                                    <Label
                                        for='working_hours'
                                        className='text-muted'
                                    >
                                        <IntlMessages id='user-staff.working_hours' />
                                    </Label>
                                    <WorkingHours
                                        onChange={onChangeWorkingHours}
                                        onAdd={onAddWorkingHours}
                                        onRemove={onRemoveWorkingHours}
                                        values={values.working_hours}
                                        disabled={
                                            setting.viewonly && !setting.add
                                        }
                                    />
                                    {state.pending_hour &&
                                    GetWorkingHours(values.working_hours) !==
                                        state.pending_hour.working_hours ? (
                                        <WarningMessage
                                            text='Đang chờ áp dụng'
                                            value={''}
                                            at={
                                                state.pending_hour
                                                    .date_start_pending_setting
                                            }
                                        />
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                {/* STATUS WORKING */}
                                <FormGroup className='mt-3 has-float-label'>
                                    <Label
                                        for='status_working'
                                        className='text-muted'
                                    >
                                        <IntlMessages id='user-staff.status_working' />
                                    </Label>
                                    <Select
                                        components={{
                                            Input: CustomSelectInput,
                                        }}
                                        className='react-select'
                                        classNamePrefix='react-select'
                                        name='status_working'
                                        defaultValue={GetStatusWorking(
                                            values.status_working
                                        )}
                                        isDisabled={
                                            setting.viewonly && !setting.add
                                        }
                                        placeholder={
                                            <IntlMessages id='select-one-time' />
                                        }
                                        options={StatusWorking()}
                                        onChange={(e) => {
                                            onChange(e, 'status_working');
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        {/* {setting.update ? (
                <>
                    <Row className='pt-3 custom-line'></Row>

                    <Row className='mt-3'>
                        <Col xs={12} sm={4} md={4} lg={4}>
                             // COMMISSION 
                            <FormGroup>
                                <Label for='current_commission'>
                                    <IntlMessages id='user-staff.current_commission' />
                                </Label>
                                <Input
                                    type='text'
                                    name='current_commission'
                                    id='current_commission'
                                    disabled={true}
                                    value={AddCommaNumber(
                                        values.current_commission
                                    )}
                                    onChange={onChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={4} md={4} lg={4}>
                            // BONUS 
                            <FormGroup>
                                <Label for='current_bonus'>
                                    <IntlMessages id='user-staff.current_bonus' />
                                </Label>
                                <Input
                                    type='text'
                                    name='current_bonus'
                                    id='current_bonus'
                                    disabled={true}
                                    value={AddCommaNumber(
                                        values.current_bonus
                                    )}
                                    onChange={onChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={4} md={4} lg={4}>
                            // OVERTIME
                            <FormGroup>
                                <Label for='current_overtime'>
                                    <IntlMessages id='user-staff.current_overtime' />
                                </Label>
                                <Input
                                    type='text'
                                    name='current_overtime'
                                    id='current_overtime'
                                    disabled={true}
                                    value={AddCommaNumber(
                                        values.current_overtime
                                    )}
                                    onChange={onChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={4} md={4} lg={4}>
                            //  ADVENCE
                            <FormGroup>
                                <Label for='current_advence'>
                                    <IntlMessages id='user-staff.current_advence' />
                                </Label>
                                <Input
                                    type='text'
                                    name='current_advence'
                                    id='current_advence'
                                    disabled={true}
                                    value={AddCommaNumber(
                                        values.current_advence
                                    )}
                                    onChange={onChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={4} md={4} lg={4}>
                            //  FINED
                            <FormGroup>
                                <Label for='current_fined'>
                                    <IntlMessages id='user-staff.current_fined' />
                                </Label>
                                <Input
                                    type='text'
                                    name='current_fined'
                                    id='current_fined'
                                    disabled={true}
                                    value={AddCommaNumber(
                                        values.current_fined
                                    )}
                                    onChange={onChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={4} md={4} lg={4}>
                            //  DAY OFF
                            <FormGroup>
                                <Label for='current_dayoff'>
                                    <IntlMessages id='user-staff.current_dayoff' />
                                </Label>
                                <Input
                                    type='text'
                                    name='current_dayoff'
                                    id='current_dayoff'
                                    disabled={true}
                                    value={AddCommaNumber(
                                        values.current_dayoff
                                    )}
                                    onChange={onChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            //  DAY WORKING
                            <FormGroup>
                                <Label for='current_day_working'>
                                    <IntlMessages id='user-staff.current_day_working' />
                                </Label>
                                <Input
                                    type='text'
                                    name='current_day_working'
                                    id='current_day_working'
                                    disabled={true}
                                    value={values.current_day_working}
                                    onChange={onChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            //  SALARY
                            <FormGroup>
                                <Label for='current_salary'>
                                    <IntlMessages id='user-staff.current_salary' />
                                </Label>
                                <Input
                                    type='text'
                                    name='current_salary'
                                    id='current_salary'
                                    disabled={true}
                                    value={AddCommaNumber(
                                        values.current_salary
                                    )}
                                    onChange={onChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            //  TOTAL DAY WORKING
                            <FormGroup>
                                <Label for='total_day_working'>
                                    <IntlMessages id='user-staff.total_day_working' />
                                </Label>
                                <Input
                                    type='text'
                                    name='total_day_working'
                                    id='total_day_working'
                                    disabled={true}
                                    value={values.total_day_working}
                                    onChange={onChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            //  TOTAL SALARY
                            <FormGroup>
                                <Label for='total_salary'>
                                    <IntlMessages id='user-staff.total_salary' />
                                </Label>
                                <Input
                                    type='text'
                                    name='total_salary'
                                    id='total_salary'
                                    disabled={true}
                                    value={AddCommaNumber(
                                        values.total_salary
                                    )}
                                    onChange={onChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </>
            ) : null} */}

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <DynamicForm
                                    values={values.dynamic_form}
                                    data={data_dynamic_form}
                                    onChange={onChangeDynamicForm}
                                    disabled={setting.viewonly && !setting.add}
                                />
                            </Col>
                        </Row>
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='general.btn-update' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

const WarningMessage = (props) => {
    return (
        <Label className='warning-message'>
            {`* ${props.text}${props.value}, tại ngày ${props.at}`}
        </Label>
    );
};

export default React.memo(ContentComponent);
