import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-router-dom';
import moment from 'moment';
import { Row, Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import {
    DateRangePicker,
    DateRange,
} from '@matharumanpreet00/react-daterange-picker';
import Switch from 'rc-switch';
import 'rc-switch/assets/index.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Colxx } from '../../../components/common/CustomBootstrap';
import { isAnalyticsRC, isAnalyticsRBD } from '../../../helpers/Roles';
import Select from 'react-select';
import CustomSelectInput from '../../../components/common/CustomSelectInput';
import IntlMessages from '../../../helpers/IntlMessages';

const ContentComponent = (props) => {
    const [state, setState] = useState({
        open: false,
    });

    useEffect(() => {
        scrollDetect();
    }, []);

    function scrollDetect() {
        window.onscroll = function () {
            if (window.scrollY && window.scrollY > 200) {
                setState({
                    ...state,
                    open: false,
                });
            }
        };
    }

    const getDateLabel = (dates) => {
        let date_start = moment(dates.startDate).clone().startOf('days');
        let date_end = moment(dates.endDate).clone().startOf('days');

        if (
            date_start.isSame(moment().startOf('days')) &&
            date_end.isSame(moment().startOf('days'))
        ) {
            return 'Hôm nay';
        } else if (
            date_start.isSame(moment().subtract(1, 'days').startOf('days')) &&
            date_end.isSame(moment().subtract(1, 'days').startOf('days'))
        ) {
            return 'Hôm qua';
        } else if (
            date_start.isSame(moment().subtract(6, 'days').startOf('days')) &&
            date_end.isSame(moment().startOf('days'))
        ) {
            return '7 ngày gần đây';
        } else if (
            date_start.isSame(moment().startOf('isoWeeks').startOf('days')) &&
            date_end.isSame(moment().endOf('isoWeeks').startOf('days'))
        ) {
            return 'Tuần này';
        } else if (
            date_start.isSame(
                moment()
                    .subtract(1, 'weeks')
                    .startOf('isoWeeks')
                    .startOf('days')
            ) &&
            date_end.isSame(
                moment().subtract(1, 'weeks').endOf('isoWeeks').startOf('days')
            )
        ) {
            return 'Tuần trước';
        } else if (
            date_start.isSame(moment().subtract(30, 'days').startOf('days')) &&
            date_end.isSame(moment().startOf('days'))
        ) {
            return '30 ngày gần đây';
        } else if (
            date_start.isSame(moment().startOf('months').startOf('days')) &&
            date_end.isSame(moment().startOf('days'))
        ) {
            return 'Tháng này';
        } else if (
            date_start.isSame(
                moment().subtract(1, 'months').startOf('months').startOf('days')
            ) &&
            date_end.isSame(
                moment().subtract(1, 'months').endOf('months').startOf('days')
            )
        ) {
            return 'Tháng trước';
        } else {
            return `${date_start.format('YYYY-MM-DD')} đến ${date_end.format(
                'YYYY-MM-DD'
            )}`;
        }
    };

    const onSelectDropdown = (dates) => {
        if (!isAnalyticsRBD()) return false;

        let date_start = moment();
        let date_end = moment();
        let all = false;
        let dateLabel = '';

        switch (dates.label) {
            case 'Hôm nay':
                dateLabel = dates.label;
                break;
            case 'Hôm qua':
                date_start = moment().subtract(1, 'days').startOf('days');
                date_end = moment().subtract(1, 'days').startOf('days');
                dateLabel = dates.label;
                break;
            case '7 ngày gần đây':
                date_start = moment().subtract(6, 'days').startOf('days');
                date_end = moment().startOf('days');
                dateLabel = dates.label;
                break;
            case 'Tuần này':
                date_start = moment().startOf('isoWeeks').startOf('days');
                date_end = moment().endOf('isoWeeks').startOf('days');
                dateLabel = dates.label;
                break;
            case 'Tuần trước':
                date_start = moment()
                    .subtract(1, 'weeks')
                    .startOf('isoWeeks')
                    .startOf('days');
                date_end = moment()
                    .subtract(1, 'weeks')
                    .endOf('isoWeeks')
                    .startOf('days');
                dateLabel = dates.label;
                break;
            case '30 ngày gần đây':
                date_start = moment().subtract(30, 'days').startOf('days');
                date_end = moment().startOf('days');
                dateLabel = dates.label;
                break;
            case 'Tháng này':
                date_start = moment().startOf('months').startOf('days');
                date_end = moment().startOf('days');
                dateLabel = dates.label;
                break;
            case 'Tháng trước':
                date_start = moment()
                    .subtract(1, 'months')
                    .startOf('months')
                    .startOf('days');
                date_end = moment()
                    .subtract(1, 'months')
                    .endOf('months')
                    .startOf('days');
                dateLabel = dates.label;
                break;
            case 'Tất cả':
                all = true;
                date_start = moment(dates.startDate).startOf('days');
                date_end = moment(dates.endDate).startOf('days');
                dateLabel = dates.label;
                break;
            default:
                date_start = moment(dates.startDate).startOf('days');
                date_end = moment(dates.endDate).startOf('days');
                dateLabel = getDateLabel(dates);
                break;
        }

        props.onChangeSetting({
            dateRange: dates,
            dateLabel,
            all,
            date_start,
            date_end,
            compare: null,
        });
    };

    const onRequestRangeDate = ({ date_start, date_end, all }) => {
        if (!isAnalyticsRBD()) return false;

        props.onChangeDate({ date_start, date_end, all });
    };

    const onToggleCompare = () => {
        if (!isAnalyticsRC()) return false;

        props.onToggleSetting('compare', !props.setting.compare);
    };

    const toggleTooltip = () => {
        setState({ ...state, open: !state.open });
    };

    const onSubmitRequest = () => {
        if (!isAnalyticsRBD()) return false;

        onRequestRangeDate({
            all: props.setting.all,
            date_start: props.setting.date_start,
            date_end: props.setting.date_end,
        });

        setState({
            ...state,
            open: false,
        });
    };

    return (
        <Row className='date-picker plr-sm-above' id='picker'>
            <Colxx xxs='8'>
                <Select
                    components={{ Input: CustomSelectInput }}
                    className='react-select theme-select left-0'
                    classNamePrefix='react-select'
                    name='type'
                    placeholder={<IntlMessages id='companies.unset' />}
                    options={props.data_companies.data}
                    onChange={(e) => {
                        props.onChangeCompanies(e);
                    }}
                    value={props.data_companies.data.find(
                        (_item) => _item.value === props.data_companies.selected
                    )}
                />
            </Colxx>
            <Colxx xxs='4'>
                {/* <div className='float-right'>
                    <Button outline size='sm' className='mr-2'>
                        Hôm nay
                    </Button>
                    <Button outline size='sm' className='mr-2'>
                        7 ngày qua
                    </Button>
                    <Button outline size='sm' className='mr-2'>
                        1 tháng qua
                    </Button>
                    <Button outline size='sm' className='mr-2'>
                        Ngày
                    </Button>
                    <Button outline size='sm' className='mr-2'>
                        Tháng
                    </Button>
                    <Button outline size='sm' className=''>
                        Tuỳ chỉnh
                    </Button>
                </div> */}
                <a
                    href='#section'
                    id='datePicker'
                    className='d-flex justify-content-center align-items-center theme-button'
                    onClick={toggleTooltip}
                >
                    {' '}
                    <i className='simple-icon-calendar mr-3' />{' '}
                    <span className='text-one mr-3 d-none d-md-flex'>
                        {props.setting.dateLabel}
                    </span>{' '}
                    <i className='simple-icon-arrow-down text-small' />{' '}
                </a>
                <Popover
                    isOpen={state.open}
                    toggle={toggleTooltip}
                    placement='right-start'
                    target='datePicker'
                    trigger='click'
                    container={document.getElementById('picker')}
                    className='custom-tooltip-date-picker'
                >
                    <PopoverBody className='custom-tooltip-date-picker__popover-body'>
                        <PerfectScrollbar
                            options={{
                                suppressScrollX: true,
                                wheelPropagation: true,
                            }}
                        >
                            <div className='p-2'>
                                <div className='custom-daterange__container'>
                                    <div className='date-label mb-2'>
                                        <DateRangePicker
                                            initialDateRange={
                                                props.setting.dateRange
                                            }
                                            open={true}
                                            onChange={(range) =>
                                                onSelectDropdown(range)
                                            }
                                            definedRanges={[
                                                {
                                                    label: 'Hôm nay',
                                                    startDate: moment(),
                                                    endDate: moment(),
                                                },
                                                {
                                                    label: 'Hôm qua',
                                                    startDate:
                                                        moment().subtract(
                                                            1,
                                                            'days'
                                                        ),
                                                    endDate: moment().subtract(
                                                        1,
                                                        'days'
                                                    ),
                                                },
                                                {
                                                    label: '7 ngày gần đây',
                                                    startDate:
                                                        moment().subtract(
                                                            6,
                                                            'days'
                                                        ),
                                                    endDate: moment(),
                                                },
                                                {
                                                    label: 'Tuần này',
                                                    startDate:
                                                        moment().startOf(
                                                            'isoWeeks'
                                                        ),
                                                    endDate:
                                                        moment().endOf(
                                                            'isoWeeks'
                                                        ),
                                                },
                                                {
                                                    label: 'Tuần trước',
                                                    startDate: moment()
                                                        .subtract(1, 'weeks')
                                                        .startOf('isoWeeks'),
                                                    endDate: moment()
                                                        .subtract(1, 'weeks')
                                                        .endOf('isoWeeks'),
                                                },
                                                {
                                                    label: '30 ngày gần đây',
                                                    startDate:
                                                        moment().subtract(
                                                            30,
                                                            'days'
                                                        ),
                                                    endDate: moment().subtract(
                                                        1,
                                                        'days'
                                                    ),
                                                },
                                                {
                                                    label: 'Tháng này',
                                                    startDate:
                                                        moment().startOf(
                                                            'months'
                                                        ),
                                                    endDate: moment(),
                                                },
                                                {
                                                    label: 'Tháng trước',
                                                    startDate: moment()
                                                        .subtract(1, 'months')
                                                        .startOf('months'),
                                                    endDate: moment()
                                                        .subtract(1, 'months')
                                                        .endOf('months'),
                                                },
                                                {
                                                    label: 'Tất cả',
                                                    startDate: moment().add(
                                                        1,
                                                        'days'
                                                    ),
                                                    endDate: moment().add(
                                                        1,
                                                        'days'
                                                    ),
                                                },
                                            ]}
                                        />
                                        <div className='d-flex justify-content-between align-items-center mt-3'>
                                            <span className='switch-compare'>
                                                <span onClick={onToggleCompare}>
                                                    So sánh
                                                </span>
                                                <Switch
                                                    disabled={!isAnalyticsRC()}
                                                    onChange={onToggleCompare}
                                                    checked={
                                                        props.setting.compare
                                                    }
                                                    className='custom-switch custom-switch-primary custom-switch-small'
                                                />
                                            </span>
                                            <span className='mr-2'>
                                                <Button
                                                    outline
                                                    color='primary'
                                                    size='xs'
                                                    onClick={onSubmitRequest}
                                                >
                                                    Cập nhật
                                                </Button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PerfectScrollbar>
                    </PopoverBody>
                </Popover>
            </Colxx>
        </Row>
    );
};

export default ContentComponent;
