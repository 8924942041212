import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// import TopnavPaymenter from '../containers/navs/TopnavPaymenter';
// import Sidebar from '../containers/navs/Sidebar';

class AppLayout extends Component {
    render() {
        const { containerClassnames } = this.props;
        return (
            <div id='app-container' className={containerClassnames}>
                {/* <TopnavPaymenter history={this.props.history} /> */}
                <main className='main-no-sidebar main-kitchen'>
                    <div className='container-fluid'>{this.props.children}</div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = ({ menu }) => {
    const { containerClassnames } = menu;
    return { containerClassnames };
};
const mapActionToProps = {};

export default withRouter(
    connect(mapStateToProps, mapActionToProps)(AppLayout)
);
