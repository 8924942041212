import React from 'react'
import {
  FormGroup,
  Label,
} from 'reactstrap'
import {
  Text,
  Textarea,
  Number,
  Date,
  URL,
  Boolean,
  Phone,
} from '../../../components/dynamic-form'
import moment from 'moment'

const ContentComponent = (props) => {
  return props.data && props.data.length ? (
    props.data.map((_item, idx) => {
      return (
        <FormGroup key={_item.id}>
          <Label for={_item.id}>
            {_item.label}
          </Label>
          {_item.type === "text" ? <Text name={_item.id} value={props.values[_item.id] || ""} onChange={props.onChange} {...props} /> : null}
          {_item.type === "textarea" ? <Textarea name={_item.id} value={props.values[_item.id] || ""} onChange={props.onChange}  {...props} /> : null}
          {_item.type === "number" ? <Number name={_item.id} value={props.values[_item.id] || ""} onChange={props.onChange}  {...props} /> : null}
          {_item.type === "date" ? <Date name={_item.id} value={props.values[_item.id] ? moment(props.values[_item.id]) : moment()} onChange={props.onChange}  {...props} /> : null}
          {_item.type === "url" ? <URL name={_item.id} value={props.values[_item.id] || ""} onChange={props.onChange}  {...props} /> : null}
          {_item.type === "boolean" ? <Boolean name={_item.id} value={props.values[_item.id]} onChange={props.onChange}  {...props} /> : null}
          {_item.type === "phone" ? <Phone name={_item.id} value={props.values[_item.id] || ""} onChange={props.onChange}  {...props} /> : null}
        </FormGroup>
      )
    })
  ) : null;
}

export default ContentComponent;