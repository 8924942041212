import React, { Component } from "react";
import { Nav, TabContent, TabPane, CardHeader, NavItem, FormGroup, Input, Label } from "reactstrap";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import IntlMessages from "../../../../helpers/IntlMessages";
import { getAvatar, getUnSeen } from "../../../../helpers/Utils";
import ApplicationMenu from "../../../../components/common/ApplicationMenu";
import { PreviewContent } from '../Utils'

class ChatApplicationMenu extends Component {

  toggleAppMenu = tab => {
    if (this.props.activeTab !== tab) {
      this.props.toggleAppMenu(tab)
    }
    if (tab === "messages") {
      this.props.handleSearchContact("");
    }
  };

  // SEARCH
  handleSearchContactKeypress = e => {
    if (e.key === "Enter") {
      this.props.handleSearchContact(this.props.searchKey);
    }
  }

  handleConversationClick = (e, selectedUser) => {
    this.props.changeConversation(selectedUser);
    this.props.handleSearchContact("");
  }

  render() {

    let {
      inboxsData,
      allUsersData,
    } = this.props;

    return (
      <ApplicationMenu>
        <CardHeader className="pl-0 pr-0">
          <Nav tabs className="card-header-tabs ml-0 mr-0">
            <NavItem className="w-50 text-center">
              <NavLink
                className={classnames({
                  active: this.props.activeTab === "messages",
                  "nav-link": true
                })}
                onClick={() => {
                  this.toggleAppMenu("messages");
                }}
                to="#"
              >
                <IntlMessages id="chat.messages-inbox" />
              </NavLink>
            </NavItem>
            <NavItem className="w-50 text-center">
              <NavLink
                className={classnames({
                  active: this.props.activeTab === "contacts",
                  "nav-link": true
                })}
                onClick={() => {
                  this.toggleAppMenu("contacts");
                }}
                to="#"
              >
                <IntlMessages id="chat.messages-new" />
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>

        {/* BROADCAST */}
        <div className="pt-4 pr-4 pl-4 pb-0">
          <div className="form-group">
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={this.props.checkboxCheckall}
                  onChange={() => this.props.toggleCheckboxCheckAll()} /> Gửi đến tất cả cửa hàng
              </Label>
            </FormGroup>
          </div>
        </div>

        {/* SEARCH */}
        <div className="pt-4 pr-4 pl-4 pb-0">
          <div className="form-group">
            <input
              type="text"
              className="form-control rounded"
              placeholder={"Tìm kiếm"}
              value={this.props.searchKey}
              onChange={e => this.props.handleSearchContact(e.target.value)}
              onKeyPress={e => this.handleSearchContactKeypress(e)}
            />
          </div>
        </div>

        <TabContent
          activeTab={this.props.activeTab}
          className="chat-app-tab-content"
        >
          <TabPane tabId="messages" className="chat-app-tab-pane">
            <PerfectScrollbar
              options={{ suppressScrollX: true, wheelPropagation: false }}
            >
              <div className="pt-2 pr-4 pl-4 pb-2">
                {inboxsData.map((user, index) => {
                  return (
                    <div
                      key={index}
                      className="d-flex flex-row mb-1 border-bottom pb-3 mb-3"
                    >
                      <NavLink
                        className="d-flex"
                        to="#"
                        onClick={e =>
                          this.handleConversationClick(e, user)
                        }
                      >
                        <img
                          alt={"#"}
                          src={getAvatar(user.avatar)}
                          className="message-avatar img-thumbnail border-0 rounded-circle align-self-center xsmall"
                        />
                        <div className="d-flex flex-grow-1 min-width-zero">
                          <div className="pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                            <div className={classnames("min-width-zero pad-10px", {
                              unseen: !getUnSeen(user),
                            })}>
                              <p className=" mb-0 truncate">
                                {`${user.name}`}
                                <span className={classnames("text-small pl-10px", {
                                  "text-muted": getUnSeen(user),
                                })}>
                                  {`${user.date + " - " + user.time}`}
                                </span>
                              </p>
                              <p className={classnames("mb-1 text-small", {
                                "text-muted": getUnSeen(user),
                              })}>
                                <PreviewContent
                                  content={user.message}
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  );
                })}
              </div>
            </PerfectScrollbar>
          </TabPane>
          <TabPane tabId="contacts" className="chat-app-tab-pane">
            <PerfectScrollbar
              options={{ suppressScrollX: true, wheelPropagation: false }}
            >
              <div className="pt-2 pr-4 pl-4 pb-2">
                {allUsersData.map((user, index) => {
                  return (
                    <div
                      key={index}
                      className="d-flex flex-row mb-1 border-bottom pb-3 mb-3"
                    >
                      <NavLink
                        className="d-flex"
                        to="#"
                        onClick={e =>
                          this.handleConversationClick(e, user)
                        }
                      >
                        <img
                          alt={"#"}
                          src={getAvatar(user.avatar)}
                          className="message-avatar img-thumbnail border-0 rounded-circle align-self-center xsmall"
                        />
                        <div className="d-flex flex-grow-1 min-width-zero">
                          <div className="pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                            <div className="min-width-zero pad-10px">
                              <p className=" mb-0 truncate">
                                {user.name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  );
                })}
              </div>
            </PerfectScrollbar>
          </TabPane>
        </TabContent>
      </ApplicationMenu>
    );
  }
}

export default ChatApplicationMenu