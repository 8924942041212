import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import message from './message';

const Applications = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/`} />
      <Route path={`${match.url}/dashboards`} component={message} />
      <Redirect to="/error" />
    </Switch>
  </div>
);
export default Applications;
