export default {
  CREATED: 111,
  PENDING: 222,
  CLOSED: 333,
  COMPLETED: 444,
  PAYMENTED: 555,
  TABLE_EMPTY: 666,
  TABLE_HAS_GUEST: 777,
  TABLE_ORDERED: 888,
  TABLE_PAYMENTED: 999,
  TABLE_MORE_ORDERED: 1000,
  DESTROY: 1111,
  VMASS: 'vmass',
}