export const ROLE_PERMISSION = new Map();
ROLE_PERMISSION.set('C', 'Thêm')
ROLE_PERMISSION.set('R', 'Xem')
ROLE_PERMISSION.set('U', 'Sửa')
ROLE_PERMISSION.set('D', 'Xóa')
ROLE_PERMISSION.set('F', 'Toàn quyền sử dụng')
ROLE_PERMISSION.set('RBD', 'Xem ngày cụ thể')
ROLE_PERMISSION.set('RC', 'Xem và so sánh')
ROLE_PERMISSION.set('ACE', 'Administrator')


export const isRorF = (role) => {
    const ROLESETTINGS = localStorage.getItem("roleSettings")
        ? JSON.parse(localStorage.getItem("roleSettings"))
        : null

    if (!ROLESETTINGS) return true;

    const find_role = ROLESETTINGS.find(_role => _role.role_alias === role && (_role.permission === "R" || _role.permission === "F"));

    if (find_role) return true;

    return false;
}

export const isRorRBD = (role) => {
    const ROLESETTINGS = localStorage.getItem("roleSettings")
        ? JSON.parse(localStorage.getItem("roleSettings"))
        : null

    if (!ROLESETTINGS) return true;

    const find_role = ROLESETTINGS.find(_role => _role.role_alias === role && (_role.permission === "R" || _role.permission === "RBD"));

    if (find_role) return true;

    return false;
}

export const isRorRBDorRC = (role) => {
    const ROLESETTINGS = localStorage.getItem("roleSettings")
        ? JSON.parse(localStorage.getItem("roleSettings"))
        : null

    if (!ROLESETTINGS) return true;

    const find_role = ROLESETTINGS.find(_role => _role.role_alias === role && (_role.permission === "R" || _role.permission === "RBD" || _role.permission === "RC"));

    if (find_role) return true;

    return false;
}

export const isC = (role) => {
    const ROLESETTINGS = localStorage.getItem("roleSettings")
        ? JSON.parse(localStorage.getItem("roleSettings"))
        : null

    if (!ROLESETTINGS) return true;

    const find_role = ROLESETTINGS.find(_role => _role.role_alias === role && _role.permission === "C");

    if (find_role) return true;

    return false;
}

export const isR = (role) => {
    const ROLESETTINGS = localStorage.getItem("roleSettings")
        ? JSON.parse(localStorage.getItem("roleSettings"))
        : null

    if (!ROLESETTINGS) return true;

    const find_role = ROLESETTINGS.find(_role => _role.role_alias === role && _role.permission === "R");

    if (find_role) return true;

    return false;
}

export const isU = (role) => {
    const ROLESETTINGS = localStorage.getItem("roleSettings")
        ? JSON.parse(localStorage.getItem("roleSettings"))
        : null

    if (!ROLESETTINGS) return true;

    const find_role = ROLESETTINGS.find(_role => _role.role_alias === role && _role.permission === "U");

    if (find_role) return true;

    return false;
}

export const isD = (role) => {
    const ROLESETTINGS = localStorage.getItem("roleSettings")
        ? JSON.parse(localStorage.getItem("roleSettings"))
        : null

    if (!ROLESETTINGS) return true;

    const find_role = ROLESETTINGS.find(_role => _role.role_alias === role && _role.permission === "D");

    if (find_role) return true;

    return false;
}

export const isF = (role) => {
    const ROLESETTINGS = localStorage.getItem("roleSettings")
        ? JSON.parse(localStorage.getItem("roleSettings"))
        : null

    if (!ROLESETTINGS) return true;

    const find_role = ROLESETTINGS.find(_role => _role.role_alias === role && _role.permission === "F");

    if (find_role) return true;

    return false;
}

export const isAnalyticsRC = () => {
    const ROLESETTINGS = localStorage.getItem("roleSettings")
        ? JSON.parse(localStorage.getItem("roleSettings"))
        : null

    if (!ROLESETTINGS) return true;

    const find_analytics_debt = ROLESETTINGS.find(_role => _role.role_alias === "analytics_debt" && _role.permission === "RC");
    const find_analytics_income = ROLESETTINGS.find(_role => _role.role_alias === "analytics_income" && _role.permission === "RC");
    const find_analytics_make_product = ROLESETTINGS.find(_role => _role.role_alias === "analytics_make_product" && _role.permission === "RC");
    const find_analytics_order_payment = ROLESETTINGS.find(_role => _role.role_alias === "analytics_order_payment" && _role.permission === "RC");
    const find_analytics_outcome = ROLESETTINGS.find(_role => _role.role_alias === "analytics_outcome" && _role.permission === "RC");
    const find_analytics_stock_logs = ROLESETTINGS.find(_role => _role.role_alias === "analytics_stock_logs" && _role.permission === "RC");
    const find_analytics_table = ROLESETTINGS.find(_role => _role.role_alias === "analytics_table" && _role.permission === "RC");

    if (find_analytics_debt
        || find_analytics_income
        || find_analytics_make_product
        || find_analytics_order_payment
        || find_analytics_outcome
        || find_analytics_stock_logs
        || find_analytics_table
    ) {
        return true;
    }

    return false;
}

export const isAnalyticsRBD = () => {
    const ROLESETTINGS = localStorage.getItem("roleSettings")
        ? JSON.parse(localStorage.getItem("roleSettings"))
        : null

    if (!ROLESETTINGS) return true;

    const find_analytics_debt = ROLESETTINGS.find(_role => _role.role_alias === "analytics_debt" && _role.permission === "RBD");
    const find_analytics_income = ROLESETTINGS.find(_role => _role.role_alias === "analytics_income" && _role.permission === "RBD");
    const find_analytics_make_product = ROLESETTINGS.find(_role => _role.role_alias === "analytics_make_product" && _role.permission === "RBD");
    const find_analytics_order_payment = ROLESETTINGS.find(_role => _role.role_alias === "analytics_order_payment" && _role.permission === "RBD");
    const find_analytics_outcome = ROLESETTINGS.find(_role => _role.role_alias === "analytics_outcome" && _role.permission === "RBD");
    const find_analytics_stock_logs = ROLESETTINGS.find(_role => _role.role_alias === "analytics_stock_logs" && _role.permission === "RBD");
    const find_analytics_table = ROLESETTINGS.find(_role => _role.role_alias === "analytics_table" && _role.permission === "RBD");

    if (find_analytics_debt
        || find_analytics_income
        || find_analytics_make_product
        || find_analytics_order_payment
        || find_analytics_outcome
        || find_analytics_stock_logs
        || find_analytics_table
    ) {
        return true;
    }

    return false;
}