import React, { Fragment, useState } from 'react';
import { FormGroup, Input, Label, Row, Table, Badge } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import TooltipItem from '../../../components/common/TooltipItem';
// import IntlMessages from '../../../helpers/IntlMessages';
import { AddCommaNumber, SetItemsSelected } from '../../../helpers/Utils';

const ListOrders = ({
    orders,
    onHandlerDeleteOrderPending,
    incrementItem,
    decrementItem,
    cashs,
    cashsPay,
    cashFill,
    totalPay,
    decrementCount,
    totalCustomerPay,
    discount_form,
    removeOrders,
    // onRemoveOrder,
    getDiscountOrderNote,
    // getDiscountOrderType,
    // getDiscountOrderValue,
    getTotalDiscountPrice,
    calTotalPrice,
    handleToggleModalDiscountTaxFee,
    // handleToggleModalDiscount,
    tax_fee,
    // handleToggleModalTaxFee,
    getTotalTaxFeePrice,
    fee_other,
    // handleToggleModalFeeOther,
    getTotalFeeOtherPrice,
    isChecked,
    onHandleChecked,
    handleRemoveValueModal,
    itemsChange,
}) => {
    let total_discount_price = getTotalDiscountPrice(orders, discount_form);
    let total_taxfee_price = getTotalTaxFeePrice(orders, tax_fee);
    let total_fee_other_price = getTotalFeeOtherPrice(orders, fee_other);
    const totalPayment =
        calTotalPrice(orders) -
        total_discount_price +
        (total_taxfee_price ? total_taxfee_price : 0) +
        (total_fee_other_price ? total_fee_other_price : 0);
    // console.log('orders: ', orders);
    // console.log('discount_form: ', discount_form);
    // console.log('cashsPay: ', cashsPay);

    let find_item_discount = orders.find(
        (order) => order.discount && order.discount.value
    );

    const refundToCustomer =
        total_discount_price > 0 ||
        total_taxfee_price > 0 ||
        total_fee_other_price > 0
            ? AddCommaNumber(
                  totalCustomerPay(cashsPay) -
                      calTotalPrice(orders) +
                      total_discount_price -
                      total_taxfee_price -
                      total_fee_other_price
              )
            : AddCommaNumber(
                  totalCustomerPay(cashsPay) - calTotalPrice(orders)
              );

    const [missingAmountTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Số tiền còn thiếu sẽ được ghi nhận vào "Công nợ của khách hàng". Hãy điền tên và số điện thoại của khách hàng vào ô dưới.',
    });

    const [donePayTooltip] = useState({
        placement: 'left',
        text: '',
        body: 'Khi tick vào ô này. Tổng hoá đơn sẽ được ghi nhận vào "Công nợ của khách hàng". Hãy điền tên và số điện thoại của khách hàng vào ô dưới.',
    });

    const [discountTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Số tiền này sẽ được trừ với ô Tổng số tiền.',
    });

    const [taxFeeTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Số tiền này sẽ được cộng với ô Tổng số tiền.',
    });

    const [feeOtherTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Số tiền này sẽ được cộng với ô Tổng số tiền.',
    });

    return (
        <Table className='table mb-0'>
            <thead>
                <tr>
                    <th className='text-muted text-extra-small mb-2'>#</th>
                    <th className='text-muted text-extra-small mb-2'>
                        Tên sản phẩm
                    </th>
                    <th className='text-muted text-extra-small mb-2 text-center'>
                        Số lượng
                    </th>
                    <th className='text-center text-muted text-extra-small mb-2'>
                        Đơn giá
                    </th>
                    {/* {(discount_form &&
                        discount_form.saved &&
                        discount_form.value &&
                        discount_form.type) ||
                    find_item_discount ? null : ( */}
                    <th className='text-center text-muted text-extra-small mb-2'>
                        Thành tiền
                    </th>
                    {/* )} */}
                    {/* {(discount_form &&
                        discount_form.saved &&
                        discount_form.value &&
                        discount_form.type) ||
                    find_item_discount ? (
                        <th className='text-center text-muted text-extra-small mb-2'>
                            Chiết khấu
                        </th>
                    ) : null} */}
                    {/* <th className="text-right text-muted text-extra-small mb-2">
                            Chi tiết
                        </th> */}
                    <th
                        className='text-right text-muted text-extra-small mb-2 cursor-pointer'
                        onClick={removeOrders}
                    >
                        Xóa
                    </th>
                </tr>
            </thead>
            <tbody>
                {!orders.length ? (
                    <tr>
                        <td colSpan={6}>
                            <span className='d-flex justify-content-center align-items-center p-3 text-muted empty-orders'>
                                Chưa có sản phẩm nào được đặt!
                            </span>
                        </td>
                    </tr>
                ) : orders?.map((order, idx) => {
                    // console.log('order: ', order);
                    // console.log(
                    //     'sugarSelectedValue: ',
                    //     typeof order.sugarSelectedValue
                    // );
                    return (
                        <Fragment key={idx}>
                            <tr>
                                <td>{idx + 1}</td>
                                <td
                                    className={
                                        itemsChange?.length
                                            ? 'cursor-default'
                                            : 'cursor-body-menu'
                                    }
                                    onClick={
                                        itemsChange?.length
                                            ? null
                                            : () => incrementItem(order)
                                    }
                                >
                                    {order?.name
                                        ? order.name
                                        : order?.product?.name}
                                </td>
                                <td
                                    className={
                                        itemsChange?.length
                                            ? 'text-center cursor-default'
                                            : 'text-center cursor-pointer'
                                    }
                                    onClick={
                                        itemsChange?.length
                                            ? null
                                            : () => decrementItem(order)
                                    }
                                >
                                    {order.count}
                                </td>
                                <td className='text-center'>
                                    {AddCommaNumber(parseInt(order.price))}
                                </td>
                                {/* {(discount_form &&
                                    discount_form.saved &&
                                    discount_form.value &&
                                    discount_form.type) ||
                                find_item_discount ? null : ( */}
                                <td className='text-center'>
                                    {AddCommaNumber(
                                        parseInt(order.price) * order.count
                                    )}
                                </td>
                                {/* )} */}

                                {/* {(discount_form &&
                                    discount_form.saved &&
                                    discount_form.value &&
                                    discount_form.type) ||
                                (order.discount &&
                                    order.discount.type &&
                                    order.discount.value) ? ( */}
                                {/* <td className='text-center'>
                                        {`${AddCommaNumber(
                                            getDiscountOrderValue(
                                                orders,
                                                discount_form,
                                                order
                                            )
                                        )}${getDiscountOrderType(
                                            discount_form,
                                            order
                                        )}`}
                                    </td> */}
                                {/* ) : null} */}
                                <td
                                    className='text-right cursor-pointer'
                                    onClick={() =>
                                        onHandlerDeleteOrderPending(order, idx)
                                    }
                                >
                                    <i className='simple-icon-trash' />
                                </td>
                            </tr>
                            {(order.iceSelectedValue === '100' ||
                                order.iceSelectedValue === undefined) &&
                            (order.sugarSelectedValue === '100' ||
                                order.sugarSelectedValue === undefined) &&
                            (order.sizeValue === 0 || !order.sizeValue) &&
                            !order?.toppingName?.length ? null : (
                                <tr>
                                    <td className='border-top-none'></td>
                                    <td colSpan={5} className='pl-0'>
                                        {order.sizeValue === 0 ||
                                        !order.sizeValue ? null : (
                                            <span>
                                                <Badge
                                                    className='mr-1'
                                                    color='warning'
                                                >
                                                    {`${order.sizeName}`}
                                                </Badge>
                                            </span>
                                        )}

                                        {order.iceSelectedValue === '100' ||
                                        order.iceSelectedValue ===
                                            undefined ? null : (
                                            <Badge
                                                className='mr-1'
                                                color='primary'
                                            >
                                                Đá: {order.iceSelectedValue}%
                                            </Badge>
                                        )}
                                        {order.sugarSelectedValue === '100' ||
                                        order.sugarSelectedValue ===
                                            undefined ? null : (
                                            <Badge
                                                className='mr-1'
                                                color='info'
                                            >
                                                Độ ngọt:{' '}
                                                {order.sugarSelectedValue}%
                                            </Badge>
                                        )}

                                        {!order?.toppingName?.length
                                            ? null
                                            : order.toppingName.map(
                                                  (_topping, idx) => (
                                                      <Badge
                                                          color='success'
                                                          className='mr-1'
                                                          key={idx}
                                                      >
                                                          {_topping}
                                                      </Badge>
                                                  )
                                              )}
                                    </td>
                                </tr>
                            )}
                        </Fragment>
                    );
                })}
                {discount_form && discount_form.saved && discount_form.note ? (
                    <tr>
                        <td className='discount-note no-print' colSpan={5}>
                            {`* Chiết khấu: ${getDiscountOrderNote(
                                discount_form,
                                ''
                            )}`}
                        </td>
                    </tr>
                ) : null}
                {tax_fee && tax_fee.saved && tax_fee.note ? (
                    <tr>
                        <td className='discount-note no-print' colSpan={5}>
                            {`* Thuế phí: ${getDiscountOrderNote(tax_fee, '')}`}
                        </td>
                    </tr>
                ) : null}
                {fee_other && fee_other.saved && fee_other.note ? (
                    <tr>
                        <td className='discount-note no-print' colSpan={5}>
                            {`* Phí khác: ${getDiscountOrderNote(
                                fee_other,
                                ''
                            )}`}
                        </td>
                    </tr>
                ) : null}
                <tr>
                    <td colSpan='6' className='p-0 pt-3 pb-3'>
                        <table className='table table-borderless background-color-none mb-0'>
                            <tbody>
                                <tr>
                                    <td colSpan='4' className='text-right p-1'>
                                        Tổng số tiền:
                                    </td>
                                    <td
                                        colSpan='2'
                                        className='text-center text-bold p-1'
                                    >
                                        <strong>
                                            {AddCommaNumber(
                                                calTotalPrice(orders)
                                            )}
                                        </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='4' className='text-right p-1'>
                                        Chiết khấu:
                                        <TooltipItem
                                            item={discountTooltip}
                                            id={22}
                                        />
                                    </td>
                                    <td
                                        colSpan='2'
                                        className='text-center text-bold p-1 cursor-pointer'
                                        onClick={
                                            discount_form.saved
                                                ? () =>
                                                      handleRemoveValueModal(
                                                          'discount'
                                                      )
                                                : handleToggleModalDiscountTaxFee
                                        }
                                    >
                                        {discount_form &&
                                        discount_form.saved &&
                                        discount_form.value &&
                                        discount_form.type ? (
                                            AddCommaNumber(total_discount_price)
                                        ) : (
                                            <i className='simple-icon-plus text-primary' />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='4' className='text-right p-1'>
                                        Thuế phí:
                                        <TooltipItem
                                            item={taxFeeTooltip}
                                            id={23}
                                        />
                                    </td>
                                    <td
                                        colSpan='2'
                                        className='text-center text-bold p-1 cursor-pointer'
                                        onClick={
                                            tax_fee.saved
                                                ? () =>
                                                      handleRemoveValueModal(
                                                          'tax_fee'
                                                      )
                                                : handleToggleModalDiscountTaxFee
                                        }
                                    >
                                        {tax_fee &&
                                        tax_fee.saved &&
                                        tax_fee.value &&
                                        tax_fee.type ? (
                                            AddCommaNumber(total_taxfee_price)
                                        ) : (
                                            <i className='simple-icon-plus text-primary' />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='4' className='text-right p-1'>
                                        Phí khác:
                                        <TooltipItem
                                            item={feeOtherTooltip}
                                            id={24}
                                        />
                                    </td>
                                    <td
                                        colSpan='2'
                                        className='text-center text-bold p-1 cursor-pointer'
                                        onClick={
                                            fee_other.saved
                                                ? () =>
                                                      handleRemoveValueModal(
                                                          'fee_other'
                                                      )
                                                : handleToggleModalDiscountTaxFee
                                        }
                                    >
                                        {fee_other &&
                                        fee_other.saved &&
                                        fee_other.value ? (
                                            AddCommaNumber(
                                                total_fee_other_price
                                            )
                                        ) : (
                                            <i className='simple-icon-plus text-primary' />
                                        )}
                                    </td>
                                </tr>
                                {(discount_form &&
                                    discount_form.saved &&
                                    discount_form.value &&
                                    discount_form.type) ||
                                (tax_fee &&
                                    tax_fee.saved &&
                                    tax_fee.value &&
                                    tax_fee.type) ||
                                (fee_other &&
                                    fee_other.saved &&
                                    fee_other.value) ||
                                find_item_discount ? (
                                    <tr>
                                        <td
                                            colSpan='4'
                                            className='text-right p-1 pt-3'
                                        >
                                            Số tiền cần thanh toán:
                                        </td>
                                        <td
                                            colSpan='3'
                                            className='text-center text-bold p-1 pt-3'
                                        >
                                            <strong>
                                                {AddCommaNumber(totalPayment)}
                                            </strong>
                                        </td>
                                    </tr>
                                ) : null}
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td colSpan='6' className='py-3'>
                        <Row className='d-flex flex-wrap justify-content-around align-items-center'>
                            {cashs.map((cash, idx) => {
                                return (
                                    <Colxx
                                        xxs='4'
                                        sm='3'
                                        key={idx}
                                        className={
                                            cashFill(cash.cash)
                                                ? 'cash-pay mr-1 px-2 py-1 bg-primary border border-primary mb-1 text-center cursor-body-menu'
                                                : 'cash-pay mr-1 px-2 py-1 border border-primary mb-1 text-center cursor-body-menu'
                                        }
                                        onClick={(e) => {
                                            totalPay(e, cash.cash);
                                        }}
                                    >
                                        {AddCommaNumber(cash.cash)}
                                        <span
                                            className={
                                                cashFill(cash.cash)
                                                    ? 'text-white d-flex'
                                                    : 'd-none'
                                            }
                                        >
                                            {cash.quantity}
                                        </span>
                                        <span
                                            className={
                                                cashFill(cash.cash)
                                                    ? 'text-white d-flex dash-icon cursor-pointer'
                                                    : 'd-none'
                                            }
                                            onClick={(e) => {
                                                decrementCount(e, cash);
                                            }}
                                        >
                                            <i className='simple-icon-minus' />
                                        </span>
                                    </Colxx>
                                );
                            })}
                        </Row>
                    </td>
                </tr>
                <tr>
                    <td colSpan='6' className='p-0 pt-3 pb-3 text-right'>
                        <table className='table table-borderless background-color-none mb-0'>
                            <tbody>
                                {cashsPay?.length > 0 ? (
                                    <>
                                        <tr>
                                            <td
                                                colSpan='4'
                                                className='text-right p-1'
                                            >
                                                Tiền khách đưa:
                                            </td>
                                            <td
                                                colSpan='2'
                                                className='text-center text-bold p-1'
                                            >
                                                {AddCommaNumber(
                                                    totalCustomerPay(cashsPay)
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                colSpan='4'
                                                className='text-right p-1'
                                            >
                                                {cashsPay.length &&
                                                refundToCustomer < 0 ? (
                                                    <>
                                                        <span>Còn thiếu: </span>
                                                        <TooltipItem
                                                            item={
                                                                missingAmountTooltip
                                                            }
                                                            id={20}
                                                        />
                                                    </>
                                                ) : (
                                                    'Tiền trả lại khách: '
                                                )}
                                            </td>
                                            <td
                                                colSpan={
                                                    discount_form &&
                                                    discount_form.saved &&
                                                    discount_form.value &&
                                                    discount_form.type
                                                        ? 3
                                                        : 2
                                                }
                                                className='text-center text-bold p-1'
                                            >
                                                {cashsPay.length ? (
                                                    <strong>
                                                        {refundToCustomer}
                                                    </strong>
                                                ) : (
                                                    0
                                                )}
                                            </td>
                                        </tr>
                                    </>
                                ) : itemsChange?.length ? null : (
                                    <tr>
                                        <td>
                                            <FormGroup
                                                check
                                                className='float-right custom-control custom-checkbox pl-1 align-self-center pr-4'
                                            >
                                                <Input
                                                    className='itemCheck mb-0'
                                                    defaultChecked={isChecked}
                                                    onClick={onHandleChecked}
                                                    id='checkInput'
                                                    name='checkInput'
                                                    type='checkbox'
                                                />
                                                <Label for='checkInput' check>
                                                    Khách hàng chưa thanh toán.
                                                    <TooltipItem
                                                        item={donePayTooltip}
                                                        id={21}
                                                    />
                                                </Label>
                                            </FormGroup>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </Table>
    );
};

export default ListOrders;
