import React, { memo } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DynamicForm from '../general/DynamicForm';

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    children,
    data_dynamic_form,
    values,
    onChange,
    setting,
    onChangeDynamicForm,
}) => {
    // console.log('render AddComponent');
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
            autoFocus={false}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        {/* {children} */}
                        <FormGroup className='mt-3 has-float-label'>
                            <Label for='name' className='text-muted'>
                                <IntlMessages id='company-provider.name' />
                            </Label>
                            <Input
                                autoFocus={true}
                                type='text'
                                name='name'
                                id='name'
                                disabled={setting.viewonly && !setting.add}
                                value={values.name}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='phone' className='text-muted'>
                                <IntlMessages id='phone' />
                            </Label>
                            <Input
                                type='text'
                                name='phone'
                                id='phone'
                                disabled={setting.viewonly && !setting.add}
                                value={values.phone}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='email' className='text-muted'>
                                <IntlMessages id='email' />
                            </Label>
                            <Input
                                type='email'
                                name='email'
                                id='email'
                                disabled={setting.viewonly && !setting.add}
                                value={values.email}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='address' className='text-muted'>
                                <IntlMessages id='address' />
                            </Label>
                            <Input
                                type='text'
                                name='address'
                                id='address'
                                disabled={setting.viewonly && !setting.add}
                                value={values.address}
                                onChange={onChange}
                            />
                        </FormGroup>
                        {/* {!setting.add ? (
        <>
          <Row>
            <Col xs="12" sm="12" md="6" lg="6">
              <FormGroup>
                <Label for="total_price_income">
                  <IntlMessages id="company-provider.total_price_income" />
                </Label>
                <Input type="text" name="total_price_income" id="total_price_income"
                  disabled={true}
                  value={AddCommaNumber(values.total_price_income)}
                  onChange={onChange}
                />
              </FormGroup>
            </Col>
            <Col xs="12" sm="12" md="6" lg="6">
              <FormGroup>
                <Label for="total_price_outcome">
                  <IntlMessages id="company-provider.total_price_outcome" />
                </Label>
                <Input type="text" name="total_price_outcome" id="total_price_outcome"
                  disabled={true}
                  value={AddCommaNumber(values.total_price_outcome)}
                  onChange={onChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="total_price_pending">
              <IntlMessages id="company-provider.total_price_pending" />
            </Label>
            <Input type="text" name="total_price_pending" id="total_price_pending"
              disabled={true}
              value={AddCommaNumber(values.total_price_pending)}
              onChange={onChange}
            />
          </FormGroup>
        </>
      ) : null} */}

                        <DynamicForm
                            values={values.dynamic_form}
                            data={data_dynamic_form}
                            onChange={onChangeDynamicForm}
                            disabled={setting.viewonly && !setting.add}
                        />
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='company-provider.btn-add' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default memo(ContentComponent);
