import React, { memo } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';

import ListRole from './ListRole';

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    values,
    setting,
    onChange,
    onChangePermission,
    isChecked,
}) => {
    // console.log('render AddComponent');
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
            autoFocus={false}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        <FormGroup>
                            <Label for='name' className='text-muted'>
                                <IntlMessages id='role.name' />
                            </Label>
                            <Input
                                autoFocus={true}
                                type='text'
                                name='name'
                                id='name'
                                disabled={setting.viewonly && !setting.add}
                                value={values.name}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ListRole
                                settings={values.settings}
                                onChangePermission={onChangePermission}
                                isChecked={isChecked}
                                disabled={setting.viewonly && !setting.add}
                            />
                        </FormGroup>
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='role.btn-add' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default memo(ContentComponent);
