import React, { Component, Fragment } from 'react';

import { END_POINT_SOCKET } from '../../constants/defaultValues';
import io from 'socket.io-client';

const ROLE_ALIAS = 'cashier';

class ContentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        // Role
        this.socket.on('update-user-role', (res) => {
            localStorage.setItem('roleSettings', JSON.stringify(res.data));
        });
    }

    render() {
        return !this.state.isLoading ? (
            <div className='loading' />
        ) : (
            <Fragment>Cashier</Fragment>
        );
    }
}

export default ContentComponent;
