
const data = [
  {
    id: "role",
    icon: "simple-icon-lock-open",
    label: "menu.role",
    to: "/app/pages/role"
  },
  {
    id: "user-role",
    icon: "simple-icon-user-follow",
    label: "menu.user-role",
    to: "/app/pages/user-role"
  },
  {
    id: "form-dynamic",
    icon: "simple-icon-note",
    label: "menu.form-dynamic",
    to: "/app/pages/form-dynamic"
  },
  {
    id: "companies",
    icon: "simple-icon-organization",
    label: "menu.companies",
    to: "/app/pages/companies"
  },
];
export default data;
