import React, { useState, useEffect } from 'react';
import {
    Row,
    Card,
    CardTitle,
    Form,
    Label,
    Input,
    Button,
    FormText,
} from 'reactstrap';
import { connect } from 'react-redux';
import { registerUser } from '../../redux/actions';
import { Colxx } from '../../components/common/CustomBootstrap';
import IntlMessages from '../../helpers/IntlMessages';
import {
    NotificatioErrController,
    NotificatioSucController,
} from '../../helpers/Utils';
import { NavLink } from 'react-router-dom';

const Register = (props) => {
    const [values, setValues] = useState({
        fullname: '',
        phone: '',
        email: '',
        password: '',
        domain: '',
        url: window.location.host.replace('register.', ''),
        popover: false,
        pinSuccessMessage: false,
    });

    const onUserRegister = () => {
        if (
            values.fullname !== '' &&
            values.phone !== '' &&
            values.email !== '' &&
            values.password !== '' &&
            values.domain !== ''
        ) {
            const rgxStore = /[^a-z0-9_]+/g;
            const match = new RegExp(rgxStore);
            if (match.test(values.domain)) {
                NotificatioErrController(
                    'Tên đăng nhập chỉ hỗ trợ chữ thường và số. Nên sử dụng tên của cửa hàng để làm tên đăng nhập!'
                );
                return;
            }

            props.registerUser(values, props.history);

            // props.history.push("/");
        } else {
            NotificatioErrController('Vui lòng nhập đầy đủ các thông tin');
            return false;
        }
    };

    const onChangeHandleInput = (e) => {
        const { value, name } = e.target;
        setValues({ ...values, [name]: value });
    };

    useEffect(() => {
        if (props.user && props.user.status && props.user.status !== 200) {
            NotificatioErrController(props.user.data.responseText);
        }

        if (props.user && props.user.status && props.user.status === 200) {
            NotificatioSucController(props.user.data.responseText);

            setValues({ ...values, pinSuccessMessage: true });
        }
    }, [props.user]);

    return (
        <Row className='h-100'>
            <Colxx xxs='12' md='10' className='mx-auto my-auto auth-card-pad'>
                <Card className='auth-card' autoFocus={false}>
                    <div className='position-relative image-side '>
                        <p className='text-white h2'>ĐĂNG KÝ</p>
                        <p className='white mb-0'>
                            Điền đầy đủ thông tin và xác nhận email để đăng ký.{' '}
                            <br />
                        </p>
                    </div>
                    <div className='form-side'>
                        <a href='https://vmass.vn/' className='white'>
                            <span className='logo-single' />
                        </a>
                        <CardTitle className='mb-4'>
                            <IntlMessages id='user.register' />
                        </CardTitle>
                        <Form>
                            <Label className='form-group has-float-label mb-4'>
                                <Input
                                    autoFocus={true}
                                    onChange={onChangeHandleInput}
                                    type='text'
                                    name='fullname'
                                    defaultValue={values.name}
                                />
                                <IntlMessages id='user.fullname' />
                            </Label>
                            <Label className='form-group has-float-label mb-4'>
                                <Input
                                    onChange={onChangeHandleInput}
                                    type='text'
                                    name='phone'
                                    defaultValue={values.phone}
                                />
                                <IntlMessages id='user.phone' />
                            </Label>
                            <Label className='form-group has-float-label mb-4'>
                                <Input
                                    onChange={onChangeHandleInput}
                                    type='email'
                                    name='email'
                                    defaultValue={values.email}
                                />
                                <IntlMessages id='user.email' />
                            </Label>
                            <Row>
                                <Colxx xxs='6' className='username'>
                                    <Label className='form-group has-float-label mb-1'>
                                        <Input
                                            id='popover'
                                            onChange={onChangeHandleInput}
                                            // onClick={() => {
                                            //     onChangeHandleInput({
                                            //         target: {
                                            //             name: 'popover',
                                            //             value: true,
                                            //         },
                                            //     });
                                            // }}
                                            // onBlur={() => {
                                            //     onChangeHandleInput({
                                            //         target: {
                                            //             name: 'popover',
                                            //             value: false,
                                            //         },
                                            //     });
                                            // }}
                                            type='text'
                                            name='domain'
                                            className={'text-end'}
                                            defaultValue={values.domain}
                                        />
                                        <IntlMessages id='user.username' />
                                    </Label>
                                    {/* <Popover
                                        className='auth-card-popover'
                                        isOpen={values.popover}
                                        target='popover'
                                        placement='right'
                                        hideArrow={true}
                                        toggle={() => {
                                            onChangeHandleInput({
                                                target: {
                                                    name: 'popover',
                                                    value: true,
                                                },
                                            });
                                        }}
                                    >
                                        <PopoverBody>
                                            <ListGroup>
                                                <ListGroupItem>
                                                    Là tên cửa hàng của bạn
                                                </ListGroupItem>
                                                <ListGroupItem>
                                                    Không trùng với tên của cửa
                                                    hàng khác đã được đăng ký
                                                </ListGroupItem>
                                                <ListGroupItem>
                                                    Viết liền không dấu
                                                </ListGroupItem>
                                                <ListGroupItem>
                                                    Không có ký tự viết in hoa
                                                </ListGroupItem>
                                            </ListGroup>
                                        </PopoverBody>
                                    </Popover> */}
                                </Colxx>
                                <span className='domain'>{`.${values.url}`}</span>
                            </Row>
                            <Row>
                                <Colxx xxs='6' className='d-flex'>
                                    <FormText className='text-small text-muted'>
                                        <i className='simple-icon-close mr-2' />
                                        Là tên cửa hàng của bạn
                                    </FormText>
                                </Colxx>
                                <Colxx xxs='6' className='d-flex'>
                                    <FormText className='text-small text-muted'>
                                        <i className='simple-icon-close mr-2' />
                                        Viết liền không dấu
                                    </FormText>
                                </Colxx>
                                <Colxx xxs='6' className='d-flex'>
                                    <FormText className='text-small text-muted'>
                                        <i className='simple-icon-close mr-2' />
                                        Không có ký tự viết in hoa
                                    </FormText>
                                </Colxx>
                                <Colxx xxs='6' className='d-flex'>
                                    <FormText className='text-small text-muted'>
                                        <i className='simple-icon-close mr-2' />
                                        Không trùng với tên của cửa hàng khác đã
                                        được đăng ký
                                    </FormText>
                                </Colxx>
                            </Row>
                            <Label className='form-group has-float-label mt-4 mb-4'>
                                <Input
                                    onChange={onChangeHandleInput}
                                    name='password'
                                    defaultValue={values.password}
                                    type='password'
                                />
                                <IntlMessages id='user.password' />
                            </Label>
                            {values.pinSuccessMessage ? (
                                <p className='success-message'>
                                    Tài khoản của bạn đã được tạo, vui lòng kiểm
                                    tra Hộp thư đến hoặc Spam để kích hoạt.
                                </p>
                            ) : null}
                            <div className='d-flex justify-content-between align-items-center'>
                                <NavLink to={`/user/login`}>
                                    Bạn đã có tài khoản? Đăng nhập ngay!
                                </NavLink>
                                <Button
                                    color='primary'
                                    className='btn-shadow'
                                    size='lg'
                                    onClick={onUserRegister}
                                >
                                    <IntlMessages id='user.register-button' />
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Card>
            </Colxx>
        </Row>
    );
};
const mapStateToProps = ({ authUser }) => {
    const { user, loading } = authUser;
    return { user, loading };
};

export default connect(mapStateToProps, {
    registerUser,
})(Register);
