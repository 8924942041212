import React from 'react';
import { Label, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentDisplayMobile = (props) => {
    // console.log(props);

    const ItemProduct = props.values?.log?.map((item) => item.name);
    const UniqueItemProduct = [...new Set(ItemProduct)];

    return (
        <>
            <Row className='mt-3' xxs='12'>
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='phone' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p>
                        {props.values.phone ? (
                            props.values.phone
                        ) : (
                            <span className='text-lowercase text-small text-muted'>
                                (Chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='company-provider.item_product' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {UniqueItemProduct.length ? (
                            UniqueItemProduct.map((item) => item)
                        ) : (
                            <span className='text-lowercase text-small text-muted'>
                                (chưa có mặt hàng nào)
                            </span>
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='3' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='email' />
                    </p>
                </Colxx>
                <Colxx xxs='9' lg='6' className='text-right'>
                    <p className='text-lowercase'>
                        {props.values.email ? (
                            props.values.email
                        ) : (
                            <span className='text-lowercase text-small text-muted'>
                                (Chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='3' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='address' />
                    </p>
                </Colxx>
                <Colxx xxs='9' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {props.values.address ? (
                            props.values.address
                        ) : (
                            <span className='text-lowercase text-small text-muted'>
                                (Chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='company-provider.total_price_income' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p className='text-lowercase'>
                        {AddCommaNumber(props.values.total_price_income)}
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='company-provider.total_price_outcome' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {AddCommaNumber(props.values.total_price_outcome)}
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='company-provider.total_price_pending' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p className='text-lowercase'>
                        {AddCommaNumber(props.values.total_price_pending)}
                    </p>
                </Colxx>
            </Row>
        </>
    );
};

export default ContentDisplayMobile;
