import React from "react";
import { ContextMenu, MenuItem } from "react-contextmenu";

const ContextMenuContainer = ({onContextMenu,onContextMenuClick}) => {
  return (
    <ContextMenu
      id="item"
      onShow={e => onContextMenu(e, e.detail.data)}
    >
      <MenuItem onClick={onContextMenuClick} data={{ action: "delete" }}>
        <i className="simple-icon-trash" /> <span>Xóa</span>
      </MenuItem>
      <MenuItem onClick={onContextMenuClick} data={{ action: "update" }}>
        <i className="simple-icon-wrench" /> <span>Sửa</span>
      </MenuItem>
    </ContextMenu>
  );
};

export default ContextMenuContainer;
