import React from 'react';
import { Label, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { GetSelectTime } from '../../../helpers/SelectTime';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentDisplayMobile = (props) => {
    // console.log(props);
    return (
        <>
            <Row className='mt-4' xxs='12'>
                <Colxx xxs='5' lg='6'>
                    <p className='text-muted text-small mb-2'>
                        <IntlMessages id='outcome.name' />
                    </p>
                </Colxx>
                <Colxx xxs='7' lg='6' className='text-right'>
                    <p>{props.values.name}</p>
                </Colxx>
                <Colxx xxs='4' lg='6'>
                    <p className='text-muted text-small mb-2'>
                        <IntlMessages id='price' />
                    </p>
                </Colxx>
                <Colxx xxs='8' lg='6' className='text-right'>
                    <p>{AddCommaNumber(props.values.value)}</p>
                </Colxx>
                <Colxx xxs='4' lg='6'>
                    <p className='text-muted text-small mb-2'>
                        <IntlMessages id='note' />
                    </p>
                </Colxx>
                <Colxx xxs='8' lg='6' className='text-right'>
                    <p className='text-lowercase'>
                        {props.values.note ? (
                            props.values.note
                        ) : (
                            <span className='text-lowercase text-small text-muted'>
                                (Chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='4' lg='6'>
                    <p className='text-muted text-small mb-2'>
                        <IntlMessages id='address' />
                    </p>
                </Colxx>
                <Colxx xxs='8' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {props.values.address ? (
                            props.values.address
                        ) : (
                            <span className='text-lowercase text-small text-muted'>
                                (Chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='4' lg='6'>
                    <p className='text-muted text-small mb-2'>
                        <IntlMessages id='time' />
                    </p>
                </Colxx>
                <Colxx xxs='8' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {GetSelectTime(props.values.time).label}
                    </p>
                </Colxx>
                <Colxx xxs='4' lg='6'>
                    <p className='text-muted text-small mb-2'>
                        <IntlMessages id='time-created' />
                    </p>
                </Colxx>
                <Colxx xxs='8' lg='6' className='text-right'>
                    <p className='text-lowercase'>{props.values.date}</p>
                </Colxx>

                <Colxx xxs='5' lg='6'>
                    <p className='text-muted text-small mb-2'>
                        <IntlMessages id='user.created' />
                    </p>
                </Colxx>
                <Colxx xxs='7' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {props.values?.user?.fullname}
                    </p>
                </Colxx>
            </Row>
        </>
    );
};

export default ContentDisplayMobile;
