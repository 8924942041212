import React, { Component } from 'react';
import { NavItem, Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classnames from 'classnames';

import ApplicationMenu from '../../../components/common/ApplicationMenu';

class UserStaffApplicationMenu extends Component {
    render() {
        // console.log(this.props);
        const { data, filter, filterData } = this.props;
        // console.log('data: ', data);

        const dataPermission = data.map((dt) => dt.permission_name);
        const uniquePermission = [...new Set(dataPermission)];

        return (
            <ApplicationMenu>
                <PerfectScrollbar
                    options={{ suppressScrollX: true, wheelPropagation: false }}
                >
                    <div className='p-4'>
                        <NavItem
                            className={classnames({ active: !filter }, 'mb-5')}
                        >
                            <NavLink
                                to='#'
                                onClick={(e) => filterData('', '')}
                                location={{}}
                                className='d-flex justify-content-between align-items-center'
                            >
                                <div className='d-flex align-items-center'>
                                    <i className='simple-icon-layers mr-10' />
                                    <span>Tất cả</span>
                                </div>
                                <span className='float-right'>
                                    {data.length}
                                </span>
                            </NavLink>
                        </NavItem>
                        <p className='text-muted text-small'>
                            Tình trạng công việc
                        </p>
                        <ul className='list-unstyled mb-5'>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'status_working' &&
                                        filter.value === 'on',
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) =>
                                        filterData('status_working', 'on')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>Đang làm</span>
                                    </div>
                                    <span className='float-right'>
                                        {
                                            data.filter(
                                                (x) => x.status_working === 'on'
                                            ).length
                                        }
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'status_working' &&
                                        filter.value === 'off',
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) =>
                                        filterData('status_working', 'off')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Đã nghỉ việc</span>
                                    </div>
                                    <span className='float-right'>
                                        {
                                            data.filter(
                                                (x) =>
                                                    x.status_working === 'off'
                                            ).length
                                        }
                                    </span>
                                </NavLink>
                            </NavItem>
                        </ul>
                        <p className='text-muted text-small'>
                            Vị trí công việc
                        </p>
                        <ul className='list-unstyled mb-5'>
                            {uniquePermission.map((c, index) => {
                                return (
                                    <NavItem key={index}>
                                        <div
                                            onClick={(e) =>
                                                filterData('permission_name', c)
                                            }
                                        >
                                            <div className='custom-control custom-radio'>
                                                <input
                                                    type='radio'
                                                    // name='permission'
                                                    className='custom-control-input'
                                                    defaultChecked={
                                                        filter &&
                                                        filter.column ===
                                                            'permission_name' &&
                                                        filter.value === c
                                                    }
                                                />
                                                <label className='custom-control-label'>
                                                    {c}
                                                </label>
                                                <span className='float-right'>
                                                    {
                                                        data?.filter(
                                                            (d) =>
                                                                d.permission_name ===
                                                                c
                                                        ).length
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </NavItem>
                                );
                            })}
                        </ul>
                        {/* <p className='text-muted text-small'>
                            Giới tính
                        </p>
                        <ul className='list-unstyled mb-5'>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'status' &&
                                        filter.value === 'PENDING',
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) =>
                                        filterData('status', 'PENDING')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>Nam</span>
                                    </div>
                                    <span className='float-right'>
                                        10
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'status' &&
                                        filter.value === 'COMPLETED',
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) =>
                                        filterData('status', 'COMPLETED')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Nữ</span>
                                    </div>
                                    <span className='float-right'>
                                        10
                                    </span>
                                </NavLink>
                            </NavItem>
                        </ul>
                        <p className='text-muted text-small'>
                            Tính lương theo
                        </p>
                        <ul className='list-unstyled mb-5'>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'status' &&
                                        filter.value === 'COMPLETED',
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) =>
                                        filterData('status', 'COMPLETED')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Tháng</span>
                                    </div>
                                    <span className='float-right'>
                                        6
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'status' &&
                                        filter.value === 'PENDING',
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) =>
                                        filterData('status', 'PENDING')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>Ngày</span>
                                    </div>
                                    <span className='float-right'>
                                        10
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'status' &&
                                        filter.value === 'COMPLETED',
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) =>
                                        filterData('status', 'COMPLETED')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Giờ</span>
                                    </div>
                                    <span className='float-right'>
                                        4
                                    </span>
                                </NavLink>
                            </NavItem>
                        </ul> */}
                    </div>
                </PerfectScrollbar>
            </ApplicationMenu>
        );
    }
}

export default UserStaffApplicationMenu;
