import React from 'react';
import { Button, Card, CardBody, CardHeader, Row } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';

const Setting = () => {
    return (
        <Card className='mb-4'>
            <CardHeader className='mt-4 d-flex flex-column align-items-center'>
                <Colxx sm='6' className='text-center'>
                    <h5>Cài đặt chung</h5>
                </Colxx>
                <Colxx sm='6' className='text-small text-muted text-center'>
                    <p>
                        Các trường dưới đây là giá trị mặc định của các ô trong
                        trình quản lý Sản phẩm tương ứng, được khởi tạo từ đầu.
                        Bạn có thể thay đổi riêng cho từng sản phẩm để phù hợp
                        với thực tế.
                    </p>
                </Colxx>
            </CardHeader>
            <Separator />
            <CardBody>
                <Row>
                    <Colxx xxs='6' className='d-flex '>
                        <p className='text-muted text-small'>Giá bán</p>
                    </Colxx>
                    <Colxx xxs='6' className='d-flex text-center'>
                        <p>20.000đ</p>
                    </Colxx>
                    <Colxx xxs='6' className='d-flex '>
                        <p className='text-muted text-small'>Giá khuyến mãi</p>
                    </Colxx>
                    <Colxx xxs='6' className='d-flex text-center'>
                        <p>18.000đ</p>
                    </Colxx>
                </Row>
                <Separator className='mb-3' />
                <Row>
                    <Colxx xxs='12'>
                        <p className='text-muted text-small'>Kích thước</p>
                    </Colxx>
                    <Colxx xxs='6' sm='4'>
                        <p className='text-muted text-small'>Size M</p>
                        <p>0đ</p>
                    </Colxx>
                    <Colxx xxs='6' sm='4'>
                        <p className='text-muted text-small'>Size L</p>
                        <p>5.000đ</p>
                    </Colxx>
                    <Colxx xxs='6' sm='4'>
                        <p className='text-muted text-small'>Size XL</p>
                        <p>10.000đ</p>
                    </Colxx>
                </Row>
                <Separator className='mb-3' />
                <Row>
                    <Colxx xxs='12'>
                        <p className='text-muted text-small'>Sản phẩm thêm</p>
                    </Colxx>
                    <Colxx xxs='6' sm='6'>
                        <p className='text-muted text-small'>Bơ thêm</p>
                        <p>5.000đ</p>
                    </Colxx>
                    <Colxx xxs='6' sm='6'>
                        <p className='text-muted text-small'>Thêm sữa</p>
                        <p>2.000đ</p>
                    </Colxx>
                </Row>
                <Separator className='mb-3' />
                <Row>
                    <Colxx xxs='12'>
                        <p className='text-muted text-small'>Độ ngọt</p>
                    </Colxx>
                    <Colxx>
                        <Button outline className='mr-2'>
                            0
                        </Button>
                        <Button outline className='mr-2'>
                            25%
                        </Button>
                        <Button outline className='mr-2'>
                            50%
                        </Button>
                        <Button outline className='mr-2'>
                            75%
                        </Button>
                        <Button outline className='mr-2'>
                            100%
                        </Button>
                    </Colxx>
                </Row>
                <Separator className='my-3' />
                <Row>
                    <Colxx xxs='12'>
                        <p className='text-muted text-small'>Lượng đá</p>
                    </Colxx>
                    <Colxx>
                        <Button outline className='mr-2'>
                            0
                        </Button>
                        <Button outline className='mr-2'>
                            25%
                        </Button>
                        <Button outline className='mr-2'>
                            50%
                        </Button>
                        <Button outline className='mr-2'>
                            75%
                        </Button>
                        <Button outline className='mr-2'>
                            100%
                        </Button>
                    </Colxx>
                </Row>
            </CardBody>
        </Card>
    );
};

export default Setting;
