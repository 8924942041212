import React from "react";
import {
  Button,
  Card, CardBody, CardFooter, CardTitle,
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";

const ContentComponent = ({ title, setting, onSubmit, children }) => {

  return (
    <Card className="mb-3 custom-shadow">
      <CardBody>
        <CardTitle>
          {title}
        </CardTitle>
        {children}
      </CardBody>
      <CardFooter className="text-center">
        <Button
          disabled={setting.viewonly}
          color="primary"
          onClick={onSubmit}
        >
          <IntlMessages id="general.btn-update" />
        </Button>
      </CardFooter>
    </Card>
  );
};

export default ContentComponent;


