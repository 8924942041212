import React, { Component } from 'react';
import { NavItem, Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classnames from 'classnames';

import ApplicationMenu from '../../../components/common/ApplicationMenu';

class IncomeApplicationMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            priceRange: [
                { label: 'Dưới 1 triệu', value: 1000000 },
                { label: 'Từ 1 triệu - 10 triệu', value: 5000000 },
                { label: 'Trên 10 triệu', value: 10000000 },
            ],
        };
    }

    render() {
        const { priceRange } = this.state;

        const { data, filter, filterData } = this.props;
        // console.log(data);

        // const personAdd = data.map((person) => person.user.fullname);
        // const personUnique = [...new Set(personAdd)];

        return (
            <ApplicationMenu>
                <PerfectScrollbar
                    options={{ suppressScrollX: true, wheelPropagation: false }}
                >
                    <div className='p-4'>
                        <NavItem
                            className={classnames(
                                {
                                    active: !filter,
                                },
                                'mb-5'
                            )}
                        >
                            <NavLink
                                location={{}}
                                to='#'
                                onClick={(e) => filterData('', '')}
                                className='d-flex justify-content-between align-items-center'
                            >
                                <div className='d-flex align-items-center'>
                                    <i className='simple-icon-check mr-10' />
                                    <span>Tất cả</span>
                                </div>
                                <span className='float-right'>
                                    {data.length}
                                </span>
                            </NavLink>
                        </NavItem>
                        <p className='text-muted text-small'>
                            Đưa vào thống kê
                        </p>
                        <ul className='list-unstyled mb-5'>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'time' &&
                                        filter.value === 'now',
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) => filterData('time', 'now')}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>Có</span>
                                    </div>
                                    <span className='float-right'>
                                        {
                                            data.filter((x) => x.time === 'now')
                                                .length
                                        }
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'time' &&
                                        filter.value === 'since',
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) => filterData('time', 'since')}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Không</span>
                                    </div>
                                    <span className='float-right'>
                                        {
                                            data.filter(
                                                (x) => x.time === 'since'
                                            ).length
                                        }
                                    </span>
                                </NavLink>
                            </NavItem>
                        </ul>
                        <p className='text-muted text-small'>Khoảng giá</p>
                        <ul className='list-unstyled mb-5'>
                            {priceRange.map((c, index) => {
                                return (
                                    <NavItem key={index}>
                                        <div
                                            onClick={(e) =>
                                                filterData('price', c.value)
                                            }
                                        >
                                            <div className='custom-control custom-radio'>
                                                <input
                                                    type='radio'
                                                    name='price'
                                                    className='custom-control-input'
                                                    defaultChecked={
                                                        filter &&
                                                        filter.column ===
                                                            'price' &&
                                                        filter.value === c.value
                                                    }
                                                />
                                                <label className='custom-control-label'>
                                                    {c.label}
                                                </label>
                                            </div>
                                        </div>
                                    </NavItem>
                                );
                            })}
                        </ul>
                        <p className='text-muted text-small'>Người nhập liệu</p>
                        <ul className='list-unstyled mb-5'>
                            {/* {personUnique.map((c, index) => {
                                return (
                                    <NavItem key={index}>
                                        <div
                                            onClick={(e) =>
                                                filterData('fullname', c)
                                            }
                                        >
                                            <div className='custom-control custom-radio'>
                                                <input
                                                    type='radio'
                                                    name='fullname'
                                                    className='custom-control-input'
                                                    defaultChecked={
                                                        filter &&
                                                        filter.column ===
                                                            'fullname' &&
                                                        filter.value === c
                                                    }
                                                />
                                                <label className='custom-control-label'>
                                                    {c}
                                                </label>
                                            </div>
                                        </div>
                                    </NavItem>
                                );
                            })} */}
                        </ul>
                    </div>
                </PerfectScrollbar>
            </ApplicationMenu>
        );
    }
}

export default IncomeApplicationMenu;
