import React from 'react';
import ReactTable from 'react-table';
import Pagination from '../../../components/DatatablePagination';
import { GetSelectTime } from '../../../helpers/SelectTime';
import { AddCommaNumber } from '../../../helpers/Utils';
import moment from 'moment';
import { Badge } from 'reactstrap';

const ContentMultiForm = (props) => {
    const { data } = props;
    // console.log(data);
    const columns = [
        {
            Header: 'Tên phiếu chi',
            accessor: 'sub_title',
            Cell: (props) => (
                <span className='text-capitalize'>{props.value}</span>
            ),
        },
        {
            Header: 'Tên người nộp',
            accessor: 'name',
            Cell: (props) => (
                <span className='text-capitalize'>{props.value}</span>
            ),
        },
        {
            Header: 'Số tiền',
            accessor: 'value',
            Cell: (props) => AddCommaNumber(props.value),
        },
        {
            Header: 'Mô tả',
            accessor: 'note',
        },
        {
            Header: 'Địa chỉ',
            accessor: 'address',
            Cell: (props) => (
                <span className='text-capitalize'>{props.value}</span>
            ),
        },
        {
            Header: 'Trạng thái',
            accessor: 'status',
            Cell: (props) => (
                <Badge
                    pill
                    color={
                        props.value === 'Đã xét duyệt'
                            ? 'primary'
                            : props.value === 'Đang xét duyệt'
                            ? 'secondary'
                            : 'danger'
                    }
                >
                    {props.value}
                </Badge>
            ),
        },
        {
            Header: 'Thống kê',
            accessor: 'time',
            Cell: (props) => GetSelectTime(props.value).label,
        },
        {
            Header: 'Người tạo',
            accessor: 'user_created_id',
        },
        {
            Header: 'Thời gian tạo',
            accessor: 'createdAt',
            Cell: (props) => moment(props.value).format('DD/MM/YYYY'),
        },
    ];
    return (
        <ReactTable
            defaultPageSize={20}
            data={data}
            columns={columns}
            minRows={0}
            showPageJump={false}
            showPageSizeOptions={true}
            PaginationComponent={Pagination}
            NoDataComponent={() => null}
        />
    );
};

export default ContentMultiForm;
