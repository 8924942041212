import React, { Component } from 'react';
import { NavItem, Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classnames from 'classnames';

import ApplicationMenu from '../../../components/common/ApplicationMenu';

class StockApplicationMenu extends Component {
    render() {
        const { data, filter, filterData } = this.props;
        // console.log(data);
        const filteredStock = (x) =>
            data.filter((item) => (item.count / item.total) * 100 <= x);

        // get n daysAgo
        const getnDaysAgo = (nDaysAgo) => {
            let dataFiltered = [];

            // const d1 = new Date();
            // d1.setHours(0, 0, 0, 0);
            // const d1c = d1.setDate(d1.getDate() - nDaysAgo + 1);

            const d2 = new Date();
            d2.setHours(0, 0, 0, 0);
            const d2c = d2.setDate(d2.getDate() - nDaysAgo);

            const res = data.filter(function (e) {
                const temp = new Date(e.updatedAt).getTime();
                return temp > d2c;
            });
            dataFiltered.push(...res);

            return dataFiltered;
        };
        // console.log(getnDaysAgo(100));

        return (
            <ApplicationMenu>
                <PerfectScrollbar
                    options={{ suppressScrollX: true, wheelPropagation: false }}
                >
                    <div className='p-4'>
                        <p className='text-muted text-small'>Tồn kho</p>
                        <ul className='list-unstyled mb-5'>
                            <NavItem
                                className={classnames({ active: !filter })}
                            >
                                <NavLink
                                    to='#'
                                    onClick={(e) => filterData('', '')}
                                    location={{}}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-layers' />
                                        <span>Tất cả</span>
                                    </div>
                                    <span className='float-right'>
                                        {data.length}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'less_than_50' &&
                                        filter.value === '50',
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) =>
                                        filterData('less_than_50', '50')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>Còn dưới 50%</span>
                                    </div>
                                    <span className='float-right'>
                                        {filteredStock(50).length}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'less_than_20' &&
                                        filter.value === '20',
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) =>
                                        filterData('less_than_20', '20')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>Còn dưới 20%</span>
                                    </div>
                                    <span className='float-right'>
                                        {filteredStock(20).length}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'less_than_10' &&
                                        filter.value === '10',
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) =>
                                        filterData('less_than_10', '10')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Còn dưới 10%</span>
                                    </div>
                                    <span className='float-right'>
                                        {filteredStock(10).length}
                                    </span>
                                </NavLink>
                            </NavItem>
                        </ul>
                        <p className='text-muted text-small'>Thời gian nhập</p>
                        <ul className='list-unstyled mb-5'>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'nDaysAgo' &&
                                        filter.value === 5,
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) => filterData('nDaysAgo', 5)}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>5 ngày gần đây</span>
                                    </div>
                                    <span className='float-right'>
                                        {getnDaysAgo(5).length}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'nDaysAgo' &&
                                        filter.value === 10,
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) => filterData('nDaysAgo', 10)}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>10 ngày gần đây</span>
                                    </div>
                                    <span className='float-right'>
                                        {getnDaysAgo(10).length}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'nDaysAgo' &&
                                        filter.value === 30,
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) => filterData('nDaysAgo', 30)}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>30 ngày gần đây</span>
                                    </div>
                                    <span className='float-right'>
                                        {getnDaysAgo(30).length}
                                    </span>
                                </NavLink>
                            </NavItem>
                        </ul>
                        {/* <p className='text-muted text-small'>
                            Hạn sử dụng
                        </p>
                        <ul className='list-unstyled mb-5'>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'status' &&
                                        filter.value === 'PENDING',
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) =>
                                        filterData('status', 'PENDING')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>Còn dưới 1 tháng</span>
                                    </div>
                                    <span className='float-right'>
                                        10
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'status' &&
                                        filter.value === 'COMPLETED',
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) =>
                                        filterData('status', 'COMPLETED')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Còn dưới 10 ngày</span>
                                    </div>
                                    <span className='float-right'>
                                        6
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'status' &&
                                        filter.value === 'COMPLETED',
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) =>
                                        filterData('status', 'COMPLETED')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Còn dưới 5 ngày</span>
                                    </div>
                                    <span className='float-right'>
                                        3
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'status' &&
                                        filter.value === 'COMPLETED',
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) =>
                                        filterData('status', 'COMPLETED')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Còn dưới 2 ngày</span>
                                    </div>
                                    <span className='float-right'>
                                        1
                                    </span>
                                </NavLink>
                            </NavItem>
                        </ul> */}
                    </div>
                </PerfectScrollbar>
            </ApplicationMenu>
        );
    }
}

export default StockApplicationMenu;
