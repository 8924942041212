import classnames from 'classnames';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem,
    TabContent,
    TabPane,
} from 'reactstrap';
import ListDoneOrders from './ListDoneOrders';
import ListRefundOrders from './ListRefundOrders';
import RecentOrders from './RecentOrders';
import PerfectScrollbar from 'react-perfect-scrollbar';

const ContentComponent = ({
    isOpen,
    toggle,
    itemsRecentOrder,
    itemsDone,
    itemsRefund,
}) => {
    const [activeTab, setActiveTab] = useState('1');
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
        >
            <ModalHeader toggle={toggle}>
                Tổng hợp trạng thái các sản phẩm
            </ModalHeader>
            <ModalBody>
                <Nav
                    tabs
                    className='d-flex justify-content-between separator-tabs ml-0 mb-5'
                >
                    <NavItem>
                        <NavLink
                            className={classnames({
                                active: activeTab === '1',
                                'nav-link': true,
                            })}
                            onClick={() => setActiveTab('1')}
                            location={{}}
                            to='#'
                        >
                            TẤT CẢ{' '}
                            <sup className='fz-sup'>
                                {itemsRecentOrder?.length}
                            </sup>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({
                                active: activeTab === '2',
                                'nav-link': true,
                            })}
                            onClick={() => setActiveTab('2')}
                            location={{}}
                            to='#'
                        >
                            ĐÃ XONG{' '}
                            <sup className='fz-sup'>{itemsDone.length}</sup>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({
                                active: activeTab === '3',
                                'nav-link': true,
                            })}
                            onClick={() => setActiveTab('3')}
                            location={{}}
                            to='#'
                        >
                            ĐÃ HỦY{' '}
                            <sup className='fz-sup'>{itemsRefund.length}</sup>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId='1' className='scrool modal-right-add'>
                        {/* <PerfectScrollbar
                            options={{
                                suppressScrollX: true,
                                wheelPropagation: false,
                            }}
                            id='modal-right__body'
                        > */}
                        <RecentOrders items={itemsRecentOrder} />
                        {/* </PerfectScrollbar> */}
                    </TabPane>
                    <TabPane tabId='2'>
                        <ListDoneOrders
                            // onActions={this.onActions}
                            // selectedItems={selectedItemsDone}
                            // onCheckItem={this.onCheckItemDone}
                            items={itemsDone}
                        />
                    </TabPane>
                    <TabPane tabId='3'>
                        <ListRefundOrders
                            // onActions={this.onActions}
                            // selectedItems={selectedItemsRefund}
                            // onCheckItem={this.onCheckItemRefund}
                            items={itemsRefund}
                        />
                    </TabPane>
                </TabContent>
                {/* <Row>
                    <Colxx sm='12' md='12' lg='4' xl='4' className='mb-4'>
                    </Colxx>
                    <Colxx sm='12' md='12' lg='4' xl='4' className='mb-4'>
                        <ListDoneOrders
                            // onActions={this.onActions}
                            // selectedItems={selectedItemsDone}
                            // onCheckItem={this.onCheckItemDone}
                            items={itemsDone}
                        />
                    </Colxx>
                    <Colxx sm='12' md='12' lg='4' xl='4' className='mb-4'>
                        <ListRefundOrders
                            // onActions={this.onActions}
                            // selectedItems={selectedItemsRefund}
                            // onCheckItem={this.onCheckItemRefund}
                            items={itemsRefund}
                        />
                    </Colxx>
                </Row> */}
            </ModalBody>
        </Modal>
    );
};

export default ContentComponent;
