import React, { useState, useEffect, useRef } from 'react';
import ComponentToPrint from '../ComponentToPrint';
import axios from 'axios';
import { END_POINT } from '../../../constants/defaultValues';
import ReactToPrint from 'react-to-print';
import { Row, Col, Button, Card, CardBody } from 'reactstrap';
import { ParseDate } from '../../../helpers/DateTime';
import { isF } from '../../../helpers/Roles';

const ROLE_ALIAS = 'bill';

const ComponentToPrintWrapper = (props) => {
    const [state, setState] = useState({
        items: [],
        companyName: localStorage.getItem('companyName'),
        companyAddress: localStorage.getItem('companyAddress'),
        companyLogo: localStorage.getItem('companyLogo'),
        companyWebsite: localStorage.getItem('companyWebsite'),
        billDate: '',
        timeStart: '',
        pin_print: '',
    });

    const componentRef = useRef();

    useEffect(() => {
        dataBillRender();
    }, [props.orders_id]);

    const dataBillRender = () => {
        if (!props.orders_id.length) return false;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/bill/invoice_by_orders_id'}`,
                {
                    orders: props.orders_id,
                },
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                if (data.data.length) {
                    const pins = [
                        ...new Set(data.data.map((item) => item.pin_print)),
                    ];

                    let pin_print = pins.join(' + ');

                    const dates = [
                        ...new Set(data.data.map((item) => item.date)),
                    ];
                    let billDate = dates.join(' + ');

                    setState({
                        ...state,
                        items: data.data,
                        companyName: localStorage.getItem('companyName'),
                        companyAddress: localStorage.getItem('companyAddress'),
                        companyLogo: localStorage.getItem('companyLogo'),
                        companyWebsite: localStorage.getItem('companyWebsite'),
                        billDate,
                        timeStart: data.data[0].bill.time,
                        pin_print: pin_print,
                    });
                }
            });
    };

    return (
        <div className='invoice__container position-sticky-custom'>
            <Row className='invoice-react'>
                <Col className='mb-5 invoice-print-btn'>
                    <div className='invoice-react' id='bill-to-print'>
                        <div className='mb-5 invoice-contents bg-transparent'>
                            <div className='align-self-center order-invoice'>
                                <p className='mt-3'>
                                    {isF(ROLE_ALIAS) ? (
                                        componentRef && (
                                            <ReactToPrint
                                                trigger={() => (
                                                    <Button
                                                        color='secondary'
                                                        outline
                                                    >
                                                        In hóa đơn
                                                    </Button>
                                                )}
                                                content={() =>
                                                    componentRef.current
                                                }
                                            />
                                        )
                                    ) : (
                                        <Button
                                            color='secondary'
                                            outline
                                            className='disable'
                                        >
                                            In hóa đơn
                                        </Button>
                                    )}
                                </p>
                                <ComponentToPrint
                                    items={state.items}
                                    companyName={state.companyName}
                                    companyAddress={state.companyAddress}
                                    companyLogo={state.companyLogo}
                                    companyWebsite={state.companyWebsite}
                                    billDate={state.billDate}
                                    timeStart={state.timeStart}
                                    pin_print={state.pin_print}
                                    ref={componentRef}
                                    discount={null}
                                    tax_fee={null}
                                    fee_other={null}
                                    cardWrapper={true}
                                />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ComponentToPrintWrapper;
