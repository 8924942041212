import React from "react";
import {
  Input,
  Label,
  Form, FormGroup,
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { AddCommaNumber } from "../../../helpers/Utils";
const ContentComponent = (props) => {
  return (
    <Form>
      <FormGroup>
        <Label for="name">
          <IntlMessages id="stock.name" />
        </Label>
        <Input type="text" name="name" id="name"
          disabled={true}
          value={props.values.name}
        />
      </FormGroup>
      <FormGroup>
        <Label for="unit_price">
          <IntlMessages id="stock.unit-price" />
        </Label>
        <Input name="unit_price" id="unit_price"
          value={AddCommaNumber(props.values.unit_price)}
          onChange={props.onChange}
        />
      </FormGroup>
      <FormGroup>
          <Label for="update_count">
            <IntlMessages id="stock.count" /> {`( ${props.values.unit} )`}
          </Label>
          <Input name="new_count" id="update_count"
            type="number" step="1"
            value={props.values.new_count}
            placeholder={0}
            onChange={props.onChange}
          />
        </FormGroup>
    </Form>
  )
}

export default ContentComponent