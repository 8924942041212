export const SetIceOrSugar = () => {
    return [
        {
            label: '0%',
            value: '0',
        },
        {
            label: '25%',
            value: '25',
        },
        {
            label: '50%',
            value: '50',
        },
        {
            label: '75%',
            value: '75',
        },
        {
            label: '100%',
            value: '100',
        },
    ];
};

// export const GetIceOrSugar = (type) => {
//     if (type === 'text') {
//         return {
//             label: "Text",
//             value: "text"
//         }
//     }
// }
