import DataListIcon from './DataListIcon'
import ThumbListIcon from './ThumbListIcon'
import ImageListIcon from './ImageListIcon'
import MenuIcon from './MenuIcon'
import MobileMenuIcon from './MobileMenuIcon'
import TableChairs from './TableChairs'
import NewMessage from './NewMessage'
import Setting from './Setting'

export { Setting, DataListIcon, ThumbListIcon, ImageListIcon, MenuIcon, MobileMenuIcon, TableChairs, NewMessage }
