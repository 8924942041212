import React from 'react';
import {
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Button,
    Form,
    FormGroup,
    Badge,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import Select from 'react-select';
import CustomSelectInput from '../../../components/common/CustomSelectInput';
import DynamicForm from '../general/DynamicForm';

const ContentComponent = (props) => {
    return (
        <Form>
            {props.setting.add ? (
                <FormGroup>
                    <Label>
                        <IntlMessages id='make-product.product-name' />
                    </Label>
                    <Select
                        isDisabled={
                            props.setting.viewonly && !props.setting.add
                        }
                        defaultInputValue={''}
                        components={{ Input: CustomSelectInput }}
                        className='react-select'
                        classNamePrefix='react-select'
                        name='product'
                        options={props.data_products}
                        onChange={(val, extra) =>
                            props.onChange(val, 'product')
                        }
                        placeholder={<IntlMessages id='pages.select-product' />}
                    />
                </FormGroup>
            ) : null}

            <FormGroup>
                <Label>
                    <IntlMessages id='make-product.stock-name' />
                </Label>
                <Select
                    isDisabled={props.setting.viewonly && !props.setting.add}
                    defaultInputValue={''}
                    components={{ Input: CustomSelectInput }}
                    className='react-select'
                    classNamePrefix='react-select'
                    name='stock'
                    options={props.data_stocks}
                    onChange={(val, extra) => props.onChange(val, 'stock')}
                    placeholder={
                        <IntlMessages id='make-product.select-stock' />
                    }
                />
            </FormGroup>
            <FormGroup>
                <Label>
                    <IntlMessages id='pages.order-number' />
                </Label>
                <InputGroup className='mb-3'>
                    <InputGroupAddon addonType='prepend'>
                        <Button
                            disabled={
                                props.setting.viewonly && !props.setting.add
                            }
                            outline
                            color='primary'
                            size='xs'
                            onClick={() => props.onChangeCount('-')}
                        >
                            -
                        </Button>
                    </InputGroupAddon>
                    <Input
                        className={'order-number'}
                        type={'number'}
                        name='count'
                        placeholder={'1'}
                        step='1'
                        value={props.values.count}
                        onChange={props.onChange}
                        disabled={props.setting.viewonly && !props.setting.add}
                    />
                    <InputGroupAddon addonType='append'>
                        <Button
                            disabled={
                                props.setting.viewonly && !props.setting.add
                            }
                            outline
                            color='primary'
                            size='xs'
                            onClick={() => props.onChangeCount('+')}
                        >
                            +
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </FormGroup>
            <FormGroup>
                <Label>
                    <IntlMessages id='action' />
                </Label>
                <Button
                    color='success'
                    disabled={props.setting.viewonly && !props.setting.add}
                    outline
                    block
                    onClick={() => props.onAddStock()}
                >
                    <IntlMessages id='make-product.make-more' />
                </Button>
            </FormGroup>

            <div className='custom-line mt-4 mb-4' />

            {props.values.make.length ? (
                <ul className={'orders-pending'}>
                    {props.values.make.map((stock) => (
                        <li key={stock.id}>
                            <Button
                                disabled={
                                    props.setting.viewonly && !props.setting.add
                                }
                                color='primary'
                            >
                                {stock.name}{' '}
                                <Badge color='light'>
                                    {stock.count + ' (' + stock.unit + ')'}
                                </Badge>{' '}
                                {props.setting.viewonly &&
                                !props.setting.add ? null : (
                                    <Badge
                                        onClick={() =>
                                            props.onDeleteStock(stock.id)
                                        }
                                        color='danger'
                                    >
                                        x
                                    </Badge>
                                )}
                            </Button>
                        </li>
                    ))}
                </ul>
            ) : null}

            <DynamicForm
                values={props.values.dynamic_form}
                data={props.data_dynamic_form}
                onChange={props.onChangeDynamicForm}
                disabled={props.setting.viewonly && !props.setting.add}
            />
        </Form>
    );
};

export default ContentComponent;
