import React, { Component } from 'react';
import { Row, Col, Ca, Card, CardBody } from 'reactstrap';
import axios from 'axios';
import { END_POINT, END_POINT_SOCKET } from '../../../constants/defaultValues';
import {
    SetItemsSelected,
    NotificatioErrController,
    NotificatioSucController,
} from '../../../helpers/Utils';
import io from 'socket.io-client';
import {
    PageHeading,
    ContentForm,
    ListItemLeftForm,
    ContentDisplay,
    ListItemFormMobile,
    TodoApplicationMenu,
} from '../../../containers/pages/todo';
import { Add, Update, Pagination } from '../../../containers/pages/general';
import LogsHelper from '../../../helpers/Logs';
import IntlMessages from '../../../helpers/IntlMessages';
import { isC, isR, isU, isD } from '../../../helpers/Roles';
import moment from 'moment';
import { Separator } from '../../../components/common/CustomBootstrap';
import FooterPage from '../../../containers/pages/FooterPage';
import FooterTodo from '../../../containers/pages/todo/FooterTodo';
import AddTodo from '../../../containers/pages/todo/AddTodo';
import UpdateTodo from '../../../containers/pages/todo/UpdateTodo';

const DEFAULT_FORM = {
    id: '',
    title: '',
    description: '',
    momentFormatSelectDateTime: new Date(),
    date: '',
    time: '',
    dynamic_form: [],
};

const ROLE_ALIAS = 'todo';

class ContentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: {
                pageSizes: [12, 24],
                selectedPageSize: 12,
                currentPage: 1,
                totalPage: 1,
                totalItem: 0,
                search: '',
            },
            selectedItems: [],
            data: [],
            data_all: [],

            data_todo: null,
            filter: null,

            data_dynamic_form: [],
            is_loading_data: true,
            show: {
                add: false,
                update: false,
                view: false,
                viewonly: true,
            },
            accordion: [],
            activeTab: '1',
            form: DEFAULT_FORM,
        };

        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        this.socket.on('refresh-todo-all', (res) => {
            const { data } = res;

            this.setState(
                (prev) => ({
                    ...prev,
                    data_all: data.data,
                    data_dynamic_form: data.dataDynamicForm,
                    is_loading_data: false,
                })
                // () => {
                //     if (this.state.form.id) {
                //         const {
                //             id,
                //             title,
                //             description,
                //             date,
                //             time,
                //             dynamic_form,
                //         } = this.state.form;

                //         let DateTime = date + ' ' + time;

                //         this.setState((prev) => ({
                //             ...prev,
                //             form: {
                //                 ...prev.form,
                //                 id,
                //                 title,
                //                 description,
                //                 momentFormatSelectDateTime: DateTime,
                //                 dynamic_form,
                //             },
                //         }));
                //     } else {
                //         this.setState((prev) => ({
                //             ...prev,
                //             selectedItems: [],
                //             show: {
                //                 ...prev.show,
                //                 update: false,
                //             },
                //             form: DEFAULT_FORM,
                //         }));
                //     }
                // }
            );
        });

        this.socket.on('refresh-todo', (res) => {
            const { data } = res;

            let anyAccordion = [];
            let numeroPerguntas = data.data.length;
            for (var i = 0; i < numeroPerguntas; i++) {
                anyAccordion.push(false);
            }
            this.setState(
                (prev) => ({
                    ...prev,
                    data: data.data,
                    data_dynamic_form: data.dataDynamicForm,
                    is_loading_data: false,
                    page: {
                        ...prev.page,
                        totalPage: data.totalPage,
                        totalItem: data.totalItem,
                    },
                    accordion: anyAccordion,
                }),
                () => {
                    if (this.state.form.id) {
                        const {
                            id,
                            title,
                            description,
                            date,
                            time,
                            dynamic_form,
                        } = this.state.form;

                        let DateTime = date + ' ' + time;

                        this.setState((prev) => ({
                            ...prev,
                            form: {
                                ...prev.form,
                                id,
                                title,
                                description,
                                momentFormatSelectDateTime: DateTime,
                                dynamic_form,
                            },
                        }));
                    } else {
                        this.setState((prev) => ({
                            ...prev,
                            selectedItems: [],
                            show: {
                                ...prev.show,
                                update: false,
                            },
                            form: DEFAULT_FORM,
                        }));
                    }
                }
            );
        });

        this.dataListRender();
    }

    /** Axios func helper */
    dataListRender = () => {
        const { selectedPageSize, currentPage, search } = this.state.page;

        this.socket.emit('refresh-todo', {
            pageSize: selectedPageSize,
            currentPage,
            search,
            user_id: localStorage.getItem('user_id'),
        });

        this.socket.emit('refresh-todo-all', {
            user_id: localStorage.getItem('user_id'),
        });

        this.socket.emit('refresh-todo-month', {
            user_id: localStorage.getItem('user_id'),
        });
    };

    onHandlerDelete = () => {
        if (!isD(ROLE_ALIAS)) return false;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .delete(`${END_POINT + '/todo'}`, {
                data: {
                    arrayId: this.state.selectedItems,
                },
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                // console.log(data)
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['todo-delete'],
                });

                this.dataListRender();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerUpdate = (e) => {
        if (!isU(ROLE_ALIAS)) return false;
        e.preventDefault();

        const {
            id,
            title,
            description,
            momentFormatSelectDateTime,
            dynamic_form,
        } = this.state.form;

        if (!id || !title || !description || !momentFormatSelectDateTime) {
            NotificatioErrController('Dữ liệu không hợp lệ');
            return;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .put(
                `${END_POINT + '/todo'}`,
                {
                    id,
                    title,
                    description,
                    momentFormatSelectDateTime,
                    dynamic_form,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['todo-update'],
                });

                this.dataListRender();
                this.toggle('update');
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerUpdateStatus(ids, status_id) {
        if (!isU(ROLE_ALIAS)) return false;

        if (ids.length < 1) return false;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .put(
                `${END_POINT + '/todo/status'}`,
                {
                    arrayId: ids,
                    status_id: status_id,
                },
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                NotificatioSucController(data.responseText);

                if (status_id === 1) {
                    // update to done

                    this.socket.emit('update-seen-alert-data-by-item-id', {
                        user_id: localStorage.getItem('user_id'),
                        type: 'todo',
                        item_id: ids,
                    });
                }

                this.dataListRender();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    }

    onHandlerAdd = (e) => {
        if (!isC(ROLE_ALIAS)) return false;
        e.preventDefault();

        const { title, description, momentFormatSelectDateTime, dynamic_form } =
            this.state.form;

        if (!title) {
            NotificatioErrController('Tiêu đề sự kiện cần được thêm vào');
            return;
        }
        if (!description) {
            NotificatioErrController('Mô tả cần được thêm vào');
            return;
        }
        if (!momentFormatSelectDateTime) {
            NotificatioErrController('Thời gian cần được thêm vào');
            return;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/todo'}`,
                {
                    title,
                    description,
                    momentFormatSelectDateTime,
                    dynamic_form,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['todo-create'],
                });

                this.dataListRender();
                // this.toggle('add');
                this.setState((prev) => ({
                    ...prev,
                    form: {
                        ...prev.form,
                        form: DEFAULT_FORM,
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };
    /** Axios func helper */

    /** State func helper */
    toggle = (name) => {
        if (name === 'add' && !isC(ROLE_ALIAS)) return false;
        if (name === 'update' && !isU(ROLE_ALIAS)) return false;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                update: name === 'add' ? false : prev.show.update,
                view: name === 'add' ? false : prev.show.view,
                [name]: !prev.show[name],
            },
            selectedItems: name === 'add' ? [] : prev.selectedItems,
            form: name === 'add' ? DEFAULT_FORM : prev.form,
        }));
    };

    onChangeForm = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [name]: value,
                },
            }));
        } else {
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    momentFormatSelectDateTime: e,
                },
            }));
        }
    };

    onChangeDynamicForm = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    dynamic_form: {
                        ...prev.form.dynamic_form,
                        [name]: value,
                    },
                },
            }));
        } else {
            let value = e.value ? e.value : e;

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    dynamic_form: {
                        ...prev.form.dynamic_form,
                        [_name]: value,
                    },
                },
            }));
        }
    };

    onSubmitForm = (type) => {
        if (type === 'update') {
            this.onHandlerUpdate();
        } else if (type === 'add') {
            this.onHandlerAdd();
        }
    };

    onChangePage = (page) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    currentPage: page,
                },
            }),
            () => this.dataListRender()
        );
    };

    onSearchKey = (e) => {
        if (!e) return false;

        if (e.target) {
            let {
                target: { value },
            } = e;
            let v = value;
            if (value === undefined) {
                v = e.target.firstElementChild.value; // parent to child

            //     value = v.toLowerCase();
            //     this.setState(
            //         (prev) => ({
            //             ...prev,
            //             page: {
            //                 ...prev.page,
            //                 search: value,
            //             },
            //         }),
            //         () => this.dataListRender()
            //     );
            // } else {
            //     value = value.toLowerCase();
            //     this.setState(
            //         (prev) => ({
            //             ...prev,
            //             page: {
            //                 ...prev.page,
            //                 search: value,
            //             },
            //         }),
            //         () => this.dataListRender()
            //     );
            }
        }

        if (e.key === 'Enter') {
            let value = e.target.value.toLowerCase();
            this.setState(
                (prev) => ({
                    ...prev,
                    page: {
                        ...prev.page,
                        search: value,
                    },
                }),
                () => this.dataListRender()
            );
        }
    };

    onChangePageSize = (size) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    selectedPageSize: size,
                    currentPage: 1,
                },
            }),
            () => this.dataListRender()
        );
    };

    onClickedItem = (item, callback, args) => {
        if (this.state.form.id !== item.id) {
            const { id, title, description, date, time, dynamic_form } = item;

            let DateTime = date + ' ' + time;

            this.setState(
                (prev) => ({
                    ...prev,
                    show: {
                        ...prev.show,
                        update: false,
                        view: true,
                        viewonly: false,
                    },
                    form: {
                        ...prev.form,
                        id,
                        title,
                        description,
                        date,
                        time,
                        momentFormatSelectDateTime: DateTime,
                        dynamic_form,
                    },
                    selectedItems: [item.id],
                }),
                () => {
                    callback(args[0], args[1], args[2]);
                }
            );
        }
    };

    onClickedUpdate = (item) => {
        const { id, title, description, date, time, dynamic_form } = item;
        let DateTime = date + ' ' + time;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                update: true,
            },
            form: {
                ...prev.form,
                id,
                title,
                description,
                date,
                time,
                momentFormatSelectDateTime: DateTime,
                dynamic_form,
            },
        }));
    };

    onClickedDelete = (item) => {
        let selectedSet = SetItemsSelected(
            item.id,
            this.state.selectedItems,
            'require'
        );
        if (window.confirm('Bạn chắc chắn muốn xoá sự kiện này?')) {
            this.setState(
                (prev) => ({
                    ...prev,
                    selectedItems: selectedSet,
                }),
                () => this.onHandlerDelete()
            );
        }
    };

    onClickedUpdateStatus = (item, statusId) => {
        let selectedSet = SetItemsSelected(
            item.id,
            this.state.selectedItems,
            'require'
        );

        this.setState(
            (prev) => ({
                ...prev,
                selectedItems: selectedSet,
            }),
            () => this.onHandlerUpdateStatus(selectedSet, statusId)
        );
    };

    onClickedCheckbox = (id, callback) => {
        // let selectedSet = SetItemsSelected(id, this.state.selectedItems);

        this.setState((prev) => ({
            ...prev,
            selectedItems: [id],
        }));
    };
    /** State func helper */

    toggleAccordion = (tab) => {
        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => (tab === index ? !x : false));
        this.setState({
            accordion: state,
        });
    };

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    filterData = (key, value) => {
        // console.log('key: ', key);
        // console.log('value: ', value);

        if (key === '' && value === '') {
            this.setState((prev) => ({
                ...prev,
                data_todo: this.state.data,
                filter: null,
            }));
        } else if (key === 'next_hours') {
            const todayDate = new Date();
            const today = moment(new Date()).format('YYYY-MM-DD');

            const oneHourOfDay = moment(todayDate)
                .subtract(-1, 'hour')
                .format('HH:mm');
            // console.log('oneHourOfDay: ', oneHourOfDay);

            const threeHourOfDay = moment(todayDate)
                .subtract(-3, 'hour')
                .format('HH:mm');
            // console.log('threeHourOfDay: ', threeHourOfDay);

            const sixHourOfDay = moment(todayDate)
                .subtract(-6, 'hour')
                .format('HH:mm');
            // console.log('sixHourOfDay: ', sixHourOfDay);

            const filteredByHour = this.state.data.filter((item) => {
                const launchTime = item.time;

                // console.log('launchTime: ', launchTime);
                const launchDate = item.date;
                const notYet = item.status_name;

                return (
                    launchTime >=
                        (value === 1
                            ? oneHourOfDay
                            : value === 3
                            ? threeHourOfDay
                            : value === 6
                            ? sixHourOfDay
                            : null) &&
                    launchDate === today &&
                    notYet === 'Đang chờ'
                );
            });

            // console.log('filteredByHour: ', filteredByHour);
            this.setState((prev) => ({
                ...prev,
                data_todo: filteredByHour,
                filter: { column: key, value },
            }));
        } else if (key === 'pastDays') {
            const todayDate = new Date();

            const sevenDaysOfMonth = moment(todayDate)
                .subtract(7, 'day')
                .format('YYYY-MM-DD');
            // console.log(sevenDaysOfMonth);

            const thirdtyDaysOfMonth = moment(todayDate)
                .subtract(30, 'day')
                .format('YYYY-MM-DD');
            // console.log(thirdtyDaysOfMonth);

            const sixtyDaysOfMonth = moment(todayDate)
                .subtract(60, 'day')
                .format('YYYY-MM-DD');
            // console.log(sixtyDaysOfMonth);

            const filteredItems = this.state.data.filter((item) => {
                const launchDate = item.date;
                // console.log(launchTime);
                return (
                    launchDate <=
                        (value === 7
                            ? sevenDaysOfMonth
                            : value === 30
                            ? thirdtyDaysOfMonth
                            : value === 180
                            ? sixtyDaysOfMonth
                            : null) && item.status_name === 'Hoàn thành'
                );
            });
            this.setState((prev) => ({
                ...prev,
                data_todo: filteredItems,
                filter: { column: key, value },
            }));
        } else {
            const todayDate = new Date();
            const today = moment(new Date()).format('YYYY-MM-DD');

            const startDayOfWeek = moment(todayDate)
                .subtract(0, 'week')
                .startOf('week')
                .format('YYYY-MM-DD');

            const startDayOfMonth = moment(todayDate)
                .subtract(0, 'month')
                .startOf('month')
                .format('YYYY-MM-DD');

            const filteredItems = this.state.data.filter((item) =>
                value === 'today'
                    ? item[key] === today
                    : item[key] >=
                      (value === 7
                          ? startDayOfWeek
                          : value === 30
                          ? startDayOfMonth
                          : 0)
            );
            this.setState((prev) => ({
                ...prev,
                data_todo: filteredItems,
                filter: { column: key, value },
            }));
        }
    };

    render() {
        const { match } = this.props;
        const {
            page,
            selectedItems,
            data,
            data_all,
            data_todo,
            filter,
            is_loading_data,
            show,
            form,
            accordion,

            // Dynamic Form
            data_dynamic_form,
        } = this.state;

        // console.log('data: ', data);
        // console.log('data_all: ', data_all);

        if (!isR(ROLE_ALIAS))
            return (
                <Card className='text-muted mh-40vh h-100'>
                    <CardBody className='d-flex justify-content-center align-items-center'>
                        Bạn không có quyền sử dụng tính năng này!
                    </CardBody>
                </Card>
            );

        const dataRender = data_todo?.length ? data_todo : data;
        // console.log('dataRender: ', dataRender);

        return is_loading_data ? (
            <div className='loading' />
        ) : (
            <div className='app-row disable-text-selection mh-53vh'>
                <PageHeading
                    data={data}
                    heading='todo.thumb-list'
                    setting={page}
                    match={match}
                    totalItem={page.totalItem}
                    isDisabledAdd={!isC(ROLE_ALIAS)}
                    isDisabledDel={!isD(ROLE_ALIAS)}
                    isDisabledUpdate={!isU(ROLE_ALIAS)}
                    toggleFunc={() => {
                        this.toggle('add');
                        this.toggleAccordion();
                    }}
                    changePageSizeFunc={this.onChangePageSize}
                    onSearchKeyFunc={this.onSearchKey}
                />
                <AddTodo
                    title={<IntlMessages id='todo.add-new-modal-title' />}
                    isOpen={show.add}
                    toggle={() => {
                        this.toggle('add');
                    }}
                    data_dynamic_form={data_dynamic_form}
                    values={form}
                    item={data}
                    setting={show}
                    onChange={this.onChangeForm}
                    onChangeDynamicForm={this.onChangeDynamicForm}
                    onSubmit={this.onHandlerAdd}
                />
                <UpdateTodo
                    title={<IntlMessages id='todo.thumb-list' />}
                    isOpen={show.update}
                    toggle={() => {
                        this.toggle('update');
                    }}
                    onSubmit={this.onHandlerUpdate}
                    data_dynamic_form={data_dynamic_form}
                    values={form}
                    setting={show}
                    onChange={this.onChangeForm}
                    onChangeDynamicForm={this.onChangeDynamicForm}
                />
                {data.length === 0 ? (
                    <Card className='mt-4 mb-4 mh-53vh'>
                        <CardBody>Chưa có dữ liệu...</CardBody>
                    </Card>
                ) : (
                    <>
                        <Row className='content-page__row d-none d-md-flex'>
                            <Col
                                xs='12'
                                sm='12'
                                md='12'
                                lg={show.view ? 6 : 12}
                                xl={show.view ? 6 : 12}
                            >
                                {dataRender?.map((item, idx) => (
                                    <ListItemLeftForm
                                        onClickedItem={this.onClickedItem}
                                        onUpdate={this.onClickedUpdate}
                                        onDelete={this.onClickedDelete}
                                        onUpdateStatus={
                                            this.onClickedUpdateStatus
                                        }
                                        onChangeCheckbox={
                                            this.onClickedCheckbox
                                        }
                                        isDisabledUpdate={!isU(ROLE_ALIAS)}
                                        isDisabledDelete={!isD(ROLE_ALIAS)}
                                        selectedItems={selectedItems}
                                        setting={show}
                                        key={idx}
                                        idx={idx}
                                        item={item}
                                    />
                                ))}
                                <Pagination
                                    currentPage={page.currentPage}
                                    totalPage={page.totalPage}
                                    onChangePage={(i) => this.onChangePage(i)}
                                />
                            </Col>
                            {show.view ? (
                                <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                                    <ContentDisplay values={form} />
                                </Col>
                            ) : null}
                        </Row>
                        <Row className='content-page__row d-block d-md-none mb-4'>
                            <Col>
                                {dataRender?.map((item, idx) => {
                                    // console.log(item);
                                    return (
                                        <div
                                            className='d-flex mb-3 card'
                                            key={item.id}
                                        >
                                            <ListItemFormMobile
                                                onClickedItem={
                                                    this.onClickedItem
                                                }
                                                onUpdate={this.onClickedUpdate}
                                                onDelete={this.onClickedDelete}
                                                onChangeCheckbox={
                                                    this.onClickedCheckbox
                                                }
                                                onUpdateStatus={
                                                    this.onClickedUpdateStatus
                                                }
                                                isDisabledUpdate={
                                                    !isU(ROLE_ALIAS)
                                                }
                                                isDisabledDelete={
                                                    !isD(ROLE_ALIAS)
                                                }
                                                selectedItems={selectedItems}
                                                key={idx}
                                                idx={idx}
                                                item={item}
                                                form={form}
                                                accordion={accordion}
                                                toggleAccordion={
                                                    this.toggleAccordion
                                                }
                                            />
                                        </div>
                                    );
                                })}
                                <Pagination
                                    currentPage={page.currentPage}
                                    totalPage={page.totalPage}
                                    onChangePage={(i) => this.onChangePage(i)}
                                />
                            </Col>
                        </Row>
                    </>
                )}
                <TodoApplicationMenu
                    data={data}
                    filter={filter}
                    filterData={(key, value) => this.filterData(key, value)}
                />
                <Separator />
                <FooterPage>
                    <FooterTodo />
                </FooterPage>
            </div>
        );
    }
}

export default ContentComponent;
