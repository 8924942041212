import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import UserLayout from "../../layout/UserLayout";

import login from "./login";
import register from "./register";
import register_verify from "./register_verify";
import forgotPassword from "./forgot-password";


const RenderRoute = ({ component: Component, ...rest }) => {
  const store = window.location.host.split('.')[0];

  return (
    <Route
      {...rest}
      render={props =>
        store !== "register"
          ? <Component {...props} />
          :
          (
            <Redirect
              to={{
                pathname: "/user/register",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  )
};


const User = ({ match }) => {


  return (
    <UserLayout>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/login`} />
        <RenderRoute path={`${match.url}/login`} component={login} />
        <RenderRoute path={`${match.url}/verify`} component={register_verify} />

        <Route path={`${match.url}/register`} component={register} />
        <Route
          path={`${match.url}/forgot-password`}
          component={forgotPassword}
        />
        <Redirect to="/error" />
      </Switch>
    </UserLayout>
  );
};

export default User;
