import React, { Component } from 'react';
import {
    Row,
    CardTitle,
    Card,
    CardBody,
    Input,
    FormGroup,
    Label,
    Button,
    Form,
} from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import {
    NotificatioErrController,
    NotificatioSucController,
    //  CheckConfirmPassword
} from '../../../helpers/Utils';
import axios from 'axios';
import { END_POINT, END_POINT_SOCKET } from '../../../constants/defaultValues';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import { TableChairGenerateMap } from '../../../components/tablechairs';
import io from 'socket.io-client';
import LogsHelper from '../../../helpers/Logs';
// import PasswordBox from '../../../components/PasswordBox'
import { isRorF, isF, isR } from '../../../helpers/Roles';
import classnames from 'classnames';
import TooltipItem from '../../../components/common/TooltipItem';
import FooterPage from '../../../containers/pages/FooterPage';
import FooterTableSetting from '../../../containers/pages/FooterTableSetting';

const ROLE_ALIAS = 'table_setting';

class TableSetting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            tableData: {},
            tableDataAll: {},
            floor: 1,
            rowTableTooltip: {
                placement: 'top',
                text: '',
                body: 'Đánh số vào ô dưới để hiển thị số Hàng ngang tương ứng.',
            },
            colTableTooltip: {
                placement: 'top',
                text: '',
                body: 'Đánh số vào ô dưới để hiển thị số Hàng dọc tương ứng.',
            },
            floorTableTooltip: {
                placement: 'top',
                text: '',
                body: 'Đánh số vào ô dưới để hiển thị Khu vực tương ứng.',
            },
        };
        this.socket = io(END_POINT_SOCKET);

        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.dataTableRender = this.dataTableRender.bind(this);
        this.dataAllTableRender = this.dataAllTableRender.bind(this);
    }

    componentWillUnmount() {
        this.socket.close();
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        this.dataTableRender();
        this.dataAllTableRender();

        localStorage.setItem('table', []);
        localStorage.setItem('all_table', []);
    }

    /**
     * DATA TABLE POSITION
     */
    dataTableRender() {
        if (!this.state.floor) {
            return false;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/table'}?floor=${this.state.floor}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                // console.log(data);
                localStorage.setItem(
                    'table',
                    JSON.stringify(data.data.position)
                );

                this.setState({
                    tableData: data.data,
                    isLoading: true,
                });

                this.dataAllTableRender();
            });
    }

    dataAllTableRender() {
        if (!this.state.floor) {
            return false;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/table/all-table'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                // console.log(data);
                localStorage.setItem(
                    'all_table',
                    JSON.stringify(data.data.position)
                );

                this.setState({
                    tableDataAll: data.data,
                });
            });
    }

    handleChangeValue(e) {
        if (!isF(ROLE_ALIAS)) return false;

        if (!e) return false;

        if (e.target) {
            // input
            let { name, value } = e.target;

            if (name === 'floor') {
                this.setState({ [name]: value }, () => this.dataTableRender());
            } else {
                let cloneObject = this.state.tableData;
                cloneObject[name] = value;
                cloneObject['position'] = [];

                localStorage.setItem('table', JSON.stringify([]));
                // localStorage.setItem('all_table', JSON.stringify([]));

                this.setState((prevState) => ({
                    ...prevState,
                    tableData: cloneObject,
                }));
            }
        }
    }

    onSubmitGenerate = async () => {
        if (!isF(ROLE_ALIAS)) return false;

        let tableData = localStorage.getItem('table');

        // axios submit
        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/table'}`,
                {
                    floor: this.state.floor,
                    data: JSON.parse(tableData),
                },
                {
                    headers: {
                        Authorization: `Bearer ${tokenStr}`,
                        'content-type': 'application/json',
                    },
                }
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                NotificatioSucController(data.responseText);

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['table-setting-update'],
                });

                this.setState(
                    {
                        isLoading: true,
                    },
                    () => this.dataTableRender()
                );
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    /**
     * Password
     */
    // handleChangeValueModalPassword = value => {
    //   this.setState({ password: value })
    // }
    // handleChangeValueModalIsOpen = value => {
    //   this.setState({
    //     password: "",
    //     isOpenModalPassword: !this.state.isOpenModalPassword
    //   })
    // }
    // handleSubmitConfirmPassword = async (callback) => {
    //   if (this.state.password === "") {
    //     this.setState({
    //       isOpenModalPassword: true,
    //     });
    //     return false;
    //   } else {
    //     if (!(await CheckConfirmPassword(this.state.password))) {
    //       NotificatioErrController("Xác thực mật khẩu không thành công")
    //       return false;
    //     }
    //     callback();
    //   }
    // }

    render() {
        let { isLoading, tableData, floor } = this.state;
        // console.log(tableData);

        const { rowTableTooltip, colTableTooltip, floorTableTooltip } =
            this.state;

        if (!isR(ROLE_ALIAS))
            return (
                <Card className='text-muted mh-40vh h-100'>
                    <CardBody className='d-flex justify-content-center align-items-center'>
                        Bạn không có quyền sử dụng tính năng này!
                    </CardBody>
                </Card>
            );

        return !isLoading ? (
            <div className='loading' />
        ) : (
            <React.Fragment>
                {/* <PasswordBox
            password={password}
            onChange={this.handleChangeValueModalPassword}
            isOpen={isOpenModalPassword}
            toggleModal={this.handleChangeValueModalIsOpen}
            onSubmit={this.handleSubmitConfirmPassword}
            callback={passwordFuncCallback}
          /> */}
                <Row>
                    <Colxx xxs='12'>
                        <Breadcrumb
                            heading='menu.table-setting'
                            match={this.props.match}
                        />
                        <Separator className='mb-5' />
                    </Colxx>
                </Row>
                <Row className='mb-4'>
                    <Colxx xxs='12'>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <IntlMessages id='table-setting.title' />
                                </CardTitle>
                                <Form>
                                    <FormGroup row>
                                        <Colxx sm={3}>
                                            <FormGroup className='has-float-label'>
                                                <Label for='row'>
                                                    <IntlMessages id='table-setting.row' />
                                                    <TooltipItem
                                                        item={rowTableTooltip}
                                                        id={17}
                                                    />
                                                </Label>
                                                <Input
                                                    type='text'
                                                    name='row'
                                                    id='row'
                                                    value={tableData.row}
                                                    onChange={(e) => {
                                                        this.handleChangeValue(
                                                            e
                                                        );
                                                    }}
                                                />
                                            </FormGroup>
                                        </Colxx>
                                        <Colxx sm={3}>
                                            <FormGroup className='has-float-label'>
                                                <Label for='col'>
                                                    <IntlMessages id='table-setting.col' />
                                                    <TooltipItem
                                                        item={colTableTooltip}
                                                        id={18}
                                                    />
                                                </Label>
                                                <Input
                                                    type='text'
                                                    name='col'
                                                    id='col'
                                                    value={tableData.col}
                                                    onChange={(e) => {
                                                        this.handleChangeValue(
                                                            e
                                                        );
                                                    }}
                                                />
                                            </FormGroup>
                                        </Colxx>
                                        <Colxx sm={3}>
                                            <FormGroup className='has-float-label'>
                                                <Label for='floor'>
                                                    <IntlMessages id='table-setting.floor' />
                                                    <TooltipItem
                                                        item={floorTableTooltip}
                                                        id={19}
                                                    />
                                                </Label>
                                                <Input
                                                    type='text'
                                                    name='floor'
                                                    id='floor'
                                                    value={floor}
                                                    onChange={(e) => {
                                                        this.handleChangeValue(
                                                            e
                                                        );
                                                    }}
                                                />
                                            </FormGroup>
                                        </Colxx>
                                        <Colxx sm={3} className='center-item'>
                                            <Button
                                                className={classnames({
                                                    disable: !isF(ROLE_ALIAS),
                                                })}
                                                block
                                                onClick={this.onSubmitGenerate}
                                                color='primary'
                                            >
                                                <IntlMessages id='table-setting.btn-save' />{' '}
                                                {` khu ${floor}`}
                                            </Button>
                                        </Colxx>
                                        {tableData.row && tableData.col ? (
                                            <Colxx
                                                xxs='12'
                                                className='table__container__wrapper'
                                            >
                                                <TableChairGenerateMap
                                                    data={tableData}
                                                />
                                            </Colxx>
                                        ) : (
                                            <p>Đang tải ...</p>
                                        )}
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                    </Colxx>
                </Row>
                <Separator />
                <FooterPage>
                    <FooterTableSetting />
                </FooterPage>
            </React.Fragment>
        );
    }
}

export default TableSetting;
