import React, { memo } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Select from 'react-select';
import CustomSelectInput from '../../../components/common/CustomSelectInput';

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    user_roles,
    values,
    setting,
    onChange,
}) => {
    // console.log('render AddComponent');
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
            autoFocus={false}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        <FormGroup>
                            <Label for='name' className='text-muted'>
                                <IntlMessages id='user-role.name' />
                            </Label>
                            <Input
                                autoFocus={true}
                                type='text'
                                name='name'
                                id='name'
                                disabled={setting.viewonly && !setting.add}
                                value={values.name}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for='username' className='text-muted'>
                                <IntlMessages id='profile.username' />
                            </Label>
                            <Input
                                type='text'
                                name='username'
                                id='username'
                                disabled={setting.viewonly && !setting.add}
                                value={values.username}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for='email' className='text-muted'>
                                <IntlMessages id='email' />
                            </Label>
                            <Input
                                type='text'
                                name='email'
                                id='email'
                                disabled={setting.viewonly && !setting.add}
                                value={values.email}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for='password' className='text-muted'>
                                <IntlMessages id='user.password' />
                            </Label>
                            <Input
                                type='password'
                                name='password'
                                id='password'
                                placeholder={
                                    setting.add
                                        ? ''
                                        : 'Để trống nếu không thay đổi'
                                }
                                disabled={setting.viewonly && !setting.add}
                                value={values.password}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for='repassword' className='text-muted'>
                                <IntlMessages id='user.re-password' />
                            </Label>
                            <Input
                                type='password'
                                name='repassword'
                                id='repassword'
                                placeholder={
                                    setting.add
                                        ? ''
                                        : 'Để trống nếu không thay đổi'
                                }
                                disabled={setting.viewonly && !setting.add}
                                value={values.repassword}
                                onChange={onChange}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for='user_role_id' className='text-muted'>
                                <IntlMessages id='role.title' />
                            </Label>
                            <Select
                                components={{ Input: CustomSelectInput }}
                                className='react-select'
                                classNamePrefix='react-select'
                                name='user_role_id'
                                value={values.user_role_id}
                                isDisabled={setting.viewonly && !setting.add}
                                placeholder={
                                    <IntlMessages id='user-role.select-one' />
                                }
                                options={user_roles}
                                onChange={(e) => {
                                    onChange(e, 'user_role_id');
                                }}
                            />
                        </FormGroup>
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='user-role.btn-add' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default memo(ContentComponent);
