import React, { memo } from 'react';
import { Colxx } from '../../../components/common/CustomBootstrap';
import STATUS from '../../../constants/status';
import OrderStatus from '../../../containers/dashboards/OrderStatus';

const ListOrderStatus = ({
    itemsOrderStatus,
    itemsPending,
    itemsPendingBill,
    itemsDone,
    onCheckItemPending,
    onCheckItemRefund,
    onCheckItemDone,
    onActions,
    selectedItemsPending,
    selectedItemsRefund,
    selectedItemsDone,
    companyName,
    companyAddress,
    companyLogo,
    companyWebsite,
    billDate,
    pin_print,
    handleClickedPrintInvoice,
    switchCheckedBillQr,
    itemsTable,
    switchCheckedOrderTwo,
}) => {
    return (
        <>
            <Colxx className='p-0 mb-3 max-h-170'>
                <OrderStatus
                    popupTitle={'Danh sách các sản phẩm chưa nhận'}
                    title={`${itemsOrderStatus?.order} sản phẩm chưa nhận`}
                    detail={'/Tổng số sản phẩm được order'}
                    percent={
                        (itemsOrderStatus?.order * 100) /
                        itemsOrderStatus?.totalOrder
                    }
                    progressText={`${itemsOrderStatus?.order}/${itemsOrderStatus?.totalOrder}`}
                    items={itemsPending?.filter(
                        (o) => o.status.uuid === STATUS.CREATED
                    )}
                    onCheckItem={onCheckItemPending}
                    onActions={onActions}
                    selectedItems={selectedItemsPending}
                    switchCheckedBillQr={switchCheckedBillQr}
                    alias={111}
                    role='orders_created'
                    emptyText={'Các sản phẩm đã được nhận hết'}
                />
            </Colxx>
            <Colxx className='p-0 mb-3 max-h-170'>
                <OrderStatus
                    popupTitle={'Danh sách các sản phẩm chưa giao'}
                    title={`${itemsOrderStatus?.pending} sản phẩm chưa giao`}
                    detail={'/Tổng sản phẩm đã nhận'}
                    percent={
                        (itemsOrderStatus?.pending * 100) /
                        itemsOrderStatus?.totalPendingPayment
                    }
                    progressText={`${itemsOrderStatus?.pending}/${itemsOrderStatus?.totalPendingPayment}`}
                    items={itemsPending?.filter(
                        (o) => o.status.uuid !== STATUS.CREATED
                    )}
                    onCheckItem={onCheckItemRefund}
                    onActions={onActions}
                    selectedItems={selectedItemsRefund}
                    alias={222}
                    role='orders_pending'
                    emptyText={'Các sản phẩm đã được giao hết'}
                    switchCheckedOrderTwo={switchCheckedOrderTwo}
                    companyName={companyName}
                    companyAddress={companyAddress}
                    companyLogo={companyLogo}
                    companyWebsite={companyWebsite}
                />
            </Colxx>

            <Colxx className='p-0 mb-3 max-h-170' id='list-orders-status'>
                <OrderStatus
                    popupTitle={'Danh sách các bàn chưa thu tiền'}
                    title={`${itemsOrderStatus?.tablePendingPayment} bàn chưa thu tiền`}
                    detail={'/Tổng bàn có khách trong ngày'}
                    percent={
                        (itemsOrderStatus?.tablePendingPayment * 100) /
                        itemsOrderStatus?.totalTableHasGuest
                    }
                    progressText={`${itemsOrderStatus?.tablePendingPayment}/${itemsOrderStatus?.totalTableHasGuest}`}
                    items={itemsDone?.filter(
                        (o) => o.status.uuid === STATUS.COMPLETED
                    )}
                    onCheckItem={onCheckItemDone}
                    onActions={onActions}
                    selectedItems={selectedItemsDone}
                    alias={444}
                    role='orders_table_pending_payment'
                    emptyText={'Các bàn đã được thanh toán hết'}
                    itemsPendingBill={itemsPendingBill}
                    companyName={companyName}
                    companyAddress={companyAddress}
                    companyLogo={companyLogo}
                    companyWebsite={companyWebsite}
                    billDate={billDate}
                    pin_print={pin_print}
                    handleClickedPrintInvoice={handleClickedPrintInvoice}
                    itemsTable={itemsTable}
                />
            </Colxx>
        </>
    );
};

export default memo(ListOrderStatus);
