import React, { Component } from 'react';
import { Collapse, Button, Row, CustomInput } from 'reactstrap';
import { NotificatioErrController } from '../../../helpers/Utils';
import axios from 'axios';
import { END_POINT } from '../../../constants/defaultValues';
import { ROLE_PERMISSION } from '../../../helpers/Roles';
import classnames from 'classnames';

export default class ContentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            data: [],
            collapse: false,
            accordion: [true, false, false],
        };
    }

    componentDidMount() {
        this.dataRender();
    }

    dataRender() {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/user-role/role'}`, {
                headers: {
                    Authorization: `Bearer ${tokenStr}`,
                },
            })
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                let anyAccordion = [];
                let numeroPerguntas = data.data.length;
                for (var i = 0; i < numeroPerguntas; i++) {
                    if (!i) {
                        anyAccordion.push(true);
                    }
                    anyAccordion.push(false);
                }

                this.setState({
                    data: data.data,
                    isLoading: true,
                    accordion: anyAccordion,
                });
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    }

    toggleAccordion = (tab) => {
        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => (tab === index ? !x : false));
        this.setState({
            accordion: state,
        });
    };

    toggle = () => {
        this.setState({ collapse: !this.state.collapse });
    };

    onChangePermission = ({ alias, permission }) => {
        let permissions = null;

        if (permission === 'F') {
            const find_role = this.state.data.find(
                (role) => role.alias === alias
            );
            permissions = find_role.permissions.split('|');
        }

        this.props.onChangePermission({ alias, permission, permissions });
    };

    isDisabledPermission = ({ alias, permission }) => {
        if (permission === 'C' || permission === 'U' || permission === 'D') {
            const find_role_R = this.props.settings.find(
                (setting) =>
                    setting.role_alias === alias && setting.permission === 'R'
            );

            if (!find_role_R) return true;
        }

        return false;
    };

    permissionsRender = (alias, permissions, disabled) => {
        const data = permissions.split('|');
        return data.map((permission, idx) => (
            <CustomInput
                disabled={
                    disabled || this.isDisabledPermission({ alias, permission })
                }
                key={idx}
                className={classnames('custom-checkbox mb-0 d-block', {
                    'ml-20px':
                        permission === 'C' ||
                        permission === 'U' ||
                        permission === 'D',
                })}
                type='checkbox'
                id={`${alias}_${permission}`}
                checked={
                    this.props.settings.find(
                        (setting) =>
                            setting.role_alias === alias &&
                            setting.permission === permission
                    ) || false
                }
                onChange={() => {
                    this.onChangePermission({ alias, permission });
                }}
                label={
                    <span className={`custom-control-label cursor-pointer`}>
                        {ROLE_PERMISSION.get(permission)}
                    </span>
                }
            />
        ));
    };

    render() {
        const { isLoading, data } = this.state;
        // console.log('data: ', data);

        return !isLoading ? (
            <div className='loading' />
        ) : (
            data.map((item, idx) => {
                return (
                    <div className='d-flex mb-3 card' key={item.alias}>
                        <div className='d-flex flex-grow-1 min-width-zero'>
                            <Button
                                color='link'
                                onClick={() => this.toggleAccordion(idx)}
                                aria-expanded={this.state.accordion[idx]}
                                className='card-body btn-empty btn-link list-item-heading text-left text-one btn btn-link pre-wrap'
                            >
                                {item.name}
                            </Button>
                        </div>
                        <Collapse isOpen={this.state.accordion[idx]}>
                            <div className='card-body accordion-content pt-0 overflow-hidden'>
                                {this.permissionsRender(
                                    item.alias,
                                    item.permissions,
                                    this.props.disabled
                                )}
                            </div>
                        </Collapse>
                    </div>
                );
            })
        );
    }
}
