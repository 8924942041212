import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AppLayoutPaymenter from "../../layout/AppLayoutPaymenter";
import defaultContent from './default';

const ContentComponent = ({ match }) => (
    <AppLayoutPaymenter>
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/default`} />
            <Route path={`${match.url}/default`} component={defaultContent} />
            <Route path={`${match.url}`} component={defaultContent} />
            <Redirect to="/error" />

        </Switch>
    </AppLayoutPaymenter>
);
export default ContentComponent;