import React, { Component, Fragment } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card, CardBody, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import ChatApplicationMenu from '../../../containers/pages/message/ChatApplicationMenu';
import ChatHeading from '../../../containers/pages/message/ChatHeading';
import MessageCard from '../../../containers/pages/message/MessageCard';
import SaySomething from '../../../containers/pages/message/SaySomething';
import io from 'socket.io-client';
import { END_POINT_SOCKET } from '../../../constants/defaultValues';
import axios from 'axios';
import { END_POINT } from '../../../constants/defaultValues';
import {
    NotificatioErrController,
    IsRedirectFromAlert,
} from '../../../helpers/Utils';
import { isF, isR } from '../../../helpers/Roles';

const ROLE_ALIAS = 'message';

class ChatApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuActiveTab: 'messages',
            USER_PARENT_ID: localStorage.getItem('b'),
            messageInput: '',
            selectedConversation: true,
            searchKey: '',
            inboxsData: [],
            originalInboxsData: undefined,
            conversationData: [],
            originalConversationData: [],
            selectedUser: null,
            allUsersData: [],
            files: null,
            isLoadedMore: false,
            isEndMessagesData: false,
            isFirstLoad: true,
            aboutProps: {
                message_item_id: 0,
                message_user_id: 0,
            },
        };

        this.socket = io(END_POINT_SOCKET);
    }

    // axios
    allUsersDataRender = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/message/all_contacts'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                this.setState({
                    allUsersData: data.data,
                    originalAllUsersData: data.data,
                });
            });
    };

    componentDidUpdate(prevProps, prevState) {
        if (IsRedirectFromAlert(this.props)) {
            if (!this.state.inboxsData.length) {
                // call get inbox
                this.socket.emit('message get inbox', {
                    // user_id: localStorage.getItem('user_id'),
                    user_id: this.state.USER_PARENT_ID,
                });
            } else {
                // [] ! null
                const { message_item_id, message_user_id } =
                    this.props.location.aboutProps;
                const data = this.state.inboxsData;

                let selectedUserDefault = null;
                if (message_item_id.indexOf('.') >= 0) {
                    // guest
                    let table_chair_id = message_item_id.split('.')[0];
                    let sender_id = message_item_id.split('.')[1];
                    let receive_id = message_user_id;

                    selectedUserDefault = data.filter(
                        (ibx) =>
                            ibx.sender_id == sender_id &&
                            ibx.receive_id == receive_id &&
                            ibx.table_chair_id == table_chair_id
                    );
                } else {
                    // store
                    selectedUserDefault = data.filter(
                        (ibx) =>
                            [ibx.friend_id, ibx.user_id].includes(
                                parseInt(message_user_id)
                            ) &&
                            [ibx.friend_id, ibx.user_id].includes(
                                parseInt(message_item_id)
                            )
                    );
                }
                selectedUserDefault = selectedUserDefault.length
                    ? selectedUserDefault[0]
                    : null;

                // get conversation
                if (selectedUserDefault) {
                    if (prevState.selectedUser) {
                        if (
                            selectedUserDefault.id == prevState.selectedUser.id
                        ) {
                            return;
                        }
                    }

                    this.changeConversation(selectedUserDefault);

                    // if (selectedUserDefault.sender_id) {
                    //   // guest
                    //   this.handleGetConversationGuest(selectedUserDefault);
                    // }
                    // else {
                    //   // store
                    //   this.handleGetConversation(selectedUserDefault)
                    // }
                }
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.location && props.location.aboutProps) {
            const { message_item_id, message_user_id } =
                props.location.aboutProps;

            if (
                message_item_id &&
                message_user_id &&
                message_item_id != state.aboutProps.message_item_id &&
                message_user_id != state.aboutProps.message_user_id
            ) {
                return {
                    aboutProps: {
                        message_item_id: message_item_id,
                        message_user_id: message_user_id,
                    },
                };
            }
        }
        return null;
    }

    componentWillUnmount() {
        this.socket.close();
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });
        this.socket.emit('join room', {
            room_id: this.state.USER_PARENT_ID,
        });

        this.socket.emit('message get inbox', {
            // user_id: localStorage.getItem('user_id'),
            user_id: this.state.USER_PARENT_ID,
        });

        this.socket.on('message get inbox', (res) => {
            const {
                data: { data },
            } = res;

            this.setState({
                inboxsData: data,
                originalInboxsData: data,
            });
        });

        this.socket.on('message get conversation', (res) => {
            const {
                data: { data, isEnd },
            } = res;

            if (this.state.selectedUser) {
                if (!data.length) return;

                let lastData = data[data.length - 1];

                // not update myself
                if (lastData.user_id != this.state.USER_PARENT_ID) {
                    // console.log("Me received, update seen: ", lastData);

                    this.socket.emit('message update seen conversation', {
                        // user_id_will_alert: localStorage.getItem('user_id'),
                        user_id_will_alert: this.state.USER_PARENT_ID,
                        user_id: lastData.user_id,
                        friend_id: lastData.friend_id,
                        message: lastData,
                    });
                }

                this.socket.emit('message get inbox', {
                    // user_id: localStorage.getItem('user_id'),
                    user_id: this.state.USER_PARENT_ID,
                });

                this.setState({
                    conversationData: data,
                    isLoadedMore: true,
                    isEndMessagesData: isEnd,
                });
            }
        });

        this.socket.on('message get conversation guest', (res) => {
            const {
                data: { data, isEnd },
            } = res;
            // let id = localStorage.getItem("user_id");
            let id = this.state.USER_PARENT_ID;

            if (
                this.state.selectedUser &&
                (this.state.selectedUser.sender_id == id ||
                    this.state.selectedUser.receive_id == id)
            ) {
                if (!data.length) return;

                let lastData = data[data.length - 1];

                if (
                    IsRedirectFromAlert(this.props) ||
                    (lastData.table_chair_id ===
                        this.state.selectedUser.table_chair_id &&
                        (lastData.sender_id ===
                            this.state.selectedUser.sender_id ||
                            lastData.sender_id ===
                                this.state.selectedUser.receive_id))
                ) {
                    // not update myself
                    if (lastData.sender_id != this.state.USER_PARENT_ID) {
                        // console.log("GUEST: Me received, update seen: ", lastData);

                        this.socket.emit(
                            'message update seen conversation guest',
                            {
                                user_id_will_alert: this.state.USER_PARENT_ID,
                                user_id: this.state.USER_PARENT_ID,
                                pin_id: lastData.sender_id,
                                table_chair_id: lastData.table_chair_id,
                                message: lastData,
                            }
                        );
                    }

                    this.setState({
                        conversationData: data,
                        isLoadedMore: true,
                        isEndMessagesData: isEnd,
                    });
                }
            }

            this.socket.emit('message get inbox', {
                // user_id: localStorage.getItem('user_id'),
                user_id: this.state.USER_PARENT_ID,
            });
        });

        this.socket.on('message reload conversation', (res) => {
            const {
                id,
                data: { data },
            } = res;

            if (
                this.state.selectedUser &&
                (this.state.selectedUser.user_id == id ||
                    this.state.selectedUser.friend_id == id)
            ) {
                if (!data.length) return;

                let lastData = data[data.length - 1];

                // not update myself
                if (lastData.user_id != this.state.USER_PARENT_ID) {
                    // console.log("Me received, update seen: ", lastData);

                    this.socket.emit('message update seen conversation', {
                        user_id_will_alert: this.state.USER_PARENT_ID,
                        user_id: this.state.USER_PARENT_ID,
                        friend_id: id,
                        message: lastData,
                    });
                }
                this.setState({
                    conversationData: data,
                });
            }
            // else {
            //   if (!data.length)
            //     return;

            //   // not select user, new message unseen
            //   let lastData = data[data.length - 1];

            //   // console.log("create alert now, not select user or select user diff", lastData);

            //   // not user selected -> alert
            //   this.socket.emit('message create alert', {
            //     user_id_will_alert: localStorage.getItem('user_id'),
            //     message: lastData,
            //   })
            // }

            this.socket.emit('message get inbox', {
                // user_id: localStorage.getItem('user_id'),
                user_id: this.state.USER_PARENT_ID,
            });
        });

        this.socket.on('broadcast', () => {
            // receive from broadcast

            const { selectedUser } = this.state;
            let selectedUserId = 0;
            if (selectedUser) {
                selectedUserId =
                    selectedUser.user_id != this.state.USER_PARENT_ID
                        ? selectedUser.user_id
                        : selectedUser.friend_id;
            }

            // reload conversion
            if (selectedUserId) {
                this.socket.emit('message get conversation', {
                    user_id: this.state.USER_PARENT_ID,
                    friend_id: selectedUserId,
                });
            }

            // reload inbox
            this.socket.emit('message get inbox', {
                user_id: this.state.USER_PARENT_ID,
            });
        });

        // get all user data;
        this.allUsersDataRender();
    }

    // Main conversion GUEST
    getConversationGuest = (selectedUser) => {
        let pin_id =
            selectedUser.sender_id != this.state.USER_PARENT_ID
                ? selectedUser.sender_id
                : selectedUser.receive_id;

        let offset = 0;
        if (this.state.conversationData) {
            offset = this.state.conversationData.length;
        }

        this.socket.emit('message get conversation guest', {
            user_id: this.state.USER_PARENT_ID,
            table_chair_id: selectedUser.table_chair_id,
            pin_id,
            offset,
        });
    };
    handleGetConversationGuest = (selectedUser) => {
        this.getConversationGuest(selectedUser);
        this.setState({ selectedUser, isLoadedMore: false });
    };
    handleSendToConversationGuest = () => {
        if (!isF(ROLE_ALIAS)) return false;

        let { messageInput, selectedUser } = this.state;

        let pin_id =
            selectedUser.sender_id != this.state.USER_PARENT_ID
                ? selectedUser.sender_id
                : selectedUser.receive_id;

        this.socket.emit('message send conversation guest', {
            user_id: this.state.USER_PARENT_ID,
            sender_id: this.state.USER_PARENT_ID,
            pin_id: pin_id,
            message: messageInput,
            table_chair_id: selectedUser.table_chair_id,
            real_id: localStorage.getItem('user_id'),
        });
    };

    // Main conversion
    // Files
    onChangeFile = (e) => {
        if (!isF(ROLE_ALIAS)) return false;

        if (e.target.files.length > 3) {
            NotificatioErrController('Tối đa 3 tệp tin được cho phép');
            return;
        }

        if (this.state.selectedUser.sender_id) {
            NotificatioErrController('Không hỗ trợ gửi file cho khách');
            return;
        }

        for (let i = 0; i < e.target.files.length; i++) {
            let file = e.target.files[i];

            if (file.size >= 100000000) {
                NotificatioErrController('Kích thước tối đa 100 MB / tệp');
                return;
            }
        }

        this.setState({ files: e.target.files });
    };
    onSelectedFile = (e) => {
        this.setState({ files: null });
    };
    handleSendFilesToConversation = (selectedUserId) => {
        if (!isF(ROLE_ALIAS)) return false;

        let { files } = this.state;

        // files
        for (let i = 0; i < files.length; i++) {
            let file = files[i];

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (evt) => {
                let arrayData = reader.result;

                let template = {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    data: arrayData,
                };

                this.socket.emit('message send conversation', {
                    user_id: this.state.USER_PARENT_ID,
                    friend_id: selectedUserId,
                    file: template,
                    real_id: localStorage.getItem('user_id'),
                });
            };
        }
    };

    getConversation = (selectedUser) => {
        let selectedUserId =
            selectedUser.user_id != this.state.USER_PARENT_ID
                ? selectedUser.user_id
                : selectedUser.friend_id;

        let offset = 0;
        if (this.state.conversationData) {
            offset = this.state.conversationData.length;
        }

        this.socket.emit('message get conversation', {
            user_id: this.state.USER_PARENT_ID,
            friend_id: selectedUserId,
            offset,
        });
    };
    handleGetConversation = (selectedUser) => {
        this.getConversation(selectedUser);
        this.setState({ selectedUser, isLoadedMore: false });
    };
    handleSendToConversation = () => {
        if (!isF(ROLE_ALIAS)) return false;

        let { messageInput, selectedUser, files } = this.state;

        // selected user
        let selectedUserId =
            selectedUser.user_id != this.state.USER_PARENT_ID
                ? selectedUser.user_id
                : selectedUser.friend_id;

        // files
        if (files) {
            this.handleSendFilesToConversation(selectedUserId);
        }

        this.socket.emit('message send conversation', {
            user_id: this.state.USER_PARENT_ID,
            friend_id: selectedUserId,
            message: messageInput,
            real_id: localStorage.getItem('user_id'),
        });
    };
    handleChatInputPress = (e) => {
        if (!isF(ROLE_ALIAS)) return false;

        if (e.key === 'Enter') {
            this.handleSendButtonClick();
        }
    };
    handleChatInputChange = (e) => {
        if (!isF(ROLE_ALIAS)) return false;

        this.setState({
            messageInput: e.target.value,
        });
    };
    handleSendButtonClick = () => {
        let { messageInput, selectedUser, files } = this.state;

        if (messageInput != null || files != null) {
            if (
                (messageInput && messageInput.length > 0) ||
                (files && files.length > 0)
            ) {
                if (selectedUser.sender_id) {
                    // guest
                    this.handleSendToConversationGuest();
                } else {
                    this.handleSendToConversation();
                }

                this.setState({
                    messageInput: '',
                    menuActiveTab: 'messages',
                    files: null,
                });
            }
        }
    };

    // Menu
    toggleAppMenu = (tab) => {
        this.setState({
            menuActiveTab: tab,
        });
    };

    changeConversation = (selectedUser) => {
        this.setState(
            {
                selectedUser,
                conversationData: [],
                isFirstLoad: true,
            },
            () => {
                if (selectedUser.sender_id) {
                    // guest
                    this.handleGetConversationGuest(selectedUser);
                } else {
                    this.handleGetConversation(selectedUser);
                }
            }
        );
    };

    isLoaded = (e) => {
        this.setState({ isFirstLoad: false });
    };

    handleSearchContact = (keyword) => {
        const { originalAllUsersData, originalInboxsData, menuActiveTab } =
            this.state;

        this.setState({
            searchKey: keyword,
        });

        if (keyword.length > 0) {
            // this.props.toggleAppMenu("contacts")

            let dataSearch = [];
            if (menuActiveTab == 'contacts') {
                // allUsersData
                if (keyword[0] === '#') {
                    // uuid
                    dataSearch = originalAllUsersData.filter(
                        (u) => ('#' + u.companyUUID).indexOf(keyword) >= 0
                    );
                } else {
                    // name
                    dataSearch = originalAllUsersData.filter(
                        (u) =>
                            (u.companyName &&
                                u.companyName
                                    .toLowerCase()
                                    .indexOf(keyword.toLowerCase().trim()) >=
                                    0) ||
                            (u.fullname &&
                                u.fullname
                                    .toLowerCase()
                                    .indexOf(keyword.toLowerCase().trim()) >= 0)
                    );
                }

                this.setState({
                    allUsersData: dataSearch,
                });
            } else {
                // inboxsData
                if (keyword[0] === '#') {
                    // uuid
                    dataSearch = originalInboxsData.filter(
                        (u) =>
                            ('#' + u['friend.companyUUID']).indexOf(keyword) >=
                            0
                    );
                } else {
                    // name
                    dataSearch = originalInboxsData.filter(
                        (u) =>
                            (u['friend.companyName'] &&
                                u['friend.companyName']
                                    .toLowerCase()
                                    .indexOf(keyword.trim().toLowerCase()) >=
                                    0) ||
                            (u['friend.fullname'] &&
                                u['friend.fullname']
                                    .toLowerCase()
                                    .indexOf(keyword.trim().toLowerCase()) >=
                                    0) ||
                            (u['user.companyName'] &&
                                u['user.companyName']
                                    .toLowerCase()
                                    .indexOf(keyword.trim().toLowerCase()) >=
                                    0) ||
                            (u['user.fullname'] &&
                                u['user.fullname']
                                    .toLowerCase()
                                    .indexOf(keyword.trim().toLowerCase()) >= 0)
                    );
                }
                this.setState({
                    inboxsData: dataSearch,
                });
            }
        } else {
            this.setState({
                allUsersData: this.state.originalAllUsersData,
                inboxsData: this.state.originalInboxsData,
            });
        }
    };

    render() {
        const {
            menuActiveTab,
            messageInput,
            inboxsData,
            conversationData,
            selectedUser,
            allUsersData,
            searchKey,
            files,
            isLoadedMore,
            isEndMessagesData,
            isFirstLoad,
        } = this.state;

        if (!isR(ROLE_ALIAS))
            return (
                <Card className='text-muted mh-40vh h-100'>
                    <CardBody className='d-flex justify-content-center align-items-center'>
                        Bạn không có quyền sử dụng tính năng này!
                    </CardBody>
                </Card>
            );

        return (
            <Fragment>
                <Row className='app-row-message'>
                    {selectedUser ? (
                        <Colxx xxs='12' className='chat-app'>
                            <ChatHeading item={selectedUser} />
                            <PerfectScrollbar
                                onYReachStart={(container) => {
                                    if (!isEndMessagesData)
                                        container.scrollTop = 63 * 8 - 33; // height * size - (height / 2)

                                    if (
                                        isLoadedMore &&
                                        !isEndMessagesData &&
                                        !isFirstLoad
                                    ) {
                                        // console.log("load more");

                                        setTimeout(() => {
                                            if (selectedUser.sender_id) {
                                                // guest
                                                this.handleGetConversationGuest(
                                                    selectedUser
                                                );
                                            } else {
                                                this.handleGetConversation(
                                                    selectedUser
                                                );
                                            }
                                        }, 1);
                                    }

                                    if (isFirstLoad) {
                                        this.isLoaded();
                                    }
                                }}
                                ref={(ref) => {
                                    this._scrollBarRef = ref;
                                }}
                                containerRef={(ref) => {}}
                                options={{
                                    suppressScrollX: true,
                                    wheelPropagation: false,
                                }}
                            >
                                {conversationData.map((item, idx) => (
                                    <div key={idx} ref={`message_${idx}`}>
                                        <MessageCard idx={idx} item={item} />
                                    </div>
                                ))}
                            </PerfectScrollbar>
                            <SaySomething
                                isDisabled={!isF(ROLE_ALIAS)}
                                classNames='chat-input-container d-flex justify-content-between align-items-center'
                                placeholder={'Viết tin nhắn'}
                                messageInput={messageInput}
                                handleSendButtonClick={
                                    this.handleSendButtonClick
                                }
                                handleChatInputChange={
                                    this.handleChatInputChange
                                }
                                handleChatInputPress={this.handleChatInputPress}
                                onChangeFile={this.onChangeFile}
                                onSelectedFile={this.onSelectedFile}
                                filesLength={files ? files.length : 0}
                            />
                        </Colxx>
                    ) : (
                        <Colxx xxs='12' className='chat-app'>
                            <div className='message-default-content'>
                                <p>Bắt đầu cuộc trò chuyện</p>
                            </div>
                        </Colxx>
                    )}
                </Row>
                <ChatApplicationMenu
                    isDisabled={!isF(ROLE_ALIAS)}
                    messagesData={inboxsData}
                    activeTab={menuActiveTab}
                    toggleAppMenu={this.toggleAppMenu}
                    changeConversation={this.changeConversation}
                    allUsersData={allUsersData}
                    handleSearchContact={this.handleSearchContact}
                    searchKey={searchKey}
                />
            </Fragment>
        );
    }
}

export default ChatApp;
