import React, { Component } from 'react';
import {
    Card,
    Badge,
    ButtonDropdown,
    CustomInput,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { connect } from 'react-redux';
import {
    setContainerClassnames,
    clickOnMobileMenu,
} from '../../../redux/actions';
import IntlMessages from '../../../helpers/IntlMessages';
import classnames from 'classnames';
import { Separator } from '../../../components/common/CustomBootstrap';

class LabelItemLeft extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdownSplitOpen: false,
        };
    }

    toggleSplit = () => {
        this.setState((prevState) => ({
            dropdownSplitOpen: !prevState.dropdownSplitOpen,
        }));
    };

    menuButtonClick = (e, menuClickCount, containerClassnames) => {
        setTimeout(() => {
            var event = document.createEvent('HTMLEvents');
            event.initEvent('resize', false, false);
            window.dispatchEvent(event);
        }, 350);
        this.props.setContainerClassnames(
            menuClickCount,
            containerClassnames,
            this.props.selectedMenuHasSubItems
        );
    };

    onClickedItem = (item) => {
        this.props.onClickedItem(
            // callback
            item,
            this.menuButtonClick,
            [null, 1000, this.props.containerClassnames]
        );
    };

    render() {
        const { dropdownSplitOpen } = this.state;
        const user_id = localStorage.getItem('user_id');
        // console.log('user_id: ', user_id);
        const user_created_id = this.props.item.user_created_id;
        // console.log('user_created_id: ', user_created_id);

        // console.log(this.props.item);

        return (
            <Card className='mb-3 custom-shadow'>
                <div className='d-flex flex-row cursor-pointer'>
                    <div className={'d-flex flex-grow-1 min-width-zero'}>
                        {this.props.item.at ? (
                            <div
                                onClick={() =>
                                    this.onClickedItem(this.props.item)
                                }
                                className={classnames(
                                    'card-body align-self-center d-flex flex-ssm-column justify-content-between min-width-zero',
                                    {
                                        'flex-lg-row align-items-lg-center':
                                            !this.props.setting.update,
                                    }
                                )}
                            >
                                <div className='w-50 list-item-heading mb-1 truncate d-block w-sm-100'>
                                    <span>{this.props.item.stock}</span>
                                </div>
                                <div className='w-50'>
                                    {Number(user_id) !==
                                        Number(user_created_id) &&
                                    this.props.item.status ===
                                        'Chờ xét duyệt' ? (
                                        <>
                                            <Badge
                                                color={'success'}
                                                className='p-2 mr-1'
                                                onClick={() =>
                                                    this.props.showAlertApprove(
                                                        this.props.item
                                                    )
                                                }
                                                pill
                                            >
                                                Duyệt phiếu
                                            </Badge>
                                            <Badge
                                                color={'danger'}
                                                className='p-2'
                                                onClick={() =>
                                                    this.props.showAlertReject(
                                                        this.props.item.id
                                                    )
                                                }
                                                pill
                                            >
                                                Từ chối
                                            </Badge>
                                        </>
                                    ) : (
                                        <Badge
                                            color={
                                                this.props.item.status ===
                                                'Đã xét duyệt'
                                                    ? 'primary'
                                                    : this.props.item.status ===
                                                      'Chờ xét duyệt'
                                                    ? 'secondary'
                                                    : 'danger'
                                            }
                                            pill
                                        >
                                            {this.props.item.status}
                                        </Badge>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div
                                onClick={() =>
                                    this.onClickedItem(this.props.item)
                                }
                                className='card-body w-100 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center'
                            >
                                <div className='list-item-heading mb-1 truncate d-block'>
                                    <span>{this.props.item.stock}</span>
                                </div>
                            </div>
                        )}
                        <div className='custom-control custom-checkbox pl-1 align-self-center pr-4'>
                            <ButtonDropdown
                                isOpen={dropdownSplitOpen}
                                toggle={this.toggleSplit}
                                onClick={() =>
                                    this.onClickedItem(this.props.item)
                                }
                            >
                                <div className='cursor-pointer btn btn-lg btn-primary pl-4 pr-0 check-button check-all'>
                                    <CustomInput
                                        className='custom-checkbox mb-0 d-inline-block'
                                        type='checkbox'
                                        id={this.props.idx}
                                        checked={this.props.selectedItems.includes(
                                            this.props.item.id
                                        )}
                                        onChange={() =>
                                            this.props.onChangeCheckbox(
                                                this.props.item.id
                                            )
                                        }
                                        label={
                                            <span
                                                className={`custom-control-label`}
                                            />
                                        }
                                    />
                                </div>
                                <DropdownToggle
                                    caret
                                    color='primary'
                                    className='dropdown-toggle-split'
                                />
                                <DropdownMenu right>
                                    <DropdownItem
                                        className={classnames('text-left', {
                                            disable:
                                                this.props.isDisabledUpdate,
                                        })}
                                        onClick={() =>
                                            this.props.isDisabledUpdate
                                                ? null
                                                : this.props.onUpdate(
                                                      this.props.item
                                                  )
                                        }
                                    >
                                        <i className='simple-icon-note pr-2' />
                                        <IntlMessages id='pages.update' ˇ />
                                    </DropdownItem>
                                    <Separator />
                                    {/* <DropdownItem
                                        className={classnames('text-left', {
                                            disable:
                                                this.props.isDisabledUpdate,
                                        })}
                                        onClick={this.props.toggleFunc}
                                    >
                                        Thêm giao dịch
                                    </DropdownItem>
                                    <Separator /> */}
                                    <DropdownItem
                                        className={classnames('text-left red', {
                                            disable:
                                                this.props.isDisabledDelete,
                                        })}
                                        onClick={() =>
                                            this.props.isDisabledDelete
                                                ? null
                                                : this.props.onDelete(
                                                      this.props.item
                                                  )
                                        }
                                    >
                                        <>
                                            <i className='simple-icon-trash pr-2' />
                                            <IntlMessages id='pages.delete' />
                                        </>
                                    </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </div>
                    </div>
                </div>
            </Card>
        );
    }
}

const mapStateToProps = ({ menu }) => {
    const { containerClassnames, menuClickCount, selectedMenuHasSubItems } =
        menu;
    return {
        containerClassnames,
        menuClickCount,
        selectedMenuHasSubItems,
    };
};
export default connect(mapStateToProps, {
    setContainerClassnames,
    clickOnMobileMenu,
})(LabelItemLeft);
