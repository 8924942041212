import React from 'react';
import { Card, CardBody, CardTitle, Progress } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import IntlMessages from '../../helpers/IntlMessages';
import { isRorRBD } from '../../helpers/Roles';
import classnames from 'classnames';

const StockStatus = (props) => {
    // console.log(props);

    const unit = (x) => {
        return props.unit?.find((u) => u.name === x);
    };
    // console.log(unit('Sting'));

    return !props.items ? (
        <div className='loading' />
    ) : (
        <Card
            className={classnames('h-100 dashboard-list-with-thumbs-lg', {
                disable: !isRorRBD('analytics_stocks'),
            })}
        >
            <CardBody>
                <CardTitle>
                    <IntlMessages id='dashboards.stock-status' />
                </CardTitle>
                <div className='scroll dashboard-list-stock'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: true,
                        }}
                    >
                        {props.items.map((s, index) => {
                            // console.log(s);
                            return (
                                <div key={index} className='mb-4'>
                                    {!s.softDeleted && s.count !== 0  ? (
                                        <>
                                            <p className='mb-2'>
                                                {s.name}
                                                <span className='float-right text-muted'>
                                                    {'còn ' +
                                                        s.count +
                                                        ' ' +
                                                        (unit(s.name)?.unit ===
                                                        'undefind'
                                                            ? ''
                                                            : unit(s.name)?.unit)}{' '}
                                                    -{' '}
                                                    {`${Math.round(
                                                        (s.count / s.income) * 100
                                                    )}%`}
                                                </span>
                                            </p>
                                            <Progress
                                                value={(s.count / s.income) * 100}
                                            />
                                        </>
                                    ) : ''}
                                </div>
                            );
                        })}
                    </PerfectScrollbar>
                </div>
            </CardBody>
        </Card>
    );
};
export default StockStatus;
