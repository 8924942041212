import React from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Select from 'react-select';
import CustomSelectInput from '../../../components/common/CustomSelectInput';
import { SetType, GetType } from '../../../helpers/FormDynamic';

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    forms,
    values,
    setting,
    onChange,
}) => {
    // console.log('render UpdateComponent');
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='pad-125rem scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        <FormGroup>
                            <Label for='label' className='text-muted'>
                                <IntlMessages id='form-dynamic.label' />
                            </Label>
                            <Input
                                type='text'
                                name='label'
                                id='label'
                                value={values.label}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for='alias' className='text-muted'>
                                <IntlMessages id='form-dynamic.alias' />
                            </Label>
                            <Select
                                components={{ Input: CustomSelectInput }}
                                className='react-select'
                                classNamePrefix='react-select'
                                name='form'
                                value={values.form}
                                placeholder={
                                    <IntlMessages id='form-dynamic.select-one-alias' />
                                }
                                options={forms}
                                onChange={(e) => {
                                    onChange(e, 'form');
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for='type' className='text-muted'>
                                <IntlMessages id='form-dynamic.type' />
                            </Label>
                            <Select
                                components={{ Input: CustomSelectInput }}
                                className='react-select'
                                classNamePrefix='react-select'
                                name='type'
                                value={GetType(values.type)}
                                placeholder={
                                    <IntlMessages id='form-dynamic.select-one-type' />
                                }
                                options={SetType()}
                                onChange={(e) => {
                                    onChange(e, 'type');
                                }}
                            />
                        </FormGroup>
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='general.btn-update' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default React.memo(ContentComponent);
