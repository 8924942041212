import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import defaultBreakEven from './default';

const BreakEven = ({ match }) => (
    <div className='dashboard-wrapper'>
        <Switch>
            <Redirect
                exact
                from={`${match.url}/`}
                to={`${match.url}/default`}
            />
            <Route path={`${match.url}/default`} component={defaultBreakEven} />
            <Route path={`${match.url}`} component={defaultBreakEven} />
            <Redirect to='/error' />
        </Switch>
    </div>
);
export default BreakEven;
