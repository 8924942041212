import React from 'react';
import { Label, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { GetSelectTime } from '../../../helpers/SelectTime';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentDisplayMobile = (props) => {
    // console.log(props);
    return (
        <>
            <Row className='mt-3' xxs='12'>
                <Colxx xxs='12' lg='6'>
                    <p className='text-muted text-small mb-2'>Form được thêm</p>
                    <p className='text-capitalize'>
                        {props.values.label ? (
                            props.values.form.label
                        ) : (
                            <span className='text-lowercase'>
                                (Chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='12' lg='6'>
                    <p className='text-muted text-small mb-2'>
                        Tên trường dữ liệu
                    </p>
                    <p className='text-capitalize'>
                        {props.values.label ? (
                            props.values.label
                        ) : (
                            <span className='text-lowercase'>
                                (Chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='12' lg='6'>
                    <p className='text-muted text-small mb-2'>Kiểu dữ liệu</p>
                    <p>{props.values.type}</p>
                </Colxx>
            </Row>
        </>
    );
};

export default ContentDisplayMobile;
