import React from 'react';
import { Label, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';

const ContentDisplayMobile = (props) => {
    // console.log(props);
    return (
        <>
            <Row className='mt-3'>
                <Colxx xxs='12'>
                    <p className='text-muted text-small mb-2'>
                        <IntlMessages id='todo.title' />
                    </p>
                    <p>{props.values.title}</p>
                </Colxx>
                <Colxx xxs='12'>
                    <p className='text-muted text-small mb-2'>
                        <IntlMessages id='todo.description' />
                    </p>
                    <p>
                        {props.values.description ? (
                            props.values.description
                        ) : (
                            <span className='text-lowercase'>
                                (không có nội dung mô tả)
                            </span>
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='12'>
                    <p className='text-muted text-small mb-2'>
                        <IntlMessages id='todo.date-time' />
                    </p>
                    <p className='text-lowercase'>
                        {props.values.time + ' / ' + props.values.date}
                    </p>
                </Colxx>
            </Row>
        </>
    );
};

export default ContentDisplayMobile;
