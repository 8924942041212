import React, { Fragment } from "react";
import { getAvatar } from "../../../helpers/Utils";

const ChatHeading = ({ item }) => {
  return (
    <Fragment>
      <div className="d-flex flex-row chat-heading">
        <div className="d-flex">
          <img
            alt={"avatar"}
            src={getAvatar(item.avatar)}
            className="message-avatar img-thumbnail border-0 rounded-circle ml-0 mr-4 align-self-center small"
          />
        </div>
        <div className="d-flex min-width-zero">
          <div className="card-body pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
            <div className="min-width-zero">
              <div>
                <p className="list-item-heading mb-1 truncate ">{item.name}</p>
              </div>
              {/* <p className="mb-0 text-muted text-small">
                {getChatDisplayTime(item)}
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="separator mb-3" />
    </Fragment>
  );
};

export default ChatHeading;
