const enUs = {
    /* 00.General */
    'general.copyright': 'Coffee Management © 2019 All Rights Reserved.',
    'general.refresh': 'Làm mới danh sách',
    'general.btn-update': 'Cập nhật',
    'general.btn-update-count': 'Bổ sung',
    'general.btn-add': 'Thêm',
    'general.btn-order': 'Đặt sản phẩm ngay!',
    'general.btn-order-preview': 'Xem sản phẩm',
    'general.btn-create-make-product': 'Tạo định lượng',
    'general.btn-update-make-product': 'Cập nhật định lượng',
    'general.info': 'THÔNG TIN',
    'general.setting': 'CÀI ĐẶT',
    'general.function': 'TÍNH NĂNG',
    timeCreated: 'Thời gian tạo',

    /* 01.Order */
    'order.to-pending': 'Chuyển chế biến',
    'order.to-done': 'Chuyển thanh toán',
    'order.done': 'Hoàn tất',
    'order.to-refund': 'Huỷ do hết hàng',
    'order.to-change': 'Điều chỉnh',
    'order.to-bill-draft': 'In hoá đơn tạm tính',
    'order.to-bill': 'Xuất hóa đơn',
    'order.to-end': 'Hoàn tất',
    'order.print-bill': 'In hóa đơn',
    'order.to-excel': 'Tải về file Excel',
    'order.to-destroy': 'Hủy đơn hàng',
    'order.transfer-table': 'Chuyển bàn',
    'order.to-bill-now': 'In tạm tính',
    'order.to-bill-all': 'In hoá đơn tất cả',
    'order.open-menu': 'Thêm sản phẩm',
    'order.search': 'Tìm kiếm Mã hoá đơn',

    /* 02. Profile */
    'company-uuid': 'Mã cửa hàng',
    'company-name': 'Tên cửa hàng',
    'profile.fullname': 'Họ và tên',
    'profile.username': 'Tên đăng nhập',
    'profile.email': 'Email',
    'profile.address': 'Địa chỉ',
    'profile.description': 'Mô tả',
    'profile.avatar': 'Hình ảnh cá nhân',
    'profile.company-address': 'Địa chỉ cửa hàng',
    'profile.company-website': 'Website cửa hàng',
    'profile.company-logo': 'Logo cửa hàng',
    'profile.company-image': 'Hình ảnh cửa hàng',
    'profile.company-start-time': 'Thời gian mở cửa',
    'profile.company-end-time': 'Thời gian đóng cửa',
    'profile.company-menu-hash': 'Mã menu cửa hàng',
    'profile.company-menu-hash-create': 'Tạo mới',
    'profile.phone': 'Số điện thoại',
    'profile.birth-day': 'Sinh nhật',
    'profile.btn-update': 'Cập nhật',
    'pages.responsibilities': 'Chuyên môn',
    'menu.contact': 'Mạng xã hội',

    /* 03.User Login, Logout, Register */
    'user.login-title': 'Đăng nhập',
    'user.register': 'Đăng ký',
    'user.forgot-password': 'Quên mật khẩu',
    'user.username': 'Tên đăng nhập',
    'user.email': 'Email',
    'user.phone': 'Số điện thoại',
    'user.password': 'Mật khẩu',
    'user.re-password': 'Nhập lại mật khẩu',
    'user.forgot-password-question': 'Quên mật khẩu?',
    'user.reset-password-button': 'Nhận code mới',
    'user.reset-password-button-submit': 'Đổi mật khẩu',
    'user.fullname': 'Họ và tên',
    'user.login-button': 'Đăng nhập',
    'user.register-button': 'Đăng ký',
    'user.code': 'Code',

    /* 04.Menu */
    'menu.partner': 'Con người',
    'menu.money': 'Tài chính',
    'menu.m': 'Sản phẩm',
    'menu.warehouse': 'Kho',
    'menu.invoice': 'Hóa đơn',
    'menu.order': 'Hóa đơn',
    'menu.app': 'Trang chủ',
    'menu.dashboards': 'Trang chủ',
    'menu.calendars': 'Lịch',
    'menu.analytics': 'Báo cáo',
    'menu.faq': 'FAQ',
    'menu.view': 'Câu hỏi và trả lời',
    'menu.default': 'Tổng quát',
    'menu.content': 'Content',
    'menu.product': 'Sản phẩm',
    'menu.menu': 'Sản phẩm',
    'menu.pages': 'Quản lý',
    'menu.thumb-list': 'Quản lý sản phẩm',
    'menu.order': 'Order',
    'menu.search': 'Tìm kiếm',
    'menu.login': 'Đăng nhập',
    'menu.register': 'Đăng ký',
    'menu.company': 'Thông tin cửa hàng',
    'menu.profile': 'Thông tin cá nhân',
    'menu.forgot-password': 'Thay đổi mật khẩu',
    'menu.applications': 'Tài khoản',
    'menu.promotion': 'Khuyến mại',
    'menu.todo': 'Sự kiện',
    'menu.category': 'Loại sản phẩm',
    'menu.ui': 'UI',
    'menu.table-setting': 'Bàn ghế',
    'menu.stock': 'Nguyên vật liệu',
    'menu.warehouse-import': 'Phiếu nhập kho',
    'menu.warehouse-export': 'Phiếu xuất kho',
    'menu.warehouse-transfer': 'Phiếu chuyển kho',
    'menu.make-product': 'Định lượng',
    'menu.payment': 'Bàn chờ thanh toán',
    'menu.table-pending-payment': 'Bàn chờ thanh toán',
    'menu.message': 'Tin nhắn',
    'menu.ref': 'Giới thiệu người mới',
    'menu.upgrade': 'Nâng cấp',
    'menu.setting': 'Cài đặt',
    'menu.root': 'VMASS',
    'menu.user-staff': 'Nhân viên',
    'menu.customer': 'Khách hàng',
    'menu.company-provider': 'Nhà cung cấp',
    'menu.treasury': 'Tài sản',
    'menu.income': 'Phiếu thu',
    'menu.outcome': 'Phiếu chi',
    'menu.role': 'Tạo phân quyền ',
    'menu.form-dynamic': 'Form tùy chỉnh',
    'menu.user-role': 'Tạo tài khoản',
    'menu.qrcode': 'QR Code',
    'menu.companies': 'Liên kết đại lý',

    /* 05.Dashboards */
    'dashboards.pending-orders': 'Nhận',
    'dashboards.completed-orders': 'Đã giao, Thu tiền',
    'dashboards.refund-requests': 'Hết hàng, Hủy',
    'dashboards.sales': 'Nhận',
    'dashboards.sales-chart': 'Biểu đồ doanh thu, chi phí',
    'dashboards.orders': 'Đã giao',
    'dashboards.refunds': 'Hết hàng',
    'dashboards.calendar': 'Lịch',
    'dashboards.best-sellers': 'Danh sách các sản phẩm đã bán',
    'dashboards.this-week': 'Tuần',
    'dashboards.this-month': 'Tháng',
    'dashboards.this-all-sales': 'Tất cả doanh số',
    'dashboards.recent-orders': 'Recent Orders',
    'dashboards.stock-status': 'Thống kê nguyên vật liệu',
    'dashboards.logs': 'Hoạt động',
    'dashboards.message': 'Tin nhắn',

    /** Analytics */
    'analytics.dashboards': 'Tổng quát',
    'analytics.user-staff': 'Nhân viên',
    'analytics.customer': 'Khách hàng',
    'analytics.company-provider': 'Nhà cung cấp',
    'analytics.treasury': 'Tài sản',
    'analytics.income': 'Phiếu thu',
    'analytics.outcome': 'Phiếu chi',
    'analytics.role': 'Tạo phân quyền',
    'analytics.user-role': 'Tạo tài khoản',
    'analytics.form-dynamic': 'Form tùy chỉnh',
    'analytics.companies': 'Liên kết đại lý',
    'analytics.dashboards.outcome': 'THỐNG KÊ CHI PHÍ',
    'analytics.dashboards.income': 'THỐNG KÊ DOANH THU',
    'analytics.dashboards.stock-log': 'THỐNG KÊ NGUYÊN LIỆU NHẬP',
    'analytics.dashboards.order-payment': 'THỐNG KÊ DANH SÁCH SẢN PHẨM ĐÃ BÁN',
    'analytics.dashboards.debt': 'THỐNG KÊ CÔNG NỢ',
    'analytics.dashboards.make-product': 'THỐNG KÊ NGUYÊN LIỆU SỬ DỤNG',
    'analytics.dashboards.order-table': 'THỐNG KÊ TỪNG BÀN',

    /* 06.Product */
    'pages.add-new': 'THÊM',
    'pages.add-new-user-staff': 'THÊM NHÂN VIÊN',
    'pages.add-new-log': 'THÊM KHÁC',
    'pages.order-new': 'ORDER',
    'pages.image': 'Hình ảnh sản phẩm',
    'pages.add-new-modal-title': 'Thêm sản phẩm',
    'pages.order-new-modal-title': 'Đặt sản phẩm mới',
    'pages.update-modal-title': 'Cập nhật sản phẩm',
    'pages.display-options': 'Hiển thị tùy chọn',
    'pages.orderby': 'Sắp xếp : ',
    'pages.product-name': 'Tên sản phẩm',
    'pages.category': 'Loại sản phẩm',
    'pages.description': 'Mô tả',
    'pages.status': 'Trạng thái',
    'pages.cancel': 'Đóng',
    'pages.submit': 'Gửi',
    'pages.delete': 'Xóa',
    'pages.delete-forever': 'Xóa vĩnh viễn',
    'pages.another-action': 'Khác',
    'pages.update': 'Sửa thông tin',
    'pages.price': 'Giá',
    'pages.select-category': 'Chọn 1 danh mục sản phẩm',
    'pages.select-product': 'Chọn 1 sản phẩm',
    'pages.order-number': 'Trọng lượng',
    'pages.no-selected': 'Không có hành động',
    'pages.order-more': 'Thêm sản phẩm',
    'pages.total-order-price': 'Tổng số tiền',
    'pages.current-order-price': 'Thành tiền hiện tại',
    'pages.error-title': 'Ooops... có lỗi xảy ra!',
    'pages.error-code': 'Lỗi',
    'pages.go-back-home': 'Quay lại trang chủ',
    'pages.softhide': 'Ẩn sản phẩm',
    'pages.un-softhide': 'Hiện sản phẩm',
    'pages.bestter': 'Khuyên dùng',
    'pages.un-bestter': 'Bỏ khuyên dùng',
    'product.title': 'Thông tin sản phẩm',
    'product.price.original': 'Giá bán',
    'product.price.sale': 'Giá khuyến mãi',
    'product.ice': 'Lượng đá',
    'product.sugar': 'Độ ngọt',
    'product.size': 'Kích thước',
    'product.topping': 'Topping',
    'product.add.name': 'Tên',
    'product.add.value': 'Tiền thêm',
    'product.add_price': 'Giá thêm',
    'product.update_cost': 'Thêm định lượng',
    'product.edit_cost': 'Sửa định lượng',
    'product.search': 'Tìm tên Sản phẩm',
    'product.btn-add': 'Tạo sản phẩm',

    /* 07.Category */
    'category.add-new-modal-title': 'Thêm danh mục sản phẩm',
    'category.update-title': 'Cập nhật danh mục sản phẩm',
    'category.title': 'Tên sản phẩm',
    'category.thumb-list': 'Danh mục sản phẩm',
    'category.nav-tab': 'DANH MỤC',
    'category.add-new-category': 'THÊM DANH MỤC',
    'category.btn-add': 'Tạo danh mục',

    /* 08.Todo */
    'todo.add-new': 'THÊM',
    'todo.thumb-list': 'Sự kiện',
    'todo.add-new-modal-title': 'Thêm sự kiện',
    'todo.update-modal-title': 'Cập nhật sự kiện',
    'todo.title': 'Tiêu đề',
    'todo.description': 'Mô tả',
    'todo.date': 'Ngày',
    'todo.time': 'Giờ',
    'todo.update-done': 'Hoàn thành',
    'todo.update-pending': 'Đang chờ',
    'todo.delete': 'Xóa',
    'todo.no-selected': 'Không có hành động',
    'todo.update': 'Sửa',
    'todo.date-time': 'Thời gian',
    'todo.orderby': 'Sắp xếp : ',
    'todo.btn-add': 'Tạo sự kiện',

    /*table setting */
    'table-setting.row': 'Hàng ngang',
    'table-setting.col': 'Hàng dọc',
    'table-setting.floor': 'Khu',
    'table-setting.btn-generate': 'Tạo bàn',
    'table-setting.map': 'Sơ đồ',
    'table-setting.btn-save': 'Lưu',
    'table-setting.title': 'Cài đặt mô hình bàn ghế',

    /* stock */
    'stock.thumb-list': 'Nguyên vật liệu',
    'stock.add-new-modal-title': 'Thêm nguyên vật liệu',
    'stock.name': 'Tên nguyên liệu',
    'stock.unit': 'Đơn vị tính',
    'stock.unit-price': 'Giá mua',
    'stock.unit-price-new': 'Đơn giá mới',
    'stock.price': 'Thành tiền',
    'stock.count': 'Số lượng',
    'stock.delete': 'Xóa nguyên liệu',
    'stock.reset': 'Reset về 0',
    'stock.count-update': 'Bổ sung nguyên liệu',
    'stock.count-edit': 'Chỉnh sửa thông tin nguyên liệu',
    'stock.count-current': 'Còn lại',
    'stock.count-total': 'Tổng nhập',
    'stock.update-title': 'Bổ sung nguyên liệu',
    'stock.search': 'Tìm tên Nguyên vật liệu',
    'stock.btn-add': 'Tạo nguyên vật liệu',

    /* warehouse */
    'warehouse.add-new-modal-title': 'Thêm phiếu',
    'warehouse.phone': 'Số điện thoại',
    'warehouse.address': 'Địa chỉ',
    'warehouse.code': 'Mã số hàng hoá',
    'warehouse.unit': 'Đơn vị tính',
    'warehouse.description': 'Mô tả',
    'warehouse.btn-add': 'Tạo phiếu',

    'warehouse.import-search': 'Tìm tên Phiếu nhập kho',
    'warehouse.import': 'Phiếu nhập kho',
    'warehouse.import-stock': 'Mặt hàng nhập',
    'warehouse.import-title': 'Cập nhật phiếu nhập kho',
    'warehouse.import-name': 'Tên người nhập kho',
    'warehouse.import-quantity': 'Số lượng nhập',
    'warehouse.import-price': 'Giá nhập',
    'warehouse.btn-add-import': 'Tạo phiếu nhập kho',

    'warehouse.export-search': 'Tìm tên Phiếu xuất kho',
    'warehouse.export': 'Phiếu xuất kho',
    'warehouse.export-stock': 'Mặt hàng xuất',
    'warehouse.export-title': 'Cập nhật phiếu xuất kho',
    'warehouse.export-name': 'Tên người xuất kho',
    'warehouse.export-quantity': 'Số lượng xuất',
    'warehouse.export-price': 'Giá xuất',
    'warehouse.btn-add-export': 'Tạo phiếu xuất kho',

    'warehouse.transfer-search': 'Tìm tên Phiếu chuyển kho',
    'warehouse.transfer': 'Phiếu chuyển kho',
    'warehouse.transfer-stock': 'Mặt hàng chuyển kho',
    'warehouse.transfer-title': 'Cập nhật phiếu chuyển kho',
    'warehouse.transfer-name': 'Tên người chuyển kho',
    'warehouse.transfer-quantity': 'Số lượng chuyển',
    'warehouse.transfer-price': 'Giá chuyển kho',
    'warehouse.transfer-name-carrier': 'Tên người vận chuyển',
    'warehouse.transfer-phone-carrier': 'SĐT người vận chuyển',
    'warehouse.transfer-receiving-warehouse': 'Chuyển đến kho',
    'warehouse.transportation': 'Phương tiện vận chuyển',
    'warehouse.cost-transfer': 'Phí vận chuyển',
    'warehouse.btn-add-transfer': 'Tạo phiếu chuyển kho',

    /* make product */
    'make-product.thumb-list': 'Định lượng',
    'make-product.add-new-modal-title': 'Thêm định lượng',
    'make-product.name': 'Tên',
    'make-product.unit': 'Đơn vị',
    'make-product.count': 'Số lượng',
    'make-product.make-more': 'Thêm',
    'make-product.product-name': 'Tên sản phẩm',
    'make-product.stock-name': 'Tên nguyên liệu',
    'make-product.select-stock': 'Chọn 1 nguyên liệu',
    'make-product.add-modal-title': 'Tạo mới',
    'make-product.update-modal-title': 'Chỉnh sửa',
    'make-product.number-product-used': 'Số nguyên liệu sử dụng',
    'make-product.price': 'Giá bán',
    'make-product.cost': 'Giá Cost',
    'make-product.percent-cost': 'Phần trăm giá cost/giá bán',
    'make-product.btn-add': 'Tạo định lượng',

    /* FAQ */
    'faq.title': 'Hướng dẫn',
    'faq.text-title': 'Câu hỏi',
    'faq.text-content': 'Trả lời',
    'faq.modal-title': 'Câu hỏi và Trả lời',

    /** Ref */
    'ref.name': 'Tên chủ quán',
    'ref.companyName': 'Tên quán',
    'ref.email': 'Email',
    'ref.phone': 'Số điện thoại',
    'ref.address': 'Địa chỉ',
    'ref.content': 'Lời nhắn',
    'ref.title': 'Thông tin người được giới thiệu',
    'ref.sub-title':
        'Hoa hồng giới thiệu của bạn hiện tại là 30% trên số tiền hợp đồng của người được giới thiệu ký với VMASS. Thông tin của bạn sẽ được gửi tự động cho VMASS dựa trên thông tin cá nhân của bạn đã cung cấp.',
    'ref.btn-submit': 'Gửi giới thiệu',

    /** Chat */
    'chat.messages': 'Tin nhắn',
    'chat.messages-inbox': 'Hộp thư đến',
    'chat.messages-new': 'Danh bạ',

    /** Discount */
    'discount.add-new-modal-title': 'Thêm chiết khấu',
    'discount.note': 'Ghi chú',
    'discount.type': 'Loại giá trị',
    'discount.value': 'Giá trị',
    'discount.user': 'Nhân viên',
    'discount.products': 'Sản phẩm',
    'discount.select-all-products': 'Tất cả sản phẩm',
    'discount.select-one-type': 'Chọn loại giá trị',
    'discount.select-one-product': 'Chọn 1 sản phẩm',
    'discount.select-one-type-product': 'Chọn 1 loại sản phẩm',
    'discount.to-bill': 'Thêm chiết khấu',

    /** Customer */
    'customer.add-new-modal-title': 'Thêm khách hàng',
    'customer.title': 'Thông tin khách hàng',
    'customer.name': 'Tên khách hàng',
    'customer.phone': 'Số điện thoại',
    'customer.email': 'Email',
    'customer.address': 'Địa chỉ',
    'customer.point': 'Số điểm hiện tại',
    'customer.armorial': 'Huy hiệu',
    'customer.used-item': 'Số lần sử dụng sản phẩm',
    'customer.tags': 'Tags',
    'customer.info': 'INFO',
    'customer.used': 'SẢN PHẨM ĐÃ DÙNG',
    'customer.setting': 'CÀI ĐẶT',
    'customer.search': 'Tìm Số điện thoại KH',
    'customer.btn-add': 'Tạo khách hàng',

    /** Income, Outcome, Treasury */
    action: 'Hành động',
    name: 'Tên',
    phone: 'Số điện thoại',
    email: 'Email',
    address: 'Địa chỉ',
    price: 'Số tiền',
    note: 'Mô tả',
    time: 'Thống kê',
    'time-created': 'Thời gian tạo',
    'user.created': 'Người nhập liệu',
    'select-one-time': 'Chọn 1 loại',
    'select-one-time-discount': 'Chọn 1 loại giảm giá',
    'select-one-time-gift': 'Chọn 1 loại quà tặng',

    'income.title': 'Phiếu thu',
    'income.sub_title': 'Tiêu đề',
    'income.add-new-modal-title': 'Thêm phiếu thu',
    'income.name': 'Tên người nộp',
    'income.search': 'Tìm tên Phiếu thu',
    'income.btn-add': 'Tạo phiếu thu',

    'outcome.title': 'Phiếu chi',
    'outcome.sub_title': 'Tiêu đề',
    'outcome.add-new-modal-title': 'Thêm phiếu chi',
    'outcome.name': 'Tên người nhận',
    'outcome.search': 'Tìm tên Phiếu chi',
    'outcome.btn-add': 'Tạo phiếu chi',

    'treasury.name': 'Tên tài sản',
    'treasury.title': 'Tài sản',
    'treasury.add-new-modal-title': 'Thêm tài sản',
    'treasury.timeCreated': 'Thời gian tạo',
    'treasury.search': 'Tìm tên Tài sản',
    'treasury.btn-add': 'Tạo tài sản',

    /** Break Event */
    'breakeven.title': 'Điểm hòa vốn',

    /** Company Provider */
    'company-provider.title': 'Nhà cung cấp',
    'company-provider.title-log': 'Thêm giao dịch',
    'company-provider.title-log-add': 'Thêm giao dịch',
    'company-provider.title-log-add-more': 'Thanh toán thêm',
    'company-provider.add-new-modal-title': 'Thêm nhà cung cấp',
    'company-provider.name': 'Tên nhà cung cấp',
    'company-provider.item_name': 'Mặt hàng cung cấp',
    'company-provider.total_price_income': 'Tổng số tiền nhập',
    'company-provider.total_price_outcome': 'Đã thanh toán',
    'company-provider.total_price_pending': 'Còn lại',
    'company-provider.price': 'Số tiền',
    'company-provider.item_product': 'Mặt hàng cung cấp',
    'company-provider.name': 'Tên nhà cung cấp',
    'company-provider.search': 'Tìm tên Nhà cung cấp',
    'company-provider.btn-add': 'Tạo nhà cung cấp',

    /** User Staff */
    'user-staff.title': 'Thông tin nhân viên',
    'user-staff.add-new-modal-title': 'Thêm nhân viên',
    'user-staff.add-new-logs-modal-title': 'Thêm khác',
    'user-staff.title': 'Nhân viên',
    'user-staff.permission_name': 'Vị trí công việc',
    'user-staff.date_join': 'Ngày vào làm',
    'user-staff.date_get_salary': 'Ngày thanh toán lương',
    'user-staff.hour_working_on_day': 'Số giờ làm việc trong ngày',
    'user-staff.status_working': 'Tình trạng công việc',
    'user-staff.salary_by': 'Tính lương theo',
    'user-staff.salary_value': 'Mức lương',
    'user-staff.working_hours': 'Giờ làm việc',
    'user-staff.current_commission': 'Tiền hoa hồng',
    'user-staff.current_bonus': 'Tiền thưởng',
    'user-staff.current_overtime': 'Tiền tăng ca',
    'user-staff.current_advence': 'Tiền tạm ứng',
    'user-staff.current_fined': 'Tiền phạt',
    'user-staff.current_dayoff': 'Tiền nghỉ phép',
    'user-staff.current_day_working': 'Ngày công tháng này',
    'user-staff.current_salary': 'Lương tháng này',
    'user-staff.total_salary': 'Tổng lương',
    'user-staff.total_day_working': 'Tổng ngày công',
    'user-staff.working_hours_from': '* Làm từ',
    'user-staff.working_hours_to': '* Làm đến',
    'user-staff.logs_users_staff': 'Tên nhân viên',
    'user-staff.logs_type': 'Loại',
    'user-staff.logs_value_salary': 'Số tiền',
    'user-staff.logs_value_hour': 'Giờ',
    'user-staff.overtime_coefficient': 'Hệ số lương tăng ca',
    'user-staff.select-all-users': 'Chọn nhân viên',
    'user-staff.select-one': 'Chọn một loại',
    'user-staff.name': 'Tên nhân viên',
    'user-staff.phone': 'Số điện thoại',
    'user-staff.address': 'Địa chỉ',
    'user-staff.email': 'Email',
    'user-staff.search': 'Tìm tên Nhân viên',
    'user-staff.btn-add': 'Tạo nhân viên',

    /** Role */
    'role.name': 'Tên phân quyền',
    'role.title': 'Phân quyền',
    'role.add-new-modal-title': 'Thêm phân quyền',
    'role.btn-add': 'Tạo phân quyền',

    /** User Role */
    'user-role.title': 'Nhân viên phân quyền',
    'user-role.name': 'Tên nhân viên',
    'user-role.add-new-modal-title': 'Thêm nhân viên phân quyền',
    'user-role.select-one': 'Chọn một quyền',
    'user-role.place-holder-password': 'Để trống nếu không thay đổi',
    'user-role.btn-add': 'Tạo tài khoản',

    /** Form Dynamic */
    'form-dynamic.title': 'Form tùy chỉnh',
    'form-dynamic.add-new-modal-title': 'Thêm Form tùy chỉnh',
    'form-dynamic.label': 'Tiêu đề',
    'form-dynamic.alias': 'Loại form',
    'form-dynamic.select-one-alias': 'Chọn 1 form',
    'form-dynamic.type': 'Kiểu dữ liệu',
    'form-dynamic.select-one-type': 'Chọn 1 kiểu',
    'form-dynamic.btn-add': 'Tạo form tuỳ chỉnh',

    'url.placeholder': 'http:// or https://',

    /** Compananies */
    'companies.find-company-uuid': 'Tìm Mã cửa hàng',
    'companies.title': 'Liên kết đại lý',
    'companies.add-new-modal-title': 'Thêm liên kết',
    'companies.name': 'Tên liên kết',
    'companies.type': 'Loại liên kết',
    'companies.unset': 'Chưa liên kết',
    'companies.btn-add': 'Tạo liên kết',

    /** Promotion */
    'promotion.thumb-list': 'Chương trình khuyến mại',
    'promotion-add': 'Thêm khuyến mại',
    'promotion-name': 'Tên chương trình khuyến mại',
    'promotion-code': 'Mã code',
    'promotion-quantity': 'Số lượng áp dụng',
    'promotion-description': 'Mô tả chương trình',
    'promotion-date_start': 'Ngày bắt đầu',
    'promotion-date_end': 'Ngày kết thúc',
};

export default enUs;
