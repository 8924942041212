import React, { Component } from 'react';
import {
    Badge,
    ButtonDropdown,
    CustomInput,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Collapse,
} from 'reactstrap';
import { connect } from 'react-redux';
import {
    setContainerClassnames,
    clickOnMobileMenu,
} from '../../../redux/actions';
import IntlMessages from '../../../helpers/IntlMessages';
import classnames from 'classnames';
import { UpdateMobile } from '../general';
import ContentDisplayMobile from './ContentDisplayMobile';
import { Separator } from '../../../components/common/CustomBootstrap';
import moment from 'moment';

class LabelItemLeft extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdownSplitOpen: false,
        };
    }

    toggleSplit = () => {
        this.setState((prevState) => ({
            dropdownSplitOpen: !prevState.dropdownSplitOpen,
        }));
    };

    menuButtonClick = (e, menuClickCount, containerClassnames) => {
        setTimeout(() => {
            var event = document.createEvent('HTMLEvents');
            event.initEvent('resize', false, false);
            window.dispatchEvent(event);
        }, 350);
        this.props.setContainerClassnames(
            menuClickCount,
            containerClassnames,
            this.props.selectedMenuHasSubItems
        );
    };

    onClickedItem = (item) => {
        this.props.onClickedItem(
            // callback
            item,
            this.menuButtonClick,
            [null, 1000, this.props.containerClassnames]
        );
    };

    render() {
        const { item } = this.props;
        const { dropdownSplitOpen } = this.state;
        // console.log(this.props.item);
        return (
            <div className='custom-shadow'>
                <div className='d-flex flex-row cursor-pointer'>
                    <div
                        className={classnames(
                            item.status_name === 'Hoàn thành' ? 'disable' : '',
                            'd-flex flex-grow-1 min-width-zero'
                        )}
                        // className={'d-flex flex-grow-1 min-width-zero'}
                    >
                        <Button
                            color=''
                            // onClick={() =>
                            //     this.props.toggleAccordion(this.props.idx)
                            // }
                            aria-expanded={this.props.accordion[this.props.idx]}
                            className='flex-grow-1 btn-empty list-item-heading text-left text-one pre-wrap p-0'
                        >
                            <div
                                onClick={() => {
                                    item.status_name === 'Hoàn thành'
                                        ? this.props.onUpdateStatus(
                                              this.props.item,
                                              0
                                          )
                                        : this.props.onUpdateStatus(
                                              this.props.item,
                                              1
                                          );
                                    // this.onClickedItem(this.props.item);
                                }}
                                // onClick={() => {
                                //     this.onClickedItem(this.props.item);
                                // }}
                                className={classnames(
                                    item.status_name === 'Hoàn thành'
                                        ? 'text-deco-line-through'
                                        : '',
                                    'card-body w-100 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center'
                                )}
                                // className='card-body w-100 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center'
                            >
                                <span>{this.props.item.title}</span>
                                <div className='mt-2 w-100 text-muted text-small'>
                                    {this.props.item.time +
                                        ' / ' +
                                        moment(this.props.item.date).format(
                                            'YYYY-MM-DD'
                                        )}
                                    {/* <Badge
                                        color={
                                            this.props.item.status_id
                                                ? 'primary'
                                                : 'secondary'
                                        }
                                        pill
                                    >
                                        {this.props.item.status_name}
                                    </Badge> */}
                                </div>
                                <p className='text-muted mt-3 m-0'>
                                    {this.props.item.description}
                                </p>
                            </div>
                            {/* <div
                                onClick={() => {
                                    this.onClickedItem(this.props.item);
                                }}
                                className='card-body w-100 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center'
                            >
                                <div className='list-item-heading mb-1 truncate d-block text-capitalize'>
                                    {this.props.item.name}
                                </div>
                            </div> */}
                        </Button>
                        {this.props.item.status_name === 'Hoàn thành' ? null : (
                            <div className='custom-control custom-checkbox pl-1 align-self-center pr-4'>
                                <ButtonDropdown
                                    isOpen={dropdownSplitOpen}
                                    toggle={this.toggleSplit}
                                    // onClick={() => {
                                    //     this.onClickedItem(this.props.item);
                                    // }}
                                >
                                    <div className='cursor-pointer btn btn-lg btn-primary pl-4 pr-0 check-button check-all'>
                                        <CustomInput
                                            className='custom-checkbox mb-0 d-inline-block'
                                            type='checkbox'
                                            id={this.props.idx}
                                            checked={this.props.selectedItems.includes(
                                                this.props.item.id
                                            )}
                                            onChange={() => {
                                                this.props.onChangeCheckbox(
                                                    this.props.item.id
                                                );
                                            }}
                                            label={
                                                <span
                                                    className={`custom-control-label`}
                                                />
                                            }
                                        />
                                    </div>
                                    <DropdownToggle
                                        caret
                                        color='primary'
                                        className='dropdown-toggle-split'
                                    />
                                    <DropdownMenu right>
                                        {/* <DropdownItem
                                        className={classnames('text-left', {
                                            disable:
                                                this.props.isDisabledUpdate,
                                        })}
                                        onClick={() =>
                                            this.props.isDisabledUpdate
                                                ? null
                                                : this.props.onUpdateStatus(
                                                      this.props.item,
                                                      1
                                                  )
                                        }
                                    >
                                        <i className='simple-icon-check pr-2' />
                                        <IntlMessages id='todo.update-done' ˇ />
                                    </DropdownItem>
                                    <Separator />
                                    <DropdownItem
                                        className={classnames('text-left', {
                                            disable:
                                                this.props.isDisabledUpdate,
                                        })}
                                        onClick={() =>
                                            this.props.isDisabledUpdate
                                                ? null
                                                : this.props.onUpdateStatus(
                                                      this.props.item,
                                                      0
                                                  )
                                        }
                                    >
                                        <i className='simple-icon-minus pr-2' />
                                        <IntlMessages
                                            id='todo.update-pending'
                                            ˇ
                                        />
                                    </DropdownItem>
                                    <Separator /> */}
                                        <DropdownItem
                                            className={classnames('text-left', {
                                                disable:
                                                    this.props.isDisabledUpdate,
                                            })}
                                            onClick={() =>
                                                this.props.isDisabledUpdate
                                                    ? null
                                                    : this.props.onUpdate(
                                                          this.props.item
                                                      )
                                            }
                                        >
                                            <i className='simple-icon-note pr-2' />
                                            <IntlMessages id='pages.update' ˇ />
                                        </DropdownItem>
                                        <Separator />
                                        <DropdownItem
                                            className={classnames(
                                                'text-left red',
                                                {
                                                    disable:
                                                        this.props
                                                            .isDisabledDelete,
                                                }
                                            )}
                                            onClick={() =>
                                                this.props.isDisabledDelete
                                                    ? null
                                                    : this.props.onDelete(
                                                          this.props.item
                                                      )
                                            }
                                        >
                                            <>
                                                <i className='simple-icon-trash pr-2' />
                                                <IntlMessages id='pages.delete' />
                                            </>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </div>
                        )}
                    </div>
                </div>

                {/* <Collapse isOpen={this.props.accordion[this.props.idx]}>
                    <div className='card-body accordion-content pt-0 overflow-hidden'>
                        <Separator />
                        <UpdateMobile>
                            <ContentDisplayMobile
                                // data_dynamic_form={data_dynamic_form}
                                values={this.props.form}
                            />
                        </UpdateMobile>
                    </div>
                </Collapse> */}
            </div>
        );
    }
}

const mapStateToProps = ({ menu }) => {
    const { containerClassnames, menuClickCount, selectedMenuHasSubItems } =
        menu;
    return {
        containerClassnames,
        menuClickCount,
        selectedMenuHasSubItems,
    };
};
export default connect(mapStateToProps, {
    setContainerClassnames,
    clickOnMobileMenu,
})(LabelItemLeft);
