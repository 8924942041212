import React, { Component } from 'react';
import { Row, Col, TabPane, TabContent, Nav, NavItem } from 'reactstrap';
import axios from 'axios';
import { END_POINT, END_POINT_SOCKET } from '../../../constants/defaultValues';
import {
    SetItemsSelected,
    NotificatioErrController,
    NotificatioSucController,
} from '../../../helpers/Utils';
import io from 'socket.io-client';
import {
    ContentForm,
    ListItemLeftForm,
} from '../../../containers/pages/make-product';
import {
    Add,
    Update,
    PageHeading,
    Pagination,
} from '../../../containers/pages/general';
import LogsHelper from '../../../helpers/Logs';
import IntlMessages from '../../../helpers/IntlMessages';
import classnames from 'classnames';
import { isC, isU, isD } from '../../../helpers/Roles';
import ContentDisplay from '../../../containers/pages/make-product/ContentDisplay';
import ListItemFormMobile from '../../../containers/pages/make-product/ListItemFormMobile';
import { NavLink } from 'react-router-dom';

const DEFAULT_FORM = {
    id: '',
    count: 1,
    stock: null,
    product: null,
    make: [],
    data_products: [],
    data_stocks: [],

    product_id: '',
    dynamic_form: [],
};

const ROLE_ALIAS = 'make_product';

class ContentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: {
                pageSizes: [12, 24],
                selectedPageSize: 12,
                currentPage: 1,
                totalPage: 1,
                totalItem: 0,
                search: '',
            },
            selectedItems: [],
            data: [],
            data_dynamic_form: [],
            is_loading_data: true,
            show: {
                add: false,
                update: false,
                view: false,
                viewonly: true,
            },
            accordion: [],
            activeTab: '1',
            form: DEFAULT_FORM,
        };

        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        this.dataListRender();
    }

    /** Axios func helper */
    dataListRender = () => {
        const { selectedPageSize, currentPage, search } = this.state.page;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(
                `${
                    END_POINT + '/make-product/json'
                }?pageSize=${selectedPageSize}&currentPage=${currentPage}&search=${search}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((data) => {
                const Products = this.dataListRenderProducts();
                const StockUnits = this.dataListRenderStocksUnit();

                return Promise.all([Products, StockUnits])
                    .then((promises) => {
                        let anyAccordion = [];
                        let numeroPerguntas = data.data.length;
                        for (var i = 0; i < numeroPerguntas; i++) {
                            // if (!i) {
                            //     anyAccordion.push(true);
                            // }
                            anyAccordion.push(false);
                        }
                        this.setState(
                            (prev) => ({
                                ...prev,
                                data: data.data,
                                data_dynamic_form: data.dataDynamicForm,
                                data_products: promises[0],
                                data_stocks: promises[1],
                                is_loading_data: false,
                                page: {
                                    ...prev.page,
                                    totalPage: data.totalPage,
                                    totalItem: data.totalItem,
                                },
                                accordion: anyAccordion,
                            }),
                            () => {
                                if (this.state.form.id) {
                                    const { id, product, make, dynamic_form } =
                                        this.state.form;
                                    this.setState((prev) => ({
                                        ...prev,
                                        form: {
                                            ...prev.form,
                                            id,
                                            product: {
                                                name: product.name,
                                                id: id,
                                            },
                                            make: make,
                                            dynamic_form,
                                        },
                                    }));
                                } else {
                                    this.setState((prev) => ({
                                        ...prev,
                                        selectedItems: [],
                                        show: {
                                            ...prev.show,
                                            update: false,
                                        },
                                        form: DEFAULT_FORM,
                                    }));
                                }
                            }
                        );
                    })
                    .catch((err) => console.log(err));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    dataListRenderProducts() {
        let tokenStr = localStorage.getItem('access_token');

        return axios
            .get(`${END_POINT + '/product/all'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => []);
    }

    dataListRenderStocksUnit() {
        let tokenStr = localStorage.getItem('access_token');
        return axios
            .get(`${END_POINT + '/stock/all-unit'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => []);
    }

    onHandlerDelete = () => {
        if (!isD(ROLE_ALIAS)) return false;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .delete(`${END_POINT + '/make-product'}`, {
                data: {
                    arrayId: this.state.selectedItems,
                },
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['make-product-delete'],
                });

                this.dataListRender();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerUpdate = () => {
        if (!isU(ROLE_ALIAS)) return false;

        const { make, product, dynamic_form } = this.state.form;

        if (make.length < 1) {
            NotificatioErrController('Chưa thêm nguyên liệu');
            return false;
        }

        if (!product) {
            NotificatioErrController('Chọn 1 sản phẩm để tạo');
            return false;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .put(
                `${END_POINT + '/make-product'}`,
                {
                    product_id: product.id,
                    stocks: make,
                    dynamic_form,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['make-product-update'],
                });

                this.dataListRender();
                this.toggle('update');
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerAdd = () => {
        if (!isC(ROLE_ALIAS)) return false;

        const { make, product, dynamic_form } = this.state.form;

        if (make.length < 1) {
            NotificatioErrController('Chưa thêm nguyên liệu');
            return false;
        }

        if (!product) {
            NotificatioErrController('Chọn 1 sản phẩm để tạo');
            return false;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/make-product'}`,
                {
                    product_id: product.id,
                    stocks: make,
                    dynamic_form,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['make-product-create'],
                });

                this.dataListRender();
                // this.toggle('add');
                this.setState((prev) => ({
                    ...prev,
                    form: {
                        ...prev.form,
                        form: DEFAULT_FORM,
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };
    /** Axios func helper */

    /** State func helper */
    toggle = (name) => {
        if (name === 'add' && !isC(ROLE_ALIAS)) return false;
        if (name === 'update' && !isU(ROLE_ALIAS)) return false;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                update: name === 'add' ? false : prev.show.update,
                view: name === 'add' ? false : prev.show.view,
                [name]: !prev.show[name],
            },
            selectedItems: name === 'add' ? [] : prev.selectedItems,
            form: name === 'add' ? DEFAULT_FORM : prev.form,
        }));
    };

    onChangeForm = (e, _name) => {
        if (e && e.target) {
            let {
                target: { name, value },
            } = e;
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [name]: value,
                },
            }));
        } else {
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [_name]: e,
                },
            }));
        }
    };

    onChangeFormCount = (type) => {
        let count = 0;
        if (type === '-') {
            let val = this.state.form.count - 1;
            if (val < 1) return false;

            count = val;
        } else if (type === '+') {
            count = this.state.form.count + 1;
        }

        this.setState((prev) => ({
            ...prev,
            form: {
                ...prev.form,
                count: count,
            },
        }));
    };

    onChangeDynamicForm = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    dynamic_form: {
                        ...prev.form.dynamic_form,
                        [name]: value,
                    },
                },
            }));
        } else {
            let value = e.value ? e.value : e;

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    dynamic_form: {
                        ...prev.form.dynamic_form,
                        [_name]: value,
                    },
                },
            }));
        }
    };

    onSubmitForm = (type) => {
        if (type === 'update') {
            this.onHandlerUpdate();
        } else if (type === 'add') {
            this.onHandlerAdd();
        }
    };

    onChangePage = (page) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    currentPage: page,
                },
            }),
            () => this.dataListRender()
        );
    };

    onSearchKey = (e) => {
        if (!e) return false;

        if (e.target) {
            let {
                target: { value },
            } = e;
            let v = value;
            if (value === undefined) {
                v = e.target.firstElementChild.value; // parent to child

                value = v.toLowerCase();
                this.setState(
                    (prev) => ({
                        ...prev,
                        page: {
                            ...prev.page,
                            search: value,
                        },
                    }),
                    () => this.dataListRender()
                );
            } else {
                value = value.toLowerCase();
                this.setState(
                    (prev) => ({
                        ...prev,
                        page: {
                            ...prev.page,
                            search: value,
                        },
                    }),
                    () => this.dataListRender()
                );
            }
        }

        if (e.key === 'Enter') {
            let value = e.target.value.toLowerCase();
            this.setState(
                (prev) => ({
                    ...prev,
                    page: {
                        ...prev.page,
                        search: value,
                    },
                }),
                () => this.dataListRender()
            );
        }
    };

    onChangePageSize = (size) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    selectedPageSize: size,
                    currentPage: 1,
                },
            }),
            () => this.dataListRender()
        );
    };

    onClickedItem = (item, callback, args) => {
        if (this.state.form.id !== item.id) {
            const { id, name, stocks, dynamic_form } = item;

            this.setState(
                (prev) => ({
                    ...prev,
                    show: {
                        ...prev.show,
                        update: false,
                        view: true,
                        viewonly: false,
                    },
                    form: {
                        ...prev.form,
                        id,
                        product: {
                            name: name,
                            id: id,
                        },
                        make: stocks,
                        dynamic_form,
                    },
                    selectedItems: [item.id],
                }),
                () => {
                    callback(args[0], args[1], args[2]);
                }
            );
        }
    };

    onClickedUpdate = (item) => {
        const { id, name, stocks, dynamic_form } = item;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                viewonly: false,
                update: true,
                view: false,
            },
            form: {
                ...prev.form,
                id,
                product: {
                    name: name,
                    id: id,
                },
                make: stocks,
                dynamic_form,
            },
        }));
    };

    onClickedDelete = (item) => {
        let selectedSet = SetItemsSelected(
            item.id,
            this.state.selectedItems,
            'require'
        );
        if (window.confirm('Bạn chắc chắn muốn xoá định lượng này?')) {
            this.setState(
                (prev) => ({
                    ...prev,
                    selectedItems: selectedSet,
                }),
                () => this.onHandlerDelete()
            );
        }
    };

    onClickedCheckbox = (id, callback) => {
        // let selectedSet = SetItemsSelected(id, this.state.selectedItems);

        this.setState((prev) => ({
            ...prev,
            selectedItems: [id],
        }));
    };

    onClickedFormAddStock = () => {
        const { count, stock, make } = this.state.form;

        if (!stock) {
            NotificatioErrController('Chọn 1 nguyên liệu để tạo');
            return false;
        }

        let existsId = make.filter((s) => s.id === stock.id);
        if (existsId.length > 0) {
            NotificatioErrController('Đã chọn nguyên liệu này');
            return false;
        }

        let aStock = {
            id: stock.id,
            name: stock.name,
            unit: stock.unit,
            count: count,
        };
        make.push(aStock);

        this.setState((prev) => ({
            ...prev,
            form: {
                ...prev.form,
                make,
                count: 1,
                stock: null,
            },
        }));
    };

    onClickedFormDeleteStock = (id) => {
        let make = this.state.form.make.filter((s) => s.id !== id);
        this.onChangeForm(make, 'make');
    };

    /** State func helper */

    toggleAccordion = (tab) => {
        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => (tab === index ? !x : false));
        this.setState({
            accordion: state,
        });
    };

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    render() {
        const { match } = this.props;
        const {
            page,
            selectedItems,
            data,
            data_products,
            data_stocks,
            is_loading_data,
            show,
            form,
            accordion,

            // Dynamic Form
            data_dynamic_form,
        } = this.state;
        // console.log('selectedItems: ', typeof selectedItems);
        // console.log('data: ', data);
        // console.log('data_dynamic_form: ', data_dynamic_form);
        // console.log('data_products: ', data_products);
        // console.log('data_stocks: ', data_stocks);

        return is_loading_data ? (
            <div className='loading' />
        ) : (
            <div className='app-row disable-text-selection'>
                <PageHeading
                    heading='make-product.thumb-list'
                    setting={page}
                    match={match}
                    totalItem={page.totalItem}
                    isDisabledAdd={!isC(ROLE_ALIAS)}
                    toggleFunc={() => {
                        this.toggle('add');
                        this.toggleAccordion();
                    }}
                    changePageSizeFunc={this.onChangePageSize}
                    onSearchKeyFunc={this.onSearchKey}
                />
                <Add
                    title={
                        <IntlMessages id='make-product.add-new-modal-title' />
                    }
                    isOpen={show.add}
                    toggle={() => {
                        this.toggle('add');
                    }}
                    onSubmit={this.onHandlerAdd}
                >
                    <ContentForm
                        data_dynamic_form={data_dynamic_form}
                        data_products={data_products}
                        data_stocks={data_stocks}
                        values={form}
                        setting={show}
                        onChange={this.onChangeForm}
                        onChangeCount={this.onChangeFormCount}
                        onAddStock={this.onClickedFormAddStock}
                        onDeleteStock={this.onClickedFormDeleteStock}
                        onChangeDynamicForm={this.onChangeDynamicForm}
                    />
                </Add>
                <Update
                    title={<IntlMessages id='make-product.thumb-list' />}
                    setting={show}
                    onSubmit={this.onHandlerUpdate}
                    isOpen={show.update}
                    toggle={() => {
                        this.toggle('update');
                    }}
                >
                    <ContentForm
                        data_dynamic_form={data_dynamic_form}
                        data_products={data_products}
                        data_stocks={data_stocks}
                        values={form}
                        setting={show}
                        onChange={this.onChangeForm}
                        onChangeCount={this.onChangeFormCount}
                        onAddStock={this.onClickedFormAddStock}
                        onDeleteStock={this.onClickedFormDeleteStock}
                        onChangeDynamicForm={this.onChangeDynamicForm}
                    />
                </Update>
                <Nav
                    tabs
                    className='d-flex justify-content-between separator-tabs ml-0 mb-5'
                >
                    <NavItem>
                        <NavLink
                            className={classnames({
                                active: this.state.activeTab === '1',
                                'nav-link': true,
                            })}
                            onClick={() => this.toggleTab('1')}
                            location={{}}
                            to='#'
                        >
                            <IntlMessages id='general.info' />
                        </NavLink>
                    </NavItem>

                    {/* <NavItem>
                        <NavLink
                            className={classnames({
                                active: this.state.activeTab === '2',
                                'nav-link': true,
                            })}
                            onClick={() => this.toggleTab('2')}
                            location={{}}
                            to='#'
                        >
                            <span className='mr-2'>
                                <i className='simple-icon-settings'></i>
                            </span>
                            <IntlMessages id='general.setting' />
                        </NavLink>
                    </NavItem> */}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId='1'>
                        <Row className='content-page__row d-none d-md-flex'>
                            <Col
                                xs='12'
                                sm='12'
                                md='12'
                                lg={show.view ? 6 : 12}
                                xl={show.view ? 6 : 12}
                            >
                                {data.map((item, idx) => (
                                    <ListItemLeftForm
                                        onClickedItem={this.onClickedItem}
                                        onUpdate={this.onClickedUpdate}
                                        onDelete={this.onClickedDelete}
                                        onChangeCheckbox={
                                            this.onClickedCheckbox
                                        }
                                        isDisabledUpdate={!isU(ROLE_ALIAS)}
                                        isDisabledDelete={!isD(ROLE_ALIAS)}
                                        selectedItems={selectedItems}
                                        key={idx}
                                        idx={idx}
                                        item={item}
                                    />
                                ))}
                                <Pagination
                                    currentPage={page.currentPage}
                                    totalPage={page.totalPage}
                                    onChangePage={(i) => this.onChangePage(i)}
                                />
                            </Col>
                            {show.view ? (
                                <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                                    <ContentDisplay values={form} />
                                </Col>
                            ) : null}
                        </Row>
                        <Row className='content-page__row d-block d-md-none mb-4'>
                            <Col>
                                {data.map((item, idx) => {
                                    return (
                                        <div
                                            className='d-flex mb-3 card'
                                            key={item.id}
                                        >
                                            <ListItemFormMobile
                                                onClickedItem={
                                                    this.onClickedItem
                                                }
                                                onUpdate={this.onClickedUpdate}
                                                onDelete={this.onClickedDelete}
                                                onChangeCheckbox={
                                                    this.onClickedCheckbox
                                                }
                                                isDisabledUpdate={
                                                    !isU(ROLE_ALIAS)
                                                }
                                                isDisabledDelete={
                                                    !isD(ROLE_ALIAS)
                                                }
                                                selectedItems={selectedItems}
                                                key={idx}
                                                idx={idx}
                                                item={item}
                                                form={form}
                                                accordion={accordion}
                                                toggleAccordion={
                                                    this.toggleAccordion
                                                }
                                            />
                                        </div>
                                    );
                                })}
                                <Pagination
                                    currentPage={page.currentPage}
                                    totalPage={page.totalPage}
                                    onChangePage={(i) => this.onChangePage(i)}
                                />
                            </Col>
                        </Row>
                    </TabPane>
                    {/* <TabPane tabId='2'>Setting</TabPane> */}
                </TabContent>
                {/* <CustomerApplicationMenu /> */}
            </div>
        );
    }
}

export default ContentComponent;
