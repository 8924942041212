import React from 'react';
import { Input, Label, Form, FormGroup, Row, Col } from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { AddCommaNumber } from '../../../helpers/Utils';
import DynamicForm from '../general/DynamicForm';

const ContentComponent = (props) => {
    return (
        <Form autoFocus={false}>
            <FormGroup>
                <Label for='name'>
                    <IntlMessages id='name' />
                </Label>
                <Input
                    autoFocus={true}
                    type='text'
                    name='name'
                    id='name'
                    disabled={props.setting.viewonly && !props.setting.add}
                    value={props.values.name}
                    onChange={props.onChange}
                />
            </FormGroup>
            <FormGroup>
                <Label for='phone'>
                    <IntlMessages id='phone' />
                </Label>
                <Input
                    type='text'
                    name='phone'
                    id='phone'
                    disabled={props.setting.viewonly && !props.setting.add}
                    value={props.values.phone}
                    onChange={props.onChange}
                />
            </FormGroup>
            <FormGroup>
                <Label for='email'>
                    <IntlMessages id='email' />
                </Label>
                <Input
                    type='email'
                    name='email'
                    id='email'
                    disabled={props.setting.viewonly && !props.setting.add}
                    value={props.values.email}
                    onChange={props.onChange}
                />
            </FormGroup>
            <FormGroup>
                <Label for='address'>
                    <IntlMessages id='address' />
                </Label>
                <Input
                    type='text'
                    name='address'
                    id='address'
                    disabled={props.setting.viewonly && !props.setting.add}
                    value={props.values.address}
                    onChange={props.onChange}
                />
            </FormGroup>
            {/* {!props.setting.add ? (
        <>
          <Row>
            <Col xs="12" sm="12" md="6" lg="6">
              <FormGroup>
                <Label for="total_price_income">
                  <IntlMessages id="company-provider.total_price_income" />
                </Label>
                <Input type="text" name="total_price_income" id="total_price_income"
                  disabled={true}
                  value={AddCommaNumber(props.values.total_price_income)}
                  onChange={props.onChange}
                />
              </FormGroup>
            </Col>
            <Col xs="12" sm="12" md="6" lg="6">
              <FormGroup>
                <Label for="total_price_outcome">
                  <IntlMessages id="company-provider.total_price_outcome" />
                </Label>
                <Input type="text" name="total_price_outcome" id="total_price_outcome"
                  disabled={true}
                  value={AddCommaNumber(props.values.total_price_outcome)}
                  onChange={props.onChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="total_price_pending">
              <IntlMessages id="company-provider.total_price_pending" />
            </Label>
            <Input type="text" name="total_price_pending" id="total_price_pending"
              disabled={true}
              value={AddCommaNumber(props.values.total_price_pending)}
              onChange={props.onChange}
            />
          </FormGroup>
        </>
      ) : null} */}

            <DynamicForm
                values={props.values.dynamic_form}
                data={props.data_dynamic_form}
                onChange={props.onChangeDynamicForm}
                disabled={props.setting.viewonly && !props.setting.add}
            />
        </Form>
    );
};

export default ContentComponent;
