import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import Pagination from '../../../components/DatatablePagination';
import { ComponentTitle, ComponentBodyDecrement } from './Common';
import { AddCommaNumber } from '../../../helpers/Utils';

export default (props) => {
    const [state, setState] = useState({
        columns: [],
    });

    useEffect(() => {
        setState({
            ...state,
            columns: [
                {
                    Header: (
                        <div style={{ textAlign: 'center' }}>{props.title}</div>
                    ),
                    columns: [
                        {
                            Header: 'STT',
                            accessor: 'stt',
                            maxWidth: 50,
                        },
                        {
                            Header: 'Tên',
                            accessor: 'label',
                            minWidth: 160,
                        },
                        {
                            Header: (
                                <ComponentTitle
                                    compare={props.setting.compare}
                                    title='Số lượng'
                                    value={props.total_count}
                                    align='center'
                                />
                            ),
                            accessor: 'count',
                            Cell: (_props) => {
                                const value = _props.value + ' ' + 'lần';
                                return (
                                    <ComponentBodyDecrement
                                        compare={props.setting.compare}
                                        all={props.setting.all}
                                        type='text'
                                        align='center'
                                        value={value}
                                        compare_value={
                                            _props.original.compare_count
                                        }
                                    />
                                );
                            },
                        },
                        {
                            Header: (
                                <ComponentTitle
                                    compare={props.setting.compare}
                                    title='Còn lại'
                                    value={AddCommaNumber(
                                        props.total_price_pending
                                    )}
                                    align='end'
                                />
                            ),
                            minWidth: 160,
                            accessor: 'price_pending',
                            sortMethod: (a, b) => Number(a) - Number(b),
                            Cell: (_props) => (
                                <ComponentBodyDecrement
                                    compare={props.setting.compare}
                                    all={props.setting.all}
                                    type='price'
                                    align='end'
                                    value={_props.value}
                                    compare_value={
                                        _props.original.compare_price_pending
                                    }
                                />
                            ),
                        },
                        {
                            Header: (
                                <ComponentTitle
                                    compare={props.setting.compare}
                                    title='Tổng'
                                    value={AddCommaNumber(
                                        props.total_price_income
                                    )}
                                    align='end'
                                />
                            ),
                            minWidth: 160,
                            accessor: 'price_income',
                            sortMethod: (a, b) => Number(a) - Number(b),
                            Cell: (_props) => (
                                <ComponentBodyDecrement
                                    compare={props.setting.compare}
                                    all={props.setting.all}
                                    type='price'
                                    align='end'
                                    value={_props.value}
                                    compare_value={
                                        _props.original.compare_price_income
                                    }
                                />
                            ),
                        },
                    ],
                },
            ],
        });
    }, [props.data, props.setting.compare, props.setting.all]);

    return (
        <ReactTable
            defaultPageSize={6}
            data={props.data}
            columns={state.columns}
            minRows={0}
            showPageJump={false}
            showPageSizeOptions={true}
            PaginationComponent={Pagination}
            NoDataComponent={() => null}
        />
    );
};
