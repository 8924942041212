import React from 'react';
import ReactTable from 'react-table';
import { Badge } from 'reactstrap';
import Pagination from '../../../components/DatatablePagination';
import { GetSelectTime } from '../../../helpers/SelectTime';
import { AddCommaNumber } from '../../../helpers/Utils';
import moment from 'moment';

const ContentMultiForm = (props) => {
    const { data, onChange, data_tags } = props;
    // console.log(data);
    const columns = [
        // {
        //     Header: 'Tên người nhập kho',
        //     accessor: 'name',
        //     minWidth: 200,
        // },
        // {
        //     Header: 'Số điện thoại',
        //     accessor: 'phone',
        // },
        // {
        //     Header: 'Địa chỉ',
        //     accessor: 'address',
        // },
        {
            Header: 'Mặt hàng xuất',
            accessor: 'stock',
            Cell: (props) => (
                <span className='text-capitalize'>{props.value}</span>
            ),
        },
        // {
        //     Header: 'Đơn vị tính',
        //     accessor: 'unit',
        //     Cell: (props) => (
        //         <span className='text-capitalize'>{props.value}</span>
        //     ),
        // },
        // {
        //     Header: 'Mã số',
        //     accessor: 'code',
        //     Cell: (props) => (
        //         <span className='text-uppercase'>{props.value}</span>
        //     ),
        // },
        {
            Header: 'Số lượng xuất',
            accessor: 'quantity',
            Cell: (props) => AddCommaNumber(props.value),
        },
        {
            Header: 'Giá xuất',
            accessor: 'price',
            Cell: (props) => AddCommaNumber(props.value),
        },
        {
            Header: 'Tình trạng',
            accessor: 'status',
            Cell: (props) => (
                <Badge
                    pill
                    color={
                        props.value === 'Đã xét duyệt'
                            ? 'primary'
                            : props.value === 'Đang xét duyệt'
                            ? 'secondary'
                            : 'danger'
                    }
                >
                    {props.value}
                </Badge>
            ),
        },
        {
            Header: 'Mô tả',
            accessor: 'description',
            Cell: (props) => <span>{props.value}</span>,
        },
        // {
        //     Header: 'Thống kê',
        //     accessor: 'time',
        //     Cell: (props) => GetSelectTime(props.value).label,
        // },
        {
            Header: 'Người nhập liệu',
            accessor: 'user_created_id',
        },
        {
            Header: 'Thời gian tạo',
            accessor: 'createdAt',
            Cell: (props) => moment(props.value).format('DD/MM/YYYY'),
        },
    ];
    return (
        <ReactTable
            defaultPageSize={20}
            data={data}
            columns={columns}
            minRows={0}
            showPageJump={false}
            showPageSizeOptions={true}
            PaginationComponent={Pagination}
            NoDataComponent={() => null}
        />
    );
};

export default ContentMultiForm;
