import React from 'react';
import { Badge, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { GetSelectTime } from '../../../helpers/SelectTime';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentDisplayMobile = (props) => {
    // console.log(props);
    return (
        <>
            <Row className='mt-4' xxs='12'>
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.import-name' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p>{props.values.name}</p>
                </Colxx>
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.phone' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p>{props.values.phone}</p>
                </Colxx>
                <Colxx xxs='4' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.address' />
                    </p>
                </Colxx>
                <Colxx xxs='8' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {props.values.address ? (
                            props.values.address
                        ) : (
                            <span className='text-lowercase text-small text-muted'>
                                (Chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='5' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.import-stock' />
                    </p>
                </Colxx>
                <Colxx xxs='7' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {props.values.stock ? (
                            props.values.stock
                        ) : (
                            <span className='text-lowercase text-small text-muted'>
                                (Chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='5' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.code' />
                    </p>
                </Colxx>
                <Colxx xxs='7' lg='6' className='text-right'>
                    <p>
                        {props.values.code ? (
                            props.values.code
                        ) : (
                            <span className='text-lowercase text-small text-muted'>
                                (Chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='4' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.unit' />
                    </p>
                </Colxx>
                <Colxx xxs='8' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {props.values.unit ? (
                            props.values.unit
                        ) : (
                            <span className='text-lowercase text-small text-muted'>
                                (Chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='5' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.import-quantity' />
                    </p>
                </Colxx>
                <Colxx xxs='7' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {props.values.quantity ? (
                            AddCommaNumber(props.values.quantity)
                        ) : (
                            <span className='text-lowercase text-small text-muted'>
                                (Chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='4' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.import-price' />
                    </p>
                </Colxx>
                <Colxx xxs='8' lg='6' className='text-right'>
                    <p>
                        {props.values.price ? (
                            <Badge color='primary' pill>
                                {AddCommaNumber(props.values.price)} đ
                            </Badge>
                        ) : (
                            <span className='text-lowercase text-small text-muted'>
                                (Chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx>

                {/* <Colxx xxs='4' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='time' />
                    </p>
                </Colxx>
                <Colxx xxs='8' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {GetSelectTime(props.values?.time)?.label}
                    </p>
                </Colxx> */}
                <Colxx xxs='4' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='timeCreated' />
                    </p>
                </Colxx>
                <Colxx xxs='8' lg='6' className='text-right'>
                    <p className='text-lowercase'>{props.values.at}</p>
                </Colxx>

                <Colxx xxs='5' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='user.created' />
                    </p>
                </Colxx>
                <Colxx xxs='7' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {props.values?.user?.fullname}
                    </p>
                </Colxx>
            </Row>
        </>
    );
};

export default ContentDisplayMobile;
