import React from "react";
import {
  Input,
  Label,
  Form, FormGroup,
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { AddCommaNumber } from "../../../helpers/Utils";

const ContentComponent = (props) => {
  return (
    <Form>
      <FormGroup>
        <Label for="item_name">
          <IntlMessages id="company-provider.item_name" />
        </Label>
        <Input type="text" name="item_name" id="item_name"
          disabled={true}
          value={props.values.item_name}
        />
      </FormGroup>
      <FormGroup>
        <Label for="price_outcome">
          <IntlMessages id="company-provider.price" />
        </Label>
        <Input name="price_outcome" id="price_outcome"
          value={AddCommaNumber(props.values.price_outcome)}
          onChange={props.onChange}
        />
      </FormGroup>
    </Form>
  )
}

export default ContentComponent