import { defaultDirection } from '../constants/defaultValues';
import { NotificationManager } from '../components/common/react-notifications';
import { END_POINT } from '../constants/defaultValues';
import axios from 'axios';

export const AddCommaNumber = (number) => {
    if (!number) return number;

    let negative = '';
    if (parseFloat(number) < 0) {
        negative = '-';
    }
    number = number.toString();
    return (
        negative +
        number.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    );
};

export const AddCommaPhoneNumber = (number) => {
    if (!number) return number;

    let negative = '';
    if (parseFloat(number) < 0) {
        negative = '-';
    }
    number = number.toString();
    return (
        negative +
        number
            .replace(/\D/g, '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            .replace('.', '')
    );
};
export const mapOrder = (array, order, key) => {
    array.sort(function (a, b) {
        var A = a[key],
            B = b[key];
        if (order.indexOf(A + '') > order.indexOf(B + '')) {
            return 1;
        } else {
            return -1;
        }
    });
    return array;
};

export const getDateWithFormat = (date) => {
    let today = new Date();

    if (date) {
        today = new Date(date);
    }

    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    return dd + '/' + mm + '/' + yyyy;
    // return yyyy + "-" + mm + "-" + dd;
};

export const getCurrentDate = (momentDateTime) => {
    var options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'Asia/Ho_Chi_Minh',
    };

    let curr = new Date().toLocaleDateString('vn-VN', options).split('/');
    if (momentDateTime) {
        curr = new Date(momentDateTime)
            .toLocaleDateString('vn-VN', options)
            .split('/');
    }

    let yyyy = curr[2];
    let mm = curr[0];
    let dd = curr[1];

    if (curr.length <= 1) return curr[0];

    return yyyy + '-' + mm + '-' + dd;
};

export const getCurrentTime = () => {
    const now = new Date();
    return now.getHours() + ':' + now.getMinutes();
};

export const getAvatar = (image) => {
    if (image) {
        return END_POINT + '/public/' + image;
    }
    return '/assets/img/profile-pic-l.jpg';
};

export const getQrPay = (bank_code, account_number) => {
    if ((!bank_code, !account_number)) {
        return false;
    }
    return `https://qrcode.io.vn/api/generate/${bank_code}/${account_number}`;
};

export const getQrPayWithCash = (bank_code, account_number, amount) => {
    if ((!bank_code, !account_number, !amount)) {
        return false;
    }
    return `https://qrcode.io.vn/api/generate/${bank_code}/${account_number}/${amount}`;
};

export const getQrPayWithCashAndInfo = (
    bank_code,
    account_number,
    amount,
    info
) => {
    if ((!bank_code, !account_number, !amount)) {
        return false;
    }
    return `https://qrcode.io.vn/api/generate/${bank_code}/${account_number}/${amount}/${info}`;
};

export const getDirection = () => {
    let direction = defaultDirection;
    if (localStorage.getItem('direction')) {
        const localValue = localStorage.getItem('direction');
        if (localValue === 'rtl' || localValue === 'ltr') {
            direction = localValue;
        }
    }
    return {
        direction,
        isRtl: direction === 'rtl',
    };
};

export const collect = (props) => {
    return { data: props.data };
};

export const setDirection = (localValue) => {
    let direction = 'ltr';
    if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
    }
    localStorage.setItem('direction', direction);
};

export const RandomString = (len = 5) =>
    Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, len);
export const RandomNumber = (len = 5) =>
    Math.random()
        .toString(10)
        .replace(/[a-z.0]+/g, '1')
        .substr(0, len);

export const NotificatioErrController = (message) => {
    NotificationManager.error(message, 'Lỗi !', 3000, null, null, 'filled');
};

export const NotificatioSucController = (message) => {
    NotificationManager.primary(message, '', 3000, null, null, 'filled');
};

// Seen
export const getUnSeen = (user) => {
    // let local_id = localStorage.getItem("user_id");
    let local_id = localStorage.getItem('b');

    if (user.user_id) {
        if (user.user_id == local_id) {
            // my sender
            return true;
        } else {
            // my receiver
            return user.isSeen;
        }
    }
    if (user.sender_id) {
        if (user.sender_id == local_id) {
            return true;
        } else {
            return user.isSeen;
        }
    }
    return false;
};

export const getCompanyImage = (company) => {
    if (company.companyImage) {
        return END_POINT + '/public/' + company.companyImage;
    }
    return '/assets/img/profile-pic-l.jpg';
};

export const getChatDisplayTime = (user) => {
    if (user.data && user.time) return user.date + ' - ' + user.time;
    return '';
};

export const CheckConfirmPassword = async (confirmPassword) => {
    if (confirmPassword != null || confirmPassword != '') {
        let confirm = await axios
            .post(`${END_POINT}/auth/signin`, {
                username: getUserName(),
                password: confirmPassword,
                domain: getDomain(),
            })
            .then((res) => res.data)
            .catch((err) => err.response);

        if (confirm.status !== 200) {
            return false;
        }
    } else {
        return false;
    }

    return true;
};

const getDomain = () => {
    return window.location.host.split('.')[0];
};

const getUserName = () => {
    return localStorage.getItem('username');
};

export const IsRedirectFromAlert = (props) => {
    if (props.location && props.location.aboutProps) {
        const { message_item_id, message_user_id } = props.location.aboutProps;
        if (message_user_id && message_item_id) return true;

        return false;
    }
};

export const SetItemsSelected = (id, items, permission) => {
    let selected = items;
    let selectedSet = items;

    if (selected.includes(id)) {
        if (permission !== 'require') {
            // dont remove if id is required
            selectedSet = selected.filter((items) => items !== id);
        }
    } else {
        selected.push(id);

        let set = new Set(selected);
        selectedSet = Array.from(set);
    }

    return selectedSet;
};

export const ScrollDetect = () => {
    var lastScroll = 0;

    window.onscroll = function () {
        let currentScroll = 0;

        if (document) {
            if (document.documentElement) {
                currentScroll = document.documentElement.scrollTop;
            }

            if (document.body) {
                currentScroll = document.body.scrollTop;
            }
        }
        // let currentScroll = document.documentElement.scrollTop || document.body.scrollTop; // Get Current Scroll Value

        if (currentScroll > 0 && lastScroll <= currentScroll) {
            lastScroll = currentScroll;

            const navbar = document.getElementsByClassName('navbar');
            if (navbar) {
                navbar[0].style.transition = 'opacity 0.5s linear 0s';
                navbar[0].style.opacity = '0';
                navbar[0].style.height = '0';
            }
        } else {
            lastScroll = currentScroll;
            const navbar = document.getElementsByClassName('navbar');
            if (navbar.length) {
                navbar[0].style.opacity = '1';
                navbar[0].style.height = 'inherit';
            }
        }
    };
};
