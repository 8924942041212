import React, { Component, Fragment } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Row } from "reactstrap";
import { Colxx } from "../../../components/common/CustomBootstrap";
import ChatApplicationMenu from "../../../containers/pages/message/root/ChatApplicationMenu";
import ChatHeading from "../../../containers/pages/message/ChatHeading";
import MessageCard from "../../../containers/pages/message/MessageCard";
import SaySomething from "../../../containers/pages/message/SaySomething";
import io from 'socket.io-client';
import { END_POINT_SOCKET } from '../../../constants/defaultValues'
import axios from "axios";
import { END_POINT } from "../../../constants/defaultValues"
import { Redirect } from 'react-router-dom'
import { NotificatioErrController } from '../../../helpers/Utils'

const initSelectedUser = {
  companyName: "Tất cả cửa hàng"
}

class ChatApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActiveTab: "messages",
      USER_PARENT_ID: localStorage.getItem("b"),
      messageInput: "",
      selectedConversation: true,
      searchKey: "",
      inboxsData: [],
      originalInboxsData: [],
      conversationData: [],
      originalConversationData: [],
      selectedUser: null,
      originalSelectedUser: null,
      allUsersData: [],
      checkboxCheckall: false,
      files: null,
      isLoadedMore: false,
      isEndMessagesData: false,
      isFirstLoad: true,
    };

    this.socket = io(END_POINT_SOCKET);
  }

  // axios
  allUsersDataRender = () => {
    let tokenStr = localStorage.getItem('access_token');
    axios
      .get(
        `${END_POINT + "/message/root/all_contacts"}`,
        { headers: { "Authorization": `Bearer ${tokenStr}` } }
      )
      .then(res => {
        return res.data;
      })
      .then(data => {
        this.setState({
          allUsersData: data.data,
          originalAllUsersData: data.data,
        });
      })
  }

  componentWillUnmount() {
    this.socket.close();
  }

  componentDidMount() {
    this.socket.emit('join room', {
      room_id: localStorage.getItem('user_id')
    })

    this.socket.emit('message get inbox', {
      user_id: localStorage.getItem('user_id'),
    })

    this.socket.on('message get inbox', (res) => {
      const { data: { data } } = res;

      this.setState({
        inboxsData: data,
        originalInboxsData: data,
      })
    });

    this.socket.on('message get conversation', (res) => {
      const { data: { data, isEnd } } = res;

      if (this.state.selectedUser) {
        if (!data.length)
          return;

        let lastData = data[data.length - 1];

        // not update myself
        if (lastData.user_id != localStorage.getItem("user_id")) {
          // console.log("Me received, update seen: ", lastData);

          this.socket.emit('message update seen conversation', {
            user_id_will_alert: localStorage.getItem('user_id'),
            user_id: lastData.user_id,
            friend_id: lastData.friend_id,
            message: lastData,
          });
        }

        this.socket.emit('message get inbox', {
          user_id: localStorage.getItem('user_id'),
        });

        this.setState({
          conversationData: data,
          isLoadedMore: true,
          isEndMessagesData: isEnd,
          originalConversationData: data,
        })
      }
    });

    this.socket.on('message reload conversation', (res) => {

      const { id, data: { data } } = res;

      if (!data.length) {
        // from broadcast
        this.socket.emit('message get inbox', {
          user_id: localStorage.getItem('user_id'),
        })
        return;
      }

      if (this.state.selectedUser
        && (this.state.selectedUser.user_id == id || this.state.selectedUser.friend_id == id)) {

        if (!data.length)
          return;

        let lastData = data[data.length - 1];

        // not update myself
        if (lastData.user_id != localStorage.getItem("user_id")) {
          // console.log("Me received, update seen: ", lastData);

          this.socket.emit('message update seen conversation', {
            user_id_will_alert: localStorage.getItem('user_id'),
            user_id: localStorage.getItem('user_id'),
            friend_id: id,
            message: lastData,
          });
        }
        this.setState({
          conversationData: data,
          originalConversationData: data,
        })
      }
      else {
        if (!data.length)
          return;

        // not select user, new message unseen
        let lastData = data[data.length - 1];

        // console.log("create alert now, not select user or select user diff", lastData);

        // not user selected -> alert
        this.socket.emit('message create alert', {
          user_id_will_alert: localStorage.getItem('user_id'),
          message: lastData,
        })
      }

      this.socket.emit('message get inbox', {
        user_id: localStorage.getItem('user_id'),
      })
    });

    this.allUsersDataRender();
  }

  // Main conversion
  // Files 
  onChangeFile = e => {
    if (e.target.files.length > 3) {
      NotificatioErrController("Tối đa 3 tệp tin được cho phép"); return;
    }

    if (this.state.selectedUser.sender_id) {
      NotificatioErrController("Không hỗ trợ gửi file cho khách"); return;
    }

    for (let i = 0; i < e.target.files.length; i++) {
      let file = e.target.files[i];

      if (file.size >= 100000000) {
        NotificatioErrController("Kích thước tối đa 100 MB / tệp"); return;
      }
    }

    this.setState({ files: e.target.files })
  }
  onSelectedFile = e => {
    this.setState({ files: null })
  }
  handleSendFilesToConversation = (selectedUserId) => {
    let { files } = this.state;

    // files
    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (evt) => {
        let arrayData = reader.result;

        let template = {
          name: file.name,
          type: file.type,
          size: file.size,
          data: arrayData
        }

        this.socket.emit('message send conversation', {
          user_id: localStorage.getItem('user_id'),
          friend_id: selectedUserId,
          file: template,
          real_id: localStorage.getItem("user_id"),
        });
      }
    }
  }

  // Main conversion
  getConversation = (selectedUser) => {
    let selectedUserId = selectedUser.user_id != localStorage.getItem('user_id')
      ? selectedUser.user_id
      : selectedUser.friend_id;

    let offset = 0;
    if (this.state.conversationData) {
      offset = this.state.conversationData.length;
    }

    console.log({
      user_id: localStorage.getItem('user_id'),
      friend_id: selectedUserId,
      offset,
    });


    this.socket.emit('message get conversation', {
      user_id: localStorage.getItem('user_id'),
      friend_id: selectedUserId,
      offset,
    });
  }
  handleGetConversation = (selectedUser) => {
    this.getConversation(selectedUser)
    this.setState({ selectedUser, isLoadedMore: false, })
  }
  handleSendToConversation = () => {
    let { messageInput, selectedUser, files } = this.state;

    // selected user
    let selectedUserId = selectedUser.user_id != localStorage.getItem("user_id") ? selectedUser.user_id : selectedUser.friend_id;

    // files
    if (files) {
      this.handleSendFilesToConversation(selectedUserId)
    }

    this.socket.emit('message send conversation', {
      user_id: localStorage.getItem('user_id'),
      friend_id: selectedUserId,
      message: messageInput,
      real_id: localStorage.getItem("user_id"),
    });
  }
  handleBroadcastToConversation = () => {
    // reload this conversation
    let selectedUserId = 0;
    const { originalSelectedUser, messageInput } = this.state;

    if (originalSelectedUser) {
      selectedUserId = originalSelectedUser.user_id != localStorage.getItem("user_id") ? originalSelectedUser.user_id : originalSelectedUser.friend_id;
    }

    this.socket.emit('message broadcast conversation', {
      user_id: localStorage.getItem('user_id'),
      message: messageInput,
      current_id: selectedUserId
    });
  }
  handleChatInputPress = e => {
    if (e.key === "Enter") {
      this.handleSendButtonClick(); // forward
    }
  };
  handleChatInputChange = e => {
    this.setState({
      messageInput: e.target.value
    });
  };
  handleSendButtonClick = () => {
    let { messageInput, checkboxCheckall, files } = this.state;

    if (messageInput.length > 0 || files.length > 0) {
      if (checkboxCheckall) {
        // broadcast
        this.handleBroadcastToConversation();

        this.setState({
          selectedUser: this.state.originalSelectedUser,
          checkboxCheckall: !this.state.checkboxCheckall,
          // conversationData: this.state.originalConversationData,
        })
      } else {
        this.handleSendToConversation();
      }

      this.setState({
        messageInput: "",
        menuActiveTab: "messages",
        files: null,
      });
    }
  };

  // Menu
  toggleAppMenu = tab => {
    this.setState({
      menuActiveTab: tab
    });
  };

  changeConversation = (selectedUser) => {
    this.setState({
      selectedUser,
      conversationData: [],
      isFirstLoad: true,
    }, () => {
      if (selectedUser.sender_id) { // guest
        // this.handleGetConversationGuest(selectedUser);
      }
      else {
        this.handleGetConversation(selectedUser);
      }
    })
  }

  isLoaded = e => {
    this.setState({ isFirstLoad: false })
  }

  // CHECK ALL
  toggleCheckboxCheckAll = () => {

    if (this.state.checkboxCheckall) {
      // uncheck
      this.setState({
        selectedUser: this.state.originalSelectedUser,
        checkboxCheckall: !this.state.checkboxCheckall,
        conversationData: this.state.originalConversationData,
      })
    } else {
      // uncheck -> check
      this.setState({
        selectedUser: initSelectedUser,
        checkboxCheckall: !this.state.checkboxCheckall,
        conversationData: [],
      })
    }
  }

  handleSearchContact = keyword => {
    const { originalAllUsersData, originalInboxsData, menuActiveTab } = this.state;

    this.setState({
      searchKey: keyword
    });

    if (keyword.length > 0) {
      // this.props.toggleAppMenu("contacts")

      let dataSearch = [];
      if (menuActiveTab == "contacts") {
        // allUsersData
        if (keyword[0] === "#") {
          // uuid
          dataSearch = originalAllUsersData.filter(u => ("#" + u.companyUUID).indexOf(keyword) >= 0)
        } else {
          // name
          dataSearch = originalAllUsersData.filter(u => u.companyName && u.companyName.toLowerCase().indexOf(keyword.toLowerCase().trim()) >= 0 || u.fullname && u.fullname.toLowerCase().indexOf(keyword.toLowerCase().trim()) >= 0);
        }

        this.setState({
          allUsersData: dataSearch
        });
      } else {
        // inboxsData
        if (keyword[0] === "#") {
          // uuid
          dataSearch = originalInboxsData.filter(u => ("#" + u["friend.companyUUID"]).indexOf(keyword) >= 0)
        } else {
          // name
          dataSearch = originalInboxsData.filter(u =>
            u["friend.companyName"] && u["friend.companyName"].toLowerCase().indexOf(keyword.trim().toLowerCase()) >= 0
            || u["friend.fullname"] && u["friend.fullname"].toLowerCase().indexOf(keyword.trim().toLowerCase()) >= 0
            || u["user.companyName"] && u["user.companyName"].toLowerCase().indexOf(keyword.trim().toLowerCase()) >= 0
            || u["user.fullname"] && u["user.fullname"].toLowerCase().indexOf(keyword.trim().toLowerCase()) >= 0);
        }
        this.setState({
          inboxsData: dataSearch
        });
      }
    } else {
      this.setState({
        allUsersData: this.state.originalAllUsersData,
        inboxsData: this.state.originalInboxsData,
      })
    }
  };

  render() {
    const {
      menuActiveTab,
      messageInput,
      inboxsData,
      conversationData,
      selectedUser,
      allUsersData,
      checkboxCheckall,
      searchKey,
      files,
      isLoadedMore,
      isEndMessagesData,
      isFirstLoad,
    } = this.state;

    const store = window.location.host.split('.')[0];
    if (store !== 'message') {
      return <Redirect to="/app/dashboards/default" />
    }

    return (
      <Fragment>
        <Row className="app-row">
          {selectedUser ? (
            <Colxx xxs="12" className="chat-app">
              <ChatHeading
                item={selectedUser}
              />
              <PerfectScrollbar
                onYReachStart={(container) => {

                  if (!isEndMessagesData)
                    container.scrollTop = 63 * 8 - 33; // height * size - (height / 2)

                  if (isLoadedMore && !isEndMessagesData && !isFirstLoad) {
                    setTimeout(() => {
                      if (selectedUser.sender_id) { // guest
                      }
                      else {
                        this.handleGetConversation(selectedUser);
                      }
                    }, 1)
                  }

                  if (isFirstLoad) {
                    this.isLoaded()
                  }
                }}
                ref={ref => {
                  this._scrollBarRef = ref;
                }}
                containerRef={ref => { }}
                options={{ suppressScrollX: true, wheelPropagation: false }}
              >
                {conversationData.map((item, idx) =>
                  <div key={idx} ref={`message_${idx}`}>
                    <MessageCard
                      idx={idx}
                      item={item}
                    />
                  </div>
                )}
              </PerfectScrollbar>
              <SaySomething
                classNames="chat-input-container d-flex justify-content-between align-items-center"
                placeholder={"Viết tin nhắn"}
                messageInput={messageInput}
                handleSendButtonClick={this.handleSendButtonClick}
                handleChatInputChange={this.handleChatInputChange}
                handleChatInputPress={this.handleChatInputPress}
                onChangeFile={this.onChangeFile}
                onSelectedFile={this.onSelectedFile}
                filesLength={files ? files.length : 0}
              />
            </Colxx>
          ) : (
              <Colxx xxs="12" className="chat-app">
                <div className="message-default-content">
                  <p>Bắt đầu cuộc trò chuyện</p>
                </div>
              </Colxx>
            )}
        </Row>
        <ChatApplicationMenu
          toggleCheckboxCheckAll={this.toggleCheckboxCheckAll}
          checkboxCheckall={checkboxCheckall}
          inboxsData={inboxsData}
          activeTab={menuActiveTab}
          toggleAppMenu={this.toggleAppMenu}
          changeConversation={this.changeConversation}
          allUsersData={allUsersData}
          handleSearchContact={this.handleSearchContact}
          searchKey={searchKey}
        />
      </Fragment>
    )
  }
}

export default ChatApp