import React from 'react';
import ReactTable from 'react-table';
import Pagination from '../../../components/DatatablePagination';

const ContentMultiForm = (props) => {
    const { data } = props;
    // console.log(data);
    const columns = [
        {
            Header: 'Tên nguyên liệu',
            accessor: 'name',
            Cell: (props) => (
                <span className='text-capitalize'>{props.value}</span>
            ),
        },
        {
            Header: 'Đơn vị tính',
            accessor: 'unit',
            Cell: (props) => (
                <span className='text-capitalize'>{props.value}</span>
            ),
        },
        {
            Header: 'Tổng nhập',
            accessor: 'total',
        },
        {
            Header: 'Số lượng mới',
            accessor: 'income',
        },
        {
            Header: 'Tồn kho',
            accessor: 'count',
        },
        {
            Header: 'Còn dưới 20%',
            accessor: 'is_alert_down_20',
            Cell: (props) => (props.value === true ? 'Đúng' : 'Không đúng'),
        },
        {
            Header: 'Còn dưới 10%',
            accessor: 'is_alert_down_10',
            Cell: (props) => (props.value === true ? 'Đúng' : 'Không đúng'),
        },
    ];
    return (
        <ReactTable
            defaultPageSize={20}
            data={data}
            columns={columns}
            minRows={0}
            showPageJump={false}
            showPageSizeOptions={true}
            PaginationComponent={Pagination}
            NoDataComponent={() => null}
        />
    );
};

export default ContentMultiForm;
