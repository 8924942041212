import classNames from 'classnames';
import React, { Fragment } from 'react';
import { Badge, Button, Card, CardBody, CardTitle, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentDisplay = (props) => {
    // console.log(props);
    return (
        <Card className='mb-4'>
            <CardBody>
                <CardTitle>
                    <IntlMessages id='role.title' />
                </CardTitle>
                <Row className='mt-3 '>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='name' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.name ? (
                                props.values.name
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>Quyền</p>
                        <p className='mb-3'>
                            {props.values.user_role_id?.label}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            Tên đăng nhập
                        </p>
                        <p className='mb-3 text-lowercase'>
                            {props.values.username ? (
                                props.values.username
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>Email</p>
                        <p className='mb-3 text-lowercase'>
                            {props.values.email}
                        </p>
                    </Colxx>
                </Row>
            </CardBody>
        </Card>
    );
};

export default ContentDisplay;
