import React, { Component } from 'react';
import { NavItem, Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classnames from 'classnames';
import moment from 'moment';

import ApplicationMenu from '../../../components/common/ApplicationMenu';

class TodoApplicationMenu extends Component {
    render() {
        const { data, filter, filterData } = this.props;

        const today = moment(new Date()).format('YYYY-MM-DD');

        const filterByLaunchDate = (num) => {
            const todayDate = new Date();

            const startDayOfWeek = moment(todayDate)
                .subtract(0, 'week')
                .startOf('week')
                .format('YYYY-MM-DD');

            const startDayOfMonth = moment(todayDate)
                .subtract(0, 'month')
                .startOf('month')
                .format('YYYY-MM-DD');

            return data.filter((item) => {
                const launchDate = item.date;

                return (
                    launchDate >=
                    (num === 7
                        ? startDayOfWeek
                        : num === 30
                        ? startDayOfMonth
                        : null)
                );
            });
        };

        const filterByLaunchHours = (num) => {
            const todayDate = new Date();
            const today = moment(new Date()).format('YYYY-MM-DD');

            const oneHourOfDay = moment(todayDate)
                .subtract(-1, 'hour')
                .format('HH:mm');
            // console.log(oneHourOfDay);

            const threeHourOfDay = moment(todayDate)
                .subtract(-3, 'hour')
                .format('HH:mm');
            // console.log(threeHourOfDay);

            const sixHourOfDay = moment(todayDate)
                .subtract(-6, 'hour')
                .format('HH:mm');
            // console.log(sixHourOfDay);

            return data.filter((item) => {
                const launchTime = item.time;
                const launchDate = item.date;
                const notYet = item.status_name;
                // console.log(launchTime);
                return (
                    launchTime <=
                        (num === 1
                            ? oneHourOfDay
                            : num === 3
                            ? threeHourOfDay
                            : num === 6
                            ? sixHourOfDay
                            : null) &&
                    launchDate === today &&
                    notYet === 'Đang chờ'
                );
            });
        };
        // console.log(filterByLaunchHours(6));

        const filterByLaunchPrevDays = (num) => {
            const todayDate = new Date();
            const today = moment(new Date()).format('YYYY-MM-DD');

            const sevenDaysOfMonth = moment(todayDate)
                .subtract(7, 'day')
                .format('YYYY-MM-DD');
            // console.log(sevenDaysOfMonth);

            const thirdtyDaysOfMonth = moment(todayDate)
                .subtract(30, 'day')
                .format('YYYY-MM-DD');
            // console.log(thirdtyDaysOfMonth);

            const sixtyDaysOfMonth = moment(todayDate)
                .subtract(60, 'day')
                .format('YYYY-MM-DD');
            // console.log(sixtyDaysOfMonth);

            return data.filter((item) => {
                const launchDate = item.date;
                // console.log(launchTime);
                return (
                    launchDate >=
                        (num === 7
                            ? sevenDaysOfMonth
                            : num === 30
                            ? thirdtyDaysOfMonth
                            : num === 180
                            ? sixtyDaysOfMonth
                            : null) && item.status_name === 'Hoàn thành'
                );
            });
        };
        // console.log(filterByLaunchPrevDays(60));

        return (
            <ApplicationMenu>
                <PerfectScrollbar
                    options={{ suppressScrollX: true, wheelPropagation: false }}
                >
                    <div className='p-4'>
                        <p className='text-muted text-small'>Việc cần làm</p>
                        <ul className='list-unstyled mb-5'>
                            <NavItem
                                className={classnames({ active: !filter })}
                            >
                                <NavLink
                                    to='#'
                                    onClick={(e) => filterData('', '')}
                                    location={{}}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-layers' />
                                        <span>Tất cả</span>
                                    </div>
                                    <span className='float-right'>
                                        {data.length}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'date' &&
                                        filter.value === 'today',
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) => filterData('date', 'today')}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>Trong ngày</span>
                                    </div>
                                    <span className='float-right'>
                                        {
                                            data.filter((x) => x.date === today)
                                                .length
                                        }
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'date' &&
                                        filter.value === 7,
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) => filterData('date', 7)}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Trong tuần</span>
                                    </div>
                                    <span className='float-right'>
                                        {filterByLaunchDate(7).length}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'date' &&
                                        filter.value === 30,
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) => filterData('date', 30)}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Trong tháng</span>
                                    </div>
                                    <span className='float-right'>
                                        {filterByLaunchDate(30).length}
                                    </span>
                                </NavLink>
                            </NavItem>
                        </ul>
                        <p className='text-muted text-small'>Sắp diễn ra</p>
                        <ul className='list-unstyled mb-5'>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'next_hours' &&
                                        filter.value === 1,
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) => filterData('next_hours', 1)}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>Trong 1 giờ tới</span>
                                    </div>
                                    <span className='float-right'>
                                        {filterByLaunchHours(1).length}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'next_hours' &&
                                        filter.value === 3,
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) => filterData('next_hours', 3)}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Trong 3 giờ tới</span>
                                    </div>
                                    <span className='float-right'>
                                        {filterByLaunchHours(3).length}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'next_hours' &&
                                        filter.value === 6,
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) => filterData('next_hours', 6)}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Trong 6 giờ tới</span>
                                    </div>
                                    <span className='float-right'>
                                        {filterByLaunchHours(6).length}
                                    </span>
                                </NavLink>
                            </NavItem>
                        </ul>
                        <p className='text-muted text-small'>Đã diễn ra</p>
                        <ul className='list-unstyled mb-5'>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'pastDays' &&
                                        filter.value === 7,
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) => filterData('pastDays', 7)}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>7 ngày trước</span>
                                    </div>
                                    <span className='float-right'>
                                        {filterByLaunchPrevDays(7).length}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'pastDays' &&
                                        filter.value === 30,
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) => filterData('pastDays', 30)}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>30 ngày trước</span>
                                    </div>
                                    <span className='float-right'>
                                        {filterByLaunchPrevDays(30).length}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'pastDays' &&
                                        filter.value === 180,
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) => filterData('pastDays', 180)}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>6 tháng trước</span>
                                    </div>
                                    <span className='float-right'>
                                        {filterByLaunchPrevDays(180).length}
                                    </span>
                                </NavLink>
                            </NavItem>
                        </ul>
                        {/* <p className='text-muted text-small'>
                            Danh mục
                        </p>
                        <ul className='list-unstyled mb-5'>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'status' &&
                                        filter.value === 1,
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) =>
                                        filterData('status', 1)
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>Khách đặt bàn</span>
                                    </div>
                                    <span className='float-right'>
                                        1
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'status' &&
                                        filter.value === 'COMPLETED',
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) =>
                                        filterData('status', 'COMPLETED')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Ghi chú</span>
                                    </div>
                                    <span className='float-right'>
                                        3
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'status' &&
                                        filter.value === 'COMPLETED',
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) =>
                                        filterData('status', 'COMPLETED')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Lịch hẹn</span>
                                    </div>
                                    <span className='float-right'>
                                        2
                                    </span>
                                </NavLink>
                            </NavItem>
                        </ul> */}
                    </div>
                </PerfectScrollbar>
            </ApplicationMenu>
        );
    }
}

export default TodoApplicationMenu;
