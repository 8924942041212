import React, { memo, useState } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
    Row,
    Badge,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Colxx } from '../../../components/common/CustomBootstrap';
import { Creatable } from 'react-select';
import DynamicForm from '../general/DynamicForm';
import TooltipItem from '../../../components/common/TooltipItem';
import { AddCommaNumber, AddCommaPhoneNumber } from '../../../helpers/Utils';

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    data_dynamic_form,
    values,
    setting,
    onChange,
    onChangeDynamicForm,
    data_tags,
}) => {
    // console.log('render UpdateComponent');
    const [tagsTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Dùng để phân loại khách hàng. Ví dụ: "Khách mới", "Khách VIP"... ',
    });

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='pad-125rem scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        <FormGroup className='mt-3 has-float-label'>
                            <Label for='name' className='text-muted'>
                                <IntlMessages id='customer.name' />
                            </Label>
                            <Input
                                autoFocus
                                type='text'
                                name='name'
                                id='name'
                                value={values.name}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <Row>
                            <Colxx sm={12} md={12} lg={12}>
                                <FormGroup className='has-float-label'>
                                    <Label for='phone' className='text-muted'>
                                        <IntlMessages id='customer.phone' />
                                    </Label>
                                    <Input
                                        type='phone'
                                        name='phone'
                                        id='phone'
                                        value={AddCommaPhoneNumber(
                                            values.phone
                                        )}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Colxx>
                            <Colxx sm={12} md={12} lg={12}>
                                <FormGroup className='has-float-label'>
                                    <Label for='email' className='text-muted'>
                                        <IntlMessages id='customer.email' />
                                    </Label>
                                    <Input
                                        type='email'
                                        name='email'
                                        id='email'
                                        value={values.email}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Colxx>
                        </Row>
                        <FormGroup className='has-float-label'>
                            <Label for='address' className='text-muted'>
                                <IntlMessages id='customer.address' />
                            </Label>
                            <Input
                                type='text'
                                name='address'
                                id='address'
                                value={values.address}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <Row>
                            <Colxx xxs='4'>
                                <FormGroup className='d-flex flex-column has-float-label'>
                                    <Label for='debt' className='text-muted'>
                                        Công nợ
                                    </Label>
                                    {/* <p>
                                        <Badge color='primary' size='lg'>
                                            {AddCommaNumber(values.debt)}
                                        </Badge>
                                    </p> */}
                                    <Input
                                        disabled
                                        type='text'
                                        name='debt'
                                        id='debt'
                                        value={AddCommaNumber(values.debt)}
                                        // onChange={onChange}
                                    />
                                </FormGroup>
                            </Colxx>
                            <Colxx xxs='4'>
                                <FormGroup className='has-float-label'>
                                    <Label
                                        for='debt_less'
                                        className='text-muted'
                                    >
                                        Trả bớt
                                    </Label>
                                    <Input
                                        type='text'
                                        name='debt_less'
                                        id='debt_less'
                                        value={AddCommaNumber(
                                            values?.debt_less
                                        )}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Colxx>
                            <Colxx xxs='4'>
                                <FormGroup className='has-float-label'>
                                    <Label
                                        for='debt_more'
                                        className='text-muted'
                                    >
                                        Nợ thêm
                                    </Label>
                                    <Input
                                        type='text'
                                        name='debt_more'
                                        id='debt_more'
                                        value={AddCommaNumber(
                                            values?.debt_more
                                        )}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Colxx>
                        </Row>
                        <FormGroup className='has-float-label'>
                            <Label for='tags' className='text-muted'>
                                <IntlMessages id='customer.tags' />
                                <TooltipItem item={tagsTooltip} id={5} />
                            </Label>
                            <Creatable
                                className={'react-select-creatable'}
                                isMulti
                                onChange={(val) => onChange(val, 'tags')}
                                options={data_tags}
                                placeholder={'Chọn hoặc tạo mới'}
                                name='tags'
                                id='tags'
                                value={values.tags}
                            />
                        </FormGroup>

                        <DynamicForm
                            values={values.dynamic_form}
                            data={data_dynamic_form}
                            onChange={onChangeDynamicForm}
                        />
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='general.btn-update' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default React.memo(ContentComponent);
