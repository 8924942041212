/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* TODOAPP*/
export const TODO_GET_LIST = "TODO_GET_LIST";
export const TODO_GET_LIST_SUCCESS = "TODO_GET_LIST_SUCCESS";
export const TODO_GET_LIST_ERROR = "TODO_GET_LIST_ERROR";
export const TODO_GET_LIST_WITH_FILTER = "TODO_GET_LIST_WITH_FILTER";
export const TODO_GET_LIST_WITH_ORDER = "TODO_GET_LIST_WITH_ORDER";
export const TODO_GET_LIST_SEARCH = "TODO_GET_LIST_SEARCH";
export const TODO_ADD_ITEM = "TODO_ADD_ITEM";
export const TODO_ADD_ITEM_SUCCESS = "TODO_ADD_ITEM_SUCCESS";
export const TODO_ADD_ITEM_ERROR = "TODO_ADD_ITEM_ERROR";
export const TODO_SELECTED_ITEMS_CHANGE = "TODO_SELECTED_ITEMS_CHANGE";

/* PRODUCT */
export const PRODUCT_GET_LIST = "PRODUCT_GET_LIST";
export const PRODUCT_GET_LIST_SUCCESS = "PRODUCT_GET_LIST_SUCCESS";
export const PRODUCT_GET_LIST_ERROR = "PRODUCT_GET_LIST_ERROR";
export const PRODUCT_ADD_ITEM = "TODO_ADD_ITEM";
export const PRODUCT_ADD_ITEM_SUCCESS = "PRODUCT_ADD_ITEM_SUCCESS";
export const PRODUCT_ADD_ITEM_ERROR = "PRODUCT_ADD_ITEM_ERROR";
export const PRODUCT_DELETE_ITEM = "PRODUCT_DELETE_ITEM";
export const PRODUCT_DELETE_ITEM_SUCCESS = "PRODUCT_DELETE_ITEM_SUCCESS";
export const PRODUCT_DELETE_ITEM_ERROR = "PRODUCT_DELETE_ITEM_ERROR";

/* CATEGORY */
export const CATEGORY_GET_LIST = "CATEGORY_GET_LIST";
export const CATEGORY_GET_LIST_SUCCESS = "CATEGORY_GET_LIST_SUCCESS";
export const CATEGORY_GET_LIST_ERROR = "CATEGORY_GET_LIST_ERROR";
export const CATEGORY_ADD_ITEM = "CATEGORY_ADD_ITEM";
export const CATEGORY_ADD_ITEM_SUCCESS = "CATEGORY_ADD_ITEM_SUCCESS";
export const CATEGORY_ADD_ITEM_ERROR = "CATEGORY_ADD_ITEM_ERROR";
export const CATEGORY_DELETE_ITEM = "CATEGORY_DELETE_ITEM";
export const CATEGORY_DELETE_ITEM_SUCCESS = "CATEGORY_DELETE_ITEM_SUCCESS";
export const CATEGORY_DELETE_ITEM_ERROR = "CATEGORY_DELETE_ITEM_ERROR";
export const CATEGORY_UPDATE_ITEM = "CATEGORY_UPDATE_ITEM";
export const CATEGORY_UPDATE_ITEM_SUCCESS = "CATEGORY_UPDATE_ITEM_SUCCESS";
export const CATEGORY_UPDATE_ITEM_ERROR = "CATEGORY_UPDATE_ITEM_ERROR";

/* ORDER */
export const ORDER_ADD_ITEM = "ORDER_ADD_ITEM";
export const ORDER_ADD_ITEM_SUCCESS = "ORDER_ADD_ITEM_SUCCESS";
export const ORDER_ADD_ITEM_ERROR = "ORDER_ADD_ITEM_ERROR";


export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";