import React, { Fragment } from 'react';
import Select from 'react-select';
import {
    InputGroupAddon,
    Badge,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Row,
    Button,
} from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import CustomSelectInput from '../../../components/common/CustomSelectInput';
import IntlMessages from '../../../helpers/IntlMessages';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentFormCost = (props) => {
    const {
        make_products,
        data_dynamic_form,
        data_categories,
        data_stocks,
        values,
        form_cost,
        setting,
        onChange,
        onDeleteImage,
        onAddStock,
        onDeleteStock,
        onChangeDynamicForm,
    } = props;
    // console.log(props.make_products);

    const make_product = make_products.filter(
        (product) => product.product_id === values.id
    );

    return (
        <Form className='make-product'>
            <Row>
                <Colxx className='mb-3'>
                    <p className='text-muted text-small mb-2'>
                        <IntlMessages id='make-product.product-name' />
                    </p>
                    <p>{values.name}</p>
                </Colxx>
            </Row>
            <Row>
                <Colxx className='mb-3'>
                    <p className='text-muted text-small mb-2'>
                        <IntlMessages id='product.price.original' />
                    </p>
                    <p className='mb-3 text-lowercase'>
                        {values.price_sale !== '0' ? (
                            <>
                                <Badge
                                    color='primary'
                                    pill
                                    className='spacer-around-1'
                                >
                                    {AddCommaNumber(values.price_sale)}
                                </Badge>
                                <Badge
                                    color='light'
                                    pill
                                    className='spacer-around-1'
                                >
                                    <del>{AddCommaNumber(values.price)}</del>
                                </Badge>
                            </>
                        ) : (
                            <Badge color='primary' pill>
                                {AddCommaNumber(values.price)}
                            </Badge>
                        )}
                    </p>
                </Colxx>
            </Row>

            <Fragment>
                <Row className='make_product_unit'>
                    <Colxx xxs='7' className='mb-2'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='make-product.stock-name' />
                        </p>

                        <FormGroup className='mb-0'>
                            <Select
                                // isDisabled={!setting.add}
                                defaultInputValue={''}
                                components={{ Input: CustomSelectInput }}
                                className='react-select'
                                classNamePrefix='react-select'
                                name='stock'
                                options={data_stocks}
                                onChange={(val, extra) =>
                                    onChange(val, 'stock')
                                }
                                placeholder={
                                    <IntlMessages id='make-product.select-stock' />
                                }
                            />
                        </FormGroup>
                    </Colxx>
                    <Colxx xxs='3' className='mb-2 px-0'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='pages.order-number' />
                        </p>
                        <FormGroup className='mb-0'>
                            <InputGroup>
                                <Input
                                    className={'order-number'}
                                    type={'number'}
                                    name='count'
                                    placeholder={'0'}
                                    step='0'
                                    value={form_cost?.count || ''}
                                    onChange={onChange}
                                    // disabled={!setting.add}
                                />
                                <InputGroupAddon addonType='prepend'>
                                    {form_cost?.stock?.unit || ''}
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </Colxx>
                    <Colxx xxs='2' className='pr-0'>
                        <p className='text-muted text-small mb-2'>Thêm</p>
                        <Button
                            className='btn-add-make-product'
                            color='primary'
                            onClick={() => onAddStock()}
                        >
                            +
                        </Button>
                    </Colxx>
                </Row>
                <Separator className='mt-3 mb-3' />
                {form_cost.make?.length ? (
                    <ul className={'orders-pending'}>
                        {form_cost.make?.map((stock, idx) => (
                            <li key={idx} className='mr-2'>
                                <Button color='primary' className='px-3 py-2'>
                                    {stock.name}
                                    <Badge className='ml-2' color='light'>
                                        {stock.count + ' (' + stock.unit + ')'}
                                    </Badge>{' '}
                                    {setting.viewonly && !setting.add ? null : (
                                        <Badge
                                            onClick={() =>
                                                onDeleteStock(stock.id)
                                            }
                                            color='danger'
                                        >
                                            x
                                        </Badge>
                                    )}
                                </Button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    ''
                )}
                {make_product?.length ? (
                    <ul className={'orders-pending'}>
                        {make_product?.map((stock, idx) => (
                            <li key={idx} className='mr-2'>
                                <Button
                                    disabled={setting.viewonly && !setting.add}
                                    color='primary'
                                    className='px-3 py-2'
                                >
                                    {stock.stock.name}
                                    <Badge className='ml-2' color='light'>
                                        {stock.count +
                                            ' (' +
                                            stock.stock.unit +
                                            ')'}
                                    </Badge>{' '}
                                    {/* {setting.viewonly && !setting.add ? null : (
                                        <Badge
                                            onClick={() =>
                                                onDeleteStock(stock.id)
                                            }
                                            color='danger'
                                        >
                                            x
                                        </Badge>
                                    )} */}
                                </Button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className='d-flex justify-content-center align-items-center text-muted'>
                        Sản phẩm này chưa được định lượng!
                    </p>
                )}
                <Separator className='mt-3 mb-3' />
                <Row>
                    <Colxx sm='6'>
                        <p className='text-muted text-small mb-2'>Giá cost</p>
                        <p className='text-muted'>(chưa có thông tin)</p>
                    </Colxx>
                    <Colxx sm='6'>
                        <p className='text-muted text-small mb-2'>
                            Giá cost/Giá bán
                        </p>
                        <p className='text-muted'>(chưa có thông tin)</p>
                    </Colxx>
                </Row>
            </Fragment>
        </Form>
    );
};

export default ContentFormCost;
