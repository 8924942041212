import React, { memo, useState } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
    Row,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Select, { Creatable } from 'react-select';
import CustomSelectInput from '../../../components/common/CustomSelectInput';
import { GetSelectTime, SetSelectTime } from '../../../helpers/SelectTime';
import { AddCommaNumber, AddCommaPhoneNumber } from '../../../helpers/Utils';
import DynamicForm from '../general/DynamicForm';
import TooltipItem from '../../../components/common/TooltipItem';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    data_dynamic_form,
    values,
    data_stocks,
    stock_name,
    setting,
    onChange,
    onChangeDynamicForm,
}) => {
    // console.log('render AddComponent');

    const findStockEqual = data_stocks?.find((d) => d.name === values?.stock);

    const [timeTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Nếu chọn "Đưa vào thống kê" thì số liệu này sẽ được tính như một khoản chi trong ngày. Nếu chọn "Không đưa vào thống kê" thì chỉ xem như một con số tượng trưng.',
    });
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
            autoFocus={false}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        <FormGroup className='mt-3 has-float-label'>
                            <Label for='stock' className='text-muted'>
                                <IntlMessages id='warehouse.import-stock' />
                            </Label>
                            <Creatable
                                className={'react-select-creatable'}
                                onChange={(val) => onChange(val, 'stock')}
                                options={data_stocks}
                                placeholder={'Chọn 1 mặt hàng hoặc tạo mới'}
                                name='stock'
                                id='stock'
                                value={data_stocks?.value}
                            />
                        </FormGroup>
                        {/* <FormGroup>
                            <Label for='code' className='text-muted'>
                                <IntlMessages id='warehouse.code' />
                            </Label>
                            <Input
                                type='text'
                                name='code'
                                id='code'
                                value={values.code}
                                onChange={onChange}
                            />
                        </FormGroup> */}
                        {!findStockEqual ? (
                            <FormGroup className='has-float-label'>
                                <Label for='unit' className='text-muted'>
                                    <IntlMessages id='warehouse.unit' />
                                </Label>
                                <Input
                                    type='text'
                                    name='unit'
                                    id='unit'
                                    value={values.unit}
                                    onChange={onChange}
                                />
                            </FormGroup>
                        ) : null}
                        <FormGroup className='has-float-label'>
                            <Label for='quantity' className='text-muted'>
                                <IntlMessages id='warehouse.import-quantity' />
                            </Label>
                            <Input
                                type='text'
                                name='quantity'
                                id='quantity'
                                value={AddCommaNumber(values.quantity)}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <Row>
                            <Colxx xxs={findStockEqual ? '6' : '12'}>
                                <FormGroup className='has-float-label'>
                                    <Label for='price' className='text-muted'>
                                        <IntlMessages id='warehouse.import-price' />
                                    </Label>
                                    <Input
                                        type='text'
                                        name='price'
                                        id='price'
                                        value={AddCommaNumber(values.price)}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Colxx>
                            {findStockEqual ? (
                                <Colxx xxs='6'>
                                    <FormGroup className='has-float-label'>
                                        <Label
                                            for='price'
                                            className='text-muted'
                                        >
                                            Giá mua trung bình
                                        </Label>
                                        <Input
                                            disabled
                                            type='text'
                                            name='price'
                                            id='price'
                                            value={AddCommaNumber(
                                                findStockEqual?.avarage_price
                                            )}
                                            // onChange={onChange}
                                        />
                                    </FormGroup>
                                </Colxx>
                            ) : null}
                        </Row>

                        {/* <FormGroup>
                            <Label for='time' className='text-muted'>
                                <IntlMessages id='time' />
                                <TooltipItem item={timeTooltip} id={7} />
                            </Label>
                            <Select
                                components={{ Input: CustomSelectInput }}
                                className='react-select'
                                classNamePrefix='react-select'
                                name='time'
                                value={GetSelectTime(values.time)}
                                placeholder={
                                    <IntlMessages id='select-one-time' />
                                }
                                options={SetSelectTime()}
                                onChange={(e) => {
                                    onChange(e, 'time');
                                }}
                            />
                        </FormGroup> */}
                        <Separator className='mb-4 mt-3' />
                        <FormGroup className='has-float-label'>
                            <Label for='name' className='text-muted'>
                                <IntlMessages id='warehouse.import-name' />
                            </Label>
                            <Input
                                type='text'
                                name='name'
                                id='name'
                                value={values.name}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='phone' className='text-muted'>
                                <IntlMessages id='warehouse.phone' />
                            </Label>
                            <Input
                                type='text'
                                name='phone'
                                id='phone'
                                value={AddCommaPhoneNumber(values.phone)}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='address' className='text-muted'>
                                <IntlMessages id='warehouse.address' />
                            </Label>
                            <Input
                                type='text'
                                name='address'
                                id='address'
                                value={values.address}
                                onChange={onChange}
                            />
                        </FormGroup>
                        {/* {setting.update ? (
                            <FormGroup>
                            <Label for="user.created">
                                <IntlMessages id="user.created" />
                            </Label>
                            <Input type="text" name="user.created" id="user.created"
                                disabled={true}
                                value={values.user}
                                onChange={onChange}
                            />
                            </FormGroup>
                        ) : null} */}

                        <DynamicForm
                            values={values.dynamic_form}
                            data={data_dynamic_form}
                            onChange={onChangeDynamicForm}
                        />
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='warehouse.btn-add-import' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default memo(ContentComponent);
