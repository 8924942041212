import React from 'react';
import { Alert } from 'reactstrap';
import {
    NotificatioErrController,
    NotificatioSucController,
    CheckConfirmPassword,
} from '../../helpers/Utils';
import axios from 'axios';
import { END_POINT } from '../../constants/defaultValues';
import PasswordBox from '../../components/PasswordBox';

class ContentComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: null,
            data: [],

            // password
            password: '',
            isOpenModalPassword: false,
            passwordFuncCallback: null,
        };
    }

    componentDidMount() {
        this.dataListRender();
    }

    dataListRender = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/companies/pending'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                this.setState((prev) => ({
                    ...prev.state,
                    data: data.data,
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response?.data?.responseText);
                return false;
            });
    };

    onSubmitAccept = (id) => {
        let domain = window.location.host.split('.')[0];
        if (domain !== 'demo') {
            // confirm password
            if (this.state.password === '') {
                this.setState({
                    passwordFuncCallback: this.onSubmitAccept,
                    isOpenModalPassword: true,
                    id,
                });
                return false;
            } else {
                this.setState({
                    password: '',
                    isOpenModalPassword: false,
                });
            }
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/companies/accept'}`,
                {
                    id: this.state.id,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.dataListRender();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onSubmitReject = (id) => {
        let domain = window.location.host.split('.')[0];
        if (domain !== 'demo') {
            // confirm password
            if (this.state.password === '') {
                this.setState({
                    passwordFuncCallback: this.onSubmitReject,
                    isOpenModalPassword: true,
                    id,
                });
                return false;
            } else {
                this.setState({
                    password: '',
                    isOpenModalPassword: false,
                });
            }
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .delete(`${END_POINT + '/companies'}`, {
                data: {
                    arrayId: [this.state.id],
                },
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.dataListRender();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    /** Password */
    handleChangeValueModalPassword = (value) => {
        this.setState({ password: value });
    };
    handleChangeValueModalIsOpen = (value) => {
        this.setState({
            password: '',
            isOpenModalPassword: !this.state.isOpenModalPassword,
        });
    };
    handleSubmitConfirmPassword = async (callback) => {
        if (this.state.password === '') {
            this.setState({
                isOpenModalPassword: true,
            });
            return false;
        } else {
            if (!(await CheckConfirmPassword(this.state.password))) {
                NotificatioErrController('Xác thực mật khẩu không thành công');
                return false;
            }
            callback();
        }
    };
    /** Password */

    render() {
        const {
            data,

            password,
            isOpenModalPassword,
            passwordFuncCallback,
        } = this.state;

        return (
            <div>
                <PasswordBox
                    password={password}
                    onChange={this.handleChangeValueModalPassword}
                    isOpen={isOpenModalPassword}
                    toggleModal={this.handleChangeValueModalIsOpen}
                    onSubmit={this.handleSubmitConfirmPassword}
                    callback={passwordFuncCallback}
                />

                {data.map((_item, idx) => (
                    <Alert
                        key={idx}
                        color='primary'
                        className='rounded companies-required'
                    >
                        <span className='alert-link fz-20'>{_item.name}</span>{' '}
                        <span>gửi yêu cầu làm</span>{' '}
                        <span className='alert-link fz-20'>{_item.type}</span>{' '}
                        <span>tới bạn</span>{' '}
                        <span className='submit-btn'>
                            <span
                                className='link'
                                onClick={() => {
                                    this.onSubmitAccept(_item.id);
                                }}
                            >
                                Đồng ý
                            </span>
                            {'  '}
                            <span
                                className='link'
                                onClick={() => {
                                    this.onSubmitReject(_item.id);
                                }}
                            >
                                Từ chối
                            </span>
                        </span>
                    </Alert>
                ))}
            </div>
        );
    }
}

export default ContentComponent;
