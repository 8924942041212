import React, { Component } from "react";
import {
  Tooltip
} from 'reactstrap'

export default class CustomTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false
    };
  }
  toggle = () => {
    this.setState(prevState => ({
      tooltipOpen: !prevState.tooltipOpen
    }));
  };

  render() {
    return (
      <span>
        <Tooltip
          placement={this.props.placement}
          isOpen={this.state.tooltipOpen}
          target={"Tooltip-" + this.props.id}
          toggle={e => {this.toggle()}}
          trigger="click"
        >
          {this.props.body}
        </Tooltip>
      </span>
    );
  }
}