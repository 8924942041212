import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import BigCalendar from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.less';
import moment from 'moment';
import 'moment/locale/vi';

import { CalendarToolbar } from '../../components/CalendarToolbar';
import { NotificationManager } from '../../components/common/react-notifications';
import { END_POINT_SOCKET } from '../../constants/defaultValues';
import io from 'socket.io-client';

import { getDirection } from '../../helpers/Utils';
import { isR } from '../../helpers/Roles';
import classnames from 'classnames';

let allViews = Object.keys(BigCalendar.Views).map((k) => BigCalendar.Views[k]);

BigCalendar.momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(BigCalendar);

const showNotification = (event, type) => {
    if (type === 'success') {
        NotificationManager.primary(
            event.description,
            event.title + ' - ' + event.time,
            5000,
            null,
            null,
            'filled'
        );
    }

    if (type === 'warning') {
        NotificationManager.warning(
            event.description,
            event.title + ' - ' + event.time,
            5000,
            null,
            null,
            'filled'
        );
    }

    if (type === 'danger') {
        NotificationManager.error(
            event.description,
            event.title + ' - ' + event.time,
            5000,
            null,
            null,
            'filled'
        );
    }
};

const onSelectEvent = (event) => {
    // console.log('event: ', event);
    if (event.status_id) {
        // done
        showNotification(event, 'success');
    } else {
        showNotification(event, 'danger');
    }
};

// const onShowMore = (events) => {
//     events.map((event) => {
//         if (event.status_id) {
//             // done
//             return showNotification(event, 'success');
//         } else {
//             return showNotification(event, 'danger');
//         }
//     });
// };

const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = null;
    if (event.status_id) {
        backgroundColor = 'green';
    } else {
        backgroundColor = '#c43d4b';
    }
    var style = {
        backgroundColor: backgroundColor,
        opacity: 0.9,
        color: '#fff',
    };
    return {
        style: style,
    };
};

// const { start, end } = slotInfo;
// const eventsForThisDay = events.filter(
//     (event) => event.start >= start && event.start < end
// );

class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            localizer: BigCalendar.momentLocalizer(moment),
            events: [],
            isLoading: false,
        };

        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        this.socket.on('refresh-todo-month', (res) => {
            const { data } = res;

            this.setState({
                events: data.data,
                isLoading: true,
            });
        });

        this.socket.emit('refresh-todo-month', {
            user_id: localStorage.getItem('user_id'),
        });
    }

    componentWillUnmount() {
        this.socket.close();
    }

    moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
        const { events } = this.state;

        const idx = events.indexOf(event);
        let allDay = event.allDay;

        if (!event.allDay && droppedOnAllDaySlot) {
            allDay = true;
        } else if (event.allDay && !droppedOnAllDaySlot) {
            allDay = false;
        }

        const updatedEvent = { ...event, start, end, allDay };

        const nextEvents = [...events];
        nextEvents.splice(idx, 1, updatedEvent);

        this.setState({
            events: nextEvents,
        });
    };

    newEvent = ({ start, end }) => {
        //Change next two lines to make nicer way of adding an event
        const title = window.prompt('Thêm tiêu đề sự kiện');
        const description = window.prompt('Thêm mô tả sự kiện');
        if (title)
            this.setState({
                events: [
                    ...this.state.events,
                    {
                        start,
                        end,
                        title,
                        description,
                    },
                ],
            });
    };
    render() {
        let { localizer, events, isLoading } = this.state;
        // console.log(events);

        return !isLoading ? (
            <div className='loading' />
        ) : (
            <div className='mb-4'>
                <Card
                    className={classnames({
                        disable: !isR('calendar'),
                    })}
                >
                    <CardBody>
                        <DragAndDropCalendar
                            popup
                            editable={true}
                            selectable={true}
                            localizer={localizer}
                            onEventDrop={this.moveEvent}
                            onSelectSlot={() => {
                                this.props.onClickedAdd();
                            }}
                            defaultView={BigCalendar.Views.MONTH}
                            defaultDate={new Date()}
                            step={60}
                            views={allViews}
                            style={{ minHeight: '750px' }}
                            events={events.sort((a, b) =>
                                a.status_name.localeCompare(b.status_name)
                            )}
                            rtl={getDirection().isRtl}
                            onSelectEvent={(event) => {
                                onSelectEvent(event);
                            }}
                            components={{
                                toolbar: CalendarToolbar,
                            }}
                            eventPropGetter={eventStyleGetter}
                        />
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default Calendar;
