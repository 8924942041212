import React, { Component } from 'react';
import {
    Row,
    Col,
    Nav,
    NavItem,
    TabContent,
    TabPane,
    Card,
    CardBody,
} from 'reactstrap';
import axios from 'axios';
import { END_POINT, END_POINT_SOCKET } from '../../../constants/defaultValues';
import {
    SetItemsSelected,
    NotificatioErrController,
    NotificatioSucController,
} from '../../../helpers/Utils';
import io from 'socket.io-client';
import {
    PageHeading,
    ContentForm,
    ContentFormLog,
    ContentFormItemLog,
    ContentLog,
    Log,
    ContentDisplay,
    ListItemFormMobile,
    CompanyProviderApplicationMenu,
} from '../../../containers/pages/company-provider';
import { Add, Update, Pagination } from '../../../containers/pages/general';
import LogsHelper from '../../../helpers/Logs';
import IntlMessages from '../../../helpers/IntlMessages';
import classnames from 'classnames';
import { isC, isR, isU, isD } from '../../../helpers/Roles';
import { NavLink } from 'react-router-dom';
import FooterCompanyProvider from '../../../containers/pages/company-provider/FooterCompanyProvider';
import FooterPage from '../../../containers/pages/FooterPage';
import { Separator } from '../../../components/common/CustomBootstrap';
import AddConpanyProvider from '../../../containers/pages/company-provider/AddConpanyProvider';
import AddCompanyProviderLog from '../../../containers/pages/company-provider/AddCompanyProviderLog';
import AddCompanyProviderItemLog from '../../../containers/pages/company-provider/AddCompanyProviderItemLog';
import UpdateCompanyProvider from '../../../containers/pages/company-provider/UpdateCompanyProvider';
import ListItemLeftForm from '../../../containers/pages/company-provider/ListItemLeftForm';
import ContentMultiForm from '../../../containers/pages/company-provider/ContentMultiForm';

const DEFAULT_FORM = {
    id: '',
    name: '',
    phone: '',
    email: '',
    address: '',
    total_price_income: '0',
    total_price_outcome: '0',
    total_price_pending: '0',
    price_income: '0',
    price_outcome: '0',
    price_pending: '0',
    log: [],

    // item
    items_selected: [],

    //item log
    item_id: '',
    item_name: '',

    dynamic_form: [],
};

const ROLE_ALIAS = 'company_provider';

class ContentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: {
                pageSizes: [12, 24],
                selectedPageSize: 12,
                currentPage: 1,
                totalPage: 1,
                totalItem: 0,
                search: '',
            },
            selectedItems: [],
            data: [],
            data_all: [],

            data_company_provider: null,
            filter: null,

            data_dynamic_form: [],
            is_loading_data: true,
            show: {
                add: false,
                add_item: false,
                add_item_log: false,
                update: false,
                view: false,
                viewonly: true,
            },
            accordion: [],
            activeTab: '1',
            displayMode: 'thumblist',
            form: DEFAULT_FORM,
        };

        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        this.dataListRender();
        this.dataAllRender();
    }

    /** Axios func helper */
    dataListRender = () => {
        const { selectedPageSize, currentPage, search } = this.state.page;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(
                `${
                    END_POINT + '/company-provider/json'
                }?pageSize=${selectedPageSize}&currentPage=${currentPage}&search=${search}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((data) => {
                let anyAccordion = [];
                let numeroPerguntas = data.data.length;
                for (var i = 0; i < numeroPerguntas; i++) {
                    // if (!i) {
                    //     anyAccordion.push(true);
                    // }
                    anyAccordion.push(false);
                }
                this.setState(
                    (prev) => ({
                        ...prev,
                        data: data.data,
                        data_dynamic_form: data.dataDynamicForm,
                        is_loading_data: false,
                        page: {
                            ...prev.page,
                            totalPage: data.totalPage,
                            totalItem: data.totalItem,
                        },
                        accordion: anyAccordion,
                    }),
                    () => {
                        if (this.state.form.id) {
                            const {
                                id,
                                name,
                                phone,
                                email,
                                address,
                                total_price_income,
                                total_price_outcome,
                                total_price_pending,
                                company_provider_item,
                                dynamic_form,
                            } = this.state.form;
                            this.setState((prev) => ({
                                ...prev,
                                form: {
                                    ...prev.form,
                                    id,
                                    name,
                                    phone,
                                    email,
                                    address,
                                    total_price_income,
                                    total_price_outcome,
                                    total_price_pending,
                                    log: company_provider_item,
                                    dynamic_form,
                                },
                            }));
                        } else {
                            this.setState((prev) => ({
                                ...prev,
                                selectedItems: [],
                                show: {
                                    ...prev.show,
                                    update: false,
                                },
                                form: DEFAULT_FORM,
                            }));
                        }
                    }
                );
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    dataAllRender = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/company-provider'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                this.setState((prev) => ({
                    ...prev,
                    data_all: data.data,
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerDelete = () => {
        if (!isD(ROLE_ALIAS)) return false;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .delete(`${END_POINT + '/company-provider'}`, {
                data: {
                    arrayId: this.state.selectedItems,
                },
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['company-provider-delete'],
                });

                this.dataListRender();
                this.dataAllRender();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerUpdate = (e) => {
        if (!isU(ROLE_ALIAS)) return false;
        e.preventDefault();

        const { id, name, phone, email, address, dynamic_form } =
            this.state.form;

        if (!name || !id) {
            NotificatioErrController('Dữ liệu không hợp lệ');
            return;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .put(
                `${END_POINT + '/company-provider'}`,
                {
                    id,
                    name,
                    phone,
                    email,
                    address,
                    dynamic_form,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['company-provider-update'],
                });

                this.dataListRender();
                this.dataAllRender();
                this.toggle('update');
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerAdd = () => {
        if (!isC(ROLE_ALIAS)) return false;

        const { name, phone, email, address, dynamic_form } = this.state.form;

        if (!name) {
            NotificatioErrController('Tên nhà cung cấp cần được thêm vào');
            return;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/company-provider'}`,
                {
                    name,
                    phone,
                    email,
                    address,
                    dynamic_form,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['company-provider-create'],
                });

                this.dataListRender();
                this.dataAllRender();
                // this.toggle('add');
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerAddItem = () => {
        if (!isC(ROLE_ALIAS)) return false;

        const { id, items_selected, price_income, price_outcome } =
            this.state.form;

        if (!id || !items_selected || !price_income) {
            NotificatioErrController('Dữ liệu không hợp lệ');
            return;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/company-provider/item'}`,
                {
                    id,
                    name: [items_selected],
                    price_income: price_income.toString().replace(/\./g, ''),
                    price_outcome: price_outcome.toString().replace(/\./g, ''),
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['company-provider-create-log'],
                });

                this.dataListRender();
                this.dataAllRender();
                this.toggleItem('add_item');
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerAddLog = () => {
        if (!isC(ROLE_ALIAS)) return false;

        const { item_id, price_outcome } = this.state.form;

        if (!item_id || !price_outcome) {
            NotificatioErrController('Dữ liệu không hợp lệ');
            return;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/company-provider/log'}`,
                {
                    id: item_id,
                    price_outcome: price_outcome.toString().replace(/\./g, ''),
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['company-provider-create-log'],
                });

                this.dataListRender();
                this.dataAllRender();
                this.toggleItem('add_item_log');
                this.setState((prev) => ({
                    ...prev,
                    form: {
                        ...prev.form,
                        form: DEFAULT_FORM,
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };
    /** Axios func helper */

    /** State func helper */
    toggle = (name) => {
        if (name === 'add' && !isC(ROLE_ALIAS)) return false;
        if (name === 'update' && !isU(ROLE_ALIAS)) return false;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                update: name === 'add' ? false : prev.show.update,
                [name]: !prev.show[name],
            },
            selectedItems: name === 'add' ? [] : prev.selectedItems,
            form: name === 'add' ? DEFAULT_FORM : prev.form,
        }));
    };

    hideDisplayContent = () => {
        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                view: false,
            },
        }));
    };

    toggleItem = (name) => {
        if (name === 'add_item' && !isU(ROLE_ALIAS)) return false;
        if (name === 'add_item_log' && !isU(ROLE_ALIAS)) return false;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                [name]: !prev.show[name],
            },
        }));
    };
    toggleItemLog = (item) => {
        if (!isU(ROLE_ALIAS)) return false;

        const { id, name } = item;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                add_item_log: true,
            },
            form: {
                ...prev.form,
                item_id: id,
                item_name: name,
                price_outcome: 0,
            },
        }));
    };

    onChangeForm = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [name]: value,
                },
            }));
        } else {
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [_name]: e,
                },
            }));
        }
    };

    onChangeFormPrice = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;

            let input_value =
                parseFloat(value.toString().replace(/\./g, '')) || 0;
            let price_outcome =
                parseFloat(
                    this.state.form.price_outcome.toString().replace(/\./g, '')
                ) || 0;
            let price_income =
                parseFloat(
                    this.state.form.price_income.toString().replace(/\./g, '')
                ) || 0;

            let price_pending = 0;
            if (name === 'price_income') {
                price_pending = input_value - price_outcome;
            }
            if (name === 'price_outcome') {
                price_pending = price_income - input_value;
            }

            let price_parse = parseFloat(price_pending);

            if (isNaN(price_parse)) {
                price_pending = 0;
            } else if (price_parse < 0) {
                price_pending = 0;
            }

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [name]: value,
                    price_pending: price_pending,
                },
            }));
        } else {
        }
    };

    onChangeDynamicForm = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    dynamic_form: {
                        ...prev.form.dynamic_form,
                        [name]: value,
                    },
                },
            }));
        } else {
            let value = e.value ? e.value : e;

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    dynamic_form: {
                        ...prev.form.dynamic_form,
                        [_name]: value,
                    },
                },
            }));
        }
    };

    onSubmitForm = (type, e) => {
        e.preventDefault();
        if (type === 'update') {
            this.onHandlerUpdate();
        } else if (type === 'add') {
            this.onHandlerAdd();
        } else if (type === 'add-item') {
            this.onHandlerAddItem();
        } else if (type === 'add-item-log') {
            this.onHandlerAddLog();
        }
    };

    onChangePage = (page) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    currentPage: page,
                },
            }),
            () => this.dataListRender()
        );
    };

    onSearchKey = (e) => {
        if (!e) return false;

        if (e.target) {
            let {
                target: { value },
            } = e;
            let v = value;
            if (value === undefined) {
                v = e.target.firstElementChild.value; // parent to child

            //     value = v.toLowerCase();
            //     this.setState(
            //         (prev) => ({
            //             ...prev,
            //             page: {
            //                 ...prev.page,
            //                 search: value,
            //             },
            //         }),
            //         () => this.dataListRender()
            //     );
            // } else {
            //     value = value.toLowerCase();
            //     this.setState(
            //         (prev) => ({
            //             ...prev,
            //             page: {
            //                 ...prev.page,
            //                 search: value,
            //             },
            //         }),
            //         () => this.dataListRender()
            //     );
            }
        }

        if (e.key === 'Enter') {
            let value = e.target.value.toLowerCase();
            this.setState(
                (prev) => ({
                    ...prev,
                    page: {
                        ...prev.page,
                        search: value,
                    },
                }),
                () => this.dataListRender()
            );
        }
    };

    onChangePageSize = (size) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    selectedPageSize: size,
                    currentPage: 1,
                },
            }),
            () => this.dataListRender()
        );
    };

    onClickedItem = (item, callback, args) => {
        if (this.state.form.id !== item.id) {
            const {
                id,
                name,
                phone,
                email,
                address,
                total_price_income,
                total_price_outcome,
                total_price_pending,
                company_provider_item,
                dynamic_form,
            } = item;

            this.setState(
                (prev) => ({
                    ...prev,
                    show: {
                        ...prev.show,
                        update: false,
                        view: true,
                        viewonly: false,
                    },
                    form: {
                        ...prev.form,
                        id,
                        name,
                        phone,
                        email,
                        address,
                        total_price_income,
                        total_price_outcome,
                        total_price_pending,
                        dynamic_form,
                        log: company_provider_item,
                    },
                    selectedItems: [item.id],
                }),
                () => {
                    callback(args[0], args[1], args[2]);
                }
            );
        }
    };

    onClickedUpdate = (item) => {
        const {
            id,
            name,
            phone,
            email,
            address,
            total_price_income,
            total_price_outcome,
            total_price_pending,
            company_provider_item,
            dynamic_form,
        } = item;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                viewonly: false,
                update: true,
            },
            form: {
                ...prev.form,
                id,
                name,
                phone,
                email,
                address,
                total_price_income,
                total_price_outcome,
                total_price_pending,
                dynamic_form,
                log: company_provider_item,
            },
        }));
    };

    onClickedDelete = (item) => {
        let selectedSet = SetItemsSelected(
            item.id,
            this.state.selectedItems,
            'require'
        );
        if (window.confirm('Bạn chắc chắn muốn xoá nhà cung cấp này?')) {
            this.setState(
                (prev) => ({
                    ...prev,
                    selectedItems: selectedSet,
                }),
                () => this.onHandlerDelete()
            );
        }
    };

    onClickedCheckbox = (id, callback) => {
        // let selectedSet = SetItemsSelected(id, this.state.selectedItems);

        this.setState((prev) => ({
            ...prev,
            selectedItems: [id],
        }));
    };
    /** State func helper */

    toggleAccordion = (tab) => {
        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => (tab === index ? !x : false));
        this.setState({
            accordion: state,
        });
    };

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    filterData = (key, value) => {
        const { data_all } = this.state;
        if (key === '' && value === '') {
            this.setState((prev) => ({
                ...prev,
                data: data_all,
                filter: null,
            }));
        } else if (key === 'outcome-lessthan-income') {
            const filteredStatus = data_all.filter(
                (item) => item.total_price_income > item.total_price_outcome
            );
            this.setState((prev) => ({
                ...prev,
                data: filteredStatus,
                filter: { column: key, value },
            }));
        } else if (key === 'outcome-equal-income') {
            const filteredStatus = data_all.filter(
                (item) =>
                    item.total_price_income === item.total_price_outcome &&
                    item.total_price_income !== '0'
            );
            this.setState((prev) => ({
                ...prev,
                data: filteredStatus,
                filter: { column: key, value },
            }));
        } else if (key === 'item_product') {
            const filteredItemProduct = data_all.filter((item) =>
                item.company_provider_item.find((i) => i.name === value)
            );
            this.setState((prev) => ({
                ...prev,
                data: filteredItemProduct,
                filter: { column: key, value },
            }));
        } else {
            const filteredItems = data_all.filter(
                (item) => item[key] === value
            );
            this.setState((prev) => ({
                ...prev,
                data: filteredItems,
                filter: { column: key, value },
            }));
        }
    };

    changeDisplayMode = (mode) => {
        this.setState((prev) => ({
            ...prev,
            displayMode: mode,
            show: {
                ...prev.show,
                view: false,
            },
        }));
        return false;
    };

    render() {
        const { match } = this.props;
        const {
            page,
            selectedItems,
            data,
            data_all,
            filter,
            data_tags,
            is_loading_data,
            show,
            form,
            accordion,
            displayMode,
            data_dynamic_form,
        } = this.state;

        // console.log('data: ', data);
        // console.log('data_all: ', data_all);
        // const dataRender = data_company_provider?.length
        //     ? data_company_provider
        //     : data;
        // console.log(dataRender);

        if (!isR(ROLE_ALIAS))
            return (
                <Card className='text-muted mh-40vh h-100'>
                    <CardBody className='d-flex justify-content-center align-items-center'>
                        Bạn không có quyền sử dụng tính năng này!
                    </CardBody>
                </Card>
            );

        return is_loading_data ? (
            <div className='loading' />
        ) : (
            <div
                // className={classnames(
                //     'disable-text-selection',
                //     displayMode === 'thumblist' ? 'app-row' : ''
                // )}
                className='app-row disable-text-selection'
            >
                <PageHeading
                    data={data}
                    heading='analytics.company-provider'
                    setting={page}
                    match={match}
                    totalItem={page.totalItem}
                    isDisabledAdd={!isC(ROLE_ALIAS)}
                    displayMode={displayMode}
                    changeDisplayMode={this.changeDisplayMode}
                    toggleFunc={() => {
                        this.toggle('add');
                        this.hideDisplayContent();
                        this.toggleAccordion();
                    }}
                    changePageSizeFunc={this.onChangePageSize}
                    onSearchKeyFunc={this.onSearchKey}
                />
                <AddConpanyProvider
                    title={
                        <IntlMessages id='company-provider.add-new-modal-title' />
                    }
                    isOpen={show.add}
                    toggle={() => {
                        this.toggle('add');
                    }}
                    data_dynamic_form={data_dynamic_form}
                    values={form}
                    setting={show}
                    onChange={this.onChangeForm}
                    onChangeDynamicForm={this.onChangeDynamicForm}
                    onSubmit={(e) => {
                        this.onSubmitForm('add', e);
                    }}
                />
                <AddCompanyProviderLog
                    title={<IntlMessages id='company-provider.title-log' />}
                    isOpen={show.add_item}
                    toggle={() => {
                        this.toggleItem('add_item');
                    }}
                    values={form}
                    setting={show}
                    onChange={this.onChangeForm}
                    onChangePrice={this.onChangeFormPrice}
                    onSubmit={(e) => {
                        this.onSubmitForm('add-item', e);
                    }}
                />
                <AddCompanyProviderItemLog
                    title={
                        <IntlMessages id='company-provider.title-log-add-more' />
                    }
                    isOpen={show.add_item_log}
                    toggle={() => {
                        this.toggleItem('add_item_log');
                    }}
                    values={form}
                    setting={show}
                    onChange={this.onChangeForm}
                    onSubmit={() => {
                        this.onSubmitForm('add-item-log');
                    }}
                />
                <UpdateCompanyProvider
                    title={<IntlMessages id='company-provider.title' />}
                    onSubmit={this.onHandlerUpdate}
                    isOpen={show.update}
                    toggle={() => {
                        this.toggle('update');
                    }}
                    data_dynamic_form={data_dynamic_form}
                    data_tags={data_tags}
                    values={form}
                    setting={show}
                    onChange={this.onChangeForm}
                    onChangeDynamicForm={this.onChangeDynamicForm}
                />
                {data.length === 0 ? (
                    <Card className='mt-4 mb-4 mh-53vh'>
                        <CardBody>Chưa có dữ liệu...</CardBody>
                    </Card>
                ) : (
                    <>
                        <Nav
                            tabs
                            className='d-flex justify-content-between separator-tabs ml-0 mb-5'
                        >
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: this.state.activeTab === '1',
                                        'nav-link': true,
                                    })}
                                    onClick={() => this.toggleTab('1')}
                                    location={{}}
                                    to='#'
                                >
                                    <IntlMessages id='general.info' />
                                </NavLink>
                            </NavItem>
                            {/* <NavItem>
                        <NavLink
                            className={classnames({
                                active: this.state.activeTab === '2',
                                'nav-link': true,
                            })}
                            onClick={() => this.toggleTab('2')}
                            location={{}}
                            to='#'
                        >
                            <span className='mr-2'>
                                <i className='simple-icon-settings'></i>
                            </span>
                            <IntlMessages id='general.setting' />
                        </NavLink>
                    </NavItem> */}
                        </Nav>
                        <TabContent
                            activeTab={this.state.activeTab}
                            className='mh-53vh'
                        >
                            <TabPane tabId='1'>
                                <Row className='content-page__row d-none d-md-flex'>
                                    <Col
                                        xs='12'
                                        sm='12'
                                        md='12'
                                        lg={
                                            show.view &&
                                            displayMode === 'thumblist'
                                                ? 6
                                                : 12
                                        }
                                        xl={
                                            show.view &&
                                            displayMode === 'thumblist'
                                                ? 6
                                                : 12
                                        }
                                    >
                                        {data?.length ? (
                                            displayMode === 'thumblist' ? (
                                                <>
                                                    {data?.map((item, idx) => (
                                                        <ListItemLeftForm
                                                            // toggleFunc={() => {
                                                            //     this.toggleItem('add_item');
                                                            // }}
                                                            onClickedItem={
                                                                this
                                                                    .onClickedItem
                                                            }
                                                            onUpdate={
                                                                this
                                                                    .onClickedUpdate
                                                            }
                                                            onDelete={
                                                                this
                                                                    .onClickedDelete
                                                            }
                                                            onChangeCheckbox={
                                                                this
                                                                    .onClickedCheckbox
                                                            }
                                                            isDisabledUpdate={
                                                                !isU(ROLE_ALIAS)
                                                            }
                                                            isDisabledDelete={
                                                                !isD(ROLE_ALIAS)
                                                            }
                                                            selectedItems={
                                                                selectedItems
                                                            }
                                                            key={idx}
                                                            idx={idx}
                                                            item={item}
                                                        />
                                                    ))}
                                                    {/* {data?.length <
                                                    12 ? null : ( */}
                                                    <Pagination
                                                        currentPage={
                                                            page.currentPage
                                                        }
                                                        totalPage={
                                                            page.totalPage
                                                        }
                                                        onChangePage={(i) =>
                                                            this.onChangePage(i)
                                                        }
                                                    />
                                                    {/* )} */}
                                                </>
                                            ) : (
                                                <ContentMultiForm
                                                    data={data_all}
                                                    // onChange={this.onChangeForm}
                                                />
                                            )
                                        ) : (
                                            <Card className='d-flex justify-content-center align-items-center'>
                                                <CardBody>
                                                    Không có dữ liệu khách hàng
                                                    nào phù hợp với lựa chọn của
                                                    bạn.
                                                </CardBody>
                                            </Card>
                                        )}
                                    </Col>
                                    {show.view ? (
                                        <Col
                                            xs='12'
                                            sm='12'
                                            md='12'
                                            lg='6'
                                            xl='6'
                                        >
                                            <ContentDisplay
                                                // data_dynamic_form={data_dynamic_form}
                                                // data_tags={data_tags}
                                                values={form}
                                            />
                                            <ContentLog
                                                toggleFunc={() => {
                                                    this.toggleItem('add_item');
                                                }}
                                                items={form.log}
                                                toggleItemLogFunc={
                                                    this.toggleItemLog
                                                }
                                                isDisabledUpdate={
                                                    !isU(ROLE_ALIAS)
                                                }
                                            />
                                        </Col>
                                    ) : null}
                                </Row>
                                <Row className='content-page__row d-block d-md-none mb-4'>
                                    <Col>
                                        {data?.length ? (
                                            data?.map((item, idx) => {
                                                // console.log(item);
                                                return (
                                                    <div
                                                        className='d-flex mb-3 card'
                                                        key={item.id}
                                                    >
                                                        <ListItemFormMobile
                                                            onClickedItem={
                                                                this
                                                                    .onClickedItem
                                                            }
                                                            toggleFunc={() => {
                                                                this.toggleItem(
                                                                    'add_item'
                                                                );
                                                            }}
                                                            onUpdate={
                                                                this
                                                                    .onClickedUpdate
                                                            }
                                                            onDelete={
                                                                this
                                                                    .onClickedDelete
                                                            }
                                                            onChangeCheckbox={
                                                                this
                                                                    .onClickedCheckbox
                                                            }
                                                            isDisabledUpdate={
                                                                !isU(ROLE_ALIAS)
                                                            }
                                                            isDisabledDelete={
                                                                !isD(ROLE_ALIAS)
                                                            }
                                                            selectedItems={
                                                                selectedItems
                                                            }
                                                            key={idx}
                                                            idx={idx}
                                                            item={item}
                                                            form={form}
                                                            accordion={
                                                                accordion
                                                            }
                                                            toggleAccordion={
                                                                this
                                                                    .toggleAccordion
                                                            }
                                                            data_tags={
                                                                data_tags
                                                            }
                                                        />
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <Card className='d-flex justify-content-center align-items-center'>
                                                <CardBody>
                                                    Không có dữ liệu khách hàng
                                                    nào phù hợp với lựa chọn của
                                                    bạn.
                                                </CardBody>
                                            </Card>
                                        )}
                                        {/* {data?.length < 12 ? null : ( */}
                                        <Pagination
                                            currentPage={page.currentPage}
                                            totalPage={page.totalPage}
                                            onChangePage={(i) =>
                                                this.onChangePage(i)
                                            }
                                        />
                                        {/* )} */}
                                    </Col>
                                </Row>
                            </TabPane>
                            {/* <TabPane tabId='2'>Setting</TabPane> */}
                        </TabContent>
                    </>
                )}
                <CompanyProviderApplicationMenu
                    data={data_all}
                    filter={filter}
                    filterData={(key, value) => this.filterData(key, value)}
                />
                <Separator />
                <FooterPage>
                    <FooterCompanyProvider />
                </FooterPage>
            </div>
        );
    }
}

export default ContentComponent;
