import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';

class PasswordBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: 'password',
            wording: 'Hiện',
        };

        this.textInput = React.createRef();
        this.focus = this.focus.bind(this);
    }

    focus() {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        this.textInput.current.focus();
    }

    componentDidUpdate() {
        if (this.textInput && this.textInput.current) {
            this.focus();
        }
    }

    toggleModal = () => {
        this.props.toggleModal();
    };

    changeState = () => {
        var oldState = this.state.type;
        var isTextOrHide = oldState === 'password';
        var newState = isTextOrHide ? 'text' : 'password';
        var newWord = isTextOrHide ? `Ẩn` : 'Hiện';
        this.setState({
            type: newState,
            wording: newWord,
        });
    };

    handleOnChange = (e) => {
        if (!e) return false;
        if (!e.target) return false;

        this.props.onChange(e.target.value);
    };

    handleOnKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.props.onSubmit(this.props.callback);
        }
    };

    render() {
        let { isOpen } = this.props;

        return (
            <Modal
                isOpen={isOpen}
                toggle={this.toggleModal}
                className='disable-text-selection password-box'
                size='sm'
            >
                <ModalHeader toggle={this.toggleModal}>
                    <label className='pasword__label'>
                        <input
                            ref={this.textInput}
                            autoFocus={true}
                            type={this.state.type}
                            autoComplete='new-password'
                            onChange={this.handleOnChange}
                            onKeyPress={this.handleOnKeyPress}
                            defaultValue={this.props.password}
                        />
                        <span
                            className='password-trigger'
                            onClick={this.changeState}
                        >
                            {this.state.wording}
                        </span>
                    </label>
                </ModalHeader>
            </Modal>
        );
    }
}

export default PasswordBox;
