import React, { useState, useEffect } from 'react';
import { Row, Card, CardTitle, Form, Label, Input, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { loginUser } from '../../redux/actions';
import { Colxx } from '../../components/common/CustomBootstrap';
import IntlMessages from '../../helpers/IntlMessages';
import { NotificatioErrController } from '../../helpers/Utils';

const Login = (props) => {
    const [values, setValues] = useState({
        username: '',
        password: '',
        domain: window.location.host.split('.')[0],
    });

    useEffect(() => {
        let domain = window.location.host.split('.')[0];
        if (domain === 'demo') {
            setValues((v) => ({
                ...v,
                username: 'demo',
                password: 'demo',
            }));
        }
    }, []);

    const onUserLogin = () => {
        if (values.username !== '' && values.password !== '') {
            props.loginUser(values, props.history);
        } else {
            NotificatioErrController('Điền đầy đủ thông tin');
            return false;
        }
    };

    const onChangeHandleInput = (e) => {
        const { value, name } = e.target;
        setValues({ ...values, [name]: value });
    };

    const onChangeHandleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onUserLogin();
        }
    };

    useEffect(() => {
        if (props.user && props.user.status && props.user.status !== 200) {
            NotificatioErrController(props.user.data.responseText);
        }
    }, [props.user]);

    return (
        <Row className='h-100'>
            <Colxx xxs='12' md='10' className='mx-auto my-auto'>
                <Card className='auth-card' autoFocus={false}>
                    <div className='position-relative image-side '>
                        <p className='text-white h2'>ĐĂNG NHẬP</p>
                        <p className='white mb-0'>
                            Đăng nhập hệ thống.
                            <br />
                        </p>
                    </div>
                    <div className='form-side'>
                        {/* <NavLink to={`/`} className="white">
              <span className="logo-single" />
            </NavLink> */}
                        <a href='https://vmass.vn/' className='white'>
                            <span className='logo-single' />
                        </a>
                        <CardTitle className='mb-4'>
                            <IntlMessages id='user.login-title' />
                        </CardTitle>
                        <Form>
                            <Label className='form-group has-float-label mb-4'>
                                <Input
                                    autoFocus={true}
                                    type='text'
                                    onChange={onChangeHandleInput}
                                    onKeyPress={onChangeHandleKeyPress}
                                    name='username'
                                    defaultValue={values.username}
                                />
                                <IntlMessages id='user.username' />
                            </Label>
                            <Label className='form-group has-float-label mb-4'>
                                <Input
                                    type='password'
                                    name='password'
                                    onChange={onChangeHandleInput}
                                    onKeyPress={onChangeHandleKeyPress}
                                    defaultValue={values.password}
                                />
                                <IntlMessages id='user.password' />
                            </Label>
                            <div className='d-flex justify-content-between align-items-center'>
                                <NavLink to={`/user/forgot-password`}>
                                    <IntlMessages id='user.forgot-password-question' />
                                </NavLink>
                                <Button
                                    color='primary'
                                    className='btn-shadow'
                                    size='lg'
                                    onClick={onUserLogin}
                                >
                                    <IntlMessages id='user.login-button' />
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Card>
            </Colxx>
        </Row>
    );
};
const mapStateToProps = ({ authUser }) => {
    const { user, loading } = authUser;
    return { user, loading };
};

export default connect(mapStateToProps, {
    loginUser,
})(Login);
