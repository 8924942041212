import React from 'react';
import { Badge, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentDisplayMobile = (props) => {
    // console.log(props);
    const findAvaragePrice = props?.data?.find(
        (d) => d.id === props?.values.id
    );
    return (
        <Row className='d-flex align-items-center mt-4'>
            <Colxx xxs='6' lg='6'>
                <p className='text-muted text-small'>
                    <IntlMessages id='stock.name' />
                </p>
            </Colxx>
            <Colxx xxs='6' lg='6' className='text-right'>
                <p className='text-capitalize'>
                    {props.values.name ? (
                        props.values.name
                    ) : (
                        <span className='text-lowercase'>
                            (Chưa có thông tin)
                        </span>
                    )}
                </p>
            </Colxx>
            <Colxx xxs='6' lg='6'>
                <p className='text-muted text-small'>Mã SKU</p>
            </Colxx>
            <Colxx xxs='6' lg='6' className='text-right'>
                <p className='text-capitalize'>{props.sku}</p>
            </Colxx>
            <Colxx xxs='6' lg='6'>
                <p className='text-muted text-small'>
                    <IntlMessages id='stock.unit' />
                </p>
            </Colxx>
            <Colxx xxs='6' lg='6' className='text-right'>
                <p>
                    {props.values.unit ? (
                        props.values.unit
                    ) : (
                        <span className='text-lowercase'>
                            (Chưa có thông tin)
                        </span>
                    )}
                </p>
            </Colxx>
            {/* <Colxx xxs='12' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='stock.unit-price' />
                    </p>
                    <p className='text-lowercase'>
                        {AddCommaNumber(props.values.unit_price)}
                    </p>
                </Colxx>
                <Colxx xxs='12' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='stock.count' />
                    </p>
                    <p className='text-capitalize'>
                        {props.values.new_count ? (
                            props.values.new_count
                        ) : (
                            <span className='text-lowercase'>
                                (Chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx> */}
            <Colxx xxs='6' lg='6'>
                <p className='text-muted text-small'>
                    <IntlMessages id='stock.count-total' />
                </p>
            </Colxx>
            <Colxx xxs='6' lg='6' className='text-right'>
                <p className='text-lowercase'>
                    {props.values.total + ' ' + props.values.unit}
                </p>
            </Colxx>
            <Colxx xxs='6' lg='6'>
                <p className='text-muted text-small'>
                    <IntlMessages id='stock.count-current' />
                </p>
            </Colxx>
            <Colxx xxs='6' lg='6' className='text-right'>
                <p className='text-lowercase'>
                    {props.values.count.toFixed(2) + ' ' + props.values.unit}
                </p>
            </Colxx>
            <Colxx xxs='6' lg='6'>
                <p className='text-muted text-small'>Giá mua trung bình</p>
            </Colxx>
            <Colxx xxs='6' lg='6' className='text-right'>
                <p className='text-lowercase'>
                    <Badge color='primary' pill>
                        {AddCommaNumber(findAvaragePrice?.avarage_price)} đ
                    </Badge>
                </p>
            </Colxx>
            <Colxx xxs='6' lg='6'>
                <p className='text-muted text-small'>Giá trị tồn kho</p>
            </Colxx>
            <Colxx xxs='6' lg='6' className='text-right'>
                <p className='text-lowercase'>
                    <Badge color='primary' pill>
                        {AddCommaNumber(
                            (
                                parseFloat(
                                    findAvaragePrice?.avarage_price
                                        .toString()
                                        .replace(/\./g, '')
                                ) * parseFloat(findAvaragePrice?.count)
                            ).toFixed(0)
                        )}{' '}
                        đ
                    </Badge>
                </p>
            </Colxx>
            <Colxx xxs='6' lg='6'>
                <p className='text-muted text-small'>Còn dưới 20%</p>
            </Colxx>
            <Colxx xxs='6' lg='6' className='text-right'>
                <p>{props.values.is_alert_down_20 ? 'Đúng' : 'Sai'}</p>
            </Colxx>
            <Colxx xxs='6' lg='6'>
                <p className='text-muted text-small'>Còn dưới 10%</p>
            </Colxx>
            <Colxx xxs='6' lg='6' className='text-right'>
                <p>{props.values.is_alert_down_10 ? 'Đúng' : 'Sai'}</p>
            </Colxx>
        </Row>
    );
};

export default ContentDisplayMobile;
