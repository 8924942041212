import React, { Component } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { END_POINT_SOCKET } from '../../constants/defaultValues';
import io from 'socket.io-client';
import classnames from 'classnames';
import { withRouter, Link } from 'react-router-dom';

const NotificationItem = ({
    handleAlertUpdateSeen,
    toggleDropdown,
    id,
    isSeen,
    type,
    title,
    date,
    time,
    location,
    user_id,
    item_id,
}) => {
    // const jump = (e) => {
    //     let message = document.getElementById('messages');
    //     message.scrollIntoView();
    // };
    return (
        <div
            className='d-flex flex-row mb-3 pb-3 border-bottom'
            onClick={(e) => {
                toggleDropdown();
                handleAlertUpdateSeen(id);
            }}
        >
            <div
                className={classnames('pl-3 pr-2', {
                    unseen: !isSeen,
                })}
            >
                {type === 'todo' ? (
                    <a href='/app/pages/todo'>
                        <p
                            className={classnames('mb-1', {
                                'font-weight-medium': isSeen,
                            })}
                        >
                            {title}
                        </p>
                        <p
                            className={classnames('mb-0 text-small', {
                                'text-muted': isSeen,
                            })}
                        >
                            {date + ' - ' + time}
                        </p>
                    </a>
                ) : type === 'message' ? (
                    location.pathname === '/app/dashboards/default' ? (
                        <Link
                            to={{
                                // pathname: '/app/dashboards/default',
                                pathname: '/app/pages/message',
                                aboutProps: {
                                    message_user_id: user_id,
                                    message_item_id: item_id,
                                },
                            }}
                        >
                            {/* <div onClick={jump}> */}
                            <p
                                className={classnames('mb-1', {
                                    'font-weight-medium': isSeen,
                                })}
                            >
                                {title}
                            </p>
                            <p
                                className={classnames('mb-0 text-small', {
                                    'text-muted': isSeen,
                                })}
                            >
                                {date + ' - ' + time}
                            </p>
                            {/* </div> */}
                        </Link>
                    ) : (
                        <Link
                            to={{
                                pathname: '/app/pages/message',
                                aboutProps: {
                                    message_user_id: user_id,
                                    message_item_id: item_id,
                                },
                            }}
                        >
                            <p
                                className={classnames('mb-1', {
                                    'font-weight-medium': isSeen,
                                })}
                            >
                                {title}
                            </p>
                            <p
                                className={classnames('mb-0 text-small', {
                                    'text-muted': isSeen,
                                })}
                            >
                                {date + ' - ' + time}
                            </p>
                        </Link>
                    )
                ) : (
                    // stock
                    <a href={`/app/pages/stock?item_id=${item_id}`}>
                        <p
                            className={classnames('mb-1', {
                                'font-weight-medium': isSeen,
                            })}
                        >
                            {title}
                        </p>
                        <p
                            className={classnames('mb-0 text-small', {
                                'text-muted': isSeen,
                            })}
                        >
                            {date + ' - ' + time}
                        </p>
                    </a>
                )}
            </div>
        </div>
    );
};

class TopnavNotifications extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            alert_data: [],
            un_seen_len: 0,
            dropdownOpen: false,
        };

        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });
        this.socket.emit('join room', {
            room_id: localStorage.getItem('b'),
        });

        this.socket.emit('refresh-alert-data', {
            user_id: localStorage.getItem('b'),
        });

        this.socket.on('refresh-alert-data', (res) => {
            const { data, un_seen_len } = res;

            this.setState({
                isLoading: true,
                alert_data: data,
                un_seen_len: un_seen_len,
            });
        });
    }

    handleAlertUpdateSeen = (uuid) => {
        this.socket.emit('update-seen-alert-data', {
            // user_id: localStorage.getItem('user_id'),
            user_id: localStorage.getItem('b'),
            uuid,
        });
    };

    toggleDropdown = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    };

    render() {
        let { alert_data, un_seen_len, isLoading, dropdownOpen } = this.state;

        return !isLoading ? (
            <div className='loading' />
        ) : (
            <div className='position-relative d-inline-block'>
                <UncontrolledDropdown
                    className='dropdown-menu-right'
                    isOpen={dropdownOpen}
                    toggle={this.toggleDropdown}
                >
                    <DropdownToggle
                        className='header-icon notificationButton'
                        color='empty'
                    >
                        <i className='iconsminds-bell' />
                        <span className='count'>{un_seen_len}</span>
                    </DropdownToggle>
                    <DropdownMenu
                        className='position-absolute mt-3 scroll'
                        right
                        id='notificationDropdown'
                    >
                        <PerfectScrollbar
                            options={{
                                suppressScrollX: true,
                                wheelPropagation: false,
                            }}
                        >
                            {alert_data.map((notification, index) => {
                                return (
                                    <NotificationItem
                                        location={this.props.location}
                                        key={index}
                                        {...notification}
                                        handleAlertUpdateSeen={
                                            this.handleAlertUpdateSeen
                                        }
                                        toggleDropdown={this.toggleDropdown}
                                    />
                                );
                            })}
                        </PerfectScrollbar>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        );
    }
}

export default withRouter(TopnavNotifications);
