import axios from 'axios'
import { END_POINT } from '../constants/defaultValues'

export const HandlerGetRequest = ({
    path,
}) => {
    let tokenStr = localStorage.getItem('access_token');

    return axios
        .get(
            `${END_POINT}${path}`,
            { headers: { "Authorization": `Bearer ${tokenStr}` } }
        )
        .then(res => res.data)
        .then(data => data)
}

export const HandlerPostRequest = ({
    path, body,
}) => {
    let tokenStr = localStorage.getItem('access_token');

    return axios
        .post(
            `${END_POINT}${path}`,
            body,
            { headers: { "Authorization": `Bearer ${tokenStr}` } }
        )
        .then(res => res.data)
        .then(data => data)
}

export const HandlerPutRequest = ({
    path, body,
}) => {
    let tokenStr = localStorage.getItem('access_token');

    return axios
        .put(
            `${END_POINT}${path}`,
            body,
            { headers: { "Authorization": `Bearer ${tokenStr}` } }
        )
        .then(res => res.data)
        .then(data => data)
}
