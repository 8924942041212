import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavItem, Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classnames from 'classnames';

import IntlMessages from '../../../helpers/IntlMessages';
import ApplicationMenu from '../../../components/common/ApplicationMenu';

class CompanyProviderApplicationMenu extends Component {
    render() {
        const { data, filter, filterData } = this.props;
        // console.log(data);
        const ItemProduct = data?.map((item) =>
            item.company_provider_item?.map((i) => i.name)
        );
        const merceItemProduct = [].concat.apply([], ItemProduct);
        const UniqueItemProduct = [...new Set(merceItemProduct)];
        // console.log(UniqueItemProduct);

        return (
            <ApplicationMenu>
                <PerfectScrollbar
                    options={{ suppressScrollX: true, wheelPropagation: false }}
                >
                    <div className='p-4'>
                        <NavItem
                            className={classnames({ active: !filter }, 'mb-5')}
                        >
                            <NavLink
                                to='#'
                                onClick={(e) => filterData('', '')}
                                location={{}}
                                className='d-flex justify-content-between align-items-center'
                            >
                                <div className='d-flex align-items-center'>
                                    <i className='simple-icon-layers mr-10' />
                                    <span>Tất cả</span>
                                </div>
                                <span className='float-right'>
                                    {data?.length}
                                </span>
                            </NavLink>
                        </NavItem>
                        <p className='text-muted text-small'>
                            Trạng thái giao dịch
                        </p>
                        <ul className='list-unstyled mb-5'>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column ===
                                            'outcome-equal-income' &&
                                        filter.value === '0',
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) =>
                                        filterData('outcome-equal-income', '0')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>Đã hoàn thành</span>
                                    </div>
                                    <span className='float-right'>
                                        {
                                            data?.filter((x) => {
                                                return (
                                                    x.total_price_income !==
                                                        '0' &&
                                                    x.total_price_outcome ===
                                                        x.total_price_income
                                                );
                                            }).length
                                        }
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column ===
                                            'outcome-lessthan-income' &&
                                        filter.value === '0',
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) =>
                                        filterData(
                                            'outcome-lessthan-income',
                                            '0'
                                        )
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Chưa hoàn thành</span>
                                    </div>
                                    <span className='float-right'>
                                        {
                                            data?.filter((d) => {
                                                return (
                                                    d.total_price_income >
                                                    d.total_price_outcome
                                                );
                                            }).length
                                        }
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column ===
                                            'total_price_income' &&
                                        filter.value === '0',
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) =>
                                        filterData('total_price_income', '0')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Chưa có giao dịch</span>
                                    </div>
                                    <span className='float-right'>
                                        {
                                            data?.filter(
                                                (x) =>
                                                    x.total_price_income === '0'
                                            ).length
                                        }
                                    </span>
                                </NavLink>
                            </NavItem>
                        </ul>
                        <p className='text-muted text-small'>
                            Mặt hàng
                            {/* <IntlMessages id='todo.categories' /> */}
                        </p>
                        <ul className='list-unstyled mb-5'>
                            {UniqueItemProduct?.map((c, index) => {
                                return (
                                    <NavItem key={index}>
                                        <div
                                            onClick={(e) =>
                                                filterData('item_product', c)
                                            }
                                        >
                                            <div className='custom-control custom-radio'>
                                                <input
                                                    type='radio'
                                                    className='custom-control-input'
                                                    defaultChecked={
                                                        filter &&
                                                        filter.column ===
                                                            'item_product' &&
                                                        filter.value === c
                                                    }
                                                />
                                                <label className='custom-control-label'>
                                                    {c}
                                                </label>
                                            </div>
                                        </div>
                                    </NavItem>
                                );
                            })}
                        </ul>
                    </div>
                </PerfectScrollbar>
            </ApplicationMenu>
        );
    }
}

export default CompanyProviderApplicationMenu;
