import React from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    InputGroup,
} from 'reactstrap';
import IntlMessages from '../../helpers/IntlMessages';
import { Colxx, Separator } from '../../components/common/CustomBootstrap';
import { AddCommaNumber } from '../../helpers/Utils';

const ContentComponent = ({
    isOpen,
    onToggle,
    onSubmit,
    onChange,
    values_discount,
    values_tax,
    values_fee,
}) => {
    return (
        <Modal
            autoFocus={false}
            isOpen={isOpen}
            toggle={onToggle}
            wrapClassName='modal-right'
            backdrop={true}
        >
            <ModalHeader toggle={onToggle}>
                Thêm chiết khấu, thuế hoặc phí khác
            </ModalHeader>
            <ModalBody>
                <Label className='mt-4 text-muted'>Chiết khấu</Label>
                <InputGroup>
                    <Colxx xxs='6' className='p-0'>
                        <Input
                            autoFocus={true}
                            type='number'
                            name='value'
                            id='value'
                            className='h-100 border-radius-topbot-left'
                            value={AddCommaNumber(values_discount.value)}
                            onChange={(e) => onChange(e, 'discount_form')}
                        />
                    </Colxx>
                    <Colxx xxs='3' className='p-0'>
                        <Button
                            name='type'
                            className='w-100 border-radius-0 h-100'
                            color='primary'
                            outline
                            size='sm'
                            onClick={(e) =>
                                onChange(e, 'discount_form', 'percent')
                            }
                            active={values_discount.type === 'percent'}
                        >
                            %
                        </Button>
                    </Colxx>
                    <Colxx xxs='3' className='p-0'>
                        <Button
                            name='type'
                            className='w-100 border-radius-topbot-right h-100'
                            color='primary'
                            outline
                            size='sm'
                            onClick={(e) =>
                                onChange(e, 'discount_form', 'price')
                            }
                            active={values_discount.type === 'price'}
                        >
                            VNĐ
                        </Button>
                    </Colxx>
                </InputGroup>
                <Input
                    className='mt-3'
                    value={values_discount.note}
                    onChange={(e) => onChange(e, 'discount_form')}
                    name='note'
                    placeholder='Ghi chú'
                />
                <Separator className='mt-4 mb-4' />
                <Label className='text-muted'>Thuế</Label>

                <InputGroup>
                    <Colxx xxs='6' className='p-0'>
                        <Input
                            type='number'
                            name='value'
                            id='value'
                            className='h-100 border-radius-topbot-left'
                            value={AddCommaNumber(values_tax?.value)}
                            onChange={(e) => onChange(e, 'tax_fee')}
                        />
                    </Colxx>
                    <Colxx xxs='3' className='p-0'>
                        <Button
                            name='type'
                            className='w-100 border-radius-0 h-100'
                            color='primary'
                            outline
                            size='sm'
                            onClick={(e) => onChange(e, 'tax_fee', 'percent')}
                            active={values_tax?.type === 'percent'}
                        >
                            %
                        </Button>
                    </Colxx>
                    <Colxx xxs='3' className='p-0'>
                        <Button
                            name='type'
                            className='w-100 border-radius-topbot-right h-100'
                            color='primary'
                            outline
                            size='sm'
                            onClick={(e) => onChange(e, 'tax_fee', 'price')}
                            active={values_tax?.type === 'price'}
                        >
                            VNĐ
                        </Button>
                    </Colxx>
                </InputGroup>
                <Input
                    className='mt-3'
                    value={values_tax?.note}
                    onChange={(e) => onChange(e, 'tax_fee')}
                    name='note'
                    placeholder='Ghi chú'
                />
                <Separator className='mt-4 mb-4' />
                <Label className='text-muted'>Phụ phí</Label>

                <InputGroup>
                    <Colxx xxs='6' className='p-0'>
                        <Input
                            type='number'
                            name='value'
                            id='value'
                            className='h-100 border-radius-topbot-left'
                            value={AddCommaNumber(values_fee?.value)}
                            onChange={(e) => onChange(e, 'fee_other')}
                        />
                    </Colxx>
                    <Colxx xxs='3' className='p-0'>
                        <Button
                            name='type'
                            className='w-100 border-radius-0 h-100'
                            color='primary'
                            outline
                            size='sm'
                            onClick={(e) => onChange(e, 'fee_other', 'percent')}
                            active={values_fee?.type === 'percent'}
                        >
                            %
                        </Button>
                    </Colxx>
                    <Colxx xxs='3' className='p-0'>
                        <Button
                            name='type'
                            className='w-100 border-radius-topbot-right h-100'
                            color='primary'
                            outline
                            size='sm'
                            onClick={(e) => onChange(e, 'fee_other', 'price')}
                            active={values_fee?.type === 'price'}
                        >
                            VNĐ
                        </Button>
                    </Colxx>
                </InputGroup>
                <Input
                    className='mt-3'
                    value={values_fee?.note}
                    onChange={(e) => onChange(e, 'fee_other')}
                    name='note'
                    placeholder='Ghi chú'
                />
            </ModalBody>
            <ModalFooter>
                <Button color='primary' onClick={onSubmit}>
                    <IntlMessages id='general.btn-add' />
                </Button>{' '}
            </ModalFooter>
        </Modal>
    );
};

export default ContentComponent;
