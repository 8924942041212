import React, { Component, useRef, useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, CardFooter, Row, Button } from 'reactstrap';
import { connect } from 'react-redux';
import {
    setContainerClassnames,
    clickOnMobileMenu,
} from '../../../redux/actions';
import { Colxx } from '../../../components/common/CustomBootstrap';
import { END_POINT_QRCODE } from '../../../constants/url';
import axios from 'axios';
import classnames from 'classnames';
import QRCodeStyling from 'qr-code-styling';

class LabelItemLeft extends Component {
    constructor(props) {
        super(props);
    }

    // componentDidMount() {
    //     qrCode.append(this.props.ref.current);
    //   }

    menuButtonClick = (e, menuClickCount, containerClassnames) => {
        setTimeout(() => {
            var event = document.createEvent('HTMLEvents');
            event.initEvent('resize', false, false);
            window.dispatchEvent(event);
        }, 350);
        this.props.setContainerClassnames(
            menuClickCount,
            containerClassnames,
            this.props.selectedMenuHasSubItems
        );
    };

    onClickedItem = (floor) => {
        this.props.onClickedItem(
            // callback
            floor,
            this.menuButtonClick,
            [null, 1000, this.props.containerClassnames]
        );
    };

    render() {
        const { data, isDisableDownload } = this.props;

        return (
            data &&
            data.map((floor, floor_idx) => (
                <Row
                    onClick={() => {
                        this.onClickedItem(floor);
                    }}
                    key={floor_idx}
                    className='mb-4'
                >
                    <Colxx xxs='12'>
                        {/* {
                            <Button
                                className='mb-4'
                                color='primary'
                                onClick={() =>
                                    generateQR(
                                        'thanhpq7.localhost:8080/menu/zacij3n60ebpvura69ncznq9cvhth45a0rzm-1'
                                    )
                                }
                            >
                                Tạo mã qrCode
                            </Button>
                        }
                        <div>
                          <img id="qrcode" alt="QR Code" />
                        </div> */}
                        <Card>
                            <CardBody>
                                <div
                                    onClick={() =>
                                        this.props.exportFloor(
                                            floor.floor,
                                            floor.data.map(
                                                (_hash) =>
                                                    _hash.u +
                                                    '-' +
                                                    _hash.identify
                                            )
                                        )
                                    }
                                    className={classnames(
                                        'position-absolute card-top-buttons custom-inline',
                                        {
                                            disable: isDisableDownload,
                                        }
                                    )}
                                >
                                    <Button size='sm' color='primary'>
                                        Tải tất cả
                                    </Button>
                                </div>
                                <CardTitle>{`Khu ${floor.floor}`}</CardTitle>
                                <Row>
                                    {floor.data.map((table, table_idx) => (
                                        <Colxx
                                            key={table_idx}
                                            xxs='12'
                                            xs='6'
                                            md='4'
                                            lg='3'
                                        >
                                            <ItemRender
                                                floor={floor}
                                                exportFloor={
                                                    this.props.exportFloor
                                                }
                                                u={table.u}
                                                identify={table.identify}
                                            />
                                        </Colxx>
                                    ))}
                                </Row>
                            </CardBody>
                        </Card>
                    </Colxx>
                </Row>
            ))
        );
    }
}

const ItemRender = ({ identify, u, exportFloor, floor }) => {
    const ref = useRef(null);
    useEffect(() => {
        qrCode.append(ref.current);
    }, []);

    const text = `${window.location.protocol}//${
        window.location.host + '/menu/' + u
    }`;
    const qrCode = new QRCodeStyling({
        data: text,
        width: 250,
        height: 250,
        image: '',
        qrOptions: {
            typeNumber: '8',
            mode: 'Byte',
            errorCorrectionLevel: 'Q',
        },
        dotsOptions: {
            color: '#f00',
            type: 'dots',
        },
        imageOptions: {
            crossOrigin: 'anonymous',
            margin: 20,
        },
        cornersSquareOptions: {
            type: '',
            color: '#ff0000',
            gradient: {
                type: 'linear',
                rotation: 0,
                colorStops: [
                    { offset: 0, color: '#ff2424' },
                    { offset: 1, color: '#9b0303' },
                ],
            },
        },
    });

    const onDownloadClick = () => {
        qrCode.download({
            extension: 'png',
        });
    };

    return (
        <Card className='mb-4'>
            <div className='qrcode-wrapper mt-3 mb-2' ref={ref}>
                {/* <img
                    src={`${END_POINT_QRCODE}/qrcode?text=${identify}&data=${
                        window.location.protocol
                    }//${window.location.host + '/menu/' + u}`}
                /> */}
                {/* <h6 className='text-center'>Quét mã để gọi món!</h6> */}
            </div>
            <CardFooter>
                <span>{`Bàn ${identify}`}</span>
                <div className='position-absolute card-bottom-buttons custom-inline'>
                    <span
                        className='cursor-pointer'
                        // onClick={() =>
                        //     exportFloor(floor.floor, [u + '-' + identify])
                        // }
                        onClick={onDownloadClick}
                    >
                        <i className='simple-icon-cloud-download' />
                    </span>
                </div>
            </CardFooter>
        </Card>
    );
};

const mapStateToProps = ({ menu }) => {
    const { containerClassnames, menuClickCount, selectedMenuHasSubItems } =
        menu;
    return {
        containerClassnames,
        menuClickCount,
        selectedMenuHasSubItems,
    };
};
export default connect(mapStateToProps, {
    setContainerClassnames,
    clickOnMobileMenu,
})(LabelItemLeft);
