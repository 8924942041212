import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card, CardBody, CardTitle } from 'reactstrap';

import IntlMessages from '../../helpers/IntlMessages';
import { isRorRBD } from '../../helpers/Roles';
import classnames from 'classnames';

const Logs = (props) => {
    return (
        <Card
            className={classnames('dashboard-list-with-thumbs-lg', {
                disable: !isRorRBD('analytics_logs'),
            })}
        >
            <CardBody>
                <CardTitle>
                    <IntlMessages id='dashboards.logs' />
                </CardTitle>
                <div className='scroll dashboard-list-stock'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: true,
                        }}
                    >
                        <table className='table table-sm table-borderless'>
                            <tbody>
                                {props.data.map((log, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <span
                                                    className={`log-indicator align-middle ${
                                                        idx % 2 === 0
                                                            ? 'border-theme-1'
                                                            : idx % 3 === 0
                                                            ? 'border-danger'
                                                            : 'border-theme-2'
                                                    }`}
                                                />
                                            </td>
                                            <td>
                                                <span className='font-weight-medium'>
                                                    {log.message}
                                                </span>
                                            </td>
                                            <td className='text-right'>
                                                <span className='text-muted'>{`${log.date}  ${log.time}`}</span>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </PerfectScrollbar>
                </div>
            </CardBody>
        </Card>
    );
};
export default Logs;
