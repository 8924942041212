import React, { useState } from 'react';
import { Input, Label, Form, FormGroup } from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import Select from 'react-select';
import CustomSelectInput from '../../../components/common/CustomSelectInput';
import { GetSelectTime, SetSelectTime } from '../../../helpers/SelectTime';
import { AddCommaNumber } from '../../../helpers/Utils';
import DynamicForm from '../general/DynamicForm';
import TooltipItem from '../../../components/common/TooltipItem';

const ContentComponent = (props) => {
    const [timeTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Nếu chọn "Đưa vào thống kê" thì số liệu này sẽ được tính như một khoản chi trong ngày. Nếu chọn "Không đưa vào thống kê" thì chỉ xem như một con số tượng trưng.',
    });
    return (
        <Form>
            <FormGroup>
                <Label for='name'>
                    <IntlMessages id='income.name' />
                </Label>
                <Input
                    type='text'
                    name='name'
                    id='name'
                    disabled={props.setting.viewonly && !props.setting.add}
                    value={props.values.name}
                    onChange={props.onChange}
                />
            </FormGroup>
            <FormGroup>
                <Label for='address'>
                    <IntlMessages id='address' />
                </Label>
                <Input
                    type='text'
                    name='address'
                    id='address'
                    disabled={props.setting.viewonly && !props.setting.add}
                    value={props.values.address}
                    onChange={props.onChange}
                />
            </FormGroup>
            <FormGroup>
                <Label for='value'>
                    <IntlMessages id='price' />
                </Label>
                <Input
                    type='text'
                    name='value'
                    id='value'
                    disabled={props.setting.viewonly && !props.setting.add}
                    value={AddCommaNumber(props.values.value)}
                    onChange={props.onChange}
                />
            </FormGroup>
            <FormGroup>
                <Label for='note'>
                    <IntlMessages id='note' />
                </Label>
                <Input
                    type='text'
                    name='note'
                    id='note'
                    disabled={props.setting.viewonly && !props.setting.add}
                    value={props.values.note}
                    onChange={props.onChange}
                />
            </FormGroup>
            <FormGroup>
                <Label for='time'>
                    <IntlMessages id='time' />
                    <TooltipItem item={timeTooltip} id={7} />
                </Label>
                <Select
                    components={{ Input: CustomSelectInput }}
                    className='react-select'
                    classNamePrefix='react-select'
                    name='time'
                    value={GetSelectTime(props.values.time)}
                    isDisabled={props.setting.viewonly && !props.setting.add}
                    placeholder={<IntlMessages id='select-one-time' />}
                    options={SetSelectTime()}
                    onChange={(e) => {
                        props.onChange(e, 'time');
                    }}
                />
            </FormGroup>
            {/* {props.setting.update ? (
        <FormGroup>
          <Label for="user.created">
            <IntlMessages id="user.created" />
          </Label>
          <Input type="text" name="user.created" id="user.created"
            disabled={true}
            value={props.values.user}
            onChange={props.onChange}
          />
        </FormGroup>
      ) : null} */}

            <DynamicForm
                values={props.values.dynamic_form}
                data={props.data_dynamic_form}
                onChange={props.onChangeDynamicForm}
                disabled={props.setting.viewonly && !props.setting.add}
            />
        </Form>
    );
};

export default ContentComponent;
