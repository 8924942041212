export const SetType = () => {
  return [
    {
      label: "Text",
      value: "text"
    },
    {
      label: "Number",
      value: "number"
    },
    {
      label: "Date",
      value: "date"
    },
    {
      label: "Textarea",
      value: "textarea"
    },
    {
      label: "URL",
      value: "url"
    },
    {
      label: "Boolean",
      value: "boolean"
    },
    {
      label: "Phone",
      value: "phone"
    },
  ]
}

export const GetType = (type) => {
  if (type === 'text') {
    return {
      label: "Text",
      value: "text"
    }
  }
  if (type === 'number') {
    return {
      label: "Number",
      value: "number"
    }
  }
  if (type === 'date') {
    return {
      label: "Date",
      value: "date"
    }
  }
  if (type === 'textarea') {
    return {
      label: "Textarea",
      value: "textarea"
    }
  }
  if (type === 'url') {
    return {
      label: "URL",
      value: "url"
    }
  }
  if (type === 'boolean') {
    return {
      label: "Boolean",
      value: "boolean"
    }
  }
  if (type === 'phone') {
    return {
      label: "Phone",
      value: "phone"
    }
  }

  return {
    label: "Text",
    value: "text"
  }
}


export const GetBooleanType = (type) => {
  if (type === 'true') {
    return {
      label: "True",
      value: "true"
    }
  }
  if (type === 'false') {
    return {
      label: "False",
      value: "false"
    }
  }
}