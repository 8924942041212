import React, { useRef } from 'react';
import {
    Card,
    Badge,
    CustomInput,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    CardBody,
    Button,
} from 'reactstrap';
import { END_POINT } from '../../constants/defaultValues';
import classnames from 'classnames';
import IntlMessages from '../../helpers/IntlMessages';
import STATUS from '../../constants/status';
import {
    filterOrdersById,
    isAllOrderCompleted,
    isIncludeOrderCompleted,
    isIncludeOrderCreated,
    isIncludeOrderPending,
} from './helpers/utils';
import ReactToPrint from 'react-to-print';
import ComponentToPrint from '../pages/ComponentToPrint';
import ComponentToPrintForKitchen from '../pages/ComponentToPrintForKitchen';
import { AddCommaNumber } from '../../helpers/Utils';

/* alias
111: ĐANG CHỜ NHẬN
222: ĐÃ NHẬN, CHỜ GIAO,
444: ĐÃ GIAO, CHỜ XUẤT HÓA ĐƠN 
*/

const ListPendingOrdersStatus = ({
    orders,
    selectedItems,
    onCheckItem,
    onActions,
    isMobile,
    alias,
    toggleModalInvoice,
    emptyText,
    itemsPendingBill,
    companyName,
    companyAddress,
    companyLogo,
    companyWebsite,
    billDate,
    pin_print,
    handleClickedPrintInvoice,
    switchCheckedBillQr,
    toggleModalNested,
    switchCheckedOrderTwo,
}) => {
    // console.log('selectedItems: ', selectedItems);
    const items = selectedItems.map((itemId) => {
        const _order = orders.find((o) => o.id === itemId);
        return _order ? _order : null;
    });

    // Tạo một đối tượng để nhóm các orders theo user_identify
    const groupedOrders = {};
    for (const order of orders) {
        const userIdentify = order.user_identify;
        if (!groupedOrders[userIdentify]) {
            groupedOrders[userIdentify] = [];
        }
        groupedOrders[userIdentify].push(order);
    }

    // Chuyển đổi đối tượng nhóm thành mảng các nhóm
    const orderGroups = Object.values(groupedOrders);
    // console.log('orderGroups: ', orderGroups);
    const flattenedArray = orderGroups?.flat();
    // console.log('flattenedArray: ', flattenedArray);

    // Sử dụng reduce để tính tổng giá trị price khi id trùng với selectedItems
    const priceSelected = flattenedArray?.reduce((sum, item) => {
        if (selectedItems.includes(item.id)) {
            return sum + Number(item.price);
        }
        return sum;
    }, 0);

    // Hiển thị tổng giá trị
    // console.log('priceSelected: ', priceSelected);

    const totalPrice = flattenedArray?.reduce(
        (a, b) => Number(a) + Number(b.price),
        0
    );

    const OrdersAtBar = ({ array }) => {
        // Tạo một đối tượng map để lưu trữ các object theo 'time'
        const groupedByTime = new Map();

        // Duyệt qua mảng dữ liệu và gom chúng theo 'time'
        array.forEach((item) => {
            const time = item.time;
            if (groupedByTime.has(time)) {
                groupedByTime.get(time).push(item);
            } else {
                groupedByTime.set(time, [item]);
            }
        });

        // Chuyển các giá trị từ map sang mảng kết quả
        const resultArray = [...groupedByTime.values()];

        // console.log(resultArray);
        return resultArray.map((result, idx) => {
            return (
                <Card key={idx} className='mb-3'>
                    <p className='p-2 mb-0 ml-4'>Tại quầy</p>
                    {result.map((order) => (
                        <OrderCard
                            key={order.id}
                            order={order}
                            selectedItems={selectedItems}
                            onCheckItem={onCheckItem}
                        />
                    ))}
                </Card>
            );
        });
    };

    // Hiển thị các nhóm bằng cách tạo các <Card /> tương ứng
    const OrderCard = ({ order, selectedItems, onCheckItem }) => {
        const toppings = order.topping.split('|');
        return (
            <div
                onClick={(event) => onCheckItem(event, order.id)}
                className={classnames('d-flex flex-row', {
                    active: selectedItems.includes(order.id),
                })}
            >
                <div className='d-block position-relative'>
                    <img
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = 'http://via.placeholder.com/320x240';
                        }}
                        src={
                            order.product.image.indexOf('http') >= 0
                                ? order.product.image
                                : END_POINT + '/public/' + order.product.image
                        }
                        alt={order.product.name}
                        className='list-thumbnail border-0 m-2'
                    />
                    <Badge
                        className='position-absolute badge-top-right m-2'
                        color={
                            order.status.uuid === STATUS.CREATED
                                ? 'danger'
                                : order.status.uuid === STATUS.CLOSED ||
                                  order.status.uuid === STATUS.DESTROY
                                ? 'secondary'
                                : order.status.uuid === STATUS.COMPLETED ||
                                  order.status.uuid === STATUS.PAYMENTED
                                ? 'success'
                                : 'primary'
                        }
                        pill
                    >
                        {order.status.name}
                    </Badge>
                </div>
                <div className='card-body-custom d-flex flex-column justify-content-between min-width-zero p-05rem'>
                    <section>
                        {/* <sup className='text-primary fz-sub'>
                            {order.user_identify}
                        </sup> */}
                        <div className='d-flex justify-content-between'>
                            <span className='list-item-heading'>
                                {order.product.name}
                            </span>
                            <span>
                                <Badge color='primary'>
                                    {AddCommaNumber(order.price)} đ
                                </Badge>
                            </span>
                        </div>
                        {/* <span className='text-muted text-small mb-1'>
                            {order.note ? (
                                <span>&#187; {order.note}</span>
                            ) : (
                                <br />
                            )}
                        </span> */}
                        {/* <div className='pr-4'>
                            <p className='text-muted mb-1 text-small'>
                                {'Số lượng: ' + order.count}
                            </p>
                        </div> */}
                        <div className='text-primary text-small font-weight-medium d-sm-block'>
                            {order.date + ' - ' + order.time}
                        </div>
                    </section>
                    <div className='w-100'>
                        {order.size === '' ||
                        order.size.split(',')[1] === '0' ? null : (
                            <Badge className='mr-1 mt-1' color='warning'>
                                {order.size.split(',')[0]}
                            </Badge>
                        )}
                        {order.ice === '' || order.ice === '100' ? null : (
                            <Badge className='mr-1 mt-1' color='primary'>
                                Đá: {order.ice}%
                            </Badge>
                        )}
                        {order.sugar === '' || order.sugar === '100' ? null : (
                            <Badge className='mr-1 mt-1' color='info'>
                                Độ ngọt: {order.sugar}%
                            </Badge>
                        )}
                        {order.topping === '' ||
                        order.topping.split(',')[1] === '0'
                            ? null
                            : toppings.map((_topping, idx) => (
                                  <Badge
                                      color='success'
                                      className='mr-1 mt-1'
                                      key={idx}
                                  >
                                      {_topping.split(',')[0]}
                                  </Badge>
                              ))}
                    </div>
                </div>
                <div className='custom-control custom-checkbox pl-1 align-self-center pr-1'>
                    <CustomInput
                        className='item-check mb-0'
                        type='checkbox'
                        id={`order_status_${order.id}`}
                        checked={selectedItems.includes(order.id)}
                        onChange={() => {}}
                        label=''
                    />
                </div>
            </div>
        );
    };

    let componentRef = useRef();
    let componentRefKitchen = useRef(null);
    return (
        <div
            className={classnames('disable-text-selection', {
                'inline-flex': !isMobile,
            })}
        >
            <div
                className={classnames(
                    'mb-3 custom-shadow mt-5 card-bill-items',
                    {
                        'card-bill-items-desktop': !isMobile,
                    }
                )}
            >
                <div className='position-absolute card-top-buttons'>
                    {isMobile ? (
                        <UncontrolledDropdown>
                            <DropdownToggle
                                color=''
                                className='btn btn-header-light icon-button'
                            >
                                <span className='simple-icon-options' />
                            </DropdownToggle>
                            {selectedItems.length ? (
                                <DropdownMenu right>
                                    {!isIncludeOrderCompleted(
                                        filterOrdersById(orders, selectedItems)
                                    ) ? (
                                        <>
                                            {alias === 111 &&
                                            !isIncludeOrderPending(
                                                filterOrdersById(
                                                    orders,
                                                    selectedItems
                                                )
                                            ) ? (
                                                <DropdownItem
                                                    onClick={() => {
                                                        onActions(
                                                            selectedItems,
                                                            'pending'
                                                        );
                                                    }}
                                                >
                                                    <IntlMessages id='order.to-pending' />
                                                </DropdownItem>
                                            ) : null}
                                            {alias === 222 &&
                                            !isIncludeOrderCreated(
                                                filterOrdersById(
                                                    orders,
                                                    selectedItems
                                                )
                                            ) ? (
                                                <DropdownItem
                                                    onClick={() => {
                                                        onActions(
                                                            selectedItems,
                                                            'done'
                                                        );
                                                    }}
                                                >
                                                    <IntlMessages id='order.to-done' />
                                                </DropdownItem>
                                            ) : null}
                                            {alias !== 444 ? (
                                                <>
                                                    <DropdownItem
                                                        onClick={() => {
                                                            onActions(
                                                                selectedItems,
                                                                'refund'
                                                            );
                                                        }}
                                                    >
                                                        <IntlMessages id='order.to-refund' />
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={() => {
                                                            onActions(
                                                                selectedItems,
                                                                'destroy'
                                                            );
                                                        }}
                                                    >
                                                        <IntlMessages id='order.to-destroy' />
                                                    </DropdownItem>
                                                </>
                                            ) : null}
                                        </>
                                    ) : null}
                                    {alias === 444 &&
                                    isAllOrderCompleted(
                                        filterOrdersById(orders, selectedItems)
                                    ) ? (
                                        <>
                                            <DropdownItem
                                                onClick={() => {
                                                    onActions(
                                                        selectedItems,
                                                        'end'
                                                    );
                                                }}
                                            >
                                                <IntlMessages id='order.to-end' />
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() => {
                                                    toggleModalInvoice({
                                                        orders: selectedItems,
                                                    });
                                                }}
                                            >
                                                <IntlMessages id='order.to-bill' />
                                            </DropdownItem>
                                        </>
                                    ) : null}
                                </DropdownMenu>
                            ) : (
                                <></>
                            )}
                        </UncontrolledDropdown>
                    ) : null}
                </div>
                {!orderGroups.length ? (
                    <Card>
                        <CardBody className='text-center align-center'>
                            Danh sách trống
                        </CardBody>
                    </Card>
                ) : (
                    <>
                        {alias === 444 ? (
                            <div className='d-flex justify-content-around align-items-center text-center w-100 mb-3'>
                                <span>Tổng cộng:</span>
                                <span className='text-primary'>
                                    {priceSelected > 0 ? (
                                        <div className='d-flex align-items-end'>
                                            <h2 className='mb-1'>
                                                {AddCommaNumber(priceSelected)}{' '}
                                                đ{' '}
                                            </h2>{' '}
                                            /{' '}
                                            <div>
                                                {AddCommaNumber(totalPrice)} đ
                                            </div>
                                        </div>
                                    ) : (
                                        `${AddCommaNumber(totalPrice)} đ`
                                    )}
                                </span>
                            </div>
                        ) : null}
                        {orderGroups.map((group) =>
                            group[0].user_identify === null ? (
                                <OrdersAtBar
                                    array={group}
                                    key={group[0].user_identify}
                                />
                            ) : (
                                <Card
                                    key={group[0].user_identify}
                                    className='mb-3'
                                >
                                    <p className='p-2 ml-4 mb-0'>
                                        {group[0].user_identify === null
                                            ? 'Tại quầy'
                                            : `Bàn ${group[0].user_identify}`}
                                    </p>
                                    {group.map((order) => (
                                        <OrderCard
                                            key={order.id}
                                            order={order}
                                            selectedItems={selectedItems}
                                            onCheckItem={onCheckItem}
                                        />
                                    ))}
                                </Card>
                            )
                        )}
                    </>
                )}
            </div>
            {!isMobile ? (
                <div className='pl-2'>
                    <section className='mb-2'>
                        {alias === 111 ? (
                            selectedItems.length &&
                            !isIncludeOrderPending(
                                filterOrdersById(orders, selectedItems)
                            ) &&
                            !isIncludeOrderCompleted(
                                filterOrdersById(orders, selectedItems)
                            ) ? (
                                switchCheckedBillQr ? (
                                    componentRefKitchen && (
                                        <ReactToPrint
                                            trigger={() => (
                                                <div className='card-bill-actions'>
                                                    <div
                                                        className='card-bill-actions-active'
                                                        onClick={() => {
                                                            onActions(
                                                                selectedItems,
                                                                'pending'
                                                            );
                                                        }}
                                                    >
                                                        <IntlMessages id='order.to-pending' />
                                                    </div>
                                                </div>
                                            )}
                                            content={() => componentRefKitchen}
                                        />
                                    )
                                ) : (
                                    <div className='card-bill-actions'>
                                        <div
                                            className='card-bill-actions-active'
                                            onClick={() => {
                                                onActions(
                                                    selectedItems,
                                                    'pending'
                                                );
                                            }}
                                        >
                                            <IntlMessages id='order.to-pending' />
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div className='card-bill-actions'>
                                    <div className='text-muted'>
                                        <IntlMessages id='order.to-pending' />
                                    </div>
                                </div>
                            )
                        ) : null}

                        <div style={{ display: 'none' }}>
                            <ComponentToPrintForKitchen
                                items={items}
                                billDate={billDate}
                                pin_print={pin_print}
                                // discount={discount}
                                ref={(el) => (componentRefKitchen = el)}
                                style={{ display: 'none' }}
                            />
                        </div>

                        {alias === 222 ? (
                            selectedItems.length &&
                            !isIncludeOrderCreated(
                                filterOrdersById(orders, selectedItems)
                            ) &&
                            !isIncludeOrderCompleted(
                                filterOrdersById(orders, selectedItems)
                            ) ? (
                                <div className='card-bill-actions'>
                                    <div
                                        className='card-bill-actions-active'
                                        onClick={() => {
                                            onActions(selectedItems, 'done');
                                        }}
                                    >
                                        {switchCheckedOrderTwo ? (
                                            <IntlMessages id='order.done' />
                                        ) : (
                                            <IntlMessages id='order.to-done' />
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className='card-bill-actions'>
                                    <div className='text-muted'>
                                        {switchCheckedOrderTwo ? (
                                            <IntlMessages id='order.done' />
                                        ) : (
                                            <IntlMessages id='order.to-done' />
                                        )}
                                    </div>
                                </div>
                            )
                        ) : null}
                    </section>

                    {alias === 111 || alias === 222 ? (
                        <div className='custom-line mb-3 mt-3 w-80 m-0-auto' />
                    ) : null}

                    <section className='mb-2'>
                        {alias !== 444 ? (
                            selectedItems.length &&
                            !isIncludeOrderCompleted(
                                filterOrdersById(orders, selectedItems)
                            ) ? (
                                <div className='card-bill-actions'>
                                    <div
                                        className='card-bill-actions-active'
                                        onClick={() => {
                                            onActions(selectedItems, 'refund');
                                        }}
                                    >
                                        <IntlMessages id='order.to-refund' />
                                    </div>
                                </div>
                            ) : (
                                <div className='card-bill-actions'>
                                    <div className='text-muted'>
                                        <IntlMessages id='order.to-refund' />
                                    </div>
                                </div>
                            )
                        ) : null}
                        {alias !== 444 ? (
                            selectedItems.length &&
                            !isIncludeOrderCompleted(
                                filterOrdersById(orders, selectedItems)
                            ) ? (
                                <div className='card-bill-actions'>
                                    <div
                                        className='card-bill-actions-active'
                                        onClick={() => {
                                            onActions(selectedItems, 'destroy');
                                        }}
                                    >
                                        <IntlMessages id='order.to-destroy' />
                                    </div>
                                </div>
                            ) : (
                                <div className='card-bill-actions'>
                                    <div className='text-muted'>
                                        <IntlMessages
                                            className='mb-1'
                                            id='order.to-destroy'
                                        />
                                    </div>
                                </div>
                            )
                        ) : null}
                    </section>

                    {/* <div className="custom-line mb-3 mt-3 w-80 m-0-auto" />  */}

                    <section className='mb-2'>
                        {alias === 444 ? (
                            selectedItems.length &&
                            isAllOrderCompleted(
                                filterOrdersById(orders, selectedItems)
                            ) ? (
                                <div className='card-bill-actions'>
                                    <div
                                        className='card-bill-actions-active'
                                        onClick={() => {
                                            onActions(selectedItems, 'end');
                                        }}
                                    >
                                        <IntlMessages id='order.to-end' />
                                    </div>
                                </div>
                            ) : (
                                <div className='card-bill-actions'>
                                    <div className='text-muted'>
                                        <IntlMessages id='order.to-end' />
                                    </div>
                                </div>
                            )
                        ) : null}
                        {alias === 444 ? (
                            selectedItems.length &&
                            isAllOrderCompleted(
                                filterOrdersById(orders, selectedItems)
                            ) ? (
                                // <div className='card-bill-actions'>
                                //     <div
                                //         className='card-bill-actions-active'
                                //         onClick={() => {
                                //             toggleModalInvoice({
                                //                 orders: selectedItems,
                                //             });
                                //        }}
                                //     >
                                //         <IntlMessages id='order.to-bill' />
                                //     </div>
                                // </div>
                                componentRef && (
                                    <ReactToPrint
                                        onClick={(e) =>
                                            handleClickedPrintInvoice(
                                                e,
                                                selectedItems
                                            )
                                        }
                                        trigger={() => (
                                            <div className='card-bill-actions'>
                                                <div
                                                    className='card-bill-actions-active'
                                                    onClick={(e) =>
                                                        handleClickedPrintInvoice(
                                                            e,
                                                            selectedItems
                                                        )
                                                    }
                                                >
                                                    <IntlMessages id='order.to-bill' />
                                                </div>
                                            </div>
                                        )}
                                        content={() => componentRef}
                                    />
                                )
                            ) : (
                                <div className='card-bill-actions'>
                                    <div className='text-muted'>
                                        <IntlMessages id='order.to-bill' />
                                    </div>
                                </div>
                            )
                        ) : null}
                        {alias === 444 ? (
                            <div className='custom-line mb-3 mt-3 w-80 m-0-auto' />
                        ) : null}
                        {alias === 444 ? (
                            selectedItems.length &&
                            isAllOrderCompleted(
                                filterOrdersById(orders, selectedItems)
                            ) ? (
                                <div className='card-bill-actions'>
                                    <div
                                        className='card-bill-actions-active'
                                        onClick={() => {
                                            onActions(selectedItems, 'change');
                                        }}
                                    >
                                        <IntlMessages id='order.to-change' />
                                    </div>
                                </div>
                            ) : (
                                <div className='card-bill-actions'>
                                    <div className='text-muted'>
                                        <IntlMessages id='order.to-change' />
                                    </div>
                                </div>
                            )
                        ) : null}
                        {/* {alias === 444 ? (
                            selectedItems.length &&
                            isAllOrderCompleted(
                                filterOrdersById(orders, selectedItems)
                            ) ? (
                                <div className='card-bill-actions'>
                                    <div
                                        className='card-bill-actions-active'
                                        onClick={() => {
                                            toggleModalInvoice(
                                                {
                                                    orders: selectedItems,
                                                },
                                                { discount: true }
                                            );
                                        }}
                                    >
                                        <IntlMessages id='discount.to-bill' />
                                    </div>
                                </div>
                            ) : (
                                <div className='card-bill-actions'>
                                    <div className='text-muted'>
                                        <IntlMessages id='discount.to-bill' />
                                    </div>
                                </div>
                            )
                        ) : null} */}
                        {alias === 444 ? (
                            selectedItems.length &&
                            isAllOrderCompleted(
                                filterOrdersById(orders, selectedItems)
                            ) ? (
                                <div className='card-bill-actions'>
                                    <div
                                        className='card-bill-actions-active'
                                        onClick={() => {
                                            toggleModalNested();
                                        }}
                                    >
                                        <IntlMessages id='order.transfer-table' />
                                    </div>
                                </div>
                            ) : (
                                <div className='card-bill-actions'>
                                    <div className='text-muted'>
                                        <IntlMessages id='order.transfer-table' />
                                    </div>
                                </div>
                            )
                        ) : null}
                    </section>
                    <div style={{ display: 'none' }}>
                        <ComponentToPrint
                            alias={alias}
                            items={items}
                            companyName={companyName}
                            companyAddress={companyAddress}
                            companyLogo={companyLogo}
                            companyWebsite={companyWebsite}
                            billDate={billDate}
                            // timeStart={timeStart}
                            pin_print={pin_print}
                            ref={(el) => (componentRef = el)}
                            // discount={
                            //     discount_form.saved ? discount_form : null
                            // }
                        />
                    </div>
                    <div className='custom-line mb-3 mt-3 w-80 m-0-auto' />
                    <section className='mb-2'>
                        {(selectedItems.length &&
                            !isIncludeOrderCompleted(
                                filterOrdersById(orders, selectedItems)
                            )) ||
                        (selectedItems.length &&
                            isAllOrderCompleted(
                                filterOrdersById(orders, selectedItems)
                            )) ? (
                            componentRef && (
                                <ReactToPrint
                                    onClick={(e) =>
                                        handleClickedPrintInvoice(
                                            e,
                                            selectedItems
                                        )
                                    }
                                    trigger={() => (
                                        <div className='card-bill-actions'>
                                            <div
                                                className='card-bill-actions-active'
                                                // onClick={() => {
                                                //     onActions(selectedItems, 'bill-draft');
                                                // }}
                                            >
                                                <IntlMessages id='order.to-bill-draft' />
                                            </div>
                                        </div>
                                    )}
                                    content={() => componentRef}
                                />
                            )
                        ) : (
                            <div className='card-bill-actions'>
                                <div className='text-muted'>
                                    <IntlMessages
                                        className='mb-1'
                                        id='order.to-bill-draft'
                                    />
                                </div>
                            </div>
                        )}
                    </section>
                </div>
            ) : null}
        </div>
    );
};

export default ListPendingOrdersStatus;
