import Axios from 'axios';
import React, { Component, Fragment } from 'react';
import { Button, Card, CardBody, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import { END_POINT, END_POINT_SOCKET } from '../../../constants/defaultValues';
import Calendar from '../../../containers/dashboards/Calendar';
import { Add } from '../../../containers/pages/general';
import { ContentFormCalendar } from '../../../containers/pages/todo';
import {
    NotificatioErrController,
    NotificatioSucController,
} from '../../../helpers/Utils';
import LogsHelper from '../../../helpers/Logs';
import IntlMessages from '../../../helpers/IntlMessages';
import io from 'socket.io-client';
import { isC, isR } from '../../../helpers/Roles';
import AddEvent from '../../../containers/pages/todo/AddEvent';

const DEFAULT_FORM = {
    id: '',
    title: '',
    description: '',
    momentFormatSelectDateTime: new Date(),
    date: '',
    time: '',
    dynamic_form: [],
};

const ROLE_ALIAS = 'calendar';

class DefaultCalendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_loading_data: false,
            add: false,
            form: DEFAULT_FORM,
        };

        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        // this.socket.on('refresh-todo-month', (res) => {
        //     const { data } = res;

        //     this.setState({
        //         events: data.data,
        //         isLoading: true,
        //     });
        // });

        // this.socket.emit('refresh-todo-month', {
        //     user_id: localStorage.getItem('user_id'),
        // });

        // this.onHandlerAdd();
    }

    onClickedAdd = () => {
        this.setState((prev) => ({
            ...prev,
            add: true,
        }));
    };

    onHandlerAdd = (e) => {
        if (!isC(ROLE_ALIAS)) return false;
        e.preventDefault();

        const { title, description, momentFormatSelectDateTime, dynamic_form } =
            this.state.form;

        // if (!title || !description || !momentFormatSelectDateTime) {
        //     NotificatioErrController('Dữ liệu không hợp lệ');
        //     return;
        // }

        let tokenStr = localStorage.getItem('access_token');
        Axios.post(
            `${END_POINT + '/todo'}`,
            {
                title,
                description,
                momentFormatSelectDateTime,
                dynamic_form,
            },
            {
                headers: { Authorization: `Bearer ${tokenStr}` },
            }
        )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['todo-create'],
                });

                this.socket.on('refresh-todo-month', (res) => {
                    const { data } = res;

                    this.setState({
                        events: data.data,
                        isLoading: true,
                    });
                });

                this.socket.emit('refresh-todo-month', {
                    user_id: localStorage.getItem('user_id'),
                });

                // this.dataListRender();
                this.toggle('add');
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    toggle = (name) => {
        this.setState((prev) => ({
            ...prev,
            [name]: !prev[name],
            form: DEFAULT_FORM,
        }));
    };

    onChangeForm = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [name]: value,
                },
            }));
        } else {
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    momentFormatSelectDateTime: e,
                },
            }));
        }
    };

    onSubmitForm = () => {
        this.onHandlerAdd();
    };

    render() {
        const { add, form } = this.state;

        if (!isR(ROLE_ALIAS))
            return (
                <Card className='text-muted mh-40vh h-100'>
                    <CardBody className='d-flex justify-content-center align-items-center'>
                        Bạn không có quyền sử dụng tính năng này!
                    </CardBody>
                </Card>
            );

        return (
            <Fragment>
                <AddEvent
                    isOpen={add}
                    title={<IntlMessages id='todo.add-new-modal-title' />}
                    toggle={() => {
                        this.toggle('add');
                    }}
                    onSubmit={this.onHandlerAdd}
                    values={form}
                    onChange={this.onChangeForm}
                />
                <Row className='mb-3'>
                    <Colxx>
                        <Button
                            color='primary'
                            size='md'
                            onClick={() => this.onClickedAdd()}
                        >
                            Thêm sự kiện
                        </Button>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx>
                        <Calendar onClickedAdd={() => this.toggle('add')} />
                    </Colxx>
                </Row>
            </Fragment>
        );
    }
}

export default DefaultCalendar;
