import React, { PureComponent, useEffect } from 'react';
import {
    Card,
    Badge,
    ButtonDropdown,
    CustomInput,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Collapse,
} from 'reactstrap';
import { connect } from 'react-redux';
import {
    setContainerClassnames,
    clickOnMobileMenu,
} from '../../../redux/actions';
import IntlMessages from '../../../helpers/IntlMessages';
import classnames from 'classnames';
import { UpdateMobile } from '../general';
import ContentDisplayMobile from './ContentDisplayMobile';
import { Separator } from '../../../components/common/CustomBootstrap';

class LabelItemLeft extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            dropdownSplitOpen: false,
        };
    }

    toggleSplit = () => {
        this.setState((prevState) => ({
            dropdownSplitOpen: !prevState.dropdownSplitOpen,
        }));
    };

    menuButtonClick = (e, menuClickCount, containerClassnames) => {
        setTimeout(() => {
            var event = document.createEvent('HTMLEvents');
            event.initEvent('resize', false, false);
            window.dispatchEvent(event);
        }, 350);
        this.props.setContainerClassnames(
            menuClickCount,
            containerClassnames,
            this.props.selectedMenuHasSubItems
        );
    };

    onClickedItem = (item) => {
        this.props.onClickedItem(
            // callback
            item,
            this.menuButtonClick,
            [null, 1000, this.props.containerClassnames]
        );
    };

    render() {
        const { dropdownSplitOpen } = this.state;
        // console.log(this.props.item);

        // console.log('render FormMobileComponent');
        return (
            <div className='custom-shadow'>
                <div className='d-flex flex-row cursor-pointer'>
                    <div className={'d-flex flex-grow-1 min-width-zero'}>
                        <Button
                            color=''
                            onClick={() =>
                                this.props.toggleAccordion(this.props.idx)
                            }
                            aria-expanded={this.props.accordion[this.props.idx]}
                            className='flex-grow-1 btn-empty list-item-heading text-left text-one pre-wrap p-0'
                        >
                            {this.props.item.at ? (
                                <div
                                    onClick={() => {
                                        this.onClickedItem(this.props.item);
                                    }}
                                    className={classnames(
                                        'card-body align-self-center d-flex flex-ssm-column justify-content-between min-width-zero',
                                        {
                                            'flex-lg-row align-items-lg-center':
                                                !this.props.setting.update,
                                        }
                                    )}
                                >
                                    <div className='w-50 list-item-heading mb-1 d-block w-sm-100 text-capitalize'>
                                        <span>{this.props.item.name}</span>
                                    </div>
                                    <div className='w-50'>
                                        <Badge color={'primary'} pill='true'>
                                            {this.props.item.at}
                                        </Badge>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    onClick={() => {
                                        this.onClickedItem(this.props.item);
                                    }}
                                    className='card-body w-100 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center'
                                >
                                    <div className='list-item-heading mb-1 d-block text-capitalize'>
                                        {this.props.item.name !== '' ? (
                                            <span>{this.props.item.name}</span>
                                        ) : (
                                            <span>{`${this.props.item.phone}`}</span>
                                        )}
                                    </div>
                                </div>
                            )}
                        </Button>
                        <div className='custom-control custom-checkbox pl-1 align-self-center pr-4'>
                            <ButtonDropdown
                                isOpen={dropdownSplitOpen}
                                toggle={this.toggleSplit}
                                // onClick={() => {
                                //     this.onClickedItem(this.props.item);
                                // }}
                            >
                                <div className='cursor-pointer btn btn-lg btn-primary pl-4 pr-0 check-button check-all'>
                                    <CustomInput
                                        className='custom-checkbox mb-0 d-inline-block'
                                        type='checkbox'
                                        id={this.props.idx}
                                        checked={this.props.selectedItems.includes(
                                            this.props.item.id
                                        )}
                                        onChange={() => {
                                            this.props.onChangeCheckbox(
                                                this.props.item.id
                                            );
                                        }}
                                        label={
                                            <span
                                                className={`custom-control-label`}
                                            />
                                        }
                                    />
                                </div>
                                <DropdownToggle
                                    caret
                                    color='primary'
                                    className='dropdown-toggle-split'
                                />
                                <DropdownMenu right>
                                    <DropdownItem
                                        className={classnames('text-left', {
                                            disable:
                                                this.props.isDisabledUpdate,
                                        })}
                                        onClick={() =>
                                            this.props.isDisabledUpdate
                                                ? null
                                                : this.props.onUpdate(
                                                      this.props.item
                                                  )
                                        }
                                    >
                                        <i className='simple-icon-note pr-2' />
                                        <IntlMessages id='pages.update' ˇ />
                                    </DropdownItem>
                                    <Separator />
                                    <DropdownItem
                                        className={classnames('text-left red', {
                                            disable:
                                                this.props.isDisabledDelete,
                                        })}
                                        onClick={() =>
                                            this.props.isDisabledDelete
                                                ? null
                                                : this.props.onDelete(
                                                      this.props.item
                                                  )
                                        }
                                    >
                                        <>
                                            <i className='simple-icon-trash pr-2' />
                                            <IntlMessages id='pages.delete' />
                                        </>
                                    </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </div>
                    </div>
                </div>

                <Collapse isOpen={this.props.accordion[this.props.idx]}>
                    <div className='card-body accordion-content pt-0 overflow-hidden'>
                        <Separator />
                        <UpdateMobile>
                            <ContentDisplayMobile
                                // data_dynamic_form={data_dynamic_form}
                                // data_tags={this.props.data_tags}
                                values={this.props.form}
                            />
                        </UpdateMobile>
                    </div>
                </Collapse>
            </div>
        );
    }
}

const mapStateToProps = ({ menu }) => {
    const { containerClassnames, menuClickCount, selectedMenuHasSubItems } =
        menu;
    return {
        containerClassnames,
        menuClickCount,
        selectedMenuHasSubItems,
    };
};
export default connect(mapStateToProps, {
    setContainerClassnames,
    clickOnMobileMenu,
})(LabelItemLeft);
