import React, { Fragment, useEffect } from 'react'
import classnames from 'classnames'

const ContentComponent = (props) => {

  useEffect(() => {

    if (props.disabledScroll) {
      document.body.classList.add('noscroll');
    }
  })
  //
  return (
    props.isOpenModal ? (
      <Fragment>
        <div className={classnames("table-modal-overlay", {
          "table-modal-overlay__hight": props.isHight,
        })} />
        <div className={classnames("table-modal-wrapper", {
          "table-modal-wrapper__hight": props.isHight,
        })}
          onClick={e => {
            document.body.classList.remove('noscroll');
            props.toggleModal();
          }}
          aria-modal aria-hidden tabIndex={-1} role="dialog" data-keyboard="false" data-backdrop="static">
          <div className={props.className ? props.className : "table-modal-fw"}
            onClick={e => {
              e.stopPropagation()
            }}>
            <div className="table-modal-header center">
              <h3 className="m-0-auto pad-l-25px pad-10px">{props.title}</h3>
              <button type="button" className="table-modal-close-button" data-dismiss="modal" aria-label="Close" onClick={() => {
                document.body.classList.remove('noscroll');
                props.toggleModal();
              }}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {props.children}
          </div>
        </div>
      </Fragment>
    ) : null
  )
}

export default ContentComponent;