import React from 'react'
import {
    Input
} from 'reactstrap'

const ContentComponent = (props) => {
    return (
        <Input
            name={props.name || "undefined"}
            disabled={props.disabled || false}
            value={props.value || ""}
            onChange={props.onChange}
            type="text"
        />
    )
}

export default ContentComponent;