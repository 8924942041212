import React, { memo, useState } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
    Row,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Select, { Creatable } from 'react-select';
import CustomSelectInput from '../../../components/common/CustomSelectInput';
import { GetSelectTime, SetSelectTime } from '../../../helpers/SelectTime';
import { AddCommaNumber } from '../../../helpers/Utils';
import DynamicForm from '../general/DynamicForm';
import TooltipItem from '../../../components/common/TooltipItem';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    data_dynamic_form,
    values,
    data_stocks,
    onChange,
    onChangeDynamicForm,
}) => {
    // console.log('render AddComponent');
    const findStockEqual = data_stocks?.find((d) => d.name === values.stock);

    const [timeTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Nếu chọn "Đưa vào thống kê" thì số liệu này sẽ được tính như một khoản chi trong ngày. Nếu chọn "Không đưa vào thống kê" thì chỉ xem như một con số tượng trưng.',
    });
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
            autoFocus={false}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        <FormGroup className='mt-3 has-float-label'>
                            <Label for='stock' className='text-muted'>
                                <IntlMessages id='warehouse.export-stock' />
                            </Label>
                            <Select
                                components={{ Input: CustomSelectInput }}
                                className='react-select'
                                classNamePrefix='react-select'
                                name='stock'
                                id='stock'
                                value={data_stocks.value}
                                placeholder={'Chọn 1 nguyên liệu'}
                                options={data_stocks}
                                onChange={(e) => {
                                    onChange(e, 'stock');
                                }}
                            />
                            {/* <Input
                                autoFocus={true}
                                type='text'
                                name='stock'
                                id='stock'
                                value={values.stock}
                                onChange={onChange}
                            /> */}
                        </FormGroup>
                        {/* <FormGroup>
                            <Label for='code' className='text-muted'>
                                <IntlMessages id='warehouse.code' />
                            </Label>
                            <Input
                                type='text'
                                name='code'
                                id='code'
                                value={values.code}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for='unit' className='text-muted'>
                                <IntlMessages id='warehouse.unit' />
                            </Label>
                            <Input
                                type='text'
                                name='unit'
                                id='unit'
                                value={values.unit}
                                onChange={onChange}
                            />
                        </FormGroup> */}
                        <Row>
                            <Colxx xxs='6'>
                                <FormGroup className='has-float-label'>
                                    <Label
                                        for='quantity'
                                        className='text-muted'
                                    >
                                        <IntlMessages id='warehouse.export-quantity' />
                                    </Label>
                                    <Input
                                        type='text'
                                        name='quantity'
                                        id='quantity'
                                        value={AddCommaNumber(values.quantity)}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Colxx>

                            <Colxx xxs='6'>
                                <FormGroup className='has-float-label'>
                                    <Label
                                        for='inventory'
                                        className='text-muted'
                                    >
                                        Số lượng còn tồn
                                    </Label>
                                    <Input
                                        disabled
                                        type='text'
                                        name='inventory'
                                        id='inventory'
                                        value={
                                            findStockEqual
                                                ? `${findStockEqual.count} ${findStockEqual.unit}`
                                                : 0
                                        }
                                    />
                                </FormGroup>
                            </Colxx>
                        </Row>
                        <Row>
                            <Colxx xxs='6'>
                                <FormGroup className='has-float-label'>
                                    <Label for='price' className='text-muted'>
                                        <IntlMessages id='warehouse.export-price' />
                                    </Label>
                                    <Input
                                        type='text'
                                        name='price'
                                        id='price'
                                        value={AddCommaNumber(values.price)}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Colxx>
                            <Colxx xxs='6'>
                                <FormGroup className='has-float-label'>
                                    <Label
                                        for='inventory'
                                        className='text-muted'
                                    >
                                        Giá mua trung bình
                                    </Label>
                                    <Input
                                        disabled
                                        type='text'
                                        name='inventory'
                                        id='inventory'
                                        value={
                                            findStockEqual
                                                ? `${AddCommaNumber(
                                                      findStockEqual.avarage_price
                                                  )} đ`
                                                : 0
                                        }
                                    />
                                </FormGroup>
                            </Colxx>
                        </Row>
                        <FormGroup className='has-float-label'>
                            <Label for='description' className='text-muted'>
                                <IntlMessages id='warehouse.description' />
                            </Label>
                            <Input
                                type='text'
                                name='description'
                                id='description'
                                value={values.description}
                                onChange={onChange}
                            />
                        </FormGroup>
                        {/* <FormGroup>
                            <Label for='time' className='text-muted'>
                                <IntlMessages id='time' />
                                <TooltipItem item={timeTooltip} id={7} />
                            </Label>
                            <Select
                                components={{ Input: CustomSelectInput }}
                                className='react-select'
                                classNamePrefix='react-select'
                                name='time'
                                value={GetSelectTime(values.time)}
                                placeholder={
                                    <IntlMessages id='select-one-time' />
                                }
                                options={SetSelectTime()}
                                onChange={(e) => {
                                    onChange(e, 'time');
                                }}
                            />
                        </FormGroup> */}
                        {/* {setting.update ? (
                            <FormGroup>
                            <Label for="user.created">
                                <IntlMessages id="user.created" />
                            </Label>
                            <Input type="text" name="user.created" id="user.created"
                                disabled={true}
                                value={values.user}
                                onChange={onChange}
                            />
                            </FormGroup>
                        ) : null} */}

                        <DynamicForm
                            values={values.dynamic_form}
                            data={data_dynamic_form}
                            onChange={onChangeDynamicForm}
                        />
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='warehouse.btn-add-export' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default memo(ContentComponent);
