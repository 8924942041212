import React from 'react';
import { Input, Label, Form, FormGroup } from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import DynamicForm from '../general/DynamicForm';

const ContentComponent = (props) => {
    // const inputRef = useRef();

    // useEffect(() => {
    //     inputRef.current.focus();
    // }, []);

    return (
        <Form autoFocus={false}>
            <FormGroup>
                <Label for='name'>
                    <IntlMessages id='name' />
                </Label>
                <Input
                    autoFocus={true}
                    type='text'
                    name='name'
                    id='name'
                    disabled={props.setting.viewonly && !props.setting.add}
                    value={props.values.name}
                    onChange={props.onChange}
                />
            </FormGroup>

            <DynamicForm
                values={props.values.dynamic_form}
                data={props.data_dynamic_form}
                onChange={props.onChangeDynamicForm}
                disabled={props.setting.viewonly && !props.setting.add}
            />
        </Form>
    );
};

export default ContentComponent;
