import React from 'react';

const ThumbListIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19 19'>
            <path
                className='view-icon-svg'
                d='M17.5,3H6.5a.5.5,0,0,1,0-1h11a.5.5,0,0,1,0,1Z'
            />
            <path
                className='view-icon-svg'
                d='M3,2V3H1V2H3m.12-1H.88A.87.87,0,0,0,0,1.88V3.12A.87.87,0,0,0,.88,4H3.12A.87.87,0,0,0,4,3.12V1.88A.87.87,0,0,0,3.12,1Z'
            />
            <path
                className='view-icon-svg'
                d='M3,9v1H1V9H3m.12-1H.88A.87.87,0,0,0,0,8.88v1.24A.87.87,0,0,0,.88,11H3.12A.87.87,0,0,0,4,10.12V8.88A.87.87,0,0,0,3.12,8Z'
            />
            <path
                className='view-icon-svg'
                d='M3,16v1H1V16H3m.12-1H.88a.87.87,0,0,0-.88.88v1.24A.87.87,0,0,0,.88,18H3.12A.87.87,0,0,0,4,17.12V15.88A.87.87,0,0,0,3.12,15Z'
            />
            <path
                className='view-icon-svg'
                d='M17.5,10H6.5a.5.5,0,0,1,0-1h11a.5.5,0,0,1,0,1Z'
            />
            <path
                className='view-icon-svg'
                d='M17.5,17H6.5a.5.5,0,0,1,0-1h11a.5.5,0,0,1,0,1Z'
            />
        </svg>
    );
};

export default ThumbListIcon;
