import React from 'react';
import { Label, Row, Badge } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { AddCommaNumber, AddCommaPhoneNumber } from '../../../helpers/Utils';

const ContentDisplayMobile = (props) => {
    return (
        <>
            <Row className='mt-4' xxs='12'>
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='phone' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p>
                        {props.values.phone ? (
                            AddCommaPhoneNumber(props.values.phone)
                        ) : (
                            <span className='text-lowercase text-small text-muted'>
                                (Chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='4' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='email' />
                    </p>
                </Colxx>
                <Colxx xxs='8' lg='6' className='text-right'>
                    <p className='text-lowercase'>
                        {props.values.email ? (
                            props.values.email
                        ) : (
                            <span className='text-lowercase text-small text-muted'>
                                (Chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='3' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='address' />
                    </p>
                </Colxx>
                <Colxx xxs='9' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {props.values.address ? (
                            props.values.address
                        ) : (
                            <span className='text-lowercase text-small text-muted'>
                                (Chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='customer.point' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p className='text-lowercase'>
                        {props.values.point ? props.values.point : 0}
                    </p>
                </Colxx>
                <Colxx xxs='4' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='customer.armorial' />
                    </p>
                </Colxx>
                <Colxx xxs='8' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {props.values.armorial ? (
                            props.values.armorial
                        ) : (
                            <span className='text-lowercase text-small text-muted'>
                                (chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='8' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='customer.used-item' />
                    </p>
                </Colxx>
                <Colxx xxs='4' lg='6' className='text-right'>
                    <p className='text-lowercase'>
                        {props.values.used ? props.values.used : 0}
                    </p>
                </Colxx>
                <Colxx xxs='8' lg='6'>
                    <p className='text-muted text-small'>Số tiền đã chi tiêu</p>
                </Colxx>
                <Colxx xxs='4' lg='6' className='text-right'>
                    <p className='text-lowercase'>
                        {props.values.point ? (
                            <Badge color='primary' pill>
                                {AddCommaNumber(props.values.point * 1000)}
                            </Badge>
                        ) : (
                            0
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='8' lg='6'>
                    <p className='text-muted text-small'>Công nợ</p>
                </Colxx>
                <Colxx xxs='4' lg='6' className='text-right'>
                    <p className='text-lowercase'>
                        {props.values.debt ? (
                            <Badge color='primary' pill>
                                {AddCommaNumber(props.values.debt)}
                            </Badge>
                        ) : (
                            0
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='3' lg='3'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='customer.tags' />
                    </p>
                </Colxx>
                <Colxx xxs='9' lg='9' className='text-right'>
                    {props?.values?.tags?.length
                        ? props?.values?.tags?.map((tag, idx) => (
                              <Badge
                                  color='info'
                                  pill
                                  key={idx}
                                  className='mr-1'
                              >
                                  {tag.label}
                              </Badge>
                          ))
                        : props?.values?.tags}
                </Colxx>
            </Row>
        </>
    );
};

export default ContentDisplayMobile;
