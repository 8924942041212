import React from "react";
import {
  Input,
  Label,
  Form, FormGroup,
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { AddCommaNumber } from "../../../helpers/Utils";
import { Creatable } from 'react-select'

const ContentComponent = (props) => {

  let items_select = [];
  if (props.values.log.length > 0) {

    const reducer = (a, o) => (a.push({
      label: o.name,
      value: o.id,
    }), a);
    items_select = props.values.log.filter(_item => _item.alias === "new").reduce(reducer, []);
  }

  return (
    <Form>
      <FormGroup>
        <Label for="item_name">
          <IntlMessages id="company-provider.item_name" />
        </Label>
        <Creatable
          className={'react-select-creatable'}
          placeholder={"Chọn hoặc tạo mới"}
          // isMulti
          name="items_selected"
          id="items_selected"
          onChange={val => props.onChange(val, 'items_selected')}
          options={items_select}
        />
      </FormGroup>

      <FormGroup>
        <Label for="price_income">
          <IntlMessages id="company-provider.total_price_income" />
        </Label>
        <Input name="price_income" id="price_income"
          value={AddCommaNumber(props.values.price_income)}
          onChange={props.onChangePrice}
        />
      </FormGroup>
      <FormGroup>
        <Label for="price_outcome">
          <IntlMessages id="company-provider.total_price_outcome" />
        </Label>
        <Input name="price_outcome" id="price_outcome"
          value={AddCommaNumber(props.values.price_outcome)}
          onChange={props.onChangePrice}
        />
      </FormGroup>
      <FormGroup>
        <Label for="price_pending">
          <IntlMessages id="company-provider.total_price_pending" />
        </Label>
        <Input type="text" name="price_pending" id="price_pending"
          disabled={true}
          value={AddCommaNumber(props.values.price_pending)}
        />
      </FormGroup>
    </Form>
  )
}

export default ContentComponent