import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import orders from './order';
import invoice from './invoice';
import products from './product';
import makeProduct from './make-product';
import categories from './category';
import promotion from './promotion';
// import discount from './promotions/discount';
// import gift from './promotions/gift';
import todos from './todo';
import tableSetting from './table-setting';
import stock from './stock';
import warehouseImport from './warehouseImport';
import warehouseExport from './warehouseExport';
import warehouseTransfer from './warehouseTransfer';
import message from './message';
import userStaff from './user-staff';
import customer from './customer';
import companyProvider from './company-provider';
import treasury from './treasury';
import income from './income';
import outcome from './outcome';
import analytics from './analytics';
import role from './role';
import userRole from './user-role';
import formDynamic from './form-dynamic';
import companies from './companies';
import qrcode from './qrcode';

const Pages = ({ match }) => (
    <div className='dashboard-wrapper'>
        <Switch>
            <Redirect
                exact
                from={`${match.url}/`}
                to={`${match.url}/data-list`}
            />
            <Route path={`${match.url}/product`} component={products} />
            <Route path={`${match.url}/make-product`} component={makeProduct} />
            <Route path={`${match.url}/order`} component={orders} />
            <Route path={`${match.url}/category`} component={categories} />
            <Route path={`${match.url}/todo`} component={todos} />
            <Route path={`${match.url}/promotion`} component={promotion} />

            {/* <Route
                path={`${match.url}/promotions/discount`}
                component={discount}
            />
            <Route path={`${match.url}/promotions/gift`} component={gift} /> */}

            <Route path={`${match.url}/invoice`} component={invoice} />
            <Route
                path={`${match.url}/table-setting`}
                component={tableSetting}
            />
            <Route path={`${match.url}/stock`} component={stock} />
            <Route
                path={`${match.url}/warehouse-import`}
                component={warehouseImport}
            />
            <Route
                path={`${match.url}/warehouse-export`}
                component={warehouseExport}
            />
            <Route
                path={`${match.url}/warehouse-transfer`}
                component={warehouseTransfer}
            />
            <Route path={`${match.url}/message`} component={message} />
            <Route path={`${match.url}/analytics`} component={analytics} />
            <Route path={`${match.url}/user-staff`} component={userStaff} />
            <Route path={`${match.url}/customer`} component={customer} />
            <Route
                path={`${match.url}/company-provider`}
                component={companyProvider}
            />
            <Route path={`${match.url}/treasury`} component={treasury} />
            <Route path={`${match.url}/income`} component={income} />
            <Route path={`${match.url}/outcome`} component={outcome} />
            <Route path={`${match.url}/role`} component={role} />
            <Route path={`${match.url}/user-role`} component={userRole} />
            <Route path={`${match.url}/form-dynamic`} component={formDynamic} />
            <Route path={`${match.url}/companies`} component={companies} />
            <Route path={`${match.url}/qrcode`} component={qrcode} />
            <Route path={`${match.url}`} component={invoice} />
            <Redirect to='/error' />
        </Switch>
    </div>
);
export default Pages;
