import React from 'react';
import { Badge, Card, CardBody, CardTitle, Label, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { GetSelectTime } from '../../../helpers/SelectTime';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentDisplay = (props) => {
    // console.log(props);
    const findStockEqual = props.data_stocks?.find(
        (d) => d.name === props.values.stock
    );
    return (
        <Card className='position-sticky-custom mb-4'>
            <CardBody>
                <CardTitle>
                    <IntlMessages id='warehouse.import' />
                </CardTitle>
                <Row className='mt-3 '>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.import-name' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.name ? (
                                props.values.name
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.phone' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.phone ? (
                                props.values.phone
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.address' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.address ? (
                                props.values.address
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.import-stock' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.stock ? (
                                props.values.stock
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.code' />
                        </p>
                        <p className='mb-3'>
                            {findStockEqual
                                ? findStockEqual.sku
                                : props.values.code}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.unit' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {findStockEqual
                                ? findStockEqual.unit
                                : props.values.unit}
                            {/* {props.values.unit ? (
                                props.values.unit
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )} */}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.import-quantity' />
                        </p>
                        <p className='mb-3 text-lowercase'>
                            {AddCommaNumber(props.values.quantity)}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.import-price' />
                        </p>
                        <p className='mb-3'>
                            {props.values.price ? (
                                <Badge color='primary' pill>
                                    {AddCommaNumber(props.values.price)} đ
                                </Badge>
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    {/* <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='time' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {GetSelectTime(props.values.time).label}
                        </p>
                    </Colxx> */}
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='user.created' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.user}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            Thời gian tạo
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.at}
                        </p>
                    </Colxx>
                </Row>
            </CardBody>
        </Card>
    );
};

export default ContentDisplay;
