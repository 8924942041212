import React from 'react'
import {
    Input
} from 'reactstrap'
import IntlMessages from "../../helpers/IntlMessages";
import { FormattedMessage } from 'react-intl';

const ContentComponent = (props) => {
    return (
        <FormattedMessage id="url.placeholder">
            {placeholder =>
                <Input
                    name={props.name || "undefined"}
                    disabled={props.disabled || false}
                    value={props.value || ""}
                    onChange={props.onChange}
                    type="text"
                    placeholder={placeholder}
                />}
        </FormattedMessage>

    )
}

export default ContentComponent;