import classNames from 'classnames';
import React, { Fragment } from 'react';
import { Badge, Button, Card, CardBody, CardTitle, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { AddCommaNumber } from '../../../helpers/Utils';

const permistionLabel = [
    { permission: 'R', label: 'Xem' },
    { permission: 'C', label: 'Thêm' },
    { permission: 'U', label: 'Sửa' },
    { permission: 'D', label: 'Xóa' },
    { permission: 'F', label: 'Toàn quyền' },
    { permission: 'RDB', label: 'Xem ngày cụ thể' },
    { permission: 'RC', label: 'Xem và so sánh' },
];

const ContentDisplay = (props) => {
    // console.log(props);
    const removeDuplicate = [
        ...new Set(props.values.settings.map((e) => e.role_alias)),
    ];
    // console.log(removeDuplicate);

    const mapLabel = (permission) => {
        permistionLabel.filter((p) => p.permission === permission);
    };

    return (
        <Card className='mb-4'>
            <CardBody>
                <CardTitle>
                    <IntlMessages id='role.title' />
                </CardTitle>
                <Row className='mt-3 '>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='name' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.name ? (
                                props.values.name
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )}
                        </p>
                    </Colxx>
                </Row>
                {removeDuplicate.map((el, idx) => {
                    return (
                        <Row key={idx}>
                            <Colxx xxs='12' lg='4'>
                                <p className='text-muted text-small mb-2'>
                                    {el}
                                </p>
                            </Colxx>
                            <Colxx
                                xxs='12'
                                lg='8'
                                className='d-flex align-items-center'
                            >
                                {props.values.settings
                                    .filter((e, idx) => {
                                        return e.role_alias === el;
                                    })
                                    .map((item, idx) => {
                                        return (
                                            <Colxx key={idx} xxs='12' lg='2'>
                                                <p className='text-muted text-small mb-2'>
                                                    {item.permission}
                                                </p>
                                            </Colxx>
                                        );
                                    })}
                            </Colxx>
                        </Row>
                    );
                })}
            </CardBody>
        </Card>
    );
};

export default ContentDisplay;
