import React, { Component } from 'react';
import {
    Row,
    Card,
    CardBody,
    CardTitle,
    Input,
    FormGroup,
    Label,
    Button,
    Form,
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import axios from 'axios';
import { END_POINT } from '../../../constants/defaultValues';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import {
    NotificatioErrController,
    NotificatioSucController,
} from '../../../helpers/Utils';

import classnames from 'classnames';
import { isF, isR } from '../../../helpers/Roles';

const ROLE_ALIAS = 'ref';

class Ref extends Component {
    state = {
        name: '',
        companyName: '',
        email: '',
        phone: '',
        address: '',
        content: '',
    };

    handleChangeValue = (e) => {
        if (!isF(ROLE_ALIAS)) return false;

        if (!e) return false;

        if (e.target) {
            // input
            const {
                target: { name, value },
            } = e;

            this.setState({ [name]: value });
        }
    };

    onSubmit = (e) => {
        if (!isF(ROLE_ALIAS)) return false;
        e.preventDefault();

        const { name, companyName, email, phone, address, content } =
            this.state;

        if (!name || name === '') {
            NotificatioErrController('Tên chủ quán không được trống');
            return false;
        }
        if (!companyName || companyName === '') {
            NotificatioErrController('Tên quán không được trống');
            return false;
        }
        if (!email || email === '') {
            NotificatioErrController('Email không được trống');
            return false;
        }
        if (!phone || phone === '') {
            NotificatioErrController('Số điện thoại không được trống');
            return false;
        }
        if (!address || address === '') {
            NotificatioErrController('Địa chỉ không được trống');
            return false;
        }
        if (!content || content === '') {
            NotificatioErrController('Lời nhắn không được trống');
            return false;
        }

        // axios submit
        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/ref'}`,
                {
                    companyUUID: localStorage.getItem('companyUUID'),
                    name,
                    companyName,
                    phone,
                    email,
                    address,
                    content,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                NotificatioSucController(data.responseText);

                this.setState({
                    name: '',
                    companyName: '',
                    email: '',
                    phone: '',
                    address: '',
                    content: '',
                });
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    };

    render() {
        let { name, companyName, email, phone, address, content } = this.state;

        if (!isR(ROLE_ALIAS))
            return (
                <Card className='text-muted mh-40vh h-100'>
                    <CardBody className='d-flex justify-content-center align-items-center'>
                        Bạn không có quyền sử dụng tính năng này!
                    </CardBody>
                </Card>
            );

        return (
            <React.Fragment>
                <Row>
                    <Colxx xxs='12'>
                        <Breadcrumb
                            heading='menu.ref'
                            match={this.props.match}
                        />
                        <Separator className='mb-5' />
                    </Colxx>
                </Row>
                <Row className='mb-4'>
                    <Colxx className='mb-4' xxs='12'>
                        <div className='ref-sub-title mb-3'>
                            <IntlMessages id='ref.sub-title' />
                        </div>
                        <Card
                            className={classnames({
                                disable: !isF(ROLE_ALIAS),
                            })}
                            autoFocus={false}
                        >
                            <CardBody>
                                <CardTitle>
                                    <IntlMessages id='ref.title' />
                                </CardTitle>
                                <Form
                                    onSubmit={(e) => this.onSubmit(e)}
                                    // onClick={(e) => {
                                    //     this.onSubmit();
                                    // }}
                                >
                                    <FormGroup row>
                                        {/* NAME */}
                                        <Colxx sm={6}>
                                            <FormGroup>
                                                <Label for='name'>
                                                    <IntlMessages id='ref.name' />
                                                </Label>
                                                <Input
                                                    autoFocus={true}
                                                    type='text'
                                                    name='name'
                                                    id='name'
                                                    value={name}
                                                    onChange={
                                                        this.handleChangeValue
                                                    }
                                                />
                                            </FormGroup>
                                        </Colxx>

                                        {/* COMPANY NAME */}
                                        <Colxx sm={6}>
                                            <FormGroup>
                                                <Label for='companyName'>
                                                    <IntlMessages id='ref.companyName' />
                                                </Label>
                                                <Input
                                                    type='text'
                                                    name='companyName'
                                                    id='companyName'
                                                    value={companyName}
                                                    onChange={
                                                        this.handleChangeValue
                                                    }
                                                />
                                            </FormGroup>
                                        </Colxx>

                                        {/* EMAIL */}
                                        <Colxx sm={6}>
                                            <FormGroup>
                                                <Label for='email'>
                                                    <IntlMessages id='ref.email' />
                                                </Label>
                                                <Input
                                                    type='text'
                                                    name='email'
                                                    id='email'
                                                    value={email}
                                                    onChange={
                                                        this.handleChangeValue
                                                    }
                                                />
                                            </FormGroup>
                                        </Colxx>

                                        {/* PHONE NUMBER */}
                                        <Colxx sm={6}>
                                            <FormGroup>
                                                <Label for='phone'>
                                                    <IntlMessages id='ref.phone' />
                                                </Label>
                                                <Input
                                                    type='text'
                                                    name='phone'
                                                    id='phone'
                                                    value={phone}
                                                    onChange={
                                                        this.handleChangeValue
                                                    }
                                                />
                                            </FormGroup>
                                        </Colxx>

                                        {/* ADDRESS */}
                                        <Colxx sm={12}>
                                            <FormGroup>
                                                <Label for='address'>
                                                    <IntlMessages id='ref.address' />
                                                </Label>
                                                <Input
                                                    type='text'
                                                    name='address'
                                                    id='address'
                                                    value={address}
                                                    onChange={
                                                        this.handleChangeValue
                                                    }
                                                />
                                            </FormGroup>
                                        </Colxx>

                                        {/* CONTENT */}
                                        <Colxx sm={12}>
                                            <FormGroup>
                                                <Label for='content'>
                                                    <IntlMessages id='ref.content' />
                                                </Label>
                                                <Input
                                                    type='text'
                                                    name='content'
                                                    id='content'
                                                    value={content}
                                                    onChange={
                                                        this.handleChangeValue
                                                    }
                                                />
                                            </FormGroup>
                                        </Colxx>
                                    </FormGroup>
                                    <Button
                                        className={classnames({
                                            disable: !isF(ROLE_ALIAS),
                                        })}
                                        color='primary'
                                    >
                                        <IntlMessages id='ref.btn-submit' />
                                    </Button>
                                </Form>
                            </CardBody>
                        </Card>
                    </Colxx>
                </Row>
            </React.Fragment>
        );
    }
}

export default Ref;
