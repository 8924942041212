import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import axios from 'axios';
import { END_POINT, END_POINT_SOCKET } from '../../../constants/defaultValues';
import {
    SetItemsSelected,
    NotificatioErrController,
    NotificatioSucController,
    getCurrentDate,
    getCurrentTime,
    // CheckConfirmPassword,
} from '../../../helpers/Utils';
import io from 'socket.io-client';
import {
    ContentForm,
    ListItemLeftForm,
} from '../../../containers/pages/companies';
import {
    PageHeading,
    Pagination,
    Add,
    Update,
} from '../../../containers/pages/general';
import LogsHelper from '../../../helpers/Logs';
// import PasswordBox from '../../../components/PasswordBox'
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classnames from 'classnames';
import AddCompanies from '../../../containers/pages/companies/AddCompanies';

const DEFAULT_FORM = {
    id: '',
    uuid: '',
    name: '',
    namelink: '',
    search: '',
    type: 'unset',
};

class ContentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: {
                pageSizes: [12, 24],
                selectedPageSize: 12,
                currentPage: 1,
                totalPage: 1,
                totalItem: 0,
                search: '',
            },
            selectedItems: [],
            data: [],
            hide_button_add: true,

            is_loading_data: true,
            show: {
                add: false,
                update: false,
                viewonly: true,
            },
            form: DEFAULT_FORM,
        };

        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        this.dataListRender();
        this.getLicense();
    }

    getLicense = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/user-license'}`, {
                headers: {
                    Authorization: `Bearer ${tokenStr}`,
                },
            })
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                let user_license = data.data;

                let currentDate = getCurrentDate();
                let currentTime = getCurrentTime();

                if (
                    user_license.upgraded ||
                    ((user_license.expired_date > currentDate ||
                        (user_license.expired_date === currentDate &&
                            user_license.expired_time >= currentTime)) &&
                        user_license.purchased_package === 'platinum')
                ) {
                    this.setState((prev) => ({
                        hide_button_add: false,
                    }));
                }
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    };

    /** Axios func helper */
    dataListRender = (required = true) => {
        const { selectedPageSize, currentPage, search } = this.state.page;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(
                `${
                    END_POINT + '/companies/json'
                }?pageSize=${selectedPageSize}&currentPage=${currentPage}&search=${search}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((data) => {
                this.setState(
                    (prev) => ({
                        ...prev,
                        data: data.data,
                        data_length: data.data.length,
                        is_loading_data: false,
                        page: {
                            ...prev.page,
                            totalPage: data.totalPage,
                            totalItem: data.totalItem,
                        },
                    }),
                    () => {
                        if (required) {
                            if (this.state.form.id) {
                                const { id, uuid, name, namelink, type } =
                                    this.state.form;
                                this.setState((prev) => ({
                                    ...prev,
                                    form: {
                                        ...prev.form,
                                        id,
                                        uuid,
                                        name,
                                        namelink,
                                        type,
                                    },
                                }));
                            } else {
                                this.setState((prev) => ({
                                    ...prev,
                                    selectedItems: [],
                                    show: {
                                        ...prev.show,
                                        update: false,
                                    },
                                    form: DEFAULT_FORM,
                                }));
                            }
                        }
                    }
                );
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    dataFindCompanyByUUID = ({ company_uuid }) => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(
                `${END_POINT + '/companies/find'}?company_uuid=${company_uuid}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((data) => {
                if (data.status === 200) {
                    NotificatioSucController(data.responseText);

                    this.setState((prev) => ({
                        ...prev,
                        form: {
                            ...prev.form,
                            uuid: data.data.uuid,
                            name: data.data.name,
                            namelink: data.data.namelink,
                            type: data.data.type,
                        },
                    }));
                }
                // else {
                //   NotificatioErrController(data.responseText)
                //   return false;
                // }
            })
            .catch((err) => {
                this.setState((prev) => ({
                    ...prev,
                    form: {
                        ...prev.form,
                        uuid: '',
                        name: '',
                        namelink: '',
                        type: 'unset',
                    },
                }));
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerDelete = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .delete(`${END_POINT + '/companies'}`, {
                data: {
                    arrayId: this.state.selectedItems,
                },
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['companies-delete'],
                });

                this.dataListRender();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerUpdate = () => {
        const { id, uuid, type } = this.state.form;

        if (!id || !uuid || !type || type == 'unset') {
            NotificatioErrController('Dữ liệu không hợp lệ');
            return;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .put(
                `${END_POINT + '/companies'}`,
                {
                    uuid: id,
                    company_uuid: uuid,
                    type,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['companies-update'],
                });

                if (data.status === 200 && data.data) {
                    this.setState(
                        (prev) => ({
                            ...prev,
                            form: {
                                ...prev.form,
                                uuid: data.data.uuid,
                                namelink: data.data.namelink,
                                type: data.data.type,
                                status: data.data.status,
                            },
                        }),
                        () => {
                            this.dataListRender(false);
                        }
                    );
                }
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerAdd = (e) => {
        e.preventDefault();

        const { uuid, type } = this.state.form;

        if (!uuid) {
            NotificatioErrController('Bạn cần nhập mã cửa hàng cần tìm vào');
            return;
        }
        if (!type || type == 'unset') {
            NotificatioErrController('Loại liên kết cần được chọn');
            return;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/companies'}`,
                {
                    company_uuid: uuid,
                    type,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['companies-create'],
                });

                this.dataListRender();
                // this.toggle('add');
                this.setState((prev) => ({
                    ...prev,
                    form: {
                        ...prev.form,
                        form: DEFAULT_FORM,
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };
    /** Axios func helper */

    /** State func helper */
    toggle = (name) => {
        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                update: name === 'add' ? false : prev.show.update,
                [name]: !prev.show[name],
            },
            selectedItems: name === 'add' ? [] : prev.selectedItems,
            form: name === 'add' ? DEFAULT_FORM : prev.form,
        }));
    };

    onSearch = (e) => {
        const { search } = this.state.form;

        if (!search || search == '') return false;

        this.dataFindCompanyByUUID({ company_uuid: search });
    };

    onChangeForm = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [name]: value,
                },
            }));
        } else {
            let value = e;
            if (_name === 'type') {
                value = e.value;
            }
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [_name]: value,
                },
            }));
        }
    };

    onSubmitForm = (type) => {
        if (type === 'update') {
            this.onHandlerUpdate();
        } else if (type === 'add') {
            this.onHandlerAdd();
        }
    };

    onChangePage = (page) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    currentPage: page,
                },
            }),
            () => this.dataListRender()
        );
    };

    onSearchKey = (e) => {
        if (!e) return false;

        if (e.target) {
            let {
                target: { value },
            } = e;
            let v = value;
            if (value === undefined) {
                v = e.target.firstElementChild.value; // parent to child

                value = v.toLowerCase();
                this.setState(
                    (prev) => ({
                        ...prev,
                        page: {
                            ...prev.page,
                            search: value,
                        },
                    }),
                    () => this.dataListRender()
                );
            } else {
                value = value.toLowerCase();
                this.setState(
                    (prev) => ({
                        ...prev,
                        page: {
                            ...prev.page,
                            search: value,
                        },
                    }),
                    () => this.dataListRender()
                );
            }
        }

        if (e.key === 'Enter') {
            let value = e.target.value.toLowerCase();
            this.setState(
                (prev) => ({
                    ...prev,
                    page: {
                        ...prev.page,
                        search: value,
                    },
                }),
                () => this.dataListRender()
            );
        }
    };

    onChangePageSize = (size) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    selectedPageSize: size,
                    currentPage: 1,
                },
            }),
            () => this.dataListRender()
        );
    };

    onClickedItem = (item, callback, args) => {
        if (this.state.form.id !== item.id) {
            const { id, uuid, name, namelink, type } = item;

            this.setState(
                (prev) => ({
                    ...prev,
                    show: {
                        ...prev.show,
                        update: true,
                        viewonly: true,
                    },
                    form: {
                        ...prev.form,
                        id,
                        uuid,
                        name,
                        namelink,
                        type,
                    },
                    selectedItems: [item.id],
                }),
                () => {
                    callback(args[0], args[1], args[2]);
                }
            );
        }
    };

    onClickedUpdate = (item) => {
        const { id, uuid, name, namelink, type } = item;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                update: true,
            },
            form: {
                ...prev.form,
                id,
                uuid,
                name,
                namelink,
                type,
            },
        }));
    };

    onClickedDelete = (item) => {
        let selectedSet = SetItemsSelected(
            item.id,
            this.state.selectedItems,
            'require'
        );
        if (window.confirm('Bạn chắc chắn muốn xoá Đại lý/Chi nhánh này?')) {
            this.setState(
                (prev) => ({
                    ...prev,
                    selectedItems: selectedSet,
                }),
                () => this.onHandlerDelete()
            );
        }
    };

    onClickedCheckbox = (id, callback) => {
        // let selectedSet = SetItemsSelected(id, this.state.selectedItems);

        this.setState((prev) => ({
            ...prev,
            selectedItems: [id],
        }));
    };
    /** State func helper */

    /** Password */
    // handleChangeValueModalPassword = value => {
    //   this.setState({ password: value })
    // }
    // handleChangeValueModalIsOpen = value => {
    //   this.setState({
    //     password: "",
    //     isOpenModalPassword: !this.state.isOpenModalPassword
    //   })
    // }
    // handleSubmitConfirmPassword = async (callback) => {
    //   if (this.state.password === "") {
    //     this.setState({
    //       isOpenModalPassword: true,
    //     });
    //     return false;
    //   } else {
    //     if (!(await CheckConfirmPassword(this.state.password))) {
    //       NotificatioErrController("Xác thực mật khẩu không thành công")
    //       return false;
    //     }
    //     callback();
    //   }
    // }
    /** Password */

    render() {
        const { match } = this.props;
        const {
            hide_button_add,
            page,
            selectedItems,
            data,
            is_loading_data,
            show,
            form,

            // password,
            // isOpenModalPassword,
            // passwordFuncCallback,
        } = this.state;

        console.log('hide_button_add: ', hide_button_add);
        console.log('license: ', this.state.license);
        console.log('data_length: ', this.state.data_length);

        return is_loading_data ? (
            <div className='loading' />
        ) : (
            <div className='disable-text-selection'>
                {/* <PasswordBox
            password={password}
            onChange={this.handleChangeValueModalPassword}
            isOpen={isOpenModalPassword}
            toggleModal={this.handleChangeValueModalIsOpen}
            onSubmit={this.handleSubmitConfirmPassword}
            callback={passwordFuncCallback}
          /> */}
                <PageHeading
                    heading='analytics.companies'
                    hide_button_add={hide_button_add}
                    setting={page}
                    match={match}
                    totalItem={page.totalItem}
                    toggleFunc={() => {
                        this.toggle('add');
                    }}
                    changePageSizeFunc={this.onChangePageSize}
                    onSearchKeyFunc={this.onSearchKey}
                />
                <AddCompanies
                    title={<IntlMessages id='companies.add-new-modal-title' />}
                    isOpen={show.add}
                    toggle={() => {
                        this.toggle('add');
                    }}
                    values={form}
                    setting={show}
                    onChange={this.onChangeForm}
                    onSearch={this.onSearch}
                    onSubmit={this.onHandlerAdd}
                />
                {data.length === 0 ? (
                    <Card>
                        <CardBody>Chưa có dữ liệu...</CardBody>
                    </Card>
                ) : (
                    <Row className='content-page__row'>
                        <Col
                            xs='12'
                            sm='12'
                            md='12'
                            lg={show.update ? 6 : 12}
                            xl={show.update ? 6 : 12}
                        >
                            {/* <div
                            className={classnames('show-overflow mb-3', {
                                'scroll left-form-list':
                                    data.length >= 5 && show.update,
                            })}
                        >
                            <PerfectScrollbar
                                className={classnames('ps ', {
                                    'hide-scroll-y': !show.update,
                                })}
                                options={{
                                    suppressScrollX: true,
                                    wheelPropagation: true,
                                }}
                            > */}
                            {data.map((item, idx) => (
                                <ListItemLeftForm
                                    onClickedItem={this.onClickedItem}
                                    onUpdate={this.onClickedUpdate}
                                    onDelete={this.onClickedDelete}
                                    onChangeCheckbox={this.onClickedCheckbox}
                                    isDisabledUpdate={item.type === 'parent'}
                                    selectedItems={selectedItems}
                                    setting={show}
                                    key={idx}
                                    idx={idx}
                                    item={item}
                                />
                            ))}
                            {/* </PerfectScrollbar>
                        </div> */}
                            <Pagination
                                currentPage={page.currentPage}
                                totalPage={page.totalPage}
                                onChangePage={(i) => this.onChangePage(i)}
                            />
                        </Col>
                        {show.update ? (
                            <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                                <Update
                                    title={
                                        <IntlMessages id='companies.title' />
                                    }
                                    setting={show}
                                    onSubmit={this.onHandlerUpdate}
                                >
                                    <ContentForm
                                        values={form}
                                        setting={show}
                                        onChange={this.onChangeForm}
                                    />
                                </Update>
                            </Col>
                        ) : null}
                    </Row>
                )}
            </div>
        );
    }
}

export default ContentComponent;
