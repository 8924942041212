import React, { Component } from 'react';
// import { connect } from 'react-redux';
import { NavItem, Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classnames from 'classnames';

// import IntlMessages from '../../../helpers/IntlMessages';
import ApplicationMenu from '../../../components/common/ApplicationMenu';

class ProductApplicationMenu extends Component {
    render() {
        const { data, make_products, filter, filterData } = this.props;
        // console.log(this.props);

        const category = data?.map((d) => d.category.name);
        const categoryUnique = [...new Set(category)];

        const makeProductMatch = make_products?.filter((item) =>
            data?.map((d) => d.id).includes(item.product_id)
        );

        // const uniqueMakeProduct = [
        //     ...new Set(make_products?.map((p) => p.product_id)),
        // ];
        const uniqueProductMatch = [
            ...new Set(makeProductMatch?.map((p) => p.product_id)),
        ];
        // console.log('makeProductMatch: ', makeProductMatch);
        // console.log('uniqueProductMatch: ', uniqueProductMatch);

        // const make_product = make_products.filter(
        //     (product) => product.product_id === values.id
        // );

        // let priceCost = make_product
        //     .map((item) => {
        //         let price = data_stocks.find(
        //             (i) => i.id === item.stock.id
        //         ).avarage_price;
        //         return parseFloat(item.count) * parseFloat(price);
        //     })
        //     .reduce((sum, item) => sum + item, 0);

        return (
            <ApplicationMenu>
                <PerfectScrollbar
                    options={{ suppressScrollX: true, wheelPropagation: false }}
                >
                    <div className='p-4'>
                        <p className='text-muted text-small'>
                            Danh mục sản phẩm
                        </p>
                        <ul className='list-unstyled mb-5'>
                            <NavItem
                                className={classnames({ active: !filter })}
                            >
                                <NavLink
                                    to='#'
                                    onClick={(e) => filterData('', '')}
                                    location={{}}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-layers' />
                                        <span>Tất cả</span>
                                    </div>
                                    <span className='float-right'>
                                        {data?.length}
                                    </span>
                                </NavLink>
                            </NavItem>
                            {categoryUnique.map((cate, idx) => {
                                return (
                                    <NavItem
                                        className={classnames({
                                            active:
                                                filter &&
                                                filter.column === 'name' &&
                                                filter.value === cate,
                                        })}
                                        key={idx}
                                    >
                                        <NavLink
                                            location={{}}
                                            to='#'
                                            onClick={(e) =>
                                                filterData('name', cate)
                                            }
                                            className='d-flex justify-content-between align-items-center'
                                        >
                                            <div className='d-flex align-items-center'>
                                                <i className='simple-icon-check' />
                                                <span>{cate}</span>
                                            </div>
                                            {/* <IntlMessages id='todo.pending-tasks' /> */}
                                            <span className='float-right'>
                                                {
                                                    data.filter(
                                                        (x) =>
                                                            x.category.name ===
                                                            cate
                                                    ).length
                                                }
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                );
                            })}
                        </ul>
                        <p className='text-muted text-small'>
                            Sản phẩm khuyên dùng
                        </p>
                        <ul className='list-unstyled mb-5'>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'bestter' &&
                                        filter.value === true,
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) => filterData('bestter', true)}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>Có</span>
                                    </div>
                                    {/* <IntlMessages id='todo.pending-tasks' /> */}
                                    <span className='float-right'>
                                        {data?.filter((x) => x.bestter).length}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'bestter' &&
                                        filter.value === false,
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) =>
                                        filterData('bestter', false)
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Không</span>
                                    </div>
                                    <span className='float-right'>
                                        {
                                            data?.filter(
                                                (x) => x.bestter === false
                                            ).length
                                        }
                                    </span>
                                </NavLink>
                            </NavItem>
                        </ul>
                        <p className='text-muted text-small'>Chiết khấu</p>
                        <ul className='list-unstyled mb-5'>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'active_sale' &&
                                        filter.value === true,
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) =>
                                        filterData('active_sale', true)
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>Có</span>
                                    </div>
                                    <span className='float-right'>
                                        {
                                            data?.filter(
                                                (x) =>
                                                    Number(x.price_sale) !== 0
                                            ).length
                                        }
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'active_sale' &&
                                        filter.value === false,
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) =>
                                        filterData('active_sale', false)
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Không</span>
                                    </div>
                                    <span className='float-right'>
                                        {
                                            data?.filter(
                                                (x) =>
                                                    Number(x.price_sale) === 0
                                            ).length
                                        }
                                    </span>
                                </NavLink>
                            </NavItem>
                        </ul>
                        <p className='text-muted text-small'>Định lượng</p>
                        <ul className='list-unstyled mb-5'>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'make_product' &&
                                        filter.value === 'done',
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) =>
                                        filterData('make_product', 'done')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>Rồi</span>
                                    </div>
                                    <span className='float-right'>
                                        {uniqueProductMatch.length}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'make_product' &&
                                        filter.value === 'not_yet',
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) =>
                                        filterData('make_product', 'not_yet')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Chưa</span>
                                    </div>
                                    <span className='float-right'>
                                        {data.length -
                                            uniqueProductMatch.length}
                                    </span>
                                </NavLink>
                            </NavItem>
                        </ul>
                    </div>
                </PerfectScrollbar>
            </ApplicationMenu>
        );
    }
}

export default ProductApplicationMenu;
