import React, { Component } from 'react';
import {
    Row,
    Button,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Collapse,
} from 'reactstrap';
import { injectIntl } from 'react-intl';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import Breadcrumb from '../../navs/Breadcrumb';
import IntlMessages from '../../../helpers/IntlMessages';

class PageHeading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayOptionsIsOpen: false,
        };
    }

    toggleDisplayOptions = () => {
        this.setState((prevState) => ({
            displayOptionsIsOpen: !prevState.displayOptionsIsOpen,
        }));
    };

    render() {
        const { messages } = this.props.intl;
        const {
            changePageSizeFunc,
            onSearchKeyFunc,
            handleChangeSelectAllFunc,

            heading,
            setting,
            match,
            orders_id,
            itemsLength,
            selectedItemsLength,
        } = this.props;

        const startIndex = (setting.currentPage - 1) * setting.selectedPageSize;
        const endIndex = setting.currentPage * setting.selectedPageSize;

        const { displayOptionsIsOpen } = this.state;
        return (
            <Row className='list-page-heading__row'>
                <Colxx xxs='12'>
                    <div className='mb-2'>
                        <h1>
                            <IntlMessages id={heading} />
                        </h1>
                        <div className='text-zero top-right-button-container'></div>
                        <Breadcrumb match={match} />
                    </div>

                    <div className='mb-2'>
                        <Button
                            color='empty'
                            className='pt-0 pl-0 d-inline-block d-md-none'
                            onClick={this.toggleDisplayOptions}
                        >
                            <IntlMessages id='pages.display-options' />{' '}
                            <i className='simple-icon-arrow-down align-middle' />
                        </Button>
                        <Collapse
                            isOpen={displayOptionsIsOpen}
                            className='d-md-block'
                            id='displayOptions'
                        >
                            <div className='d-block d-md-inline-block'>
                                <div className='search-sm d-inline-block float-md-left mr-1 mb-1 align-top'>
                                    <input
                                        type='text'
                                        name='keyword'
                                        id='search'
                                        placeholder={messages['order.search']}
                                        onKeyPress={(e) => onSearchKeyFunc(e)}
                                        onChange={(e) => onSearchKeyFunc(e)}
                                    />
                                </div>
                            </div>
                            <div className='float-md-right'>
                                <span className='text-muted text-small mr-1'>{`${startIndex}-${endIndex} of ${setting.totalItem} `}</span>
                                <UncontrolledDropdown className='d-inline-block'>
                                    <DropdownToggle
                                        caret
                                        color='outline-dark'
                                        size='xs'
                                    >
                                        {setting.selectedPageSize}
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        {setting.pageSizes.map(
                                            (size, index) => {
                                                return (
                                                    <DropdownItem
                                                        key={index}
                                                        onClick={() =>
                                                            changePageSizeFunc(
                                                                size
                                                            )
                                                        }
                                                    >
                                                        {size}
                                                    </DropdownItem>
                                                );
                                            }
                                        )}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </Collapse>
                    </div>
                    {/* <Separator /> */}
                </Colxx>
            </Row>
        );
    }
}

export default injectIntl(PageHeading);
