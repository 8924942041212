import React from "react";
import {
  Input, InputGroup, InputGroupAddon,
  Label,
  Form, FormGroup, Button,
  Row, Col,
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages"
import { AddCommaNumber } from "../../../helpers/Utils"
import DynamicForm from '../general/DynamicForm'

const ContentComponent = (props) => {
  return (
    <Form>
      <FormGroup>
        <Label for="name">
          <IntlMessages id="stock.name" />
        </Label>
        <Input type="text" name="name" id="name"
          disabled={props.setting.viewonly && !props.setting.add}
          value={props.values.name}
          onChange={props.onChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="unit">
          <IntlMessages id="stock.unit" />
        </Label>
        <Input type="text" name="unit" id="unit"
          disabled={props.setting.viewonly && !props.setting.add}
          value={props.values.unit}
          onChange={props.onChange}
        />
      </FormGroup>

      {props.setting.add ? (
        <FormGroup>
          <Label for="unit_price">
            <IntlMessages id="stock.unit-price" />
          </Label>
          <Input name="unit_price" id="unit_price"
            value={AddCommaNumber(props.values.unit_price)}
            onChange={props.onChange}
          />
        </FormGroup>
      ) : null}

      {props.setting.add ? (
        <FormGroup>
          <Label for="new_count">
            <IntlMessages id="stock.count" />
          </Label>
          <Input name="new_count" id="new_count"
            type="number" step="1"
            value={props.values.new_count}
            onChange={props.onChange}
          />
        </FormGroup>
      ) : null}

      {props.setting.update ? (
        <>
          <FormGroup>
            <Label for="count">
              <IntlMessages id="stock.count-current" />
            </Label>
            <Row>
              <Col xs="6" sm="6" md="6" lg="6">
                <InputGroup>
                  <Input type="text" name="count" id="count"
                    disabled={true}
                    value={props.values.count}
                  />
                  <InputGroupAddon addonType="append">
                    {props.values.unit}
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col xs="6" sm="6" md="6" lg="6">
                <Button
                  disabled={props.setting.viewonly && !props.setting.add}
                  onClick={() => { props.onReset(props.values) }}>
                  <IntlMessages id="stock.reset" />
                </Button>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Label for="total">
              <IntlMessages id="stock.count-total" />
            </Label>
            <InputGroup>
              <Input type="text" name="total" id="total"
                disabled={true}
                value={props.values.total}
              />
              <InputGroupAddon addonType="append">
                {props.values.unit}
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </>
      ) : null}

      <DynamicForm
        values={props.values.dynamic_form}
        data={props.data_dynamic_form}
        onChange={props.onChangeDynamicForm}
        disabled={props.setting.viewonly && !props.setting.add}
      />
    </Form>
  )
}

export default ContentComponent