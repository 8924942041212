import { RandomString } from './Utils';

export const WorkingHours = (time_str) => {
    if (!time_str) return [];

    let result = [];
    const ranges = time_str.split('|');
    for (let i = 0; i < ranges.length; i++) {
        let timer = ranges[i].split('-');
        let time_start = timer[0];
        let time_end = timer[1];

        result.push({
            hash: RandomString(5),
            start: time_start,
            end: time_end,
        });
    }
    return result;
};

export const GetWorkingHours = (time_arr) => {
    let result = '';

    for (let i = 0; i < time_arr.length; i++) {
        const timer = time_arr[i];
        result += `${timer.start}-${timer.end}|`;
    }

    result = result.slice(0, -1); // last character

    return result;
};

export const LogsType = () => {
    return [
        {
            label: 'Tiền hoa hồng',
            value: 'commission',
        },
        {
            label: 'Tiền thưởng',
            value: 'bonus',
        },
        {
            label: 'Tăng ca',
            value: 'overtime_hour',
        },
        {
            label: 'Tiền tạm ứng',
            value: 'advence',
        },
        {
            label: 'Tiền phạt',
            value: 'fined',
        },
        {
            label: 'Số giờ nghỉ trừ lương',
            value: 'day_off_hour',
        },
    ];
};
export const GetLogsType = (val) => {
    if (val === 'commission') {
        return {
            label: 'Tiền hoa hồng',
            value: 'commission',
        };
    }
    if (val === 'bonus') {
        return {
            label: 'Tiền thưởng',
            value: 'bonus',
        };
    }
    if (val === 'overtime') {
        return {
            label: 'Tăng ca',
            value: 'overtime',
        };
    }
    if (val === 'advence') {
        return {
            label: 'Tiền tạm ứng',
            value: 'advence',
        };
    }
    if (val === 'fined') {
        return {
            label: 'Tiền phạt',
            value: 'fined',
        };
    }
    if (val === 'dayoff') {
        return {
            label: 'Số giờ nghỉ trừ lương',
            value: 'dayoff',
        };
    }
    return {
        label: 'Tiền hoa hồng',
        value: 'commission',
    };
};

export const LogsValueOvertimeCoefficient = () => {
    return [
        {
            label: '1',
            value: '1',
        },
        {
            label: '1.5',
            value: '1.5',
        },
        {
            label: '2',
            value: '2',
        },
        {
            label: '2.5',
            value: '2.5',
        },
        {
            label: '3',
            value: '3',
        },
        {
            label: '3.5',
            value: '3.5',
        },
        {
            label: '4',
            value: '4',
        },
        {
            label: '4.5',
            value: '4.5',
        },
        {
            label: '5',
            value: '5',
        },
    ];
};
export const GetLogsValueOvertimeCoefficient = (val) => {
    return {
        label: val,
        value: val,
    };
};

export const StatusWorking = () => {
    return [
        {
            label: 'Đang làm',
            value: 'on',
        },
        {
            label: 'Nghỉ việc',
            value: 'off',
        },
    ];
};

export const GetStatusWorking = (val) => {
    if (val === 'on') {
        return {
            label: 'Đang làm',
            value: 'on',
        };
    }
    if (val === 'off') {
        return {
            label: 'Nghỉ việc',
            value: 'off',
        };
    }

    return {
        label: 'Đang làm',
        value: 'on',
    };
};

export const SalaryBy = () => {
    return [
        {
            label: 'Giờ',
            value: 'h',
        },
        {
            label: 'Ngày',
            value: 'd',
        },
        {
            label: 'Tháng',
            value: 'm',
        },
    ];
};
export const GetSalaryBy = (val) => {
    if (val === 'h') {
        return {
            label: 'Giờ',
            value: 'h',
        };
    }
    if (val === 'd') {
        return {
            label: 'Ngày',
            value: 'd',
        };
    }
    if (val === 'm') {
        return {
            label: 'Tháng',
            value: 'm',
        };
    }
    return {
        label: 'Giờ',
        value: 'h',
    };
};

export const HourWorkingOnDay = () => {
    return [
        {
            label: '1',
            value: '1',
        },
        {
            label: '2',
            value: '2',
        },
        {
            label: '3',
            value: '3',
        },
        {
            label: '4',
            value: '4',
        },
        {
            label: '5',
            value: '5',
        },
        {
            label: '6',
            value: '6',
        },
        {
            label: '7',
            value: '7',
        },
        {
            label: '8',
            value: '8',
        },
        {
            label: '9',
            value: '9',
        },
        {
            label: '10',
            value: '10',
        },
        {
            label: '11',
            value: '11',
        },
        {
            label: '12',
            value: '12',
        },
        {
            label: '13',
            value: '13',
        },
        {
            label: '14',
            value: '14',
        },
        {
            label: '15',
            value: '15',
        },
        {
            label: '16',
            value: '16',
        },
        {
            label: '17',
            value: '17',
        },
        {
            label: '18',
            value: '18',
        },
        {
            label: '19',
            value: '19',
        },
        {
            label: '20',
            value: '20',
        },
        {
            label: '21',
            value: '21',
        },
        {
            label: '22',
            value: '22',
        },
        {
            label: '23',
            value: '23',
        },
        {
            label: '24',
            value: '24',
        },
    ];
};
export const GetHourWorkingOnDay = (val) => {
    return {
        label: val,
        value: val,
    };
};

export const DateGetSalary = () => {
    return [
        {
            label: '1',
            value: '1',
        },
        {
            label: '2',
            value: '2',
        },
        {
            label: '3',
            value: '3',
        },
        {
            label: '4',
            value: '4',
        },
        {
            label: '5',
            value: '5',
        },
        {
            label: '6',
            value: '6',
        },
        {
            label: '7',
            value: '7',
        },
        {
            label: '8',
            value: '8',
        },
        {
            label: '9',
            value: '9',
        },
        {
            label: '10',
            value: '10',
        },
        {
            label: '11',
            value: '11',
        },
        {
            label: '12',
            value: '12',
        },
        {
            label: '13',
            value: '13',
        },
        {
            label: '14',
            value: '14',
        },
        {
            label: '15',
            value: '15',
        },
        {
            label: '16',
            value: '16',
        },
        {
            label: '17',
            value: '17',
        },
        {
            label: '18',
            value: '18',
        },
        {
            label: '19',
            value: '19',
        },
        {
            label: '20',
            value: '20',
        },
        {
            label: '21',
            value: '21',
        },
        {
            label: '22',
            value: '22',
        },
        {
            label: '23',
            value: '23',
        },
        {
            label: '24',
            value: '24',
        },
        {
            label: '25',
            value: '25',
        },
        {
            label: '26',
            value: '26',
        },
        {
            label: '27',
            value: '27',
        },
        {
            label: '28',
            value: '28',
        },
    ];
};

export const GetDateGetSalary = (val) => {
    return {
        label: val,
        value: val,
    };
};

export const FindUpdateForm = ({ _new, find_user_staff }) => {
    const {
        date_get_salary,
        salary_by,
        salary_value,
        working_hours,
        hour_working_on_day,
    } = _new;

    // Mặc định KHÔNG có pending thì gửi dữ liệu mới thay đổi lên
    let result = {
        date_get_salary: null,
        salary_by: null,
        salary_value: null,
        hour_working_on_day: null,
        working_hours: null,
    };

    // Có thay đổi thì mới cập nhật
    if (date_get_salary != find_user_staff.setting.date_get_salary) {
        result.date_get_salary = date_get_salary;
    }
    if (salary_by != find_user_staff.setting.salary_by) {
        result.salary_by = salary_by;
    }
    if (salary_value != find_user_staff.setting.salary_value) {
        result.salary_value = salary_value.replace(/\./g, '');
    }
    if (hour_working_on_day != find_user_staff.setting.hour_working_on_day) {
        result.hour_working_on_day = hour_working_on_day;
    }
    if (
        GetWorkingHours(working_hours) != find_user_staff.setting.working_hours
    ) {
        result.working_hours = GetWorkingHours(working_hours);
    }

    return result;
};
