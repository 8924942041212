import React, { Component, useEffect } from 'react';
import {
    Card,
    Badge,
    ButtonDropdown,
    CustomInput,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Collapse,
} from 'reactstrap';
import { connect } from 'react-redux';
import {
    setContainerClassnames,
    clickOnMobileMenu,
} from '../../../redux/actions';
import IntlMessages from '../../../helpers/IntlMessages';
import classnames from 'classnames';
import { UpdateMobile } from '../general';
import ContentDisplayMobile from './ContentDisplayMobile';
import { Separator } from '../../../components/common/CustomBootstrap';

class LabelItemLeft extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdownSplitOpen: false,
        };
    }

    toggleSplit = () => {
        this.setState((prevState) => ({
            dropdownSplitOpen: !prevState.dropdownSplitOpen,
        }));
    };

    menuButtonClick = (e, menuClickCount, containerClassnames) => {
        setTimeout(() => {
            var event = document.createEvent('HTMLEvents');
            event.initEvent('resize', false, false);
            window.dispatchEvent(event);
        }, 350);
        this.props.setContainerClassnames(
            menuClickCount,
            containerClassnames,
            this.props.selectedMenuHasSubItems
        );
    };

    onClickedItem = (item) => {
        this.props.onClickedItem(
            // callback
            item,
            this.menuButtonClick,
            [null, 1000, this.props.containerClassnames]
        );
    };

    render() {
        const { dropdownSplitOpen } = this.state;
        const {
            make_products,
            data_stocks,
            item,
            toggleAccordion,
            accordion,
            idx,
            setting,
            selectedItems,
            isDisabledUpdate,
            isDisabledUpdateCost,
            isDisabledDelete,
            onUpdateCost,
            onUpdate,
            onChangeCheckbox,
            onBestter,
            onSoftHide,
            onDelete,
            onChange,
            form,
            sku,
        } = this.props;
        // console.log(item);
        return (
            <div className='custom-shadow'>
                <div className='d-flex flex-row cursor-pointer'>
                    <div
                        className={classnames(
                            'd-flex flex-grow-1 min-width-zero',
                            {
                                disable: item.softHide ? 'disable' : '',
                            }
                        )}
                        // className={'d-flex flex-grow-1 min-width-zero'}
                    >
                        <Button
                            color=''
                            onClick={() => toggleAccordion(idx)}
                            aria-expanded={accordion[idx]}
                            className='flex-grow-1 btn-empty list-item-heading text-left text-one pre-wrap p-0'
                        >
                            {item.at ? (
                                <div
                                    onClick={() => {
                                        this.onClickedItem(item);
                                    }}
                                    className={classnames(
                                        'card-body align-self-center d-flex flex-ssm-column justify-content-between min-width-zero',
                                        {
                                            'flex-lg-row align-items-lg-center':
                                                !setting.update,
                                        }
                                    )}
                                >
                                    <div className='w-50 list-item-heading mb-1 d-block w-sm-100 text-capitalize'>
                                        <span>{item.name}</span>
                                    </div>
                                    <div className='w-50'>
                                        <Badge color={'primary'} pill='true'>
                                            {item.at}
                                        </Badge>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    onClick={() => {
                                        this.onClickedItem(item);
                                    }}
                                    className='card-body w-100 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center'
                                >
                                    <div className='list-item-heading mb-1 d-block text-capitalize'>
                                        {item.name !== '' ? (
                                            <span>{item.name}</span>
                                        ) : (
                                            <span>{`${item.phone}`}</span>
                                        )}
                                    </div>
                                </div>
                            )}
                        </Button>
                        <div className='custom-control custom-checkbox pl-1 align-self-center pr-4'>
                            <ButtonDropdown
                                isOpen={dropdownSplitOpen}
                                toggle={this.toggleSplit}
                                // onClick={() => {
                                //     this.onClickedItem(item);
                                // }}
                            >
                                <div className='cursor-pointer btn btn-lg btn-primary pl-4 pr-0 check-button check-all'>
                                    <CustomInput
                                        className='custom-checkbox mb-0 d-inline-block'
                                        type='checkbox'
                                        id={idx}
                                        checked={selectedItems.includes(
                                            item.id
                                        )}
                                        onChange={() => {
                                            onChangeCheckbox(item.id);
                                        }}
                                        label={
                                            <span
                                                className={`custom-control-label`}
                                            />
                                        }
                                    />
                                </div>
                                <DropdownToggle
                                    caret
                                    color='primary'
                                    className='dropdown-toggle-split'
                                />
                                <DropdownMenu right>
                                    <DropdownItem
                                        className={classnames('text-left', {
                                            disable: isDisabledUpdate,
                                        })}
                                        onClick={() =>
                                            isDisabledUpdate
                                                ? null
                                                : onUpdate(item)
                                        }
                                    >
                                        <i className='simple-icon-note pr-2' />
                                        <IntlMessages id='pages.update' ˇ />
                                    </DropdownItem>
                                    {/* <Separator />
                                    {make_products?.length ? (
                                        <DropdownItem
                                            className={classnames('text-left', {
                                                disable: isDisabledUpdateCost,
                                            })}
                                            onClick={() =>
                                                isDisabledUpdateCost
                                                    ? null
                                                    : onUpdateCost(item)
                                            }
                                        >
                                            <i className='simple-icon-note pr-2' />
                                            <IntlMessages id='product.edit_cost' />
                                        </DropdownItem>
                                    ) : (
                                        <DropdownItem
                                            className={classnames('text-left', {
                                                disable: isDisabledUpdateCost,
                                            })}
                                            onClick={() =>
                                                isDisabledUpdateCost
                                                    ? null
                                                    : onUpdateCost(item)
                                            }
                                        >
                                            Thêm định lượng
                                        </DropdownItem>
                                    )} */}
                                    <Separator />
                                    <DropdownItem
                                        className={classnames('text-left', {
                                            disable: isDisabledUpdate,
                                        })}
                                        onClick={() =>
                                            isDisabledUpdate
                                                ? null
                                                : onBestter(item)
                                        }
                                    >
                                        {item.bestter ? (
                                            <>
                                                <i className='simple-icon-close pr-2' />
                                                <IntlMessages id='pages.un-bestter' />
                                            </>
                                        ) : (
                                            <>
                                                <i className='simple-icon-check pr-2' />
                                                <IntlMessages id='pages.bestter' />
                                            </>
                                        )}
                                    </DropdownItem>
                                    <Separator />
                                    <DropdownItem
                                        className={classnames('text-left', {
                                            disable: isDisabledUpdate,
                                        })}
                                        onClick={() =>
                                            isDisabledUpdate
                                                ? null
                                                : onSoftHide(item)
                                        }
                                    >
                                        {item.softHide ? (
                                            <>
                                                <i className='simple-icon-lock-open pr-2' />
                                                <IntlMessages id='pages.un-softhide' />
                                            </>
                                        ) : (
                                            <>
                                                <i className='simple-icon-lock pr-2' />
                                                <IntlMessages id='pages.softhide' />
                                            </>
                                        )}
                                    </DropdownItem>
                                    <Separator />
                                    <DropdownItem
                                        className={classnames('text-left red', {
                                            disable: isDisabledDelete,
                                        })}
                                        onClick={() =>
                                            isDisabledDelete
                                                ? null
                                                : onDelete(item)
                                        }
                                    >
                                        <>
                                            <i className='simple-icon-trash pr-2' />
                                            <IntlMessages id='pages.delete' />
                                        </>
                                    </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </div>
                    </div>
                </div>

                <Collapse isOpen={accordion[idx]}>
                    <div className='card-body accordion-content pt-0 overflow-hidden'>
                        <Separator />
                        <UpdateMobile>
                            <ContentDisplayMobile
                                // data_dynamic_form={data_dynamic_form}
                                // data_tags={this.props.data_tags}
                                sku={sku}
                                values={form}
                                onChange={onChange}
                                data_stocks={data_stocks}
                                make_products={make_products}
                            />
                        </UpdateMobile>
                    </div>
                </Collapse>
            </div>
        );
    }
}

const mapStateToProps = ({ menu }) => {
    const { containerClassnames, menuClickCount, selectedMenuHasSubItems } =
        menu;
    return {
        containerClassnames,
        menuClickCount,
        selectedMenuHasSubItems,
    };
};
export default connect(mapStateToProps, {
    setContainerClassnames,
    clickOnMobileMenu,
})(LabelItemLeft);
