import React from "react";
import {
  Input,
  Label,
  Form, FormGroup,
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import { SetType, GetType } from '../../../helpers/FormDynamic'

const ContentComponent = (props) => {
  return (
    <Form>
      <FormGroup>
        <Label for="label">
          <IntlMessages id="form-dynamic.label" />
        </Label>
        <Input type="text" name="label" id="label"
          disabled={props.setting.viewonly && !props.setting.add}
          value={props.values.label}
          onChange={props.onChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="alias">
          <IntlMessages id="form-dynamic.alias" />
        </Label>
        <Select
          components={{ Input: CustomSelectInput }}
          className="react-select"
          classNamePrefix="react-select"
          name="form"
          value={props.values.form}
          isDisabled={props.setting.viewonly && !props.setting.add}
          placeholder={<IntlMessages id="form-dynamic.select-one-alias" />}
          options={props.forms}
          onChange={(e) => { props.onChange(e, 'form') }}
        />
      </FormGroup>
      <FormGroup>
        <Label for="type">
          <IntlMessages id="form-dynamic.type" />
        </Label>
        <Select
          components={{ Input: CustomSelectInput }}
          className="react-select"
          classNamePrefix="react-select"
          name="type"
          value={GetType(props.values.type)}
          isDisabled={props.setting.viewonly && !props.setting.add}
          placeholder={<IntlMessages id="form-dynamic.select-one-type" />}
          options={SetType()}
          onChange={(e) => { props.onChange(e, 'type') }}
        />
      </FormGroup>
    </Form>
  )
}

export default ContentComponent