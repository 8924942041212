import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";

const ContentComponent = ({ title, isOpen, toggle, onSubmit, children }) => {

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      wrapClassName="modal-right"
      backdrop={true}
    >
      <ModalHeader toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        {children}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onSubmit}>
          <IntlMessages id="general.btn-add" />
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default ContentComponent;
