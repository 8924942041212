export const SetType = [
  {
    label: "Tổng đại lý",
    value: "parent"
  },
  {
    label: "Chi nhánh",
    value: "child"
  },
]

export const GetType = (type) => {
  if (type === 'parent') {
    return {
      label: "Tổng đại lý",
      value: "parent"
    }
  }
  if (type === 'child') {
    return {
      label: "Chi nhánh",
      value: "child"
    }
  }
  return {
    label: "Chưa liên kết",
    value: "unset"
  }
}
