import React, { Component } from 'react';
import {
    Row,
    Col,
    Nav,
    NavItem,
    TabContent,
    TabPane,
    CardBody,
    Card,
} from 'reactstrap';
import axios from 'axios';
import { END_POINT, END_POINT_SOCKET } from '../../../constants/defaultValues';
import {
    SetItemsSelected,
    NotificatioErrController,
    NotificatioSucController,
    RandomString,
    getDateWithFormat,
    getAvatar,
} from '../../../helpers/Utils';
import io from 'socket.io-client';
import {
    PageHeading,
    ListItemLeftForm,
    ListItemFormMobile,
    UserStaffApplicationMenu,
    ContentDisplay,
} from '../../../containers/pages/user-staff';
import { Pagination } from '../../../containers/pages/general';
import LogsHelper from '../../../helpers/Logs';
import IntlMessages from '../../../helpers/IntlMessages';
import {
    GetWorkingHours,
    WorkingHours,
    FindUpdateForm,
} from '../../../helpers/UserStaff';
import classnames from 'classnames';
import { isC, isR, isU, isD } from '../../../helpers/Roles';
import { NavLink } from 'react-router-dom';
import FooterPage from '../../../containers/pages/FooterPage';
import { Separator } from '../../../components/common/CustomBootstrap';
import FooterUserStaff from '../../../containers/pages/user-staff/FooterUserStaff';
import AddUserStaff from '../../../containers/pages/user-staff/AddUserStaff';
import AddUserStaffLog from '../../../containers/pages/user-staff/AddUserStaffLog';
import UpdateUserStaff from '../../../containers/pages/user-staff/UpdateUserStaff';

const DEFAULT_FORM = {
    id: '',
    name: '',
    phone: '',
    email: '',
    address: '',
    permission_name: '',
    date_join: new Date(),
    date_get_salary: '1',
    salary_by: 'h',
    salary_value: '',
    working_hours: [],
    hour_working_on_day: 8,
    status_working: 'on',

    // disabled
    total_salary: '',
    total_day_working: '',
    current_salary: '',
    current_day_working: '',
    current_commission: '',
    current_bonus: '',
    current_overtime: '',
    current_advence: '',
    current_fined: '',
    current_dayoff: '',
    pending_date: null,
    pending_hour: null,
    lst_6_month: [],

    // logs
    logs_users_staff: '',
    logs_type: 'commission',
    logs_value: '',
    logs_value_overtime_coefficient: 1,

    dynamic_form: [],
};

const ROLE_ALIAS = 'user_staff';

class ContentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: {
                pageSizes: [12, 24],
                selectedPageSize: 12,
                currentPage: 1,
                totalPage: 1,
                totalItem: 0,
                search: '',
            },
            selectedItems: [],
            data: [],
            data_all: [],
            filter: null,
            data_dynamic_form: [],
            data_detail: {},
            is_loading_data: true,
            is_loading_data_detail: true,
            show: {
                add: false,
                update: false,
                view: false,
                log: false,
                viewonly: true,
            },
            accordion: [],
            activeTab: '1',
            form: DEFAULT_FORM,
        };

        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        this.dataListRender();
    }

    /** Axios func helper */
    dataListRender = () => {
        const { selectedPageSize, currentPage, search } = this.state.page;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(
                `${
                    END_POINT + '/user-staff/json'
                }?pageSize=${selectedPageSize}&currentPage=${currentPage}&search=${search}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((data) => {
                // console.log(data);
                let anyAccordion = [];
                let numeroPerguntas = data.data.length;
                for (var i = 0; i < numeroPerguntas; i++) {
                    // if (!i) {
                    //     anyAccordion.push(true);
                    // }
                    anyAccordion.push(false);
                }
                this.setState(
                    (prev) => ({
                        ...prev,
                        data: data.data,
                        data_all: data.data,
                        data_dynamic_form: data.dataDynamicForm,
                        is_loading_data: false,
                        is_loading_data_detail: true,
                        page: {
                            ...prev.page,
                            totalPage: data.totalPage,
                            totalItem: data.totalItem,
                        },
                        accordion: anyAccordion,
                    }),
                    () => {
                        if (this.state.form.id) {
                            const { id, dynamic_form } = this.state.form;

                            this.setState(
                                (prev) => ({
                                    ...prev,
                                    is_loading_data_detail: false,
                                    form: {
                                        ...prev.form,
                                        id,
                                        dynamic_form,
                                    },
                                }),
                                () => this.dataUserStaffRender(id)
                            );
                        } else {
                            this.setState((prev) => ({
                                ...prev,
                                selectedItems: [],
                                show: {
                                    ...prev.show,
                                    update: false,
                                    view: false,
                                },
                                form: DEFAULT_FORM,
                            }));
                        }
                    }
                );
            })
            .catch((err) => {
                NotificatioErrController(err.response?.data?.responseText);
                return false;
            });
    };

    dataUserStaffRender = (id) => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT}/user-staff?user_staff_id=${id}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                // console.log(data);
                this.setState((prev) => ({
                    ...prev,
                    is_loading_data_detail: false,
                    data_detail: data.data,
                    form: {
                        ...prev.form,
                        id: data.data.id,
                        email: data.data.email,
                        name: data.data.name,
                        phone: data.data.phone,
                        address: data.data.address,
                        date_get_salary: data.data.setting?.date_get_salary,
                        date_join: data.data.setting?.date_join,
                        hour_working_on_day:
                            data.data.setting?.hour_working_on_day,
                        permission_name: data.data.setting?.permission_name,
                        salary_by: data.data.setting?.salary_by,
                        salary_value: data.data.setting?.salary_value,
                        status_working: data.data.setting?.status_working,
                        working_hours: WorkingHours(
                            data.data.setting?.working_hours
                        ),

                        // disabled
                        current_commission: data.data.current_commission,
                        current_bonus: data.data.current_bonus,
                        current_overtime: data.data.current_overtime,
                        current_advence: data.data.current_advence,
                        current_fined: data.data.current_fined,
                        current_dayoff: data.data.current_dayoff,
                        current_day_working: data.data.current_day_working,
                        current_salary: data.data.current_salary,
                        total_day_working: data.data.total_day_working,
                        total_salary: data.data.total_salary,
                        pending_date: data.data.pending_date,
                        pending_hour: data.data.pending_hour,
                        lst_6_month: data.data.lst_6_month,
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onDownloadPdfUserStaffRender = ({ month, year }) => {
        let tokenStr = localStorage.getItem('access_token');
        this.setState(
            (prev) => ({
                ...prev,
                is_loading_data_detail: true,
            }),
            async () => {
                await axios
                    .get(
                        `${END_POINT}/user-staff?user_staff_id=${this.state.form.id}&month=${month}&year=${year}`,
                        { headers: { Authorization: `Bearer ${tokenStr}` } }
                    )
                    .then((res) => res.data)
                    .then((data) => {
                        const link = data.data;

                        if (link) {
                            window.open(getAvatar(link), '_target');
                        }
                    })
                    .catch((err) => {
                        NotificatioErrController(
                            err.response.data.responseText
                        );
                        return false;
                    });

                this.setState((prev) => ({
                    ...prev,
                    is_loading_data_detail: false,
                }));
            }
        );
    };

    // dataAllRender = () => {
    //     let tokenStr = localStorage.getItem('access_token');
    //     axios
    //         .get(`${END_POINT + '/user-staff'}`, {
    //             headers: { Authorization: `Bearer ${tokenStr}` },
    //         })
    //         .then((res) => res.data)
    //         .then((data) => {
    //             this.setState((prev) => ({
    //                 ...prev,
    //                 data_all: data.data,
    //             }));
    //         })
    //         .catch((err) => {
    //             NotificatioErrController(err.response.data.responseText);
    //             return false;
    //         });
    // };

    onHandlerDelete = () => {
        if (!isD(ROLE_ALIAS)) return false;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .delete(`${END_POINT + '/user-staff'}`, {
                data: {
                    arrayId: this.state.selectedItems,
                },
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['user-staff-delete'],
                });

                this.dataListRender();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerUpdate = (e) => {
        if (!isU(ROLE_ALIAS)) return false;
        e.preventDefault();

        const {
            id,
            name,
            phone,
            email,
            address,
            permission_name,
            date_join,
            status_working,
            dynamic_form,
        } = this.state.form;

        if (!name || !id) {
            NotificatioErrController('Dữ liệu không hợp lệ');
            return;
        }

        // Cập nhật dữ liệu gửi lên
        const update_form = FindUpdateForm({
            _new: this.state.form,
            find_user_staff: this.state.data_detail,
        });

        let tokenStr = localStorage.getItem('access_token');
        axios
            .put(
                `${END_POINT + '/user-staff'}`,
                {
                    id,
                    name,
                    phone,
                    email,
                    address,
                    permission_name,
                    status_working,
                    date_join: getDateWithFormat(date_join),

                    date_get_salary: update_form.date_get_salary,
                    salary_by: update_form.salary_by,
                    salary_value: update_form.salary_value,

                    hour_working_on_day: update_form.hour_working_on_day,
                    working_hours: update_form.working_hours,
                    dynamic_form,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['user-staff-update'],
                });

                this.dataListRender();
                this.toggle('update');
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerAdd = (e) => {
        if (!isC(ROLE_ALIAS)) return false;

        e.preventDefault();

        const {
            name,
            phone,
            email,
            address,
            permission_name,
            date_join,
            date_get_salary,
            salary_by,
            salary_value,
            working_hours,
            hour_working_on_day,
            status_working,
            dynamic_form,
        } = this.state.form;

        if (!name) {
            NotificatioErrController('Dữ liệu không hợp lệ');
            return;
        }

        let _salary_value = salary_value.replace(/\./g, '');

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/user-staff'}`,
                {
                    name,
                    phone,
                    email,
                    address,
                    permission_name,
                    date_get_salary,
                    salary_by,
                    salary_value: _salary_value,
                    hour_working_on_day,
                    status_working,
                    date_join: getDateWithFormat(date_join),
                    working_hours: GetWorkingHours(working_hours),
                    dynamic_form,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['user-staff-create'],
                });

                this.dataListRender();
                // this.toggle('add');
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerAddLog = (e) => {
        if (!isU(ROLE_ALIAS)) return false;
        e.preventDefault();

        let {
            logs_users_staff,
            logs_type,
            logs_value,
            logs_value_overtime_coefficient,
        } = this.state.form;

        if (!logs_type || !logs_value) {
            NotificatioErrController('Dữ liệu không hợp lệ');
            return;
        }

        if (!logs_users_staff.length) {
            logs_users_staff = this.state.data_all;
        }

        let logs_form = {};
        logs_form.users_staff_id = logs_users_staff.map((user) => user.id);
        logs_form[logs_type] = logs_value.toString().replace(/\./g, '');

        if (logs_type === 'overtime_hour') {
            logs_form.overtime_coefficient = logs_value_overtime_coefficient;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(`${END_POINT + '/user-staff/log'}`, logs_form, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['user-staff-create-log'],
                });

                this.dataListRender();
                this.toggle('log');
                this.setState((prev) => ({
                    ...prev,
                    form: {
                        ...prev.form,
                        form: DEFAULT_FORM,
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };
    /** Axios func helper */

    /** State func helper */
    toggle = (name) => {
        if (name === 'add' && !isC(ROLE_ALIAS)) return false;
        if (name === 'update' && !isU(ROLE_ALIAS)) return false;
        if (name === 'log' && !isU(ROLE_ALIAS)) return false;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                update: name === 'add' ? false : prev.show.update,
                [name]: !prev.show[name],
            },
            selectedItems: name === 'add' ? [] : prev.selectedItems,
            form: name === 'add' ? DEFAULT_FORM : prev.form,
        }));
    };

    onChangeForm = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [name]: value,
                },
            }));
        } else {
            let { value } = e;
            if (_name === 'date_join') {
                value = e;
            } else if (_name === 'logs_users_staff') {
                value = e;
            }

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [_name]: value,
                },
            }));
        }
    };

    onChangeDynamicForm = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    dynamic_form: {
                        ...prev.form.dynamic_form,
                        [name]: value,
                    },
                },
            }));
        } else {
            let value = e.value ? e.value : e;

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    dynamic_form: {
                        ...prev.form.dynamic_form,
                        [_name]: value,
                    },
                },
            }));
        }
    };

    onChangeWorkingHours = (time, _name, hash) => {
        let find_time = this.state.form.working_hours.find(
            (time) => time.hash === hash
        );

        if (find_time) {
            if (_name === 'working_hours_from') {
                find_time.start = time.format('HH:mm');
            }
            if (_name === 'working_hours_to') {
                find_time.end = time.format('HH:mm');
            }
        }

        this.setState((prev) => ({
            ...prev,
            form: {
                ...prev.form,
                working_hours: this.state.form.working_hours,
            },
        }));
    };

    onAddWorkingHours = () => {
        let time_more = {
            hash: RandomString(5),
            start: '08:00',
            end: '12:00',
        };

        const add_more_working_hour = this.state.form.working_hours;
        add_more_working_hour.push(time_more);
        this.setState((prev) => ({
            ...prev,
            form: {
                ...prev.form,
                working_hours: add_more_working_hour,
            },
        }));
    };

    onRemoveWorkingHours = (hash) => {
        const remove_working_hour = this.state.form.working_hours.filter(
            (hour) => hour.hash !== hash
        );
        this.setState((prev) => ({
            ...prev,
            form: {
                ...prev.form,
                working_hours: remove_working_hour,
            },
        }));
    };

    onSubmitForm = (type) => {
        if (type === 'update') {
            this.onHandlerUpdate();
        } else if (type === 'add') {
            this.onHandlerAdd();
        }
    };

    onChangePage = (page) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    currentPage: page,
                },
            }),
            () => this.dataListRender()
        );
    };

    onSearchKey = (e) => {
        if (!e) return false;

        if (e.target) {
            let {
                target: { value },
            } = e;
            let v = value;
            if (value === undefined) {
                v = e.target.firstElementChild.value; // parent to child

                value = v.toLowerCase();
                this.setState(
                    (prev) => ({
                        ...prev,
                        page: {
                            ...prev.page,
                            search: value,
                        },
                    }),
                    () => this.dataListRender()
                );
            } else {
                value = value.toLowerCase();
                this.setState(
                    (prev) => ({
                        ...prev,
                        page: {
                            ...prev.page,
                            search: value,
                        },
                    }),
                    () => this.dataListRender()
                );
            }
        }

        if (e.key === 'Enter') {
            let value = e.target.value.toLowerCase();
            this.setState(
                (prev) => ({
                    ...prev,
                    page: {
                        ...prev.page,
                        search: value,
                    },
                }),
                () => this.dataListRender()
            );
        }
    };

    onChangePageSize = (size) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    selectedPageSize: size,
                    currentPage: 1,
                },
            }),
            () => this.dataListRender()
        );
    };

    onClickedItem = (item, callback, args) => {
        if (this.state.form.id !== item.id) {
            const { id, dynamic_form } = item;

            this.setState(
                (prev) => ({
                    ...prev,
                    is_loading_data_detail: true,
                    show: {
                        ...prev.show,
                        update: false,
                        view: true,
                        viewonly: false,
                    },
                    form: {
                        ...prev.form,
                        id,
                        dynamic_form,
                    },
                    selectedItems: [item.id],
                }),
                () => {
                    callback(args[0], args[1], args[2]);

                    this.dataUserStaffRender(id);
                }
            );
        }
    };

    onClickedUpdate = (item) => {
        const { id, dynamic_form } = item;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                viewonly: false,
                update: true,
            },
            form: {
                ...prev.form,
                id,
                dynamic_form,
            },
        }));
    };

    onClickedDelete = (item) => {
        let selectedSet = SetItemsSelected(
            item.id,
            this.state.selectedItems,
            'require'
        );
        if (window.confirm('Bạn chắc chắn muốn xoá nhân viên này?')) {
            this.setState(
                (prev) => ({
                    ...prev,
                    selectedItems: selectedSet,
                }),
                () => this.onHandlerDelete()
            );
        }
    };

    onClickedCheckbox = (id, callback) => {
        // let selectedSet = SetItemsSelected(id, this.state.selectedItems);

        this.setState((prev) => ({
            ...prev,
            selectedItems: [id],
        }));
    };

    toggleAccordion = (tab) => {
        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => (tab === index ? !x : false));
        this.setState({
            accordion: state,
        });
    };

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    filterData = (key, value) => {
        const { data_all, data } = this.state;

        if (key === '' || value === '') {
            this.setState((prev) => ({
                ...prev,
                data: data_all,
                filter: null,
            }));
        } else {
            const filteredItems = data_all.filter(
                (item) => item[key] === value
            );
            this.setState((prev) => ({
                ...prev,
                data: filteredItems,
                filter: { column: key, value: value },
            }));
        }
    };

    render() {
        const { match } = this.props;
        const {
            page,
            selectedItems,
            data,
            filter,
            data_all,
            is_loading_data,
            is_loading_data_detail,
            show,
            form,
            accordion,
            // Dynamic Form
            data_dynamic_form,
        } = this.state;

        if (!isR(ROLE_ALIAS))
            return (
                <Card className='text-muted mh-40vh h-100'>
                    <CardBody className='d-flex justify-content-center align-items-center'>
                        Bạn không có quyền sử dụng tính năng này!
                    </CardBody>
                </Card>
            );

        return is_loading_data ? (
            <div className='loading' />
        ) : (
            <div className='app-row disable-text-selection'>
                <PageHeading
                    data={data}
                    heading='analytics.user-staff'
                    setting={page}
                    match={match}
                    totalItem={page.totalItem}
                    isDisabledAdd={!isC(ROLE_ALIAS)}
                    isDisabledUpdate={!isU(ROLE_ALIAS)}
                    toggleFunc={() => {
                        this.toggle('add');
                        // this.hideDisplayContent();
                        this.toggleAccordion();
                    }}
                    toggleLogFunc={() => {
                        this.toggle('log');
                    }}
                    changePageSizeFunc={this.onChangePageSize}
                    onSearchKeyFunc={this.onSearchKey}
                />
                <AddUserStaffLog
                    title={
                        <IntlMessages id='user-staff.add-new-logs-modal-title' />
                    }
                    isOpen={show.log}
                    toggle={() => {
                        this.toggle('log');
                    }}
                    values={form}
                    data_all={data_all}
                    setting={show}
                    onChange={this.onChangeForm}
                    onSubmit={this.onHandlerAddLog}
                />
                <AddUserStaff
                    title={<IntlMessages id='user-staff.add-new-modal-title' />}
                    isOpen={show.add}
                    toggle={() => {
                        this.toggle('add');
                    }}
                    data_dynamic_form={data_dynamic_form}
                    values={form}
                    setting={show}
                    onChange={this.onChangeForm}
                    onChangeWorkingHours={this.onChangeWorkingHours}
                    onAddWorkingHours={this.onAddWorkingHours}
                    onRemoveWorkingHours={this.onRemoveWorkingHours}
                    onChangeDynamicForm={this.onChangeDynamicForm}
                    onSubmit={this.onHandlerAdd}
                ></AddUserStaff>
                <UpdateUserStaff
                    title={<IntlMessages id='user-staff.title' />}
                    onSubmit={this.onHandlerUpdate}
                    isOpen={show.update}
                    toggle={() => {
                        this.toggle('update');
                    }}
                    data_dynamic_form={data_dynamic_form}
                    values={form}
                    setting={show}
                    onChange={this.onChangeForm}
                    onChangeWorkingHours={this.onChangeWorkingHours}
                    onAddWorkingHours={this.onAddWorkingHours}
                    onRemoveWorkingHours={this.onRemoveWorkingHours}
                    onChangeDynamicForm={this.onChangeDynamicForm}
                />
                {data.length === 0 ? (
                    <Card className='mt-4 mb-4 mh-53vh'>
                        <CardBody>Chưa có dữ liệu...</CardBody>
                    </Card>
                ) : (
                    <>
                        <Nav
                            tabs
                            className='d-flex justify-content-between separator-tabs ml-0 mb-5'
                        >
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: this.state.activeTab === '1',
                                        'nav-link': true,
                                    })}
                                    onClick={() => this.toggleTab('1')}
                                    location={{}}
                                    to='#'
                                >
                                    <IntlMessages id='general.info' />
                                </NavLink>
                            </NavItem>
                            {/* <NavItem>
                        <NavLink
                            className={classnames({
                                active: this.state.activeTab === '2',
                                'nav-link': true,
                            })}
                            onClick={() => this.toggleTab('2')}
                            location={{}}
                            to='#'
                        >
                            <span className='mr-2'>
                                <i className='simple-icon-settings'></i>
                            </span>
                            <IntlMessages id='general.setting' />
                        </NavLink>
                    </NavItem> */}
                        </Nav>
                        <TabContent
                            activeTab={this.state.activeTab}
                            className='mh-53vh'
                        >
                            <TabPane tabId='1'>
                                <Row className='content-page__row d-none d-md-flex'>
                                    <Col
                                        xs='12'
                                        sm='12'
                                        md='12'
                                        lg={show.view ? 6 : 12}
                                        xl={show.view ? 6 : 12}
                                    >
                                        {data?.map((item, idx) => (
                                            <ListItemLeftForm
                                                onClickedItem={
                                                    this.onClickedItem
                                                }
                                                onUpdate={this.onClickedUpdate}
                                                onDelete={this.onClickedDelete}
                                                onChangeCheckbox={
                                                    this.onClickedCheckbox
                                                }
                                                isDisabledUpdate={
                                                    !isU(ROLE_ALIAS)
                                                }
                                                isDisabledDelete={
                                                    !isD(ROLE_ALIAS)
                                                }
                                                selectedItems={selectedItems}
                                                key={idx}
                                                idx={idx}
                                                item={item}
                                                setting={show}
                                            />
                                        ))}
                                        <Pagination
                                            currentPage={page.currentPage}
                                            totalPage={page.totalPage}
                                            onChangePage={(i) =>
                                                this.onChangePage(i)
                                            }
                                        />
                                    </Col>
                                    {show.view ? (
                                        <Col
                                            xs='12'
                                            sm='12'
                                            md='12'
                                            lg='6'
                                            xl='6'
                                        >
                                            <ContentDisplay
                                                // data_dynamic_form={data_dynamic_form}
                                                values={form}
                                                is_loading_data_detail={
                                                    is_loading_data_detail
                                                }
                                                setting={show}
                                                data={form.lst_6_month}
                                                onClickDownloadSalary={
                                                    this
                                                        .onDownloadPdfUserStaffRender
                                                }
                                            />
                                        </Col>
                                    ) : null}
                                </Row>
                                <Row className='content-page__row d-block d-md-none mb-4'>
                                    <Col>
                                        {data?.map((item, idx) => {
                                            return (
                                                <div
                                                    className='d-flex mb-3 card'
                                                    key={item.id}
                                                >
                                                    <ListItemFormMobile
                                                        onClickedItem={
                                                            this.onClickedItem
                                                        }
                                                        onUpdate={
                                                            this.onClickedUpdate
                                                        }
                                                        onDelete={
                                                            this.onClickedDelete
                                                        }
                                                        onChangeCheckbox={
                                                            this
                                                                .onClickedCheckbox
                                                        }
                                                        isDisabledUpdate={
                                                            !isU(ROLE_ALIAS)
                                                        }
                                                        isDisabledDelete={
                                                            !isD(ROLE_ALIAS)
                                                        }
                                                        selectedItems={
                                                            selectedItems
                                                        }
                                                        key={idx}
                                                        idx={idx}
                                                        item={item}
                                                        form={form}
                                                        accordion={accordion}
                                                        toggleAccordion={
                                                            this.toggleAccordion
                                                        }
                                                        is_loading_data_detail={
                                                            is_loading_data_detail
                                                        }
                                                    />
                                                </div>
                                            );
                                        })}
                                        <Pagination
                                            currentPage={page.currentPage}
                                            totalPage={page.totalPage}
                                            onChangePage={(i) =>
                                                this.onChangePage(i)
                                            }
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                            {/* <TabPane tabId='2'>
                        <Setting />
                    </TabPane> */}
                        </TabContent>
                    </>
                )}
                <UserStaffApplicationMenu
                    data={data_all}
                    filter={filter}
                    filterData={(key, value) => this.filterData(key, value)}
                />
                <Separator />
                <FooterPage>
                    <FooterUserStaff />
                </FooterPage>
            </div>
        );
    }
}

export default ContentComponent;
