import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import Pagination from '../../../components/DatatablePagination';
import { ComponentTitle, ComponentBody } from './Common';
import { AddCommaNumber } from '../../../helpers/Utils';

export default (props) => {
    const [state, setState] = useState({
        columns: [],
    });

    useEffect(() => {
        setState({
            ...state,
            columns: [
                {
                    Header: (
                        <div style={{ textAlign: 'center' }}>{props.title}</div>
                    ),
                    columns: [
                        {
                            Header: 'STT',
                            accessor: 'stt',
                            maxWidth: 50,
                        },
                        {
                            Header: 'Tên',
                            accessor: 'label',
                            minWidth: 160,
                        },
                        {
                            Header: (
                                <div style={{ textAlign: 'center' }}>
                                    {' '}
                                    {'Số lượng'}
                                </div>
                            ),
                            accessor: 'count',
                            Cell: (_props) => {
                                // console.log(_props);
                                const value =
                                    _props.value + ' ' + _props.original.unit;
                                return (
                                    <ComponentBody
                                        compare={props.setting.compare}
                                        all={props.setting.all}
                                        type='text'
                                        align='center'
                                        value={value}
                                        compare_value={
                                            _props.original.compare_count
                                        }
                                    />
                                );
                            },
                        },
                        // {
                        //     Header: (
                        //         <div style={{ textAlign: 'center' }}>
                        //             {' '}
                        //             {'Đơn giá'}
                        //         </div>
                        //     ),
                        //     minWidth: 160,
                        //     accessor: 'label',
                        //     Cell: (_props) => {
                        //         console.log(_props);
                        //         const value =
                        //             _props.original.price +
                        //             '/' +
                        //             _props.original.unit;
                        //         return (
                        //             <ComponentBody
                        //                 compare={props.setting.compare}
                        //                 all={props.setting.all}
                        //                 type='text'
                        //                 align='center'
                        //                 value={value}
                        //                 compare_value=''
                        //             />
                        //         );
                        //     },
                        // },
                        {
                            Header: (
                                <ComponentTitle
                                    compare={props.setting.compare}
                                    title='Tổng'
                                    value={AddCommaNumber(props.total_price)}
                                    align='end'
                                />
                            ),
                            minWidth: 160,
                            accessor: 'price',
                            sortMethod: (a, b) => Number(a) - Number(b),
                            Cell: (_props) => (
                                <ComponentBody
                                    compare={props.setting.compare}
                                    all={props.setting.all}
                                    type='price'
                                    align='end'
                                    value={_props.value}
                                    compare_value={
                                        _props.original.compare_price
                                    }
                                />
                            ),
                        },
                    ],
                },
            ],
        });
    }, [props.data, props.setting.compare, props.setting.all]);

    return (
        <ReactTable
            defaultPageSize={6}
            data={props.data}
            columns={state.columns}
            minRows={0}
            showPageJump={false}
            showPageSizeOptions={true}
            PaginationComponent={Pagination}
            NoDataComponent={() => null}
        />
    );
};
