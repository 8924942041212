import moment from 'moment';
import React from 'react';
import { Badge, Card, CardBody, CardTitle, Label, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';

const ContentDisplay = (props) => {
    // console.log(props);
    return (
        <Card className='position-sticky-custom mb-4'>
            <CardBody>
                <CardTitle>
                    <IntlMessages id='todo.thumb-list' />
                </CardTitle>
                <Row className='mt-3 '>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='todo.title' />
                        </p>
                        <p className='mb-3'>{props.values.title}</p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='todo.description' />
                        </p>
                        <p className='mb-3'>
                            {props.values.description ? (
                                props.values.description
                            ) : (
                                <span className='text-lowercase'>
                                    (không có nội dung mô tả)
                                </span>
                            )}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='todo.date-time' />
                        </p>
                        <p className='mb-3 text-lowercase'>
                            {props.values.time + ' / ' + props.values.date}
                        </p>
                    </Colxx>
                </Row>
            </CardBody>
        </Card>
    );
};

export default ContentDisplay;
