import React, { Component } from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    UncontrolledDropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';
import IntlMessages from '../../helpers/IntlMessages';
import { LineChart, BarChart } from '../../components/charts';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
// import { PASSWORD_DOWNLOAD_REPORT } from '../../constants/defaultValues'
import { isRorRBD } from '../../helpers/Roles';
import classnames from 'classnames';

const formatDatasExport = (datas) => {
    const { report } = datas;
    let custs = [];

    Object.keys(report).forEach((key) => {
        if (key === 'dates') {
            for (let i = 0; i < report[key].length; i++) {
                custs.push({
                    'Thời gian': report[key][i],
                    'Doanh thu': report['income'][i],
                    'Chi phí': report['outcome'][i],
                });
            }
        }
    });

    return custs;
};

const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const exportToCSV = ({ csvData, fileName }) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};

class SalesChartCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
        };

        this.handleDownloadData = this.handleDownloadData.bind(this);
    }

    componentWillMount() {
        this.setState({ isLoading: true });
    }

    handleDownloadData() {
        const fileName = 'Báo cáo doanh thu và chi phí theo giờ';

        exportToCSV({
            csvData: formatDatasExport(this.props.lineChartData),
            fileName,
        });
    }

    render() {
        let { isLoading } = this.state;

        let { lineChartData, license } = this.props;
        // console.log('lineChartData: ', lineChartData)

        return !isLoading ? (
            <div className='loading' />
        ) : (
            <Card
                className={classnames('dashboard-list-with-thumbs-lg', {
                    disable: !isRorRBD('analytics_charts'),
                })}
            >
                <div className='position-absolute card-top-buttons custom-inline'>
                    {license && (
                        <UncontrolledDropdown>
                            <DropdownToggle
                                color=''
                                className='btn btn-header-light icon-button'
                            >
                                <span className='simple-icon-options' />
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={this.handleDownloadData}>
                                    <IntlMessages id='order.to-excel' />
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    )}
                </div>
                <CardBody>
                    <CardTitle>
                        <IntlMessages id='dashboards.sales-chart' />
                    </CardTitle>
                    <div className='dashboard-line-chart'>
                        <LineChart shadow data={lineChartData} />
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default SalesChartCard;
