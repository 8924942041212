import React from 'react';
import {
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
    Label,
    Form,
    FormGroup,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import Select from 'react-select';
import CustomSelectInput from '../../../components/common/CustomSelectInput';
import { SetType, GetType } from '../../../helpers/Companies';

const ContentComponent = (props) => {
    return (
        <Form>
            {props.setting.add ? (
                <FormGroup>
                    <Label for='search'>
                        <IntlMessages id='companies.find-company-uuid' />
                    </Label>

                    <InputGroup>
                        <Input
                            type='text'
                            name='search'
                            id='search'
                            value={props.values.search}
                            onChange={props.onChange}
                        />
                        <InputGroupAddon addonType='append'>
                            <Button onClick={props.onSearch}>
                                <IntlMessages id='menu.search' />
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                </FormGroup>
            ) : null}

            <FormGroup>
                <Label for='uuid'>
                    <IntlMessages id='company-uuid' />
                </Label>
                <Input
                    type='text'
                    name='uuid'
                    id='uuid'
                    disabled={true}
                    value={props.values.uuid}
                    onChange={() => {}}
                />
            </FormGroup>
            <FormGroup>
                <Label for='name'>
                    <IntlMessages id='company-name' />
                </Label>
                <Input
                    type='text'
                    name='name'
                    id='name'
                    disabled={true}
                    value={props.values.name}
                    onChange={() => {}}
                />
            </FormGroup>
            {props.setting.update ? (
                <FormGroup>
                    <Label for='namelink'>
                        <IntlMessages id='companies.name' />
                    </Label>
                    <Input
                        type='text'
                        name='namelink'
                        id='namelink'
                        disabled={true}
                        value={props.values.namelink}
                        onChange={() => {}}
                    />
                </FormGroup>
            ) : null}

            <FormGroup>
                <Label for='type'>
                    <IntlMessages id='companies.type' />
                </Label>
                <Select
                    components={{ Input: CustomSelectInput }}
                    className='react-select'
                    classNamePrefix='react-select'
                    name='type'
                    placeholder={<IntlMessages id='companies.unset' />}
                    options={SetType}
                    onChange={(e) => {
                        props.onChange(e, 'type');
                    }}
                    value={GetType(props.values.type)}
                />
            </FormGroup>
        </Form>
    );
};

export default ContentComponent;
