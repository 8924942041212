import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { Nav, NavItem, Collapse, Tooltip } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from 'react-router-dom';

import IntlMessages from '../../helpers/IntlMessages';

import {
    setContainerClassnames,
    addContainerClassname,
    changeDefaultClassnames,
    changeSelectedMenuHasSubItems,
} from '../../redux/actions';

import menuItems from '../../constants/menu';
import menurightItems from '../../constants/menu-right';
import { ScrollDetect } from '../../helpers/Utils';
import { END_POINT, END_POINT_SOCKET } from '../../constants/defaultValues';
import io from 'socket.io-client';
import axios from 'axios';

const ROLESETTINGS = localStorage.getItem('roleSettings')
    ? JSON.parse(localStorage.getItem('roleSettings'))
    : null;

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedParentMenu: '',
            viewingParentMenu: '',
            collapsedMenus: [],
            freePackage: false,
            proPackage: false,
            un_seen_mess: null,
        };
        this.socket = io(END_POINT_SOCKET);
    }

    handleWindowResize = (event) => {
        if (event && !event.isTrusted) {
            return;
        }
        const { containerClassnames } = this.props;
        let nextClasses = this.getMenuClassesForResize(containerClassnames);
        this.props.setContainerClassnames(
            0,
            nextClasses.join(' '),
            this.props.selectedMenuHasSubItems
        );
    };

    handleDocumentClick = (e) => {
        const container = this.getContainer();
        let isMenuClick = false;
        if (
            e.target &&
            e.target.classList &&
            (e.target.classList.contains('menu-button') ||
                e.target.classList.contains('menu-button-mobile'))
        ) {
            isMenuClick = true;
        } else if (
            e.target.parentElement &&
            e.target.parentElement.classList &&
            (e.target.parentElement.classList.contains('menu-button') ||
                e.target.parentElement.classList.contains('menu-button-mobile'))
        ) {
            isMenuClick = true;
        } else if (
            e.target.parentElement &&
            e.target.parentElement.parentElement &&
            e.target.parentElement.parentElement.classList &&
            (e.target.parentElement.parentElement.classList.contains(
                'menu-button'
            ) ||
                e.target.parentElement.parentElement.classList.contains(
                    'menu-button-mobile'
                ))
        ) {
            isMenuClick = true;
        }
        if (
            container.contains(e.target) ||
            container === e.target ||
            isMenuClick
        ) {
            return;
        }
        this.setState({
            viewingParentMenu: '',
        });
        this.toggle();
    };

    getMenuClassesForResize = (classes) => {
        const { menuHiddenBreakpoint, subHiddenBreakpoint } = this.props;
        let nextClasses = classes.split(' ').filter((x) => x !== '');
        const windowWidth = window.innerWidth;
        if (windowWidth < menuHiddenBreakpoint) {
            nextClasses.push('menu-mobile');
        } else if (windowWidth < subHiddenBreakpoint) {
            nextClasses = nextClasses.filter((x) => x !== 'menu-mobile');
            if (
                nextClasses.includes('menu-default') &&
                !nextClasses.includes('menu-sub-hidden')
            ) {
                nextClasses.push('menu-sub-hidden');
            }
        } else {
            nextClasses = nextClasses.filter((x) => x !== 'menu-mobile');
            if (
                nextClasses.includes('menu-default') &&
                nextClasses.includes('menu-sub-hidden')
            ) {
                nextClasses = nextClasses.filter(
                    (x) => x !== 'menu-sub-hidden'
                );
            }
        }
        return nextClasses;
    };

    getContainer = () => {
        return ReactDOM.findDOMNode(this);
    };

    toggle = () => {
        const hasSubItems = this.getIsHasSubItem();
        this.props.changeSelectedMenuHasSubItems(hasSubItems);
        const { containerClassnames, menuClickCount } = this.props;
        const currentClasses = containerClassnames
            ? containerClassnames.split(' ').filter((x) => x !== '')
            : '';
        let clickIndex = -1;

        if (!hasSubItems) {
            if (
                currentClasses.includes('menu-default') &&
                (menuClickCount % 4 === 0 || menuClickCount % 4 === 3)
            ) {
                clickIndex = 1;
            } else if (
                currentClasses.includes('menu-sub-hidden') &&
                (menuClickCount === 2 || menuClickCount === 3)
            ) {
                clickIndex = 0;
            } else if (
                currentClasses.includes('menu-hidden') ||
                currentClasses.includes('menu-mobile')
            ) {
                clickIndex = 0;
            }
        } else {
            if (
                currentClasses.includes('menu-sub-hidden') &&
                menuClickCount === 3
            ) {
                clickIndex = 2;
            } else if (
                currentClasses.includes('menu-hidden') ||
                currentClasses.includes('menu-mobile')
            ) {
                clickIndex = 0;
            }
        }
        if (clickIndex >= 0) {
            if (clickIndex > 90) {
                this.props.setContainerClassnames(
                    0,
                    containerClassnames,
                    hasSubItems
                );
            } else {
                this.props.setContainerClassnames(
                    clickIndex,
                    containerClassnames,
                    hasSubItems
                );
            }
        }
    };

    handleProps = () => {
        this.addEvents();
    };

    addEvents = () => {
        ['click', 'touchstart', 'touchend'].forEach((event) =>
            document.addEventListener(event, this.handleDocumentClick, true)
        );
    };

    removeEvents = () => {
        ['click', 'touchstart', 'touchend'].forEach((event) =>
            document.removeEventListener(event, this.handleDocumentClick, true)
        );
    };

    setSelectedLiActive = (callback) => {
        const oldli = document.querySelector('.sub-menu  li.active');
        if (oldli != null) {
            oldli.classList.remove('active');
        }

        const oldliSub = document.querySelector('.third-level-menu  li.active');
        if (oldliSub != null) {
            oldliSub.classList.remove('active');
        }

        /* set selected parent menu */
        const selectedSublink = document.querySelector(
            '.third-level-menu  a.active'
        );
        if (selectedSublink != null) {
            selectedSublink.parentElement.classList.add('active');
        }

        const selectedlink = document.querySelector('.sub-menu  a.active');
        if (selectedlink != null) {
            selectedlink.parentElement.classList.add('active');
            this.setState(
                {
                    selectedParentMenu:
                        selectedlink.parentElement.parentElement.getAttribute(
                            'data-parent'
                        ),
                },
                callback
            );
        } else {
            const selectedParentNoSubItem = document.querySelector(
                '.main-menu  li a.active'
            );
            if (selectedParentNoSubItem != null) {
                this.setState(
                    {
                        selectedParentMenu:
                            selectedParentNoSubItem.getAttribute('data-flag'),
                    },
                    callback
                );
            } else if (this.state.selectedParentMenu === '') {
                this.setState(
                    {
                        selectedParentMenu: menuItems[0].id,
                    },
                    callback
                );
            }
        }
    };

    setHasSubItemStatus = () => {
        const hasSubmenu = this.getIsHasSubItem();
        this.props.changeSelectedMenuHasSubItems(hasSubmenu);
        this.toggle();
    };

    getIsHasSubItem = () => {
        const { selectedParentMenu } = this.state;
        const menuItem = menuItems.find((x) => x.id === selectedParentMenu);
        if (menuItem)
            return menuItem && menuItem.subs && menuItem.subs.length > 0
                ? true
                : false;
        else return false;
    };

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setSelectedLiActive(this.setHasSubItemStatus);

            window.scrollTo(0, 0);
        }
        this.handleProps();
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();
        this.handleProps();
        this.setSelectedLiActive(this.setHasSubItemStatus);

        ScrollDetect();
        this.getExpired();

        this.socket.emit('join room', {
            room_id: localStorage.getItem('b'),
        });

        this.socket.emit('refresh-alert-data', {
            user_id: localStorage.getItem('b'),
        });

        this.socket.on('refresh-alert-data', (res) => {
            const { un_seen_mess } = res;

            this.setState({
                un_seen_mess: un_seen_mess,
            });
        });
    }

    componentWillUnmount() {
        this.removeEvents();
        window.removeEventListener('resize', this.handleWindowResize);
    }

    openSubMenu = (e, menuItem) => {
        const selectedParent = menuItem.id;
        const hasSubMenu = menuItem.subs && menuItem.subs.length > 0;
        this.props.changeSelectedMenuHasSubItems(hasSubMenu);
        if (!hasSubMenu) {
            this.setState({
                viewingParentMenu: selectedParent,
                selectedParentMenu: selectedParent,
            });
            this.toggle();
        } else {
            e.preventDefault();

            const { containerClassnames, menuClickCount } = this.props;
            const currentClasses = containerClassnames
                ? containerClassnames.split(' ').filter((x) => x !== '')
                : '';

            if (!currentClasses.includes('menu-mobile')) {
                if (
                    currentClasses.includes('menu-sub-hidden') &&
                    (menuClickCount === 2 || menuClickCount === 0)
                ) {
                    this.props.setContainerClassnames(
                        3,
                        containerClassnames,
                        hasSubMenu
                    );
                } else if (
                    currentClasses.includes('menu-hidden') &&
                    (menuClickCount === 1 || menuClickCount === 3)
                ) {
                    this.props.setContainerClassnames(
                        2,
                        containerClassnames,
                        hasSubMenu
                    );
                } else if (
                    currentClasses.includes('menu-default') &&
                    !currentClasses.includes('menu-sub-hidden') &&
                    (menuClickCount === 1 || menuClickCount === 3)
                ) {
                    this.props.setContainerClassnames(
                        0,
                        containerClassnames,
                        hasSubMenu
                    );
                } else {
                    this.props.setContainerClassnames(
                        0,
                        containerClassnames,
                        hasSubMenu
                    );
                }
            } else {
                this.props.addContainerClassname(
                    'sub-show-temporary',
                    containerClassnames
                );
            }
            this.setState({
                viewingParentMenu: selectedParent,
            });
        }
    };

    toggleMenuCollapse = (e, menuKey) => {
        e.preventDefault();

        const { collapsedMenus } = this.state;
        if (collapsedMenus.indexOf(menuKey) > -1) {
            this.setState({
                collapsedMenus: collapsedMenus.filter((x) => x !== menuKey),
            });
        } else {
            collapsedMenus.push(menuKey);
            this.setState({
                collapsedMenus,
            });
        }
        return false;
    };

    filteredList = (menuItems) => {
        const { currentUser } = this.props;
        if (currentUser) {
            return menuItems.filter(
                (x) =>
                    (x.roles && x.roles.includes(currentUser.role)) || !x.roles
            );
        } else if (ROLESETTINGS) {
            return menuItems.filter((x) => !x.denied);
        } else {
            return menuItems;
        }
    };

    getExpired = () => {
        let tokenStr = localStorage.getItem('access_token');
        return axios
            .get(`${END_POINT + '/auth/get-expired'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => {
                this.setState((prevState) => ({
                    ...prevState,
                    freePackage: res.data.freePackage,
                    proPackage: res.data.proPackage,
                }));
            })
            .catch((err) => []);
    };

    render() {
        const {
            selectedParentMenu,
            viewingParentMenu,
            collapsedMenus,
            freePackage,
            proPackage,
            un_seen_mess,
        } = this.state;
        return (
            <>
                <div className='sidebar'>
                    <div className='main-menu'>
                        <div className='scroll'>
                            <PerfectScrollbar
                                options={{
                                    suppressScrollX: true,
                                    wheelPropagation: false,
                                }}
                            >
                                <Nav vertical className='list-unstyled'>
                                    {menuItems &&
                                        menuItems.map((item) => {
                                            return (
                                                <NavItem
                                                    key={item.id}
                                                    className={classnames({
                                                        active:
                                                            (selectedParentMenu ===
                                                                item.id &&
                                                                viewingParentMenu ===
                                                                    '') ||
                                                            viewingParentMenu ===
                                                                item.id,
                                                    })}
                                                >
                                                    {item.newWindow ? (
                                                        <a
                                                            href={item.to}
                                                            rel='noopener noreferrer'
                                                            target='_blank'
                                                        >
                                                            <i
                                                                className={
                                                                    item.icon
                                                                }
                                                            />{' '}
                                                            <IntlMessages
                                                                id={item.label}
                                                            />
                                                        </a>
                                                    ) : (
                                                        <NavLink
                                                            to={item.to}
                                                            onClick={(e) =>
                                                                this.openSubMenu(
                                                                    e,
                                                                    item
                                                                )
                                                            }
                                                            // onClick={(e) => {
                                                            //     if (
                                                            //         (freePackage ||
                                                            //             proPackage) &&
                                                            //         item.upgrade ===
                                                            //             'platinum'
                                                            //     ) {
                                                            //         e.preventDefault();
                                                            //     } else {
                                                            //         this.openSubMenu(
                                                            //             e,
                                                            //             item
                                                            //         );
                                                            //     }
                                                            // }}
                                                            data-flag={item.id}
                                                            className={classnames(
                                                                {
                                                                    opacity_03:
                                                                        (freePackage ||
                                                                            proPackage) &&
                                                                        item.upgrade ===
                                                                            'platinum',
                                                                },
                                                                'position-relative'
                                                            )}
                                                        >
                                                            <i
                                                                className={
                                                                    item.icon
                                                                }
                                                            />{' '}
                                                            {item.id ===
                                                                'message' &&
                                                            un_seen_mess !==
                                                                0 ? (
                                                                <span className='count_mess'>
                                                                    {
                                                                        un_seen_mess
                                                                    }
                                                                </span>
                                                            ) : null}
                                                            <section className='position-relative'>
                                                                <IntlMessages
                                                                    id={
                                                                        item.label
                                                                    }
                                                                />
                                                                {(freePackage ||
                                                                    proPackage) &&
                                                                item.upgrade ===
                                                                    'platinum' ? (
                                                                    <i className='simple-icon-diamond diamond-upgrade' />
                                                                ) : null}
                                                            </section>
                                                        </NavLink>
                                                    )}
                                                </NavItem>
                                            );
                                        })}
                                </Nav>
                            </PerfectScrollbar>
                        </div>
                    </div>

                    <div className='sub-menu'>
                        <div className='scroll'>
                            <PerfectScrollbar
                                options={{
                                    suppressScrollX: true,
                                    wheelPropagation: false,
                                }}
                            >
                                {menuItems &&
                                    this.filteredList(menuItems).map((item) => {
                                        return (
                                            <Nav
                                                key={item.id}
                                                className={classnames({
                                                    'd-block':
                                                        (this.state
                                                            .selectedParentMenu ===
                                                            item.id &&
                                                            this.state
                                                                .viewingParentMenu ===
                                                                '') ||
                                                        this.state
                                                            .viewingParentMenu ===
                                                            item.id,
                                                })}
                                                data-parent={item.id}
                                            >
                                                {item.subs &&
                                                    this.filteredList(
                                                        item.subs
                                                    ).map((sub, index) => {
                                                        return (
                                                            <NavItem
                                                                key={`${item.id}_${index}`}
                                                                className={`${
                                                                    sub.subs &&
                                                                    sub.subs
                                                                        .length >
                                                                        0
                                                                        ? 'has-sub-item'
                                                                        : ''
                                                                }`}
                                                            >
                                                                {sub.newWindow ? (
                                                                    <a
                                                                        href={
                                                                            sub.to
                                                                        }
                                                                        rel='noopener noreferrer'
                                                                        target='_blank'
                                                                    >
                                                                        <i
                                                                            className={
                                                                                sub.icon
                                                                            }
                                                                        />{' '}
                                                                        <IntlMessages
                                                                            id={
                                                                                sub.label
                                                                            }
                                                                        />
                                                                    </a>
                                                                ) : sub.subs &&
                                                                  sub.subs
                                                                      .length >
                                                                      0 ? (
                                                                    <>
                                                                        <NavLink
                                                                            className={`rotate-arrow-icon opacity-50 ${
                                                                                collapsedMenus.indexOf(
                                                                                    `${item.id}_${index}`
                                                                                ) ===
                                                                                -1
                                                                                    ? ''
                                                                                    : 'collapsed'
                                                                            }`}
                                                                            to={
                                                                                sub.to
                                                                            }
                                                                            id={`${item.id}_${index}`}
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                this.toggleMenuCollapse(
                                                                                    e,
                                                                                    `${item.id}_${index}`
                                                                                )
                                                                            }
                                                                        >
                                                                            <i className='simple-icon-arrow-down' />{' '}
                                                                            <IntlMessages
                                                                                id={
                                                                                    sub.label
                                                                                }
                                                                            />
                                                                        </NavLink>

                                                                        <Collapse
                                                                            isOpen={
                                                                                collapsedMenus.indexOf(
                                                                                    `${item.id}_${index}`
                                                                                ) ===
                                                                                -1
                                                                            }
                                                                        >
                                                                            <Nav className='third-level-menu'>
                                                                                {this.filteredList(
                                                                                    sub.subs
                                                                                ).map(
                                                                                    (
                                                                                        thirdSub,
                                                                                        thirdIndex
                                                                                    ) => {
                                                                                        return (
                                                                                            <NavItem
                                                                                                key={`${item.id}_${index}_${thirdIndex}`}
                                                                                            >
                                                                                                {thirdSub.newWindow ? (
                                                                                                    <a
                                                                                                        href={
                                                                                                            thirdSub.to
                                                                                                        }
                                                                                                        rel='noopener noreferrer'
                                                                                                        target='_blank'
                                                                                                    >
                                                                                                        <i
                                                                                                            className={
                                                                                                                thirdSub.icon
                                                                                                            }
                                                                                                        />{' '}
                                                                                                        <IntlMessages
                                                                                                            id={
                                                                                                                thirdSub.label
                                                                                                            }
                                                                                                        />
                                                                                                    </a>
                                                                                                ) : (
                                                                                                    <NavLink
                                                                                                        to={
                                                                                                            // (freePackage ||
                                                                                                            //     proPackage) &&
                                                                                                            // thirdSub.upgrade ===
                                                                                                            //     'platinum'
                                                                                                            //     ? '#'
                                                                                                            //     :
                                                                                                            thirdSub.to
                                                                                                        }
                                                                                                        className={classnames(
                                                                                                            {
                                                                                                                opacity_03:
                                                                                                                    (freePackage ||
                                                                                                                        proPackage) &&
                                                                                                                    thirdSub.upgrade ===
                                                                                                                        'platinum',
                                                                                                            },
                                                                                                            'd-flex align-items-center'
                                                                                                        )}
                                                                                                    >
                                                                                                        <i
                                                                                                            className={
                                                                                                                thirdSub.icon
                                                                                                            }
                                                                                                        />{' '}
                                                                                                        <section className='position-relative'>
                                                                                                            <IntlMessages
                                                                                                                id={
                                                                                                                    thirdSub.label
                                                                                                                }
                                                                                                            />
                                                                                                            {(freePackage ||
                                                                                                                proPackage) &&
                                                                                                            thirdSub.upgrade ===
                                                                                                                'platinum' ? (
                                                                                                                <i className='simple-icon-diamond diamond-upgrade-sub ' />
                                                                                                            ) : null}
                                                                                                        </section>
                                                                                                    </NavLink>
                                                                                                )}
                                                                                            </NavItem>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </Nav>
                                                                        </Collapse>
                                                                    </>
                                                                ) : (
                                                                    <NavLink
                                                                        to={
                                                                            (freePackage ||
                                                                                proPackage) &&
                                                                            sub.upgrade ===
                                                                                'platinum'
                                                                                ? '#'
                                                                                : sub.to
                                                                        }
                                                                        className={classnames(
                                                                            {
                                                                                opacity_03:
                                                                                    (freePackage ||
                                                                                        proPackage) &&
                                                                                    sub.upgrade ===
                                                                                        'platinum',
                                                                            },
                                                                            'd-flex align-items-center'
                                                                        )}
                                                                    >
                                                                        <i
                                                                            className={
                                                                                sub.icon
                                                                            }
                                                                        />{' '}
                                                                        <section className='position-relative'>
                                                                            <IntlMessages
                                                                                id={
                                                                                    sub.label
                                                                                }
                                                                            />
                                                                            {(freePackage ||
                                                                                proPackage) &&
                                                                            sub.upgrade ===
                                                                                'platinum' ? (
                                                                                <i className='simple-icon-diamond diamond-upgrade-sub' />
                                                                            ) : null}
                                                                        </section>
                                                                    </NavLink>
                                                                )}
                                                            </NavItem>
                                                        );
                                                    })}
                                            </Nav>
                                        );
                                    })}
                            </PerfectScrollbar>
                        </div>
                    </div>
                </div>

                <div className={'sidebar-right'}>
                    <div className='main-menu-right'>
                        <div className='scroll-right'>
                            <PerfectScrollbar
                                options={{
                                    suppressScrollX: true,
                                    wheelPropagation: false,
                                }}
                            >
                                <Nav vertical className='list-unstyled'>
                                    {menurightItems &&
                                        menurightItems.map((item) => {
                                            return (
                                                <NavItem
                                                    key={item.id}
                                                    className={classnames({
                                                        active:
                                                            (selectedParentMenu ===
                                                                item.id &&
                                                                viewingParentMenu ===
                                                                    '') ||
                                                            viewingParentMenu ===
                                                                item.id,
                                                    })}
                                                >
                                                    <NavLink
                                                        to={item.to}
                                                        onClick={(e) =>
                                                            this.openSubMenu(
                                                                e,
                                                                item
                                                            )
                                                        }
                                                        data-flag={item.id}
                                                    >
                                                        <i
                                                            className={
                                                                item.icon
                                                            }
                                                        />{' '}
                                                        <IntlMessages
                                                            id={item.label}
                                                        />
                                                    </NavLink>
                                                </NavItem>
                                            );
                                        })}
                                </Nav>
                            </PerfectScrollbar>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ menu }) => {
    const {
        containerClassnames,
        subHiddenBreakpoint,
        menuHiddenBreakpoint,
        menuClickCount,
        selectedMenuHasSubItems,
    } = menu;
    return {
        containerClassnames,
        subHiddenBreakpoint,
        menuHiddenBreakpoint,
        menuClickCount,
        selectedMenuHasSubItems,
    };
};
export default withRouter(
    connect(mapStateToProps, {
        setContainerClassnames,
        addContainerClassname,
        changeDefaultClassnames,
        changeSelectedMenuHasSubItems,
    })(Sidebar)
);
