import React, { Component } from 'react';
import {
    Row,
    Col,
    Nav,
    NavItem,
    TabContent,
    TabPane,
    Card,
    CardBody,
} from 'reactstrap';
import axios from 'axios';
import { END_POINT, END_POINT_SOCKET } from '../../../constants/defaultValues';
import {
    SetItemsSelected,
    NotificatioErrController,
    NotificatioSucController,
    getCurrentDate,
    getCurrentTime,
} from '../../../helpers/Utils';
import io from 'socket.io-client';
import {
    PageHeading,
    ContentFormCountUpdate,
    ListItemLeftForm,
    ContentDisplay,
    ListItemFormMobile,
    StockApplicationMenu,
} from '../../../containers/pages/stock';
import { Pagination } from '../../../containers/pages/general';
import LogsHelper from '../../../helpers/Logs';
import IntlMessages from '../../../helpers/IntlMessages';
import classnames from 'classnames';
import { isC, isR, isU, isD } from '../../../helpers/Roles';
import { NavLink } from 'react-router-dom';
import { Separator } from '../../../components/common/CustomBootstrap';
import FooterPage from '../../../containers/pages/FooterPage';
import FooterStock from '../../../containers/pages/stock/FooterStock';
import AddStock from '../../../containers/pages/stock/AddStock';
import AddMoreStock from '../../../containers/pages/stock/AddMoreStock';
import UpdateStock from '../../../containers/pages/stock/UpdateStock';
import ContentMultiForm from '../../../containers/pages/stock/ContentMultiForm';

const DEFAULT_FORM = {
    id: '',
    name: '',
    unit: '',
    unit_price: 0,
    new_count: 0,
    count: 0,
    avarage_price: '',
    total: 0,
    dynamic_form: [],
};

const ROLE_ALIAS = 'stock';

class ContentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: {
                pageSizes: [12, 24],
                selectedPageSize: 12,
                currentPage: 1,
                totalPage: 1,
                totalItem: 0,
                search: '',
            },
            selectedItems: [],
            data: [],
            data_all: [],
            license: {},
            data_length: 0,
            hide_button_add: false,

            data_stock: null,
            filter: null,

            data_dynamic_form: [],
            is_loading_data: true,
            show: {
                add: false,
                update: false,
                view: false,
                updateCount: false,
            },
            accordion: [],
            activeTab: '1',
            displayMode: 'thumblist',
            form: DEFAULT_FORM,
            sku: '',
        };

        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        this.dataListRender();
        this.dataAllRender();
        this.dataAllRenderSoftDel();
        this.getLicense();
    }

    getLicense = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/user-license'}`, {
                headers: {
                    Authorization: `Bearer ${tokenStr}`,
                },
            })
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                let user_license = data.data;

                let currentDate = getCurrentDate();
                let currentTime = getCurrentTime();

                if (
                    user_license.expired_date < currentDate &&
                    user_license.upgraded === false
                ) {
                    this.setState((prev) => ({
                        license: {
                            using: false,
                        },
                    }));
                }

                if (
                    (user_license.expired_date > currentDate ||
                        (user_license.expired_date === currentDate &&
                            user_license.expired_time >= currentTime)) &&
                    user_license.purchased_package === 'pro'
                ) {
                    this.setState((prev) => ({
                        license: {
                            using: true,
                            purchase_type: 'pro',
                        },
                    }));
                }
                this.hideButtonAdd();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    };

    hideButtonAdd = () => {
        let { license, data_length } = this.state;
        if (license.using === false && data_length > 10) {
            return this.setState({
                hide_button_add: true,
            });
        }
        if (
            license.using === true &&
            license.purchase_type === 'pro' &&
            data_length > 20
        ) {
            this.setState({
                hide_button_add: true,
            });
        }
    };

    /** Axios func helper */
    dataListRender = (required = true) => {
        const { selectedPageSize, currentPage, search } = this.state.page;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(
                `${
                    END_POINT + '/stock/json'
                }?pageSize=${selectedPageSize}&currentPage=${currentPage}&search=${search}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((data) => {
                // console.log(data);
                let search = window.location.search;
                let params = new URLSearchParams(search);
                let item_id = null;
                let item_vs_percent = params.get('item_id');
                if (item_vs_percent) {
                    const split_item_vs_percent = item_vs_percent.split('.');

                    if (split_item_vs_percent.length) {
                        item_id = split_item_vs_percent[0];

                        const find_item = data.data.find(
                            (_item) => _item.id == item_id
                        );
                        if (find_item !== undefined) {
                            this.onClickedUpdate(find_item);
                            this.onClickedUpdateCount(find_item);
                        }
                    }
                }

                let anyAccordion = [];
                let numeroPerguntas = data.data.length;
                for (var i = 0; i < numeroPerguntas; i++) {
                    // if (!i) {
                    //     anyAccordion.push(true);
                    // }
                    anyAccordion.push(false);
                }

                this.setState(
                    (prev) => ({
                        ...prev,
                        data: data.data,
                        data_dynamic_form: data.dataDynamicForm,
                        is_loading_data: false,
                        page: {
                            ...prev.page,
                            totalPage: data.totalPage,
                            totalItem: data.totalItem,
                        },
                        accordion: anyAccordion,
                    }),
                    () => {
                        if (required) {
                            if (this.state.form.id) {
                                const {
                                    id,
                                    name,
                                    sku,
                                    unit,
                                    avarage_price,
                                    count,
                                    total,
                                    dynamic_form,
                                } = this.state.form;
                                this.setState((prev) => ({
                                    ...prev,
                                    form: {
                                        ...prev.form,
                                        id,
                                        name,
                                        sku,
                                        unit,
                                        avarage_price,
                                        count,
                                        total,
                                        dynamic_form,
                                    },
                                }));
                            } else {
                                this.setState((prev) => ({
                                    ...prev,
                                    selectedItems: [],
                                    show: {
                                        ...prev.show,
                                        update: false,
                                    },
                                    form: DEFAULT_FORM,
                                }));
                            }
                        }
                    }
                );
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    dataAllRender = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/stock/all-unit'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                this.setState((prev) => ({
                    ...prev,
                    data_all: data.data,
                    data_length: data.data.length,
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    dataAllRenderSoftDel = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/stock/all-soft-del'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                // console.log('dataStockSoftDel: ', data.data);
                const getSKU = data.data.length + 1;
                this.setState((prev) => ({
                    ...prev,
                    sku: `NVL${getSKU}`,
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerDelete = () => {
        if (!isD(ROLE_ALIAS)) return false;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .delete(`${END_POINT + '/stock'}`, {
                data: {
                    arrayId: this.state.selectedItems,
                },
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['stock-delete'],
                });

                this.socket.emit('refresh-stock', {
                    user_id: localStorage.getItem('user_id'),
                });

                this.dataListRender();
                this.dataAllRender();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerUpdate = (e) => {
        if (!isU(ROLE_ALIAS)) return false;
        e.preventDefault();

        const { id, name, unit, dynamic_form } = this.state.form;

        if (!name || !id || !unit) {
            NotificatioErrController('Dữ liệu không hợp lệ');
            return;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .put(
                `${END_POINT + '/stock'}`,
                {
                    id,
                    name,
                    unit,
                    dynamic_form,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['stock-update'],
                });

                this.socket.emit('refresh-stock', {
                    user_id: localStorage.getItem('user_id'),
                });

                this.dataListRender();
                this.dataAllRender();
                this.toggle('update');
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerUpdateCount = (e) => {
        if (!isU(ROLE_ALIAS)) return false;
        e.preventDefault();

        const { id, name, new_count, unit_price } = this.state.form;

        if (!name || !id || !new_count || !unit_price) {
            NotificatioErrController('Dữ liệu không hợp lệ');
            return;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .put(
                `${END_POINT + '/stock/count'}`,
                {
                    id,
                    count: new_count,
                    unit_price: unit_price.toString().replace(/\./g, ''),
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['stock-add-more'],
                });

                this.socket.emit('refresh-stock', {
                    user_id: localStorage.getItem('user_id'),
                });

                this.socket.emit('refresh-alert-data', {
                    user_id: localStorage.getItem('user_id'),
                });

                if (data.status === 200 && data.data) {
                    this.setState(
                        (prev) => ({
                            ...prev,
                            form: {
                                ...prev.form,
                                count: data.data.count,
                                total: data.data.total,
                            },
                        }),
                        () => {
                            this.toggle('updateCount');
                            this.dataListRender(false);
                        }
                    );
                }
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerAdd = (e) => {
        if (!isC(ROLE_ALIAS)) return false;
        e.preventDefault();

        const { name, unit, new_count, unit_price, dynamic_form } =
            this.state.form;

        if (!name) {
            NotificatioErrController('Tên nguyên liệu cần được thêm vào');
            return;
        }
        if (!unit) {
            NotificatioErrController('Đơn vị tính cần được thêm vào');
            return;
        }
        if (!new_count) {
            NotificatioErrController('Đơn giá cần được thêm vào');
            return;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/stock'}`,
                {
                    name,
                    sku: this.state.sku,
                    unit,
                    count: new_count,
                    unit_price: unit_price.toString().replace(/\./g, ''),
                    dynamic_form,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['stock-create'],
                });

                this.socket.emit('refresh-stock', {
                    user_id: localStorage.getItem('user_id'),
                });

                // this.setState((prev) => ({
                //     ...prev,
                //     sku: '',
                // }));

                this.dataListRender();
                this.dataAllRender();
                this.dataAllRenderSoftDel();
                // this.toggle('add');
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerReset = async () => {
        if (!isU(ROLE_ALIAS)) return false;

        let id = this.state.selectedItems[0];

        // axios submit
        let tokenStr = localStorage.getItem('access_token');
        return axios
            .put(
                `${END_POINT + '/stock/reset'}`,
                {
                    id: id,
                },
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                NotificatioSucController('Reset nguyên liệu về 0 thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['stock-reset'],
                });

                this.socket.emit('refresh-stock', {
                    user_id: localStorage.getItem('user_id'),
                });

                this.dataListRender();
                this.dataAllRender();
                this.setState((prev) => ({
                    ...prev,
                    form: {
                        ...prev.form,
                        form: DEFAULT_FORM,
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    };
    /** Axios func helper */

    /** State func helper */
    toggle = (name) => {
        if (name === 'add' && !isC(ROLE_ALIAS)) return false;
        if (name === 'update' && !isU(ROLE_ALIAS)) return false;
        if (name === 'updateCount' && !isU(ROLE_ALIAS)) return false;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                update: name === 'add' ? false : prev.show.update,
                // update:
                //     name !== 'updateCount' ||
                //     name !== 'add' ||
                //     name !== 'update'
                //         ? false
                //         : prev.show.update,
                view: name === 'add' ? false : prev.show.view,
                [name]: !prev.show[name],
            },
            selectedItems: name === 'add' ? [] : prev.selectedItems,
            form: name === 'add' ? DEFAULT_FORM : prev.form,
        }));
    };

    onChangeForm = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [name]: value,
                },
            }));
        } else {
        }
    };

    onChangeDynamicForm = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    dynamic_form: {
                        ...prev.form.dynamic_form,
                        [name]: value,
                    },
                },
            }));
        } else {
            let value = e.value ? e.value : e;

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    dynamic_form: {
                        ...prev.form.dynamic_form,
                        [_name]: value,
                    },
                },
            }));
        }
    };

    onSubmitForm = (type) => {
        if (type === 'update') {
            this.onHandlerUpdate();
        } else if (type === 'add') {
            this.onHandlerAdd();
        }
    };

    onChangePage = (page) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    currentPage: page,
                },
            }),
            () => this.dataListRender()
        );
    };

    onSearchKey = (e) => {
        if (!e) return false;

        if (e.target) {
            let {
                target: { value },
            } = e;
            let v = value;
            if (value === undefined) {
                v = e.target.firstElementChild.value; // parent to child

                value = v.toLowerCase();
                this.setState(
                    (prev) => ({
                        ...prev,
                        page: {
                            ...prev.page,
                            search: value,
                        },
                    }),
                    () => this.dataListRender()
                );
            } else {
                value = value.toLowerCase();
                this.setState(
                    (prev) => ({
                        ...prev,
                        page: {
                            ...prev.page,
                            search: value,
                        },
                    }),
                    () => this.dataListRender()
                );
            }
        }

        if (e.key === 'Enter') {
            let value = e.target.value.toLowerCase();
            this.setState(
                (prev) => ({
                    ...prev,
                    page: {
                        ...prev.page,
                        search: value,
                    },
                }),
                () => this.dataListRender()
            );
        }
    };

    onChangePageSize = (size) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    selectedPageSize: size,
                    currentPage: 1,
                },
            }),
            () => this.dataListRender()
        );
    };

    onClickedItem = (item, callback, args) => {
        if (this.state.form.id !== item.id) {
            const { id, name, sku, unit, count, total, dynamic_form } = item;
            this.setState(
                (prev) => ({
                    ...prev,
                    show: {
                        ...prev.show,
                        update: false,
                        view: true,
                        viewonly: false,
                    },
                    form: {
                        ...prev.form,
                        id,
                        name,
                        unit,
                        count,
                        total,
                        dynamic_form,
                    },
                    sku,
                    selectedItems: [item.id],
                }),
                () => {
                    callback(args[0], args[1], args[2]);
                }
            );
        }
    };

    onClickedUpdate = (item) => {
        const { id, name, sku, unit, count, total, dynamic_form } = item;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                update: true,
                updateCount: false,
            },
            form: {
                ...prev.form,
                id,
                name,
                sku,
                unit,
                count,
                total,
                dynamic_form,
            },
        }));
    };

    onClickedUpdateCount = (item) => {
        const { id, name, sku } = item;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                updateCount: true,
                update: false,
            },
            form: {
                ...prev.form,
                id,
                name,
                sku,
                new_count: 0,
            },
        }));
    };

    onClickedDelete = (item) => {
        let selectedSet = SetItemsSelected(
            item.id,
            this.state.selectedItems,
            'require'
        );
        if (window.confirm('Bạn chắc chắn muốn xoá nguyên liệu này?')) {
            this.setState(
                (prev) => ({
                    ...prev,
                    selectedItems: selectedSet,
                }),
                () => this.onHandlerDelete()
            );
        }
    };

    onClickedReset = (item) => {
        let selectedSet = SetItemsSelected(
            item.id,
            this.state.selectedItems,
            'require'
        );
        if (
            window.confirm(
                'Bạn chắc chắn muốn reset tồn kho nguyên liệu này về 0?'
            )
        ) {
            this.setState(
                (prev) => ({
                    ...prev,
                    selectedItems: selectedSet,
                }),
                () => this.onHandlerReset()
            );
        }
    };

    onClickedCheckbox = (id, callback) => {
        // let selectedSet = SetItemsSelected(id, this.state.selectedItems);

        this.setState((prev) => ({
            ...prev,
            selectedItems: [id],
        }));
    };
    /** State func helper */

    toggleAccordion = (tab) => {
        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => (tab === index ? !x : false));
        this.setState({
            accordion: state,
        });
    };

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    filterData = (key, value) => {
        const { data_all } = this.state;
        if (key === '' && value === '') {
            this.setState((prev) => ({
                ...prev,
                data: data_all,
                filter: null,
            }));
        } else if (key === 'less_than_50') {
            const filteredStock = data_all.filter(
                (item) => (item.count / item.total) * 100 <= 50
            );
            // console.log(filteredStock);
            this.setState((prev) => ({
                ...prev,
                data: filteredStock,
                filter: { column: key, value },
            }));
        } else if (key === 'less_than_20') {
            const filteredStock = data_all.filter(
                (item) => (item.count / item.total) * 100 <= 20
            );
            // console.log(filteredStock);
            this.setState((prev) => ({
                ...prev,
                data: filteredStock,
                filter: { column: key, value },
            }));
        } else if (key === 'less_than_10') {
            const filteredStock = data_all.filter(
                (item) => (item.count / item.total) * 100 <= 10
            );
            // console.log(filteredStock);
            this.setState((prev) => ({
                ...prev,
                data: filteredStock,
                filter: { column: key, value },
            }));
        } else if (key === 'nDaysAgo') {
            // console.log('key: ', key);
            // console.log('value: ', value);
            // const d1 = new Date();
            // d1.setHours(0, 0, 0, 0);
            // const d1c = d1.setDate(d1.getDate() - value + 1);

            const d2 = new Date();
            d2.setHours(0, 0, 0, 0);
            const d2c = d2.setDate(d2.getDate() - value);

            const res = data_all.filter(function (e) {
                const temp = new Date(e.updatedAt).getTime();
                return temp > d2c;
            });
            // console.log(res);
            this.setState((prev) => ({
                ...prev,
                data: res,
                filter: { column: key, value },
            }));
        } else {
            const filteredItems = data_all.filter(
                (item) => item[key] === value
            );
            this.setState((prev) => ({
                ...prev,
                data: filteredItems,
                filter: { column: key, value },
            }));
        }
    };

    changeDisplayMode = (mode) => {
        this.setState((prev) => ({
            ...prev,
            displayMode: mode,
            show: {
                ...prev.show,
                view: false,
            },
        }));
        return false;
    };

    render() {
        const { match } = this.props;
        const {
            hide_button_add,
            page,
            selectedItems,
            data,
            data_all,
            is_loading_data,
            show,
            form,
            sku,
            accordion,
            data_stock,
            filter,
            displayMode,
            // Dynamic Form
            data_dynamic_form,
        } = this.state;
        // console.log(form);
        // console.log('data: ', data);
        // console.log('sku: ', sku);
        // console.log('data_all: ', data_all);

        // const dataRender = data_stock?.length ? data_stock : data;
        // console.log('dataRender: ', dataRender);
        // console.log('data_length: ', this.state.data_length);
        // console.log('hide_button_add: ', hide_button_add);

        if (!isR(ROLE_ALIAS))
            return (
                <Card className='text-muted mh-40vh h-100'>
                    <CardBody className='d-flex justify-content-center align-items-center'>
                        Bạn không có quyền sử dụng tính năng này!
                    </CardBody>
                </Card>
            );

        return is_loading_data ? (
            <div className='loading' />
        ) : (
            <div
                // className={classnames(
                //     'disable-text-selection',
                //     displayMode === 'thumblist' ? 'app-row' : ''
                // )}
                className='app-row disable-text-selection'
            >
                <PageHeading
                    heading='stock.thumb-list'
                    hide_button_add={hide_button_add}
                    setting={page}
                    match={match}
                    totalItem={page.totalItem}
                    isDisabledAdd={!isC(ROLE_ALIAS)}
                    toggleFunc={() => {
                        this.toggle('add');
                        this.toggleAccordion();
                    }}
                    changePageSizeFunc={this.onChangePageSize}
                    onSearchKeyFunc={this.onSearchKey}
                    displayMode={displayMode}
                    changeDisplayMode={this.changeDisplayMode}
                />
                <AddStock
                    title={<IntlMessages id='stock.add-new-modal-title' />}
                    isOpen={show.add}
                    toggle={() => {
                        this.toggle('add');
                    }}
                    data_dynamic_form={data_dynamic_form}
                    values={form}
                    sku={sku}
                    setting={show}
                    onChange={this.onChangeForm}
                    onReset={this.onClickedReset}
                    onChangeDynamicForm={this.onChangeDynamicForm}
                    onSubmit={this.onHandlerAdd}
                />
                <AddMoreStock
                    title={<IntlMessages id='stock.count-update' />}
                    isOpen={show.updateCount}
                    toggle={() => {
                        this.toggle('updateCount');
                    }}
                    values={form}
                    data={data}
                    sku={sku}
                    setting={show}
                    onChange={this.onChangeForm}
                    onSubmit={this.onHandlerUpdateCount}
                >
                    <ContentFormCountUpdate />
                </AddMoreStock>
                <UpdateStock
                    title={<IntlMessages id='stock.count-edit' />}
                    onSubmit={this.onHandlerUpdate}
                    isOpen={show.update}
                    toggle={() => {
                        this.toggle('update');
                    }}
                    data_dynamic_form={data_dynamic_form}
                    values={form}
                    sku={sku}
                    setting={show}
                    onReset={this.onClickedReset}
                    onChange={this.onChangeForm}
                    onChangeDynamicForm={this.onChangeDynamicForm}
                />
                {data.length === 0 ? (
                    <Card className='mt-4 mb-4 mh-53vh'>
                        <CardBody>Chưa có dữ liệu...</CardBody>
                    </Card>
                ) : (
                    <>
                        <Nav
                            tabs
                            className='d-flex justify-content-between separator-tabs ml-0 mb-5'
                        >
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: this.state.activeTab === '1',
                                        'nav-link': true,
                                    })}
                                    onClick={() => this.toggleTab('1')}
                                    location={{}}
                                    to='#'
                                >
                                    <IntlMessages id='general.info' />
                                </NavLink>
                            </NavItem>

                            {/* <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: this.state.activeTab === '2',
                                        'nav-link': true,
                                    })}
                                    onClick={() => this.toggleTab('2')}
                                    location={{}}
                                    to='#'
                                >
                                    <span className='mr-2'>
                                        <i className='simple-icon-settings'></i>
                                    </span>
                                    <IntlMessages id='general.setting' />
                                </NavLink>
                            </NavItem> */}
                        </Nav>
                        <TabContent
                            activeTab={this.state.activeTab}
                            className='mh-53vh'
                        >
                            <TabPane tabId='1'>
                                <Row className='content-page__row d-none d-md-flex'>
                                    <Col
                                        xs='12'
                                        sm='12'
                                        md='12'
                                        lg={
                                            show.view &&
                                            displayMode === 'thumblist'
                                                ? 6
                                                : 12
                                        }
                                        xl={
                                            show.view &&
                                            displayMode === 'thumblist'
                                                ? 6
                                                : 12
                                        }
                                    >
                                        {data?.length ? (
                                            displayMode === 'thumblist' ? (
                                                <>
                                                    {data.map((item, idx) => (
                                                        <ListItemLeftForm
                                                            onClickedItem={
                                                                this
                                                                    .onClickedItem
                                                            }
                                                            onUpdate={
                                                                this
                                                                    .onClickedUpdate
                                                            }
                                                            onUpdateCount={
                                                                this
                                                                    .onClickedUpdateCount
                                                            }
                                                            onDelete={
                                                                this
                                                                    .onClickedDelete
                                                            }
                                                            onChangeCheckbox={
                                                                this
                                                                    .onClickedCheckbox
                                                            }
                                                            isDisabledUpdate={
                                                                !isU(ROLE_ALIAS)
                                                            }
                                                            isDisabledDelete={
                                                                !isD(ROLE_ALIAS)
                                                            }
                                                            selectedItems={
                                                                selectedItems
                                                            }
                                                            key={idx}
                                                            idx={idx}
                                                            item={item}
                                                        />
                                                    ))}
                                                    {/* {data?.length <
                                                    12 ? null : ( */}
                                                    <Pagination
                                                        currentPage={
                                                            page.currentPage
                                                        }
                                                        totalPage={
                                                            page.totalPage
                                                        }
                                                        onChangePage={(i) =>
                                                            this.onChangePage(i)
                                                        }
                                                    />
                                                    {/* )} */}
                                                </>
                                            ) : (
                                                <ContentMultiForm
                                                    data={data_all}
                                                />
                                            )
                                        ) : (
                                            <Card className='d-flex justify-content-center align-items-center'>
                                                <CardBody>
                                                    Không có dữ liệu khách hàng
                                                    nào phù hợp với lựa chọn của
                                                    bạn.
                                                </CardBody>
                                            </Card>
                                        )}
                                    </Col>
                                    {show.view ? (
                                        <Col
                                            xs='12'
                                            sm='12'
                                            md='12'
                                            lg='6'
                                            xl='6'
                                        >
                                            <ContentDisplay
                                                // data_dynamic_form={data_dynamic_form}
                                                // data_tags={data_tags}
                                                values={form}
                                                data={data}
                                                sku={sku}
                                            />
                                        </Col>
                                    ) : null}
                                </Row>
                                <Row className='content-page__row d-block d-md-none mb-4'>
                                    <Col>
                                        {data?.length ? (
                                            data.map((item, idx) => {
                                                // console.log(item);
                                                return (
                                                    <div
                                                        className='d-flex mb-3 card'
                                                        key={item.id}
                                                    >
                                                        <ListItemFormMobile
                                                            onClickedItem={
                                                                this
                                                                    .onClickedItem
                                                            }
                                                            onUpdate={
                                                                this
                                                                    .onClickedUpdate
                                                            }
                                                            onDelete={
                                                                this
                                                                    .onClickedDelete
                                                            }
                                                            onChangeCheckbox={
                                                                this
                                                                    .onClickedCheckbox
                                                            }
                                                            isDisabledUpdate={
                                                                !isU(ROLE_ALIAS)
                                                            }
                                                            isDisabledDelete={
                                                                !isD(ROLE_ALIAS)
                                                            }
                                                            selectedItems={
                                                                selectedItems
                                                            }
                                                            key={idx}
                                                            idx={idx}
                                                            item={item}
                                                            data={data}
                                                            sku={sku}
                                                            form={form}
                                                            accordion={
                                                                accordion
                                                            }
                                                            toggleAccordion={
                                                                this
                                                                    .toggleAccordion
                                                            }
                                                        />
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <Card className='d-flex justify-content-center align-items-center'>
                                                <CardBody>
                                                    Không có dữ liệu khách hàng
                                                    nào phù hợp với lựa chọn của
                                                    bạn.
                                                </CardBody>
                                            </Card>
                                        )}
                                        {/* {data?.length < 12 ? null : ( */}
                                        <Pagination
                                            currentPage={page.currentPage}
                                            totalPage={page.totalPage}
                                            onChangePage={(i) =>
                                                this.onChangePage(i)
                                            }
                                        />
                                        {/* )} */}
                                    </Col>
                                </Row>
                            </TabPane>
                            {/* <TabPane tabId='2'>Setting</TabPane> */}
                        </TabContent>
                    </>
                )}
                <StockApplicationMenu
                    data={data_all}
                    filterData={(column, value) =>
                        this.filterData(column, value)
                    }
                    filter={filter}
                />
                <Separator />
                <FooterPage>
                    <FooterStock />
                </FooterPage>
            </div>
        );
    }
}

export default ContentComponent;
