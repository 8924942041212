import React, { Component } from 'react';
import { Row, Card, CardBody, Badge } from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import axios from 'axios';
import { END_POINT, END_POINT_SOCKET } from '../../../constants/defaultValues';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import {
    AddCommaPhoneNumber,
    getQrPay,
    NotificatioErrController,
    NotificatioSucController,
} from '../../../helpers/Utils';
import io from 'socket.io-client';
import LogsHelper from '../../../helpers/Logs';
import CustomTooltip from '../../../components/CustomTooltip';
import SingleLightbox from '../../../components/pages/SingleLightbox';
import { getAvatar } from '../../../helpers/Utils';
import { isC, isF, isU } from '../../../helpers/Roles';
import { Update } from '../../../containers/pages/general';
import ContentFormPersonal from '../../../containers/applications/ContentFormPersonal';
import ContentFormCompany from '../../../containers/applications/ContentFormCompany';
import { GetBank } from '../../../helpers/ListBank';

const ROLE_ALIAS = 'profile';

const copyToClipboard = (value) => {
    let input = document.createElement('input');
    input.setAttribute('value', value);
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand('copy');
    document.body.removeChild(input);

    NotificatioSucController('Thành công');
};

const DEFAULT_FORM_PERSONAL = {
    id: '',
    fullname: '',
    email: '',
    birthDay: new Date(),
    avatar: '',
    avatarImageFile: '',
    phone: '',
    description: '',
    address: '',

    dynamic_form: [],
};

const DEFAULT_FORM_COMPANY = {
    id: '',
    companyName: '',
    companyAddress: '',
    hotline: '',
    companyStartTime: new Date(),
    companyEndTime: new Date(),
    companyWebsite: '',
    companyImageFile: '',
    companyLogoFile: '',
    companyImage: '',
    companyLogo: '',

    namePay: '',
    numberPay: '',
    bankPay: '',
    qrPayFile: '',
    qrPayImage: '',

    companyMenuHash: '',
    links: [],
    valueNewLink: '',
    dynamic_form: [],
};

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            show: {
                update: false,
                update_company: false,
            },

            fullname: '',
            email: '',
            birthDay: new Date(),
            avatar: '',
            avatarImageFile: '',
            phone: '',

            companyName: '',
            companyUUID: '',
            companyAddress: '',
            hotline: '',
            companyStartTime: new Date(),
            companyEndTime: new Date(),
            companyWebsite: '',
            companyImageFile: '',
            companyLogoFile: '',
            companyImage: '',
            companyLogo: '',
            companyMenuHash: '',
            links: [],
            valueNewLink: '',

            data_dynamic_form: [],
            // form: DEFAULT_FORM_PERSONAL,
            // form_company: DEFAULT_FORM_COMPANY,

            namePay: '',
            numberPay: '',
            bankPay: '',
            qrPayFile: '',
            qrPayImage: '',
            description: '',
            address: '',
        };
        this.socket = io(END_POINT_SOCKET);

        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.handleDeleteImage = this.handleDeleteImage.bind(this);
        this.handleChangeBirthDay = this.handleChangeBirthDay.bind(this);
        this.handleChangeCompanyStartTime =
            this.handleChangeCompanyStartTime.bind(this);
        this.handleChangeCompanyEndTime =
            this.handleChangeCompanyEndTime.bind(this);
        // this.onSubmitUpdate = this.onSubmitUpdate.bind(this);
        this.onSubmitUpdatePersonal = this.onSubmitUpdatePersonal.bind(this);
        this.onSubmitUpdateCompany = this.onSubmitUpdateCompany.bind(this);
        this.onChangeValueMenuLink = this.onChangeValueMenuLink.bind(this);
        this.onCreateMenu = this.onCreateMenu.bind(this);
    }

    componentWillUnmount() {
        this.socket.close();
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        this.dataRender();
        // this.onLoadMenuLinks();
    }

    dataRender() {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/user'}`, {
                headers: {
                    Authorization: `Bearer ${tokenStr}`,
                },
            })
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                // console.log('data: ', data);
                this.setState({
                    fullname: data.data.fullname || '',
                    email: data.data.email || '',
                    companyAddress: data.data.companyAddress || '',
                    hotline: data.data.hotline || '',
                    companyUUID: data.data.companyUUID || '',
                    companyName: data.data.companyName || '',
                    companyWebsite: data.data.companyWebsite || '',
                    birthDay: data.data.birthDate || new Date(),
                    phone: data.data.phoneNumber || '',
                    avatar: data.data.image || '',
                    companyStartTime: data.data.companyStartTime || new Date(),
                    companyEndTime: data.data.companyEndTime || new Date(),
                    companyImage: data.data.companyImage || '',
                    companyLogo: data.data.companyLogo || '',
                    companyMenuHash: data.data.companyMenuHash || '',
                    isLoading: true,

                    namePay: data.data.namePay || '',
                    numberPay: data.data.numberPay || '',
                    bankPay: data.data.bankPay || '',
                    qrPayImage: data.data.qrPayImage || '',
                    description: data.data.description || '',
                    address: data.data.address || '',
                });

                localStorage.setItem('fullname', data.data.fullname);
                localStorage.setItem(
                    'companyAddress',
                    data.data.companyAddress
                );
                localStorage.setItem('hotline', data.data.hotline);
                localStorage.setItem(
                    'companyWebsite',
                    data.data.companyWebsite
                );
                localStorage.setItem('companyName', data.data.companyName);
                localStorage.setItem('companyImage', data.data.companyImage);
                localStorage.setItem('companyLogo', data.data.companyLogo);
                localStorage.setItem('avatar', data.data.image);

                localStorage.setItem('namePay', data.data.namePay);
                localStorage.setItem('numberPay', data.data.numberPay);
                localStorage.setItem('bankPay', data.data.bankPay);
                localStorage.setItem('qrPayImage', data.data.qrPayImage);
            })
            .catch((err) => {
                NotificatioErrController(err.response?.data?.responseText);
            });
    }

    handleChangeValue(e, _name) {
        if (!isF(ROLE_ALIAS)) return false;

        if (!e) return false;

        if (e.target) {
            // input
            const { name, value } = e.target;

            this.setState((prevState) => ({ ...prevState, [name]: value }));

            if (name === 'avatar') {
                this.setState({ avatarImageFile: e.target.files[0] });
            }

            if (name === 'companyImage') {
                this.setState({ companyImageFile: e.target.files[0] });
            }

            if (name === 'companyLogo') {
                this.setState({ companyLogoFile: e.target.files[0] });
            }

            if (name === 'qrPayImage') {
                this.setState({ qrPayFile: e.target.files[0] });
            }
        } else {
            let value = e;
            if (_name === 'bankPay') {
                value = e.value;
                // console.log('value: ', value);
            }
            this.setState((prev) => ({
                ...prev,
                [_name]: value,
            }));
        }
    }

    handleDeleteImage(type) {
        if (!isF(ROLE_ALIAS)) return false;

        this.setState({
            [type]: '',
        });
    }

    handleChangeBirthDay(date) {
        if (!isF(ROLE_ALIAS)) return false;

        this.setState({ birthDay: date });
    }

    handleChangeCompanyStartTime(time) {
        if (!isF(ROLE_ALIAS)) return false;

        this.setState({ companyStartTime: time });
    }

    handleChangeCompanyEndTime(time) {
        if (!isF(ROLE_ALIAS)) return false;

        this.setState({ companyEndTime: time });
    }

    // async onSubmitUpdate() {
    //     if (!isF(ROLE_ALIAS)) return false;

    //     if (this.state.companyName === '' || this.state.companyAddress === '') {
    //         NotificatioErrController(
    //             'Tên cửa hàng và địa chỉ không được trống. Cập nhật để sử dụng hóa đơn.'
    //         );
    //         return false;
    //     }

    //     let birthDate = moment(this.state.birthDay).format('YYYY-MM-DD');
    //     let companyStartTime = moment(
    //         this.state.companyStartTime,
    //         'HH:mm'
    //     ).format('HH:mm');
    //     let companyEndTime = moment(this.state.companyEndTime, 'HH:mm').format(
    //         'HH:mm'
    //     );

    //     let formData = new FormData();
    //     if (this.state.avatar) {
    //         formData.append('avatar', this.state.avatarImageFile);
    //     }

    //     if (this.state.companyImage) {
    //         formData.append('companyImage', this.state.companyImageFile);
    //     }

    //     if (this.state.companyLogo) {
    //         formData.append('companyLogo', this.state.companyLogoFile);
    //     }

    //     formData.append('fullname', this.state.fullname);
    //     formData.append('email', this.state.email);
    //     formData.append('companyName', this.state.companyName);
    //     formData.append('companyAddress', this.state.companyAddress);
    //     formData.append('companyWebsite', this.state.companyWebsite.trim());
    //     formData.append('phoneNumber', this.state.phone);
    //     formData.append('birthDate', birthDate);
    //     formData.append('companyStartTime', companyStartTime);
    //     formData.append('companyEndTime', companyEndTime);

    //     this.setState({ isLoading: false });

    //     // axios submit
    //     let tokenStr = localStorage.getItem('access_token');
    //     axios
    //         .put(`${END_POINT + '/user'}`, formData, {
    //             headers: {
    //                 Authorization: `Bearer ${tokenStr}`,
    //                 'content-type': 'multipart/form-data',
    //             },
    //         })
    //         .then((res) => {
    //             return res.data;
    //         })
    //         .then((data) => {
    //             NotificatioSucController(data.responseText);

    //             this.socket.emit('create logs', {
    //                 user_id: localStorage.getItem('user_id'),
    //                 message: LogsHelper['profile-update'],
    //             });

    //             this.setState({
    //                 avatarImageFile: '',
    //                 companyImageFile: '',
    //                 companyLogoFile: '',
    //                 isLoading: true,
    //             });

    //             this.dataRender();
    //         })
    //         .catch((err) => {
    //             NotificatioErrController(err.response.data.responseText);
    //         });
    // }

    onSubmitUpdatePersonal() {
        if (!isF(ROLE_ALIAS)) return false;

        let birthDate = moment(this.state.birthDay).format('YYYY-MM-DD');
        let companyStartTime = moment(
            this.state.companyStartTime,
            'HH:mm'
        ).format('HH:mm');
        let companyEndTime = moment(this.state.companyEndTime, 'HH:mm').format(
            'HH:mm'
        );

        let formData = new FormData();
        if (this.state.avatar) {
            formData.append('avatar', this.state.avatarImageFile);
        }

        formData.append('fullname', this.state.fullname);
        formData.append('phoneNumber', this.state.phone);
        formData.append('email', this.state.email);
        formData.append('address', this.state.address);
        formData.append('birthDate', birthDate);
        formData.append('description', this.state.description);

        formData.append('companyName', this.state.companyName);
        formData.append('companyAddress', this.state.companyAddress);
        formData.append('hotline', this.state.hotline);
        formData.append('companyWebsite', this.state.companyWebsite.trim());
        formData.append('companyStartTime', companyStartTime);
        formData.append('companyEndTime', companyEndTime);
        formData.append('namePay', this.state.namePay);
        formData.append('numberPay', this.state.numberPay);
        formData.append('bankPay', this.state.bankPay);

        this.setState({ isLoading: false });

        // axios submit
        let tokenStr = localStorage.getItem('access_token');
        axios
            .put(`${END_POINT + '/user'}`, formData, {
                headers: {
                    Authorization: `Bearer ${tokenStr}`,
                    'content-type': 'multipart/form-data',
                },
            })
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                NotificatioSucController(data.responseText);

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['profile-update'],
                });

                this.setState({
                    avatarImageFile: '',
                    isLoading: true,
                });

                this.dataRender();
                this.toggle('update');
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    }

    onSubmitUpdateCompany() {
        if (!isF(ROLE_ALIAS)) return false;

        let birthDate = moment(this.state.birthDay).format('YYYY-MM-DD');

        if (this.state.companyName === '') {
            NotificatioErrController(
                'Tên cửa hàng không được để trống. Cập nhật để sử dụng hóa đơn.'
            );
            return false;
        }

        if (this.state.companyAddress === '') {
            NotificatioErrController(
                'Địa chỉ cửa hàng không được để trống. Cập nhật để sử dụng hóa đơn.'
            );
            return false;
        }

        let companyStartTime = moment(
            this.state.companyStartTime,
            'HH:mm'
        ).format('HH:mm');
        let companyEndTime = moment(this.state.companyEndTime, 'HH:mm').format(
            'HH:mm'
        );

        let formData = new FormData();

        if (this.state.companyImage) {
            formData.append('companyImage', this.state.companyImageFile);
        }

        if (this.state.companyLogo) {
            formData.append('companyLogo', this.state.companyLogoFile);
        }

        if (this.state.qrPayImage) {
            formData.append('qrPayImage', this.state.qrPayFile);
        }

        formData.append('fullname', this.state.fullname);
        formData.append('phoneNumber', this.state.phone);
        formData.append('email', this.state.email);
        formData.append('address', this.state.address);
        formData.append('birthDate', birthDate);
        formData.append('description', this.state.description);

        formData.append('companyName', this.state.companyName);
        formData.append('companyAddress', this.state.companyAddress);
        formData.append('hotline', this.state.hotline);
        formData.append('companyWebsite', this.state.companyWebsite.trim());
        formData.append('companyStartTime', companyStartTime);
        formData.append('companyEndTime', companyEndTime);

        formData.append('namePay', this.state.namePay);
        formData.append('numberPay', this.state.numberPay);
        formData.append('bankPay', this.state.bankPay);

        this.setState({ isLoading: false });

        // axios submit
        let tokenStr = localStorage.getItem('access_token');
        axios
            .put(`${END_POINT + '/user'}`, formData, {
                headers: {
                    Authorization: `Bearer ${tokenStr}`,
                    'content-type': 'multipart/form-data',
                },
            })
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                NotificatioSucController(data.responseText);

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['profile-company-update'],
                });

                this.setState({
                    companyImageFile: '',
                    companyLogoFile: '',
                    qrPayFile: '',
                    isLoading: false,
                });

                this.dataRender();
                this.toggle('update_company');
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    }

    onChangeValueMenuLink(e) {
        if (!isF(ROLE_ALIAS)) return false;

        if (!e) return false;

        if (!e.target) return false;

        const { value } = e.target;

        this.setState({ valueNewLink: value });
    }

    onCreateMenu(e) {
        e.preventDefault();

        if (this.state.valueNewLink === '') return false;

        let tokenStr = localStorage.getItem('access_token');
        return axios
            .post(
                `${END_POINT + '/user-link'}`,
                {
                    identify: this.state.valueNewLink.trim(),
                },
                {
                    headers: {
                        Authorization: `Bearer ${tokenStr}`,
                    },
                }
            )
            .then(() => {
                this.onLoadMenuLinks();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    }

    onLoadMenuLinks() {
        let tokenStr = localStorage.getItem('access_token');

        return axios
            .get(
                // `${END_POINT + "/user-link"}`,
                `${END_POINT + '/table/all_identify'}`,
                {
                    headers: {
                        Authorization: `Bearer ${tokenStr}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                this.setState({ links: data.data, valueNewLink: '' });
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    }

    onClickDeleteMenuLink(id) {
        if (!id || id < 0) return false;

        let tokenStr = localStorage.getItem('access_token');
        return axios
            .delete(`${END_POINT + '/user-link'}`, {
                data: {
                    id: id,
                },
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                NotificatioSucController('Thành công');
                this.onLoadMenuLinks();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    }

    renderLinks = (links) => {
        return links.map((link) => (
            <li key={link.id}>
                <Badge color='danger'>{link.u}</Badge>
                <i
                    id={'Tooltip-' + link.id + '-copy'}
                    className='iconsminds-file-copy'
                    onClick={() => {
                        copyToClipboard(
                            window.location.host + '/menu/' + link.u
                        );
                    }}
                />

                <CustomTooltip
                    placement='top'
                    id={link.id + '-copy'}
                    body={'Sao chép'}
                />
            </li>
        ));
    };
    renderListLinks = () => {
        return this.state.links.length ? (
            <ul className='profile-company-menu-link'>
                {this.renderLinks(this.state.links)}
            </ul>
        ) : (
            <></>
        );
    };

    // onHandlerUpdate = () => {
    //     if (!isU(ROLE_ALIAS)) return false;

    //     const { id, name, phone, email, address, dynamic_form } =
    //         this.state.form;

    //     if (!name || !id) {
    //         NotificatioErrController('Dữ liệu không hợp lệ');
    //         return;
    //     }

    //     let tokenStr = localStorage.getItem('access_token');
    //     axios
    //         .put(
    //             `${END_POINT + '/company-provider'}`,
    //             {
    //                 id,
    //                 name,
    //                 phone,
    //                 email,
    //                 address,
    //                 dynamic_form,
    //             },
    //             {
    //                 headers: { Authorization: `Bearer ${tokenStr}` },
    //             }
    //         )
    //         .then((res) => res.data)
    //         .then((data) => {
    //             NotificatioSucController('Thành công');

    //             this.socket.emit('create logs', {
    //                 user_id: localStorage.getItem('user_id'),
    //                 message: LogsHelper['company-provider-update'],
    //             });

    //             this.dataListRender();
    //             this.toggle('update');
    //         })
    //         .catch((err) => {
    //             NotificatioErrController(err.response.data.responseText);
    //             return false;
    //         });
    // };

    toggle = (name) => {
        if (name === 'add' && !isC(ROLE_ALIAS)) return false;
        if (name === 'update' && !isU(ROLE_ALIAS)) return false;
        if (name === 'update_company' && !isU(ROLE_ALIAS)) return false;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                update: name === 'update_company' ? false : prev.show.update,
                update_company:
                    name === 'update' ? false : prev.show.update_company,
                [name]: !prev.show[name],
            },
            // selectedItems: name === 'add' ? [] : prev.selectedItems,
            form: name === 'update' ? prev.form : DEFAULT_FORM_PERSONAL,
            form:
                name === 'update_company'
                    ? prev.form_company
                    : DEFAULT_FORM_COMPANY,
        }));
    };

    render() {
        let {
            fullname,
            email,
            phone,
            birthDay,
            companyAddress,
            hotline,
            companyName,
            companyWebsite,
            companyUUID,
            avatar,
            avatarImageFile,
            companyStartTime,
            companyEndTime,
            companyMenuHash,
            companyImage,
            companyImageFile,
            companyLogo,
            companyLogoFile,
            isLoading,
            // form,
            // form_company,
            show,
            // data_dynamic_form,

            namePay,
            numberPay,
            bankPay,
            qrPayFile,
            qrPayImage,
            description,
            address,
        } = this.state;

        // console.log('fullname: ', fullname);
        // console.log('description: ', description);
        // console.log('address: ', address);
        // console.log('namePay: ', namePay);
        // console.log('numberPay: ', numberPay);
        // console.log('bankPay: ', typeof bankPay);

        const shopDefault = 'VMASS';
        const addressDefault =
            '380/71 Lê Văn Lương, Phường Tân Hưng, Quận 7, TPHCM';
        const websiteDefault = 'https://vmass.vn';

        return !isLoading ? (
            <div className='loading' />
        ) : (
            <React.Fragment>
                <Row>
                    <Colxx xxs='12'>
                        <Breadcrumb
                            heading='menu.profile'
                            match={this.props.match}
                        />
                        <Separator className='mb-5' />
                    </Colxx>
                </Row>
                <Update
                    title={<IntlMessages id='menu.profile' />}
                    onSubmit={this.onSubmitUpdatePersonal}
                    isOpen={show.update}
                    toggle={() => {
                        this.toggle('update');
                    }}
                >
                    <ContentFormPersonal
                        // data_dynamic_form={data_dynamic_form}
                        setting={show}
                        handleChangeValue={this.handleChangeValue}
                        onChangeBirthday={this.handleChangeBirthDay}
                        handleDeleteImage={this.handleDeleteImage}
                        fullname={fullname}
                        email={email}
                        phone={phone}
                        birthDay={birthDay}
                        avatar={avatar}
                        avatarImageFile={avatarImageFile}
                        companyLogoFile={companyLogoFile}
                        companyLogo={companyLogo}
                        description={description}
                        address={address}
                    />
                </Update>
                <Update
                    title={<IntlMessages id='menu.company' />}
                    onSubmit={this.onSubmitUpdateCompany}
                    isOpen={show.update_company}
                    toggle={() => {
                        this.toggle('update_company');
                    }}
                >
                    <ContentFormCompany
                        // data_dynamic_form={data_dynamic_form}
                        setting={show}
                        handleChangeValue={this.handleChangeValue}
                        handleChangeCompanyStartTime={
                            this.handleChangeCompanyStartTime
                        }
                        handleChangeCompanyEndTime={
                            this.handleChangeCompanyEndTime
                        }
                        handleDeleteImage={this.handleDeleteImage}
                        companyStartTime={companyStartTime}
                        companyEndTime={companyEndTime}
                        companyUUID={companyUUID}
                        companyName={companyName}
                        companyAddress={companyAddress}
                        hotline={hotline}
                        companyWebsite={companyWebsite}
                        companyMenuHash={companyMenuHash}
                        companyImageFile={companyImageFile}
                        companyImage={companyImage}
                        companyLogoFile={companyLogoFile}
                        companyLogo={companyLogo}
                        namePay={namePay}
                        numberPay={numberPay}
                        bankPay={bankPay}
                        qrPayFile={qrPayFile}
                        qrPayImage={qrPayImage}
                    />
                </Update>
                <Row>
                    <Colxx xxs='12' className='mb-4'>
                        <Card>
                            {companyImage ? (
                                <SingleLightbox
                                    thumb={getAvatar(companyImage)}
                                    large={getAvatar(companyImage)}
                                    className='social-header card-img'
                                />
                            ) : (
                                <SingleLightbox
                                    thumb='/assets/img/balloon.jpg'
                                    large='/assets/img/balloon.jpg'
                                    className='social-header card-img'
                                />
                            )}
                        </Card>
                    </Colxx>
                    <Colxx xxs='12' lg='4' className='col-left'>
                        <div
                            className='position-absolute card-avatar-buttons cursor-pointer mr-2'
                            onClick={() => this.toggle('update')}
                        >
                            <i className='simple-icon-note' />
                        </div>
                        {companyLogo ? (
                            <SingleLightbox
                                thumb={getAvatar(companyLogo)}
                                large={getAvatar(companyLogo)}
                                className='img-thumbnail card-img social-profile-img'
                            />
                        ) : (
                            <SingleLightbox
                                thumb='/assets/img/logo.jpeg'
                                large='/assets/img/logo.jpeg'
                                className='img-thumbnail card-img social-profile-img'
                            />
                        )}
                        <Card className='mb-4'>
                            <CardBody>
                                <div className='text-center pt-4'>
                                    <p className='list-item-heading pt-2 text-capitalize'>
                                        {fullname}
                                    </p>
                                </div>
                                <p className='mb-3'>
                                    {description ? (
                                        description
                                    ) : (
                                        <span className='text-small text-muted'>
                                            (chưa thiết lập đoạn mô tả ngắn)
                                        </span>
                                    )}
                                </p>

                                <p className='text-muted text-small mb-2'>
                                    Địa chỉ
                                </p>
                                <p className='mb-3'>
                                    {address ? (
                                        address
                                    ) : (
                                        <span className='text-small text-muted'>
                                            (chưa thiết lập địa chỉ)
                                        </span>
                                    )}
                                </p>

                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='profile.phone' />
                                </p>
                                <p className='mb-3'>
                                    {AddCommaPhoneNumber(phone)}
                                </p>

                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='profile.email' />
                                </p>
                                <p className='mb-3'>{email}</p>
                            </CardBody>
                        </Card>
                    </Colxx>
                    <Colxx xxs='12' lg='8' className='mb-4 col-right'>
                        <Card>
                            <div
                                className='position-absolute card-avatar-buttons cursor-pointer'
                                onClick={() => this.toggle('update_company')}
                            >
                                <i className='simple-icon-note' />
                            </div>
                            <CardBody>
                                <Row className='d-flex justify-content-center text-uppercase fs-1 fw-bold'>
                                    <h2 className='mb-3'>{companyName}</h2>
                                </Row>
                                <Row className='mt-3 '>
                                    <Colxx xxs='12' lg='5' className='mb-4'>
                                        <p className='text-muted text-small mb-2'>
                                            <IntlMessages id='company-uuid' />
                                        </p>
                                        <p className='mb-3'>{companyUUID}</p>
                                    </Colxx>
                                    <Colxx xxs='12' lg='7' className='mb-4'>
                                        <p className='text-muted text-small mb-2'>
                                            <IntlMessages id='profile.company-menu-hash' />
                                        </p>
                                        <p className='mb-3'>
                                            {window.location.protocol +
                                                '//' +
                                                window.location.host +
                                                '/menu/' +
                                                companyMenuHash}
                                        </p>
                                    </Colxx>
                                </Row>
                                <Row>
                                    <Colxx xxs='12' lg='5' className='mb-4'>
                                        <p className='text-muted text-small mb-2'>
                                            <IntlMessages id='profile.company-website' />
                                        </p>
                                        <p className='mb-3 text-lowercase'>
                                            {companyWebsite}
                                        </p>
                                    </Colxx>
                                    <Colxx xxs='12' lg='7' className='mb-4'>
                                        <p className='text-muted text-small mb-2'>
                                            Hotline
                                        </p>
                                        <p className='mb-3 text-capitalize'>
                                            {AddCommaPhoneNumber(hotline)}
                                        </p>
                                    </Colxx>
                                </Row>
                                <Row>
                                    <Colxx xxs='12' lg='7' className='mb-4'>
                                        <p className='text-muted text-small mb-2'>
                                            <IntlMessages id='profile.company-address' />
                                        </p>
                                        <p className='mb-3 text-capitalize'>
                                            {companyAddress}
                                        </p>
                                    </Colxx>
                                </Row>
                                <Row>
                                    <Colxx xxs='12' lg='6' className='mb-4'>
                                        <p className='text-muted text-small mb-2'>
                                            <IntlMessages id='profile.company-start-time' />
                                        </p>
                                        <DatePicker
                                            name='companyStartTime'
                                            id='company-start-time'
                                            className='mb-3'
                                            // onChange={(time) =>
                                            //     this.handleChangeCompanyStartTime(
                                            //         time
                                            //     )
                                            // }
                                            selected={moment(
                                                companyStartTime,
                                                'HH:mm'
                                            )}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={5}
                                            timeCaption='Giờ'
                                            timeFormat='HH:mm'
                                            dateFormat='HH:mm'
                                        />
                                    </Colxx>
                                    <Colxx xxs='12' lg='6' className='mb-4'>
                                        <p className='text-muted text-small mb-2'>
                                            <IntlMessages id='profile.company-end-time' />
                                        </p>
                                        <DatePicker
                                            name='companyEndTime'
                                            id='company-end-time'
                                            className='mb-3'
                                            // onChange={(time) =>
                                            //     this.handleChangeCompanyEndTime(
                                            //         time
                                            //     )
                                            // }
                                            selected={moment(
                                                companyEndTime,
                                                'HH:mm'
                                            )}
                                            showTimeSelectOnly
                                            showTimeSelect
                                            timeIntervals={5}
                                            timeCaption='Giờ'
                                            timeFormat='HH:mm'
                                            dateFormat='HH:mm'
                                        />
                                    </Colxx>
                                </Row>
                                <Separator />

                                <Row>
                                    <Colxx
                                        lg='4'
                                        className='text-uppercase fs-1 fw-bold mt-4 text-right d-flex justify-content-center align-items-center'
                                    >
                                        <h3>THÔNG TIN THANH TOÁN</h3>
                                    </Colxx>
                                    <Colxx
                                        lg='3'
                                        className='d-flex justify-content-center align-items-center mt-4'
                                    >
                                        {bankPay && numberPay ? (
                                            // <SingleLightbox
                                            //     thumb={getAvatar(qrPayImage)}
                                            //     large={getAvatar(qrPayImage)}
                                            //     className='img-thumbnail card-img qrpay'
                                            // />
                                            <SingleLightbox
                                                thumb={getQrPay(
                                                    bankPay,
                                                    numberPay
                                                )}
                                                large={getQrPay(
                                                    bankPay,
                                                    numberPay
                                                )}
                                                className='img-thumbnail card-img qrpay'
                                            />
                                        ) : null}
                                    </Colxx>
                                    <Colxx
                                        lg='5'
                                        className='mt-4 text-left d-flex flex-column justify-content-center'
                                    >
                                        <Row className='d-flex align-items-center'>
                                            <p className='mb-1 text-muted text-small pr-2'>
                                                Chủ tài khoản:
                                            </p>{' '}
                                            <p className='mb-1 text-capitalize'>
                                                {namePay ? (
                                                    namePay
                                                ) : (
                                                    <p className='mb-1 text-lowercase text-small mb-0'>
                                                        (chưa có thông tin)
                                                    </p>
                                                )}
                                            </p>
                                        </Row>
                                        <Row className='d-flex align-items-center'>
                                            <p className='mb-1 text-muted text-small pr-2'>
                                                Số tài khoản:
                                            </p>
                                            <p className='mb-1 text-capitalize'>
                                                {numberPay ? (
                                                    numberPay
                                                ) : (
                                                    <p className='mb-1 text-lowercase text-small mb-0'>
                                                        (chưa có thông tin)
                                                    </p>
                                                )}
                                            </p>
                                        </Row>
                                        <Row className='d-flex align-items-center'>
                                            <p className='mb-1 text-muted text-small pr-2'>
                                                Ngân hàng:
                                            </p>
                                            <p className='mb-1 text-capitalize'>
                                                {bankPay ? (
                                                    GetBank(bankPay)?.label
                                                ) : (
                                                    <p className='mb-1 text-lowercase text-small mb-0'>
                                                        (chưa có thông tin)
                                                    </p>
                                                )}
                                            </p>
                                        </Row>
                                    </Colxx>
                                </Row>
                            </CardBody>
                        </Card>
                    </Colxx>
                </Row>
            </React.Fragment>
        );
    }
}

export default Profile;
