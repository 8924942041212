import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
    UncontrolledDropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';

import { NavLink, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { MobileMenuIcon, MenuIcon } from '../../components/svg';

import {
    setContainerClassnames,
    clickOnMobileMenu,
    logoutUser,
    changeLocale,
} from '../../redux/actions';

import { isDarkSwitchActive, END_POINT } from '../../constants/defaultValues';

import TopnavNotifications from './Topnav.Notifications';
import TopnavDarkSwitch from './Topnav.DarkSwitch';

class TopnavWithoutSidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isInFullScreen: false,
            searchKeyword: '',
            companyName:
                localStorage.getItem('companyName') &&
                localStorage.getItem('companyName') != 'null'
                    ? localStorage.getItem('companyName')
                    : localStorage.getItem('username'),
        };
    }

    isInFullScreen = () => {
        return (
            (document.fullscreenElement &&
                document.fullscreenElement !== null) ||
            (document.webkitFullscreenElement &&
                document.webkitFullscreenElement !== null) ||
            (document.mozFullScreenElement &&
                document.mozFullScreenElement !== null) ||
            (document.msFullscreenElement &&
                document.msFullscreenElement !== null)
        );
    };

    toggleFullScreen = () => {
        const isInFullScreen = this.isInFullScreen();

        var docElm = document.documentElement;
        if (!isInFullScreen) {
            if (docElm.requestFullscreen) {
                docElm.requestFullscreen();
            } else if (docElm.mozRequestFullScreen) {
                docElm.mozRequestFullScreen();
            } else if (docElm.webkitRequestFullScreen) {
                docElm.webkitRequestFullScreen();
            } else if (docElm.msRequestFullscreen) {
                docElm.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
        this.setState({
            isInFullScreen: !isInFullScreen,
        });
    };

    handleLogout = () => {
        this.props.logoutUser(this.props.history);
    };

    menuButtonClick = (e) => {
        const store = window.location.host.split('.')[0];
        if (store === 'faq') {
            return <Redirect to='/root/faq/dashboards' />;
        }

        if (store === 'message') {
            return <Redirect to='/root/message/dashboards' />;
        }

        if (store === 'upgrade') {
            return <Redirect to='/root/upgrade/dashboards' />;
        }
    };

    render() {
        const store = window.location.host.split('.')[0];
        const pathname = this.props.history.location.pathname;
        const PATH_PROFILE = '/app/applications/profile';
        const PATH_FAQ = '/root/faq/dashboards';
        const PATH_MESSAGE = '/root/message/dashboards';
        const PATH_UPGRADE = '/root/upgrade/dashboards';
        const ROLESETTINGS = localStorage.getItem('roleSettings')
            ? JSON.parse(localStorage.getItem('roleSettings'))
            : null;

        if (
            store === 'faq' &&
            PATH_PROFILE !== pathname &&
            PATH_FAQ !== pathname
        ) {
            return <Redirect to='/root/faq/dashboards' />;
        } else if (
            store === 'message' &&
            PATH_PROFILE !== pathname &&
            PATH_MESSAGE !== pathname
        ) {
            return <Redirect to='/root/message/dashboards' />;
        } else if (
            store === 'upgrade' &&
            PATH_PROFILE !== pathname &&
            PATH_UPGRADE !== pathname
        ) {
            return <Redirect to='/root/upgrade/dashboards' />;
        } else if (ROLESETTINGS && ROLESETTINGS.length) {
            const find_payment = ROLESETTINGS.find(
                (_role) => _role.role_alias === 'table_pending_payment'
            );
            if (typeof find_payment != 'undefined') {
                return <Redirect to='/payment' />;
            }
        } else if (ROLESETTINGS && ROLESETTINGS.length) {
            const find_kitchen = ROLESETTINGS.find(
                (_role) => _role.role_alias === 'kitchen'
            );
            if (typeof find_kitchen !== 'undefined') {
                return <Redirect to='/kitchen' />;
            }
        } else if (ROLESETTINGS && ROLESETTINGS.length) {
            const find_cashier = ROLESETTINGS.find(
                (_role) => _role.role_alias === 'cashier'
            );
            if (typeof find_cashier !== 'undefined') {
                return <Redirect to='/cashier' />;
            }
        }

        return (
            <nav className='navbar fixed-top'>
                <div className='d-flex align-items-center navbar-left'>
                    <NavLink
                        to='#'
                        className='menu-button d-none d-md-block'
                        onClick={this.menuButtonClick}
                    >
                        <MenuIcon />
                    </NavLink>
                    <NavLink
                        to='#'
                        className='menu-button-mobile d-xs-block d-sm-block d-md-none'
                        onClick={this.menuButtonClick}
                    >
                        <MobileMenuIcon />
                    </NavLink>
                </div>
                <a className='navbar-logo' href='https://vmass.vn/'>
                    <span className='logo d-none d-xs-block' />
                    <span className='logo d-block d-xs-none' />
                </a>

                <div className='navbar-right'>
                    {isDarkSwitchActive && <TopnavDarkSwitch />}
                    <div className='header-icons d-inline-block align-middle'>
                        <TopnavNotifications />
                        <button
                            className='header-icon btn btn-empty d-none d-sm-inline-block'
                            type='button'
                            id='fullScreenButton'
                            onClick={this.toggleFullScreen}
                        >
                            {this.state.isInFullScreen ? (
                                <i className='simple-icon-size-actual d-block' />
                            ) : (
                                <i className='simple-icon-size-fullscreen d-block' />
                            )}
                        </button>
                    </div>
                    <div className='user mr-3 d-inline-block'>
                        <UncontrolledDropdown className='dropdown-menu-right'>
                            <DropdownToggle className='p-0' color='empty'>
                                <span className='name mr-1'>
                                    {this.state.companyName}
                                </span>
                                <span>
                                    <img
                                        alt=''
                                        src={
                                            localStorage.getItem('avatar') !==
                                                'null' &&
                                            localStorage.getItem('avatar') !==
                                                'undefined.'
                                                ? END_POINT +
                                                  '/public/' +
                                                  localStorage.getItem('avatar')
                                                : '/assets/img/profile-pic-l.jpg'
                                        }
                                    />
                                </span>
                            </DropdownToggle>
                            <DropdownMenu className='mt-3' right>
                                <NavLink to='/app/applications/profile'>
                                    <DropdownItem>Thông tin</DropdownItem>
                                </NavLink>
                                <DropdownItem divider />
                                <DropdownItem
                                    onClick={() => this.handleLogout()}
                                >
                                    Đăng xuất
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = ({ menu, settings }) => {
    const { containerClassnames, menuClickCount, selectedMenuHasSubItems } =
        menu;
    const { locale } = settings;
    return {
        containerClassnames,
        menuClickCount,
        selectedMenuHasSubItems,
        locale,
    };
};
export default injectIntl(
    connect(mapStateToProps, {
        setContainerClassnames,
        clickOnMobileMenu,
        logoutUser,
        changeLocale,
    })(TopnavWithoutSidebar)
);
