import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { RandomString } from '../../helpers/Utils';
import ItemSetting from './ItemSetting';

const RenderRowWithColumns = (props) => {
    return (
        <Row>
            {[...new Array(props.col).keys()].map((c) => (
                <Col key={RandomString(5)}>
                    <ItemSetting
                        y={c}
                        x={props.x}
                        position={props.position}
                        selectTableTransfer={props.selectTableTransfer}
                    />
                </Col>
            ))}
        </Row>
    );
};

const RenderGridLayout = (props) => {
    return (
        <div className='table__container table__container__transfer'>
            {[...new Array(props.row).keys()].map((r) => (
                <RenderRowWithColumns
                    col={props.col}
                    key={r}
                    x={r}
                    position={props.position}
                    selectTableTransfer={props.selectTableTransfer}
                />
            ))}
        </div>
    );
};

export default class TableChairGenarateMap extends Component {
    render() {
        const { data, selectTableTransfer } = this.props;

        const { row, col, position } = data;

        return row && col ? (
            <RenderGridLayout
                selectTableTransfer={selectTableTransfer}
                row={parseInt(row)}
                col={parseInt(col)}
                position={position}
            />
        ) : (
            <></>
        );
    }
}
