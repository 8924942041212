import React, { Component } from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import AppLayoutRoot from "../../layout/AppLayoutRoot";
import upgrade from "./upgrade";
import message from "./message";
import faq from "./faq";

class App extends Component {
  render() {
    const { match } = this.props;

    return (
      <AppLayoutRoot>
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/faq`} />
          <Route path={`${match.url}/faq`} component={faq} />
          <Route path={`${match.url}/message`} component={message} />
          <Route path={`${match.url}/upgrade`} component={upgrade} />
          <Redirect to="/error" />
        </Switch>
      </AppLayoutRoot>
    );
  }
}
const mapStateToProps = ({ menu }) => {
  const { containerClassnames } = menu;
  return { containerClassnames };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(App)
);
