import React from 'react'
import {
  Row, Col,
  Label, Input,
} from 'reactstrap'
import IntlMessages from "../../../helpers/IntlMessages";
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentComponent = (props) => {
  return !props.values || !props.values.length ? (
    <AddMoreButton
      onAdd={() => props.onAdd()}
      disabled={props.disabled}
    />
  ) : (
      <>
        {
          props.values.map((item, idx) =>
            <TimerComponent
              key={idx}
              item={item}
              name={props.name}
              onChange={props.onChange}
              onRemove={props.onRemove}
              disabled={props.disabled}
            />
          )
        }
        <AddMoreButton
          onAdd={() => props.onAdd()}
          disabled={props.disabled}
        />
      </>
    )
}

const TimerComponent = (props) => {
  return (
    <Row className="mb-3">
      <Col sm={12} xs={4} md={4} lg={4}>
        <Label for="product.name">
          <IntlMessages id="product.add.name" />
        </Label>
        <Input
          value={props.item.name}
          id="name"
          onChange={event => props.onChange(event.target.value, props.name, props.item.id, 'name')}
          disabled={props.disabled}
        />
      </Col>
      <Col sm={12} xs={4} md={4} lg={4}>
        <Label for="product.value">
          <IntlMessages id="product.add.value" />
        </Label>
        <Input
          id="value"
          value={AddCommaNumber(props.item.value)}
          onChange={event => props.onChange(event.target.value, props.name, props.item.id, 'value')}
          disabled={props.disabled}
        />
      </Col>
      <Col sm={12} xs={3} md={3} lg={3}>
        {props.disabled ? (
          <span className="working_hours_btn disable-text-selection">-</span>
        ) : (
            <span className="working_hours_btn disable-text-selection"
              onClick={() => props.onRemove(props.name, props.item.id)}
            >-</span>
          )}
      </Col>
    </Row>
  )
}

const AddMoreButton = (props) => {
  return (
    <Row>
      <Col sm={12} xs={12} md={12} lg={12}>
        <span className="working_hours_btn working_hours_btn-zero-top mb-3 disable-text-selection"
          onClick={props.disabled ? null : props.onAdd}
        >+</span>
      </Col>
    </Row>
  )
}

export default ContentComponent