import React, { memo, useState } from 'react';
import { Input, Label, Form, FormGroup, Row, Button } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { Creatable } from 'react-select';
import DynamicForm from '../general/DynamicForm';
import TooltipItem from '../../../components/common/TooltipItem';

const ContentComponent = (props) => {
    // console.log('render contentFormComponent');

    const [tagsTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Dùng để phân loại khách hàng. Ví dụ: "Khách mới", "Khách VIP"... ',
    });

    return (
        <Form onSubmit={(e) => props.onSubmit(e)}>
            <FormGroup>
                <Label for='name'>
                    <IntlMessages id='customer.name' />
                </Label>
                <Input
                    autoFocus
                    type='text'
                    name='name'
                    id='name'
                    // disabled={props.setting.viewonly && !props.setting.add}
                    value={props.values.name}
                    onChange={props.onChange}
                />
            </FormGroup>
            <Row>
                <Colxx sm={12} md={12} lg={12}>
                    <FormGroup>
                        <Label for='phone'>
                            <IntlMessages id='customer.phone' />
                        </Label>
                        <Input
                            type='phone'
                            name='phone'
                            id='phone'
                            // disabled={
                            //     props.setting.viewonly && !props.setting.add
                            // }
                            value={props.values.phone}
                            onChange={props.onChange}
                        />
                    </FormGroup>
                </Colxx>
                <Colxx sm={12} md={12} lg={12}>
                    <FormGroup>
                        <Label for='email'>
                            <IntlMessages id='customer.email' />
                        </Label>
                        <Input
                            type='email'
                            name='email'
                            id='email'
                            // disabled={
                            //     props.setting.viewonly && !props.setting.add
                            // }
                            value={props.values.email}
                            onChange={props.onChange}
                        />
                    </FormGroup>
                </Colxx>
            </Row>
            <FormGroup>
                <Label for='address'>
                    <IntlMessages id='customer.address' />
                </Label>
                <Input
                    type='text'
                    name='address'
                    id='address'
                    // disabled={props.setting.viewonly && !props.setting.add}
                    value={props.values.address}
                    onChange={props.onChange}
                />
            </FormGroup>

            <FormGroup>
                <Label for='tags'>
                    <IntlMessages id='customer.tags' />
                    <TooltipItem item={tagsTooltip} id={5} />
                </Label>
                <Creatable
                    // isDisabled={props.setting.viewonly && !props.setting.add}
                    className={'react-select-creatable'}
                    isMulti
                    onChange={(val) => props.onChange(val, 'tags')}
                    options={props.data_tags}
                    placeholder={'Chọn hoặc tạo mới'}
                    name='tags'
                    id='tags'
                    value={props.values.tags}
                />
            </FormGroup>

            <DynamicForm
                values={props.values.dynamic_form}
                data={props.data_dynamic_form}
                onChange={props.onChangeDynamicForm}
                // disabled={props.setting.viewonly && !props.setting.add}
            />
            <Button color='primary'>
                <IntlMessages id='general.btn-add' />
            </Button>
        </Form>
    );
};

export default memo(ContentComponent);
