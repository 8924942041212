const data = {
    'dark.bluenavy': {
        theme: '#236591',
        background: '#18191b',
        primary: '#8f8f8f',
    },

    'dark.blueolympic': {
        theme: '#3e83a1',
        background: '#18191b',
        primary: '#8f8f8f',
    },
    'dark.blueyale': {
        theme: '#38688b',
        background: '#18191b',
        primary: '#8f8f8f',
    },

    'dark.greenlime': {
        theme: '#63883b',
        background: '#1a1b18',
        primary: '#8f8f8f',
    },

    'dark.greenmoss': {
        theme: '#627745',
        background: '#1a1b18',
        primary: '#8f8f8f',
    },
    'dark.greysteel': {
        theme: '#767e8d',
        background: '#1d1d1d',
        primary: '#8f8f8f',
    },

    'dark.orangecarrot': {
        theme: '#ad7140',
        background: '#1b1a19',
        primary: '#8f8f8f',
    },
    'dark.purplemonster': {
        theme: '#7e4877',
        background: '#1d1a1d',
        primary: '#8f8f8f',
    },
    'dark.redruby': {
        theme: '#913a47',
        background: '#1b1919',
        primary: '#8f8f8f',
    },

    'dark.yellowgranola': {
        theme: '#8a722c',
        background: '#1f1e1c',
        primary: '#8f8f8f',
    },

    'light.bluenavy': {
        theme: '#00365a',
        background: '#f8f8f8',
        primary: '#3a3a3a',
    },

    'light.blueolympic': {
        theme: '#008ecc',
        background: '#f8f8f8',
        primary: '#3a3a3a',
    },
    'light.blueyale': {
        theme: '#145388',
        background: '#f8f8f8',
        primary: '#3a3a3a',
    },

    'light.greenlime': {
        theme: '#6fb327',
        background: '#f8f8f8',
        primary: '#3a3a3a',
    },

    'light.greenmoss': {
        theme: '#576a3d',
        background: '#f8f8f8',
        primary: '#3a3a3a',
    },
    'light.greysteel': {
        theme: '#48494b',
        background: '#f8f8f8',
        primary: '#3a3a3a',
    },

    'light.orangecarrot': {
        theme: '#ed7117',
        background: '#f8f8f8',
        primary: '#3a3a3a',
    },
    'light.purplemonster': {
        theme: '#922c88',
        background: '#f8f8f8',
        primary: '#303030',
    },
    'light.redruby': {
        theme: '#900604',
        background: '#f8f8f8',
        primary: '#3a3a3a',
    },

    'light.yellowgranola': {
        theme: '#c0a145',
        background: '#f8f8f8',
        primary: '#3a3a3a',
    },
};
export default data;
