import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import axios from 'axios';
import { END_POINT } from '../../../constants/defaultValues';
import {
    AddCommaNumber,
    NotificatioErrController,
} from '../../../helpers/Utils';

const BreakEvent = () => {
    const [allData, setAllData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(4);

    useEffect(() => {
        dataAll();
    }, []);

    const dataAll = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/breakeven'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => (data ? setAllData({ data }) : null))
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    // console.log('all_data: ', allData);

    // let x;
    // if (activeIndex === 1) {
    //     x = 180;
    // } else if (activeIndex === 2) {
    //     x = 24;
    // } else if (activeIndex === 3) {
    //     x = 6;
    // } else {
    //     x = 2;
    // }
    // console.log('x = ', x);

    let revenue = allData?.data?.orders?.reduce(
        (a, b) => a + parseInt(b.price),
        0
    );

    let revenue_other = allData?.data?.incomes?.reduce(
        (a, b) => a + parseInt(b.value),
        0
    );

    let total_outcomes = allData?.data?.outcomes?.reduce(
        (a, b) => a + parseInt(b.value),
        0
    );

    let fee_location = allData?.data?.outcomes?.find(
        (x) => x.alias === 'location'
    )?.value;

    let fee_internet = allData?.data?.outcomes?.find(
        (x) => x.alias === 'internet'
    )?.value;

    let total_staff_salary = allData?.data?.staffs?.reduce(
        (a, b) => a + parseInt(b.total_salary),
        0
    );

    let stock_used = allData?.data?.stocks?.reduce(
        (a, b) => a + b.total * parseInt(b.avarage_price),
        0
    );

    let fee_other = allData?.data?.outcomes
        ?.filter((x) => x.alias === null)
        .reduce((a, b) => a + parseInt(b.value), 0);

    let total_revenue = revenue + revenue_other;

    let cost_fixed = 0 + parseInt(fee_location);
    let variable_cost =
        parseInt(fee_internet) +
        parseInt(parseInt(fee_other)) +
        parseInt(total_staff_salary) +
        parseInt(stock_used);
    let total_fee = parseInt(variable_cost) + parseInt(cost_fixed);

    let price_products_sale = allData?.data?.products
        ?.filter((x) => x.active_sale === true)
        .reduce((a, b) => a + parseInt(b.price_sale), 0);
    let price_products_normal = allData?.data?.products
        ?.filter((x) => x.active_sale === false)
        .reduce((a, b) => a + parseInt(b.price), 0);

    let price_products = price_products_sale + price_products_normal;
    let price_avarage = price_products / allData?.data?.products?.length;

    let breakeven_orders = (total_fee / (price_avarage * 0.7)).toFixed(0);
    let breakeven_revenue = breakeven_orders * 30000;

    let remaining_revenue = breakeven_revenue - total_revenue;

    let products_sold = allData?.data?.orders?.length;
    let remaining_products = breakeven_orders - products_sold;

    return (
        <div className='h-breakeven'>
            {/* <div style={{ overflow: 'auto' }} className='mb-5'>
                <div
                    style={{ whiteSpace: 'nowrap' }}
                    className='d-flex align-items-center justify-content-center'
                >
                    <div className='breakevent-menu'>
                        <span
                            onClick={() => {
                                setActiveIndex(1);
                            }}
                            className={
                                activeIndex === 1
                                    ? 'active-breakevent breakevent_items cursor-pointer py-1 px-5 items-active'
                                    : 'breakevent_items cursor-pointer py-1 px-5'
                            }
                        >
                            Ngày
                        </span>
                        <span
                            onClick={() => {
                                setActiveIndex(2);
                            }}
                            className={
                                activeIndex === 2
                                    ? 'active-breakevent breakevent_items cursor-pointer py-1 px-5 items-active'
                                    : 'breakevent_items cursor-pointer py-1 px-5'
                            }
                        >
                            Tuần
                        </span>
                        <span
                            onClick={() => {
                                setActiveIndex(3);
                            }}
                            className={
                                activeIndex === 3
                                    ? 'active-breakevent breakevent_items cursor-pointer py-1 px-5 items-active'
                                    : 'breakevent_items cursor-pointer py-1 px-5'
                            }
                        >
                            Tháng
                        </span>
                        <span
                            onClick={() => {
                                setActiveIndex(4);
                            }}
                            className={
                                activeIndex === 4
                                    ? 'active-breakevent breakevent_items cursor-pointer py-1 px-5 items-active'
                                    : 'breakevent_items cursor-pointer py-1 px-5'
                            }
                        >
                            Tất cả
                        </span>
                    </div>
                </div>
            </div> */}
            <div className='d-flex flex-column height-content-breakeven'>
                <Table bordered size='' className='mb-4'>
                    <thead>
                        <tr>
                            <th
                                colSpan='4'
                                className='text-center vertical-align-middle'
                            >
                                <h1 className='mb-0 pb-0'>ĐIỂM HOÀ VỐN</h1>
                            </th>
                        </tr>
                        <tr>
                            <th colSpan='2' className='text-center'>
                                <p className='mb-0'>Doanh thu hoà vốn</p>
                            </th>
                            <th colSpan='2' className='text-center'>
                                <p className='mb-0'>Sản lượng hoà vốn</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td
                                colSpan='2'
                                className='text-center text-primary'
                            >
                                {breakeven_revenue
                                    ? AddCommaNumber(breakeven_revenue)
                                    : 0}{' '}
                                đ
                            </td>
                            <td
                                colSpan='2'
                                className='text-center text-primary'
                            >
                                {breakeven_orders
                                    ? AddCommaNumber(breakeven_orders)
                                    : 0}{' '}
                                sản phẩm
                            </td>
                        </tr>
                        <tr>
                            <td className='text-center'>Đã bán</td>
                            <td className='text-center'>Còn lại</td>
                            <td className='text-center'>Đã bán</td>
                            <td className='text-center'>Còn lại</td>
                        </tr>
                        <tr>
                            <td className='text-center'>
                                {total_revenue
                                    ? AddCommaNumber(total_revenue)
                                    : 0}{' '}
                                đ
                            </td>
                            <td className='text-center text-primary'>
                                {total_revenue
                                    ? AddCommaNumber(remaining_revenue)
                                    : 0}{' '}
                                đ
                            </td>
                            <td className='text-center'>
                                {AddCommaNumber(products_sold)} sản phẩm
                            </td>
                            <td className='text-center text-primary'>
                                {remaining_products} sản phẩm
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <Table bordered size='' className='mb-4'>
                    <tbody>
                        <tr>
                            <td className='text-center bg-primary'>
                                Thời gian hoà vốn mong muốn:{' '}
                                <strong>6 tháng</strong>
                            </td>
                            <td className='text-center bg-primary'>
                                Tỷ lệ giá vốn / giá trung bình:{' '}
                                <strong>30%</strong>
                            </td>
                            <td className='text-center bg-primary'>
                                Giá trung bình:{' '}
                                <strong>
                                    {price_avarage
                                        ? AddCommaNumber(price_avarage)
                                        : 0}{' '}
                                    đ
                                </strong>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <Table bordered size='' className='mb-5'>
                    <thead>
                        <tr>
                            <th
                                rowSpan='2'
                                className='text-center vertical-align-middle'
                            >
                                <p className='mb-0'>Doanh thu</p>
                                <p className='mb-0 text-primary'>
                                    {total_revenue
                                        ? AddCommaNumber(total_revenue)
                                        : 0}{' '}
                                    đ
                                </p>
                            </th>
                            <th colSpan='2' className='text-center'>
                                <p className='mb-0'>Chi phí</p>
                                <p className='mb-0 text-primary'>
                                    {total_fee ? AddCommaNumber(total_fee) : 0}{' '}
                                    đ
                                </p>
                            </th>
                        </tr>
                        <tr>
                            <th className='text-center'>
                                <p className='mb-0'>Chi phí cố định</p>
                                <p className='mb-0'>
                                    {cost_fixed
                                        ? AddCommaNumber(cost_fixed)
                                        : 0}{' '}
                                    đ
                                </p>
                            </th>
                            <th className='text-center'>
                                <p className='mb-0'>Chi phí biến đổi</p>
                                <p className='mb-0'>
                                    {variable_cost
                                        ? AddCommaNumber(variable_cost)
                                        : 0}{' '}
                                    đ
                                </p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td rowSpan='3'>
                                <p>Doanh thu bán hàng</p>
                                <p className='float-right'>
                                    {revenue ? AddCommaNumber(revenue) : 0} đ
                                </p>
                            </td>
                            <td rowSpan='2'>
                                <p>Chi phí đầu tư ban đầu</p>
                                <p className='float-right mb-0'>
                                    {total_outcomes
                                        ? AddCommaNumber(total_outcomes)
                                        : 0}{' '}
                                    đ
                                </p>
                            </td>
                            <td className=''>
                                <span>Nguyên vật liệu</span>
                                <span className='float-right'>
                                    {AddCommaNumber(stock_used)} đ
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Điện, Nước, Internet</span>
                                <span className='float-right'>
                                    {fee_internet
                                        ? AddCommaNumber(fee_internet)
                                        : 0}{' '}
                                    đ
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan='2'>
                                <p>Chi phí đầu tư máy móc thiết bị</p>
                                <p className='float-right mb-0'>0 đ</p>
                            </td>
                            <td>
                                <span>Thuế</span>
                                <span className='float-right'>0 đ</span>
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan='3'>
                                <p>Doanh thu khác</p>
                                <p className='float-right'>
                                    {revenue_other
                                        ? AddCommaNumber(revenue_other)
                                        : 0}{' '}
                                    đ
                                </p>
                            </td>
                            <td>
                                <span>Marketing</span>
                                <span className='float-right'>0 đ</span>
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan='2'>
                                <span>Thuê mặt bằng</span>
                                <span className='float-right mb-0'>
                                    {fee_location
                                        ? AddCommaNumber(fee_location)
                                        : 0}{' '}
                                    đ
                                </span>
                            </td>
                            <td>
                                <span>Nhân công</span>
                                <span className='float-right'>
                                    {AddCommaNumber(total_staff_salary)} đ
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Chi phí khác</span>
                                <span className='float-right'>
                                    {fee_other ? AddCommaNumber(fee_other) : 0}{' '}
                                    đ
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default BreakEvent;
