const data = [
    {
        id: 'dashboards',
        icon: 'iconsminds-shop-4',
        label: 'menu.dashboards',
        to: '/app/dashboards/default',
    },
    {
        id: 'calendars',
        icon: 'iconsminds-calendar-4',
        label: 'menu.calendars',
        to: '/app/calendars',
    },
    {
        id: 'analytics',
        icon: 'iconsminds-line-chart-1',
        label: 'menu.analytics',
        to: '/app/pages/analytics',
        upgrade: 'platinum',
    },
    {
        id: 'pages',
        icon: 'iconsminds-optimization',
        label: 'menu.pages',
        to: '/app/pages/product',
        subs: [
            {
                icon: 'iconsminds-check',
                label: 'menu.todo',
                to: '/app/pages/todo',
            },
            {
                icon: 'iconsminds-printer',
                label: 'menu.invoice',
                to: '/app/pages/order',
            },
            // {
            //     icon: 'simple-icon-present',
            //     label: 'menu.promotion',
            //     to: '/app/pages/promotion',
            //     upgrade: 'platinum',
            // },
            {
                icon: 'simple-icon-people',
                label: 'menu.partner',
                to: '/app/pages/1',
                subs: [
                    {
                        icon: 'simple-icon-people',
                        label: 'analytics.user-staff',
                        to: '/app/pages/user-staff',
                        upgrade: 'platinum',
                    },
                    {
                        icon: 'simple-icon-heart',
                        label: 'analytics.customer',
                        to: '/app/pages/customer',
                        upgrade: 'platinum',
                    },
                    {
                        icon: 'iconsminds-network',
                        label: 'analytics.company-provider',
                        to: '/app/pages/company-provider',
                        upgrade: 'platinum',
                    },
                ],
            },
            {
                icon: 'simple-icon-diamond',
                label: 'menu.money',
                to: '/app/pages/2',
                subs: [
                    {
                        icon: 'iconsminds-money-bag',
                        label: 'analytics.treasury',
                        to: '/app/pages/treasury',
                        upgrade: 'platinum',
                    },
                    {
                        icon: 'iconsminds-add-file',
                        label: 'analytics.income',
                        to: '/app/pages/income',
                        upgrade: 'platinum',
                    },
                    {
                        icon: 'iconsminds-remove-file',
                        label: 'analytics.outcome',
                        to: '/app/pages/outcome',
                        upgrade: 'platinum',
                    },
                ],
            },
            {
                icon: 'simple-icon-layers',
                label: 'menu.m',
                to: '/app/pages/3',
                subs: [
                    {
                        icon: 'simple-icon-list',
                        label: 'menu.thumb-list',
                        to: '/app/pages/product',
                    },
                ],
            },
            {
                icon: 'simple-icon-calculator',
                label: 'menu.warehouse',
                to: '/app/pages/4',
                subs: [
                    {
                        icon: 'simple-icon-basket',
                        label: 'menu.stock',
                        to: '/app/pages/stock',
                    },
                    {
                        icon: 'iconsminds-add-file',
                        label: 'warehouse.import',
                        to: '/app/pages/warehouse-import',
                        upgrade: 'platinum',
                    },
                    {
                        icon: 'iconsminds-remove-file',
                        label: 'warehouse.export',
                        to: '/app/pages/warehouse-export',
                        upgrade: 'platinum',
                    },
                    {
                        icon: 'iconsminds-layer-backward',
                        label: 'warehouse.transfer',
                        to: '/app/pages/warehouse-transfer',
                        upgrade: 'platinum',
                    },
                ],
            },
            {
                icon: 'simple-icon-grid',
                label: 'menu.table-setting',
                to: '/app/pages/table-setting',
            },
            {
                icon: 'iconsminds-qr-code',
                label: 'menu.qrcode',
                to: '/app/pages/qrcode',
            },
        ],
    },
    {
        id: 'message',
        icon: 'iconsminds-speach-bubble-8',
        label: 'menu.message',
        to: '/app/pages/message',
    },
    {
        id: 'breakeven',
        icon: 'iconsminds-coins',
        label: 'breakeven.title',
        to: '/app/breakeven',
        upgrade: 'platinum',
    },
    // {
    //     id: 'faq',
    //     icon: 'iconsminds-idea-2',
    //     label: 'faq.title',
    //     to: '/app/faq',
    // },
    {
        id: 'applications',
        icon: 'iconsminds-profile',
        label: 'menu.applications',
        to: '/app/applications/profile',
        subs: [
            {
                icon: 'iconsminds-profile',
                label: 'menu.profile',
                to: '/app/applications/profile',
            },
            {
                icon: 'simple-icon-user-follow',
                label: 'menu.ref',
                to: '/app/applications/ref',
            },
            {
                icon: 'iconsminds-type-pass',
                label: 'menu.forgot-password',
                to: '/user/forgot-password',
                denied: true,
            },
            // {
            //   icon: "iconsminds-lock-2",
            //   label: "menu.login",
            //   to: "/user/login",
            //   denied: true,
            // },
            {
                icon: 'simple-icon-diamond',
                label: 'menu.upgrade',
                to: '/app/applications/upgrade',
                denied: true,
            },
            {
                icon: 'simple-icon-settings',
                label: 'menu.setting',
                to: '/app/applications/setting',
                denied: true,
            },
        ],
    },
];
export default data;
