import React, { Component } from 'react';
import {
    Row,
    Button,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Collapse,
    ButtonDropdown,
} from 'reactstrap';
import { injectIntl } from 'react-intl';

import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import Breadcrumb from '../../navs/Breadcrumb';
import IntlMessages from '../../../helpers/IntlMessages';
import classnames from 'classnames';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const formatDatasExport = (data) => {
    let custs = [];

    for (let i = 0, j = data?.length; i < j; i++) {
        custs.push({
            Stt: i + 1,
            'Tiêu đề': data[i].title,
            'Mô tả': data[i].description,
            'Tình trạng': data[i].status_name,
            'Ngày thực hiện': data[i].date,
            'Giờ thực hiện': data[i].time,
        });
    }
    return custs;
};

const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const exportToCSV = ({ csvData, fileName }) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};

class PageHeading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownSplitOpen: false,
        };
    }

    handleDownloadData = () => {
        const fileName = 'Danh sách Việc cần làm';

        exportToCSV({
            csvData: formatDatasExport(this.props.data),
            fileName,
        });
    };

    toggleSplit = () => {
        this.setState((prevState) => ({
            dropdownSplitOpen: !prevState.dropdownSplitOpen,
        }));
    };

    toggleDisplayOptions = () => {
        this.setState((prevState) => ({
            displayOptionsIsOpen: !prevState.displayOptionsIsOpen,
        }));
    };

    render() {
        const { messages } = this.props.intl;
        const {
            heading,
            setting,
            match,
            totalItem,
            isDisabledAdd,
            isDisabledDel,
            isDisabledUpdate,
            toggleFunc,
            changePageSizeFunc,
            onSearchKeyFunc,
        } = this.props;

        const startIndex = (setting.currentPage - 1) * setting.selectedPageSize;
        const endIndex = setting.currentPage * setting.selectedPageSize;

        const { displayOptionsIsOpen } = this.state;
        return (
            <Row className='list-page-heading__row'>
                <Colxx xxs='12'>
                    <div className='mb-2'>
                        <h1>
                            <IntlMessages id={heading} />
                        </h1>
                        <div className='text-zero top-right-button-container'>
                            {/* <Button
                color="primary"
                size="md"
                className={classnames("top-right-button", {
                  disable: isDisabledAdd
                })}
                onClick={() => toggleFunc()}
              >
                <IntlMessages id="pages.add-new" />
              </Button>
              {"  "} */}
                            <ButtonDropdown
                                isOpen={this.state.dropdownSplitOpen}
                                toggle={this.toggleSplit}
                            >
                                <Button
                                    color='primary'
                                    size='md'
                                    className={classnames('top-right-button', {
                                        disable: isDisabledAdd,
                                    })}
                                    onClick={() => toggleFunc()}
                                >
                                    <IntlMessages id='pages.add-new' />
                                </Button>
                                <DropdownToggle caret color='primary' />
                                <DropdownMenu
                                    className={classnames({
                                        disable:
                                            isDisabledAdd ||
                                            isDisabledDel ||
                                            isDisabledUpdate,
                                    })}
                                >
                                    {/* <DropdownItem>
                                        Tải lên từ file excel
                                    </DropdownItem>
                                    <DropdownItem divider /> */}
                                    <DropdownItem
                                        onClick={
                                            isDisabledAdd ||
                                            isDisabledDel ||
                                            isDisabledUpdate
                                                ? null
                                                : this.handleDownloadData
                                        }
                                    >
                                        Tải về file excel
                                    </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </div>
                        <Breadcrumb match={match} />
                    </div>

                    <div className='mb-2'>
                        <Button
                            color='empty'
                            className='pt-0 pl-0 d-inline-block d-md-none'
                            onClick={this.toggleDisplayOptions}
                        >
                            <IntlMessages id='pages.display-options' />{' '}
                            <i className='simple-icon-arrow-down align-middle' />
                        </Button>
                        <Collapse
                            isOpen={displayOptionsIsOpen}
                            className='d-md-block'
                            id='displayOptions'
                        >
                            <div className='d-block d-md-inline-block'></div>
                            <div className='float-md-right'>
                                <span className='text-muted text-small mr-1'>{`${startIndex}-${endIndex} of ${totalItem} `}</span>
                                <UncontrolledDropdown className='d-inline-block'>
                                    <DropdownToggle
                                        caret
                                        color='outline-dark'
                                        size='xs'
                                    >
                                        {setting.selectedPageSize}
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        {setting.pageSizes.map(
                                            (size, index) => {
                                                return (
                                                    <DropdownItem
                                                        key={index}
                                                        onClick={() =>
                                                            changePageSizeFunc(
                                                                size
                                                            )
                                                        }
                                                    >
                                                        {size}
                                                    </DropdownItem>
                                                );
                                            }
                                        )}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </Collapse>
                    </div>
                    <Separator />
                </Colxx>
            </Row>
        );
    }
}

export default injectIntl(PageHeading);
