import React from 'react';
// import { Badge } from 'reactstrap';
import ReactTable from 'react-table';
import Pagination from '../../../components/DatatablePagination';
import moment from 'moment';
import { AddCommaNumber, AddCommaPhoneNumber } from '../../../helpers/Utils';

const ContentMultiForm = (props) => {
    const { data } = props;
    // console.log('data: ', data);
    const columns = [
        {
            Header: 'Tên khách hàng',
            accessor: 'name',
            minWidth: 150,
            Cell: (props) => (
                <span className='text-capitalize'>{props.value}</span>
            ),
        },
        {
            Header: 'Số điện thoại',
            accessor: 'phone',
            Cell: (props) => AddCommaPhoneNumber(props.value),
        },
        {
            Header: 'Email',
            accessor: 'email',
            Cell: (props) => (
                <span className='text-lowercase'>{props.value}</span>
            ),
        },
        // {
        //     Header: 'Tags',
        //     accessor: 'tags',
        //     Cell: props => {
        //         props.value.map(d => {
        //             <Badge>{d.value}</Badge>
        //         })
        //     },
        // },
        {
            Header: 'Số điểm',
            accessor: 'point',
            Cell: (props) => AddCommaNumber(props.value),
        },
        {
            Header: 'Số lần sử dụng',
            accessor: 'used',
            Cell: (props) => AddCommaNumber(props.value),
        },
        {
            Header: 'Danh hiệu',
            accessor: 'armorial',
        },
        {
            Header: 'Công nợ',
            accessor: 'debt',
            Cell: (props) => AddCommaNumber(props.value),
        },
        {
            Header: 'Ngày tạo',
            accessor: 'createdAt',
            Cell: (props) => moment(props.value).format('DD/MM/YYYY'),
            minWidth: 200,
        },
        {
            Header: 'Ngày cập nhật',
            accessor: 'updatedAt',
            Cell: (props) => moment(props.value).format('DD/MM/YYYY'),
            minWidth: 200,
        },
    ];
    return (
        <ReactTable
            defaultPageSize={20}
            data={data}
            columns={columns}
            minRows={0}
            showPageJump={false}
            showPageSizeOptions={true}
            PaginationComponent={Pagination}
            NoDataComponent={() => null}
        />
    );
};

export default ContentMultiForm;
