import moment from 'moment';
import React from 'react';

import DatePicker from 'react-datepicker';
import SingleLightbox from '../../components/pages/SingleLightbox';
import {
    CustomInput,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Label,
} from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import IntlMessages from '../../helpers/IntlMessages';
import { AddCommaPhoneNumber, getAvatar } from '../../helpers/Utils';
import { GetBank, SetBank } from '../../helpers/ListBank';
import Select from 'react-select';
import CustomSelectInput from '../../components/common/CustomSelectInput';

// const copyToClipboard = (value) => {
//     let input = document.createElement('input');
//     input.setAttribute('value', value);
//     document.body.appendChild(input);
//     input.select();
//     input.setSelectionRange(0, 99999); /*For mobile devices*/
//     document.execCommand('copy');
//     document.body.removeChild(input);

//     NotificatioSucController('Thành công');
// };

const ContentFormCompany = ({
    companyName,
    companyStartTime,
    handleChangeValue,
    handleChangeCompanyStartTime,
    handleChangeCompanyEndTime,
    companyEndTime,
    companyAddress,
    hotline,
    companyWebsite,
    companyImageFile,
    companyImage,
    companyLogoFile,
    companyLogo,
    handleDeleteImage,
    namePay,
    numberPay,
    bankPay,
    qrPayFile,
    qrPayImage,
}) => {
    // console.log('companyImageFile: ', companyImageFile);
    // console.log('companyImage: ', companyImage);
    // console.log('qrPayFile: ', qrPayFile);
    // console.log('bankPay: ', bankPay);
    return (
        <>
            <Form>
                <FormGroup row>
                    {/* COMPANY NAME */}
                    <Colxx sm={12}>
                        <FormGroup>
                            <Label for='company-name'>
                                <IntlMessages id='company-name' />
                            </Label>
                            <Input
                                type='text'
                                name='companyName'
                                id='company-name'
                                value={companyName}
                                onChange={handleChangeValue}
                            />
                        </FormGroup>
                    </Colxx>

                    {/* COMPANY START TIME */}
                    <Colxx sm={6}>
                        <FormGroup>
                            <Label for='company-start-time'>
                                <IntlMessages id='profile.company-start-time' />
                            </Label>
                            <DatePicker
                                name='companyStartTime'
                                id='company-start-time'
                                className='mb-3'
                                onChange={(time) =>
                                    handleChangeCompanyStartTime(time)
                                }
                                selected={moment(companyStartTime, 'HH:mm')}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={5}
                                timeCaption='Giờ'
                                timeFormat='HH:mm'
                                dateFormat='HH:mm'
                            />
                        </FormGroup>
                    </Colxx>

                    {/* COMPANY END TIME */}
                    <Colxx sm={6}>
                        <FormGroup>
                            <Label for='company-end-time'>
                                <IntlMessages id='profile.company-end-time' />
                            </Label>
                            <DatePicker
                                name='companyEndTime'
                                id='company-end-time'
                                className='mb-3'
                                onChange={(time) =>
                                    handleChangeCompanyEndTime(time)
                                }
                                selected={moment(companyEndTime, 'HH:mm')}
                                showTimeSelectOnly
                                showTimeSelect
                                timeIntervals={5}
                                timeCaption='Giờ'
                                timeFormat='HH:mm'
                                dateFormat='HH:mm'
                            />
                        </FormGroup>
                    </Colxx>

                    {/* COMPANY ADDRESS */}
                    <Colxx sm={12}>
                        <FormGroup>
                            <Label for='company-address'>
                                <IntlMessages id='profile.company-address' />
                            </Label>
                            <Input
                                type='text'
                                name='companyAddress'
                                id='company-address'
                                value={companyAddress}
                                onChange={handleChangeValue}
                            />
                        </FormGroup>
                    </Colxx>

                    {/* HOTLINE */}
                    <Colxx sm={12}>
                        <FormGroup>
                            <Label for='hotline'>Hot-line</Label>
                            <Input
                                type='text'
                                name='hotline'
                                id='hotline'
                                value={AddCommaPhoneNumber(hotline)}
                                onChange={handleChangeValue}
                            />
                        </FormGroup>
                    </Colxx>

                    {/* COMPANY WEBSITE */}
                    <Colxx sm={12}>
                        <FormGroup>
                            <Label for='company-website'>
                                <IntlMessages id='profile.company-website' />
                            </Label>
                            <Input
                                type='text'
                                name='companyWebsite'
                                id='company-website'
                                value={companyWebsite}
                                onChange={handleChangeValue}
                            />
                        </FormGroup>
                    </Colxx>

                    {/* COMPANY IMAGE */}
                    <Colxx sm={12}>
                        <FormGroup>
                            <Label for='company-image'>
                                <IntlMessages id='profile.company-image' />
                                {' (1920px x 1080px) '}
                                {!companyImageFile && companyImage ? (
                                    <span
                                        onClick={() => {
                                            handleDeleteImage('companyImage');
                                        }}
                                        className={
                                            'cursor-pointer glyph-icon simple-icon-pencil'
                                        }
                                    />
                                ) : null}
                            </Label>
                            {companyImageFile || !companyImage ? (
                                <InputGroup
                                    className='mb-3'
                                    encType='multipart/form-data'
                                >
                                    <CustomInput
                                        label={companyImage}
                                        type='file'
                                        id='company-image'
                                        name='companyImage'
                                        onChange={handleChangeValue}
                                    />
                                </InputGroup>
                            ) : (
                                <SingleLightbox
                                    thumb={getAvatar(companyImage)}
                                    large={getAvatar(companyImage)}
                                    className='responsive border-0 card-img-top mb-3 mxh-700 profile-company-image'
                                />
                            )}
                        </FormGroup>
                    </Colxx>

                    {/* COMPANY LOGO */}
                    <Colxx sm={12}>
                        <FormGroup>
                            <Label for='company-logo'>
                                <IntlMessages id='profile.company-logo' />
                                {' (90px x 90px) '}
                                {!companyLogoFile && companyLogo ? (
                                    <span
                                        onClick={() => {
                                            handleDeleteImage('companyLogo');
                                        }}
                                        className={
                                            'cursor-pointer glyph-icon simple-icon-pencil'
                                        }
                                    />
                                ) : null}
                            </Label>
                            {companyLogoFile || !companyLogo ? (
                                <InputGroup
                                    className='mb-3'
                                    encType='multipart/form-data'
                                >
                                    <CustomInput
                                        label={companyLogo}
                                        type='file'
                                        id='company-logo'
                                        name='companyLogo'
                                        onChange={handleChangeValue}
                                    />
                                </InputGroup>
                            ) : (
                                <div className='company-avatar'>
                                    <img
                                        src={getAvatar(companyLogo)}
                                        alt='logo'
                                    />
                                </div>
                            )}
                        </FormGroup>
                    </Colxx>

                    <Colxx className='mt-4'>
                        <h4>THÔNG TIN THANH TOÁN</h4>
                    </Colxx>

                    {/* BANK PAY */}
                    <Colxx sm={12}>
                        <FormGroup>
                            <Label for='bank-pay'>Ngân hàng</Label>
                            <Select
                                defaultValue={GetBank(bankPay)}
                                components={{ Input: CustomSelectInput }}
                                className='react-select'
                                classNamePrefix='react-select'
                                name='bankPay'
                                placeholder={'Nhập tên ngân hàng'}
                                options={SetBank}
                                onChange={(e) =>
                                    handleChangeValue(e, 'bankPay')
                                }
                                menuPlacement='top'
                                // value={GetBank(bankPay)}
                            />
                        </FormGroup>
                    </Colxx>

                    {/* NAME PAY */}
                    <Colxx sm={12}>
                        <FormGroup>
                            <Label for='name-pay'>Tên chủ tài khoản</Label>
                            <Input
                                type='text'
                                name='namePay'
                                id='name-pay'
                                value={namePay}
                                onChange={handleChangeValue}
                            />
                        </FormGroup>
                    </Colxx>

                    {/* NUMBER PAY */}
                    <Colxx sm={12}>
                        <FormGroup>
                            <Label for='number-pay'>Số tài khoản</Label>
                            <Input
                                type='text'
                                name='numberPay'
                                id='number-pay'
                                value={numberPay}
                                onChange={handleChangeValue}
                            />
                        </FormGroup>
                    </Colxx>

                    {/* QR Pay Image */}
                    {/* <Colxx sm={12}>
                        <FormGroup>
                            <Label for='qr-pay'>
                                Mã QR
                                {' - Kích thước: 200px x 200px'}
                                {!qrPayFile && qrPayImage ? (
                                    <span
                                        onClick={() => {
                                            handleDeleteImage('qrPay');
                                        }}
                                        className={
                                            'cursor-pointer glyph-icon simple-icon-pencil'
                                        }
                                    />
                                ) : null}
                            </Label>
                            {qrPayFile || !qrPayImage ? (
                                <InputGroup
                                    className='mb-3'
                                    encType='multipart/form-data'
                                >
                                    <CustomInput
                                        label={qrPayImage}
                                        type='file'
                                        id='qr-pay'
                                        name='qrPayImage'
                                        onChange={handleChangeValue}
                                    />
                                </InputGroup>
                            ) : (
                                <div className='qr-pay text-center'>
                                    <img
                                        src={getAvatar(qrPayImage)}
                                        alt='qr-pay'
                                        className='mxw-200'
                                    />
                                </div>
                            )}
                        </FormGroup>
                    </Colxx> */}
                </FormGroup>
            </Form>
        </>
    );
};

export default ContentFormCompany;
