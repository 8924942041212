import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { AddCommaNumber } from '../../../helpers/Utils';
import { Colxx } from '../../../components/common/CustomBootstrap';
import { SetIceOrSugar } from '../../../helpers/Product';
import classnames from 'classnames';

const ContentComponent = (props) => {
    return (
        <Row className='card-body-custom product-btn'>
            {SetIceOrSugar().map((_item, idx) => (
                <Colxx key={idx} className='mt-1 pad-2px w-20'>
                    <Button
                        color='secondary'
                        size='sm'
                        outline
                        block
                        onClick={() =>
                            props.disabled
                                ? null
                                : props.onSelected({
                                      name: props.name,
                                      value: _item.value,
                                  })
                        }
                        className={classnames({
                            'btn-selected': props.selectedValue.includes(
                                _item.value
                            ),
                            'btn-disabled': !props.selectedValue.includes(
                                _item.value
                            ),
                        })}
                    >
                        {_item.label}
                    </Button>
                </Colxx>
            ))}
        </Row>
    );
};

export default ContentComponent;
