import React, { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactDOM from 'react-dom';
import {
    Collapse,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Table,
    InputGroup,
    Form,
    Row,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { AddCommaNumber } from '../../../helpers/Utils';
import classnames from 'classnames';

const renderListOrders = ({
    orders,
    incrementItem,
    decrementItem,
    onRemoveOrder,
}) => {
    return (
        orders.length && (
            <Table className='table mb-0'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th className='text-muted text-center text-extra-small mb-2'>
                            Tên sản phẩm
                        </th>
                        <th className='text-muted text-center text-extra-small mb-2'>
                            Số lượng
                        </th>
                        <th className='text-center text-muted text-extra-small mb-2'>
                            Đơn giá
                        </th>
                        {/* <th className="text-right text-muted text-extra-small mb-2">
            Chi tiết
          </th> */}
                        <th className='text-right text-muted text-extra-small mb-2 cursor-pointer'>
                            Xóa
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order, idx) => (
                        <React.Fragment key={order.id}>
                            <tr>
                                <td>{idx + 1}</td>
                                <td onClick={() => incrementItem(order)}>
                                    {order.name}
                                </td>
                                <td
                                    className='text-center'
                                    onClick={() => decrementItem(order)}
                                >
                                    {order.count}
                                </td>
                                <td className='text-center'>
                                    {AddCommaNumber(parseInt(order.price))}
                                </td>
                                <td
                                    className='text-right cursor-pointer'
                                    onClick={() => onRemoveOrder(order.id)}
                                >
                                    <i className='simple-icon-trash' />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan='5' className='pad-lf-075rem'>
                                    <Collapse isOpen={order.previewDisplay}>
                                        <p>preview detail</p>
                                    </Collapse>
                                </td>
                            </tr>
                            {/* <tr>
                                <td colSpan='5'>
                                    <Separator />
                                </td>
                            </tr> */}
                        </React.Fragment>
                    ))}
                </tbody>
            </Table>
        )
    );
};

const RenderModalContent = ({
    totalPrice,
    onToggleModal,
    orders,
    incrementItem,
    decrementItem,
    phone,
    name,
    companyName,
    onChange,
    onRemoveOrder,
    handleSubmit,
}) => (
    <>
        <ModalHeader toggle={onToggleModal} className='border-bottom-0 p-3'>
            <IntlMessages id='pages.order-new-modal-title' />
        </ModalHeader>
        <ModalBody className='pad-model-order-preview table-right scroll'>
            <PerfectScrollbar
                options={{
                    suppressScrollX: true,
                    wheelPropagation: false,
                }}
                id='table-orders'
            >
                {renderListOrders({
                    orders,
                    incrementItem,
                    decrementItem,
                    onRemoveOrder,
                })}
                <div className='p75 mt-2'>
                    <Label className='mt-2'>
                        <IntlMessages id='pages.total-order-price' />
                    </Label>
                    <span className='float-right'>
                        <strong>{AddCommaNumber(totalPrice)}</strong>
                    </span>
                </div>
            </PerfectScrollbar>
        </ModalBody>
        <ModalFooter className='d-flex flex-column p-3'>
            <Row className='mb-3'>
                <Colxx xxs='6' className='pr-0'>
                    <Input
                        onChange={onChange}
                        value={name}
                        placeholder='Tên khách hàng'
                        name='customer_name'
                        className='border-top-0 border-right-0 border-left-0 rounded-0 p-1'
                    />
                </Colxx>
                <Colxx xxs='6' className='pr-0'>
                    <Input
                        onChange={onChange}
                        value={phone}
                        placeholder='Số điện thoại'
                        name='customer_phone'
                        className='border-primary border-top-0 border-right-0 border-left-0 rounded-0 p-1'
                    />
                </Colxx>
            </Row>
            <Button className='w-100' color='primary' onClick={handleSubmit}>
                {'Đặt ngay !'}
            </Button>{' '}
        </ModalFooter>
    </>
);

const ProductOrderModal = ({
    isOpen,
    onToggleModal,
    orders,
    incrementItem,
    decrementItem,
    phone,
    name,
    companyName,
    onToggleNote,
    onChange,
    onRemoveOrder,
    handleSubmit,
    calTotalPrice,
    isFromMenu,
}) => {
    let totalPrice = calTotalPrice(orders);

    return !isFromMenu ? (
        <Modal
            isOpen={isOpen}
            toggle={onToggleModal}
            wrapClassName='modal-right'
            backdrop={true}
        >
            <RenderModalContent
                totalPrice={totalPrice}
                orders={orders}
                incrementItem={incrementItem}
                decrementItem={decrementItem}
                phone={phone}
                name={name}
                companyName={companyName}
                onChange={onChange}
                onRemoveOrder={onRemoveOrder}
                onToggleModal={onToggleModal}
                handleSubmit={handleSubmit}
            />
        </Modal>
    ) : (
        document.getElementById('table-chairs') &&
            ReactDOM.createPortal(
                <div
                    style={{
                        position: 'relative',
                        zIndex: 1050,
                    }}
                    tabIndex={-1}
                >
                    <div className='modal-right modal-right-custom'>
                        <div
                            className={classnames({
                                'modal show d-block': isOpen,
                            })}
                            role='dialog'
                            tabIndex={-1}
                        >
                            <div
                                className='modal-dialog z-index-100'
                                role='document'
                            >
                                <div className='modal-content'>
                                    <RenderModalContent
                                        totalPrice={totalPrice}
                                        orders={orders}
                                        incrementItem={incrementItem}
                                        decrementItem={decrementItem}
                                        phone={phone}
                                        name={name}
                                        companyName={companyName}
                                        onChange={onChange}
                                        onRemoveOrder={onRemoveOrder}
                                        onToggleModal={onToggleModal}
                                        handleSubmit={handleSubmit}
                                    />
                                </div>
                            </div>
                            <div
                                onClick={onToggleModal}
                                className={classnames({
                                    'modal-backdrop fade show z-index-99':
                                        isOpen,
                                })}
                            />
                        </div>
                    </div>
                </div>,
                document.getElementById('table-chairs')
            )
    );
};

export default ProductOrderModal;
