import React from 'react';
import { Input, Label, Form, FormGroup, Row, Col } from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import Select from 'react-select';
import CustomSelectInput from '../../../components/common/CustomSelectInput';
import {
    LogsValueOvertimeCoefficient,
    GetLogsValueOvertimeCoefficient,
    LogsType,
    GetLogsType,
} from '../../../helpers/UserStaff';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentComponent = (props) => {
    return (
        <Form>
            <FormGroup>
                {/* User Staff */}
                <Label for='logs_users_staff'>
                    <IntlMessages id='user-staff.logs_users_staff' />
                </Label>
                <Select
                    components={{ Input: CustomSelectInput }}
                    className='react-select'
                    classNamePrefix='react-select'
                    name='logs_users_staff'
                    isMulti
                    placeholder={
                        <IntlMessages id='user-staff.select-all-users' />
                    }
                    options={props.data_all}
                    defaultValue={props.values.logs_users_staff}
                    onChange={(e) => {
                        props.onChange(e, 'logs_users_staff');
                    }}
                />
            </FormGroup>

            {/* Type */}
            <FormGroup>
                <Label for='logs_type'>
                    <IntlMessages id='user-staff.logs_type' />
                </Label>
                <Select
                    components={{ Input: CustomSelectInput }}
                    className='react-select'
                    classNamePrefix='react-select'
                    name='logs_type'
                    placeholder={<IntlMessages id='user-staff.select-one' />}
                    options={LogsType()}
                    defaultValue={GetLogsType(props.values.logs_type)}
                    onChange={(e) => {
                        props.onChange(e, 'logs_type');
                    }}
                />
            </FormGroup>

            {props.values.logs_type === 'overtime_hour' ? (
                <FormGroup>
                    {/* Over time overtime_coefficient */}
                    <Label for='logs_value_overtime_coefficient'>
                        <IntlMessages id='user-staff.overtime_coefficient' />
                    </Label>
                    <Select
                        components={{ Input: CustomSelectInput }}
                        className='react-select'
                        classNamePrefix='react-select'
                        name='logs_value_overtime_coefficient'
                        placeholder={
                            <IntlMessages id='user-staff.select-one' />
                        }
                        options={LogsValueOvertimeCoefficient()}
                        defaultValue={GetLogsValueOvertimeCoefficient(
                            props.values.logs_value_overtime_coefficient
                        )}
                        onChange={(e) => {
                            props.onChange(
                                e,
                                'logs_value_overtime_coefficient'
                            );
                        }}
                    />
                </FormGroup>
            ) : null}

            <FormGroup>
                {props.values.logs_type === 'overtime_hour' ||
                props.values.logs_type === 'day_off_hour' ? (
                    <Label for='logs_value'>
                        <IntlMessages id='user-staff.logs_value_hour' />
                    </Label>
                ) : (
                    <Label for='logs_value'>
                        <IntlMessages id='user-staff.logs_value_salary' />
                    </Label>
                )}
                <Input
                    name='logs_value'
                    id='logs_value'
                    value={AddCommaNumber(props.values.logs_value)}
                    onChange={props.onChange}
                />
            </FormGroup>
        </Form>
    );
};

export default ContentComponent;
