import React from 'react'
import {
    Input
} from 'reactstrap'
import { AddCommaPhoneNumber } from '../../helpers/Utils';

const ContentComponent = (props) => {
    return (
        <Input
            name={props.name || "undefined"}
            disabled={props.disabled || false}
            value={AddCommaPhoneNumber(props.value)}
            onChange={props.onChange}
            type="text"
            maxLength={12}
        />
    )
}

export default ContentComponent;