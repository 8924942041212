import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Collapse,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Table,
    Row,
} from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import IntlMessages from '../../helpers/IntlMessages';
import { AddCommaNumber } from '../../helpers/Utils';

const renderListOrders = ({
    orders,
    incrementItem,
    decrementItem,
    onRemoveOrder,
}) => {
    return (
        orders.length && (
            <Table className='table mb-0'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th className='text-muted text-center text-extra-small mb-2'>
                            Tên sản phẩm
                        </th>
                        <th className='text-muted text-center text-extra-small mb-2'>
                            Số lượng
                        </th>
                        <th className='text-center text-muted text-extra-small mb-2'>
                            Đơn giá
                        </th>
                        <th className='text-right text-muted text-extra-small mb-2 cursor-pointer'>
                            Xóa
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order, idx) => (
                        <React.Fragment key={order.id}>
                            <tr>
                                <td>{idx + 1}</td>
                                <td onClick={() => incrementItem(order)}>
                                    {order.name}
                                </td>
                                <td
                                    className='text-center'
                                    onClick={() => decrementItem(order)}
                                >
                                    {order.count}
                                </td>
                                <td className='text-center'>
                                    {AddCommaNumber(parseInt(order.price))}
                                </td>
                                <td
                                    className='text-right cursor-pointer'
                                    onClick={() => onRemoveOrder(order.id)}
                                >
                                    <i className='simple-icon-trash' />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan='5' className='pad-lf-075rem'>
                                    <Collapse isOpen={order.previewDisplay}>
                                        <p>preview detail</p>
                                    </Collapse>
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </Table>
        )
    );
};

const ProductOrderModalMenu = ({
    isOpen,
    onToggleModal,
    orders,
    incrementItem,
    decrementItem,
    phone,
    name,
    customer_debt,
    // companyName,
    onChange,
    onRemoveOrder,
    handleSubmit,
    calTotalPrice,
    refund_to_customer,
}) => {
    // console.log('customer_debt: ', customer_debt);
    let totalPrice = calTotalPrice(orders);

    return (
        <Modal
            isOpen={isOpen}
            toggle={onToggleModal}
            wrapClassName='modal-right'
            backdrop={true}
        >
            <ModalHeader toggle={onToggleModal} className='border-bottom-0 p-3'>
                <IntlMessages id='pages.order-new-modal-title' />
            </ModalHeader>
            <ModalBody className='pad-model-order-preview table-right scroll'>
                <PerfectScrollbar
                    options={{
                        suppressScrollX: true,
                        wheelPropagation: false,
                    }}
                    id='table-orders'
                >
                    {renderListOrders({
                        orders,
                        incrementItem,
                        decrementItem,
                        onRemoveOrder,
                    })}
                    <div className='p75 mt-2'>
                        <Label className='mt-2'>
                            <IntlMessages id='pages.total-order-price' />
                        </Label>
                        <span className='float-right'>
                            <strong>{AddCommaNumber(totalPrice)}</strong>
                        </span>
                    </div>
                </PerfectScrollbar>
                {/* {customer_debt !== '' ? ( */}
                {/* <Row>
                    <span>Còn nợ: </span>
                    <span>{customer_debt}</span>
                </Row> */}
                {/* ) : null} */}
            </ModalBody>
            <ModalFooter className='d-flex flex-column p-3'>
                <Row className='mb-3 flex-nowrap'>
                    <Colxx sm='6' className='pr-0'>
                        <Input
                            onChange={onChange}
                            value={name}
                            placeholder='Tên khách hàng'
                            name='customer_name'
                            className='border-top-0 border-right-0 border-left-0 rounded-0 p-1'
                        />
                    </Colxx>
                    <Colxx sm='6' className='pr-0'>
                        <Input
                            onChange={onChange}
                            value={phone}
                            placeholder='Số điện thoại'
                            name='customer_phone'
                            className='border-primary border-top-0 border-right-0 border-left-0 rounded-0 p-1'
                        />
                    </Colxx>
                </Row>
                <Button
                    className='w-100'
                    color='primary'
                    onClick={
                        refund_to_customer < 0 && (name === '' || phone === '')
                            ? null
                            : handleSubmit
                    }
                >
                    {'Đặt ngay !'}
                </Button>{' '}
            </ModalFooter>
        </Modal>
    );
};

export default ProductOrderModalMenu;
