import React from 'react';
import { Badge, Button, Label, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';

const ContentDisplayMobile = (props) => {
    // console.log(props);
    return (
        <>
            <Row className='mt-3'>
                <Colxx xxs='12' lg='6'>
                    <p className='text-muted text-small mb-2'>
                        <IntlMessages id='make-product.number-product-used' />
                    </p>
                    <p className='text-lowercase'>{props.values.make.length}</p>
                </Colxx>
                <Colxx xxs='12' lg='6'>
                    <p className='text-muted text-small mb-2'>
                        <IntlMessages id='make-product.price' />
                    </p>
                    <p className='text-capitalize'>20.000đ</p>
                </Colxx>
                <Colxx xxs='12' lg='6'>
                    <p className='text-muted text-small mb-2'>
                        <IntlMessages id='make-product.cost' />
                    </p>
                    <p className='text-lowercase'>5.000đ</p>
                </Colxx>
                <Colxx xxs='12' lg='6'>
                    <p className='text-muted text-small mb-2'>
                        <IntlMessages id='make-product.percent-cost' />
                    </p>
                    <p className='text-capitalize'>25%</p>
                </Colxx>
                <Colxx xxs='12' lg='6'>
                    {props.values.make.length ? (
                        <ul className={'orders-pending'}>
                            {props.values.make.map((stock) => (
                                <li key={stock.id} className='pr-2'>
                                    <Button color='primary'>
                                        {stock.name}{' '}
                                        <Badge color='light' className='ml-2'>
                                            {stock.count +
                                                ' (' +
                                                stock.unit +
                                                ')'}
                                        </Badge>{' '}
                                    </Button>
                                </li>
                            ))}
                        </ul>
                    ) : null}
                </Colxx>
            </Row>
        </>
    );
};

export default ContentDisplayMobile;
