import React from 'react';
import { useRef, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

export function useHorizontalScroll() {
    const elRef = useRef();
    useEffect(() => {
        const el = elRef.current;
        if (el) {
            const onWheel = (e) => {
                if (e.deltaY == 0) return;
                e.preventDefault();
                el.scrollTo({
                    left: el.scrollLeft + e.deltaY,
                    behavior: 'smooth',
                });
            };
            el.addEventListener('wheel', onWheel);
            return () => el.removeEventListener('wheel', onWheel);
        }
    }, []);
    return elRef;
}

const CategoryMenu = (props) => {
    const scrollRef = useHorizontalScroll();
    return (
        <div
            ref={scrollRef}
            style={{ overflow: 'auto' }}
            className='w-100 category-menu mb-3'
        >
            <div
                style={{ whiteSpace: 'nowrap' }}
                className='d-flex align-items-center'
            >
                <span
                    onClick={() => {
                        props.onClickCategory('all', 99);
                    }}
                    className={
                        props.activeIndex === 99
                            ? 'active-menu nav_items cursor-pointer py-1 px-3 items-active'
                            : 'nav_items cursor-pointer py-1 px-3 items-active'
                    }
                >
                    Tất cả
                </span>
                {props.category}
            </div>
        </div>
    );
};

export default CategoryMenu;
