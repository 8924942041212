import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import { END_POINT } from '../../constants/defaultValues';

const FooterPage = (props) => {
    const [freePackage, setFreePackage] = useState(false);
    const [proPackage, setProPackage] = useState(false);

    useEffect(() => {
        getExpired();
    }, []);

    const getExpired = () => {
        let tokenStr = localStorage.getItem('access_token');
        return Axios.get(`${END_POINT + '/auth/get-expired'}`, {
            headers: { Authorization: `Bearer ${tokenStr}` },
        })
            .then((res) => {
                setFreePackage(res.data.freePackage);
                setProPackage(res.data.proPackage);
            })
            .catch((err) => []);
    };

    return (
        <footer className='footer-page'>
            <Row className='pt-4 pb-4'>
                <Colxx xxs='6'>
                    <p className='text-muted mb-0'>
                        <a
                            href='https://vmass.vn'
                            className='text-muted text-small'
                        >
                            Bản quyền thuộc vmass.vn
                        </a>
                    </p>
                </Colxx>
                <Colxx xxs='6' className='d-flex justify-content-end pt-0 pl-0'>
                    {freePackage || proPackage ? (
                        <>
                            <i className='simple-icon-diamond line-height-1dot5 pr-1' />
                            <NavLink
                                to='/app/applications/upgrade'
                                className='mb-0 pr-2'
                            >
                                Nâng cấp
                            </NavLink>
                            {' || '}
                        </>
                    ) : null}
                    {props.children}
                </Colxx>
            </Row>
        </footer>
    );
};

export default FooterPage;
