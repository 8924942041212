import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Select from 'react-select';
import CustomSelectInput from '../../../components/common/CustomSelectInput';
import { GetSelectTime, SetSelectTime } from '../../../helpers/SelectTime';
import { AddCommaNumber } from '../../../helpers/Utils';
import DynamicForm from '../general/DynamicForm';
import TooltipItem from '../../../components/common/TooltipItem';

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    data_dynamic_form,
    values,
    setting,
    onChange,
    onChangeDynamicForm,
}) => {
    // console.log('render UpdateComponent');
    const [timeTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Nếu chọn "Đưa vào thống kê" thì số liệu này sẽ được tính như một khoản chi trong ngày. Nếu chọn "Không đưa vào thống kê" thì chỉ xem như một con số tượng trưng.',
    });
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='pad-125rem scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        <FormGroup className='mt-3 has-float-label'>
                            <Label for='sub_title' className='text-muted'>
                                <IntlMessages id='outcome.sub_title' />
                            </Label>
                            <Input
                                type='text'
                                name='sub_title'
                                id='sub_title'
                                disabled={setting.viewonly && !setting.add}
                                value={values.sub_title}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='name' className='text-muted'>
                                <IntlMessages id='outcome.name' />
                            </Label>
                            <Input
                                type='text'
                                name='name'
                                id='name'
                                disabled={setting.viewonly && !setting.add}
                                value={values.name}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='address' className='text-muted'>
                                <IntlMessages id='address' />
                            </Label>
                            <Input
                                type='text'
                                name='address'
                                id='address'
                                disabled={setting.viewonly && !setting.add}
                                value={values.address}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='value' className='text-muted'>
                                <IntlMessages id='price' />
                            </Label>
                            <Input
                                type='text'
                                name='value'
                                id='value'
                                disabled={setting.viewonly && !setting.add}
                                value={AddCommaNumber(values.value)}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='note' className='text-muted'>
                                <IntlMessages id='note' />
                            </Label>
                            <Input
                                type='text'
                                name='note'
                                id='note'
                                disabled={setting.viewonly && !setting.add}
                                value={values.note}
                                onChange={onChange}
                            />
                        </FormGroup>
                        {values.alias ? null : (
                            <FormGroup className='has-float-label'>
                                <Label for='time' className='text-muted'>
                                    <IntlMessages id='time' />
                                    <TooltipItem item={timeTooltip} id={8} />
                                </Label>
                                <Select
                                    components={{ Input: CustomSelectInput }}
                                    className='react-select'
                                    classNamePrefix='react-select'
                                    name='time'
                                    value={GetSelectTime(values.time)}
                                    isDisabled={
                                        setting.viewonly && !setting.add
                                    }
                                    placeholder={
                                        <IntlMessages id='select-one-time' />
                                    }
                                    options={SetSelectTime()}
                                    onChange={(e) => {
                                        onChange(e, 'time');
                                    }}
                                />
                            </FormGroup>
                        )}
                        {/* {setting.update ? (
        <FormGroup>
          <Label for="GetSelectTime(values.time)">
            <IntlMessages id="user.created" />
          </Label>
          <Input type="text" name="user.created" id="user.created"
            disabled={true}
            value={values.user}
            onChange={onChange}
          />
        </FormGroup>
      ) : null} */}

                        <DynamicForm
                            values={values.dynamic_form}
                            data={data_dynamic_form}
                            onChange={onChangeDynamicForm}
                            disabled={setting.viewonly && !setting.add}
                        />
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='general.btn-update' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default React.memo(ContentComponent);
