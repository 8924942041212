import React, { Fragment } from "react";
import { Card, CardBody } from "reactstrap";
import CustomTooltip from '../../../components/CustomTooltip'
import { getAvatar } from "../../../helpers/Utils";
import { ParseContent } from './Utils'

const MessageCard = ({ item, idx }) => {
  return (
    <Fragment>
      <span className={`max-width-80per inline-flex float-${
        (item.user_id || item.sender_id) != localStorage.getItem("b") ? "left" : "right flex-direction-row-reverse"
        }`}>
        {item.isShowAvatar ? <img
          alt={"#"}
          src={getAvatar(item.avatarMe)}
          className={`message-avatar img-thumbnail border-0 rounded-circle align-self-center xsmall m-10px float-${
            (item.user_id || item.sender_id) != localStorage.getItem("b") ? "left" : "right"
            }`}
        /> : <span className={`message-avatar border-0 rounded-circle align-self-center xsmall m-10px float-${
          (item.user_id || item.sender_id) != localStorage.getItem("b") ? "left" : "right"
          }`}/> }
        <Card
          className={`min-width-150px d-inline-block mb-1 float-${
            (item.user_id || item.sender_id) != localStorage.getItem("b") ? "left" : "right"
            }`}
        >
          <CardBody className="pad-125rem " id={`Tooltip-${idx}-sms`}>
            <div className="mb-0 text-semi-muted">
              <ParseContent
                content={item.message}
              />
            </div>
            <CustomTooltip
              placement="top"
              id={`${idx}-sms`}
              body={`${item.date + " - " + item.time}`}
              delay={{ show: 2000, hide: 0 }}
            />
          </CardBody>
        </Card>
      </span>
      <div className="clearfix" />
    </Fragment>
  );
};

export default MessageCard;
