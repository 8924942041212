import React, { Component } from 'react';
import {
    Row,
    Card,
    CardBody,
    Button,
    ModalBody,
    ModalHeader,
    Modal,
} from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import axios from 'axios';
import { END_POINT } from '../../../constants/defaultValues';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import {
    AddCommaNumber,
    getQrPayWithCashAndInfo,
    NotificatioErrController,
    NotificatioSucController,
} from '../../../helpers/Utils';
import IntlMessages from '../../../helpers/IntlMessages';
import Select from 'react-select';
import CustomSelectInput from '../../../components/common/CustomSelectInput';
import SingleLightbox from '../../../components/pages/SingleLightbox';

const features = [
    {
        title: 'Tài khoản dành cho thu ngân',
        person: true,
        person_limit: false,
        professional: true,
        professional_limit: false,
        enterprise: true,
        enterprise_limit: false,
    },
    {
        title: 'Tài khoản dành cho nhà bếp',
        person: true,
        person_limit: false,
        professional: true,
        professional_limit: false,
        enterprise: true,
        enterprise_limit: false,
    },
    {
        title: 'Tài khoản dành cho phục vụ',
        person: true,
        person_limit: false,
        professional: true,
        professional_limit: false,
        enterprise: true,
        enterprise_limit: false,
    },
    {
        title: 'Sử dụng QrCode để đặt món',
        person: true,
        person_limit: false,
        professional: true,
        professional_limit: false,
        enterprise: true,
        enterprise_limit: false,
    },
    {
        title: 'Thiết lập Khu vực Bàn ghế',
        person: true,
        person_limit: false,
        professional: true,
        professional_limit: false,
        enterprise: true,
        enterprise_limit: false,
    },
    {
        title: 'Quản lý Việc cần làm',
        person: true,
        person_limit: false,
        professional: true,
        professional_limit: false,
        enterprise: true,
        enterprise_limit: false,
    },
    {
        title: 'Quản lý Hóa đơn',
        person: true,
        person_limit: false,
        professional: true,
        professional_limit: false,
        enterprise: true,
        enterprise_limit: false,
    },
    {
        title: 'Quản lý Sản phẩm',
        person: false,
        person_limit: '20 sản phẩm',
        professional: false,
        professional_limit: '50 sản phẩm',
        enterprise: true,
        enterprise_limit: false,
    },
    {
        title: 'Quản lý Nguyên vật liệu',
        person: false,
        person_limit: '20 nguyên liệu',
        professional: false,
        professional_limit: '50 nguyên liệu',
        enterprise: true,
        enterprise_limit: false,
    },
    {
        title: 'Xem báo cáo thống kê số liệu',
        person: false,
        person_limit: 'Không áp dụng',
        professional: false,
        professional_limit: 'Không áp dụng',
        enterprise: true,
        enterprise_limit: false,
    },
    {
        title: 'Theo dõi tiến độ Điểm hoà vốn',
        person: false,
        person_limit: 'Không áp dụng',
        professional: false,
        professional_limit: 'Không áp dụng',
        enterprise: true,
        enterprise_limit: false,
    },
    {
        title: 'Quản lý và tích điểm Khách hàng',
        person: false,
        person_limit: 'Không áp dụng',
        professional: false,
        professional_limit: 'Không áp dụng',
        enterprise: true,
        enterprise_limit: false,
    },
    {
        title: 'Quản lý và chấm công Nhân viên',
        person: false,
        person_limit: 'Không áp dụng',
        professional: false,
        professional_limit: 'Không áp dụng',
        enterprise: true,
        enterprise_limit: false,
    },
    {
        title: 'Quản lý Nhà cung cấp',
        person: false,
        person_limit: 'Không áp dụng',
        professional: false,
        professional_limit: 'Không áp dụng',
        enterprise: true,
        enterprise_limit: false,
    },
    {
        title: 'Quản lý Tài sản',
        person: false,
        person_limit: 'Không áp dụng',
        professional: false,
        professional_limit: 'Không áp dụng',
        enterprise: true,
        enterprise_limit: false,
    },
    {
        title: 'Quản lý Phiếu thu',
        person: false,
        person_limit: 'Không áp dụng',
        professional: false,
        professional_limit: 'Không áp dụng',
        enterprise: true,
        enterprise_limit: false,
    },
    {
        title: 'Quản lý Phiếu chi',
        person: false,
        person_limit: 'Không áp dụng',
        professional: false,
        professional_limit: 'Không áp dụng',
        enterprise: true,
        enterprise_limit: false,
    },
    {
        title: 'Quản lý chuỗi',
        person: false,
        person_limit: 'Không áp dụng',
        professional: false,
        professional_limit: 'Không áp dụng',
        enterprise: true,
        enterprise_limit: false,
    },
    {
        title: 'Xem và phục hồi lại Hóa đơn tạm tính đã hủy',
        person: false,
        person_limit: 'Không áp dụng',
        professional: false,
        professional_limit: 'Không áp dụng',
        enterprise: true,
        enterprise_limit: false,
    },
];

const prices_platinum = [
    {
        label: '1 tháng',
        price: '349000',
        price_sale: '',
        value: '1m',
    },
    {
        label: '2 tháng',
        price: '649000',
        price_sale: '',
        value: '2m',
    },
    {
        label: '6 tháng',
        price: '1794000',
        price_sale: '',
        value: '6m',
    },
    {
        label: '1 năm',
        price: '2988000',
        price_sale: '',
        value: '1y',
    },
    {
        label: '2 năm',
        price: '5890000',
        price_sale: '',
        value: '2y',
    },
    {
        label: 'Trọn đời',
        price: '9900000',
        price_sale: '',
        value: 'forever',
    },
];

const prices_pro = [
    {
        label: '1 tháng',
        price: '49000',
        price_sale: '',
        value: '1m',
    },
    {
        label: '2 tháng',
        price: '98000',
        price_sale: '',
        value: '2m',
    },
    {
        label: '6 tháng',
        price: '294000',
        price_sale: '',
        value: '6m',
    },
    {
        label: '1 năm',
        price: '490000',
        price_sale: '',
        value: '1y',
    },
    {
        label: '2 năm',
        price: '980000',
        price_sale: '',
        value: '2y',
    },
    {
        label: 'Trọn đời',
        price: '4900000',
        price_sale: '',
        value: 'forever',
    },
];

// const wallet = [
//     {
//         label: 'Ví vPay',
//         img: '/assets/img/profile-pic-l-2',
//         description: 'Mô tả ví vmassPay',
//     },
//     {
//         label: 'Ví vnPay',
//         img: '/assets/img/profile-pic-l-3',
//         description: 'Mô tả ví VNPay',
//     },
//     {
//         label: 'Ví zaloPay',
//         img: '/assets/img/profile-pic-l-4',
//         description: 'Mô tả ví ZaloPay',
//     },
// ];

class Upgrade extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            modal_pro: false,
            modal_platinum: false,
            priceSelectedPro: prices_pro[0],
            priceSelectedPlatinum: prices_platinum[0],
            license: [],
            activeTab: '1',
            accordion: [true, false, false, false, false],

            item: {
                label: '1 tháng',
                price: '349.000 đ',
                price_sale: '',
                value: '1',
            },
        };

        this.togglePro = this.togglePro.bind(this);
        this.togglePlatinum = this.togglePlatinum.bind(this);
        this.handleActionSubmitPro = this.handleActionSubmitPro.bind(this);
        this.handleActionSubmitPlatinum =
            this.handleActionSubmitPlatinum.bind(this);
    }

    componentDidMount() {
        this.dataRender();
    }

    dataRender = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/user-license'}`, {
                headers: {
                    Authorization: `Bearer ${tokenStr}`,
                },
            })
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                this.setState({
                    isLoading: true,
                    license: data.data,
                });
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    };

    togglePro() {
        this.setState({
            modal_pro: !this.state.modal_pro,
        });
    }

    togglePlatinum() {
        this.setState({
            modal_platinum: !this.state.modal_platinum,
        });
    }

    handleActionSubmitPro() {
        let purchased_package = 'pro';
        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/user-license/requirement'}`,
                {
                    time: this.state.priceSelectedPro.value,
                    purchased_package,
                },
                {
                    headers: {
                        Authorization: `Bearer ${tokenStr}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                NotificatioSucController(data.responseText);

                this.setState({
                    modal_pro: !this.state.modal_pro,
                });
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    }

    handleActionSubmitPlatinum() {
        let purchased_package = 'platinum';
        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/user-license/requirement'}`,
                {
                    time: this.state.priceSelectedPlatinum.value,
                    purchased_package,
                },
                {
                    headers: {
                        Authorization: `Bearer ${tokenStr}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                NotificatioSucController(data.responseText);

                this.setState({
                    modal_platinum: !this.state.modal_platinum,
                });
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    }

    handleChangeValueSelectPricePro = (e) => {
        this.setState({
            priceSelectedPro: e,
        });
    };

    handleChangeValueSelectPricePlatinum = (e) => {
        this.setState({
            priceSelectedPlatinum: e,
        });
    };

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    toggleAccordion = (tab) => {
        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => (tab === index ? !x : false));
        this.setState({
            accordion: state,
        });
    };

    onClickedItem = (item) => {
        const { label, price, price_sale, value } = this.state;
        this.setState((prev) => ({
            ...prev,
            item: {
                ...prev.item,
                label: item.label,
                price: item.price,
                price_sale: item.price_sale,
                value: item.value,
            },
        }));
    };

    render() {
        let {
            isLoading,
            modal_pro,
            modal_platinum,
            priceSelectedPro,
            priceSelectedPlatinum,
            license,
            item: { label, price, price_sale, value },
        } = this.state;

        const companyUUID = localStorage.getItem('companyUUID');

        // console.log('license: ', license);
        // console.log('priceSelectedPro: ', priceSelectedPro);

        return !isLoading ? (
            <div className='loading' />
        ) : (
            <React.Fragment>
                <Modal isOpen={modal_pro} toggle={this.togglePro}>
                    <ModalHeader toggle={this.togglePro}>
                        {'Thông tin chuyển khoản'}
                    </ModalHeader>
                    <ModalBody>
                        <p>
                            Bạn hãy chọn thời gian muốn nâng cấp, sau đó chuyển
                            khoản vào số tài khoản phía dưới, với số tiền và nội
                            dung tương ứng. Cuối cùng, hãy bấm vào nút: "Xác
                            nhận đã thanh toán & yêu cầu nâng cấp"
                        </p>
                        <Separator className='mb-3' />
                        <h6 className='text-muted'>Thời hạn: </h6>
                        <Select
                            defaultValue={priceSelectedPro}
                            components={{ Input: CustomSelectInput }}
                            className='react-select'
                            classNamePrefix='react-select'
                            name='product'
                            options={prices_pro}
                            onChange={(newValue, extra) =>
                                this.handleChangeValueSelectPricePro(newValue)
                            }
                            placeholder={
                                <IntlMessages id='pages.select-product' />
                            }
                            menuPlacement='top'
                        />
                        <div className='mb-3' />
                        <Row className='d-flex align-items-center'>
                            <Colxx xxs='4'>
                                <p className='text-small text-muted text-center px-3 mb-0'>
                                    Quét để thanh toán
                                </p>
                                <SingleLightbox
                                    thumb={getQrPayWithCashAndInfo(
                                        970436,
                                        '0101001196647',
                                        priceSelectedPro.price,
                                        companyUUID
                                    )}
                                    large={getQrPayWithCashAndInfo(
                                        970436,
                                        '0101001196647',
                                        priceSelectedPro.price,
                                        companyUUID
                                    )}
                                    className='img-thumbnail card-img qrpay'
                                />
                            </Colxx>
                            <Colxx xxs='3'>
                                <p className='text-muted mb-2'>Chủ thẻ:</p>
                                <p className='text-muted mb-2'>Số tk:</p>
                                <p className='text-muted mb-2'>Ngân hàng:</p>
                                <p className='text-muted mb-2'>Số tiền:</p>
                                <p className='text-muted mb-2'>Nội dung:</p>
                            </Colxx>
                            <Colxx xxs='5'>
                                <p className='mb-2'>Phạm Quang Thành</p>
                                <p className='mb-2'>0101001196647</p>
                                <p className='mb-2'>Vietcombank</p>
                                <p className='text-primary mb-2'>{`${AddCommaNumber(
                                    priceSelectedPro.price
                                )} đ`}</p>
                                <p className='mb-2'>{companyUUID}</p>
                            </Colxx>
                        </Row>
                        <div className='text-center'>
                            <div className='mb-3' />
                            <span className='text-small text-muted'>
                                Sau khi chuyển khoản hãy bấm vào nút dưới!
                            </span>
                            <Button
                                color='primary'
                                onClick={this.handleActionSubmitPro}
                            >
                                {license.pending
                                    ? 'Đang chờ xét duyệt'
                                    : 'Xác nhận đã thanh toán & yêu cầu nâng cấp'}
                            </Button>{' '}
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={modal_platinum} toggle={this.togglePlatinum}>
                    <ModalHeader toggle={this.togglePlatinum}>
                        {'Thông tin chuyển khoản'}
                    </ModalHeader>
                    <ModalBody>
                        <p>
                            Bạn hãy chọn thời gian muốn nâng cấp, sau đó chuyển
                            khoản vào số tài khoản phía dưới, với số tiền và nội
                            dung tương ứng. Cuối cùng, hãy bấm vào nút: "Xác
                            nhận đã thanh toán & yêu cầu nâng cấp"
                        </p>
                        <Separator className='mb-3' />
                        <h6 className='text-muted'>Thời hạn: </h6>
                        <Select
                            defaultValue={priceSelectedPlatinum}
                            components={{ Input: CustomSelectInput }}
                            className='react-select'
                            classNamePrefix='react-select'
                            name='product'
                            options={prices_platinum}
                            onChange={(newValue, extra) =>
                                this.handleChangeValueSelectPricePlatinum(
                                    newValue
                                )
                            }
                            placeholder={
                                <IntlMessages id='pages.select-product' />
                            }
                            menuPlacement='top'
                        />
                        <div className='mb-3' />
                        <Row className='d-flex align-items-center'>
                            <Colxx xxs='4'>
                                <p className='text-small text-muted text-center px-3 mb-0'>
                                    Quét để thanh toán
                                </p>
                                <SingleLightbox
                                    thumb={getQrPayWithCashAndInfo(
                                        970436,
                                        '0101001196647',
                                        priceSelectedPlatinum.price,
                                        companyUUID
                                    )}
                                    large={getQrPayWithCashAndInfo(
                                        970436,
                                        '0101001196647',
                                        priceSelectedPlatinum.price,
                                        companyUUID
                                    )}
                                    className='img-thumbnail card-img qrpay'
                                />
                            </Colxx>
                            <Colxx xxs='3'>
                                <p className='text-muted mb-2'>Chủ thẻ:</p>
                                <p className='text-muted mb-2'>Số tk:</p>
                                <p className='text-muted mb-2'>Ngân hàng:</p>
                                <p className='text-muted mb-2'>Số tiền:</p>
                                <p className='text-muted mb-2'>Nội dung:</p>
                            </Colxx>
                            <Colxx xxs='5'>
                                <p className='mb-2'>Phạm Quang Thành</p>
                                <p className='mb-2'>0101001196647</p>
                                <p className='mb-2'>Vietcombank</p>
                                <p className='text-primary mb-2'>{`${AddCommaNumber(
                                    priceSelectedPlatinum.price
                                )} đ`}</p>
                                <p className='mb-2'>{companyUUID}</p>
                            </Colxx>
                        </Row>
                        <div className='text-center'>
                            <div className='mb-3' />
                            <span className='text-small text-muted'>
                                Sau khi chuyển khoản hãy bấm vào nút dưới!
                            </span>
                            <Button
                                color='primary'
                                onClick={this.handleActionSubmitPlatinum}
                            >
                                {license.pending
                                    ? 'Đang chờ xét duyệt'
                                    : 'Xác nhận đã thanh toán & yêu cầu nâng cấp'}
                            </Button>{' '}
                        </div>
                    </ModalBody>
                </Modal>

                <Row>
                    <Colxx xxs='12'>
                        <Breadcrumb
                            heading='menu.upgrade'
                            match={this.props.match}
                        />
                        <Separator className='mb-5' />
                    </Colxx>
                </Row>

                <Row className='equal-height-container mb-5 row text-center'>
                    <Colxx xxs='12' lg='4' md='12' className='col-item mb-4'>
                        <Card>
                            <CardBody className='pt-5 pb-5 d-flex flex-lg-column flex-md-row flex-sm-row flex-column'>
                                <div className='price-top-part min-height-143px'>
                                    <i className='large-icon iconsminds-diamond'></i>
                                    <h5 className='mb-0 font-weight-semibold mb-4'>
                                        GÓI MIỄN PHÍ
                                    </h5>
                                    <p className='text-large mb-2 text-default h-20px'>
                                        0đ
                                    </p>
                                </div>
                                <div className='pl-3 pr-3 pt-3 pb-0 d-flex price-feature-list flex-column flex-grow-1'>
                                    <ul className='list-unstyled h-50px'>
                                        <li>
                                            <p className='mb-0'>
                                                Sử dụng đầy đủ chức năng 30 ngày
                                                đầu
                                            </p>
                                        </li>
                                        <li>
                                            <p className='mb-0'>
                                                Hết 30 ngày, sử dụng miễn phí
                                                trọn đời các tính năng được liệt
                                                kê ở dưới
                                            </p>
                                        </li>
                                    </ul>
                                    <div className='text-center min-height-85px'>
                                        <button
                                            aria-current='page'
                                            className='btn btn-link btn-empty btn-lg active'
                                        >
                                            <span>
                                                {license.isExpired
                                                    ? 'ĐANG DÙNG'
                                                    : ''}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Colxx>
                    <Colxx xxs='12' lg='4' md='12' className='col-item mb-4'>
                        <Card>
                            <CardBody className='pt-5 pb-5 d-flex flex-lg-column flex-md-row flex-sm-row flex-column'>
                                <div className='price-top-part min-height-143px'>
                                    <i className='large-icon iconsminds-diamond'></i>
                                    <i className='large-icon iconsminds-diamond'></i>
                                    <h5 className='mb-0 font-weight-semibold mb-4'>
                                        GÓI TIẾP SỨC
                                    </h5>
                                    <p className='text-large mb-2 text-default h-20px'>
                                        1.500đ/ngày
                                    </p>
                                </div>
                                <div className='pl-3 pr-3 pt-3 pb-0 d-flex price-feature-list flex-column flex-grow-1'>
                                    <ul className='list-unstyled h-50px'>
                                        <li>
                                            <p className='mb-0'>
                                                Tăng dung lượng sử dụng cho các
                                                chức năng bị giới hạn ở Gói Miễn
                                                Phí
                                            </p>
                                        </li>
                                    </ul>
                                    <div className='text-center min-height-85px'>
                                        <button
                                            onClick={(e) => {
                                                if (license.upgraded)
                                                    return false;

                                                this.togglePro();
                                            }}
                                            aria-current='page'
                                            className='btn btn-link btn-empty btn-lg active'
                                        >
                                            <span>
                                                {license.upgraded ? (
                                                    'Trọn đời'
                                                ) : !license.isExpired &&
                                                  license.purchased_package ===
                                                      'pro' ? (
                                                    `HẠN DÙNG: ${license.expired_date}`
                                                ) : (
                                                    <span>
                                                        NÂNG CẤP{' '}
                                                        <i className='simple-icon-arrow-right'></i>
                                                    </span>
                                                )}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Colxx>
                    <Colxx xxs='12' lg='4' md='12' className='col-item mb-4'>
                        <Card>
                            <CardBody className='pt-5 pb-5 d-flex flex-lg-column flex-md-row flex-sm-row flex-column'>
                                <div className='price-top-part min-height-143px'>
                                    <i className='large-icon iconsminds-diamond'></i>
                                    <i className='large-icon iconsminds-diamond'></i>
                                    <i className='large-icon iconsminds-diamond'></i>
                                    <i className='large-icon iconsminds-diamond'></i>
                                    <i className='large-icon iconsminds-diamond'></i>
                                    <h5 className='mb-0 font-weight-semibold mb-4'>
                                        GÓI TOÀN DIỆN
                                    </h5>
                                    <p className='text-large mb-2 text-default h-20px'>
                                        8.300đ/ngày
                                    </p>
                                </div>
                                <div className='pl-3 pr-3 pt-3 pb-0 d-flex price-feature-list flex-column flex-grow-1'>
                                    <ul className='list-unstyled h-50px'>
                                        <li>
                                            <p className='mb-0'>
                                                Tất cả tính năng
                                            </p>
                                        </li>
                                        <li>
                                            <p className='mb-0'>
                                                Sử dụng không giới hạn
                                            </p>
                                        </li>
                                    </ul>
                                    <div className='text-center min-height-85px'>
                                        <button
                                            onClick={(e) => {
                                                if (license.upgraded)
                                                    return false;

                                                this.togglePlatinum();
                                            }}
                                            aria-current='page'
                                            className='btn btn-link btn-empty btn-lg active'
                                        >
                                            <span>
                                                {license.upgraded ? (
                                                    'Trọn đời'
                                                ) : !license.isExpired &&
                                                  license.purchased_package ===
                                                      'platinum' ? (
                                                    `HẠN DÙNG: ${license.expired_date}`
                                                ) : (
                                                    <span>
                                                        NÂNG CẤP{' '}
                                                        <i className='simple-icon-arrow-right'></i>
                                                    </span>
                                                )}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' className='col-item mb-4'>
                        <div className='card-title'>
                            <span>Tính năng</span>
                        </div>
                    </Colxx>
                    <Colxx xxs='12' className='d-none d-md-block mb-5'>
                        <div className='mb-3 table-heading'>
                            <div className='d-flex flex-grow-1 min-width-zero'>
                                <CardBody className='align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center'>
                                    <p className='list-item-heading mb-0 truncate w-40 w-xs-100' />
                                    <p className='mb-0 text-muted w-20 w-xs-100 text-center'>
                                        <span>GÓI MIỄN PHÍ</span>
                                    </p>
                                    <p className='mb-0 text-muted w-20 w-xs-100 text-center'>
                                        <span>GÓI TIẾP SỨC</span>
                                    </p>
                                    <p className='mb-0 text-muted w-20 w-xs-100 text-center'>
                                        <span>GÓI TOÀN DIỆN</span>
                                    </p>
                                </CardBody>
                            </div>
                        </div>
                        {features &&
                            features.map((f, idx) => (
                                <div
                                    className='flex flex-row mb-3 card'
                                    key={idx}
                                >
                                    <div className='d-flex flex-grow-1 min-width-zero'>
                                        <div className='align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center card-body'>
                                            <p className='list-item-heading mb-0 truncate w-40 w-xs-100'>
                                                <span>{f.title}</span>
                                            </p>
                                            {!f.person ? (
                                                <p className='mb-0 w-20 w-xs-100 text-center'>
                                                    {f.person_limit}
                                                </p>
                                            ) : (
                                                <p className='mb-0 text-primary w-20 w-xs-100 text-center'>
                                                    Không giới hạn
                                                </p>
                                            )}
                                            {!f.professional ? (
                                                <p className='mb-0 w-20 w-xs-100 text-center'>
                                                    {f.professional_limit}
                                                </p>
                                            ) : (
                                                <p className='mb-0 text-primary w-20 w-xs-100 text-center'>
                                                    Không giới hạn
                                                </p>
                                            )}
                                            {!f.enterprise ? (
                                                <p className='mb-0 w-20 w-xs-100 text-center' />
                                            ) : (
                                                <p className='mb-0 text-primary w-20 w-xs-100 text-center'>
                                                    Không giới hạn
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </Colxx>
                    <Colxx xxs='12' className='d-block d-md-none mb-5'>
                        {features &&
                            features.map((f, idx) => (
                                <>
                                    <div className='d-flex flex-row mb-3 table-heading'>
                                        <div className='d-flex flex-grow-1 min-width-zero'>
                                            <CardBody className='pl-0 pb-0'>
                                                <p className='list-item-heading mb-0 text-primary'>
                                                    {f.title}
                                                </p>
                                            </CardBody>
                                        </div>
                                    </div>
                                    <Card className='d-flex flex-row mb-3'>
                                        <div className='d-flex flex-grow-1 min-width-zero'>
                                            <CardBody className='align-self-center d-flex flex-row'>
                                                <h5 className='mb-0 truncate w-60'>
                                                    GÓI MIỄN PHÍ
                                                </h5>
                                                <p className='text-right mb-0 w-40 text-one'>
                                                    {!f.person ? (
                                                        <p className='mb-0 w-20 w-xs-100 text-center'>
                                                            {f.person_limit}
                                                        </p>
                                                    ) : (
                                                        <p className='mb-0 text-primary w-20 w-xs-100 text-center'>
                                                            Không giới hạn
                                                        </p>
                                                    )}
                                                </p>
                                            </CardBody>
                                        </div>
                                    </Card>
                                    <Card className='d-flex flex-row mb-3'>
                                        <div className='d-flex flex-grow-1 min-width-zero'>
                                            <CardBody className='align-self-center d-flex flex-row'>
                                                <h5 className='mb-0 truncate w-60'>
                                                    GÓI TIẾP SỨC
                                                </h5>
                                                <p className='text-right mb-0 w-40 text-one'>
                                                    {!f.professional ? (
                                                        <p className='mb-0 w-20 w-xs-100 text-center'>
                                                            {
                                                                f.professional_limit
                                                            }
                                                        </p>
                                                    ) : (
                                                        <p className='mb-0 text-primary w-20 w-xs-100 text-center'>
                                                            Không giới hạn
                                                        </p>
                                                    )}
                                                </p>
                                            </CardBody>
                                        </div>
                                    </Card>
                                    <Card className='d-flex flex-row mb-3'>
                                        <div className='d-flex flex-grow-1 min-width-zero'>
                                            <CardBody className='align-self-center d-flex flex-row'>
                                                <h5 className='mb-0 truncate w-60'>
                                                    GÓI TOÀN DIỆN
                                                </h5>
                                                <p className='text-right mb-0 w-40 text-one'>
                                                    {!f.enterprise ? (
                                                        <p className='mb-0 w-20 w-xs-100 text-center'>
                                                            {f.enterprise_limit}
                                                        </p>
                                                    ) : (
                                                        <p className='mb-0 text-primary w-20 w-xs-100 text-center'>
                                                            Không giới hạn
                                                        </p>
                                                    )}
                                                </p>
                                            </CardBody>
                                        </div>
                                    </Card>
                                </>
                            ))}
                    </Colxx>
                </Row>
                {/* </TabPane>
                </TabContent> */}
            </React.Fragment>
        );
    }
}

export default Upgrade;
