import React from 'react';
import { Row } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { GetSelectTime } from '../../../helpers/SelectTime';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentDisplayMobile = (props) => {
    // console.log(props);
    const findStockEqual = props.data_stocks?.find(
        (d) => d.name === props.values.stock
    );
    return (
        <>
            <Row className='mt-4' xxs='12'>
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.transfer-name' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p className='text-capitalize'>{props.values.name}</p>
                </Colxx>
                <Colxx xxs='5' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.phone' />
                    </p>
                </Colxx>
                <Colxx xxs='7' lg='6' className='text-right'>
                    <p>{props.values.phone}</p>
                </Colxx>
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.transfer-stock' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p className='text-capitalize'>{props.values.stock}</p>
                </Colxx>
                <Colxx xxs='5' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.code' />
                    </p>
                </Colxx>
                <Colxx xxs='7' lg='6' className='text-right'>
                    <p>
                        {findStockEqual
                            ? findStockEqual.sku
                            : props.values.code}
                        {/* {props.values.code ? (
                            props.values.code
                        ) : (
                            <span className='text-lowercase text-small text-muted'>
                                (Chưa có thông tin)
                            </span>
                        )} */}
                    </p>
                </Colxx>
                <Colxx xxs='4' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.unit' />
                    </p>
                </Colxx>
                <Colxx xxs='8' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {findStockEqual
                            ? findStockEqual.unit
                            : props.values.unit}
                    </p>
                </Colxx>
                <Colxx xxs='5' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.transfer-quantity' />
                    </p>
                </Colxx>
                <Colxx xxs='7' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {AddCommaNumber(props.values.quantity)}
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.transfer-price' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {AddCommaNumber(props.values.price)}
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.description' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {AddCommaNumber(props.values.description)}
                    </p>
                </Colxx>

                <Separator className='mt-4 mb-4' />

                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.transfer-name-carrier' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {props.values.name_carrier}
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.transfer-phone-carrier' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {props.values.phone_carrier}
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.transfer-receiving-warehouse' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {props.values.receiving_warehouse}
                    </p>
                </Colxx>
                <Colxx xxs='7' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.transportation' />
                    </p>
                </Colxx>
                <Colxx xxs='5' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {props.values.transportation}
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='warehouse.cost-transfer' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {props.values.cost_transfer}
                    </p>
                </Colxx>

                <Separator className='mt-4 mb-4' />

                {/* <Colxx xxs='4' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='time' />
                    </p>
                </Colxx>
                <Colxx xxs='8' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {GetSelectTime(props.values.time).label}
                    </p>
                </Colxx> */}
                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='timeCreated' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p className='text-lowercase'>{props.values.at}</p>
                </Colxx>

                <Colxx xxs='6' lg='6'>
                    <p className='text-muted text-small'>
                        <IntlMessages id='user.created' />
                    </p>
                </Colxx>
                <Colxx xxs='6' lg='6' className='text-right'>
                    <p className='text-capitalize'>
                        {props.values?.user?.fullname}
                    </p>
                </Colxx>
            </Row>
        </>
    );
};

export default ContentDisplayMobile;
