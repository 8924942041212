import Text from './Text'
import Date from './Date'
import Number from './Number'
import Textarea from './Textarea'
import URL from './URL'
import Boolean from './Boolean'
import Phone from './Phone'

export {
  Text,
  Textarea,
  Number,
  Date,
  URL,
  Boolean,
  Phone,
}