import React from 'react';

const TableAndChair = (props) => (
    <>
        {/* <svg viewBox='0 0 129 105' {...props}>
            <defs>
                <style>{}</style>
            </defs>
            <g id='Layer_2' data-name='Layer 2'>
                <g id='Layer_1-2' data-name='Layer 1'>
                    <g id='t1'>
                        <g id='chair_1' data-name='chair 1'>
                            <path
                                fill={props.s.chair_fill}
                                stroke={props.s.chair_stroke}
                                strokeMiterlimit={10}
                                className='cls-1'
                                d='M12.5 0.5H44.5V20.5H12.5z'
                            />
                            <path
                                fill='{props.s.chair_fill}'
                                stroke={props.s.chair_stroke}
                                className='cls-1'
                                d='M13 5.5L45 5.5'
                            />
                        </g>
                        <g id='chair_3' data-name='chair 3'>
                            <path
                                fill={props.s.chair_fill}
                                stroke={props.s.chair_stroke}
                                strokeMiterlimit={10}
                                className='cls-1'
                                d='M12.5 84.5H44.5V104.5H12.5z'
                            />
                            <path
                                fill={props.s.chair_fill}
                                stroke={props.s.chair_stroke}
                                strokeMiterlimit={10}
                                className='cls-1'
                                d='M12 99.5L44 99.5'
                            />
                        </g>
                        <g id='chair_4' data-name='chair 4'>
                            <path
                                fill={props.s.chair_fill}
                                stroke={props.s.chair_stroke}
                                strokeMiterlimit={10}
                                className='cls-1'
                                d='M83.5 84.5H115.5V104.5H83.5z'
                            />
                            <path
                                fill={props.s.chair_fill}
                                stroke={props.s.chair_stroke}
                                strokeMiterlimit={10}
                                className='cls-1'
                                d='M83 99.5L115 99.5'
                            />
                        </g>
                        <g id='chair_2' data-name='chair 2'>
                            <path
                                fill={props.s.chair_fill}
                                stroke={props.s.chair_stroke}
                                strokeMiterlimit={10}
                                className='cls-1'
                                d='M83.5 0.5H115.5V20.5H83.5z'
                            />
                            <path
                                fill={props.s.chair_fill}
                                stroke={props.s.chair_stroke}
                                strokeMiterlimit={10}
                                className='cls-1'
                                d='M83 5.5L115 5.5'
                            />
                        </g>
                        <path
                            fill={props.s.table_fill}
                            stroke={props.s.table_stroke}
                            strokeMiterlimit={10}
                            id='tablemain'
                            d='M0.5 20.5H128.5V84.5H0.5z'
                        />
                    </g>
                </g>
            </g>
        </svg> */}
        <svg viewBox='0 0 129 105' {...props}>
            <defs>
                <style>{}</style>
            </defs>
            <g id='Layer_2' data-name='Layer 2'>
                <g id='Layer_1-2' data-name='Layer 1'>
                    <g id='t1'>
                        <g id='chair_1' data-name='chair 1'>
                            <line
                                fill='none'
                                stroke={props.s.chair_stroke}
                                className='cls-1'
                                x1='12'
                                y1='14.5'
                                x2='45'
                                y2='14.5'
                            />
                        </g>
                        <g id='chair_3' data-name='chair 3'>
                            <line
                                fill='none'
                                stroke={props.s.chair_stroke}
                                strokeMiterlimit={10}
                                className='cls-1'
                                x1='12'
                                y1='90'
                                x2='45'
                                y2='90'
                            />
                        </g>
                        <g id='chair_4' data-name='chair 4'>
                            <line
                                fill='none'
                                stroke={props.s.chair_stroke}
                                strokeMiterlimit={10}
                                className='cls-1'
                                x1='83'
                                y1='90'
                                x2='115'
                                y2='90'
                            />
                        </g>
                        <g id='chair_2' data-name='chair 2'>
                            <line
                                fill='none'
                                stroke={props.s.chair_stroke}
                                strokeMiterlimit={10}
                                className='cls-1'
                                x1='83'
                                y1='14.5'
                                x2='115'
                                y2='14.5'
                            />
                        </g>
                        <rect
                            fill={props.s.table_fill}
                            stroke={props.s.table_stroke}
                            strokeMiterlimit={10}
                            // stroke-width='2'
                            id='tablemain'
                            x='0.5'
                            y='20.5'
                            width='128'
                            height='64'
                            rx='10'
                            ry='10'
                        />
                        <text
                            x='110'
                            y='75'
                            font-size='16'
                            font-family='Nunito'
                            fill={
                                props.s.table_fill !== 'none'
                                    ? 'white'
                                    : props.s.chair_stroke
                            }
                            text-anchor='middle'
                        >
                            {props.id_table}
                        </text>
                    </g>
                </g>
            </g>
        </svg>

        {/* {props.id_table === undefined ? null : (
            <p
                style={{
                    color:
                        props.s.chair_fill !== 'none' ||
                        props.s.chair_stroke !== 'none'
                            ? props.s.chair_stroke
                            : '',
                }}
            >
                Bàn {props.id_table}
            </p>
        )} */}
    </>
);

export default TableAndChair;
