import React, { useState, memo } from 'react';
import {
    Badge,
    Card,
    CardBody,
    CardTitle,
    Label,
    Row,
    Nav,
    NavItem,
    NavLink,
    TabPane,
    TabContent,
    CardImg,
} from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import classnames from 'classnames';
import { AddCommaNumber, AddCommaPhoneNumber } from '../../../helpers/Utils';
import { END_POINT } from '../../../constants/defaultValues';

const ContentDisplay = ({ values, data_products, data_orders }) => {
    console.log('values: ', values);

    // console.log('render ContentDisplayComponent');

    const [toggle, setToggle] = useState('1');

    const toggleTab = (tab) => {
        if (toggle !== tab) {
            setToggle(tab);
        }
    };
    // console.log('data_products: ', data_products);
    // console.log('data_orders: ', data_orders);

    const filterPhone = data_orders?.filter(
        (order) => order.phone === values.phone
    );
    // console.log('filterPhone: ', filterPhone);

    const results = filterPhone?.map((d) => {
        const productFill = data_products?.find(
            (product) => product.id === d.product_id
        );
        // console.log('productFill: ', productFill);

        return {
            name: productFill?.name,
            image: productFill?.image,
            product_id: d.product_id,
            price: d.price,
            date: d.date,
            time: d.time,
        };
    });
    // console.log('results: ', results);

    // Tạo một đối tượng để theo dõi product_id và đếm số lượng
    let idCountMap = {};

    // Lặp qua mảng results để xử lý và đếm số lượng
    const filterPId = results?.forEach((data) => {
        const { name, image, price, product_id, date, time } = data;

        if (!idCountMap[product_id]) {
            // Nếu product_id chưa xuất hiện, thêm vào idCountMap và set count là 1
            idCountMap[product_id] = {
                name,
                image,
                price,
                product_id,
                count: 1,
                date,
                time,
            };
        } else {
            // Nếu product_id đã xuất hiện, tăng count lên
            idCountMap[product_id].count++;
        }
    });

    // Chuyển idCountMap thành mảng
    let resultArray = Object.values(idCountMap);

    // Sắp xếp mảng theo thứ tự giảm dần theo 'count'
    const filteredItems = resultArray.sort((a, b) => b.count - a.count);
    // console.log('resultArray: ', resultArray);

    // const filteredItems = results?.filter(
    //     (item, index, self) =>
    //         index === self.findIndex((t) => t.product_id === item.product_id)
    // );
    // console.log('filteredItems: ', filteredItems);

    return (
        <Card className='position-sticky-custom mb-4'>
            <Nav tabs className='separator-tabs m-3'>
                <NavItem>
                    <NavLink
                        className={classnames({
                            active: toggle === '1',
                            'nav-link': true,
                        })}
                        onClick={() => toggleTab('1')}
                        location={{}}
                        to='#'
                    >
                        <IntlMessages id='general.info' />
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({
                            active: toggle === '2',
                            'nav-link': true,
                        })}
                        onClick={() => toggleTab('2')}
                        location={{}}
                        to='#'
                    >
                        <IntlMessages id='customer.used' />
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={toggle}>
                <TabPane tabId='1'>
                    <CardBody>
                        {/* <CardTitle>
                            <IntlMessages id='customer.title' />
                        </CardTitle> */}
                        <Row>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='name' />
                                </p>
                                <p className='mb-3 text-capitalize'>
                                    {values.name ? (
                                        values.name
                                    ) : (
                                        <span className='text-lowercase'>
                                            (Chưa có thông tin)
                                        </span>
                                    )}
                                </p>
                            </Colxx>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='phone' />
                                </p>
                                <p className='mb-3'>
                                    {values.phone ? (
                                        AddCommaPhoneNumber(values.phone)
                                    ) : (
                                        <span className='text-lowercase'>
                                            (Chưa có thông tin)
                                        </span>
                                    )}
                                </p>
                            </Colxx>
                        </Row>
                        <Row>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='email' />
                                </p>
                                <p className='mb-3 text-lowercase'>
                                    {values.email
                                        ? values.email
                                        : '(Chưa có thông tin)'}
                                </p>
                            </Colxx>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='address' />
                                </p>
                                <p className='mb-3 text-capitalize'>
                                    {values.address ? (
                                        values.address
                                    ) : (
                                        <span className='text-lowercase'>
                                            (Chưa có thông tin)
                                        </span>
                                    )}
                                </p>
                            </Colxx>
                        </Row>
                        <Row>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='customer.point' />
                                </p>
                                <p className='mb-3 text-lowercase'>
                                    {values.point ? values.point : 0}
                                </p>
                            </Colxx>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='customer.armorial' />
                                </p>
                                <p className='mb-3 text-capitalize'>
                                    {values.armorial ? (
                                        <Badge color='primary' pill>
                                            {values.armorial}
                                        </Badge>
                                    ) : (
                                        <span className='text-lowercase'>
                                            (Chưa đủ điểm nhận huy hiệu)
                                        </span>
                                    )}
                                </p>
                            </Colxx>
                        </Row>
                        <Row>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='customer.used-item' />
                                </p>
                                <p className='mb-3 text-lowercase'>
                                    {values.used ? values.used : 0}
                                </p>
                            </Colxx>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='customer.tags' />
                                </p>
                                {values.tags?.length
                                    ? values.tags?.map((tag, idx) => (
                                          <Badge key={idx} color='primary' pill>
                                              {tag.label}
                                          </Badge>
                                      ))
                                    : '(chưa có tag)'}
                            </Colxx>
                        </Row>
                        <Row>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    Số tiền đã chi tiêu
                                </p>
                                <p className='mb-3 text-lowercase'>
                                    {values.point ? (
                                        <Badge color='primary' pill>
                                            {AddCommaNumber(
                                                values.point * 1000
                                            )}
                                        </Badge>
                                    ) : (
                                        0
                                    )}
                                </p>
                            </Colxx>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    Công nợ
                                </p>
                                <p className='mb-3 text-lowercase'>
                                    {values.debt ? (
                                        <Badge color='primary' pill>
                                            {AddCommaNumber(values.debt)}
                                        </Badge>
                                    ) : (
                                        0
                                    )}
                                </p>
                            </Colxx>
                        </Row>
                    </CardBody>
                </TabPane>
                <TabPane tabId='2'>
                    {filteredItems?.length ? (
                        filteredItems?.map((result, idx) => (
                            <Colxx xxs='12' key={idx}>
                                <Card className='mb-3'>
                                    <div className='d-flex flex-row cursor-pointer'>
                                        <div
                                            className={
                                                'd-flex flex-grow-1 min-width-zero'
                                            }
                                        >
                                            <div className='d-block position-relative'>
                                                <img
                                                    alt={result.name}
                                                    src={`${END_POINT}/public/${result.image}`}
                                                    className='list-thumbnail responsive border-0'
                                                />
                                            </div>
                                            <div className='d-flex card-body card-body-custom w-100 justify-content-between'>
                                                <div className='align-self-center d-flex flex-column justify-content-between min-width-zero'>
                                                    <div className='list-item-heading mb-1 truncate d-block'>
                                                        <span>
                                                            {result.count}x{' '}
                                                            {result.name}
                                                        </span>
                                                    </div>
                                                    <p className='text-small text-muted'>
                                                        {result.time} /{' '}
                                                        {result.date}
                                                    </p>
                                                </div>
                                                <div className='pt-3'>
                                                    <Badge color='primary' pill>
                                                        {AddCommaNumber(
                                                            result.price
                                                        )}
                                                    </Badge>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Colxx>
                        ))
                    ) : (
                        <Colxx xxs='12'>
                            <Card className='mb-3'>
                                <CardBody className='text-small text-muted text-center'>
                                    Khách hàng chưa sử dụng món tại cửa hàng!
                                </CardBody>
                            </Card>
                        </Colxx>
                    )}
                </TabPane>
            </TabContent>
        </Card>
    );
};

export default memo(ContentDisplay);
