import Axios from 'axios';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import XLSX from 'xlsx';
import { END_POINT } from '../../../constants/defaultValues';
import {
    NotificatioErrController,
    NotificatioSucController,
} from '../../../helpers/Utils';

const ContentFormAddExcel = () => {
    // const onDrop = useCallback((acceptedFiles) => {
    //     acceptedFiles.forEach((file) => {
    //         const reader = new FileReader();

    //         reader.onabort = () => console.log('file reading was aborted');
    //         reader.onerror = () => console.log('file reading has failed');
    //         reader.onload = () => {
    //             // Do whatever you want with the file contents
    //             const binaryStr = reader.result;
    //             console.log(binaryStr);
    //         };
    //         reader.readAsArrayBuffer(file);
    //     });
    // }, []);
    const onDrop = useCallback((acceptedFiles) => {
        // console.log('handleExcelDrop');
        // setFileNames(acceptedFiles.map((file) => file.name));
        acceptedFiles.forEach((file) => {
            // console.log('handleExcelDrop:forEach(file)');
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;

            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = (e) => {
                // Do whatever you want with the file contents
                let bstr = e.target.result;
                let workbook = XLSX.read(bstr, {
                    type: rABS ? 'binary' : 'array',
                });
                let sheet_name_list = workbook.SheetNames[0];
                let jsonFromExcel = XLSX.utils.sheet_to_json(
                    workbook.Sheets[sheet_name_list],
                    {
                        raw: false,
                        dateNF: 'MM-DD-YYYY',
                        header: 1,
                        defval: '',
                    }
                );
                // console.log('jsonFromExcel object=', jsonFromExcel);

                let tokenStr = localStorage.getItem('access_token');
                Axios.post(
                    `${END_POINT + '/customer/create-from-excel'}`,
                    jsonFromExcel,
                    {
                        headers: { Authorization: `Bearer ${tokenStr}` },
                    }
                )
                    .then((res) => res.data)
                    .then((data) => {
                        NotificatioSucController('Thành công');
                    })
                    .catch((err) => {
                        NotificatioErrController(
                            err.response.data.responseText
                        );
                        return false;
                    });

                // let name = [];
                // for (let i = 0; jsonFromExcel.length > i; i++) {
                //     name.push({
                //         name: jsonFromExcel[i][0] || '',
                //         phone: jsonFromExcel[i][1] || '',
                //         email: jsonFromExcel[i][2] || '',
                //         address: jsonFromExcel[i][3] || '',
                //         used: jsonFromExcel[i][4] || 0,
                //         point: parseFloat(jsonFromExcel[i][5]) || 0,
                //         armorial: jsonFromExcel[i][6] || '',
                //     });
                // }
                // console.log(name);

                // console.log(
                //     'jsonFromExcel string=',
                //     JSON.stringify(jsonFromExcel)
                // );
                // const binaryStr = reader.result;
                // console.log(binaryStr);
            };
            if (rABS) reader.readAsBinaryString(file);
            else reader.readAsArrayBuffer(file);
            // reader.readAsArrayBuffer(file);
        });
    }, []);
    // const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    const files = acceptedFiles.map((file) => {
        return (
            <div className='d-flex align-items-center'>
                <i className='iconsminds-file' />
                <p className='pl-2 mt-3' key={file.path}>
                    {file.path} - {file.size} bytes
                </p>
            </div>
        );
    });

    return (
        <section className='container p-0'>
            <div {...getRootProps({ className: 'dropzone add-file' })}>
                <input {...getInputProps()} />
                <div className='d-flex flex-column'>
                    <p className='text-muted pb-3'>
                        Kéo thả file excel vào đây, hoặc bấm vào để chọn thư mục
                        tải file lên.
                    </p>
                    <p className='text-muted text-small'>
                        (Lưu ý: File tải lên phải có định dạng là excel (.xlsx)
                        và chỉ tải 1 file excel lên cho mỗi lần)
                    </p>
                </div>
            </div>
            <aside>
                <h4 className='text-muted'>File đã tải lên</h4>
                {files.length ? (
                    files
                ) : (
                    <p className='d-flex justify-content-center align-items-center text-muted text-small'>
                        Chưa có file nào được tải lên
                    </p>
                )}
            </aside>
        </section>
    );
};

export default ContentFormAddExcel;
