import React, { Component } from 'react';
import {
    isMultiColorActive,
    defaultColor,
    themeColorStorageKey,
    isDarkSwitchActive,
} from '../../constants/defaultValues';
import THEME_COLOR from '../../constants/themeColor';
import { TableChairs } from '../svg';

const color =
    (isMultiColorActive || isDarkSwitchActive) &&
    localStorage.getItem(themeColorStorageKey)
        ? localStorage.getItem(themeColorStorageKey)
        : defaultColor;

const THEME_COLOR_STYLE = {
    hide: {
        // hide, not setting yet
        key: 0,
        chair_fill: 'none',
        chair_stroke: 'none',
        table_fill: 'none',
        table_stroke: 'none',
    },
    default: {
        // mac dinh
        key: 1,
        chair_fill: 'none',
        chair_stroke: THEME_COLOR[color].primary,
        table_fill: 'none',
        table_stroke: THEME_COLOR[color].primary,
    },
    busy: {
        // co khach
        key: 2,
        chair_fill: 'none',
        chair_stroke: THEME_COLOR[color].theme,
        table_fill: 'none',
        table_stroke: THEME_COLOR[color].theme,
    },
    order: {
        // co order
        key: 3,
        chair_fill: 'none',
        chair_stroke: THEME_COLOR[color].theme,
        table_fill: THEME_COLOR[color].theme,
        table_stroke: THEME_COLOR[color].theme,
    },
    orders: {
        // orders ghep ban
        key: 4,
        chair_fill: 'none',
        chair_stroke: THEME_COLOR[color].theme,
        table_fill: THEME_COLOR[color].theme,
        table_stroke: THEME_COLOR[color].primary,
    },
};
export default class TableChairIcon extends Component {
    render() {
        const { theme, item } = this.props;

        return (
            <TableChairs
                id_table={item?.identify}
                s={THEME_COLOR_STYLE[theme]}
            />
        );
    }
}
