import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Select from 'react-select';
import CustomSelectInput from '../../../components/common/CustomSelectInput';
import { GetSelectTime, SetSelectTime } from '../../../helpers/SelectTime';
import { AddCommaNumber } from '../../../helpers/Utils';
import DynamicForm from '../general/DynamicForm';
import TooltipItem from '../../../components/common/TooltipItem';
import { Separator } from '../../../components/common/CustomBootstrap';

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    data_dynamic_form,
    setting,
    values,
    data_stocks,
    onChange,
    onChangeDynamicForm,
}) => {
    // console.log('render UpdateComponent');
    const [timeTooltip] = useState({
        placement: 'top',
        text: '',
        body: 'Nếu chọn "Đưa vào thống kê" thì số liệu này sẽ được tính như một khoản chi trong ngày. Nếu chọn "Không đưa vào thống kê" thì chỉ xem như một con số tượng trưng.',
    });
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='pad-125rem scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        <FormGroup className='mt-3 has-float-label'>
                            <Label for='stock' className='text-muted'>
                                <IntlMessages id='warehouse.transfer-stock' />
                            </Label>
                            <Select
                                components={{ Input: CustomSelectInput }}
                                className='react-select'
                                classNamePrefix='react-select'
                                name='stock'
                                id='stock'
                                value={data_stocks.value}
                                placeholder={'Chọn 1 nguyên liệu'}
                                options={data_stocks}
                                onChange={(e) => {
                                    onChange(e, 'stock');
                                }}
                            />
                        </FormGroup>
                        {/* <FormGroup>
                            <Label for='stock' className='text-muted'>
                                <IntlMessages id='warehouse.transfer-stock' />
                            </Label>
                            <Input
                                autoFocus={true}
                                type='text'
                                name='stock'
                                id='stock'
                                value={values.stock}
                                onChange={onChange}
                            />
                        </FormGroup> */}
                        {/* <FormGroup>
                            <Label for='code' className='text-muted'>
                                <IntlMessages id='warehouse.code' />
                            </Label>
                            <Input
                                type='text'
                                name='code'
                                id='code'
                                value={values.code}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for='unit' className='text-muted'>
                                <IntlMessages id='warehouse.unit' />
                            </Label>
                            <Input
                                type='text'
                                name='unit'
                                id='unit'
                                value={values.unit}
                                onChange={onChange}
                            />
                        </FormGroup> */}
                        <FormGroup className='has-float-label'>
                            <Label for='quantity' className='text-muted'>
                                <IntlMessages id='warehouse.transfer-quantity' />
                            </Label>
                            <Input
                                type='text'
                                name='quantity'
                                id='quantity'
                                value={AddCommaNumber(values.quantity)}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='price' className='text-muted'>
                                <IntlMessages id='warehouse.transfer-price' />
                            </Label>
                            <Input
                                type='text'
                                name='price'
                                id='price'
                                value={AddCommaNumber(values.price)}
                                onChange={onChange}
                            />
                        </FormGroup>
                        {/* <FormGroup>
                            <Label for='time' className='text-muted'>
                                <IntlMessages id='time' />
                                <TooltipItem item={timeTooltip} id={7} />
                            </Label>
                            <Select
                                components={{ Input: CustomSelectInput }}
                                className='react-select'
                                classNamePrefix='react-select'
                                name='time'
                                value={GetSelectTime(values.time)}
                                placeholder={
                                    <IntlMessages id='select-one-time' />
                                }
                                options={SetSelectTime()}
                                onChange={(e) => {
                                    onChange(e, 'time');
                                }}
                            />
                        </FormGroup> */}
                        <Separator className='mb-4 mt-2' />
                        <FormGroup className='has-float-label'>
                            <Label for='name' className='text-muted'>
                                <IntlMessages id='warehouse.transfer-name' />
                            </Label>
                            <Input
                                type='text'
                                name='name'
                                id='name'
                                value={values.name}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='phone' className='text-muted'>
                                <IntlMessages id='warehouse.phone' />
                            </Label>
                            <Input
                                type='text'
                                name='phone'
                                id='phone'
                                value={values.phone}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='description' className='text-muted'>
                                <IntlMessages id='warehouse.description' />
                            </Label>
                            <Input
                                type='text'
                                name='description'
                                id='description'
                                value={values.description}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <Separator className='mb-4 mt-4' />
                        <FormGroup className='has-float-label'>
                            <Label for='name_carrier' className='text-muted'>
                                <IntlMessages id='warehouse.transfer-name-carrier' />
                            </Label>
                            <Input
                                type='text'
                                name='name_carrier'
                                id='name_carrier'
                                value={values.name_carrier}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='phone_carrier' className='text-muted'>
                                <IntlMessages id='warehouse.transfer-phone-carrier' />
                            </Label>
                            <Input
                                type='text'
                                name='phone_carrier'
                                id='phone_carrier'
                                value={values.phone_carrier}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label
                                for='receiving_warehouse'
                                className='text-muted'
                            >
                                <IntlMessages id='warehouse.transfer-receiving-warehouse' />
                            </Label>
                            <Input
                                type='text'
                                name='receiving_warehouse'
                                id='receiving_warehouse'
                                value={values.receiving_warehouse}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='transportation' className='text-muted'>
                                <IntlMessages id='warehouse.transportation' />
                            </Label>
                            <Input
                                type='text'
                                name='transportation'
                                id='transportation'
                                value={values.transportation}
                                onChange={onChange}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='cost_transfer' className='text-muted'>
                                <IntlMessages id='warehouse.cost-transfer' />
                            </Label>
                            <Input
                                type='text'
                                name='cost_transfer'
                                id='cost_transfer'
                                value={values.cost_transfer}
                                onChange={onChange}
                            />
                        </FormGroup>
                        {/* {setting.update ? (
                            <FormGroup>
                            <Label for="user.created">
                                <IntlMessages id="user.created" />
                            </Label>
                            <Input type="text" name="user.created" id="user.created"
                                disabled={true}
                                value={values.user}
                                onChange={onChange}
                            />
                            </FormGroup>
                        ) : null} */}

                        <DynamicForm
                            values={values.dynamic_form}
                            data={data_dynamic_form}
                            onChange={onChangeDynamicForm}
                            disabled={setting.viewonly && !setting.add}
                        />
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='general.btn-update' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default React.memo(ContentComponent);
