import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { NavItem, Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classnames from 'classnames';

import IntlMessages from '../../../helpers/IntlMessages';
import ApplicationMenu from '../../../components/common/ApplicationMenu';

class CustomerApplicationMenu extends PureComponent {
    render() {
        // console.log(this.props);
        const { data, data_tags, filter, filterData } = this.props;

        let labelCounts = {};

        // Đếm số lần xuất hiện của mỗi label
        data_tags.forEach((item) => {
            labelCounts[item.label] = (labelCounts[item.label] || 0) + 1;
        });

        // Cập nhật trường count trong mảng data_tags
        data_tags.forEach((item) => {
            item.count = labelCounts[item.label];
        });

        // Tạo một đối tượng để theo dõi các label đã xuất hiện
        let uniqueLabels = {};

        // Lọc mảng data_tags để chỉ giữ lại các object có label duy nhất
        let filteredData = data_tags.filter((item) => {
            if (!uniqueLabels[item.label]) {
                uniqueLabels[item.label] = true;
                return true;
            }
            return false;
        });

        const showFiveLabels = filteredData.slice(0, 10).map((tag, idx) => {
            const valueTag = tag.label;
            return (
                <NavItem key={idx}>
                    <NavLink
                        location={{}}
                        to='#'
                        onClick={(e) => filterData('tags', valueTag)}
                        className='d-flex justify-content-between align-items-center'
                    >
                        <div className='d-flex align-items-center'>
                            <i className='simple-icon-check' />
                            <span>{valueTag}</span>
                        </div>
                        <span className='float-right'>{tag.count}</span>
                    </NavLink>
                </NavItem>
            );
        });

        // console.log(filteredData);

        return (
            <ApplicationMenu>
                <PerfectScrollbar
                    options={{ suppressScrollX: true, wheelPropagation: false }}
                >
                    <div className='p-4'>
                        <NavItem
                            className={classnames({ active: !filter }, 'mb-5')}
                        >
                            <NavLink
                                to='#'
                                onClick={(e) => filterData('', '')}
                                location={{}}
                                className='d-flex justify-content-between align-items-center'
                            >
                                <div className='d-flex align-items-center'>
                                    <i className='simple-icon-layers mr-10' />
                                    <span>Tất cả</span>
                                </div>
                                <span className='float-right'>
                                    {data.length}
                                </span>
                            </NavLink>
                        </NavItem>
                        <p className='text-muted text-small'>Công nợ</p>
                        <ul className='list-unstyled mb-5'>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'debt' &&
                                        filter.value === '',
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) => filterData('debt', '')}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Không có công nợ</span>
                                    </div>
                                    <span className='float-right'>
                                        {
                                            data.filter(
                                                (x) => Number(x.debt) === 0
                                            ).length
                                        }
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'debt' &&
                                        filter.value === 'have_debt',
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) =>
                                        filterData('debt', 'have_debt')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>Có công nợ</span>
                                    </div>
                                    <span className='float-right'>
                                        {
                                            data.filter(
                                                (x) => Number(x.debt) !== 0
                                            ).length
                                        }
                                    </span>
                                </NavLink>
                            </NavItem>
                        </ul>
                        <p className='text-muted text-small'>Huy hiệu</p>
                        <ul className='list-unstyled mb-5'>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'armorial' &&
                                        filter.value === '',
                                })}
                            >
                                <NavLink
                                    location={{}}
                                    to='#'
                                    onClick={(e) => filterData('armorial', '')}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Chưa có huy hiệu</span>
                                    </div>
                                    <span className='float-right'>
                                        {
                                            data.filter(
                                                (x) => x.armorial === ''
                                            ).length
                                        }
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'armorial' &&
                                        filter.value === 'Hạng Đồng',
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) =>
                                        filterData('armorial', 'Hạng Đồng')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>Hạng Đồng</span>
                                    </div>
                                    <span className='float-right'>
                                        {
                                            data.filter(
                                                (x) =>
                                                    x.armorial === 'Hạng Đồng'
                                            ).length
                                        }
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'armorial' &&
                                        filter.value === 'Hạng Vàng',
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) =>
                                        filterData('armorial', 'Hạng Vàng')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>Hạng Vàng</span>
                                    </div>
                                    <span className='float-right'>
                                        {
                                            data.filter(
                                                (x) =>
                                                    x.armorial === 'Hạng Vàng'
                                            ).length
                                        }
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'armorial' &&
                                        filter.value === 'Hạng Platinum',
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) =>
                                        filterData('armorial', 'Hạng Platinum')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>Hạng Platinum</span>
                                    </div>
                                    <span className='float-right'>
                                        {
                                            data.filter(
                                                (x) =>
                                                    x.armorial ===
                                                    'Hạng Platinum'
                                            ).length
                                        }
                                    </span>
                                </NavLink>
                            </NavItem>
                        </ul>
                        <p className='text-muted text-small'>Email</p>
                        <ul className='list-unstyled mb-5'>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'email' &&
                                        filter.value === 'none-empty',
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) =>
                                        filterData('email', 'none-empty')
                                    }
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-check' />
                                        <span>Có</span>
                                    </div>
                                    <span className='float-right'>
                                        {
                                            data.filter(
                                                (x) =>
                                                    x.email !== '' &&
                                                    x.email !== null
                                            ).length
                                        }
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={classnames({
                                    active:
                                        filter &&
                                        filter.column === 'email' &&
                                        filter.value === '',
                                })}
                            >
                                <NavLink
                                    to='#'
                                    location={{}}
                                    onClick={(e) => filterData('email', '')}
                                    className='d-flex justify-content-between align-items-center'
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className='simple-icon-close' />
                                        <span>Không</span>
                                    </div>
                                    <span className='float-right'>
                                        {
                                            data.filter(
                                                (x) =>
                                                    x.email === '' ||
                                                    x.email === null
                                            ).length
                                        }
                                    </span>
                                </NavLink>
                            </NavItem>
                        </ul>
                        <p className='text-muted text-small'>Tags</p>
                        <ul className='list-unstyled mb-5'>{showFiveLabels}</ul>
                    </div>
                </PerfectScrollbar>
            </ApplicationMenu>
        );
    }
}

export default CustomerApplicationMenu;
