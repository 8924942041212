import React from "react"
import ContentLoader from "react-content-loader" 

const MyLoader = () => (
  <ContentLoader 
    speed={2}
    width={400}
    height={160}
    viewBox="0 0 400 160"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="7" rx="3" ry="3" width="88" height="6" /> 
    <rect x="-1" y="24" rx="3" ry="3" width="52" height="6" /> 
    <rect x="0" y="55" rx="3" ry="3" width="410" height="6" /> 
    <rect x="0" y="72" rx="3" ry="3" width="380" height="6" /> 
    <rect x="0" y="91" rx="3" ry="3" width="398" height="5" />
  </ContentLoader>
)

export default MyLoader