import React, { Component } from "react";
import axios from "axios";
import { END_POINT } from "../../../constants/defaultValues";
import {
  Collapse,
  Button,
  Row,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx } from "../../../components/common/CustomBootstrap";
import { NotificatioErrController, NotificatioSucController } from '../../../helpers/Utils'
import {
  ContextMenu,
  PageHeading,
} from '../../../containers/faq'
import { ContextMenuTrigger } from 'react-contextmenu'
import classnames from "classnames"
import { Redirect } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import RawHTML from '../../../helpers/RawContent'
// import 'jodit';
// import 'jodit/build/jodit.min.css';
// import 'jodit/build/jodit.min.js';
import JoditEditor from "jodit-react";

function collect(props) {
  return { data: props.data };
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default class RootFaq extends Component {
  render() {
    return (
      <MainEditor />
    )
  }
}

const config = {
  readonly: false,
  uploader: { insertImageAsBase64URI: true },
  toolbarAdaptive: true,
}

class MainEditor extends Component {
  constructor(props) {
    super(props);
    this.mouseTrap = require('mousetrap');
    this.editor = React.createRef();

    this.state = {
      contentSaved: "",
      submited: false,
      isLoaded: false,
      editorState: "",
      selectedItems: [],
      data: [],
      isOpenModal: false,
      dataSubmit: {
        id: undefined,
        title: "",
      }
    }
  }

  componentDidMount() {
    this.mouseTrap.bind(["ctrl+a", "command+a"], () =>
      this.handleChangeSelectAll(false)
    );
    this.mouseTrap.bind(["ctrl+d", "command+d"], () => {
      this.setState({
        selectedItems: []
      });
      return false;
    });

    this.dataFaqRender();
  }
  componentWillUnmount() {
    this.mouseTrap.unbind("ctrl+a");
    this.mouseTrap.unbind("command+a");
    this.mouseTrap.unbind("ctrl+d");
    this.mouseTrap.unbind("command+d");
  }


  dataFaqRender = () => {
    let tokenStr = localStorage.getItem('access_token');
    axios
      .get(
        `${END_POINT + "/faq"}`,
        {
          headers: {
            "Authorization": `Bearer ${tokenStr}`
          }
        }
      )
      .then(res => {
        return res.data;
      })
      .then(data => {
        this.setState({
          data: data.data,
          isLoaded: true,
        })
      })
      .catch(err => {
        NotificatioErrController(err.response.data.responseText)
      })
  }

  dataFaqUpdateOrder = (items) => {
    let tokenStr = localStorage.getItem('access_token');
    axios
      .put(
        `${END_POINT + "/faq/order"}`,
        {
          data: items,
        },
        {
          headers: {
            "Authorization": `Bearer ${tokenStr}`
          }
        }
      )
      .catch(err => {
        NotificatioErrController(err.response.data.responseText)
      })
  }

  onValueDataSubmitStateChange = (e) => {
    const { target: { name, value } } = e;

    this.setState({
      dataSubmit: {
        ...this.state.dataSubmit,
        [name]: value
      }
    });
  };

  onEditorStateChange = (editorState) => {
    if (!editorState || editorState == null) return;
    this.setState({
      editorState,
    });
  };

  handleSubmit() {
    if (!this.state.dataSubmit.title) {
      NotificatioErrController("Câu hỏi không được trống");
      return false;
    }

    if (!this.state.editorState) {
      NotificatioErrController("Trả lời không được trống");
      return false;
    }

    let html = this.state.editorState;

    this.setState({ submited: true });

    if (this.state.dataSubmit.id !== undefined) {
      // update 
      this.onHandleUpdate({
        id: this.state.dataSubmit.id,
        title: this.state.dataSubmit.title,
        content: html,
      })
    } else {
      // create
      this.onHandleCreate({
        title: this.state.dataSubmit.title,
        content: html,
      })
    }
  }

  onHandleUpdate(data) {
    let tokenStr = localStorage.getItem('access_token');
    axios
      .put(`${END_POINT + "/faq"}`, data, { headers: { "Authorization": `Bearer ${tokenStr}` } })
      .then(res => {
        return res.data;
      })
      .then(data => {
        NotificatioSucController(data.responseText)
        this.setState({ submited: false, dataSubmit: { id: undefined, title: "" }, isOpenModal: false });
        this.dataFaqRender();
      })
      .catch(err => {
        NotificatioErrController(err.response.data.responseText)
        this.setState({ submited: false, isOpenModal: false });
      })
  }

  onHandleCreate(data) {
    let tokenStr = localStorage.getItem('access_token');
    axios
      .post(`${END_POINT + "/faq"}`, data, { headers: { "Authorization": `Bearer ${tokenStr}` } })
      .then(res => {
        return res.data;
      })
      .then(data => {
        NotificatioSucController(data.responseText)
        this.setState({ submited: false, dataSubmit: { id: undefined, title: "" }, isOpenModal: false });
        this.dataFaqRender();
      })
      .catch(err => {
        NotificatioErrController(err.response.data.responseText)
        this.setState({ submited: false, isOpenModal: false });
      })
  }

  onHandleDetele(ids) {
    if (ids.length < 1)
      return false;

    let tokenStr = localStorage.getItem('access_token');
    axios
      .delete(
        `${END_POINT + "/faq"}`,
        {
          data:
          {
            arrayId: ids,
          },
          headers: { "Authorization": `Bearer ${tokenStr}` }
        }
      )
      .then(res => {
        return res.data;
      })
      .then(data => {
        NotificatioSucController(data.responseText)
        this.dataFaqRender();
      })
      .catch(err => {
        NotificatioSucController(err.response.data.responseText)
      })
  }

  /**
   *  
   * Checkbox
   * 
   */
  toggleModal = () => {

    if (this.state.isOpenModal) {
      this.setState({
        dataSubmit: {
          ...this.state.dataSubmit,
          id: 0,
          title: "",
        },
        editorState: "",
        isOpenModal: !this.state.isOpenModal,
      });
      return;
    }

    this.setState({
      isOpenModal: !this.state.isOpenModal
    });
  };

  handleChangeSelectAll = isToggle => {
    if (this.state.selectedItems.length >= this.state.data.length) {
      if (isToggle) {
        this.setState({
          selectedItems: []
        });
      }
    } else {
      this.setState({
        selectedItems: this.state.data.map(x => x.id)
      });
    }
    document.activeElement.blur();
    return false;
  };

  onActions = (action) => {
    switch (action) {
      case "delete":
        this.onHandleDetele(this.state.selectedItems);
        break;

      case "update":
        if (this.state.selectedItems.length > 0) {

          let item = this.state.data.filter(item => item.id === this.state.selectedItems[0])[0]
          if (item) {
            this.setState({
              editorState: item.content,//editorState,
              dataSubmit: {
                ...this.state.dataSubmit,
                id: item.id,
                title: item.title,
              }
            });
            this.toggleModal();
          }
        }
        break;
      default:
        break;
    }
    return false;
  }
  onContextMenuClick = (e, data, target) => {
    this.onActions(data.action)
  };
  onContextMenu = (e, data) => {
    const clickedProductId = data.data;
    this.setState({
      selectedItems: [clickedProductId]
    });
    return true;
  };
  onCheckItem = (event, id) => {
    if (
      event.target.tagName === "A" ||
      (event.target.parentElement && event.target.parentElement.tagName === "A")
    ) {
      return true;
    }
    if (this.state.lastChecked === null) {
      this.setState({
        lastChecked: id
      });
    }

    let selectedItems = this.state.selectedItems;
    if (selectedItems.includes(id)) {
      selectedItems = selectedItems.filter(x => x !== id);
    } else {
      selectedItems.push(id);
    }
    this.setState({
      selectedItems
    });

    if (event.shiftKey) {
      var items = this.state.data;
      var start = this.getIndex(id, items, "id");
      var end = this.getIndex(this.state.lastChecked, items, "id");
      items = items.slice(Math.min(start, end), Math.max(start, end) + 1);
      selectedItems.push(
        ...items.map(item => {
          return item.id;
        })
      );
      selectedItems = Array.from(new Set(selectedItems));
      this.setState({
        selectedItems
      });
    }
    document.activeElement.blur();
  };

  // DRAG AND DROP
  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.data,
      result.source.index,
      result.destination.index
    );

    this.setState({
      data: items
    });

    this.dataFaqUpdateOrder(items);
  }

  // onBlur = (event) => { const editorValue = event.target.innerHTML; this.onEditorStateChange(editorValue); };

  render() {
    let {
      isLoaded,
      submited,
      editorState,
      selectedItems,
      data,
      isOpenModal,
      dataSubmit,
    } = this.state;

    const store = window.location.host.split('.')[0];
    if (store !== 'faq') {
      return <Redirect to="/app/dashboards/default" />
    }

    // /// all options from https://xdsoft.net/jodit/doc/
    return !isLoaded ? (
      <div className="loading" />
    ) : (
        <React.Fragment>
          <div className="disable-text-selection">
            <PageHeading
              handleChangeSelectAll={this.handleChangeSelectAll}
              selectedItemsLength={selectedItems ? selectedItems.length : 0}
              itemsLength={data ? data.length : 0}
              toggleModal={this.toggleModal}
              onActions={this.onActions}
            />
            <Faq
              data={data}
              onDragEnd={this.onDragEnd}
              isSelect={selectedItems}
              onCheckItem={this.onCheckItem}
            />

            <ContextMenu
              onContextMenuClick={this.onContextMenuClick}
              onContextMenu={this.onContextMenu}
            />
            <Modal
              isOpen={isOpenModal}
              size="lg"
              toggle={this.toggleModal}
            >
              <ModalHeader toggle={this.toggleModal}>
                <IntlMessages id="faq.modal-title" />
              </ModalHeader>
              <ModalBody>
                <Form>
                  <FormGroup>
                    <Label for="title">
                      <IntlMessages id="faq.text-title" />
                    </Label>
                    <Input
                      type="text"
                      name="title"
                      id="title"
                      className="mb-4"
                      onChange={this.onValueDataSubmitStateChange}
                      value={dataSubmit.title}
                    />
                    <Label for="text-content">
                      <IntlMessages id="faq.text-content" />
                    </Label>
                    <div className="border mb-4">
                      <JoditEditor
                        ref={this.editor}
                        value={editorState}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => this.onEditorStateChange(newContent.target.innerHTML)}
                        onChange={newContent => { }}
                      />
                    </div>
                    <div className="container-button-editor">
                      {
                        submited
                          ?
                          <Button disabled block>CẬP NHẬT</Button>
                          :
                          <Button
                            onClick={() => {
                              this.handleSubmit();
                            }}
                            size="sm"
                            color="success"
                            className="margin-0-auto"
                            block
                          >
                            CẬP NHẬT</Button>
                      }
                    </div>
                  </FormGroup>
                </Form>
              </ModalBody>
            </Modal>
          </div>
        </React.Fragment>
      );
  }
}

class Faq extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      collapse: false,
      accordion: [true, false, false]
    };
  }

  componentDidMount() {
    let anyAccordion = [];
    let numeroPerguntas = this.props.data.length;
    for (var i = 0; i < numeroPerguntas; i++) {
      if (!i) {
        anyAccordion.push(true)
      }
      anyAccordion.push(false);
    }

    this.setState({
      accordion: anyAccordion
    })
  }

  toggleAccordion = tab => {
    const prevState = this.state.accordion;
    const state = prevState.map((x, index) => (tab === index ? !x : false));
    this.setState({
      accordion: state
    });
  };

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };


  render() {
    const { isSelect, onCheckItem, data } = this.props;

    return !data ?
      <div className="loading" />
      : (
        <div className="dashboard-wrapper">
          <Row>
            <Colxx xxs="12" className="mb-4">
              <DragDropContext onDragEnd={this.props.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {data.map((faq, idx) => {
                        return (
                          <Draggable key={faq.id} draggableId={`item_${faq.id}`} index={idx}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ContextMenuTrigger id="item" data={faq.id} collect={collect}>
                                  <div
                                    onClick={event => onCheckItem(event, faq.id)}
                                    className={classnames("drag card d-flex mb-3", {
                                      active: isSelect.includes(faq.id)
                                    })} >
                                    <div className="d-flex flex-grow-1 min-width-zero">
                                      <Button
                                        color="link"
                                        onClick={() => this.toggleAccordion(idx)}
                                        aria-expanded={this.state.accordion[idx]}
                                        className="card-body btn-empty btn-link list-item-heading text-left text-one btn btn-link pre-wrap"
                                      >
                                        {faq.title}
                                      </Button>
                                      <div className="custom-control custom-checkbox pl-1 align-self-center mr-4 flex-item">
                                        <CustomInput
                                          className="itemCheck mb-0"
                                          type="checkbox"
                                          id={`check_${faq.id}`}
                                          checked={isSelect.includes(faq.id)}
                                          onChange={() => { }}
                                          label=""
                                        />
                                        <span>
                                          <i className="iconsminds-cursor-move-2" />
                                        </span>
                                      </div>
                                    </div>
                                    <Collapse isOpen={this.state.accordion[idx]}>
                                      <div className="card-body accordion-content pt-0 overflow-hidden">
                                        <RawHTML children={faq.content} className={""} />
                                      </div>
                                    </Collapse>
                                  </div>
                                </ContextMenuTrigger>
                              </div>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Colxx>
          </Row>
        </div>
      );
  }
}