import React from 'react';
import { Row, Card, CardBody, Badge } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
// import CircularProgressbar from 'react-circular-progressbar';
import { AddCommaNumber } from '../../../helpers/Utils';
// import classnames from 'classnames';
// import { isRorRBD } from '../../../helpers/Roles';

const ContentComponent = ({
    stocks,
    products,
    data_outcome,
    data_income,
    data_order_table,
    data_order_payment,
    all,
}) => {
    // console.log('data_order_table: ', data_order_table);
    // console.log('stocks: ', stocks);
    // console.log('data_order_payment: ', data_order_payment);

    const totalInventory = stocks?.data?.reduce((acc, curr) => {
        const avaragePrice = parseFloat(curr.avarage_price);
        const count = curr.count;
        if (!isNaN(avaragePrice) && !isNaN(count)) {
            return Math.floor(acc + avaragePrice * count);
        } else {
            return Math.floor(acc);
        }
    }, 0);

    // console.log('totalInventory: ', totalInventory);

    const get_income = GetIncomeDataAndCompare({
        income: data_income,
        outcome: data_outcome,
    });

    return (
        <>
            {/* <Row className='analytics-banner'></Row> */}
            <Card className='progress-banner w-100 mb-4'>
                <CardBody>
                    <Row className='d-flex flex-column align-items-center justify-content-center mb-3'>
                        <p>Doanh thu</p>
                        <div className='d-flex flex-column flex-lg-row'>
                            <h2 className='mr-2 text-white'>
                                {`${AddCommaNumber(data_income.total_price)} đ`}
                            </h2>
                        </div>
                        {!all ? (
                            <>
                                <Badge
                                    color={
                                        data_income.compare_total.compare_price
                                            .increment
                                            ? 'success'
                                            : 'danger'
                                    }
                                >
                                    {/* <span
                                            className={
                                                data_income.compare_total
                                                    .compare_price.increment
                                                    ? 'green'
                                                    : 'red'
                                            }
                                        > */}
                                    {`${
                                        data_income.compare_total.compare_price
                                            .increment
                                            ? '+'
                                            : ''
                                    } ${
                                        data_income.compare_total.compare_price
                                            .value
                                    } %`}
                                    {/* </span> */}
                                </Badge>
                                {/* <span className='d-xxs-block mt-3 fs-15px'>
                                    {`${
                                        data_income.compare_total.compare_price
                                            .increment
                                            ? '+'
                                            : ''
                                    } ${
                                        data_income.compare_total.compare_price
                                            .value
                                    } %`}
                                </span> */}
                            </>
                        ) : null}
                    </Row>
                    <Row>
                        <Colxx
                            xxs='6'
                            md='3'
                            className='d-flex flex-column align-items-center justify-content-center mt-3'
                        >
                            <p>Chi phí</p>
                            <div className='d-flex flex-column flex-lg-row'>
                                <h5 className='mr-2 text-white'>
                                    {`${AddCommaNumber(
                                        data_outcome.total_price
                                    )} đ`}
                                </h5>
                            </div>
                            {!all ? (
                                <>
                                    <Badge
                                        color={
                                            data_income.compare_total
                                                .compare_price.increment
                                                ? 'danger'
                                                : 'success'
                                        }
                                    >
                                        {/* <span
                                            className={
                                                data_outcome.compare_total
                                                    .compare_price.increment
                                                    ? 'red'
                                                    : 'green'
                                            }
                                        > */}
                                        {`${
                                            data_outcome.compare_total
                                                .compare_price.increment
                                                ? '+'
                                                : ''
                                        } ${
                                            data_outcome.compare_total
                                                .compare_price.value
                                        } %`}
                                        {/* </span> */}
                                    </Badge>
                                    {/* <span className='d-xxs-block mt-3 fs-15px'>
                                        {`${
                                            data_outcome.compare_total
                                                .compare_price.increment
                                                ? '+'
                                                : ''
                                        } ${
                                            data_outcome.compare_total
                                                .compare_price.value
                                        } %`}
                                    </span> */}
                                </>
                            ) : null}
                        </Colxx>
                        <Colxx
                            xxs='6'
                            md='3'
                            className='d-flex flex-column align-items-center justify-content-center mt-3'
                        >
                            <p>Lợi nhuận</p>
                            <div className='d-flex flex-column flex-lg-row'>
                                <h5 className='mr-2 text-white'>
                                    {`${AddCommaNumber(
                                        get_income.total_price
                                    )} đ`}
                                </h5>
                            </div>
                            {!all ? (
                                <>
                                    <Badge
                                        color={
                                            data_income.compare_total
                                                .compare_price.increment
                                                ? 'success'
                                                : 'danger'
                                        }
                                    >
                                        {/* <span
                                            className={
                                                get_income.compare_price
                                                    .increment
                                                    ? 'green'
                                                    : 'red'
                                            }
                                        > */}
                                        {`${
                                            get_income.compare_price.increment
                                                ? '+'
                                                : ''
                                        } ${get_income.compare_price.value} %`}
                                        {/* </span> */}
                                    </Badge>
                                    {/* <div className='progress-bar-circle progress-bar-banner position-relative d-xxs-none'>
                                        <CircularProgressbar
                                            className='progress-bar-banner-medium'
                                            strokeWidth={4}
                                            percentage={
                                                get_income.compare_price.value
                                            }
                                            text={`${
                                                get_income.compare_price
                                                    .increment
                                                    ? '+'
                                                    : ''
                                            } ${
                                                get_income.compare_price.value
                                            } %`}
                                        />
                                    </div>
                                    <div className='d-xxs-block mt-3 fs-15px'>
                                        {`${
                                            get_income.compare_price.increment
                                                ? '+'
                                                : ''
                                        } ${get_income.compare_price.value} %`}
                                    </div> */}
                                </>
                            ) : null}
                        </Colxx>
                        <Colxx
                            xxs='6'
                            md='3'
                            className='d-flex flex-column align-items-center justify-content-center mt-3'
                        >
                            <p>Số hoá đơn</p>
                            <div className='d-flex flex-column flex-lg-row'>
                                <h5 className='mr-2 text-white'>
                                    {`${data_order_table.total_table_has_guest} hoá đơn`}
                                </h5>
                            </div>
                            {!all ? (
                                <Badge
                                    color={
                                        data_income.compare_total.compare_price
                                            .increment
                                            ? 'success'
                                            : 'danger'
                                    }
                                >
                                    {/* <span
                                        className={
                                            data_order_table.compare_total
                                                .compare_count.increment
                                                ? 'green'
                                                : 'red'
                                        }
                                    >
                                        ( */}
                                    {`${
                                        data_order_table.compare_total
                                            .compare_count.increment
                                            ? '+'
                                            : ''
                                    } ${
                                        data_order_table.compare_total
                                            .compare_count.value
                                    } %`}
                                    {/* )
                                    </span> */}
                                </Badge>
                            ) : null}
                        </Colxx>
                        <Colxx
                            xxs='6'
                            md='3'
                            className='d-flex flex-column align-items-center justify-content-center mt-3 '
                        >
                            <p>Số sản phẩm đã bán</p>
                            <div className='d-flex flex-column flex-lg-row'>
                                <h5 className='mr-2 text-white'>
                                    {`${data_order_payment.total_count} sản phẩm`}
                                </h5>
                            </div>
                            {!all ? (
                                <Badge
                                    color={
                                        data_income.compare_total.compare_price
                                            .increment
                                            ? 'success'
                                            : 'danger'
                                    }
                                >
                                    {/* <span
                                        className={
                                            data_order_payment.compare_total
                                                .compare_count.increment
                                                ? 'green'
                                                : 'red'
                                        }
                                    >
                                        ( */}
                                    {`${
                                        data_order_payment.compare_total
                                            .compare_count.increment
                                            ? '+'
                                            : ''
                                    } ${
                                        data_order_payment.compare_total
                                            .compare_count.value
                                    } %`}
                                    {/* )
                                    </span> */}
                                </Badge>
                            ) : null}
                        </Colxx>
                        <Colxx
                            xxs='6'
                            md='3'
                            className='d-flex flex-column align-items-center justify-content-center mt-3'
                        >
                            <p>Giá bán trung bình</p>
                            <div className='d-flex flex-column flex-lg-row'>
                                <h5 className='mr-2 text-white'>
                                    {data_order_payment?.total_count
                                        ? AddCommaNumber(
                                              (
                                                  parseFloat(
                                                      data_order_payment?.total_price
                                                  ) /
                                                  data_order_payment?.total_count
                                              ).toFixed(0)
                                          )
                                        : 0}{' '}
                                    đ
                                </h5>
                                {/* {!all ? (
                                    <span
                                        className={
                                            data_order_payment.compare_total
                                                .compare_count.increment
                                                ? 'green'
                                                : 'red'
                                        }
                                    >
                                        (
                                        {`${
                                            data_order_payment.compare_total
                                                .compare_count.increment
                                                ? '+'
                                                : ''
                                        } ${
                                            data_order_payment.compare_total
                                                .compare_count.value
                                        } %`}
                                        )
                                    </span>
                                ) : null} */}
                            </div>
                        </Colxx>
                        <Colxx
                            xxs='6'
                            md='3'
                            className='d-flex flex-column align-items-center justify-content-center mt-3 '
                        >
                            <p>Tổng số sản phẩm</p>
                            <div className='d-flex flex-column flex-lg-row'>
                                <h5 className='mr-2 text-white'>
                                    {products?.data?.length} sản phẩm
                                </h5>
                                {/* {!all ? (
                                    <span
                                        className={
                                            data_order_payment.compare_total
                                                .compare_count.increment
                                                ? 'green'
                                                : 'red'
                                        }
                                    >
                                        (
                                        {`${
                                            data_order_payment.compare_total
                                                .compare_count.increment
                                                ? '+'
                                                : ''
                                        } ${
                                            data_order_payment.compare_total
                                                .compare_count.value
                                        } %`}
                                        )
                                    </span>
                                ) : null} */}
                            </div>
                        </Colxx>
                        <Colxx
                            xxs='6'
                            md='3'
                            className='d-flex flex-column align-items-center justify-content-center mt-3 border-right-above-md'
                        >
                            <p>Lượng hàng tồn kho</p>
                            <div className='d-flex flex-column flex-lg-row'>
                                <h5 className='mr-2 text-white'>
                                    {stocks?.data?.length} mặt hàng
                                </h5>
                                {/* {!all ? (
                                    <span
                                        className={
                                            data_order_payment.compare_total
                                                .compare_count.increment
                                                ? 'green'
                                                : 'red'
                                        }
                                    >
                                        (
                                        {`${
                                            data_order_payment.compare_total
                                                .compare_count.increment
                                                ? '+'
                                                : ''
                                        } ${
                                            data_order_payment.compare_total
                                                .compare_count.value
                                        } %`}
                                        )
                                    </span>
                                ) : null} */}
                            </div>
                        </Colxx>
                        <Colxx
                            xxs='6'
                            md='3'
                            className='d-flex flex-column align-items-center justify-content-center mt-3 border-right-above-md '
                        >
                            <p>Giá trị tồn kho</p>
                            <div className='d-flex flex-column flex-lg-row'>
                                <h5 className='mr-2 text-white'>
                                    {AddCommaNumber(totalInventory)} đ
                                </h5>
                                {/* {!all ? (
                                    <span
                                        className={
                                            data_order_payment.compare_total
                                                .compare_count.increment
                                                ? 'green'
                                                : 'red'
                                        }
                                    >
                                        (
                                        {`${
                                            data_order_payment.compare_total
                                                .compare_count.increment
                                                ? '+'
                                                : ''
                                        } ${
                                            data_order_payment.compare_total
                                                .compare_count.value
                                        } %`}
                                        )
                                    </span>
                                ) : null} */}
                            </div>
                        </Colxx>
                        {/* <Colxx
                            xxs='12'
                            md='3'
                            className='d-flex flex-column align-items-center justify-content-center mt-3'
                        >
                            <p>Điểm hoà vốn</p>
                            <div className='d-flex flex-column flex-lg-row'>
                                // <h5 className='mr-2 text-white'>1.500.000 đ</h5>
                                //  {!all ? (
                                //     <span
                                //         className={
                                //             data_order_payment.compare_total
                                //                 .compare_count.increment
                                //                 ? 'green'
                                //                 : 'red'
                                //         }
                                //     >
                                //         (
                                //         {`${
                                //             data_order_payment.compare_total
                                //                 .compare_count.increment
                                //                 ? '+'
                                //                 : ''
                                //         } ${
                                //             data_order_payment.compare_total
                                //                 .compare_count.value
                                //         } %`}
                                //         )
                                //     </span>
                                // ) : null}
                            </div>
                        </Colxx> */}
                    </Row>
                </CardBody>
            </Card>
            {/* <Colxx
                sm='12'
                lg='2'
                xl='2'
                className='mb-4 d-sm-none d-xs-none d-xss-none d-lg-block d-xxs-none'
            >
                <Card
                    className={classnames('progress-banner', {
                        disable: !isRorRBD('analytics_charts'),
                    })}
                >
                    <div className='m-0-auto justify-content-between d-flex flex-row align-items-center text-center custom-card h-100'>
                        <div className='container'>
                            <div>
                                <h5 className='text-white'>
                                    {'Số lần mua hàng'}
                                </h5>
                                <div>
                                    <span className='lead lead-small text-white'>
                                        {`${data_order_table.total_table_has_guest} Lần`}
                                    </span>{' '}
                                    {!all ? (
                                        <span
                                            className={
                                                data_order_table.compare_total
                                                    .compare_count.increment
                                                    ? 'green'
                                                    : 'red'
                                            }
                                        >
                                            {`${
                                                data_order_table.compare_total
                                                    .compare_count.increment
                                                    ? '+'
                                                    : ''
                                            } ${
                                                data_order_table.compare_total
                                                    .compare_count.value
                                            } %`}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </Colxx>
            <Colxx
                sm='3'
                lg='2'
                xl='2'
                xs='3'
                xxs='3'
                className='mb-4 col-pad-left'
            >
                <Card
                    className={classnames(
                        'progress-banner progress-banner-small',
                        {
                            disable: !isRorRBD('analytics_charts'),
                        }
                    )}
                >
                    <div className='m-0-auto justify-content-between d-flex flex-row align-items-center text-center custom-card h-100'>
                        <div className='container'>
                            <div>
                                <h4 className='text-white '>{'Doanh thu'}</h4>
                                <div>
                                    <span className='lead lead-small text-white'>
                                        {`${AddCommaNumber(
                                            data_income.total_price
                                        )} đ`}
                                    </span>{' '}
                                    {!all ? (
                                        <>
                                            <span
                                                className={
                                                    data_income.compare_total
                                                        .compare_price.increment
                                                        ? 'green'
                                                        : 'red'
                                                }
                                            >
                                                {`${
                                                    data_income.compare_total
                                                        .compare_price.increment
                                                        ? '+'
                                                        : ''
                                                } ${
                                                    data_income.compare_total
                                                        .compare_price.value
                                                } %`}
                                            </span>
                                            <span className='d-xxs-block mt-3 text-white fs-15px'>
                                                {`${
                                                    data_income.compare_total
                                                        .compare_price.increment
                                                        ? '+'
                                                        : ''
                                                } ${
                                                    data_income.compare_total
                                                        .compare_price.value
                                                } %`}
                                            </span>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </Colxx>
            <Colxx
                sm='6'
                lg='4'
                xl='4'
                xs='6'
                xxs='6'
                className='mb-4 col-pad-center'
            >
                <Card
                    className={classnames('progress-banner', {
                        disable: !isRorRBD('analytics_charts'),
                    })}
                >
                    <div className='m-0-auto justify-content-around d-flex flex-row align-items-center text-center custom-card h-100 w-100'>
                        <div className='container-center d-flex justify-content-around align-items-center w-100'>
                            <div>
                                <h3 className='text-white'>{'Lợi nhuận'}</h3>
                                <p className='lead lead-small text-white'>
                                    {`${AddCommaNumber(
                                        get_income.total_price
                                    )} đ`}
                                </p>
                            </div>
                            {!all ? (
                                <>
                                    <div className='progress-bar-circle progress-bar-banner position-relative d-xxs-none'>
                                        <CircularProgressbar
                                            className='progress-bar-banner-medium'
                                            strokeWidth={4}
                                            percentage={
                                                get_income.compare_price.value
                                            }
                                            text={`${
                                                get_income.compare_price
                                                    .increment
                                                    ? '+'
                                                    : ''
                                            } ${
                                                get_income.compare_price.value
                                            } %`}
                                        />
                                    </div>
                                    <div className='d-xxs-block mt-3 text-white fs-15px'>
                                        {`${
                                            get_income.compare_price.increment
                                                ? '+'
                                                : ''
                                        } ${get_income.compare_price.value} %`}
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                </Card>
            </Colxx>
            <Colxx
                sm='3'
                lg='2'
                xl='2'
                xs='3'
                xxs='3'
                className='mb-4 col-pad-right'
            >
                <Card
                    className={classnames(
                        'progress-banner progress-banner-small',
                        {
                            disable: !isRorRBD('analytics_charts'),
                        }
                    )}
                >
                    <div className='m-0-auto justify-content-between d-flex flex-row align-items-center text-center custom-card h-100'>
                        <div className='container'>
                            <div>
                                <h4 className='text-white'>{'Chi phí'}</h4>
                                <div>
                                    <span className='lead lead-small text-white'>
                                        {`${AddCommaNumber(
                                            data_outcome.total_price
                                        )} đ`}
                                    </span>{' '}
                                    {!all ? (
                                        <>
                                            <span
                                                className={
                                                    data_outcome.compare_total
                                                        .compare_price.increment
                                                        ? 'red'
                                                        : 'green'
                                                }
                                            >
                                                {`${
                                                    data_outcome.compare_total
                                                        .compare_price.increment
                                                        ? '+'
                                                        : ''
                                                } ${
                                                    data_outcome.compare_total
                                                        .compare_price.value
                                                } %`}
                                            </span>
                                            <span className='d-xxs-block mt-3 text-white fs-15px'>
                                                {`${
                                                    data_outcome.compare_total
                                                        .compare_price.increment
                                                        ? '+'
                                                        : ''
                                                } ${
                                                    data_outcome.compare_total
                                                        .compare_price.value
                                                } %`}
                                            </span>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </Colxx>
            <Colxx
                sm='6'
                lg='2'
                xl='2'
                className='mb-4 d-lg-none d-xl-none d-xxs-none'
            >
                <Card
                    className={classnames('progress-banner', {
                        disable: !isRorRBD('analytics_charts'),
                    })}
                >
                    <div className='m-0-auto justify-content-between d-flex flex-row align-items-center text-center custom-card h-100'>
                        <div className='container'>
                            <div>
                                <h5 className='text-white'>
                                    {'Số hoá đơn'}
                                </h5>
                                <div>
                                    <span className='lead lead-small text-white'>
                                        {`${data_order_table.total_count} Bàn`}
                                    </span>{' '}
                                    {!all ? (
                                        <span
                                            className={
                                                data_order_table.compare_total
                                                    .compare_count.increment
                                                    ? 'green'
                                                    : 'red'
                                            }
                                        >
                                            {`${
                                                data_order_table.compare_total
                                                    .compare_count.increment
                                                    ? '+'
                                                    : ''
                                            } ${
                                                data_order_table.compare_total
                                                    .compare_count.value
                                            } %`}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </Colxx>
            <Colxx sm='6' lg='2' xl='2' className='mb-4 d-xxs-none'>
                <Card
                    className={classnames('progress-banner', {
                        disable: !isRorRBD('analytics_charts'),
                    })}
                >
                    <div className='m-0-auto justify-content-between d-flex flex-row align-items-center text-center custom-card h-100'>
                        <div className='container'>
                            <div>
                                <h5 className='text-white'>
                                    {'Số sản phẩm đã bán'}
                                </h5>
                                <div>
                                    <span className='lead lead-small text-white'>
                                        {`${data_order_payment.total_count} Sản phẩm`}
                                    </span>{' '}
                                    {!all ? (
                                        <span
                                            className={
                                                data_order_payment.compare_total
                                                    .compare_count.increment
                                                    ? 'green'
                                                    : 'red'
                                            }
                                        >
                                            {`${
                                                data_order_payment.compare_total
                                                    .compare_count.increment
                                                    ? '+'
                                                    : ''
                                            } ${
                                                data_order_payment.compare_total
                                                    .compare_count.value
                                            } %`}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </Colxx> */}
        </>
    );
};

const GetIncomeDataAndCompare = ({ income, outcome }) => {
    let result = { compare_price: {} };
    const total_get_income = Math.round(
        parseFloat(income.total_price) - parseFloat(outcome.total_price)
    );
    const total_flip_get_income = Math.round(
        parseFloat(income.compare_total.compare_price.flip_value) -
            parseFloat(outcome.compare_total.compare_price.flip_value)
    );

    result.total_price = total_get_income;

    // So sánh
    // Giá tiền
    let percent_change_price = 0;
    const change_price =
        parseFloat(total_get_income) - parseFloat(total_flip_get_income);
    percent_change_price =
        total_flip_get_income == 0
            ? 0
            : (change_price * 100) /
              Math.abs(parseFloat(total_flip_get_income));

    result.compare_price.increment = change_price <= 0 ? false : true;
    result.compare_price.value = Math.round(percent_change_price * 100) / 100;
    result.compare_price.flip_value = total_flip_get_income;

    return result;
};

export default ContentComponent;
