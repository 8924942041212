import React from 'react';
import { Card, CardBody, CardTitle, Label, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentDisplay = (props) => {
    // console.log(props);
    const ItemProduct = props.values.log?.map((item) => item.name);
    const UniqueItemProduct = [...new Set(ItemProduct)];
    // console.log(UniqueItemProduct);

    return (
        <Card className='mb-3'>
            <CardBody>
                <CardTitle>
                    <IntlMessages id='company-provider.title' />
                </CardTitle>
                <Row className='mt-3 '>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='name' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.name ? (
                                props.values.name
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='phone' />
                        </p>
                        <p className='mb-3'>
                            {props.values.phone ? (
                                props.values.phone
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='company-provider.item_product' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {UniqueItemProduct.length ? (
                                UniqueItemProduct.map((item) => item)
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có mặt hàng nào)
                                </span>
                            )}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='email' />
                        </p>
                        <p className='mb-3 text-lowercase'>
                            {props.values.email
                                ? props.values.email
                                : '(Chưa có thông tin)'}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='address' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.address ? (
                                props.values.address
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='company-provider.total_price_income' />
                        </p>
                        <p className='mb-3'>
                            {AddCommaNumber(props.values.total_price_income)}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='company-provider.total_price_outcome' />
                        </p>
                        <p className='mb-3'>
                            {AddCommaNumber(props.values.total_price_outcome)}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='company-provider.total_price_pending' />
                        </p>
                        <p className='mb-3'>
                            {AddCommaNumber(props.values.total_price_pending)}
                        </p>
                    </Colxx>
                </Row>
            </CardBody>
        </Card>
    );
};

export default ContentDisplay;
