import React from 'react';
import { Label, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { GetSelectTime } from '../../../helpers/SelectTime';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentDisplayMobile = (props) => {
    // console.log(props);
    return (
        <>
            <Row className='mt-3' xxs='12'>
                <Colxx xxs='12' lg='6'>
                    <p className='text-muted text-small mb-2'>
                        <IntlMessages id='name' />
                    </p>
                    <p className='text-capitalize'>
                        {props.values.name ? (
                            props.values.name
                        ) : (
                            <span className='text-lowercase'>
                                (Chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='12' lg='6'>
                    <p className='text-muted text-small mb-2'>Quyền</p>
                    <p>{props.values.user_role_id?.label}</p>
                </Colxx>
                <Colxx xxs='12' lg='6'>
                    <p className='text-muted text-small mb-2'>Tên đăng nhập</p>
                    <p className='text-lowercase'>
                        {props.values.username ? (
                            props.values.username
                        ) : (
                            <span className='text-lowercase'>
                                (Chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx>
                <Colxx xxs='12' lg='6'>
                    <p className='text-muted text-small mb-2'>Email</p>
                    <p className='text-lowercase'>{props.values.email}</p>
                </Colxx>
            </Row>
        </>
    );
};

export default ContentDisplayMobile;
