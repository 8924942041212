import React, { useState } from 'react';
import {
    ButtonDropdown,
    Row,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Breadcrumb,
    Collapse,
    UncontrolledDropdown,
} from 'reactstrap';
import classnames from 'classnames';
import {
    Colxx,
    Separator,
} from '../../../../components/common/CustomBootstrap';
import IntlMessages from '../../../../helpers/IntlMessages';

const PageHeading = ({
    heading,
    isDisabledAdd,
    toggleFunc,
    onSearchKeyFunc,
    setting,
    totalItem,
    changePageSizeFunc,
    displayMode,
    changeDisplayMode,
    match,
    intl,
}) => {
    // const { messages } = intl;
    const [dropdownSplitOpen, setDropdownSplitOpen] = useState(false);
    const [displayOptionsIsOpen, setDisplayOptionsIsOpen] = useState(false);

    const toggleSplit = () => {
        setDropdownSplitOpen({
            dropdownSplitOpen: !dropdownSplitOpen,
        });
    };

    const toggleDisplayOptions = () => {
        setDisplayOptionsIsOpen({
            displayOptionsIsOpen: !displayOptionsIsOpen,
        });
    };

    const startIndex = (setting.currentPage - 1) * setting.selectedPageSize;
    const endIndex = setting.currentPage * setting.selectedPageSize;

    return (
        <Row className='list-page-heading__row'>
            <Colxx xxs='12'>
                <div className='d-fex justify-content-between align-items-center mb-2'>
                    <h1>
                        {/* Chiết khấu */}
                        <IntlMessages id={heading} />
                    </h1>
                    <div className='text-zero top-right-button-container'>
                        <ButtonDropdown
                            isOpen={dropdownSplitOpen}
                            toggle={toggleSplit}
                        >
                            <Button
                                color='primary'
                                size='md'
                                className={classnames('top-right-button', {
                                    disable: isDisabledAdd,
                                })}
                                onClick={() => toggleFunc()}
                            >
                                <IntlMessages id='pages.add-new' />
                            </Button>
                            <DropdownToggle caret color='primary' />
                            <DropdownMenu>
                                <DropdownItem
                                // onClick={this.handleDownloadData}
                                >
                                    Tải về file excel
                                </DropdownItem>
                            </DropdownMenu>
                        </ButtonDropdown>
                    </div>
                    {/* <Breadcrumb match={match} /> */}
                </div>

                <div className='mb-2'>
                    <Button
                        color='empty'
                        className='pt-0 pl-0 d-inline-block d-md-none'
                        onClick={toggleDisplayOptions}
                    >
                        <IntlMessages id='pages.display-options' />{' '}
                        <i className='simple-icon-arrow-down align-middle' />
                    </Button>
                    <Collapse
                        isOpen={displayOptionsIsOpen}
                        className='d-md-block'
                        id='displayOptions'
                    >
                        <div className='d-block d-md-inline-block'>
                            <div className='search-sm d-inline-block float-md-left mr-1 mb-1 align-top'>
                                <input
                                    type='text'
                                    name='keyword'
                                    id='search'
                                    placeholder='Tìm kiếm'
                                    onKeyPress={(e) => onSearchKeyFunc(e)}
                                    onChange={(e) => onSearchKeyFunc(e)}
                                />
                            </div>
                        </div>
                        <div className='float-md-right'>
                            <span className='text-muted text-small mr-1'>{`${startIndex}-${endIndex} of ${totalItem} `}</span>
                            <UncontrolledDropdown className='d-inline-block'>
                                <DropdownToggle
                                    caret
                                    color='outline-dark'
                                    size='xs'
                                >
                                    {setting.selectedPageSize}
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {setting.pageSizes.map((size, index) => {
                                        return (
                                            <DropdownItem
                                                key={index}
                                                onClick={() =>
                                                    changePageSizeFunc(size)
                                                }
                                            >
                                                {size}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </Collapse>
                </div>
                <Separator />
            </Colxx>
        </Row>
    );
};

export default PageHeading;
