import React, { Component, Fragment, useRef } from 'react';
import ReactDOM from 'react-dom';
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Badge,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { Colxx } from '../../../components/common/CustomBootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import OrderListView from './OrderListView';
import TableChairIcon from './TableChairIcon';
import STATUS from '../../../constants/status';
import { TableChairGenerateMap } from '../../../components/tablechairsTransfer';
import {
    filterOrdersById,
    filterOrdersByBillAndTableId,
    filterOrdersByTableId,
    isAllOrderCompleted,
    isIncludeOrderCompleted,
    isAllBillDataCompleted,
    isIncludeOrderCreated,
    isIncludeOrderPending,
} from '../helpers/utils';
import InvoicePages from '../../../views/app/pages/invoice';
import classnames from 'classnames';
import { NotificatioSucController } from '../../../helpers/Utils';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import MenuGuest from '../../../views/app/pages/menu';
import CustomModalContainer from '../../../views/app/ui/modal';
import { isRorF, isR, isF } from '../../../helpers/Roles';
import ComponentToPrint from '../../pages/ComponentToPrint';
import ReactToPrint from 'react-to-print';
import Axios from 'axios';
import { END_POINT, END_POINT_SOCKET } from '../../../constants/defaultValues';
import LogsHelper from '../../../helpers/Logs';
import io from 'socket.io-client';

export default class ItemSetting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checkboxChecked: isInvisible(props.position, props.x, props.y),
            item: getItemFromPosition(props.position, props.x, props.y) || {
                x: props.x,
                y: props.y,
                identify: undefined,
            },
            elModalContainer: null,
            bills: [],
            isOpenModal: false,
            isOpenModalMenu: false,
            isOpenModalNested: false,
            isOpenModalInvoice: false,
            orders: [],
            selectedItems: [],
            aboutPropsInvoice: null,
            menuHashId: '',

            screenWidth: window.innerWidth,
        };

        this.socket = io(END_POINT_SOCKET);

        this.onActions = this.onActions.bind(this);
        this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);

        document.body.classList.remove('noscroll');
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        window.addEventListener('resize', this.handleWindowSizeChange);

        this.setState({
            orders: getOrders(this.state.item, this.props.orders),
            elModalContainer: document.getElementById('table-chairs'),
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }
    handleWindowSizeChange() {
        this.setState({ screenWidth: window.innerWidth });
    }

    onCheckItem = (e, id) => {
        let selectedItems = this.state.selectedItems;
        if (selectedItems.includes(id)) {
            selectedItems = selectedItems.filter((x) => x !== id);
        } else {
            selectedItems.push(id);
        }

        // toggle show preview.
        const find_order = this.state.orders.find((_order) => _order.id === id);
        if (find_order) {
            // Chưa có, hoặc chưa setting
            if (!find_order.previewDisplay) {
                find_order.previewDisplay = true;
            } else {
                find_order.previewDisplay = !find_order.previewDisplay;
            }
        }

        this.setState({
            selectedItems: selectedItems,
            orders: this.state.orders,
        });
        document.activeElement.blur();
    };

    toggleModal = (e) => {
        if (!isRorF('orders_table')) return false;

        let menu = [];
        if (!this.state.isOpenModal) {
            let bill_id = [];
            this.state.orders.forEach((p) => {
                // paymented || destroy || closed
                if (
                    p.status_id === STATUS.PAYMENTED || // đã thanh toán
                    p.status_id === STATUS.DESTROY || // khách hủy sản phẩm
                    p.status_id === STATUS.CLOSED // hết hàng
                ) {
                    return false;
                }

                if (!bill_id.includes(p.bill.id)) {
                    bill_id.push(p.bill.id);

                    let template = {
                        id: p.bill.id,
                        name: p.bill.id.split('-')[0],
                        user_identify: p.user_identify,
                        data: [],
                    };
                    template.data.push(p);
                    menu.push(template);
                } else {
                    menu.forEach((m) => {
                        if (p.bill.id === m.id) {
                            m.data.push(p);
                        }
                    });
                }
            });
        }

        this.setState({
            isOpenModal: !this.state.isOpenModal,
            bills: menu,
        });
    };

    toggleModalNested = () => {
        if (!isF('orders_table')) return false;

        this.setState({
            isOpenModalNested: !this.state.isOpenModalNested,
        });
    };

    toggleModalMenu = () => {
        if (!isF('orders_table')) return false;

        if (this.state.isOpenModal) {
            this.props.infoTable({ item: this.state.item });

            this.setState({
                // isOpenModalMenu: !this.state.isOpenModalMenu,
                isOpenModal: false,
            });
        } else {
            this.props.infoTable({ item: this.state.item });

            // this.setState({
            //     isOpenModalMenu: !this.state.isOpenModalMenu,
            // });
        }
    };

    toggleModalInvoice = (data = null, discount) => {
        // console.log('data: ', data);
        if (!isF('orders_table')) return false;

        if (data) {
            data.discount = discount ? discount.discount : false;

            if (data.bill) {
                // Nhiều hóa đơn (thanh toán tất cả)
                if (typeof data.bill === 'object') {
                    const first = data.bill[0];
                    const user_identify = first.user_identify;

                    data.orders = filterOrdersByTableId({
                        orders: this.state.orders,
                        user_identify,
                    });
                }

                // 1 hóa đơn (thanh toán ngay)
                else {
                    data.orders = filterOrdersByBillAndTableId({
                        orders: this.state.orders,
                        bill_id: data.bill,
                    });
                }
            }
        }

        this.setState({
            isOpenModalInvoice: !this.state.isOpenModalInvoice,
            aboutPropsInvoice: data,
        });
    };

    selectTableTransfer = (item) => {
        if (this.state.selectedItems.length < 1) return false;

        this.props.onActions(
            this.state.selectedItems,
            'transfer',
            item,
            'table'
        );
    };

    onActions(selectedItems, action) {
        if (!isF('orders_table')) return false;

        if (selectedItems.length < 1) return false;
        this.props.onActions(selectedItems, action, null, 'table');
    }

    onContextMenuClick = (e, data, target) => {
        const { action } = data;

        switch (action) {
            case 'menu':
                copyToClipboard(this.state.menuHashId);
                break;
            default:
                break;
        }
        return true;
    };
    onContextMenu = (e, data) => {
        // console.log('e: ', e.detail.data);
        const {
            id,
            identify,
            user: { companyMenuHash },
        } = data.data;

        let menu = `${companyMenuHash}-${id}`;
        menu = `${window.location.host}/menu/${menu}`;

        this.setState({
            menuHashId: menu,
        });
        return true;
    };

    render() {
        let {
            elModalContainer,
            checkboxChecked,
            item,
            isOpenModal,
            isOpenModalMenu,
            isOpenModalNested,
            isOpenModalInvoice,
            aboutPropsInvoice,
            bills,
            selectedItems,
            orders,
            screenWidth,
        } = this.state;
        // console.log('maxFloor: ', maxFloor);
        // console.log('selectedFloorTable: ', selectedFloorTable);
        // console.log('onUpdateSelectedFloorTable: ', onUpdateSelectedFloorTable);
        // console.log('floorsActiveId: ', floorsActiveId);

        const {
            x,
            y,
            isLoading,
            billDate,
            maxFloor,
            selectedFloorTable,
            onUpdateSelectedFloorTable,
            floorsActiveId,
        } = this.props;

        const isMobile = screenWidth <= 980;

        return (
            elModalContainer && (
                <span className='table-item-setting'>
                    <div id='table-chairs-orders' />
                    <ContextMenuTrigger
                        id={`table_ctx_${x}_${y}`}
                        data={item}
                        collect={collect}
                    >
                        <RenderTableColor
                            id_table={item.identify}
                            isLoading={false}
                            toggleModal={this.toggleModal}
                            toggleModalMenu={this.toggleModalMenu}
                            checkboxChecked={checkboxChecked}
                            status={item.status ? item.status.uuid : 0}
                        />
                    </ContextMenuTrigger>
                    <ContextMenu
                        id={`table_ctx_${x}_${y}`}
                        onShow={(e) => this.onContextMenu(e, e.detail.data)}
                    >
                        <MenuItem
                            onClick={this.onContextMenuClick}
                            data={{ action: 'menu' }}
                        >
                            <i className='simple-icon-docs' />{' '}
                            <span>Copy Link Menu</span>
                        </MenuItem>
                    </ContextMenu>

                    {/* ORDERS */}
                    <ModalContainer elModalContainer={elModalContainer}>
                        <ModalChild
                            isOpenModal={isOpenModal}
                            toggleModal={this.toggleModal}
                            isOpenModalNested={isOpenModalNested}
                            toggleModalNested={this.toggleModalNested}
                            isOpenModalInvoice={isOpenModalInvoice}
                            toggleModalInvoice={this.toggleModalInvoice}
                            aboutPropsInvoice={aboutPropsInvoice}
                            toggleModalMenu={this.toggleModalMenu}
                            bills={bills}
                            orders={orders}
                            itemsTable={this.props.data}
                            selectTableTransfer={this.selectTableTransfer}
                            selectedItems={selectedItems}
                            onActions={this.onActions}
                            onCheckItem={this.onCheckItem}
                            isMobile={isMobile}
                            billDate={billDate}
                            maxFloor={maxFloor}
                            selectedFloorTable={selectedFloorTable}
                            onUpdateSelectedFloorTable={
                                onUpdateSelectedFloorTable
                            }
                            floorsActiveId={floorsActiveId}
                        />
                    </ModalContainer>

                    {/* MENU  */}
                    <ModalContainer elModalContainer={elModalContainer}>
                        <CustomModalContainer
                            isOpenModal={isOpenModalMenu}
                            toggleModal={this.toggleModalMenu}
                            disabledScroll={isOpenModalMenu || isOpenModal}
                            title={'Danh sách sản phẩm'}
                            className='table-modal'
                        >
                            <ModalMenu item={item} />
                        </CustomModalContainer>
                    </ModalContainer>
                </span>
            )
        );
    }
}

const ModalChild = ({
    isOpenModal,
    toggleModal,
    isOpenModalNested,
    toggleModalNested,
    itemsTable,
    selectTableTransfer,
    isOpenModalInvoice,
    toggleModalInvoice,
    aboutPropsInvoice,
    toggleModalMenu,
    bills,
    orders,
    onActions,
    selectedItems,
    onCheckItem,
    isMobile,
    billDate,
    maxFloor,
    selectedFloorTable,
    onUpdateSelectedFloorTable,
    floorsActiveId,
}) => {
    // Đây là function để gom các sản phẩm cùng id và cùng status_id
    function groupProductsByIdAndStatusId(data) {
        const groupedProducts = {};

        data.forEach((item) => {
            const productId = item.product.id;
            const statusId = item.status_id;
            const key = `${productId}_${statusId}`;

            if (groupedProducts[key]) {
                groupedProducts[key].count += item.count;
            } else {
                groupedProducts[key] = {
                    ...item,
                    count: item.count,
                };
            }
        });

        return Object.values(groupedProducts);
    }

    // Lặp qua mảng bills và gom nhóm 'product' trong mỗi 'data' của từng 'bill' dựa trên 'id' và 'status_id'
    const result = bills.map((bill) => {
        return {
            ...bill,
            data: groupProductsByIdAndStatusId(bill.data),
        };
    });

    // console.log('result: ', result);

    return isOpenModal ? (
        <Fragment>
            <div className='table-modal-overlay' />
            <div
                className='table-modal-wrapper'
                onClick={(e) => {
                    document.body.classList.remove('noscroll');
                    toggleModal();
                }}
                aria-modal
                aria-hidden
                tabIndex={-1}
                role='dialog'
                data-keyboard='false'
                data-backdrop='static'
            >
                <div
                    className={classnames('table-modal', {
                        'table-bill-desktop': !isMobile,
                    })}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <div className='table-modal-header center'>
                        <h3 className='m-0-auto pad-l-25px pad-10px'>
                            Danh sách các sản phẩm trong bàn
                        </h3>
                        <button
                            type='button'
                            className='table-modal-close-button'
                            data-dismiss='modal'
                            aria-label='Close'
                            onClick={() => {
                                document.body.classList.remove('noscroll');
                                toggleModal();
                            }}
                        >
                            <span aria-hidden='true'>&times;</span>
                        </button>
                    </div>
                    {/* TRANSFER TABLE */}
                    <CustomModalContainer
                        isOpenModal={isOpenModalNested}
                        toggleModal={toggleModalNested}
                        disabledScroll={isOpenModalNested || isOpenModal}
                        title={'Chuyển bàn'}
                    >
                        <RenderFloorTable
                            maxFloor={maxFloor}
                            selectedFloorTable={selectedFloorTable}
                            onUpdateSelectedFloorTable={
                                onUpdateSelectedFloorTable
                            }
                            floorsActiveId={floorsActiveId}
                        />
                        <TableChairGenerateMap
                            data={itemsTable}
                            selectTableTransfer={selectTableTransfer}
                        />
                    </CustomModalContainer>

                    {/* INVOICE */}
                    <CustomModalContainer
                        isOpenModal={isOpenModalInvoice}
                        toggleModal={toggleModalInvoice}
                        disabledScroll={isOpenModalInvoice || isOpenModal}
                        title={''}
                    >
                        <InvoicePages
                            location={{
                                aboutProps: aboutPropsInvoice,
                            }}
                        />
                    </CustomModalContainer>

                    <HandlerTableBill
                        onCheckItem={onCheckItem}
                        isOpenModal={isOpenModal}
                        toggleModal={toggleModal}
                        toggleModalNested={toggleModalNested}
                        toggleModalInvoice={toggleModalInvoice}
                        toggleModalMenu={toggleModalMenu}
                        bills={bills}
                        orders={orders}
                        selectedItems={selectedItems}
                        onActions={onActions}
                        isMobile={isMobile}
                        billDate={billDate}
                    />
                </div>
            </div>
        </Fragment>
    ) : null;
};

const RenderFloorTable = ({
    onUpdateSelectedFloorTable,
    selectedFloorTable,
    maxFloor,
    floorsActiveId,
}) => {
    // console.log('maxFloor: ', maxFloor);
    return (
        <div className='w-100 floor'>
            <Nav tabs className='w-100'>
                {maxFloor >= 1 ? (
                    <div className='d-flex justify-content-between w-100'>
                        <div className='d-flex'>
                            {[...new Array(maxFloor).keys()].map((floor) => (
                                <NavItem
                                    key={floor}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onUpdateSelectedFloorTable(floor + 1);
                                    }}
                                >
                                    <NavLink
                                        href='#'
                                        className={classnames('', {
                                            active:
                                                selectedFloorTable ===
                                                floor + 1,
                                            'floor-has-order':
                                                floorsActiveId.includes(
                                                    floor + 1
                                                ),
                                        })}
                                    >{`Khu ${floor + 1}`}</NavLink>
                                </NavItem>
                            ))}
                        </div>
                    </div>
                ) : null}
            </Nav>
        </div>
    );
};

const ModalMenu = ({ item }) => (
    <div
        className='scroll smooth-scroll-container-scroll'
        id='table-modal-scroll'
    >
        <MenuGuest
            hash={item.user.companyMenuHash}
            user_identify={item.identify}
            table_id={item.id}
        />
    </div>
);

const HandlerTableBill = (props) => {
    const items = props.selectedItems.map((itemId) => {
        const _order = props.orders.find((o) => o.id === itemId);
        return _order ? _order : null;
    });

    let componentRef = useRef();
    return (
        <div className='table-bill__container cursor-pointer'>
            <div
                className={classnames(
                    'scroll table-modal-scroll disable-text-selection',
                    {
                        'inline-flex': !props.isMobile,
                    }
                )}
            >
                <PerfectScrollbar
                    options={{ suppressScrollX: true, wheelPropagation: false }}
                    className={'no-padding custom-row-no-margin'}
                >
                    <Colxx xxs='12' md='12' xl='12' className='col-left'>
                        {props.bills.length &&
                            props.bills.map((bill) => {
                                const ordersId = bill?.data.map((o) => o.id);
                                // console.log('ordersId: ', ordersId);
                                return (
                                    bill.data.length && (
                                        <Card
                                            className={classnames(
                                                'mb-3 custom-shadow mt-5 card-bill-items',
                                                {
                                                    'card-bill-items-desktop':
                                                        !props.isMobile,
                                                }
                                            )}
                                            key={bill.id}
                                        >
                                            <div className='position-absolute card-top-buttons'>
                                                {props.isMobile ? (
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle
                                                            color=''
                                                            className='btn btn-header-light icon-button'
                                                        >
                                                            <span className='simple-icon-options' />
                                                        </DropdownToggle>
                                                        <DropdownMenu right>
                                                            {props.selectedItems
                                                                .length ? (
                                                                <>
                                                                    {!isIncludeOrderCompleted(
                                                                        filterOrdersById(
                                                                            props.orders,
                                                                            props.selectedItems
                                                                        )
                                                                    ) ? (
                                                                        <>
                                                                            {!isIncludeOrderPending(
                                                                                filterOrdersById(
                                                                                    props.orders,
                                                                                    props.selectedItems
                                                                                )
                                                                            ) ? (
                                                                                <DropdownItem
                                                                                    onClick={() => {
                                                                                        props.onActions(
                                                                                            props.selectedItems,
                                                                                            'pending'
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <IntlMessages id='order.to-pending' />
                                                                                </DropdownItem>
                                                                            ) : null}
                                                                            {!isIncludeOrderCreated(
                                                                                filterOrdersById(
                                                                                    props.orders,
                                                                                    props.selectedItems
                                                                                )
                                                                            ) ? (
                                                                                <DropdownItem
                                                                                    onClick={() => {
                                                                                        props.onActions(
                                                                                            props.selectedItems,
                                                                                            'done'
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <IntlMessages id='order.to-done' />
                                                                                </DropdownItem>
                                                                            ) : null}
                                                                            <DropdownItem
                                                                                onClick={() => {
                                                                                    props.onActions(
                                                                                        props.selectedItems,
                                                                                        'refund'
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <IntlMessages id='order.to-refund' />
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                onClick={() => {
                                                                                    props.onActions(
                                                                                        props.selectedItems,
                                                                                        'destroy'
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <IntlMessages id='order.to-destroy' />
                                                                            </DropdownItem>
                                                                        </>
                                                                    ) : null}
                                                                    <DropdownItem
                                                                        onClick={() => {
                                                                            props.toggleModalNested();
                                                                        }}
                                                                    >
                                                                        <IntlMessages id='order.transfer-table' />
                                                                    </DropdownItem>
                                                                    {isAllOrderCompleted(
                                                                        filterOrdersById(
                                                                            props.orders,
                                                                            props.selectedItems
                                                                        )
                                                                    ) ? (
                                                                        <DropdownItem
                                                                            // onClick={() => {
                                                                            //     props.toggleModalInvoice(
                                                                            //         {
                                                                            //             orders: props.selectedItems,
                                                                            //         },
                                                                            //         {
                                                                            //             discount: false,
                                                                            //         }
                                                                            //     );
                                                                            // }}
                                                                            onClick={() => {
                                                                                props.onActions(
                                                                                    props.selectedItems,
                                                                                    'end'
                                                                                );
                                                                            }}
                                                                        >
                                                                            <IntlMessages id='order.to-bill' />
                                                                        </DropdownItem>
                                                                    ) : null}
                                                                </>
                                                            ) : null}
                                                            <DropdownItem
                                                                onClick={() => {
                                                                    props.toggleModalMenu();
                                                                }}
                                                            >
                                                                <IntlMessages id='order.open-menu' />
                                                            </DropdownItem>
                                                            {props.selectedItems
                                                                .length &&
                                                            isAllOrderCompleted(
                                                                filterOrdersById(
                                                                    props.orders,
                                                                    props.selectedItems
                                                                )
                                                            ) ? (
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        props.toggleModalInvoice(
                                                                            {
                                                                                orders: props.selectedItems,
                                                                            },
                                                                            {
                                                                                discount: true,
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    <IntlMessages id='discount.to-bill' />
                                                                </DropdownItem>
                                                            ) : null}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                ) : null}
                                            </div>
                                            <CardBody className='p-125-pb-5px'>
                                                <CardTitle className='d-flex justify-content-between align-items-center'>
                                                    <Badge
                                                        color='primary'
                                                        pill
                                                        className='h-100'
                                                    >
                                                        {'#' + bill.name}
                                                    </Badge>
                                                    {isAllBillDataCompleted(
                                                        bill.data
                                                    )
                                                        ? // (
                                                          //     <div
                                                          //         // onClick={() => {
                                                          //         //     props.toggleModalInvoice(
                                                          //         //         {
                                                          //         //             bill: bill.id,
                                                          //         //         },
                                                          //         //         {
                                                          //         //             discount: false,
                                                          //         //         }
                                                          //         //     );
                                                          //         // }}
                                                          //         onClick={() => {
                                                          //             props.onActions(
                                                          //                 ordersId,
                                                          //                 'end'
                                                          //             );
                                                          //         }}
                                                          //     >
                                                          //         <Button
                                                          //             size='sm'
                                                          //             className='btn m-0-auto'
                                                          //             color='primary'
                                                          //             outline
                                                          //         >
                                                          //             <IntlMessages id='order.to-bill-now' />
                                                          //         </Button>
                                                          //     </div>
                                                          // )
                                                          items.length ? (componentRef && (
                                                              <ReactToPrint
                                                                //   onClick={() => {
                                                                //       props.onActions(
                                                                //           ordersId,
                                                                //           'end'
                                                                //       );
                                                                //   }}
                                                                  trigger={() => (
                                                                      <div>
                                                                          <Button
                                                                              size='sm'
                                                                              className='btn m-0-auto'
                                                                              color='primary'
                                                                              outline
                                                                            //   onClick={() => {
                                                                            //       props.onActions(
                                                                            //           ordersId,
                                                                            //           'end'
                                                                            //       );
                                                                            //   }}
                                                                          >
                                                                              <IntlMessages id='order.to-bill-now' />
                                                                          </Button>
                                                                      </div>
                                                                  )}
                                                                  content={() =>
                                                                      componentRef
                                                                  }
                                                              />
                                                          )
                                                          ) : (
                                                            <div>
                                                                <Button
                                                                    disabled
                                                                    size='sm'
                                                                    // color='primary'
                                                                    className='btn m-0-auto'
                                                                    outline
                                                                >
                                                                    <IntlMessages id='order.to-bill-now' />
                                                                </Button>
                                                            </div>
                                                        )
                                                        : null}
                                                </CardTitle>
                                                {bill.data &&
                                                    bill.data.map(
                                                        (order, idx) => {
                                                            // console.log(
                                                            //     'order: ',
                                                            //     order
                                                            // );
                                                            return (
                                                                <OrderListView
                                                                    id={`table_order_id_${order.id}`}
                                                                    key={`table_order_${order.id}`}
                                                                    order={
                                                                        order
                                                                    }
                                                                    underLine={
                                                                        idx <
                                                                        bill
                                                                            .data
                                                                            .length -
                                                                            1
                                                                    }
                                                                    selectedItems={
                                                                        props.selectedItems
                                                                    }
                                                                    onCheckItem={
                                                                        props.onCheckItem
                                                                    }
                                                                    isSelect={props.selectedItems.includes(
                                                                        order.id
                                                                    )}
                                                                    collect={
                                                                        collect
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )}
                                            </CardBody>
                                        </Card>
                                    )
                                );
                            })}
                    </Colxx>
                </PerfectScrollbar>
                {!props.isMobile ? (
                    <div>
                        <section className='mb-2'>
                            {props.selectedItems.length &&
                            !isIncludeOrderPending(
                                filterOrdersById(
                                    props.orders,
                                    props.selectedItems
                                )
                            ) &&
                            !isIncludeOrderCompleted(
                                filterOrdersById(
                                    props.orders,
                                    props.selectedItems
                                )
                            ) ? (
                                <div className='card-bill-actions card-bill-actions-mini'>
                                    <div
                                        className='card-bill-actions-active'
                                        onClick={() => {
                                            props.onActions(
                                                props.selectedItems,
                                                'pending'
                                            );
                                        }}
                                    >
                                        <IntlMessages id='order.to-pending' />
                                    </div>
                                </div>
                            ) : (
                                <div className='card-bill-actions card-bill-actions-mini'>
                                    <IntlMessages id='order.to-pending' />
                                </div>
                            )}
                            {props.selectedItems.length &&
                            !isIncludeOrderCreated(
                                filterOrdersById(
                                    props.orders,
                                    props.selectedItems
                                )
                            ) &&
                            !isIncludeOrderCompleted(
                                filterOrdersById(
                                    props.orders,
                                    props.selectedItems
                                )
                            ) ? (
                                <div className='card-bill-actions card-bill-actions-mini'>
                                    <div
                                        className='card-bill-actions-active'
                                        onClick={() => {
                                            props.onActions(
                                                props.selectedItems,
                                                'done'
                                            );
                                        }}
                                    >
                                        <IntlMessages id='order.to-done' />
                                    </div>
                                </div>
                            ) : (
                                <div className='card-bill-actions card-bill-actions-mini'>
                                    <IntlMessages id='order.to-done' />
                                </div>
                            )}
                        </section>

                        <div className='custom-line mb-3 mt-3 w-80 m-0-auto' />

                        <section className='mb-2'>
                            {props.selectedItems.length &&
                            !isIncludeOrderCompleted(
                                filterOrdersById(
                                    props.orders,
                                    props.selectedItems
                                )
                            ) ? (
                                <div className='card-bill-actions card-bill-actions-mini'>
                                    <div
                                        className='card-bill-actions-active'
                                        onClick={() => {
                                            props.onActions(
                                                props.selectedItems,
                                                'refund'
                                            );
                                        }}
                                    >
                                        <IntlMessages id='order.to-refund' />
                                    </div>
                                </div>
                            ) : (
                                <div className='card-bill-actions card-bill-actions-mini'>
                                    <IntlMessages id='order.to-refund' />
                                </div>
                            )}
                            {props.selectedItems.length &&
                            !isIncludeOrderCompleted(
                                filterOrdersById(
                                    props.orders,
                                    props.selectedItems
                                )
                            ) ? (
                                <div className='card-bill-actions card-bill-actions-mini'>
                                    <div
                                        className='card-bill-actions-active'
                                        onClick={() => {
                                            props.onActions(
                                                props.selectedItems,
                                                'destroy'
                                            );
                                        }}
                                    >
                                        <IntlMessages id='order.to-destroy' />
                                    </div>
                                </div>
                            ) : (
                                <div className='card-bill-actions card-bill-actions-mini'>
                                    <IntlMessages
                                        className='mb-1'
                                        id='order.to-destroy'
                                    />
                                </div>
                            )}
                        </section>

                        <div className='custom-line mb-3 mt-3 w-80 m-0-auto' />

                        <section className='mb-2'>
                            {props.selectedItems.length ? (
                                <div className='card-bill-actions card-bill-actions-mini'>
                                    <div
                                        className='card-bill-actions-active'
                                        onClick={() => {
                                            props.toggleModalNested();
                                        }}
                                    >
                                        <IntlMessages id='order.transfer-table' />
                                    </div>
                                </div>
                            ) : (
                                <div className='card-bill-actions card-bill-actions-mini'>
                                    <IntlMessages id='order.transfer-table' />
                                </div>
                            )}
                            {props.selectedItems.length &&
                            isAllOrderCompleted(
                                filterOrdersById(
                                    props.orders,
                                    props.selectedItems
                                )
                            ) ? (
                                componentRef && (
                                    <ReactToPrint
                                        onClick={() => {
                                            props.onActions(
                                                props.selectedItems,
                                                'end'
                                            );
                                        }}
                                        trigger={() => (
                                            <div className='card-bill-actions card-bill-actions-mini'>
                                                <div
                                                    className='card-bill-actions-active'
                                                    onClick={() => {
                                                        props.onActions(
                                                            props.selectedItems,
                                                            'end'
                                                        );
                                                    }}
                                                >
                                                    <IntlMessages id='order.to-bill' />
                                                </div>
                                            </div>
                                        )}
                                        content={() => componentRef}
                                    />
                                )
                            ) : (
                                <div className='card-bill-actions card-bill-actions-mini'>
                                    <IntlMessages id='order.to-bill' />
                                </div>
                            )}
                        </section>

                        <div className='custom-line mb-3 mt-3 w-80 m-0-auto' />

                        <section className='mb-2'>
                            <div className='card-bill-actions card-bill-actions-mini'>
                                <div
                                    className='card-bill-actions-active'
                                    onClick={() => {
                                        props.toggleModalMenu();
                                    }}
                                >
                                    <IntlMessages id='order.open-menu' />
                                </div>
                            </div>
                            {props.selectedItems.length &&
                            isAllOrderCompleted(
                                filterOrdersById(
                                    props.orders,
                                    props.selectedItems
                                )
                            ) ? (
                                <div className='card-bill-actions card-bill-actions-mini'>
                                    <div
                                        className='card-bill-actions-active'
                                        onClick={() => {
                                            props.toggleModalInvoice(
                                                { orders: props.selectedItems },
                                                { discount: true }
                                            );
                                        }}
                                    >
                                        <IntlMessages id='discount.to-bill' />
                                    </div>
                                </div>
                            ) : (
                                <div className='card-bill-actions card-bill-actions-mini'>
                                    <IntlMessages id='discount.to-bill' />
                                </div>
                            )}
                        </section>
                    </div>
                ) : null}
            </div>
            <div style={{ display: 'none' }}>
                <ComponentToPrint
                    items={items}
                    companyName={localStorage.getItem('companyName')}
                    companyAddress={localStorage.getItem('companyAddress')}
                    companyLogo={localStorage.getItem('companyLogo')}
                    companyWebsite={localStorage.getItem('companyWebsite')}
                    billDate={props.billDate}
                    // timeStart={timeStart}
                    // pin_print={pin_print}
                    ref={(el) => (componentRef = el)}
                />
            </div>
            {isAllOrderCompleted(props.orders) && props.bills.length > 1 ? (
                <div
                    className={classnames('table-modal-footer center', {
                        'max-width-520px': !props.isMobile,
                        'max-width-450px': props.isMobile,
                    })}
                    onClick={() => {
                        props.toggleModalInvoice(
                            { bill: props.bills },
                            { discount: false }
                        );
                    }}
                >
                    <Button
                        size='sm'
                        className='btn m-0-auto mt-2'
                        color='primary'
                        block
                    >
                        <IntlMessages id='order.to-bill-all' />
                    </Button>
                </div>
            ) : null}
        </div>
    );
};

const copyToClipboard = (value) => {
    let input = document.createElement('input');
    input.setAttribute('value', value);
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand('copy');
    document.body.removeChild(input);

    NotificatioSucController('Copy mã menu thành công');
};

const RenderTableColor = (props) => {
    if (!props.checkboxChecked) {
        return <TableChairIcon id_table={props.id_table} theme={'hide'} />;
    }

    if (props.isLoading) {
        return <TableChairIcon id_table={props.id_table} theme={'disabled'} />;
    }

    if (props.status === STATUS.TABLE_HAS_GUEST) {
        return (
            <span onClick={props.toggleModal}>
                <TableChairIcon id_table={props.id_table} theme={'busy'} />
            </span>
        );
    } else if (props.status === STATUS.TABLE_ORDERED) {
        return (
            <span onClick={props.toggleModal}>
                <TableChairIcon id_table={props.id_table} theme={'order'} />
            </span>
        );
    } else if (props.status === STATUS.TABLE_MORE_ORDERED) {
        return (
            <span onClick={props.toggleModal}>
                <TableChairIcon id_table={props.id_table} theme={'orders'} />
            </span>
        );
    }

    return (
        <span onClick={props.toggleModalMenu}>
            <TableChairIcon id_table={props.id_table} theme={'default'} />
        </span>
    );
};

class ModalContainer extends React.Component {
    constructor(props) {
        super(props);
        this.el = null;
    }
    componentDidMount() {
        if (this.props.elModalContainer) {
            this.el = document.createElement('div');
            this.props.elModalContainer.appendChild(this.el);
        }
    }
    componentWillUnmount() {
        if (this.el) this.props.elModalContainer.removeChild(this.el);
    }
    render() {
        return this.el && ReactDOM.createPortal(this.props.children, this.el);
    }
}

const isInvisible = (position, x, y) => {
    return position.some((p) => p.x === x && p.y === y);
};

const getItemFromPosition = (position, x, y) => {
    return position.find((p) => p.x === x && p.y === y);
};

const getOrders = (item, orders) => {
    return orders.filter(
        (order) =>
            order.user_identify == item.identify && order.table_id == item.id
    );
};

const collect = (props) => {
    return { data: props.data };
};
