import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class Main extends Component {
    render() {
        const store = window.location.host.split('.')[0];
        if (store === 'faq') {
            return <Redirect to='/root/faq/dashboards' />;
        }

        if (store === 'message') {
            return <Redirect to='/root/message/dashboards' />;
        }

        if (store === 'upgrade') {
            return <Redirect to='/root/upgrade/dashboards' />;
        }

        const ROLESETTINGS = localStorage.getItem('roleSettings')
            ? JSON.parse(localStorage.getItem('roleSettings'))
            : null;

        if (ROLESETTINGS && ROLESETTINGS.length) {
            const find_payment = ROLESETTINGS.find(
                (_role) => _role.role_alias === 'table_pending_payment'
            );
            if (typeof find_payment !== 'undefined') {
                return <Redirect to='/payment' />;
            }
        }

        if (ROLESETTINGS && ROLESETTINGS.length) {
            const find_kitchen = ROLESETTINGS.find(
                (_role) => _role.role_alias === 'kitchen'
            );
            if (typeof find_kitchen !== 'undefined') {
                return <Redirect to='/kitchen' />;
            }
        }

        if (ROLESETTINGS && ROLESETTINGS.length) {
            const find_cashier = ROLESETTINGS.find(
                (_role) => _role.role_alias === 'cashier'
            );
            if (typeof find_cashier !== 'undefined') {
                return <Redirect to='/cashier' />;
            }
        }
        // if (store === "register") {
        //   return <Redirect to="/user/register" />
        // }

        return <Redirect to='/app' />;
    }
}
export default Main;
