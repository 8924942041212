import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import axios from 'axios';
import { END_POINT, END_POINT_SOCKET } from '../../../constants/defaultValues';
import {
    SetItemsSelected,
    NotificatioErrController,
    NotificatioSucController,
    // CheckConfirmPassword,
} from '../../../helpers/Utils';
import io from 'socket.io-client';
import { ContentForm } from '../../../containers/pages/category';
import {
    Add,
    Update,
    PageHeading,
    Pagination,
} from '../../../containers/pages/general';
import ListItemLeftForm from '../../../containers/pages/ListItemLeftForm';
import LogsHelper from '../../../helpers/Logs';
// import PasswordBox from '../../../components/PasswordBox'
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classnames from 'classnames';
import { isC, isR, isU, isD } from '../../../helpers/Roles';

const DEFAULT_FORM = {
    id: '',
    name: '',
    dynamic_form: [],
};

const ROLE_ALIAS = 'category';

class ContentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: {
                pageSizes: [12, 24],
                selectedPageSize: 12,
                currentPage: 1,
                totalPage: 1,
                totalItem: 0,
                search: '',
            },
            selectedItems: [],
            data: [],
            data_dynamic_form: [],
            is_loading_data: true,
            show: {
                add: false,
                update: false,
                viewonly: true,
            },
            form: DEFAULT_FORM,

            // password
            // password: '',
            // isOpenModalPassword: false,
            // passwordFuncCallback: null,
        };

        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        this.dataListRender();
    }

    /** Axios func helper */
    dataListRender = () => {
        const { selectedPageSize, currentPage, search } = this.state.page;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(
                `${
                    END_POINT + '/category/json'
                }?pageSize=${selectedPageSize}&currentPage=${currentPage}&search=${search}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((data) => {
                // console.log(data);
                this.setState(
                    (prev) => ({
                        ...prev,
                        data: data.data,
                        data_dynamic_form: data.dataDynamicForm,
                        is_loading_data: false,
                        page: {
                            ...prev.page,
                            totalPage: data.totalPage,
                            totalItem: data.totalItem,
                        },
                    }),
                    () => {
                        if (this.state.form.id) {
                            const { id, name, dynamic_form } = this.state.form;
                            this.setState((prev) => ({
                                ...prev,
                                form: {
                                    ...prev.form,
                                    id,
                                    name,
                                    dynamic_form,
                                },
                            }));
                        } else {
                            this.setState((prev) => ({
                                ...prev,
                                selectedItems: [],
                                show: {
                                    ...prev.show,
                                    update: false,
                                },
                                form: DEFAULT_FORM,
                            }));
                        }
                    }
                );
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerDelete = () => {
        if (!isD(ROLE_ALIAS)) return false;

        // let domain = window.location.host.split('.')[0];
        // if (domain !== 'demo') {
        //     // confirm password
        //     if (this.state.password === '') {
        //         this.setState({
        //             passwordFuncCallback: this.onHandlerDelete,
        //             isOpenModalPassword: true,
        //         });
        //         return false;
        //     } else {
        //         this.setState({
        //             password: '',
        //             isOpenModalPassword: false,
        //         });
        //     }
        // }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .delete(`${END_POINT + '/category'}`, {
                data: {
                    arrayId: this.state.selectedItems,
                },
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['category-delete'],
                });

                this.dataListRender();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerUpdate = () => {
        if (!isU(ROLE_ALIAS)) return false;

        // let domain = (window.location.host).split('.')[0];
        // if (domain !== "demo") {
        //   // confirm password
        //   if (this.state.password === "") {
        //     this.setState({
        //       passwordFuncCallback: this.onHandlerUpdate,
        //       isOpenModalPassword: true,
        //     });
        //     return false;
        //   } else {
        //     this.setState({
        //       password: "",
        //       isOpenModalPassword: false,
        //     });
        //   }
        // }

        const { id, name, dynamic_form } = this.state.form;

        if (!name || !id) {
            NotificatioErrController('Dữ liệu không hợp lệ');
            return;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .put(
                `${END_POINT + '/category'}`,
                {
                    id,
                    name,
                    dynamic_form,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['category-update'],
                });

                this.dataListRender();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerAdd = () => {
        if (!isC(ROLE_ALIAS)) return false;

        const { name, value, dynamic_form } = this.state.form;

        if (!name) {
            NotificatioErrController('Tên danh mục cần được thêm vào');
            return;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/category'}`,
                {
                    name,
                    dynamic_form,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['category-create'],
                });

                this.dataListRender();
                // this.toggle('add');
                this.setState((prev) => ({
                    ...prev,
                    form: {
                        ...prev.form,
                        form: DEFAULT_FORM,
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };
    /** Axios func helper */

    /** State func helper */
    toggle = (name) => {
        if (name === 'add' && !isC(ROLE_ALIAS)) return false;
        if (name === 'update' && !isU(ROLE_ALIAS)) return false;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                update: name === 'add' ? false : prev.show.update,
                [name]: !prev.show[name],
            },
            selectedItems: name === 'add' ? [] : prev.selectedItems,
            form: name === 'add' ? DEFAULT_FORM : prev.form,
        }));
    };

    onChangeForm = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [name]: value,
                },
            }));
        }
    };
    onChangeDynamicForm = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    dynamic_form: {
                        ...prev.form.dynamic_form,
                        [name]: value,
                    },
                },
            }));
        } else {
            let value = e.value ? e.value : e;

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    dynamic_form: {
                        ...prev.form.dynamic_form,
                        [_name]: value,
                    },
                },
            }));
        }
    };

    onSubmitForm = (type) => {
        if (type === 'update') {
            this.onHandlerUpdate();
        } else if (type === 'add') {
            this.onHandlerAdd();
        }
    };

    onChangePage = (page) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    currentPage: page,
                },
            }),
            () => this.dataListRender()
        );
    };

    onSearchKey = (e) => {
        if (!e) return false;

        if (e.target) {
            let {
                target: { value },
            } = e;
            let v = value;
            if (value === undefined) {
                v = e.target.firstElementChild.value; // parent to child

            //     value = v.toLowerCase();
            //     this.setState(
            //         (prev) => ({
            //             ...prev,
            //             page: {
            //                 ...prev.page,
            //                 search: value,
            //             },
            //         }),
            //         () => this.dataListRender()
            //     );
            // } else {
            //     value = value.toLowerCase();
            //     this.setState(
            //         (prev) => ({
            //             ...prev,
            //             page: {
            //                 ...prev.page,
            //                 search: value,
            //             },
            //         }),
            //         () => this.dataListRender()
            //     );
            }
        }

        if (e.key === 'Enter') {
            let value = e.target.value.toLowerCase();
            this.setState(
                (prev) => ({
                    ...prev,
                    page: {
                        ...prev.page,
                        search: value,
                    },
                }),
                () => this.dataListRender()
            );
        }
    };

    onChangePageSize = (size) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    selectedPageSize: size,
                    currentPage: 1,
                },
            }),
            () => this.dataListRender()
        );
    };

    onClickedItem = (item, callback, args) => {
        if (this.state.form.id !== item.id) {
            const { id, name, dynamic_form } = item;

            this.setState(
                (prev) => ({
                    ...prev,
                    show: {
                        ...prev.show,
                        update: false,
                        viewonly: false,
                    },
                    form: {
                        ...prev.form,
                        id,
                        name,
                        dynamic_form,
                    },
                    selectedItems: [item.id],
                }),
                () => {
                    callback(args[0], args[1], args[2]);
                }
            );
        }
    };

    onClickedUpdate = (item) => {
        const { id, name, dynamic_form } = item;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                viewonly: false,
                update: true,
            },
            form: {
                ...prev.form,
                id,
                name,
                dynamic_form,
            },
        }));
    };

    onClickedDelete = (item) => {
        let selectedSet = SetItemsSelected(
            item.id,
            this.state.selectedItems,
            'require'
        );
        if (window.confirm('Bạn chắc chắn muốn xoá danh mục này?')) {
            this.setState(
                (prev) => ({
                    ...prev,
                    selectedItems: selectedSet,
                }),
                () => this.onHandlerDelete()
            );
        }
    };

    onClickedCheckbox = (id, callback) => {
        // let selectedSet = SetItemsSelected(id, this.state.selectedItems);

        this.setState((prev) => ({
            ...prev,
            selectedItems: [id],
        }));
    };
    /** State func helper */

    render() {
        const { match } = this.props;
        const {
            page,
            selectedItems,
            data,
            is_loading_data,
            show,
            form,
            data_dynamic_form,
        } = this.state;

        if (!isR(ROLE_ALIAS))
            return (
                <Card className='text-muted mh-40vh h-100'>
                    <CardBody className='d-flex justify-content-center align-items-center'>
                        Bạn không có quyền sử dụng tính năng này!
                    </CardBody>
                </Card>
            );

        return is_loading_data ? (
            <div className='loading' />
        ) : (
            <div className='disable-text-selection'>
                <PageHeading
                    heading='category.thumb-list'
                    setting={page}
                    match={match}
                    totalItem={page.totalItem}
                    isDisabledAdd={!isC(ROLE_ALIAS)}
                    toggleFunc={() => {
                        this.toggle('add');
                    }}
                    changePageSizeFunc={this.onChangePageSize}
                    onSearchKeyFunc={this.onSearchKey}
                />
                <Add
                    title={<IntlMessages id='category.add-new-modal-title' />}
                    isOpen={show.add}
                    toggle={() => {
                        this.toggle('add');
                    }}
                    onSubmit={this.onHandlerAdd}
                >
                    <ContentForm
                        data_dynamic_form={data_dynamic_form}
                        values={form}
                        setting={show}
                        onChange={this.onChangeForm}
                        onChangeDynamicForm={this.onChangeDynamicForm}
                    />
                </Add>
                <Update
                    title={<IntlMessages id='category.thumb-list' />}
                    isOpen={show.update}
                    toggle={() => {
                        this.toggle('update');
                    }}
                    setting={show}
                    onSubmit={this.onHandlerUpdate}
                >
                    <ContentForm
                        data_dynamic_form={data_dynamic_form}
                        values={form}
                        setting={show}
                        onChange={this.onChangeForm}
                        onChangeDynamicForm={this.onChangeDynamicForm}
                    />
                </Update>
                <Row className='content-page__row'>
                    <Col xs='12' sm='12' md='12' lg='12' xl='12'>
                        {data.map((item, idx) => (
                            <ListItemLeftForm
                                onClickedItem={this.onClickedItem}
                                onUpdate={this.onClickedUpdate}
                                onDelete={this.onClickedDelete}
                                onChangeCheckbox={this.onClickedCheckbox}
                                isDisabledUpdate={!isU(ROLE_ALIAS)}
                                isDisabledDelete={!isD(ROLE_ALIAS)}
                                selectedItems={selectedItems}
                                active={2}
                                key={idx}
                                idx={idx}
                                item={item}
                            />
                        ))}
                        <Pagination
                            currentPage={page.currentPage}
                            totalPage={page.totalPage}
                            onChangePage={(i) => this.onChangePage(i)}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ContentComponent;
