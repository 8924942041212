export const SetBank = [
    {
        label: 'Vietcombank - Ngân hàng TMCP Ngoại Thương Việt Nam',
        value: 970436,
    },
    {
        label: 'ViettinBank - Ngân hàng TMCP Công thương Việt Nam',
        value: 970415,
    },
    {
        label: 'BIDV - Ngân hàng TMCP Đầu tư và Phát triển Việt Nam',
        value: 970418,
    },
    {
        label: 'Agribank - Ngân hàng Nông nghiệp và Phát triển Nông thôn Việt Nam',
        value: 970405,
    },
    {
        label: 'MBBank - Ngân hàng TMCP Quân đội',
        value: 970422,
    },
    {
        label: 'Techcombank - Ngân hàng TMCP Kỹ thương Việt Nam',
        value: 970407,
    },
    {
        label: 'OCB - 	Ngân hàng TMCP Phương Đông',
        value: 970448,
    },
    {
        label: 'ACB - Ngân hàng TMCP Á Châu',
        value: 970416,
    },
    {
        label: 'VPBank - Ngân hàng TMCP Việt Nam Thịnh Vượng',
        value: 970432,
    },
    {
        label: 'TPBank - Ngân hàng TMCP Tiên Phong',
        value: 970423,
    },
    {
        label: 'Sacombank - Ngân hàng TMCP Sài Gòn Thương Tín',
        value: 970403,
    },
    {
        label: 'HDBank - Ngân hàng TMCP Phát triển Thành phố Hồ Chí Minh',
        value: 970437,
    },
    {
        label: 'VietCapitalBank - Ngân hàng TMCP Bản Việt',
        value: 970454,
    },
    {
        label: 'SCB - Ngân hàng TMCP Sài Gòn',
        value: 970429,
    },
    {
        label: 'VIB - Ngân hàng TMCP Quốc tế Việt Nam',
        value: 970441,
    },
    {
        label: 'SHB - Ngân hàng TMCP Sài Gòn - Hà Nội',
        value: 970443,
    },
    {
        label: 'Eximbank - Ngân hàng TMCP Xuất Nhập khẩu Việt Nam',
        value: 970431,
    },
    {
        label: 'MSB - Ngân hàng TMCP Hàng Hải',
        value: 970426,
    },
    {
        label: 'CAKE - Ngân hàng số CAKE by VPBank',
        value: 546034,
    },
    {
        label: 'Ubank - Ngân hàng số Ubank by VPBank',
        value: 546035,
    },
    {
        label: 'Timo - Ngân hàng số Timo by Bản Việt Bank',
        value: 963388,
    },
    {
        label: 'ViettelMoney - Tổng Công ty Dịch vụ số Viettel',
        value: 971005,
    },
    {
        label: 'VNPTMoney - VNPT Money',
        value: 971011,
    },
    {
        label: 'SaigonBank - Ngân hàng TMCP Sài Gòn Công Thương',
        value: 970400,
    },
    {
        label: 'BacABank - Ngân hàng TMCP Bắc Á',
        value: 970409,
    },
    {
        label: 'PVcombank - Ngân hàng TMCP Đại Chúng Việt Nam',
        value: 970412,
    },
    {
        label: 'OceanBank - Ngân hàng Thương mại TNHH MTV Đại Dương',
        value: 970414,
    },
    {
        label: 'NCB - Ngân hàng TMCP Quốc Dân',
        value: 970419,
    },
    {
        label: 'ShinhanBank - Ngân hàng TNHH MTV Shinhan Việt Nam',
        value: 970424,
    },
    {
        label: 'ABBANK - Ngân hàng TMCP An Bình',
        value: 970425,
    },
    {
        label: 'VietABank - Ngân hàng TMCP Việt Á',
        value: 970427,
    },
    {
        label: 'NamABank - Ngân hàng TMCP Nam Á',
        value: 970428,
    },
    {
        label: 'PGBank - Ngân hàng TMCP Xăng dầu Petrolimex',
        value: 970430,
    },
    {
        label: 'VietBank - Ngân hàng TMCP Việt Nam Thương Tín',
        value: 970433,
    },
    {
        label: 'BaoVietBank - Ngân hàng TMCP Bảo Việt',
        value: 970438,
    },
    {
        label: 'SeABank - Ngân hàng TMCP Đông Nam A',
        value: 970440,
    },
    {
        label: 'COOPBANK - Ngân hàng Hợp tác xã Việt Nam',
        value: 970446,
    },
    {
        label: 'LienVietPostBank - Ngân hàng TMCP Bưu Điện Liên Việt',
        value: 970449,
    },
    {
        label: 'KienLongBank - Ngân hàng TMCP Kiên Long',
        value: 970452,
    },
    {
        label: 'KBank - Ngân hàng Đại chúng TNHH Kasikornbank',
        value: 668888,
    },
    {
        label: 'UnitedOverseas - Ngân hàng United Overseas - Chi nhánh TP. Hồ Chí Minh',
        value: 970458,
    },
    {
        label: 'StandardChartered - Ngân hàng TNHH MTV Standard Chartered Bank Việt Nam',
        value: 970410,
    },
    {
        label: 'PublicBank - Ngân hàng TNHH MTV Public Việt Nam',
        value: 970439,
    },
    {
        label: 'Nonghyup - Ngân hàng Nonghyup - Chi nhánh Hà Nội',
        value: 801011,
    },
    {
        label: 'IndovinaBank - Ngân hàng TNHH Indovina',
        value: 970434,
    },
    {
        label: 'IBKHCM - Ngân hàng Công nghiệp Hàn Quốc - Chi nhánh TP. Hồ Chí Minh',
        value: 970456,
    },
    {
        label: 'IBKHN - Ngân hàng Công nghiệp Hàn Quốc - Chi nhánh Hà Nội',
        value: 970455,
    },
    {
        label: 'VRB - Ngân hàng Liên doanh Việt - Nga',
        value: 970421,
    },
    {
        label: 'Woori - Ngân hàng TNHH MTV Woori Việt Nam',
        value: 970457,
    },
    {
        label: 'KookminHN - Ngân hàng Kookmin - Chi nhánh Hà Nội',
        value: 970462,
    },
    {
        label: 'KookminHCM - Ngân hàng Kookmin - Chi nhánh Thành phố Hồ Chí Minh',
        value: 970463,
    },
    {
        label: 'HSBC - Ngân hàng TNHH MTV HSBC (Việt Nam)',
        value: 458761,
    },
    {
        label: 'HongLeong - Ngân hàng TNHH MTV Hong Leong Việt Nam',
        value: 970442,
    },
    {
        label: 'GPBank - Ngân hàng Thương mại TNHH MTV Dầu Khí Toàn Cầu',
        value: 970408,
    },
    {
        label: 'DongABank - Ngân hàng TMCP Đông Á',
        value: 970406,
    },
    {
        label: 'DBSBank - DBS Bank Ltd - Chi nhánh Thành phố Hồ Chí Minh',
        value: 796500,
    },
    {
        label: 'CIMB - Ngân hàng TNHH MTV CIMB Việt Nam',
        value: 422589,
    },
    {
        label: 'CBBank - Ngân hàng Thương mại TNHH MTV Xây dựng Việt Nam',
        value: 970444,
    },
    {
        label: 'Citibank - Ngân hàng Citibank',
        value: 533948,
    },
    {
        label: 'KEBHanaHCM - Ngân hàng KEB Hana – Chi nhánh Thành phố Hồ Chí Minh',
        value: 970466,
    },
    {
        label: 'KEBHANAHN - Ngân hàng KEB Hana – Chi nhánh Hà Nội',
        value: 970467,
    },
    {
        label: 'MAFC - Công ty Tài chính TNHH MTV Mirae Asset (Việt Nam)',
        value: 977777,
    },
    {
        label: 'VBSP - Ngân hàng Chính sách Xã hội',
        value: 999888,
    },
];

export const GetBank = (value) => {
    if (value === '970436') {
        return {
            label: 'Vietcombank - Ngân hàng TMCP Ngoại Thương Việt Nam',
            value: 970436,
        };
    }
    if (value === '970415') {
        return {
            label: 'ViettinBank - Ngân hàng TMCP Công thương Việt Nam',
            value: 970415,
        };
    }
    if (value === '970418') {
        return {
            label: 'BIDV - Ngân hàng TMCP Đầu tư và Phát triển Việt Nam',
            value: 970418,
        };
    }
    if (value === '970405') {
        return {
            label: 'Agribank - Ngân hàng Nông nghiệp và Phát triển Nông thôn Việt Nam',
            value: 970405,
        };
    }
    if (value === '970422') {
        return {
            label: 'MBBank - Ngân hàng TMCP Quân đội',
            value: 970422,
        };
    }
    if (value === '970407') {
        return {
            label: 'Techcombank - Ngân hàng TMCP Kỹ thương Việt Nam',
            value: 970407,
        };
    }
    if (value === '970448') {
        return {
            label: 'OCB - 	Ngân hàng TMCP Phương Đông',
            value: 970448,
        };
    }
    if (value === '970416') {
        return {
            label: 'ACB - Ngân hàng TMCP Á Châu',
            value: 970416,
        };
    }
    if (value === '970432') {
        return {
            label: 'VPBank - Ngân hàng TMCP Việt Nam Thịnh Vượng',
            value: 970432,
        };
    }
    if (value === '970423') {
        return {
            label: 'TPBank - Ngân hàng TMCP Tiên Phong',
            value: 970423,
        };
    }
    if (value === '970403') {
        return {
            label: 'Sacombank - Ngân hàng TMCP Sài Gòn Thương Tín',
            value: 970403,
        };
    }
    if (value === '970437') {
        return {
            label: 'HDBank - Ngân hàng TMCP Phát triển Thành phố Hồ Chí Minh',
            value: 970437,
        };
    }
    if (value === '970454') {
        return {
            label: 'VietCapitalBank - Ngân hàng TMCP Bản Việt',
            value: 970454,
        };
    }
    if (value === '970429') {
        return {
            label: 'SCB - Ngân hàng TMCP Sài Gòn',
            value: 970429,
        };
    }
    if (value === '970441') {
        return {
            label: 'VIB - Ngân hàng TMCP Quốc tế Việt Nam',
            value: 970441,
        };
    }
    if (value === '970443') {
        return {
            label: 'SHB - Ngân hàng TMCP Sài Gòn - Hà Nội',
            value: 970443,
        };
    }
    if (value === '970431') {
        return {
            label: 'Eximbank - Ngân hàng TMCP Xuất Nhập khẩu Việt Nam',
            value: 970431,
        };
    }
    if (value === '970426') {
        return {
            label: 'MSB - Ngân hàng TMCP Hàng Hải',
            value: 970426,
        };
    }
    if (value === '546034') {
        return {
            label: 'CAKE - Ngân hàng số CAKE by VPBank',
            value: 546034,
        };
    }
    if (value === '546035') {
        return {
            label: 'Ubank - Ngân hàng số Ubank by VPBank',
            value: 546035,
        };
    }
    if (value === '963388') {
        return {
            label: 'Timo - Ngân hàng số Timo by Bản Việt Bank',
            value: 963388,
        };
    }
    if (value === '971005') {
        return {
            label: 'ViettelMoney - Tổng Công ty Dịch vụ số Viettel',
            value: 971005,
        };
    }
    if (value === '971011') {
        return {
            label: 'VNPTMoney - VNPT Money',
            value: 971011,
        };
    }
    if (value === '970400') {
        return {
            label: 'SaigonBank - Ngân hàng TMCP Sài Gòn Công Thương',
            value: 970400,
        };
    }
    if (value === '970409') {
        return {
            label: 'BacABank - Ngân hàng TMCP Bắc Á',
            value: 970409,
        };
    }
    if (value === '970412') {
        return {
            label: 'PVcombank - Ngân hàng TMCP Đại Chúng Việt Nam',
            value: 970412,
        };
    }
    if (value === '970414') {
        return {
            label: 'OceanBank - Ngân hàng Thương mại TNHH MTV Đại Dương',
            value: 970414,
        };
    }
    if (value === '970419') {
        return {
            label: 'NCB - Ngân hàng TMCP Quốc Dân',
            value: 970419,
        };
    }
    if (value === '970424') {
        return {
            label: 'ShinhanBank - Ngân hàng TNHH MTV Shinhan Việt Nam',
            value: 970424,
        };
    }
    if (value === '970425') {
        return {
            label: 'ABBANK - Ngân hàng TMCP An Bình',
            value: 970425,
        };
    }
    if (value === '970427') {
        return {
            label: 'VietABank - Ngân hàng TMCP Việt Á',
            value: 970427,
        };
    }
    if (value === '970428') {
        return {
            label: 'NamABank - Ngân hàng TMCP Nam Á',
            value: 970428,
        };
    }
    if (value === '970430') {
        return {
            label: 'PGBank - Ngân hàng TMCP Xăng dầu Petrolimex',
            value: 970430,
        };
    }
    if (value === '970433') {
        return {
            label: 'VietBank - Ngân hàng TMCP Việt Nam Thương Tín',
            value: 970433,
        };
    }
    if (value === '970438') {
        return {
            label: 'BaoVietBank - Ngân hàng TMCP Bảo Việt',
            value: 970438,
        };
    }
    if (value === '970440') {
        return {
            label: 'SeABank - Ngân hàng TMCP Đông Nam A',
            value: 970440,
        };
    }
    if (value === '970446') {
        return {
            label: 'COOPBANK - Ngân hàng Hợp tác xã Việt Nam',
            value: 970446,
        };
    }
    if (value === '970449') {
        return {
            label: 'LienVietPostBank - Ngân hàng TMCP Bưu Điện Liên Việt',
            value: 970449,
        };
    }
    if (value === '970452') {
        return {
            label: 'KienLongBank - Ngân hàng TMCP Kiên Long',
            value: 970452,
        };
    }
    if (value === '668888') {
        return {
            label: 'KBank - Ngân hàng Đại chúng TNHH Kasikornbank',
            value: 668888,
        };
    }
    if (value === '970458') {
        return {
            label: 'UnitedOverseas - Ngân hàng United Overseas - Chi nhánh TP. Hồ Chí Minh',
            value: 970458,
        };
    }
    if (value === '970410') {
        return {
            label: 'StandardChartered - Ngân hàng TNHH MTV Standard Chartered Bank Việt Nam',
            value: 970410,
        };
    }
    if (value === '970439') {
        return {
            label: 'PublicBank - Ngân hàng TNHH MTV Public Việt Nam',
            value: 970439,
        };
    }
    if (value === '801011') {
        return {
            label: 'Nonghyup - Ngân hàng Nonghyup - Chi nhánh Hà Nội',
            value: 801011,
        };
    }
    if (value === '970434') {
        return {
            label: 'IndovinaBank - Ngân hàng TNHH Indovina',
            value: 970434,
        };
    }
    if (value === '970456') {
        return {
            label: 'IBKHCM - Ngân hàng Công nghiệp Hàn Quốc - Chi nhánh TP. Hồ Chí Minh',
            value: 970456,
        };
    }
    if (value === '970455') {
        return {
            label: 'IBKHN - Ngân hàng Công nghiệp Hàn Quốc - Chi nhánh Hà Nội',
            value: 970455,
        };
    }
    if (value === '970421') {
        return {
            label: 'VRB - Ngân hàng Liên doanh Việt - Nga',
            value: 970421,
        };
    }
    if (value === '970457') {
        return {
            label: 'Woori - Ngân hàng TNHH MTV Woori Việt Nam',
            value: 970457,
        };
    }
    if (value === '970462') {
        return {
            label: 'KookminHN - Ngân hàng Kookmin - Chi nhánh Hà Nội',
            value: 970462,
        };
    }
    if (value === '970463') {
        return {
            label: 'KookminHCM - Ngân hàng Kookmin - Chi nhánh Thành phố Hồ Chí Minh',
            value: 970463,
        };
    }
    if (value === '458761') {
        return {
            label: 'HSBC - Ngân hàng TNHH MTV HSBC (Việt Nam)',
            value: 458761,
        };
    }
    if (value === '970442') {
        return {
            label: 'HongLeong - Ngân hàng TNHH MTV Hong Leong Việt Nam',
            value: 970442,
        };
    }
    if (value === '970408') {
        return {
            label: 'GPBank - Ngân hàng Thương mại TNHH MTV Dầu Khí Toàn Cầu',
            value: 970408,
        };
    }
    if (value === '970406') {
        return {
            label: 'DongABank - Ngân hàng TMCP Đông Á',
            value: 970406,
        };
    }
    if (value === '796500') {
        return {
            label: 'DBSBank - DBS Bank Ltd - Chi nhánh Thành phố Hồ Chí Minh',
            value: 796500,
        };
    }
    if (value === '422589') {
        return {
            label: 'CIMB - Ngân hàng TNHH MTV CIMB Việt Nam',
            value: 422589,
        };
    }
    if (value === '970444') {
        return {
            label: 'CBBank - Ngân hàng Thương mại TNHH MTV Xây dựng Việt Nam',
            value: 970444,
        };
    }
    if (value === '533948') {
        return {
            label: 'Citibank - Ngân hàng Citibank',
            value: 533948,
        };
    }
    if (value === '970466') {
        return {
            label: 'KEBHanaHCM - Ngân hàng KEB Hana – Chi nhánh Thành phố Hồ Chí Minh',
            value: 970466,
        };
    }
    if (value === '970467') {
        return {
            label: 'KEBHANAHN - Ngân hàng KEB Hana – Chi nhánh Hà Nội',
            value: 970467,
        };
    }
    if (value === '977777') {
        return {
            label: 'MAFC - Công ty Tài chính TNHH MTV Mirae Asset (Việt Nam)',
            value: 977777,
        };
    }
    if (value === '999888') {
        return {
            label: 'VBSP - Ngân hàng Chính sách Xã hội',
            value: 999888,
        };
    }
};
