import STATUS from '../../../constants/status'

const filterOrdersById = (orders, selected) => {
  return orders.filter(({ id }) => selected.includes(id))
}

const filterOrdersByBillAndTableId = ({ orders, bill_id }) => {
  const find_order = orders.find(order => order.bill.id === bill_id);

  return orders
    .filter(order => order.bill.id === find_order.bill.id && order.user_identify === find_order.user_identify)
    .map(order => order.id);
}

const filterOrdersByTableId = ({ orders, user_identify }) => {
  return orders.filter(order => order.user_identify === user_identify).map(order => order.id);
}

const isAllBillDataCompleted = bill => {
  return !bill.some(order => order.status.uuid !== STATUS.COMPLETED)
}

const isAllOrderCompleted = orders => {
  return !orders.some(order => order.status.uuid !== STATUS.COMPLETED)
}

const isIncludeOrderCompleted = orders => {
  return orders.some(order => order.status.uuid === STATUS.COMPLETED)
}

const isIncludeOrderPending = orders => {
  return orders.some(order => order.status.uuid === STATUS.PENDING)
}

const isIncludeOrderCreated = orders => {
  return orders.some(order => order.status.uuid === STATUS.CREATED)
}

export {
  filterOrdersById,
  filterOrdersByBillAndTableId,
  filterOrdersByTableId,
  isAllOrderCompleted,
  isAllBillDataCompleted,
  isIncludeOrderCompleted,
  isIncludeOrderCreated,
  isIncludeOrderPending,
}