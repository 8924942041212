import React from 'react';
import { CustomInput, Badge, Collapse } from 'reactstrap';
import classnames from 'classnames';
import { Colxx } from '../../../components/common/CustomBootstrap';
import { END_POINT } from '../../../constants/defaultValues';
import STATUS from '../../../constants/status';
import IntlMessages from '../../../helpers/IntlMessages';
import { AddCommaNumber } from '../../../helpers/Utils';

const ThumbListView = ({
    order,
    isSelect,
    collect,
    onCheckItem,
    underLine,
}) => {
    const size = order.size && order.size.split(',');
    const sizeValue = size.length && size[1] != '0' ? size[0] : '';

    return (
        <div className='mb-3'>
            <span
                onClick={(event) => onCheckItem(event, order.id)}
                className={classnames(
                    'mb-2 d-flex flex-row disable-text-selection',
                    {
                        active: isSelect,
                    }
                )}
            >
                <section className='d-block position-relative'>
                    <img
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = 'http://via.placeholder.com/320x240';
                        }}
                        alt={order.name}
                        src={
                            order.product.image.indexOf('http') >= 0
                                ? order.product.image
                                : END_POINT + '/public/' + order.product.image
                        }
                        className='list-thumbnail medium border-0'
                    />
                    <Badge
                        className='position-absolute badge-top-right'
                        color={
                            order.status.uuid === STATUS.CREATED
                                ? 'danger'
                                : order.status.uuid === STATUS.CLOSED
                                ? 'secondary'
                                : order.status.uuid === STATUS.COMPLETED
                                ? 'success'
                                : 'primary'
                        }
                        pill
                    >
                        {order.status.name}
                    </Badge>
                </section>
                <div className='card-body-custom align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center pr-0'>
                    <section className='w-100'>
                        {/* <sup className='text-primary fz-sub'>
                            {order.user_identify}
                        </sup> */}
                        <span className='list-item-heading'>
                            {order.product.name}
                        </span>
                        <br />
                        <div className='d-flex justify-content-between'>
                            <div className='pr-4'>
                                <p className='text-muted mb-1 text-small'>
                                    {'Số lượng: ' + order.count}
                                </p>
                            </div>
                            <div className='custom-control custom-checkbox pl-1 align-self-center'>
                                <Badge pill>{`${AddCommaNumber(
                                    order.price
                                )} đ`}</Badge>
                            </div>
                        </div>
                        <div className='text-primary text-small font-weight-medium d-sm-block'>
                            {order.date + ' - ' + order.time}
                        </div>
                    </section>
                </div>
            </span>
            <Collapse
                isOpen={true}
                // className={classnames({
                //     'custom-line-bottom':
                //         underLine &&
                //         (order.size ||
                //             order.topping ||
                //             order.ice ||
                //             order.sugar ||
                //             order.note),
                // })}
            >
                {sizeValue ? (
                    <div className='mb-2'>
                        {order.product.sizeTitle ? (
                            <span>{order.product.sizeTitle}</span>
                        ) : (
                            <IntlMessages id='product.size' />
                        )}
                        {': '}
                        {/* <SizeComponent data={order.size} /> */}
                        <span>
                            <Badge>{`${sizeValue}`}</Badge>
                        </span>
                    </div>
                ) : null}

                {order.topping ? (
                    <div className='mb-2'>
                        {order.product.toppingTitle ? (
                            <span>{order.product.toppingTitle}</span>
                        ) : (
                            <IntlMessages id='product.topping' />
                        )}
                        {': '}
                        <ToppingComponent data={order.topping} />
                    </div>
                ) : null}

                {order.ice && order.ice != '100' ? (
                    <div className='mb-2'>
                        {order.product.iceTitle ? (
                            <span>{order.product.iceTitle}</span>
                        ) : (
                            <IntlMessages id='product.ice' />
                        )}
                        {': '}
                        <span>
                            <Badge>{`${order.ice} %`}</Badge>
                        </span>
                    </div>
                ) : null}

                {order.sugar && order.sugar != '100' ? (
                    <div className='mb-2'>
                        {order.product.sugarTitle ? (
                            <span>{order.product.sugarTitle}</span>
                        ) : (
                            <IntlMessages id='product.sugar' />
                        )}
                        {': '}
                        <span>
                            <Badge>{`${order.sugar} %`}</Badge>
                        </span>
                    </div>
                ) : null}

                {order.note ? (
                    <div className='mb-2'>
                        <span>{`Ghi chú: `}</span>
                        <span className='red text-small'>{order.note}</span>
                    </div>
                ) : null}
            </Collapse>
        </div>
    );
};

export default React.memo(ThumbListView);

const ToppingComponent = (props) => {
    const toppings = props.data.split('|');

    return toppings.length ? (
        <span>
            {toppings.map((_topping, idx) => (
                <Badge className='spacer-5' key={idx}>
                    {_topping.split(',')[0]}
                </Badge>
            ))}
        </span>
    ) : null;
};

const SizeComponent = (props) => {
    const size = props.data.split(',');

    return size.length && size[1] != '0' ? (
        <span>
            <Badge>{size[0]}</Badge>
        </span>
    ) : null;
};
