import React, { Fragment, useState } from 'react';
import { Card, CardBody, CardSubtitle, CardText, Row, Badge } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import { AddCommaNumber } from '../../helpers/Utils';
import { END_POINT } from '../../constants/defaultValues';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import ModalProductDetail from './ModalProductDetail';

const ListMenu = (props) => {
    const { menus, listsData, loading, onClickImage, onToggleDetail } = props;
    
    const [modal, setModal] = useState(false);
    const [productDetail, setProductDetail] = useState(null);
    
    const data = listsData?.length ? listsData : menus?.length === 1 ? menus : menus?.slice(1);
    // console.log('data_menu: ', data);

    const handleToggleModalDetail = (product) => {
        const productDefault = { ...product };
        // console.log('productDefault: ', productDefault);
        setProductDetail(productDefault);
        setModal(!modal[productDefault.id]);

        // onToggleDetail({
        //     product,
        //     name: 'detailDisplay',
        // });
    };

    // const updateOrders = ({ product, requireCreated = false }) => {
    //     const find_order = orders?.find(
    //         (_product) => _product.id === product.id
    //     );

    //     // Kiểm tra size, topping, ice, sugar, price_sale, active_sale
    //     let price = product.price;
    //     if (product.active_sale) {
    //         price = product.price_sale;
    //     }

    //     // size
    //     if (product.sizeSelectedId.length) {
    //         const find_size = product.size.find((_size) =>
    //             product.sizeSelectedId.includes(_size.id)
    //         );
    //         price = parseFloat(price) + parseFloat(find_size.value);
    //     }
    //     // topping
    //     if (product.toppingSelectedId.length) {
    //         const find_topping = product.topping.filter((_size) =>
    //             product.toppingSelectedId.includes(_size.id)
    //         );

    //         for (let i = 0; i < find_topping.length; i++) {
    //             price = parseFloat(price) + parseFloat(find_topping[i].value);
    //         }
    //     }

    //     // Update giá tiền nếu đã tồn tại
    //     if (find_order) {
    //         const orders = orders?.map((o) =>
    //             o.id === find_order.id
    //                 ? {
    //                       ...o,
    //                       price: price,
    //                       note: product.note,
    //                       iceSelectedValue: product.iceSelectedValue,
    //                       sugarSelectedValue: product.sugarSelectedValue,
    //                       sizeSelectedId: product.sizeSelectedId,
    //                       toppingSelectedId: product.toppingSelectedId,
    //                   }
    //                 : o
    //         );

    //         setOrders(orders);
    //     } else {
    //         /* Tạo mới nếu chưa có
    //          *
    //          * Chỉ tạo mới khi click nút thêm sản phẩm, input hoặc nhấn nút +, -
    //          */
    //         if (requireCreated) {
    //             orders.push({
    //                 id: product.id,
    //                 name: product.name,
    //                 price: price,
    //                 count: 1,
    //                 note: product.note,
    //                 iceSelectedValue: product.iceSelectedValue,
    //                 sugarSelectedValue: product.sugarSelectedValue,
    //                 sizeSelectedId: product.sizeSelectedId,
    //                 toppingSelectedId: product.toppingSelectedId,
    //                 previewDisplay: false,
    //             });
    //         }
    //         setOrders(orders);
    //     }
    // };

    // const change = (e, id) => {
    //     if (!e || !e.target) return false;

    //     let { value } = e.target;
    //     let order = getOrder(id);

    //     value = parseInt(value);

    //     if (!order) {
    //         // init
    //         const product = getProductInfo(id);
    //         if (!product) return false;

    //         updateOrders({ product, requireCreated: true });
    //     } else {
    //         // change
    //         const orders = orders
    //             ?.map((o) =>
    //                 o.id === order.id
    //                     ? { ...o, count: value >= 0 ? value : 0 }
    //                     : o
    //             )
    //             .filter((order) => order.count > 0);

    //         setOrders(orders);
    //     }
    // };

    // const getOrder = (id) => {
    //     const orders = orders?.filter((order) => order.id === id);
    //     return orders?.length ? orders[0] : null;
    // };

    // const getProductInfo = (id) => {
    //     const products = menus
    //         .map((categories) =>
    //             categories.data.filter((product) => product.id === id)
    //         )
    //         .filter((items) => items.length > 0);

    //     return products.length > 0 ? products[0][0] : null;
    // };

    // const calTotalPrice = (orders) => {
    //     let total = 0;
    //     for (let i = 0; i < orders.length; i++) {
    //         let order = orders[i];

    //         total += parseInt(order.price) * order.count;
    //     }
    //     return total;
    // };

    // const calcNumberOrder = (orders) => {
    //     let sum = 0;
    //     let i = 0;
    //     let orders_len = orders.length;
    //     let order = null;
    //     for (; i < orders_len; i++) {
    //         order = orders[i];
    //         sum += order.count;
    //     }
    //     return sum;
    // };

    return loading ? (
        <div className='loading' />
    ) : (
        <Fragment>
            <Row>
                {data?.length ? (
                    data?.map((menu, index) => {
                        return (
                            menu?.data?.length && (
                                <Fragment key={index}>
                                    {menu?.data &&
                                        menu?.data?.map((product, idx) => {
                                            return (
                                                <Fragment key={idx}>
                                                    <Colxx
                                                        key={idx}
                                                        xxs='6'
                                                        md='4'
                                                        lg='4'
                                                        xl='4'
                                                        xxl='3'
                                                        className='pad-bot d-flex'
                                                    >
                                                        <Card
                                                            className={classNames(
                                                                'card-hover',
                                                                {
                                                                    disable:
                                                                        product.softHide
                                                                            ? 'disable'
                                                                            : '',
                                                                }
                                                            )}
                                                        >
                                                            <div className='position-relative cursor-body-menu'>
                                                                <img
                                                                    alt={
                                                                        product.name
                                                                    }
                                                                    src={
                                                                        product.image.indexOf(
                                                                            'http'
                                                                        ) >= 0
                                                                            ? product.image
                                                                            : END_POINT +
                                                                              '/public/' +
                                                                              product.image
                                                                    }
                                                                    onError={(
                                                                        e
                                                                    ) => {
                                                                        e.target.onerror =
                                                                            null;
                                                                        e.target.src =
                                                                            'http://via.placeholder.com/320x240';
                                                                    }}
                                                                    className='card-img-top'
                                                                    onClick={
                                                                        product.softHide
                                                                            ? null
                                                                            : () =>
                                                                                  onClickImage(
                                                                                      product
                                                                                  )
                                                                    }
                                                                />
                                                                {product.bestter ? (
                                                                    <Badge
                                                                        className='position-absolute badge-top-right'
                                                                        color={
                                                                            'success'
                                                                        }
                                                                        pill
                                                                    >
                                                                        {
                                                                            'Khuyên dùng'
                                                                        }
                                                                    </Badge>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                            <div
                                                                className='d-flex justify-content-around'
                                                                onClick={
                                                                    product.ice
                                                                        .length ||
                                                                    product.size
                                                                        .length ||
                                                                    product
                                                                        .sugar
                                                                        .length ||
                                                                    product
                                                                        .topping
                                                                        .length
                                                                        ? () =>
                                                                              handleToggleModalDetail(
                                                                                  product
                                                                              )
                                                                        : null
                                                                }
                                                            >
                                                                <CardBody
                                                                    className={classNames(
                                                                        'd-flex flex-column p-3',
                                                                        {
                                                                            'cursor-pointer':
                                                                                product
                                                                                    .ice
                                                                                    .length ||
                                                                                product
                                                                                    .size
                                                                                    .length ||
                                                                                product
                                                                                    .sugar
                                                                                    .length ||
                                                                                product
                                                                                    .topping
                                                                                    .length,
                                                                        }
                                                                    )}
                                                                >
                                                                    <CardSubtitle className='w-100'>
                                                                        {
                                                                            product.name
                                                                        }
                                                                    </CardSubtitle>
                                                                    {product.softHide ? (
                                                                        <Colxx className='col-3'></Colxx>
                                                                    ) : (
                                                                        <CardText className='d-flex text-muted text-small mb-0 font-weight-light mt-auto'>
                                                                            {product.active_sale ? (
                                                                                <>
                                                                                    <Badge
                                                                                        color='light'
                                                                                        pill
                                                                                        className='mr-1'
                                                                                    >
                                                                                        <del>
                                                                                            {AddCommaNumber(
                                                                                                product.price
                                                                                            )}
                                                                                        </del>
                                                                                    </Badge>
                                                                                    <Badge
                                                                                        color='primary'
                                                                                        pill
                                                                                    >
                                                                                        {AddCommaNumber(
                                                                                            product.price_sale
                                                                                        )}
                                                                                    </Badge>
                                                                                </>
                                                                            ) : (
                                                                                <Badge
                                                                                    color='primary'
                                                                                    pill
                                                                                >
                                                                                    {AddCommaNumber(
                                                                                        product.price
                                                                                    )}
                                                                                </Badge>
                                                                            )}
                                                                        </CardText>
                                                                    )}
                                                                </CardBody>
                                                                {product.ice
                                                                    .length ||
                                                                product.size
                                                                    .length ||
                                                                product.sugar
                                                                    .length ||
                                                                product.topping
                                                                    .length ? (
                                                                    <i className='iconsminds-arrow-right h-100 d-flex align-items-center pr-1' />
                                                                ) : null}
                                                            </div>
                                                        </Card>
                                                    </Colxx>
                                                </Fragment>
                                            );
                                        })}
                                </Fragment>
                            )
                        );
                    })
                ) : (
                    <div className='d-flex justify-content-center w-100'>
                        <p className='text-muted text-center'>
                            Bạn chưa cài đặt Danh mục sản phẩm và Sản phẩm,{' '}
                            <Link to={{ pathname: '/app/pages/product' }}>
                                bấm vào đây để cài đặt ngay!
                            </Link>
                        </p>
                    </div>
                )}
            </Row>
            <ModalProductDetail
                product={productDetail}
                productDetail={productDetail}
                setProductDetail={setProductDetail}
                // order={getOrder(
                //     product.id
                // )}
                // detailDisplay={
                //     productDetail.detailDisplay
                // }
                // orderDisplay={
                //     productDetail.orderDisplay
                // }
                // isOpen={modal}
                // onToggle={handleToggleModalDetail}
                // change={change}
                onToggleDetail={onToggleDetail}
                onClickImage={onClickImage}
            />
        </Fragment>
    );
};

export default ListMenu;
