import React from 'react';
import moment from 'moment';
import { Row } from 'reactstrap';
import { Colxx } from './common/CustomBootstrap';

export const CalendarToolbar = (toolbar) => {
    const goToBack = () => {
        toolbar.onNavigate('PREV');
    };
    const goToNext = () => {
        toolbar.onNavigate('NEXT');
    };
    const goToCurrent = () => {
        toolbar.onNavigate('TODAY');
    };
    // const goToMonth = () => {
    //     toolbar.onNavigate('MONTH');
    // };
    // const goToWeek = () => {
    //     toolbar.onNavigate('WEEK');
    // };
    // const goToDay = () => {
    //     toolbar.onNavigate('DAY');
    // };
    // const goToList = () => {
    //     toolbar.onNavigate('LIST');
    // };

    const label = () => {
        const date = moment(toolbar.date);
        return (
            <span>
                <span>{date.format('MMMM')} </span>
                <span> {date.format('YYYY')}</span>
            </span>
        );
    };

    return (
        <Row className='big-calendar-header d-flex justify-content-between align-items-center'>
            <Colxx xxs='4'>
                <button
                    className='btn calendar-prev-btn mr-1'
                    onClick={goToBack}
                >
                    <span className='simple-icon-arrow-left' />
                </button>
                <button
                    className='btn calendar-next-btn mr-2'
                    onClick={goToNext}
                >
                    <span className='simple-icon-arrow-right' />
                </button>
                <button
                    className='btn btn-primary calendar-today-btn mr-2'
                    onClick={goToCurrent}
                >
                    Hôm nay
                </button>
            </Colxx>
            <Colxx xxs='4' className='text-center'>
                <label>{label()}</label>
            </Colxx>
            <Colxx xxs='4'></Colxx>
        </Row>
    );
};
