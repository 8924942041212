import React, { memo } from 'react';

const FooterTodo = () => {
    return (
        <a
            href='https://docs.vmass.vn/quan-ly/viec-can-lam'
            target='_blank'
            className='mb-0 pl-2'
        >
            Hướng dẫn
        </a>
    );
};

export default memo(FooterTodo);
