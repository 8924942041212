import React from 'react';
import ReactTable from 'react-table';
import Pagination from '../../../components/DatatablePagination';
import { GetSelectTime } from '../../../helpers/SelectTime';
import moment from 'moment';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentMultiForm = (props) => {
    const { data } = props;
    // console.log('props: ', props);
    const columns = [
        {
            Header: 'Tên tài sản',
            accessor: 'name',
            minWidth: 150,
            Cell: (props) => (
                <span className='text-capitalize'>{props.value}</span>
            ),
        },
        {
            Header: 'Số tiền',
            accessor: 'value',
            Cell: (props) => AddCommaNumber(props.value),
        },
        {
            Header: 'Mô tả',
            accessor: 'note',
        },
        {
            Header: 'Thống kê',
            accessor: 'time',
            Cell: (props) => GetSelectTime(props.value).label,
        },
        {
            Header: 'Người tạo',
            accessor: 'user_created_id',
        },
        {
            Header: 'Thời gian tạo',
            accessor: 'createdAt',
            Cell: (props) => moment(props.value).format('DD/MM/YYYY'),
        },
    ];
    return (
        <ReactTable
            defaultPageSize={20}
            data={data}
            columns={columns}
            minRows={0}
            showPageJump={false}
            showPageSizeOptions={true}
            PaginationComponent={Pagination}
            NoDataComponent={() => null}
        />
    );
};

export default ContentMultiForm;
