import React from 'react';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { GetSelectTime } from '../../../helpers/SelectTime';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentDisplay = (props) => {
    // console.log(props);
    const findStockEqual = props.data_stocks?.find(
        (d) => d.name === props.values.stock
    );
    return (
        <Card className='position-sticky-custom mb-4'>
            <CardBody>
                <CardTitle>
                    <IntlMessages id='warehouse.transfer' />
                </CardTitle>
                <Row className='mt-3 '>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.transfer-stock' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.stock ? (
                                props.values.stock
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.code' />
                        </p>
                        <p className='mb-3'>
                            {findStockEqual
                                ? findStockEqual.sku
                                : props.values.code}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.unit' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {findStockEqual
                                ? findStockEqual.unit
                                : props.values.unit}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.transfer-quantity' />
                        </p>
                        <p className='mb-3 text-lowercase'>
                            {AddCommaNumber(props.values.quantity)}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.transfer-price' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.price ? (
                                AddCommaNumber(props.values.price)
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.description' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.description}
                        </p>
                    </Colxx>
                </Row>
                <Separator className='mb-4' />
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.transfer-name' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.name}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.phone' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.phone}
                        </p>
                    </Colxx>
                </Row>
                <Separator className='mb-4' />
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.transfer-name-carrier' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.name_carrier}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.transfer-phone-carrier' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.phone_carrier}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.transfer-receiving-warehouse' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.receiving_warehouse}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.transportation' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.transportation}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='12'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='warehouse.cost-transfer' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {AddCommaNumber(props.values.cost_transfer)}
                        </p>
                    </Colxx>
                </Row>
                <Separator className='mb-4' />
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='user.created' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.user}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            Thời gian tạo
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.at}
                        </p>
                    </Colxx>
                </Row>
            </CardBody>
        </Card>
    );
};

export default ContentDisplay;
