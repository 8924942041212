import React from 'react';
import ReactTable from 'react-table';
import Pagination from '../../../components/DatatablePagination';
import { AddCommaNumber, AddCommaPhoneNumber } from '../../../helpers/Utils';

const ContentMultiForm = (props) => {
    const { data, onChange } = props;
    // console.log(data);
    const columns = [
        {
            Header: 'Tên nhà cung cấp',
            accessor: 'name',
            Cell: (props) => (
                <span className='text-capitalize'>{props.value}</span>
            ),
        },
        {
            Header: 'Số điện thoại',
            accessor: 'phone',
            Cell: (props) => AddCommaPhoneNumber(props.value),
        },
        {
            Header: 'Email',
            accessor: 'email',
            Cell: (props) => (
                <span className='text-lowercase'>{props.value}</span>
            ),
        },
        {
            Header: 'Địa chỉ',
            accessor: 'address',
            Cell: (props) => (
                <span className='text-capitalize'>{props.value}</span>
            ),
        },
        // {
        //     Header: 'Mặt hàng cung cấp',
        //     accessor: '',
        // },
        {
            Header: 'Tổng nhập',
            accessor: 'total_price_income',
            Cell: (props) => AddCommaNumber(props.value),
        },
        {
            Header: 'Đã thanh toán',
            accessor: 'total_price_outcome',
            Cell: (props) => AddCommaNumber(props.value),
        },
        {
            Header: 'Còn lại',
            accessor: 'total_price_pending',
            Cell: (props) => AddCommaNumber(props.value),
        },
    ];
    return (
        <ReactTable
            defaultPageSize={20}
            data={data}
            columns={columns}
            minRows={0}
            showPageJump={false}
            showPageSizeOptions={true}
            PaginationComponent={Pagination}
            NoDataComponent={() => null}
        />
    );
};

export default ContentMultiForm;
