import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import {
    UncontrolledDropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';

import { NavLink, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import {
    setContainerClassnames,
    clickOnMobileMenu,
    logoutUser,
    changeLocale,
} from '../../redux/actions';

import {
    menuHiddenBreakpoint,
    searchPath,
    isDarkSwitchActive,
    END_POINT,
    END_POINT_SOCKET,
} from '../../constants/defaultValues';

import { MobileMenuIcon, MenuIcon, Setting } from '../../components/svg';
import TopnavNotifications from './Topnav.Notifications';
import TopnavDarkSwitch from './Topnav.DarkSwitch';

import { getDirection, setDirection } from '../../helpers/Utils';
import io from 'socket.io-client';
import { NotificationManager } from '../../components/common/react-notifications';

class TopNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isInFullScreen: false,
            searchKeyword: '',
            itemMenuRightClickCount: 99,
            companyName:
                localStorage.getItem('companyName') &&
                localStorage.getItem('companyName') != 'null'
                    ? localStorage.getItem('companyName')
                    : localStorage.getItem('username'),
        };
        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        // alert mp3
        this.socket.on('alert-mp3', () => {
            try {
                let alert = document.getElementById('alert-mp3');
                alert.play();
            } catch (error) {
                console.log('alert mp3 exception');
            }
        });

        // schedule todo
        this.socket.on('alert-todo-noti', (res) => {
            // refresh alert data
            this.socket.emit('refresh-alert-data', {
                user_id: localStorage.getItem('user_id'),
            });

            NotificationManager.info(
                "Kiểm tra 'Việc cần làm' ngay bây giờ",
                'Lời nhắc',
                3000,
                null,
                null,
                'filled'
            );
        });

        // Logout
        this.socket.on('logout', () => {
            this.handleLogout();
        });

        // Role
        this.socket.on('update-user-role', (res) => {
            // console.log(res.data);
            localStorage.setItem('roleSettings', JSON.stringify(res.data));
        });

        // alert notification
        this.socket.on('remind-created-orders', (res) => {
            NotificationManager.info(
                'Có sản phẩm mới được đặt. Nhận ngay !',
                'Lời nhắc',
                3000,
                null,
                null,
                'filled'
            );
        });

        this.socket.on('alert-stock-down', (res) => {
            NotificationManager.warning(
                'Mức nguyên liệu xuống thấp!',
                'Đề xuất mua thêm',
                3000,
                null,
                null,
                'filled'
            );
        });
    }

    handleChangeLocale = (locale, direction) => {
        this.props.changeLocale(locale);

        const currentDirection = getDirection().direction;
        if (direction !== currentDirection) {
            setDirection(direction);
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }
    };
    isInFullScreen = () => {
        return (
            (document.fullscreenElement &&
                document.fullscreenElement !== null) ||
            (document.webkitFullscreenElement &&
                document.webkitFullscreenElement !== null) ||
            (document.mozFullScreenElement &&
                document.mozFullScreenElement !== null) ||
            (document.msFullscreenElement &&
                document.msFullscreenElement !== null)
        );
    };
    handleSearchIconClick = (e) => {
        if (window.innerWidth < menuHiddenBreakpoint) {
            let elem = e.target;
            if (!e.target.classList.contains('search')) {
                if (e.target.parentElement.classList.contains('search')) {
                    elem = e.target.parentElement;
                } else if (
                    e.target.parentElement.parentElement.classList.contains(
                        'search'
                    )
                ) {
                    elem = e.target.parentElement.parentElement;
                }
            }

            if (elem.classList.contains('mobile-view')) {
                this.search();
                elem.classList.remove('mobile-view');
                this.removeEventsSearch();
            } else {
                elem.classList.add('mobile-view');
                this.addEventsSearch();
            }
        } else {
            this.search();
        }
    };
    addEventsSearch = () => {
        document.addEventListener(
            'click',
            this.handleDocumentClickSearch,
            true
        );
    };
    removeEventsSearch = () => {
        document.removeEventListener(
            'click',
            this.handleDocumentClickSearch,
            true
        );
    };

    handleDocumentClickSearch = (e) => {
        let isSearchClick = false;
        if (
            e.target &&
            e.target.classList &&
            (e.target.classList.contains('navbar') ||
                e.target.classList.contains('simple-icon-magnifier'))
        ) {
            isSearchClick = true;
            if (e.target.classList.contains('simple-icon-magnifier')) {
                this.search();
            }
        } else if (
            e.target.parentElement &&
            e.target.parentElement.classList &&
            e.target.parentElement.classList.contains('search')
        ) {
            isSearchClick = true;
        }

        if (!isSearchClick) {
            const input = document.querySelector('.mobile-view');
            if (input && input.classList) input.classList.remove('mobile-view');
            this.removeEventsSearch();
            this.setState({
                searchKeyword: '',
            });
        }
    };
    handleSearchInputChange = (e) => {
        this.setState({
            searchKeyword: e.target.value,
        });
    };
    handleSearchInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.search();
        }
    };

    search = () => {
        this.props.history.push(searchPath + '/' + this.state.searchKeyword);
        this.setState({
            searchKeyword: '',
        });
    };

    toggleFullScreen = () => {
        const isInFullScreen = this.isInFullScreen();

        var docElm = document.documentElement;
        if (!isInFullScreen) {
            if (docElm.requestFullscreen) {
                docElm.requestFullscreen();
            } else if (docElm.mozRequestFullScreen) {
                docElm.mozRequestFullScreen();
            } else if (docElm.webkitRequestFullScreen) {
                docElm.webkitRequestFullScreen();
            } else if (docElm.msRequestFullscreen) {
                docElm.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
        this.setState({
            isInFullScreen: !isInFullScreen,
        });
    };

    handleLogout = () => {
        this.props.logoutUser(this.props.history);
    };

    menuButtonClick = (e, menuClickCount, containerClassnames) => {
        e.preventDefault();

        setTimeout(() => {
            var event = document.createEvent('HTMLEvents');
            event.initEvent('resize', false, false);
            window.dispatchEvent(event);
        }, 350);
        if (menuClickCount > 90) {
            this.props.setContainerClassnames(
                0,
                containerClassnames,
                this.props.selectedMenuHasSubItems
            );
        } else {
            this.props.setContainerClassnames(
                ++menuClickCount,
                containerClassnames,
                this.props.selectedMenuHasSubItems
            );
        }
    };

    menuRightButtonClick = (e, menuClickCount, containerClassnames) => {
        e.preventDefault();

        setTimeout(() => {
            var event = document.createEvent('HTMLEvents');
            event.initEvent('resize', false, false);
            window.dispatchEvent(event);
        }, 350);

        this.setState(
            (prev) => ({
                ...prev,
                itemMenuRightClickCount:
                    this.state.itemMenuRightClickCount === 99 ? -99 : 99,
            }),
            () => {
                this.props.setContainerClassnames(
                    this.state.itemMenuRightClickCount,
                    containerClassnames,
                    this.props.selectedMenuHasSubItems
                );
            }
        );
    };

    mobileMenuButtonClick = (e, containerClassnames) => {
        e.preventDefault();
        this.props.clickOnMobileMenu(containerClassnames);
    };

    renderMenuIcon = (e) => {
        const store = window.location.host.split('.')[0];

        if (store === 'faq') {
            return '/root/faq/dashboards';
        }
        if (store === 'message') {
            return '/root/message/dashboards';
        }
        if (store === 'upgrade') {
            return '/root/upgrade/dashboards';
        }
        return '#';
    };

    render() {
        const { containerClassnames, menuClickCount } = this.props;
        const store = window.location.host.split('.')[0];
        const pathname = this.props.history.location.pathname;
        const PATH_PROFILE = '/app/applications/profile';
        const PATH_FAQ = '/root/faq/dashboards';
        const PATH_MESSAGE = '/root/message/dashboards';
        const PATH_UPGRADE = '/root/upgrade/dashboards';

        const ROLESETTINGS = localStorage.getItem('roleSettings')
            ? JSON.parse(localStorage.getItem('roleSettings'))
            : null;

        if (
            store === 'faq' &&
            PATH_PROFILE !== pathname &&
            PATH_FAQ !== pathname
        ) {
            return <Redirect to='/root/faq/dashboards' />;
        } else if (
            store === 'message' &&
            PATH_PROFILE !== pathname &&
            PATH_MESSAGE !== pathname
        ) {
            return <Redirect to='/root/message/dashboards' />;
        } else if (
            store === 'upgrade' &&
            PATH_PROFILE !== pathname &&
            PATH_UPGRADE !== pathname
        ) {
            return <Redirect to='/root/upgrade/dashboards' />;
        } else if (ROLESETTINGS && ROLESETTINGS.length) {
            const find_payment = ROLESETTINGS.find(
                (_role) => _role.role_alias === 'table_pending_payment'
            );
            if (typeof find_payment != 'undefined') {
                return <Redirect to='/payment' />;
            }
        } else if (ROLESETTINGS && ROLESETTINGS.length) {
            const find_kitchen = ROLESETTINGS.find(
                (_role) => _role.role_alias === 'kitchen'
            );
            if (typeof find_kitchen != 'undefined') {
                return <Redirect to='/kitchen' />;
            }
        } else if (ROLESETTINGS && ROLESETTINGS.length) {
            const find_cashier = ROLESETTINGS.find(
                (_role) => _role.role_alias === 'cashier'
            );
            if (typeof find_cashier != 'undefined') {
                return <Redirect to='/cashier' />;
            }
        }

        return (
            <nav className='navbar fixed-top'>
                <audio
                    id='alert-mp3'
                    preload='auto'
                    src='/assets/sound/alert.mp3'
                ></audio>
                <div className='d-flex align-items-center navbar-left'>
                    {store === 'faq' ||
                    store === 'message' ||
                    store === 'upgrade' ? (
                        <>
                            <NavLink
                                to={this.renderMenuIcon()}
                                className='menu-button d-none d-md-block'
                            >
                                <MenuIcon />
                            </NavLink>
                            <NavLink
                                to={this.renderMenuIcon()}
                                className='menu-button-mobile d-xs-block d-sm-block d-md-none'
                            >
                                <MobileMenuIcon />
                            </NavLink>
                        </>
                    ) : (
                        <>
                            <NavLink
                                to='#'
                                className='menu-button d-none d-md-block'
                                onClick={(e) =>
                                    this.menuButtonClick(
                                        e,
                                        menuClickCount,
                                        containerClassnames
                                    )
                                }
                            >
                                <MenuIcon />
                            </NavLink>
                            <NavLink
                                to='#'
                                className='menu-button-mobile d-xs-block d-sm-block d-md-none'
                                onClick={(e) =>
                                    this.mobileMenuButtonClick(
                                        e,
                                        containerClassnames
                                    )
                                }
                            >
                                <MobileMenuIcon />
                            </NavLink>
                            {/* <div className='d-inline-block'>
                                <UncontrolledDropdown className='ml-2'>
                                    <DropdownToggle
                                        caret
                                        color='light'
                                        size='sm'
                                        className='language-button'
                                    >
                                        <span className='name'>
                                            {locale.toUpperCase()}
                                            Việt Nam
                                        </span>
                                    </DropdownToggle>
                                    <DropdownMenu className='mt-3' right>
                                        {localeOptions.map(l => {
                                            return (
                                                <DropdownItem
                                                onClick={() => this.handleChangeLocale(l.id, l.direction)}
                                                key={l.id}
                                                >
                                                {l.name}
                                                </DropdownItem>
                                            );
                                        })}
                                        <DropdownItem>Japan</DropdownItem>
                                        <DropdownItem>English</DropdownItem>
                                        <DropdownItem>France</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div> */}
                        </>
                    )}
                </div>
                <NavLink className='navbar-logo' to='/app/dashboards/default'>
                    <span className='logo d-block' />
                    {/* <span className='logo-mobile d-block d-xs-none' /> */}
                </NavLink>

                <div className='navbar-right'>
                    {isDarkSwitchActive && <TopnavDarkSwitch />}
                    <div className='header-icons d-inline-block align-middle'>
                        <TopnavNotifications />
                        <button
                            className='header-icon btn btn-empty d-none d-sm-inline-block'
                            type='button'
                            id='fullScreenButton'
                            onClick={this.toggleFullScreen}
                        >
                            {this.state.isInFullScreen ? (
                                <i className='simple-icon-size-actual d-block' />
                            ) : (
                                <i className='simple-icon-size-fullscreen d-block' />
                            )}
                        </button>
                    </div>
                    <div className='user mr-3 d-inline-block'>
                        <UncontrolledDropdown className='dropdown-menu-right'>
                            <DropdownToggle className='p-0' color='empty'>
                                <span className='name mr-1 text-capitalize'>
                                    {this.state.companyName}
                                </span>
                                <span>
                                    <img
                                        alt=''
                                        src={
                                            localStorage.getItem(
                                                'companyLogo'
                                            ) !== 'null' &&
                                            localStorage.getItem(
                                                'companyLogo'
                                            ) !== 'undefined.'
                                                ? END_POINT +
                                                  '/public/' +
                                                  localStorage.getItem(
                                                      'companyLogo'
                                                  )
                                                : '/assets/img/logo.jpeg'
                                        }
                                    />
                                </span>
                            </DropdownToggle>
                            <DropdownMenu className='mt-3' right>
                                <NavLink to='/app/applications/profile'>
                                    <DropdownItem>
                                        {/* <i className='iconsminds-male mr-2' /> */}
                                        <span>
                                            <img
                                                alt=''
                                                src={
                                                    localStorage.getItem(
                                                        'companyLogo'
                                                    ) !== 'null' &&
                                                    localStorage.getItem(
                                                        'companyLogo'
                                                    ) !== 'undefined.'
                                                        ? END_POINT +
                                                          '/public/' +
                                                          localStorage.getItem(
                                                              'companyLogo'
                                                          )
                                                        : '/assets/img/logo.jpeg'
                                                }
                                                className='img-account'
                                            />
                                        </span>
                                        Tài khoản
                                    </DropdownItem>
                                </NavLink>
                                <DropdownItem divider />
                                {/* <NavLink to='/cashier/default'>
                                    <DropdownItem>
                                        <i className='iconsminds-cash-register-2 mr-2'></i>
                                        Thu ngân
                                    </DropdownItem>
                                </NavLink> */}
                                <NavLink to='/payment/default'>
                                    <DropdownItem>
                                        <i className='iconsminds-business-woman mr-2'></i>
                                        Chạy bàn
                                    </DropdownItem>
                                </NavLink>
                                <NavLink to='/kitchen/default'>
                                    <DropdownItem>
                                        <i className='iconsminds-chef-hat mr-2'></i>
                                        Bếp / Pha chế
                                    </DropdownItem>
                                </NavLink>
                                <DropdownItem divider />
                                <DropdownItem
                                    onClick={() => this.handleLogout()}
                                >
                                    <i className='iconsminds-power-2 mr-2' />
                                    Đăng xuất
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    {ROLESETTINGS == null ? (
                        <div
                            className='cursor-pointer setting d-inline-block align-middle mr-3'
                            onClick={(e) =>
                                this.menuRightButtonClick(
                                    e,
                                    menuClickCount,
                                    containerClassnames
                                )
                            }
                        >
                            <i className='simple-icon-menu' />
                            {/* <Setting /> */}
                        </div>
                    ) : null}
                </div>
            </nav>
        );
    }
}

const mapStateToProps = ({ menu, settings }) => {
    const { containerClassnames, menuClickCount, selectedMenuHasSubItems } =
        menu;
    const { locale } = settings;
    return {
        containerClassnames,
        menuClickCount,
        selectedMenuHasSubItems,
        locale,
    };
};
export default injectIntl(
    connect(mapStateToProps, {
        setContainerClassnames,
        clickOnMobileMenu,
        logoutUser,
        changeLocale,
    })(TopNav)
);
