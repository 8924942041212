import React, { memo } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AddCommaNumber } from '../../../helpers/Utils';
import { Creatable } from 'react-select';

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    values,
    setting,
    onChange,
    onChangePrice,
}) => {
    // console.log('render AddComponent');

    let items_select = [];
    if (values?.log?.length > 0) {
        const reducer = (a, o) => (
            a.push({
                label: o.name,
                value: o.id,
            }),
            a
        );
        items_select = values?.log
            ?.filter((_item) => _item.alias === 'new')
            .reduce(reducer, []);
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        <FormGroup className='mt-3 has-float-label'>
                            <Label for='item_name' className='text-muted'>
                                <IntlMessages id='company-provider.item_name' />
                            </Label>
                            <Creatable
                                className={'react-select-creatable'}
                                placeholder={'Chọn hoặc tạo mới'}
                                // isMulti
                                name='items_selected'
                                id='items_selected'
                                onChange={(val) =>
                                    onChange(val, 'items_selected')
                                }
                                options={items_select}
                            />
                        </FormGroup>

                        <FormGroup className='has-float-label'>
                            <Label for='price_income' className='text-muted'>
                                <IntlMessages id='company-provider.total_price_income' />
                            </Label>
                            <Input
                                name='price_income'
                                id='price_income'
                                value={AddCommaNumber(values.price_income)}
                                onChange={onChangePrice}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='price_outcome' className='text-muted'>
                                <IntlMessages id='company-provider.total_price_outcome' />
                            </Label>
                            <Input
                                name='price_outcome'
                                id='price_outcome'
                                value={AddCommaNumber(values.price_outcome)}
                                onChange={onChangePrice}
                            />
                        </FormGroup>
                        <FormGroup className='has-float-label'>
                            <Label for='price_pending' className='text-muted'>
                                <IntlMessages id='company-provider.total_price_pending' />
                            </Label>
                            <Input
                                type='text'
                                name='price_pending'
                                id='price_pending'
                                disabled={true}
                                value={AddCommaNumber(values.price_pending)}
                            />
                        </FormGroup>
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='general.btn-add' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default memo(ContentComponent);
