import React, { Component, Fragment } from 'react';
import TableChairIcon from './TableChairIcon';
import { isF } from '../../helpers/Roles';

const ROLE_ALIAS = 'table_setting';

const isInvisible = (position, x, y) => {
    return position.some((p) => p.x === x && p.y === y);
};

const getItemFromPosition = (position, x, y) => {
    return position.find((p) => p.x === x && p.y === y);
};

const getIdentify = () => {
    let tableData = localStorage.getItem('table');
    if (tableData) {
        tableData = JSON.parse(tableData);

        const all_identify = tableData.map((_table) => _table.identify);
        // console.log('all_identify: ', all_identify);
        const MAX = Math.max(...all_identify);
        // console.log('MAX: ', MAX);

        // Tìm ra các số bị miss
        for (let i = 1; i <= MAX; i++) {
            const find_table = tableData.some(
                (_table) => _table.identify === i
            );

            if (!find_table) {
                return i;
            }
        }

        if (MAX === -Infinity) return 1;

        return parseInt(MAX) + 1;
    }

    return 1;
};

export default class ItemSetting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            position: [],
            checkboxChecked: isInvisible(props.position, props.x, props.y),
            item: getItemFromPosition(props.position, props.x, props.y) || {
                x: props.x,
                y: props.y,
            },
            isLoading: false,
            identify: '',
        };

        this.select = this.select.bind(this);
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
            identify: this.state.item.identify,
        });
        // localStorage.setItem("table", [])
    }

    /**
     *
     * CHAIR ICON && DATA MAP
     *
     */
    select(e) {
        if (!isF(ROLE_ALIAS)) return false;

        let { item } = this.state;

        if (item) {
            let position = localStorage.getItem('table')
                ? JSON.parse(localStorage.getItem('table'))
                : [];

            let _item = item;
            _item.x = item.x;
            _item.y = item.y;

            // Chưa lưu
            if (!isInvisible(position, item.x, item.y)) {
                const next_identify = getIdentify();
                _item.identify = next_identify;

                position.push(_item);

                localStorage.setItem('table', JSON.stringify(position));
                // localStorage.setItem('all_table', JSON.stringify(position));
            }
            // Đã lưu rồi
            else {
                _item.identify = '';

                let removeItem = position.filter(
                    (p) => p.x !== item.x || p.y !== item.y
                );
                localStorage.setItem('table', JSON.stringify(removeItem));
                // localStorage.setItem('all_table', JSON.stringify(removeItem));
            }
            this.setState({
                identify: _item.identify,
            });
        }
    }

    render() {
        let { isLoading, identify } = this.state;

        return !isLoading ? (
            <div className='loading' />
        ) : (
            <Fragment>
                <div
                    className='unselect table-item-setting'
                    onClick={() => {
                        this.select();
                    }}
                >
                    <TableChairIcon id_table={identify} theme={'default'} />
                </div>
            </Fragment>
        );
    }
}
