import React from 'react';

let UpgradeTimeMapping = new Map();
UpgradeTimeMapping.set('1m', '1 tháng');
UpgradeTimeMapping.set('2m', '2 tháng');
UpgradeTimeMapping.set('6m', '6 tháng');
UpgradeTimeMapping.set('1y', '1 năm');
UpgradeTimeMapping.set('2y', '2 năm');
UpgradeTimeMapping.set('5y', '5 năm');
UpgradeTimeMapping.set('forever', 'Trọn đời');

const ThumbListViewMenu = ({ item, handleToggleModal }) => {
    // console.log('item: ', item);
    return (
        <div className={'d-flex flex-grow-1 min-width-zero custom-line'}>
            <div className='align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center card-body pad-1rem'>
                <p className='list-item-heading mb-0 truncate w-20 w-xs-100 text-center'>
                    <span className='btn btn-empty btn-lg active'>
                        {item.user.fullname}
                    </span>
                </p>
                <p className='list-item-heading mb-0 truncate w-20 w-xs-100 text-center'>
                    <span className='btn btn-empty btn-lg active'>
                        {item.user.companyName}
                    </span>
                </p>
                <p className='list-item-heading mb-0 truncate w-15 w-xs-100 text-center'>
                    {item.pending ? (
                        <button
                            onClick={(e) => handleToggleModal(e, item)}
                            aria-current='page'
                            className='btn btn-link btn-empty btn-lg active'
                        >
                            <span>{item.user.username}</span>{' '}
                            <i className='simple-icon-arrow-right'></i>
                        </button>
                    ) : (
                        <span className='btn btn-empty btn-lg active'>
                            {item.user.username}
                        </span>
                    )}
                </p>
                <p className='list-item-heading mb-0 truncate w-20 w-xs-100 text-center'>
                    <span className='btn btn-empty btn-lg active'>
                        {item.user.companyUUID}
                    </span>
                </p>
                <p className='mb-0 text-primary w-20 w-xs-100 text-center'>
                    {!item.pending
                        ? item.upgraded
                            ? 'Trọn đời'
                            : item.expired_date + ' - ' + item.expired_time
                        : null}
                </p>
                <p className='mb-0 text-primary w-15 w-xs-100 text-center'>
                    {item.pending || item.pending_date !== null
                        ? UpgradeTimeMapping.get(item.pending_date)
                        : null}
                </p>
            </div>
        </div>
    );
};

export default ThumbListViewMenu;
