import React, { Component } from 'react';
import TableChairIcon from './TableChairIcon';

const isInvisible = (position, x, y) => {
    return position.some((p) => p.x === x && p.y === y);
};

const getItemFromPosition = (position, x, y) => {
    return position.find((p) => p.x === x && p.y === y);
};

export default class ItemSetting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            position: [],
            checkboxChecked: isInvisible(props.position, props.x, props.y),
            item: getItemFromPosition(props.position, props.x, props.y) || {
                x: props.x,
                y: props.y,
            },
        };
    }

    /**
     *
     * CHAIR ICON && DATA MAP
     *
     */
    select = (e) => {
        let { item } = this.state;

        if (item) {
            this.props.selectTableTransfer(item);
        }
    };

    render() {
        let { checkboxChecked, item } = this.state;

        return (
            <div
                className='table-item-setting'
                onClick={() => {
                    this.select();
                }}
            >
                {checkboxChecked ? (
                    <TableChairIcon item={item} theme={'default'} />
                ) : (
                    <TableChairIcon theme={'hide'} />
                )}
            </div>
        );
    }
}
