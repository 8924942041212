import React from 'react';
import { Card, CardBody, CardTitle, Label, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { GetSelectTime } from '../../../helpers/SelectTime';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentDisplay = (props) => {
    // console.log(props);
    return (
        <Card className='position-sticky-custom mb-4'>
            <CardBody>
                <CardTitle>
                    <IntlMessages id='treasury.title' />
                </CardTitle>
                <Row className='mt-3 '>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='treasury.name' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.name ? (
                                props.values.name
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='price' />
                        </p>
                        <p className='mb-3'>
                            {AddCommaNumber(props.values.value)}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='12'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='note' />
                        </p>
                        <p className='mb-3'>
                            {props.values.note
                                ? props.values.note
                                : '(chưa có thông tin)'}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='treasury.timeCreated' />
                        </p>
                        <p className='mb-3'>{props.values.at}</p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='time' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {GetSelectTime(props.values.time).label}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            <IntlMessages id='user.created' />
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.user}
                        </p>
                    </Colxx>
                </Row>
            </CardBody>
        </Card>
    );
};

export default ContentDisplay;
