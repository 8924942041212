import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AppLayoutCashier from '../../layout/AppLayoutCashier';
import defaultContent from './default';

const ContentComponent = ({ match }) => (
    <AppLayoutCashier>
        <Switch>
            <Redirect
                exact
                from={`${match.url}/`}
                to={`${match.url}/default`}
            />
            <Route path={`${match.url}/default`} component={defaultContent} />
            <Route path={`${match.url}`} component={defaultContent} />
            <Redirect to='/error' />
        </Switch>
    </AppLayoutCashier>
);
export default ContentComponent;
