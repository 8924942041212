export const SetSelectTime = () => {
  return [
    {
      label: "Không đưa vào thống kê",
      value: "since"
    },
    {
      label: "Đưa vào thống kê",
      value: "now"
    },
  ]
}

export const GetSelectTime = (time) => {
  if (time === 'since') {
    return {
      label: "Không đưa vào thống kê",
      value: "since"
    }
  }
  if (time === 'now') {
    return {
      label: "Đưa vào thống kê",
      value: "now"
    }
  }

  return null
} 