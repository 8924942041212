import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Row, Button } from 'reactstrap';
import axios from 'axios';
import { END_POINT } from '../../../constants/defaultValues';
import { NotificationManager } from '../../../components/common/react-notifications';
import { Separator, Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import GlideComponentCustom from '../../../components/carousel/GlideComponentCustom';
import io from 'socket.io-client';
import { END_POINT_SOCKET } from '../../../constants/defaultValues';
import smoothscroll from 'smoothscroll-polyfill';
import {
    PageHeading,
    ThumbListViewMenuGuest,
    ProductOrderModalMenuGuest,
} from '../../../containers/pages/guest';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    getCompanyImage,
    AddCommaNumber,
    RandomNumber,
} from '../../../helpers/Utils';
import ReactDOM from 'react-dom';

smoothscroll.polyfill();

window.smoothScrollTo = function (endX, endY, duration) {
    let startX = window.scrollX || window.pageXOffset,
        startY = window.scrollY || window.pageYOffset,
        distanceX = endX - startX,
        distanceY = endY - startY,
        startTime = new Date().getTime();

    // Easing function
    let easeInOutQuart = function (time, from, distance, duration) {
        if ((time /= duration / 2) < 1)
            return (distance / 2) * time * time * time * time + from;
        return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
    };

    let timer = window.setInterval(function () {
        let time = new Date().getTime() - startTime,
            newX = easeInOutQuart(time, startX, distanceX, duration),
            newY = easeInOutQuart(time, startY, distanceY, duration);
        if (time >= duration) {
            window.clearInterval(timer);
        }
        window.scrollTo(newX, newY);
    }, 1000 / 60); // 60 fps
};

class ProductGuest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTabDetail: false,
            messageInput: '',
            displayMode: 'thumblist',
            categories: [
                // { label: "Tạo mới", value: "", key: 0 },
            ],
            products: [
                // { label: "Tạo mới", value: "", key: 0 },
            ],

            // selectedOrderOption: { column: "name", label: "Tên" },
            dropdownSplitOpen: false,
            categoryId: 0,
            search: '',
            items: [],
            isLoading: false,

            orders: [
                // {
                //   "id": 7,
                //   "name": "234423",
                //   "price": "130000",
                //   "count": 2,
                //   "note": ""
                //   "noteDisplay": false
                // }
            ],
            modalOrderOpen: false,
            store: window.location.host.split('.')[0],
            hash: props.hash,
            user_identify: props.user_identify,
            table_id: props.table_id,
            customer_phone: '',
            customer_name: '',
            company: {
                companyName: localStorage.getItem('companyName'),
                companyAddress: localStorage.getItem('companyAddress'),
                companyImage: localStorage.getItem('companyImage'),
            },
            isVerify: true,
            startAt: 0,
            reMount: false,
            isFirstLoad: true,
            isCreatedAlert: false,

            table_chair: {
                pin_id: undefined,
                id: undefined,
                floor: undefined,
                identify: undefined,
            },
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onToggleNote = this.onToggleNote.bind(this);
        this.listenToScroll = this.listenToScroll.bind(this);

        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        // exists user_link
        if (this.state.user_identify) {
            // not null
            if (this.state.user_identify.indexOf('null') < 0) {
                localStorage.setItem('user_link', this.state.user_identify);
            }
        } else {
            if (localStorage.getItem('user_link')) {
                localStorage.removeItem('user_link');
            }
        }

        // verify store and hash
        this.isVerifyHashAndTime();

        // working, loading data
        this.dataListRender();

        ReactDOM.findDOMNode(this).addEventListener(
            'scroll',
            this.listenToScroll
        );
    }

    componentWillUnmount() {
        ReactDOM.findDOMNode(this).removeEventListener(
            'scroll',
            this.listenToScroll
        );

        this.socket.close();
    }

    listenToScroll() {
        const menu_container = document.getElementsByClassName(
            'scrollbar-container'
        )[4];
        const winScroll = menu_container.scrollTop;

        let nav_items = document.getElementsByClassName('nav_items');
        if (nav_items.length < 1) return false;

        let item = null;
        let itemp = null;
        if (winScroll > 10 && winScroll <= 450) {
            for (itemp of nav_items) {
                itemp.classList.remove('active');
            }
            nav_items.item(0).classList.add('active');
        }

        if (winScroll > 450) {
            let offset = 90;

            let nav_len = nav_items.length;
            for (let i = 0; i < nav_len; i++) {
                let id = nav_items.item(i).id;
                item = document.getElementById('item_' + id);

                let posItem = item ? item.getBoundingClientRect().top : 10;
                if (posItem >= 0 && posItem <= offset) {
                    // active item
                    for (itemp of nav_items) {
                        itemp.classList.remove('active');
                    }

                    nav_items.item(i).classList.add('active');

                    // startAt glide
                    if (nav_len <= 3 || i === 0 || i === 1) {
                        this.setState({ startAt: 0 });
                    } else if (i < nav_len - 1) {
                        this.setState({ reMount: true, startAt: i - 1 });
                    }
                }

                // you're at the bottom of the page
                if (
                    menu_container.innerHeight + menu_container.scrollY >=
                    menu_container.offsetHeight
                ) {
                    for (itemp of nav_items) {
                        itemp.classList.remove('active');
                    }

                    nav_items.item(nav_len - 1).classList.add('active');
                }
            }
        }
    }

    isVerifyHashAndTime() {
        let { store, hash, table_id } = this.state;
        return axios
            .get(
                `${
                    END_POINT + '/product/guest/verify'
                }?store=${store}&hash=${hash}&tableId=${table_id}`
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                // console.log(data);
                if (!data.data) {
                    this.setState({ isVerify: false });
                } else {
                    const { table_chair } = data;

                    if (table_chair) {
                        const { id, floor, identify } = table_chair;

                        this.setState((prev) => ({
                            table_chair: {
                                ...prev.table_chair,
                                floor,
                                identify,
                                id,
                                pin_id: `${RandomNumber(5)}`,
                            },
                        }));
                    } else {
                        this.setState({ isVerify: false });
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isVerify: false });
            });
    }

    offset(el) {
        const menu_container = document.getElementsByClassName(
            'scrollbar-container'
        )[4];

        let rect = el.getBoundingClientRect(),
            scrollLeft =
                menu_container.pageXOffset || menu_container.scrollLeft,
            scrollTop = menu_container.pageYOffset || menu_container.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }

    scrollToMyRef(e) {
        // console.log('id: ', typeof e.target.id);
        let pos = this.offset(document.getElementById('item_' + e.target.id));

        document.getElementsByClassName('scrollbar-container')[4].scroll({
            top: pos.top - 75,
            left: 0,
            behavior: 'smooth',
        });

        // if ('scrollBehavior' in document.documentElement.style) { //Checks if browser supports scroll function
        //   console.log("1")

        //   document.getElementsByClassName("scrollbar-container")[4].scroll({
        //     top: pos.top - 75,
        //     left: 0,
        //     behavior: 'smooth',
        //   });
        // } else {
        //   console.log("2")
        //   document.getElementsByClassName("scrollbar-container")[4].querySelector('#item_' + e.target.id).scrollIntoView({
        //     behavior: 'smooth',
        //     block: 'nearest',
        //     inline: 'start'
        //   });
        // }
    }

    renderCategories() {
        return this.state.items.map((categories, index) => (
            <p
                onClick={(e) => {
                    this.scrollToMyRef(e);
                }}
                key={categories.id}
                id={index}
                className='nav_items cursor-pointer'
            >
                {categories.name}
            </p>
        ));
    }

    onSearchKey = (e) => {
        if (e.key === 'Enter') {
            this.setState(
                {
                    search: e.target.value.toLowerCase(),
                },
                () => this.dataListRender()
            );
        }
    };

    onSearchChange = (e) => {
        this.setState(
            {
                search: e.target.value.toLowerCase(),
            },
            () => this.dataListRender()
        );
    };

    dataListRender() {
        const { categoryId, search, store, hash } = this.state;

        if (!store || !hash || hash === null) return false;

        axios
            .get(
                `${
                    END_POINT + '/product/guest'
                }?store=${store}&categoryId=${categoryId}&search=${search}`
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                // console.log(data);
                let menu = [];

                // best seller - goi y mon ngon
                let template = {
                    id: 0,
                    name: 'Khuyên dùng',
                    data: data.data.filter((p) => !!p.bestter),
                };
                menu.push(template);

                // group by category
                let category_id = [];
                data.data
                    .filter((p) => !p.bestter)
                    .forEach((p) => {
                        if (!category_id.includes(p.category.id)) {
                            category_id.push(p.category.id);

                            template = {
                                id: p.category.id,
                                name: p.category.name,
                                data: [],
                            };
                            template.data.push(p);
                            menu.push(template);
                        } else {
                            menu.forEach((m) => {
                                if (p.category.id === m.id) {
                                    m.data.push(p);
                                }
                            });
                        }
                    });

                const { id, pin_id } = this.state.table_chair;

                // messages
                this.socket.emit('join room', {
                    room_id: `${pin_id}.${id}`,
                });
                this.socket.emit('message get conversation guest', {
                    user_id: localStorage.getItem('user_id'),
                    pin_id,
                    sender_id: pin_id,
                    table_chair_id: id,
                });

                // state
                this.setState(
                    {
                        totalPage: data.totalPage,
                        items: menu, //data.data,
                        totalItemCount: data.totalItem,
                        isLoading: true,
                        startAt: 0,
                    },
                    () => {
                        // const menu_container = document.getElementsByClassName("scrollbar-container")[4];
                        // if (menu_container) {
                        //   ReactDOM.findDOMNode(menu_container).addEventListener('scroll', this.listenToScroll)
                        // }
                    }
                );
            })
            .catch((err) => {
                NotificationManager.error(
                    err.response.data.responseText,
                    'Lỗi !',
                    3000,
                    null,
                    null,
                    'filled'
                );
                return false;
            });
    }

    // ORDER
    toggleModalOrder = () => {
        // thêm class name này mới scroll được modal
        if (this.state.modalOrderOpen) {
            document.body.classList.remove('modal-open');
        } else {
            document.body.classList.add('modal-open');
        }
        this.setState({
            modalOrderOpen: !this.state.modalOrderOpen,
        });
    };
    updateOrders = ({ product, requireCreated = false }) => {
        const find_order = this.state.orders.find(
            (_product) => _product.id === product.id
        );

        // Kiểm tra size, topping, ice, sugar, price_sale, active_sale
        let price = product.price;
        if (product.active_sale) {
            price = product.price_sale;
        }

        // size
        if (product.sizeSelectedId.length) {
            const find_size = product.size.find((_size) =>
                product.sizeSelectedId.includes(_size.id)
            );
            price = parseFloat(price) + parseFloat(find_size.value);
        }
        // topping
        if (product.toppingSelectedId.length) {
            const find_topping = product.topping.filter((_size) =>
                product.toppingSelectedId.includes(_size.id)
            );

            for (let i = 0; i < find_topping.length; i++) {
                price = parseFloat(price) + parseFloat(find_topping[i].value);
            }
        }

        // Update giá tiền nếu đã tồn tại
        if (find_order) {
            const orders = this.state.orders.map((o) =>
                o.id === find_order.id
                    ? {
                          ...o,
                          price: price,
                          note: product.note,
                          iceSelectedValue: product.iceSelectedValue,
                          sugarSelectedValue: product.sugarSelectedValue,
                          sizeSelectedId: product.sizeSelectedId,
                          toppingSelectedId: product.toppingSelectedId,
                      }
                    : o
            );

            this.setState({ orders });
        } else {
            /* Tạo mới nếu chưa có
             *
             * Chỉ tạo mới khi click nút thêm sản phẩm, input hoặc nhấn nút +, -
             */
            let orders = this.state.orders;

            if (requireCreated) {
                orders.push({
                    id: product.id,
                    name: product.name,
                    price: price,
                    count: 1,
                    note: product.note,
                    iceSelectedValue: product.iceSelectedValue,
                    sugarSelectedValue: product.sugarSelectedValue,
                    sizeSelectedId: product.sizeSelectedId,
                    toppingSelectedId: product.toppingSelectedId,
                    previewDisplay: false,
                });
            }
            this.setState({ orders });
        }
    };

    plus = (id) => {
        let order = this.getOrder(id);

        if (!order) {
            // init

            const product = this.getProductInfo(id);
            if (!product) return false;

            this.updateOrders({ product, requireCreated: true });
        } else {
            // plus
            const orders = this.state.orders.map((o) =>
                o.id === order.id ? { ...o, count: o.count + 1 } : o
            );

            this.setState({ orders });
        }
    };
    sub = (id) => {
        let order = this.getOrder(id);

        if (!order) return false;

        // sub
        const orders = this.state.orders
            .map((o) =>
                o.id === order.id
                    ? { ...o, count: o.count - 1 >= 0 ? o.count - 1 : 0 }
                    : o
            )
            .filter((order) => order.count > 0);

        this.setState({ orders });
    };
    change = (e, id) => {
        if (!e || !e.target) return false;

        let { value } = e.target;
        let order = this.getOrder(id);

        value = parseInt(value);

        if (!order) {
            // init
            const product = this.getProductInfo(id);
            if (!product) return false;

            this.updateOrders({ product, requireCreated: true });
        } else {
            // change
            const orders = this.state.orders
                .map((o) =>
                    o.id === order.id
                        ? { ...o, count: value >= 0 ? value : 0 }
                        : o
                )
                .filter((order) => order.count > 0);

            this.setState({ orders });
        }
    };

    onChange = (e) => {
        if (e.target) {
            const {
                target: { name, value },
            } = e;

            this.setState((prev) => ({
                ...prev,
                [name]: value,
            }));
        } else {
        }
    };

    onToggleNote(id) {
        const orders = this.state.orders.map((o) =>
            o.id === id ? { ...o, noteDisplay: !o.noteDisplay } : o
        );

        this.setState({ orders });
    }

    onRemoveOrder = (id) => {
        let orders = this.state.orders.filter((_order) => _order.id !== id);

        this.setState({ orders });
    };

    onToggleDetail = ({ product, name, value }) => {
        const products = this.state.items;
        let find_product = products.map((categories) =>
            categories.data.find(
                (_product) => _product.id === product.id && !_product.softHide
            )
        );

        if (find_product) {
            find_product = find_product.find(function (el) {
                return el != null;
            });
        }

        if (find_product) {
            // toggle hiển thị hoặc không
            if (name === 'detailDisplay') {
                find_product[name] = !find_product[name];

                this.setState((prev) => ({
                    ...prev,
                    items: products,
                }));
            } else if (name === 'orderDisplay') {
                find_product[name] = !find_product[name];

                this.setState(
                    (prev) => ({
                        ...prev,
                        items: products,
                    }),
                    () =>
                        this.updateOrders({
                            product: find_product,
                            requireCreated: true,
                        })
                );
            }

            // check vào các giá trị size, topping, ice, sugar
            else {
                let _value = value;

                // size và topping thì cho mảng các giá trị
                // Vì Topping thì có thể mix nhiều loại
                // Size thì luôn luôn required 1 loại
                if (name === 'sizeSelectedId' || name === 'toppingSelectedId') {
                    if (name === 'sizeSelectedId') {
                        _value = [_value];
                    } else if (find_product[name].includes(value)) {
                        _value = find_product[name].filter(
                            (_item) => _item !== value
                        );
                    } else if (name === 'toppingSelectedId') {
                        find_product[name].push(_value);
                        _value = find_product[name];
                    }
                }

                find_product[name] = _value;

                this.setState(
                    (prev) => ({
                        ...prev,
                        items: products,
                    }),
                    () => this.updateOrders({ product: find_product })
                );
            }
        }
    };

    handleSubmit() {
        if (this.state.orders.length < 1) {
            NotificationManager.error(
                'Chưa đặt sản phẩm. Thêm ít nhất 1 sản phẩm',
                'Lỗi !',
                3000,
                null,
                null,
                'filled'
            );
            return false;
        }

        // axios submit
        axios
            .post(`${END_POINT + '/bill/guest'}`, {
                data: this.state.orders,
                store: this.state.store,
                phone: this.state.customer_phone,
                name: this.state.customer_name,
                hash: this.state.hash || '',
                table_id: this.state.table_id || '',
            })
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                // console.log('data: ', data);
                NotificationManager.primary(
                    data.responseText,
                    '',
                    3000,
                    null,
                    null,
                    'filled'
                );

                const { bill_id } = data;

                // real-time
                this.socket.emit('refresh-bestseller', {
                    user_id: localStorage.getItem('user_id'),
                    type: localStorage.getItem('selectedDropdownBestSeller'),
                });
                this.socket.emit('refresh-recent-order', {
                    user_id: localStorage.getItem('user_id'),
                    floor: 0,
                    user_identify: this.state.user_identify || '',
                    table_id: this.state.table_id || '',
                });
                this.socket.emit('refresh-pending-order', {
                    user_id: localStorage.getItem('user_id'),
                });
                this.socket.emit('refresh-done-order', {
                    user_id: localStorage.getItem('user_id'),
                });
                this.socket.emit('refresh-refund-order', {
                    user_id: localStorage.getItem('user_id'),
                });

                // refresh alert data
                this.socket.emit('refresh-alert-data', {
                    user_id: localStorage.getItem('user_id'),
                });

                // re-mind order after 2 minutes
                this.socket.emit('remind-created-orders', {
                    data: this.state.orders,
                    user_id: localStorage.getItem('user_id'),
                    bill_id,
                });

                // Accept scroll body
                document.body.classList.remove('noscroll');
                document.body.classList.remove('modal-open');

                this.setState({
                    orders: [],
                    orderCount: 1,
                    orderProduct: '',
                    modalOrderOpen: !this.state.modalOrderOpen,
                });
            })
            .catch((err) => {
                NotificationManager.error(
                    err.response.data.responseText,
                    'Lỗi !',
                    3000,
                    null,
                    null,
                    'filled'
                );
            });
    }
    // END ORDER

    getOrder(id) {
        const orders = this.state.orders.filter((order) => order.id === id);
        return orders.length ? orders[0] : null;
    }
    getProductInfo(id) {
        const products = this.state.items
            .map((categories) =>
                categories.data.filter((product) => product.id === id)
            )
            .filter((items) => items.length > 0);

        return products.length > 0 ? products[0][0] : null;
    }

    calTotalPrice = (orders) => {
        let total = 0;
        for (let i = 0; i < orders.length; i++) {
            let order = orders[i];

            total += parseInt(order.price) * order.count;
        }
        return total;
    };

    calcNumberOrder = (orders) => {
        let sum = 0;
        let i = 0;
        let orders_len = orders.length;
        let order = null;
        for (; i < orders_len; i++) {
            order = orders[i];
            sum += order.count;
        }
        return sum;
    };

    incrementItem = (item) => {
        // Find Order
        const find_order = this.state.orders.find(
            (_product) => _product.id === item.id
        );

        // Update giá tiền nếu đã tồn tại
        if (find_order) {
            const orders = this.state.orders.map((o) =>
                o.id === find_order.id
                    ? {
                          ...o,
                          count: o.count + 1,
                          price: item.price,
                          note: item.note,
                          iceSelectedValue: item.iceSelectedValue,
                          sugarSelectedValue: item.sugarSelectedValue,
                          sizeSelectedId: item.sizeSelectedId,
                          toppingSelectedId: item.toppingSelectedId,
                      }
                    : o
            );
            this.setState({ orders });
        }
    };

    decrementItem = (item) => {
        // Find Order
        const find_order = this.state.orders.find(
            (_product) => _product.id === item.id
        );

        // Update giá tiền nếu đã tồn tại
        if (find_order.count > 1) {
            const orders = this.state.orders.map((o) =>
                o.id === find_order.id
                    ? {
                          ...o,
                          count: o.count - 1,
                          price: item.price,
                          note: item.note,
                          iceSelectedValue: item.iceSelectedValue,
                          sugarSelectedValue: item.sugarSelectedValue,
                          sizeSelectedId: item.sizeSelectedId,
                          toppingSelectedId: item.toppingSelectedId,
                      }
                    : o
            );
            this.setState({ orders });
        }
    };

    render() {
        const {
            items,
            orders,
            customer_phone,
            customer_name,
            modalOrderOpen,
            company,
            isVerify,
            startAt,
            reMount,
        } = this.state;
        // console.log('items: ', items);
        // console.log('orders', this.state.orders);
        // console.log('store', this.state.store);
        // console.log('hash', this.state.hash);
        // console.log('table_id', this.state.table_id);

        return !this.state.isLoading ? (
            <div className='loading' />
        ) : (
            <PerfectScrollbar
                onScrollY={this.listenToScroll}
                options={{ suppressScrollX: true, wheelPropagation: false }}
            >
                <div
                    className='disable-text-selection smooth-scroll-container'
                    id='menu-container'
                >
                    <Row className='custom-row-no-margin'>
                        <Colxx
                            xxs='12'
                            md='12'
                            xl='12'
                            className='col-left custom-col-12-no-padding'
                        >
                            <Card className='mb-4' id='item_home'>
                                {company.companyImage ? (
                                    <img
                                        alt=''
                                        src={getCompanyImage(company)}
                                        className='responsive border-0 card-img-top mb-3 mxh-700'
                                    />
                                ) : (
                                    <img
                                        alt=''
                                        src='/assets/img/balloon.jpg'
                                        className='responsive border-0 card-img-top mb-3 mxh-700'
                                    />
                                )}
                                <CardBody>
                                    <div className='mb-3'>
                                        <div className='menu-guest-header'>
                                            <h5 className='card-title'>
                                                {company.companyName !== 'null'
                                                    ? company.companyName
                                                    : ''}
                                            </h5>
                                        </div>
                                        <p>
                                            {company.companyAddress !== 'null'
                                                ? company.companyAddress
                                                : ''}
                                        </p>
                                    </div>
                                    <Separator className='mb-3' />
                                    <PageHeading
                                        heading='menu.order'
                                        onSearchKey={this.onSearchKey}
                                        onSearchChange={this.onSearchChange}
                                    />
                                </CardBody>
                            </Card>
                        </Colxx>
                    </Row>
                    {items.length ? (
                        <div id='nav_bar' className='sticky'>
                            <GlideComponentCustom
                                settings={{
                                    keyboard: false,
                                    startAt: startAt,
                                    gap: 5,
                                    perView: 3,
                                    type: 'slider',
                                    breakpoints: {
                                        850: { perView: 3 },
                                        1200: { perView: 3 },
                                        1900: { perView: 3 },
                                    },
                                    hideNav: true,
                                    reMount: reMount,
                                }}
                            >
                                {this.renderCategories()}
                            </GlideComponentCustom>
                        </div>
                    ) : null}
                    <Row className='mr-0px'>
                        <Colxx
                            xxs='12'
                            md='12'
                            xl='12'
                            className='col-left custom-col-12-no-padding'
                        >
                            {items.length
                                ? items.map((categories, index) => {
                                      return (
                                          categories.data.length && (
                                              <React.Fragment
                                                  key={categories.id}
                                              >
                                                  <div
                                                      id={'item_' + index}
                                                      className='mb-3 pt-30px custom-shadow'
                                                  >
                                                      <CardBody className='card-body-custom'>
                                                          <Separator className='mb-4' />
                                                          <CardTitle>
                                                              {categories.name}
                                                          </CardTitle>
                                                          {categories.data &&
                                                              categories.data.map(
                                                                  (product) => {
                                                                      return (
                                                                          <ThumbListViewMenuGuest
                                                                              key={
                                                                                  product.id
                                                                              }
                                                                              product={
                                                                                  product
                                                                              }
                                                                              order={this.getOrder(
                                                                                  product.id
                                                                              )}
                                                                              sub={
                                                                                  this
                                                                                      .sub
                                                                              }
                                                                              plus={
                                                                                  this
                                                                                      .plus
                                                                              }
                                                                              change={
                                                                                  this
                                                                                      .change
                                                                              }
                                                                              isVerify={
                                                                                  isVerify
                                                                              }
                                                                              onToggleDetail={
                                                                                  this
                                                                                      .onToggleDetail
                                                                              }
                                                                              detailDisplay={
                                                                                  product.detailDisplay
                                                                              }
                                                                              orderDisplay={
                                                                                  product.orderDisplay
                                                                              }
                                                                              sizeSelectedId={
                                                                                  product.sizeSelectedId
                                                                              }
                                                                              toppingSelectedId={
                                                                                  product.toppingSelectedId
                                                                              }
                                                                              toppingSelectedIdLen={
                                                                                  product
                                                                                      .toppingSelectedId
                                                                                      .length
                                                                              }
                                                                              iceSelectedValue={
                                                                                  product.iceSelectedValue
                                                                              }
                                                                              sugarSelectedValue={
                                                                                  product.sugarSelectedValue
                                                                              }
                                                                              showNote={
                                                                                  product.showNote
                                                                              }
                                                                          />
                                                                      );
                                                                  }
                                                              )}
                                                      </CardBody>
                                                  </div>
                                              </React.Fragment>
                                          )
                                      );
                                  })
                                : null}
                        </Colxx>{' '}
                        <ProductOrderModalMenuGuest
                            isFromMenu={true}
                            isOpen={modalOrderOpen}
                            orders={orders}
                            incrementItem={this.incrementItem}
                            decrementItem={this.decrementItem}
                            phone={customer_phone}
                            name={customer_name}
                            companyName={company.companyName}
                            onChange={this.onChange}
                            onToggleModal={this.toggleModalOrder}
                            onRemoveOrder={this.onRemoveOrder}
                            onToggleNote={this.onToggleNote}
                            handleSubmit={this.handleSubmit}
                            calTotalPrice={this.calTotalPrice}
                        />
                        <div
                            hidden={!this.state.orders.length}
                            className='m-0-auto card-body-custom order-btn-preview order-btn-preview-sticky'
                        >
                            <Button
                                color='primary'
                                size='lg'
                                className='top-right-button pl-3'
                                onClick={this.toggleModalOrder}
                                block
                            >
                                <Row>
                                    <Colxx xxs='5' md='5' xl='5'>
                                        <IntlMessages id='general.btn-order-preview' />{' '}
                                    </Colxx>
                                    <Colxx
                                        xxs='4'
                                        md='4'
                                        xl='4'
                                        className='fw-100'
                                    >
                                        {this.calcNumberOrder(orders) +
                                            ' sản phẩm'}{' '}
                                    </Colxx>
                                    <Colxx xxs='3' md='3' xl='3'>
                                        {AddCommaNumber(
                                            this.calTotalPrice(orders)
                                        )}
                                        <sup className='fz-sup'>đ</sup>
                                    </Colxx>
                                </Row>
                            </Button>
                        </div>
                    </Row>
                </div>
            </PerfectScrollbar>
        );
    }
}
export default ProductGuest;
