import React, { Fragment } from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';

const ContentDisplayMobile = (props) => {
    const removeDuplicate = [
        ...new Set(props.values.settings.map((e) => e.role_alias)),
    ];
    // console.log(props);
    return (
        <>
            <Row className='mt-3' xxs='12'>
                <Colxx xxs='12' lg='6'>
                    <p className='text-muted text-small mb-2'>
                        <IntlMessages id='name' />
                    </p>
                    <p className='text-capitalize'>
                        {props.values.name ? (
                            props.values.name
                        ) : (
                            <span className='text-lowercase'>
                                (Chưa có thông tin)
                            </span>
                        )}
                    </p>
                </Colxx>
                {removeDuplicate.map((el, idx) => {
                    return (
                        <Fragment className='mt-2' key={idx}>
                            <Colxx xxs='4'>
                                <p className='text-muted text-small mb-2'>
                                    {el}
                                </p>
                            </Colxx>
                            <Colxx
                                xxs='8'
                                className='d-flex align-items-center'
                            >
                                {props.values.settings
                                    .filter((e, idx) => {
                                        return e.role_alias === el;
                                    })
                                    .map((item, idx) => {
                                        return (
                                            <Colxx key={idx} xxs='3'>
                                                <p className='text-small mb-2'>
                                                    {item.permission}
                                                </p>
                                            </Colxx>
                                        );
                                    })}
                            </Colxx>
                        </Fragment>
                    );
                })}
            </Row>
        </>
    );
};

export default ContentDisplayMobile;
