import React, { Component, Fragment } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { injectIntl } from 'react-intl';
import { Button, Card, CardBody, CardHeader, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import TableChairs from '../../../containers/dashboards/TableChairs';

import axios from 'axios';
import { END_POINT, END_POINT_SOCKET } from '../../../constants/defaultValues';
import { NotificationManager } from '../../../components/common/react-notifications';
import { ThemeColors } from '../../../helpers/ThemeColors';
import io from 'socket.io-client';
import STATUS from '../../../constants/status';
import {
    getDateWithFormat,
    IsRedirectFromAlert,
    NotificatioErrController,
    // NotificatioSucController,
} from '../../../helpers/Utils';
import LogsHelper from '../../../helpers/Logs';
// import { isRorF, isR } from '../../../helpers/Roles';
import CompaniesRequired from '../../../containers/navs/CompaniesRequired';
import IntlMessages from '../../../helpers/IntlMessages';
import { AddCommaNumber } from '../../../helpers/Utils';
import DiscountTaxFeeModal from '../../../containers/pages/DiscountTaxFeeModal';
// import { AddModalOrderNow as DiscountAddModalOrdersNow } from '../../../containers/pages/discount';
// import { AddModalTaxFeeOrderNow as TaxFeeAddModalOrdersNow } from '../../../containers/pages/tax-fee';
// import { AddModalFeeOtherOrderNow as FeeOtherAddModalOrdersNow } from '../../../containers/pages/fee-other';
import { GetProductsSelectDiscount } from '../../../helpers/Discount';
import FullModalAllOrders from '../../../containers/dashboards/FullModalAllOrders';
import ProductOrderModalMenu from '../../../containers/dashboards/ProductOrderModalMenu';
import ListOrders from './ListOrders';
import FooterOrders from './FooterOrders';
import ListOrderStatus from './ListOrderStatus';
// import classnames from 'classnames';
import { isR, isRorF } from '../../../helpers/Roles';

import BarcodeScannerComponent from 'react-qr-barcode-scanner';

const colors = ThemeColors();

const isCurrentFloor = ({ current, get }) => current === get;

class DefaultDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            license: false,
            USER_PARENT_ID: localStorage.getItem('b'),

            itemsTable: [],
            isLoadingItemsTable: true,
            selectedFloorTable: 100,
            floorsActiveId: [],
            itemsOrderStatus: {
                order: 0,
                totalOrder: 0,
                pending: 0,
                totalPendingPayment: 0,
                tablePendingPayment: 0,
                totalTableHasGuest: 0,
            },
            itemsPending: [],
            itemsDone: [],
            itemsRefund: [],

            itemsPendingBill: [],

            selectedItemsPending: [],
            selectedItemsDone: [],
            selectedItemsRefund: [],

            itemsRecentOrder: [],

            messagesData: [],
            messageDefaultValue: {
                selectedUser: {},
                props: null,
            },
            aboutProps: {
                message_item_id: 0,
                message_user_id: 0,
            },

            cashs: [
                { cash: 1000, quantity: 0 },
                { cash: 2000, quantity: 0 },
                { cash: 5000, quantity: 0 },
                { cash: 10000, quantity: 0 },
                { cash: 20000, quantity: 0 },
                { cash: 50000, quantity: 0 },
                { cash: 100000, quantity: 0 },
                { cash: 200000, quantity: 0 },
                { cash: 500000, quantity: 0 },
            ],
            cashsPay: [],

            store: window.location.host.split('.')[0],
            orders: [],
            itemsChange: [],
            _itemsChange: [],
            orders_fr_server: [],
            ordersId: [],
            productChoose: [],

            customer_name: '',
            customer_phone: '',

            btnDropup: false,

            //invoices
            companyName: localStorage.getItem('companyName'),
            companyAddress: localStorage.getItem('companyAddress'),
            companyLogo: localStorage.getItem('companyLogo'),
            companyWebsite: localStorage.getItem('companyWebsite'),

            items: [],
            items_id: [],
            dataOrderNow: [],
            billDate: '',
            discount_form: {
                orders_id: [],
                user_id: '',
                note: '',
                value: '',
                type: 'percent',
                saved: false,
            },
            is_open_modal_discount_tax_fee: false,
            is_open_modal_discount: false,
            products_select_discount: [],

            modalFullOrders: false,
            modalOrderOpen: false,

            tax_fee: {
                orders_id: [],
                user_id: '',
                note: '',
                value: '',
                type: 'percent',
                saved: false,
            },
            is_open_modal_taxfee: false,
            products_select_taxfee: [],

            fee_other: {
                orders_id: [],
                user_id: '',
                note: '',
                value: '',
                type: 'percent',
                saved: false,
            },
            is_open_modal_fee_other: false,
            products_select_fee_other: [],
            isChecked: false,
            refund_to_customer: 0,
            data_customer: [],
            customer_debt: '',
            data_orders: [],
            info_table: '',
            data_stock: {},
            switchChecked: {},

            menus: [],
            ordersFiltered: [],
            listsData: [],
            activeIndex: 99,
            categoryId: 0,
            search: '',
            store: window.location.host.split('.')[0],
            is_loading_data: true,
            
            dataScan: '',
            scannerVisible: false,
            
            width: this.getWidth(),
        };
        this.socket = io(END_POINT_SOCKET);

        this.onUpdateOrderStatus = this.onUpdateOrderStatus.bind(this);

        this.onCheckItemPending = this.onCheckItemPending.bind(this);
        this.onCheckItemDone = this.onCheckItemDone.bind(this);
        this.onCheckItemRefund = this.onCheckItemRefund.bind(this);

        this.onActions = this.onActions.bind(this);
    }

    componentDidUpdate() {
        if (this.props.location && this.props.location.aboutProps) {
            const { message_item_id, message_user_id } =
                this.props.location.aboutProps;

            if (
                message_item_id &&
                message_user_id &&
                message_item_id != this.state.aboutProps.message_item_id &&
                message_user_id != this.state.aboutProps.message_user_id
            ) {
                // call get inbox
                this.socket.emit('message get inbox', {
                    // user_id: localStorage.getItem('user_id'),
                    user_id: this.state.USER_PARENT_ID,
                });
            }
        }
    }

    componentDidMount() {
        this.checkAccept();
        window.addEventListener('resize', this.handleResize);

        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        this.socket.emit('join room', {
            room_id: this.state.USER_PARENT_ID,
        });

        this.socket.on('refresh-recent-order', (res) => {
            const { data } = res;
            // console.log('data: ', data);

            this.setState({
                isLoadingItemsTable: false,
                itemsRecentOrder: data?.data,
                itemsOrderStatus: data?.ordersStatus,
                floorsActiveId: data?.floorsActiveId,
                itemsTable: isCurrentFloor({
                    current: this.state.selectedFloorTable,
                    get: data?.tables?.data?.getFloor,
                })
                    ? data?.tables?.data
                    : this.state.itemsTable,
            });
        });

        this.socket.on('refresh-pending-order', (res) => {
            const {
                data: { data },
            } = res;
            this.setState({
                itemsPending: data,
                selectedItemsPending: [],
            });
        });

        this.socket.on('refresh-done-order', (res) => {
            const {
                data: { data },
            } = res;
            this.setState({
                itemsDone: data,
                selectedItemsDone: [],
            });
        });

        this.socket.on('refresh-refund-order', (res) => {
            const {
                data: { data },
            } = res;
            this.setState({
                itemsRefund: data,
                selectedItemsRefund: [],
            });
        });

        this.socket.on('message get inbox', (res) => {
            const {
                data: { data },
            } = res;

            if (IsRedirectFromAlert(this.props)) {
                // console.log('from alert');

                const { message_item_id, message_user_id } =
                    this.props.location.aboutProps;

                // console.log(data);
                // console.log({ message_item_id, message_user_id } );

                let selectedUserDefault = null;
                if (message_item_id.indexOf('.') >= 0) {
                    // guest
                    let table_chair_id = message_item_id.split('.')[0];
                    let sender_id = message_item_id.split('.')[1];
                    let receive_id = message_user_id;

                    selectedUserDefault = data.filter(
                        (ibx) =>
                            ibx.sender_id == sender_id &&
                            ibx.receive_id == receive_id &&
                            ibx.table_chair_id == table_chair_id
                    );
                } else {
                    // store
                    selectedUserDefault = data.filter(
                        (ibx) =>
                            [ibx.friend_id, ibx.user_id].includes(
                                parseInt(message_user_id)
                            ) &&
                            [ibx.friend_id, ibx.user_id].includes(
                                parseInt(message_item_id)
                            )
                    );
                    // selectedUserDefault = data.filter(ibx => ibx.friend_id == message_user_id && ibx.user_id == message_item_id)
                }
                selectedUserDefault = selectedUserDefault.length
                    ? selectedUserDefault[0]
                    : null;

                // console.log(selectedUserDefault);

                if (selectedUserDefault) {
                    this.setState({
                        messagesData: data,
                        messageDefaultValue: {
                            selectedUser: selectedUserDefault,
                            props: this.props,
                        },
                        aboutProps: {
                            message_item_id: message_item_id,
                            message_user_id: message_user_id,
                        },
                    });
                }
            } else {
                this.setState({
                    messagesData: data,
                });
            }
        });

        this.socket.on('broadcast', () => {
            // reload inbox
            this.socket.emit('message get inbox', {
                // user_id: localStorage.getItem('user_id'),
                user_id: this.state.USER_PARENT_ID,
            });
        });

        this.onUpdateData();
        this.renderLengthBill();
        this.dataListRender();
        this.settingRender();
    }

    componentWillUnmount() {
        this.socket.close();
        window.removeEventListener('resize', this.handleResize);
    }

    dataListRender() {
        const { categoryId, search, store } = this.state;

        if (!store) return false;

        axios
            .get(
                `${
                    END_POINT + '/product/guest'
                }?store=${store}&categoryId=${categoryId}&search=${search}`
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                // console.log('data_product: ', data.data)
                let menu = [];

                // best seller - goi y mon ngon
                let template = {
                    id: 0,
                    name: 'Khuyên dùng',
                    data: data.data.filter((p) => !!p.bestter),
                };
                menu.push(template);

                // group by category
                let category_id = [];
                data.data.forEach((p) => {
                    if (!category_id.includes(p.category.id)) {
                        category_id.push(p.category.id);

                        template = {
                            id: p.category.id,
                            name: p.category.name,
                            data: [],
                        };
                        template.data.push(p);
                        menu.push(template);
                    } else {
                        menu.forEach((m) => {
                            if (p.category.id === m.id) {
                                m.data.push(p);
                            }
                        });
                    }
                });

                // state
                this.setState({
                    menus: menu,
                    is_loading_data: false,
                });
            })
            .catch((err) => {
                NotificationManager.error(
                    err.response.data.responseText,
                    'Lỗi !',
                    3000,
                    null,
                    null,
                    'filled'
                );
                return false;
            });
    }

    renderCategories() {
        return this.state.menus.map((categories, index) => {
            return (
                <span
                    onClick={() => {
                        this.onClickCategory(categories.name, index);
                    }}
                    key={categories.id}
                    id={index}
                    className={
                        this.state.activeIndex === index
                            ? 'active-menu nav_items cursor-pointer py-1 px-3'
                            : 'nav_items cursor-pointer py-1 px-3'
                    }
                >
                    {categories.name}
                </span>
            );
        });
    }

    settingRender() {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/setting'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => {
                return res.data;
            })
            .then(async (data) => {
                // console.log('data_setting: ', data);
                const setting = data.data;
                if (data) {
                    await this.setState((prevState) => ({
                        ...prevState,
                        switchChecked: {
                            ...prevState,
                            switchCheckedBill: setting.bill_cashier,
                            switchCheckedBillQr: setting.bill_qr,
                            switchCheckedBillBar: setting.bill_bar,
                            switchCheckedTemBar: setting.tem_bar,
                            switchCheckedTemCashier: setting.tem_cashier,
                            switchCheckedOrderOne: setting.order_processing_one,
                            switchCheckedOrderTwo: setting.order_processing_two,
                            switchCheckedOrderThree:
                                setting.order_processing_three,
                        },
                    }));
                } else {
                    NotificatioErrController('Không có dữ liệu');
                }
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    }

    onClickCategory = (name, index) => {
        let listsData = [...this.state.menus];

        if (name === 'all') {
            // const listsData = [...this.state.menus];
            const removeFirstElement = listsData.shift();
            this.setState((prev) => ({
                ...prev,
                listsData: listsData,
                activeIndex: 99,
            }));
        } else {
            const listsSort = listsData.find((menu) => menu.name === name);

            const lists = listsSort?.data?.map((d) => d);

            this.setState((prev) => ({
                ...prev,
                listsData: [{ name: name, data: lists }],
                activeIndex: index,
            }));
        }
    };

    resetMessageDefaultValue = () => {
        if (this.props.location && this.props.location.aboutProps) {
            this.props.location.aboutProps = {};
        }
        this.setState({
            messageDefaultValue: {
                selectedUser: {},
                props: null,
            },
            aboutProps: {
                message_item_id: 0,
                message_user_id: 0,
            },
        });
    };

    checkAccept = () => {
        let tokenStr = localStorage.getItem('access_token');

        return axios
            .get(`${END_POINT + '/user-license/check_accept'}`, {
                headers: {
                    Authorization: `Bearer ${tokenStr}`,
                },
            })
            .then((res) => res.data)
            .then((data) => {
                this.setState({
                    isLoading: true,
                    license: data.data,
                });

                return data.data;
            })
            .catch((err) => {
                return false;
            });
    };

    onCheckItemPending = (e, id) => {
        let selectedItems = this.state.selectedItemsPending;
        if (selectedItems.includes(id)) {
            selectedItems = selectedItems.filter((x) => x !== id);
        } else {
            selectedItems.push(id);
        }
        this.setState({
            selectedItemsPending: selectedItems,
        });
        document.activeElement.blur();
    };

    onCheckItemDone = (e, id) => {
        let selectedItems = this.state.selectedItemsDone;
        if (selectedItems.includes(id)) {
            selectedItems = selectedItems.filter((x) => x !== id);
        } else {
            selectedItems.push(id);
        }
        this.setState({
            selectedItemsDone: selectedItems,
        });
        document.activeElement.blur();
        
        this.getAllOrders();
    };

    onCheckItemRefund = (e, id) => {
        let selectedItems = this.state.selectedItemsRefund;
        if (selectedItems.includes(id)) {
            selectedItems = selectedItems.filter((x) => x !== id);
        } else {
            selectedItems.push(id);
        }
        this.setState({
            selectedItemsRefund: selectedItems,
        });
        document.activeElement.blur();
    };

    onUpdateSelectedFloorTable = (floor) => {
        this.socket.emit('refresh-recent-order', {
            user_id: localStorage.getItem('user_id'),
            floor,
        });
        this.setState({
            selectedFloorTable: floor,
        });
    };

    onUpdateData = async (orders_id = []) => {
        // console.log('updateStockCalled');
        // Cập nhật stock sau khi có sản phẩm mới thanh toán
        this.socket.emit('refresh-stock', {
            user_id: localStorage.getItem('user_id'),
            orders_id,
        });
        this.socket.emit('refresh-recent-order', {
            user_id: localStorage.getItem('user_id'),
            floor: this.state.selectedFloorTable,
        });
        this.socket.emit('refresh-pending-order', {
            user_id: localStorage.getItem('user_id'),
        });
        this.socket.emit('refresh-done-order', {
            user_id: localStorage.getItem('user_id'),
        });
        this.socket.emit('refresh-refund-order', {
            user_id: localStorage.getItem('user_id'),
        });
        this.socket.emit('refresh-alert-data', {
            user_id: localStorage.getItem('user_id'),
        });

        this.socket.emit('message get inbox', {
            // user_id: localStorage.getItem('user_id'),
            user_id: this.state.USER_PARENT_ID,
        });
    };

    onUpdateOrderStatus(ids, status) {
        // console.log('ids: ', ids);
        let tokenStr = localStorage.getItem('access_token');
        return axios
            .put(
                `${END_POINT + '/order/status'}`,
                {
                    arrayId: ids,
                    status_id: status,
                },
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                NotificationManager.primary(
                    'Đã cập nhật',
                    'Thành công',
                    3000,
                    null,
                    null,
                    'filled'
                );

                if (status === STATUS.COMPLETED) {
                    this.socket.emit('alert-paymenter', {
                        user_id: localStorage.getItem('user_id'),
                    });

                    // // Cập nhật stock sau khi có sản phẩm mới thanh toán
                    // this.socket.emit('refresh-stock', {
                    //     user_id: localStorage.getItem('user_id'),
                    // });
                }

                // this.socket.on('refresh-stock', (res) => {
                //     const {
                //         data: { data },
                //     } = res;
                //     this.setState((prev) => ({
                //         ...prev,
                //         data_stock_1: {
                //             loading: false,
                //             data: data,
                //         },
                //     }));
                // });

                this.onUpdateData(ids);
            })
            .catch((err) => {
                NotificationManager.error(
                    'Cập nhật thất bại',
                    'Lỗi !',
                    3000,
                    null,
                    null,
                    'filled'
                );
            });
    }

    onUpdateTransferTable = (orders, item) => {
        let tokenStr = localStorage.getItem('access_token');
        return axios
            .put(
                `${END_POINT + '/order/table'}`,
                {
                    orders,
                    user_identify: item.identify,
                    table_id: item.id,
                },
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                NotificationManager.primary(
                    'Chuyển bàn thành công',
                    'Thành công',
                    3000,
                    null,
                    null,
                    'filled'
                );

                this.socket.emit('refresh-recent-order', {
                    user_id: localStorage.getItem('user_id'),
                    floor: this.state.selectedFloorTable,
                });
            })
            .catch((err) => {
                NotificationManager.error(
                    'Chuyển bàn thất bại',
                    'Lỗi !',
                    3000,
                    null,
                    null,
                    'filled'
                );
            });
    };

    onActions(selectedItems, action, item, from) {
        if (selectedItems.length < 1) return false;

        if ('table' === from) {
            this.setState({
                isLoadingItemsTable: true,
            });
        }

        switch (action) {
            case 'pending':
                this.onUpdateOrderStatus(selectedItems, STATUS.PENDING);
                break;
            case 'done':
                this.onUpdateOrderStatus(selectedItems, STATUS.COMPLETED);
                break;
            case 'end':
                this.onUpdateOrderStatus(selectedItems, STATUS.PAYMENTED);
                break;
            case 'refund':
                this.onUpdateOrderStatus(selectedItems, STATUS.CLOSED);
                break;
            case 'transfer':
                this.onUpdateTransferTable(selectedItems, item);
                break;
            case 'destroy':
                this.onUpdateOrderStatus(selectedItems, STATUS.DESTROY);
                break;
            case 'change':
                this.changeOrders();
                break;
            default:
                break;
        }
    }

    changeOrders = () => {
        const { itemsDone, selectedItemsDone } = this.state;

        let itemsChange = [];
        let j = selectedItemsDone?.length;
        for (let i = 0; i < j; i++) {
            const filteredItems = itemsDone.filter(
                (item) => item.id === selectedItemsDone[i]
            );
            if (filteredItems.length > 0) {
                itemsChange.push(filteredItems[0]);
            }
        }
        this.setState((prevState) => ({
            ...prevState,
            itemsChange,
            _itemsChange: itemsChange,
        }));
        // this.getDiscountTaxFee();
    };

    getDiscountTaxFee = () => {
        let tokenStr = localStorage.getItem('access_token');

        // console.log('discount_tax_fee: !!!');
        return axios
            .get(`${END_POINT + '/order/discount_tax_fee'}`, {
                headers: {
                    Authorization: `Bearer ${tokenStr}`,
                },
            })
            .then((res) => res.data)
            .then((data) => {
                // console.log('discount_tax_fee: ', data);
                // this.setState({
                //     discount_tax_fee: data.data,
                // });
                // return data.data;
            })
            .catch((err) => {
                return false;
            });
    };

    onToggleDetail = async ({ product, name, value }) => {
        // const products = this.state.menus;
        // let find_product = products.map((categories) =>
        //     categories.data.find(
        //         (_product) => _product.id === product.id && !_product.softHide
        //     )
        // );

        // if (find_product) {
        //     find_product = find_product.find(function (el) {
        //         return el !== undefined;
        //     });
        // }

        // console.log('product: ', product);
        // console.log('name: ', name);
        // console.log('value: ', value);

        // if (find_product) {
        // toggle hiển thị hoặc không
        if (name === 'detailDisplay') {
            product[name] = !product[name];
        } else if (name === 'orderDisplay') {
            product[name] = !product[name];
        }

        // check vào các giá trị size, topping, ice, sugar
        else {
            let _value = value;

            // size và topping thì cho mảng các giá trị
            // Vì Topping thì có thể mix nhiều loại
            // Size thì luôn luôn required 1 loại
            if (name === 'sizeSelectedId' || name === 'toppingSelectedId') {
                if (name === 'sizeSelectedId') {
                    product.sizeName = product.size.find(
                        (p) => p.id === _value
                    ).name;
                    product.sizeValue = Number(
                        product.size.find((p) => p.id === _value).value
                    );

                    _value = [_value];
                } else if (product[name].includes(value)) {
                    _value = product[name].filter((_item) => _item !== value);
                } else if (name === 'toppingSelectedId') {
                    product[name].push(_value);
                    _value = product[name];
                }
            }
            product[name] = _value;
            // Lấy danh sách các giá trị 'value' từ mảng 'topping' dựa trên 'id' trong 'toppingSelectedId'
            const selectedToppingsName = product.toppingSelectedId.map(
                (selectedId) => {
                    const topping = product.topping.find(
                        (item) => item.id === selectedId
                    );
                    return topping ? topping.name : null;
                }
            );
            const selectedToppingsValue = product.toppingSelectedId.map(
                (selectedId) => {
                    const topping = product.topping.find(
                        (item) => item.id === selectedId
                    );
                    return topping ? topping.value : null;
                }
            );
            product.toppingName = selectedToppingsName;
            product.toppingValue = selectedToppingsValue;
        }

        this.filterOrders();
        // await this.onClickImage(product, 'nonePlusCount');
        // }
    };

    onClickImage = async (product, plus) => {
        if (!isRorF('orders_table')) return false;
        // console.log('product: ', product);
        // const { orders } = this.state;
        // console.log('orders: ', this.state.orders);

        const equar = (a, b) => {
            if (a.length !== b.length) {
                return false;
            } else {
                for (let i = 0; i < a.length; i++) {
                    if (a[i] !== b[i]) {
                        return false;
                    }
                }
                return true;
            }
        };

        // Find Order
        const find_order = this.state.orders.find(
            (_product) =>
                _product.iceSelectedValue === product.iceSelectedValue &&
                _product.sugarSelectedValue === product.sugarSelectedValue &&
                _product.sizeName === product.sizeName &&
                (product.toppingName
                    ? equar(_product.toppingName, product.toppingName)
                    : _product.toppingName === product.toppingName) &&
                _product.id === product.id
        );
        // console.log('find_order: ', find_order);

        let price = product.price;
        if (product.active_sale) {
            price = product.price_sale;
        }

        // size
        if (product.sizeSelectedId.length) {
            const find_size = product.size.find((_size) =>
                product.sizeSelectedId.includes(_size.id)
            );
            price = parseFloat(price) + parseFloat(find_size.value);
        }

        // topping
        if (product.toppingSelectedId.length) {
            const find_topping = product.topping.filter((_size) =>
                product.toppingSelectedId.includes(_size.id)
            );

            for (let i = 0; i < find_topping.length; i++) {
                price = parseFloat(price) + parseFloat(find_topping[i].value);
            }
        }

        // Update giá tiền nếu đã tồn tại
        if (find_order) {
            const orders = this.state.orders.map((o) =>
                o.iceSelectedValue === find_order.iceSelectedValue &&
                o.sugarSelectedValue === find_order.sugarSelectedValue &&
                o.sizeName === find_order.sizeName &&
                o.toppingName === find_order.toppingName &&
                o.id === find_order.id
                    ? {
                          ...o,
                          product: { name: product.name, price: price },
                          count: plus === 'nonePlusCount' ? 1 : o.count + 1,
                          price: price,
                          note: product.note,
                          iceSelectedValue: product.iceSelectedValue,
                          sugarSelectedValue: product.sugarSelectedValue,
                          sizeSelectedId: product.sizeSelectedId,
                          sizeName: product.sizeName,
                          sizeValue: product.sizeValue,
                          toppingSelectedId: product.toppingSelectedId,
                          toppingName: product.toppingName,
                          toppingValue: product.toppingValue,
                      }
                    : o
            );
            await this.setState({ orders });
            this.filterOrders();
        } else {
            let orders = this.state.orders;

            orders.push({
                id: product.id,
                product: {
                    name: product.name,
                    price:
                        typeof price === 'string' ? parseFloat(price) : price,
                },
                name: product.name,
                price: typeof price === 'string' ? parseFloat(price) : price,
                count: 1,
                note: product.note,
                iceSelectedValue: product.iceSelectedValue,
                sugarSelectedValue: product.sugarSelectedValue,
                sizeSelectedId: product.sizeSelectedId,
                sizeName: product.sizeName,
                sizeValue: product.sizeValue,
                toppingSelectedId: product.toppingSelectedId,
                toppingName: product.toppingName,
                toppingValue: product.toppingValue,
                previewDisplay: false,
            });
            await this.setState({ orders });
            this.filterOrders();
        }
        // console.log('orders: ', orders);

        await this.setState((prev) => ({
            ...prev,
            billDate: getDateWithFormat(),
        }));
        this.renderLengthBill();

        // Accept scroll body
        document.body.classList.remove('noscroll');
        document.body.classList.remove('modal-open');
    };

    filterOrders = async () => {
        const { orders } = this.state;
        await this.setState((prev) => ({
            ...prev,
            ordersFiltered: this.consolidateOrders(orders),
        }));
    };

    consolidateOrders = (orders) => {
        const consolidatedOrders = {};

        orders.forEach((order) => {
            const key = `${order.product_id}-${order.status_id}-${order.ice}-${order.sugar}-${order.size}-${order.topping}`;

            if (!consolidatedOrders[key]) {
                consolidatedOrders[key] = {
                    ...order,
                    count: 1,
                };
            } else {
                consolidatedOrders[key].count += 1;
            }
        });

        return Object.values(consolidatedOrders);
    };

    renderLengthBill = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/bill/length_bill'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                this.setState((prev) => ({
                    ...prev,
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    // getOrders = () => {
    //     let childelement = this.ChildElement.current;
    //     let listOrders = childelement?.state?.orders;
    //     return this.setState({ orders: listOrders });
    // };

    calTotalPrice = (orders) => {
        let total = 0;
        for (let i = 0; i < orders.length; i++) {
            let order = orders[i];

            total += parseInt(order.price) * order.count;
        }
        return total;
    };

    onChange = (e) => {
        if (e.target) {
            const {
                target: { name, value },
            } = e;

            this.setState(
                (prev) => ({
                    ...prev,
                    [name]: value,
                }),
                () => this.findCustomer()
            );
        } else {
        }
    };

    handleSubmit = async (payment) => {
        const {
            orders,
            refund_to_customer,
            customer_name,
            customer_phone,
            isChecked,
            info_table,
        } = this.state;
        // console.log('payment: ', payment);

        const totalPriceDebt = this.calTotalPrice(orders);

        if (customer_phone && customer_name === '') {
            return NotificationManager.warning(
                'Hãy thêm tên của khách hàng',
                '',
                5000,
                null,
                null,
                'filled'
            );
        }

        if (customer_phone === '' && customer_name) {
            return NotificationManager.warning(
                'Hãy thêm số điện thoại của khách hàng',
                '',
                5000,
                null,
                null,
                'filled'
            );
        }

        if (
            (refund_to_customer > 0 &&
                (customer_name === '' || customer_phone === '')) ||
            (isChecked && (customer_name === '' || customer_phone === ''))
        ) {
            return NotificationManager.warning(
                `${
                    customer_name === '' && customer_phone === ''
                        ? 'Cần thêm thông tin của khách hàng để ghi nhận công nợ'
                        : customer_name === '' || customer_phone !== ''
                        ? 'Cần thêm tên khách hàng'
                        : customer_name !== '' || customer_phone === ''
                        ? 'Cần thêm số điện thoại của khách hàng'
                        : ''
                }`,
                isChecked === true
                    ? `Số tiền khách nợ: ${AddCommaNumber(totalPriceDebt)}`
                    : `Số tiền khách nợ: ${AddCommaNumber(refund_to_customer)}`,
                5000,
                null,
                null,
                'filled'
            );
        }

        let tokenStr = localStorage.getItem('access_token');
        if (this.state.info_table === '') {
            return await axios
                .post(
                    `${END_POINT + '/bill/guest_order_done'}`,
                    {
                        data: this.state.orders,
                        store: this.state.store,
                        phone: this.state.customer_phone,
                        name: this.state.customer_name,
                        hash: this.state.hash || '',
                        table_id: this.state.table_id || '',
                        paymented: payment === 'PAYMENTED' ? true : false,
                    },
                    { headers: { Authorization: `Bearer ${tokenStr}` } }
                )
                .then(async (res) => {
                    return res.data;
                })
                .then(async (data) => {
                    const { bill_id } = data;

                    NotificationManager.primary(
                        data.responseText,
                        '',
                        3000,
                        null,
                        null,
                        'filled'
                    );
                    // this.socket.emit('refresh-recent-order', {
                    //     user_id: localStorage.getItem('user_id'),
                    //     floor: 0,
                    //     user_identify: this.state.items[0]['user_identify'],
                    //     table_id: this.state.items[0]['table_id'],
                    // });

                    // // Cập nhật stock sau khi có sản phẩm mới thanh toán
                    // this.socket.emit('refresh-stock', {
                    //     user_id: localStorage.getItem('user_id'),
                    // });

                    this.socket.emit('create logs', {
                        user_id: localStorage.getItem('user_id'),
                        message: LogsHelper['order-done'],
                    });
                    if (refund_to_customer > 0) {
                        this.socket.emit('create logs', {
                            user_id: localStorage.getItem('user_id'),
                            message: LogsHelper['customer-debt-create'],
                        });
                    }
                    this.socket.emit('refresh-pending-order', {
                        user_id: localStorage.getItem('user_id'),
                    });
                    this.socket.emit('refresh-done-order', {
                        user_id: localStorage.getItem('user_id'),
                    });
                    this.socket.emit('alert-paymenter', {
                        user_id: localStorage.getItem('user_id'),
                    });
                    // refresh alert data
                    this.socket.emit('refresh-alert-data', {
                        user_id: localStorage.getItem('user_id'),
                    });

                    // if ((name = 'paymented')) {
                    //     this.socket.emit('create logs', {
                    //         user_id: localStorage.getItem('user_id'),
                    //         message: LogsHelper['invoice-print'],
                    //     });
                    // }

                    //getIdOrders
                    await this.updateStock(bill_id);

                    await this.getIdOrdersNow(bill_id);

                    if (customer_phone && customer_name) {
                        this.createCustomerDone();
                    }

                    //invoices
                    if (orders.length > 0) {
                        this.dataOrdersRender();
                    }

                    await this.setState({
                        orders: [],
                        customer_name: '',
                        customer_phone: '',
                        cashsPay: [],
                        cashs: [
                            { cash: 1000, quantity: 0 },
                            { cash: 2000, quantity: 0 },
                            { cash: 5000, quantity: 0 },
                            { cash: 10000, quantity: 0 },
                            { cash: 20000, quantity: 0 },
                            { cash: 50000, quantity: 0 },
                            { cash: 100000, quantity: 0 },
                            { cash: 200000, quantity: 0 },
                            { cash: 500000, quantity: 0 },
                        ],
                        modalOrderOpen: false,
                        isChecked: false,
                    });
                })
                .catch((err) => {});
        } else {
            if (this.state.orders.length < 1) {
                NotificationManager.error(
                    'Chưa đặt sản phẩm. Thêm ít nhất 1 sản phẩm',
                    'Lỗi !',
                    3000,
                    null,
                    null,
                    'filled'
                );
                return false;
            }

            // axios submit
            axios
                .post(`${END_POINT + '/bill/guest-pending'}`, {
                    data: this.state.orders,
                    store: window.location.host.split('.')[0],
                    phone: this.state.customer_phone,
                    name: this.state.customer_name,
                    hash: info_table.item.user.companyMenuHash,
                    table_id: info_table.item.id,
                })
                .then(async (res) => {
                    // console.log('res: ', res.data);
                    return res.data;
                })
                .then(async (data) => {
                    const { bill_id } = data;
                    // console.log('data: ', data);
                    NotificationManager.primary(
                        data.responseText,
                        '',
                        3000,
                        null,
                        null,
                        'filled'
                    );

                    // real-time
                    this.socket.emit('refresh-bestseller', {
                        user_id: localStorage.getItem('user_id'),
                        type: localStorage.getItem(
                            'selectedDropdownBestSeller'
                        ),
                    });
                    this.socket.emit('refresh-recent-order', {
                        user_id: localStorage.getItem('user_id'),
                        floor: 0,
                        user_identify: this.state.user_identify || '',
                        table_id: this.state.table_id || '',
                    });
                    this.socket.emit('refresh-pending-order', {
                        user_id: localStorage.getItem('user_id'),
                    });
                    this.socket.emit('refresh-done-order', {
                        user_id: localStorage.getItem('user_id'),
                    });
                    this.socket.emit('refresh-refund-order', {
                        user_id: localStorage.getItem('user_id'),
                    });

                    // refresh alert data
                    this.socket.emit('refresh-alert-data', {
                        user_id: localStorage.getItem('user_id'),
                    });

                    // Cập nhật stock sau khi có sản phẩm mới thanh toán
                    // this.socket.emit('refresh-stock', {
                    //     user_id: localStorage.getItem('user_id'),
                    //     orders_id,
                    // });

                    // // refresh alert data
                    // this.socket.emit('refresh-alert-data', {
                    //     user_id: localStorage.getItem('user_id'),
                    // });

                    // // re-mind order after 2 minutes
                    // this.socket.emit('remind-created-orders', {
                    //     data: this.state.orders,
                    //     user_id: localStorage.getItem('user_id'),
                    //     bill_id,
                    // });

                    // Accept scroll body
                    document.body.classList.remove('noscroll');
                    document.body.classList.remove('modal-open');

                    //getIdOrders
                    await this.updateStock(bill_id);

                    await this.getIdOrdersNow(bill_id);

                    if (customer_phone && customer_name) {
                        this.createCustomerDone();
                    }

                    //invoices
                    if (orders.length > 0) {
                        this.dataOrdersRender();
                    }

                    await this.setState({
                        orders: [],
                        customer_name: '',
                        customer_phone: '',
                        cashsPay: [],
                        cashs: [
                            { cash: 1000, quantity: 0 },
                            { cash: 2000, quantity: 0 },
                            { cash: 5000, quantity: 0 },
                            { cash: 10000, quantity: 0 },
                            { cash: 20000, quantity: 0 },
                            { cash: 50000, quantity: 0 },
                            { cash: 100000, quantity: 0 },
                            { cash: 200000, quantity: 0 },
                            { cash: 500000, quantity: 0 },
                        ],
                        modalOrderOpen: false,
                        isChecked: false,
                        info_table: '',
                    });
                })
                .catch((err) => {
                    NotificationManager.error(
                        err.response.data.responseText,
                        'Lỗi !',
                        3000,
                        null,
                        null,
                        'filled'
                    );
                });
        }
    };

    handleSubmitChange = async () => {
        const { customer_name, customer_phone, itemsChange, _itemsChange } =
            this.state;

        const idItemsChange = itemsChange.map((item) => item.id);
        let itemsRemove = _itemsChange.filter(
            (item) => !idItemsChange.includes(item.id)
        );
        const idItemsRemove = itemsRemove.map((item) => item.id);

        this.setState((prev) => ({
            ...prev,
            discount_form: {
                ...prev.discount_form,
                orders_id: idItemsChange,
            },
            tax_fee: {
                ...prev.tax_fee,
                orders_id: idItemsChange,
            },
            fee_other: {
                ...prev.fee_other,
                orders_id: idItemsChange,
            },
        }));

        // save discount
        if (this.state.discount_form.saved) {
            await this.handleSaveDiscount();
        }

        // save tax fee
        if (this.state.tax_fee.saved) {
            await this.handleSaveTaxFee();
        }

        // save fee other
        if (this.state.fee_other.saved) {
            await this.handleSaveFeeOther();
        }
        // const totalPriceDebt = this.calTotalPrice(orders);

        if (customer_phone && customer_name === '') {
            return NotificationManager.warning(
                'Hãy thêm tên của khách hàng',
                '',
                5000,
                null,
                null,
                'filled'
            );
        }

        if (customer_phone === '' && customer_name) {
            return NotificationManager.warning(
                'Hãy thêm số điện thoại của khách hàng',
                '',
                5000,
                null,
                null,
                'filled'
            );
        }

        if (customer_phone && customer_name) {
            this.createCustomerDone();
        }

        let tokenStr = localStorage.getItem('access_token');
        if (idItemsRemove?.length) {
            return await axios
                .put(
                    `${END_POINT + '/order/change-order'}`,
                    {
                        itemsDelete: idItemsRemove,
                    },
                    { headers: { Authorization: `Bearer ${tokenStr}` } }
                )
                .then(async (res) => {
                    return res.data;
                })
                .then(async (data) => {
                    NotificationManager.primary(
                        data.responseText,
                        '',
                        3000,
                        null,
                        null,
                        'filled'
                    );

                    this.socket.emit('create logs', {
                        user_id: localStorage.getItem('user_id'),
                        message: LogsHelper['order-change'],
                    });
                    // this.socket.emit('refresh-pending-order', {
                    //     user_id: localStorage.getItem('user_id'),
                    // });
                    this.socket.emit('refresh-done-order', {
                        user_id: localStorage.getItem('user_id'),
                    });

                    await this.setState({
                        itemsChange: [],
                        _itemsChange: [],
                        customer_name: '',
                        customer_phone: '',
                        cashsPay: [],
                        cashs: [
                            { cash: 1000, quantity: 0 },
                            { cash: 2000, quantity: 0 },
                            { cash: 5000, quantity: 0 },
                            { cash: 10000, quantity: 0 },
                            { cash: 20000, quantity: 0 },
                            { cash: 50000, quantity: 0 },
                            { cash: 100000, quantity: 0 },
                            { cash: 200000, quantity: 0 },
                            { cash: 500000, quantity: 0 },
                        ],
                    });
                })
                .catch((err) => {});
        }
        await this.setState({
            itemsChange: [],
            _itemsChange: [],
            customer_name: '',
            customer_phone: '',
            cashsPay: [],
            cashs: [
                { cash: 1000, quantity: 0 },
                { cash: 2000, quantity: 0 },
                { cash: 5000, quantity: 0 },
                { cash: 10000, quantity: 0 },
                { cash: 20000, quantity: 0 },
                { cash: 50000, quantity: 0 },
                { cash: 100000, quantity: 0 },
                { cash: 200000, quantity: 0 },
                { cash: 500000, quantity: 0 },
            ],
        });
    };

    createCustomerDone = () => {
        const {
            orders,
            itemsChange,
            customer_name,
            customer_phone,
            refund_to_customer,
            discount_form,
            tax_fee,
            fee_other,
            // cashsPay,
        } = this.state;

        let items = itemsChange?.length ? itemsChange : orders;

        let total_price = 0;
        if (discount_form.saved) {
            let total_discount = discount_form.value;
            total_price -= Number(total_discount);
        }
        if (tax_fee.saved) {
            let total_tax_fee = tax_fee.value;
            total_price += Number(total_tax_fee);
        }
        if (fee_other.saved) {
            let total_fee_other = fee_other.value;
            total_price += Number(total_fee_other);
        }

        let total_price_orders = items.reduce((init, item) => {
            return init + Number(item.price) * item.count;
        }, 0);

        total_price += Number(total_price_orders);

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/customer/create-order-done'}`,
                {
                    name: customer_name,
                    phone: customer_phone,
                    email: '',
                    address: '',
                    point: total_price / 1000,
                    armorial: '',
                    used: 1,
                    tags: [],
                    debt: this.state.isChecked
                        ? total_price
                        : refund_to_customer > 0
                        ? refund_to_customer
                        : 0,
                    dynamic_form: '',
                },
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )

            .then((res) => {
                return res.data;
            })
            .then((data) => {
                NotificationManager.primary(
                    data.responseText,
                    '',
                    3000,
                    null,
                    null,
                    'filled'
                );
                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['customer-create'],
                });
            })
            .catch((err) => {
                NotificatioErrController(
                    'Có lỗi xảy ra khi tích điểm khách hàng'
                );
            });
    };

    findCustomer = () => {
        const { customer_phone, data_customer } = this.state;
        // console.log('customer_phone: ', customer_phone);
        // console.log('data_customer: ', data_customer);
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/customer'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                this.setState((prev) => ({
                    ...prev,
                    data_customer: data.data,
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });

        if (customer_phone) {
            const customerDebts = data_customer.map((customer) => {
                if (customer.phone.includes(customer_phone)) {
                    return customer.debt;
                }
            });
            const customerDebt = customerDebts.find(
                (debt) => debt !== undefined
            );
            if (customerDebt) {
                this.setState((prev) => ({
                    ...prev,
                    customer_debt: customerDebt,
                }));
            }
        }
    };

    getAllOrders = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/order/all'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                // console.log('allOrders: ', data.data);
                const orders = data.data;
                // Tìm giá trị số lớn nhất trong biến orders
                let maxPin = -Infinity; // Khởi tạo maxPin với giá trị âm vô cùng để đảm bảo mọi giá trị đều lớn hơn nó.

                for (const order of orders) {
                    if (Number(order.pin_print) > maxPin) {
                        maxPin = Number(order.pin_print);
                    }
                }

                // Cộng thêm 1 vào maxPin
                maxPin += 1;
                // console.log('maxPin: ', maxPin);
                this.setState((prev) => ({
                    ...prev,
                    pin_print: maxPin,
                }));
            });
    };
    
    updateStock = (bill_id) => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/order/all_orders_today'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                const dataOrderNow = data.data.filter(
                    (d) => d.bill_id === bill_id
                );
                const arrayId = dataOrderNow.map((d) => d.id);
                this.onUpdateData(arrayId);
            });
    };

    onHandlerDeleteOrderPending = (order, idx) => {
        const { orders, itemsChange } = this.state;
        let items = itemsChange?.length ? itemsChange : orders;

        const ordersFilter = items.filter(
            // (or) => or.id !== order.id
            (or, index) => index !== idx
        );

        this.setState({
            orders: itemsChange?.length ? [] : ordersFilter,
            itemsChange: itemsChange?.length ? ordersFilter : [],
        });
    };

    //Invoices
    dataOrdersRender = async () => {
        // console.log('dataOrderRender running...');
        let tokenStr = localStorage.getItem('access_token');

        const ordersId = this.state.orders.map((d) => d.id);
        if (ordersId === null) return false;

        await axios
            .post(
                `${END_POINT + '/bill/invoice_by_orders_id'}`,
                {
                    orders: ordersId,
                },
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                // console.log('data: ', data);
                const result = [];
                for (let i = 0; i < data.data.length; i++) {
                    const find_item_id = result.find(
                        (_item) => _item.product_id === data.data[i].product_id
                    );

                    if (find_item_id) {
                        // Có thì cộng số lượng lên
                        find_item_id.count = find_item_id.count + 1;
                        find_item_id.price =
                            parseFloat(find_item_id.price) +
                            parseFloat(data.data[i].price);
                    } else {
                        result.push(data.data[i]);
                    }
                }

                this.setState({
                    itemsPendingBill: result,
                    items_id: data.data,
                    // billDate: getDateWithFormat(),
                    bill: data?.data[0]?.bill?.id,
                    products_select_discount: GetProductsSelectDiscount(
                        data.data
                    ),
                });
                // console.log('itemsPendingBill: ', this.state.itemsPendingBill);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    handleToggleModalDiscount = () => {
        this.setState({
            is_open_modal_discount: !this.state.is_open_modal_discount,
        });
    };

    handleToggleModalDiscountTaxFee = () => {
        this.setState({
            is_open_modal_discount_tax_fee:
                !this.state.is_open_modal_discount_tax_fee,
        });
    };

    getIdOrdersNow = (bill_id) => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/order/all_orders_today'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then(async (data) => {
                // console.log('data: ', data);
                const dataOrderNow = data.data.filter(
                    (d) => d.bill_id === bill_id
                );
                const arrayId = dataOrderNow.map((d) => d.id);
                // console.log('arrayId: ', arrayId);
                this.setState((prev) => ({
                    ...prev,
                    orders_fr_server: data.data,
                    discount_form: {
                        ...prev.discount_form,
                        orders_id: arrayId,
                    },
                    tax_fee: {
                        ...prev.tax_fee,
                        orders_id: arrayId,
                    },
                    fee_other: {
                        ...prev.fee_other,
                        orders_id: arrayId,
                    },
                }));

                // save discount
                if (this.state.discount_form.saved) {
                    await this.handleSaveDiscount();
                }

                // save tax fee
                if (this.state.tax_fee.saved) {
                    await this.handleSaveTaxFee();
                }

                // save fee other
                if (this.state.fee_other.saved) {
                    await this.handleSaveFeeOther();
                }
            });
    };

    handleSubmitModalDiscountTaxFee = async () => {
        let { discount_form, tax_fee, fee_other, cashsPay } = this.state;

        // if (
        //     discount_form.value !== '' &&
        //     discount_form.type === 'percent' &&
        //     Number(discount_form.value) > 100
        // ) {
        //     return NotificatioErrController(
        //         'Giá trị chiết khấu không được vượt quá 100%'
        //     );
        // }
        // if (
        //     tax_fee.value !== '' &&
        //     tax_fee.type === 'percent' &&
        //     Number(tax_fee.value) > 500
        // ) {
        //     return NotificatioErrController(
        //         'Giá trị thuế không được vượt quá 500%'
        //     );
        // }
        // if (
        //     fee_other.value !== '' &&
        //     fee_other.type === 'percent' &&
        //     Number(fee_other.value) > 200
        // ) {
        //     return NotificatioErrController(
        //         'Giá trị chi phí khác không được vượt quá 200%'
        //     );
        // }

        // if (
        //     discount_form.value !== '' &&
        //     discount_form.type === 'price' &&
        //     Number(discount_form.value.toString().replace(/\./g, '')) < 1000
        // ) {
        //     return NotificatioErrController(
        //         'Giá trị chiết khấu không được nhỏ hơn 1.000 VNĐ'
        //     );
        // }
        // if (
        //     tax_fee.value !== '' &&
        //     tax_fee.type === 'price' &&
        //     Number(tax_fee.value.toString().replace(/\./g, '')) < 1000
        // ) {
        //     return NotificatioErrController(
        //         'Giá trị thuế không được nhỏ hơn 1.000 VNĐ'
        //     );
        // }
        // if (
        //     fee_other.value !== '' &&
        //     fee_other.type === 'price' &&
        //     Number(fee_other.value.toString().replace(/\./g, '')) < 1000
        // ) {
        //     return NotificatioErrController(
        //         'Giá trị chi phí khác không được nhỏ hơn 1.000 VNĐ'
        //     );
        // }

        await this.setState((prevState) => ({
            ...prevState,
            discount_form: {
                ...prevState.discount_form,
                saved: true,
                note: discount_form.note,
                value: discount_form.value.toString().replace(/\./g, ''),
                type: discount_form.type,
            },
            tax_fee: {
                ...prevState.tax_fee,
                saved: true,
                note: tax_fee.note,
                value: tax_fee.value.toString().replace(/\./g, ''),
                type: tax_fee.type,
            },
            fee_other: {
                ...prevState.fee_other,
                saved: true,
                note: fee_other.note,
                value: fee_other.value.toString().replace(/\./g, ''),
                type: fee_other.type,
            },
        }));
        // if (cashsPay) {
        //     this.cashRefundToCustomer();
        // }
        this.handleToggleModalDiscountTaxFee();
    };

    handleOnChangeModalDiscountTaxFee = (e, inputType, valueType) => {
        const { name, value } = e.target;
        const { discount_form, tax_fee, fee_other } = this.state;

        // Tùy thuộc vào loại input (discount, tax, fee), cập nhật state tương ứng
        this.setState((prevState) => ({
            [`${inputType}`]: {
                ...prevState[`${inputType}`],
                [name]:
                    valueType === 'percent'
                        ? 'percent'
                        : valueType === 'price'
                        ? 'price'
                        : value,
            },
        }));
    };

    // handleSubmitModalDiscount = async () => {
    //     // templ save, when click print will saved
    //     let {
    //         discount_form: { note, value, type },
    //         cashsPay,
    //     } = this.state;

    //     if (!type || !value) {
    //         return NotificatioErrController('Dữ liệu không hợp lệ');
    //     }

    //     await this.setState((prevState) => ({
    //         ...prevState,
    //         discount_form: {
    //             ...prevState.discount_form,
    //             saved: true,
    //             note,
    //             value: value.toString().replace(/\./g, ''),
    //             type,
    //             orders_id: '',
    //         },
    //     }));
    //     if (cashsPay) {
    //         this.cashRefundToCustomer();
    //     }
    //     // console.log('discount: ', this.state.discount_form);
    //     this.handleToggleModalDiscount();
    // };

    // handleOnChangeModalDiscount = (e, _name) => {
    //     // console.log('e: ', e.target);
    //     let name = '',
    //         value = '';

    //     if (e.target) {
    //         name = e.target.name;
    //         value = e.target.value;

    //         if (!name) {
    //             name = _name;
    //         }
    //     } else {
    //         name = _name;

    //         if (Array.isArray(e)) {
    //             value = e.map((d) => d.value);
    //         } else {
    //             value = e.value;
    //         }
    //     }

    //     this.setState((prevState) => ({
    //         ...prevState,
    //         discount_form: {
    //             ...prevState.discount_form,
    //             [name]: value,
    //         },
    //     }));
    // };

    handleSaveDiscount = async () => {
        const { discount_form } = this.state;

        let tokenStr = localStorage.getItem('access_token');

        return await axios
            .put(
                `${END_POINT + '/order/discount'}`,
                {
                    orders_id: discount_form.orders_id,
                    value: discount_form.value.toString().replace(/\./g, ''),
                    type: discount_form.type,
                    bill_id: '',
                    note: discount_form.note,
                },
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificationManager.primary(
                    data.responseText,
                    '',
                    3000,
                    null,
                    null,
                    'filled'
                );

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['discount-create'],
                });

                this.setState((prev) => ({
                    ...prev,
                    discount_form: {
                        orders_id: [],
                        user_id: '',
                        note: '',
                        value: '',
                        type: '',
                        saved: false,
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController('Có lỗi xảy ra khi lưu chiết khấu');
            });
    };

    // handleToggleModalTaxFee = () => {
    //     this.setState({
    //         is_open_modal_taxfee: !this.state.is_open_modal_taxfee,
    //     });
    // };

    // handleSubmitModalTaxFee = async () => {
    //     let {
    //         tax_fee: { note, type, value },
    //         cashsPay,
    //     } = this.state;

    //     if (!type || !value) {
    //         return NotificatioErrController('Dữ liệu không hợp lệ');
    //     }

    //     await this.setState((prevState) => ({
    //         ...prevState,
    //         tax_fee: {
    //             ...prevState.tax_fee,
    //             saved: true,
    //             note,
    //             value: value.toString().replace(/\./g, ''),
    //             type,
    //             orders_id: '',
    //         },
    //     }));

    //     if (cashsPay) {
    //         this.cashRefundToCustomer();
    //     }
    //     this.handleToggleModalTaxFee();
    // };

    // handleOnChangeModalTaxFee = (e, _name) => {
    //     let name = '',
    //         value = '';

    //     if (e.target) {
    //         name = e.target.name;
    //         value = e.target.value;

    //         if (!name) {
    //             name = _name;
    //         }
    //     } else {
    //         name = _name;

    //         if (Array.isArray(e)) {
    //             value = e.map((d) => d.value);
    //         } else {
    //             value = e.value;
    //         }
    //     }

    //     this.setState((prevState) => ({
    //         ...prevState,
    //         tax_fee: {
    //             ...prevState.tax_fee,
    //             [name]: value,
    //         },
    //     }));
    // };

    handleSaveTaxFee = async () => {
        const { tax_fee } = this.state;
        let tokenStr = localStorage.getItem('access_token');

        return await axios
            .put(
                `${END_POINT + '/order/tax_fee'}`,
                {
                    orders_id: tax_fee.orders_id,
                    value: tax_fee.value.toString().replace(/\./g, ''),
                    type: tax_fee.type,
                    bill_id: '',
                    note: tax_fee.note,
                },
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificationManager.primary(
                    data.responseText,
                    '',
                    3000,
                    null,
                    null,
                    'filled'
                );

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['tax-fee-create'],
                });

                this.setState((prev) => ({
                    ...prev,
                    tax_fee: {
                        orders_id: [],
                        user_id: '',
                        note: '',
                        value: '',
                        type: '',
                        saved: false,
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController('Có lỗi xảy ra khi lưu thuế phí');
            });
    };

    // handleToggleModalFeeOther = () => {
    //     this.setState({
    //         is_open_modal_fee_other: !this.state.is_open_modal_fee_other,
    //     });
    // };

    // handleSubmitModalFeeOther = async () => {
    //     let {
    //         fee_other: { note, type, value },
    //         cashsPay,
    //     } = this.state;

    //     if (!type || !value) {
    //         return NotificatioErrController('Dữ liệu không hợp lệ');
    //     }

    //     await this.setState((prevState) => ({
    //         ...prevState,
    //         fee_other: {
    //             ...prevState.fee_other,
    //             saved: true,
    //             note,
    //             value: value.toString().replace(/\./g, ''),
    //             type,
    //             orders_id: '',
    //         },
    //     }));
    //     if (cashsPay) {
    //         this.cashRefundToCustomer();
    //     }
    //     this.handleToggleModalFeeOther();
    // };

    // handleOnChangeModalFeeOther = (e, _name) => {
    //     let name = '',
    //         value = '';

    //     if (e.target) {
    //         name = e.target.name;
    //         value = e.target.value;

    //         if (!name) {
    //             name = _name;
    //         }
    //     } else {
    //         name = _name;

    //         if (Array.isArray(e)) {
    //             value = e.map((d) => d.value);
    //         } else {
    //             value = e.value;
    //         }
    //     }

    //     this.setState((prevState) => ({
    //         ...prevState,
    //         fee_other: {
    //             ...prevState.fee_other,
    //             [name]: value,
    //         },
    //     }));
    // };

    handleSaveFeeOther = async () => {
        const { fee_other } = this.state;
        // console.log('fee_other: ', fee_other);
        let tokenStr = localStorage.getItem('access_token');
        return await axios
            .put(
                `${END_POINT + '/order/fee_other'}`,
                {
                    orders_id: fee_other.orders_id,
                    value: fee_other.value.toString().replace(/\./g, ''),
                    type: fee_other.type,
                    bill_id: '',
                    note: fee_other.note,
                },
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificationManager.primary(
                    data.responseText,
                    '',
                    3000,
                    null,
                    null,
                    'filled'
                );

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['fee-other-create'],
                });

                this.setState((prev) => ({
                    ...prev,
                    fee_other: {
                        orders_id: [],
                        user_id: '',
                        note: '',
                        value: '',
                        type: '',
                        saved: false,
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController('Có lỗi xảy ra khi lưu phụ phí');
            });
    };

    handleRemoveValueModal = async (name) => {
        const { cashsPay } = this.state;
        if (name === 'discount') {
            await this.setState((prev) => ({
                ...prev,
                discount_form: {
                    orders_id: [],
                    user_id: '',
                    note: '',
                    value: '',
                    type: '',
                    saved: false,
                },
            }));
            // if (cashsPay) {
            //     this.cashRefundToCustomer();
            // }
        }
        if (name === 'tax_fee') {
            await this.setState((prev) => ({
                ...prev,
                tax_fee: {
                    orders_id: [],
                    user_id: '',
                    note: '',
                    value: '',
                    type: '',
                    saved: false,
                },
            }));
            // if (cashsPay) {
            //     this.cashRefundToCustomer();
            // }
        }
        if (name === 'fee_other') {
            await this.setState((prev) => ({
                ...prev,
                fee_other: {
                    orders_id: [],
                    user_id: '',
                    note: '',
                    value: '',
                    type: '',
                    saved: false,
                },
            }));
            // if (cashsPay) {
            //     this.cashRefundToCustomer();
            // }
        }
    };

    onRemoveOrder = (id) => {
        let orders = this.state.orders.filter((_order) => _order.id !== id);
        this.setState({ orders });
    };

    totalPay = async (e, cash) => {
        let { cashs, cashsPay } = this.state;
        const cashChoose = cashs.findIndex((item) => item.cash === cash);

        if (cashChoose >= 0) {
            cashs[cashChoose].quantity += 1;
            await this.setState({ cashs, cashsPay: cashs, isChecked: false });
            if (cashsPay) {
                this.cashRefundToCustomer();
            }
        } else {
            cashs.push({
                cash: cash,
                quantity: 1,
            });
            await this.setState({ cashs, cashsPay: cashs });
        }
    };

    // totalPay = (e, cash) => {
    //     let cashs = this.state.cashs;
    //     const cashChoose = cashs.findIndex((item) => item.cash === cash);

    //     if (cashChoose >= 0) {
    //         cashs[cashChoose].quantity += 1;
    //         this.setState({ cashs, cashsPay: cashs, isChecked: false }, () => {
    //             if (this.state.cashsPay) {
    //                 this.cashRefundToCustomer();
    //             }
    //         });
    //     } else {
    //         let cashs = this.state.cashs;

    //         cashs.push({
    //             cash: cash,
    //             quantity: 1,
    //         });
    //         this.setState({ cashs, cashsPay: cashs });
    //     }
    // };

    quantityPay = (cash) => {
        const cashsPay = this.state.cashsPay;
        cashsPay.filter((cash) => cash.cash === cash);
    };

    totalCustomerPay = (cashsPay) => {
        // console.log('cashsPay: ', cashsPay);
        let total = 0;
        for (let i = 0; i < cashsPay.length; i++) {
            let cash = cashsPay[i];

            total += parseInt(cash.cash) * cash.quantity;
        }
        return total;
    };

    removeOrders = () => {
        this.setState({
            orders: [],
            itemsChange: [],
        });
    };

    cashFill = (name) => {
        return this.state.cashsPay.find(
            (cash) => cash.cash === name && cash.quantity > 0
        );
    };

    GetTotalPrice = (items) => {
        if (!items.length) return 0;

        const cost = items.reduce((total, item) => {
            return total + item.price * 1;
        }, 0);

        return cost;
    };

    GetOriginalPrice = (item) => {
        if (!item) return 0;

        return parseFloat(item.price) / item.count;
    };

    GetTotalPaymentPrice = (total_price, total_price_discount) => {
        let total_price_val = parseFloat(total_price);
        let total_price_discount_val = parseFloat(total_price_discount);
        let total = 0;

        if (!isNaN(total_price_val) && !isNaN(total_price_discount_val)) {
            total = total_price_val - total_price_discount_val;
        }

        return total > 0 ? Math.round(total) : 0;
    };

    GetDiscountOrderValue = (items, discount, item) => {
        if (!items.length) return 0;

        const reducer = (a, o) => a + o.count;
        const items_count = items.reduce(reducer, 0);

        let price = 0;
        if (discount && discount.saved && discount.value) {
            let discount_value = parseFloat(discount.value);
            if (!isNaN(discount_value)) {
                if (discount.type === 'percent') {
                    price = discount_value;
                } else if (discount.type === 'price') {
                    price = (discount_value / items_count) * item.count;
                }
            }
            price = (Math.round(price * 100) / 100).toFixed();
        } else if (item.discount) {
            price = item.discount.value;
        }

        return Number(price);
    };

    GetDiscountOrderType = (discount, item) => {
        let type = 'price';

        if (discount && discount.saved && discount.type) {
            type = discount.type;
        } else if (item.discount) {
            type = item.discount.type;
        }
        return type === 'percent' ? '%' : '';
    };

    GetDiscountOrderNote = (discount, item) => {
        let note = '';

        if (discount && discount.saved && discount.note) {
            note = discount.note;
        } else if (item.discount) {
            note = item.discount.note;
        }
        return note;
    };

    //ChatGPT rewrite
    GetTotalDiscountPrice = (items, discount) => {
        // console.log('items: ', items);
        // console.log('discount: ', discount);
        if (!items.length) return 0;

        let total_discount = 0;
        items.forEach((item) => {
            const currentDiscount = discount || item.discount;
            if (!currentDiscount) return;

            if (currentDiscount.type === 'percent') {
                total_discount +=
                    (item.price * item.count * currentDiscount.value) / 100;
            } else if (currentDiscount.type === 'price') {
                total_discount = currentDiscount.value;
                return false;
            }
        });

        return Math.round(total_discount);
    };

    GetTotalTaxFeePrice = (items, tax_fee) => {
        if (!items.length) return 0;

        let total_tax_fee = 0;
        for (let i = 0; i < items.length; i++) {
            let item = items[i];

            // load from create
            if (tax_fee) {
                if (tax_fee.type === 'percent') {
                    let price_percent =
                        (item.price * item.count * tax_fee.value) / 100;
                    total_tax_fee = total_tax_fee + parseFloat(price_percent);
                }
                if (tax_fee.type === 'price') {
                    total_tax_fee = parseFloat(tax_fee.value);
                    break;
                }
            }

            // load from server
            else if (item.tax_fee) {
                if (item.tax_fee.type === 'percent') {
                    let price_percent =
                        (item.price * item.count * item.tax_fee.value) / 100;
                    total_tax_fee = total_tax_fee + parseFloat(price_percent);
                }
                if (item.tax_fee.type === 'price') {
                    total_tax_fee =
                        total_tax_fee + parseFloat(item.tax_fee.value);
                }
            }
        }

        return Math.round(total_tax_fee);
    };

    GetTotalFeeOtherPrice = (items, fee_other) => {
        if (!items.length) return 0;

        let total_fee_other = 0;
        for (let i = 0; i < items.length; i++) {
            let item = items[i];

            // load from create
            if (fee_other) {
                if (fee_other.type === 'percent') {
                    let price_percent =
                        (item.price * item.count * fee_other.value) / 100;
                    total_fee_other =
                        total_fee_other + parseFloat(price_percent);
                }
                if (fee_other.type === 'price') {
                    total_fee_other = parseFloat(fee_other.value);
                    break;
                }
            }

            // load from server
            else if (item.fee_other) {
                if (item.fee_other.type === 'percent') {
                    let price_percent =
                        (item.price * item.count * item.fee_other.value) / 100;
                    total_fee_other =
                        total_fee_other + parseFloat(price_percent);
                }
                if (item.fee_other.type === 'price') {
                    total_fee_other =
                        total_fee_other + parseFloat(item.fee_other.value);
                }
            }
        }

        return Math.round(total_fee_other);
    };

    toggleModalFullOrders = () => {
        this.setState((prev) => ({
            ...prev,
            modalFullOrders: !this.state.modalFullOrders,
        }));
    };

    calTotalPrice = (orders) => {
        let total = 0;
        for (let i = 0; i < orders.length; i++) {
            let order = orders[i];

            total += parseInt(order.price) * order.count;
        }
        return total;
    };

    calcNumberOrder = (orders) => {
        let sum = 0;
        let i = 0;
        let orders_len = orders.length;
        let order = null;
        for (; i < orders_len; i++) {
            order = orders[i];
            sum += order.count;
        }
        return sum;
    };

    //ChatGPT rewrite
    cashRefundToCustomer = () => {
        const { orders, cashsPay, discount_form, tax_fee, fee_other } =
            this.state;

        let total_discount_price = 0;
        if (discount_form.saved) {
            total_discount_price += this.GetTotalDiscountPrice(
                orders,
                discount_form
            );
        }
        let total_taxfee_price = 0;
        if (tax_fee.saved) {
            total_taxfee_price += this.GetTotalTaxFeePrice(orders, tax_fee);
        }
        let total_fee_other_price = 0;
        if (fee_other.saved) {
            total_fee_other_price += this.GetTotalFeeOtherPrice(
                orders,
                fee_other
            );
        }
        this.setState({
            refund_to_customer:
                this.calTotalPrice(orders) +
                total_taxfee_price +
                total_fee_other_price -
                total_discount_price -
                this.totalCustomerPay(cashsPay),
        });
        // console.log('refund_to_customer: ', this.state.refund_to_customer);
    };

    // ORDER
    toggleModalOrder = () => {
        // thêm class name này mới scroll được modal
        if (this.state.modalOrderOpen) {
            document.body.classList.remove('modal-open');
        } else {
            document.body.classList.add('modal-open');
        }
        this.setState({
            modalOrderOpen: !this.state.modalOrderOpen,
        });
    };

    // updateOrders = ({ product, requireCreated = false }) => {
    //     const find_order = this.state.orders.find(
    //         (_product) => _product.id === product.id
    //     );

    //     // Kiểm tra size, topping, ice, sugar, price_sale, active_sale
    //     let price = product.price;
    //     if (product.active_sale) {
    //         price = product.price_sale;
    //     }

    //     // size
    //     if (product.sizeSelectedId.length) {
    //         const find_size = product.size.find((_size) =>
    //             product.sizeSelectedId.includes(_size.id)
    //         );
    //         price = parseFloat(price) + parseFloat(find_size.value);
    //     }
    //     // topping
    //     if (product.toppingSelectedId.length) {
    //         const find_topping = product.topping.filter((_size) =>
    //             product.toppingSelectedId.includes(_size.id)
    //         );

    //         for (let i = 0; i < find_topping.length; i++) {
    //             price = parseFloat(price) + parseFloat(find_topping[i].value);
    //         }
    //     }

    //     // Update giá tiền nếu đã tồn tại
    //     if (find_order) {
    //         const orders = this.state.orders.map((o) =>
    //             o.id === find_order.id
    //                 ? {
    //                       ...o,
    //                       price: price,
    //                       note: product.note,
    //                       iceSelectedValue: product.iceSelectedValue,
    //                       sugarSelectedValue: product.sugarSelectedValue,
    //                       sizeSelectedId: product.sizeSelectedId,
    //                       toppingSelectedId: product.toppingSelectedId,
    //                   }
    //                 : o
    //         );

    //         this.setState({ orders });
    //     } else {
    //         /* Tạo mới nếu chưa có
    //          *
    //          * Chỉ tạo mới khi click nút thêm sản phẩm, input hoặc nhấn nút +, -
    //          */
    //         let orders = this.state.orders;

    //         if (requireCreated) {
    //             orders.push({
    //                 id: product.id,
    //                 name: product.name,
    //                 price: price,
    //                 count: 1,
    //                 note: product.note,
    //                 iceSelectedValue: product.iceSelectedValue,
    //                 sugarSelectedValue: product.sugarSelectedValue,
    //                 sizeSelectedId: product.sizeSelectedId,
    //                 toppingSelectedId: product.toppingSelectedId,
    //                 previewDisplay: false,
    //             });
    //         }
    //         this.setState({ orders });
    //     }
    // };

    //ChatGPT rewrite
    incrementItem = (item) => {
        const { orders } = this.state;
        const find_order = orders.find((order) => order.id === item.id);

        const equar = (a, b) => {
            if (a.length !== b.length) {
                return false;
            } else {
                for (let i = 0; i < a.length; i++) {
                    if (a[i] !== b[i]) {
                        return false;
                    }
                }
                return true;
            }
        };

        if (find_order) {
            const updatedOrders = orders.map((order) =>
                order.id === item.id &&
                order.iceSelectedValue === item.iceSelectedValue &&
                order.sugarSelectedValue === item.sugarSelectedValue &&
                order.sizeName === item.sizeName &&
                (item.toppingName
                    ? equar(order.toppingName, item.toppingName)
                    : order.toppingName === item.toppingName)
                    ? { ...order, count: order.count + 1 }
                    : order
            );
            this.setState({ orders: updatedOrders });
        }
    };

    //ChatGPT rewrite
    decrementItem = (item) => {
        const equar = (a, b) => {
            if (a.length !== b.length) {
                return false;
            } else {
                for (let i = 0; i < a.length; i++) {
                    if (a[i] !== b[i]) {
                        return false;
                    }
                }
                return true;
            }
        };

        const { orders } = this.state;
        const orderIndex = orders.findIndex(
            ({
                id,
                iceSelectedValue,
                sugarSelectedValue,
                sizeName,
                toppingName,
            }) =>
                id === item.id &&
                iceSelectedValue === item.iceSelectedValue &&
                sugarSelectedValue === item.sugarSelectedValue &&
                sizeName === item.sizeName &&
                (item.toppingName
                    ? equar(toppingName, item.toppingName)
                    : toppingName === item.toppingName)
        );

        if (orders[orderIndex].count > 1) {
            orders[orderIndex].count -= 1;
            this.setState({
                orders,
            });
        }
    };

    //ChatGPT rewrite
    decrementCount = async (e, cash) => {
        e.stopPropagation();
        const { cashs, cashsPay } = this.state;
        const index = cashs.findIndex((_cash) => _cash.cash === cash.cash);
        if (cashs[index].quantity > 0) {
            const newCashs = [...cashs];
            newCashs[index] = {
                ...cashs[index],
                quantity: cashs[index].quantity - 1,
            };
            await this.setState({ cashs: newCashs, cashsPay: newCashs });
            if (cashsPay) {
                this.cashRefundToCustomer();
            }
        }
    };

    onHandleChecked = () => {
        this.setState({ isChecked: !this.state.isChecked });
    };

    infoTable = (data) => {
        this.setState((prev) => ({
            ...prev,
            info_table: data,
            selectedFloorTable: 100,
        }));
    };

    handleClickedPrintInvoice = (e, selectedItems) => {
        // let arrayOrdersId = this.state.items_id.map((i) => i.id);

        let tokenStr = localStorage.getItem('access_token');

        return axios
            .put(
                `${END_POINT + '/order/status'}`,
                {
                    arrayId: selectedItems,
                    status_id: STATUS.PAYMENTED,
                },
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then(async (data) => {
                this.socket.emit('refresh-done-order', {
                    user_id: localStorage.getItem('user_id'),
                });
                this.socket.emit('refresh-stock', {
                    user_id: localStorage.getItem('user_id'),
                    // orders_id,
                });
                this.socket.emit('refresh-recent-order', {
                    user_id: localStorage.getItem('user_id'),
                    // floor: this.state.selectedFloorTable,
                });
                this.socket.emit('refresh-pending-order', {
                    user_id: localStorage.getItem('user_id'),
                });
                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['invoice-print'],
                });

                // save discount
                if (this.state.discount_form.saved) {
                    await this.handleSaveDiscount();
                }
                // save tax fee
                if (this.state.tax_fee.saved) {
                    await this.handleSaveTaxFee();
                }

                // save fee other
                if (this.state.fee_other.saved) {
                    await this.handleSaveFeeOther();
                }
            })
            .catch((err) => {});
    };

    getWidth = () => {
        return (
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth
        );
    };

    handleResize = () => {
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
            this.setState({ width: this.getWidth() });
        }, 150);
    };
    
    handleButtonClickCamera = () => {
        const { scannerVisible } = this.state;
        this.setState({ scannerVisible: !scannerVisible });
    };

    render() {
        let {
            menus,
            is_loading_data,
            listsData,
            activeIndex,
            itemsTable,
            isLoadingItemsTable,
            selectedFloorTable,
            floorsActiveId,
            itemsOrderStatus,
            itemsPending,
            itemsPendingBill,
            itemsDone,
            itemsRefund,
            itemsRecentOrder,
            selectedItemsPending,
            selectedItemsDone,
            selectedItemsRefund,
            customer_name,
            customer_phone,
            orders,
            itemsChange,
            cashs,
            cashsPay,

            companyAddress,
            companyWebsite,
            companyLogo,
            companyName,
            billDate,
            pin_print,

            is_open_modal_discount_tax_fee,
            // is_open_modal_discount,
            discount_form,
            // products_select_discount,

            // is_open_modal_taxfee,
            tax_fee,
            // products_select_taxfee,

            // is_open_modal_fee_other,
            fee_other,
            // products_select_fee_other,

            modalFullOrders,
            modalOrderOpen,

            btnDropup,
            isChecked,
            refund_to_customer,
            customer_debt,
            info_table,
            switchChecked: {
                switchCheckedBill,
                switchCheckedBillQr,
                switchCheckedBillBar,
                // switchCheckedTemBar,
                // switchCheckedTemCashier,
                switchCheckedOrderOne,
                switchCheckedOrderTwo,
                // switchCheckedOrderThree,
            },
            scannerVisible,
            dataScan,
            width,
        } = this.state;
        const accentColor = '#5cb7b7';
        const isLaptop = width <= 1500;

        // console.log('menus: ', menus);
        // console.log('selectedItemsPending: ', selectedItemsPending);
        // console.log('selectedItemsRefund: ', selectedItemsRefund);
        // console.log('selectedItemsDone: ', selectedItemsDone);
        // console.log('itemsChange: ', itemsChange);
        console.log('dataScan: ', dataScan);

        return (
            <Fragment>
                <Row>
                    <Colxx xxs='12'>
                        <CompaniesRequired />
                    </Colxx>
                </Row>
                <DiscountTaxFeeModal
                    isOpen={is_open_modal_discount_tax_fee}
                    values_discount={discount_form}
                    values_tax={tax_fee}
                    values_fee={fee_other}
                    // products_discount={products_select_discount}
                    // products_tax={products_select_taxfee}
                    // products_fee={products_select_fee_other}
                    onToggle={this.handleToggleModalDiscountTaxFee}
                    onSubmit={this.handleSubmitModalDiscountTaxFee}
                    onChange={this.handleOnChangeModalDiscountTaxFee}
                />
                {/* <DiscountAddModalOrdersNow
                    isOpen={is_open_modal_discount}
                    values={discount_form}
                    products={products_select_discount}
                    onToggle={this.handleToggleModalDiscount}
                    onSubmit={this.handleSubmitModalDiscount}
                    onChange={this.handleOnChangeModalDiscount}
                /> */}
                {/* <TaxFeeAddModalOrdersNow
                    isOpen={is_open_modal_taxfee}
                    values={tax_fee}
                    products={products_select_taxfee}
                    onToggle={this.handleToggleModalTaxFee}
                    onSubmit={this.handleSubmitModalTaxFee}
                    onChange={this.handleOnChangeModalTaxFee}
                />
                <FeeOtherAddModalOrdersNow
                    isOpen={is_open_modal_fee_other}
                    values={fee_other}
                    products={products_select_fee_other}
                    onToggle={this.handleToggleModalFeeOther}
                    onSubmit={this.handleSubmitModalFeeOther}
                    onChange={this.handleOnChangeModalFeeOther}
                /> */}
                <Row>
                    <Colxx
                        sm='12'
                        md='12'
                        lg={isLaptop ? 7 : 8}
                        xl={isLaptop ? 7 : 8}
                        id='table-chair-remove-card'
                        className={scannerVisible ? 'd-none' : ''}
                    >
                        <TableChairs
                            menus={menus}
                            is_loading_data={is_loading_data}
                            renderCategories={this.renderCategories}
                            onClickCategory={this.onClickCategory}
                            listsData={listsData}
                            activeIndex={activeIndex}
                            items={itemsTable}
                            infoTable={this.infoTable}
                            isLoading={isLoadingItemsTable}
                            selectedFloorTable={selectedFloorTable}
                            onUpdateSelectedFloorTable={
                                this.onUpdateSelectedFloorTable
                            }
                            orders={itemsRecentOrder}
                            floorsActiveId={floorsActiveId}
                            onActions={this.onActions}
                            onToggleDetail={this.onToggleDetail}
                            onClickImage={(product) =>
                                this.onClickImage(product)
                            }
                            billDate={billDate}
                            dataScan={dataScan}
                            // data-tut='reactour__3'
                        />
                    </Colxx>
                    {orders.length ? (
                        <div className='d-block d-md-none m-0-auto card-body-custom order-btn-preview order-btn-preview-sticky'>
                            <Button
                                color='primary'
                                size='lg'
                                className='top-right-button px-2'
                                onClick={this.toggleModalOrder}
                                block
                            >
                                <Row>
                                    <Colxx xxs='5' md='5' xl='5'>
                                        <IntlMessages id='general.btn-order-preview' />{' '}
                                    </Colxx>
                                    <Colxx
                                        xxs='4'
                                        md='4'
                                        xl='4'
                                        className='fw-100'
                                    >
                                        {this.calcNumberOrder(orders) +
                                            ' sản phẩm'}{' '}
                                    </Colxx>
                                    <Colxx xxs='3' md='3' xl='3'>
                                        {AddCommaNumber(
                                            this.calTotalPrice(orders)
                                        )}
                                        <sup className='fz-sup'>đ</sup>
                                    </Colxx>
                                </Row>
                            </Button>
                        </div>
                    ) : null}
                    <ProductOrderModalMenu
                        isOpen={modalOrderOpen}
                        onToggleModal={this.toggleModalOrder}
                        orders={orders}
                        incrementItem={this.incrementItem}
                        decrementItem={this.decrementItem}
                        phone={customer_phone}
                        name={customer_name}
                        customer_debt={customer_debt}
                        companyName={companyName}
                        onChange={this.onChange}
                        onRemoveOrder={this.onRemoveOrder}
                        handleSubmit={() => this.handleSubmit()}
                        calTotalPrice={this.calTotalPrice}
                        refund_to_customer={refund_to_customer}
                    />
                    {orders.length || switchCheckedOrderOne ? (
                        <Colxx
                            className='d-none d-md-flex flex-column justify-content-between h-82vh position-sticky-custom'
                            lg={isLaptop ? 5 : 4}
                            xl={isLaptop ? 5 : 4}
                        >
                            <Card
                                className='h-100 order-display'
                                data-tut='reatour__1'
                            >
                                <CardHeader className='text-center p-3'>
                                    {info_table
                                        ? `BÀN ${info_table.item.identify} - CÁC SẢN PHẨM ĐANG ĐẶT`
                                        : 'CÁC SẢN PHẨM ĐANG ĐẶT'}
                                </CardHeader>
                                <CardBody className='d-flex flex-column p-0 table-right scroll'>
                                    <PerfectScrollbar
                                        options={{
                                            suppressScrollX: true,
                                            wheelPropagation: false,
                                        }}
                                        id='table-orders'
                                    >
                                        <ListOrders
                                            orders={orders}
                                            cashs={cashs}
                                            cashsPay={cashsPay}
                                            discount_form={discount_form}
                                            tax_fee={tax_fee}
                                            fee_other={fee_other}
                                            isChecked={isChecked}
                                            refund_to_customer={
                                                refund_to_customer
                                            }
                                            incrementItem={this.incrementItem}
                                            decrementItem={this.decrementItem}
                                            cashFill={this.cashFill}
                                            totalPay={this.totalPay}
                                            decrementCount={this.decrementCount}
                                            totalCustomerPay={
                                                this.totalCustomerPay
                                            }
                                            removeOrders={this.removeOrders}
                                            onRemoveOrder={this.onRemoveOrder}
                                            getDiscountOrderValue={
                                                this.GetDiscountOrderValue
                                            }
                                            getDiscountOrderType={
                                                this.GetDiscountOrderType
                                            }
                                            getDiscountOrderNote={
                                                this.GetDiscountOrderNote
                                            }
                                            getTotalDiscountPrice={
                                                this.GetTotalDiscountPrice
                                            }
                                            getTotalTaxFeePrice={
                                                this.GetTotalTaxFeePrice
                                            }
                                            getTotalFeeOtherPrice={
                                                this.GetTotalFeeOtherPrice
                                            }
                                            calTotalPrice={this.calTotalPrice}
                                            handleToggleModalDiscountTaxFee={
                                                this
                                                    .handleToggleModalDiscountTaxFee
                                            }
                                            onHandlerDeleteOrderPending={
                                                this.onHandlerDeleteOrderPending
                                            }
                                            onHandleChecked={
                                                this.onHandleChecked
                                            }
                                            handleRemoveValueModal={
                                                this.handleRemoveValueModal
                                            }
                                        />
                                    </PerfectScrollbar>
                                </CardBody>
                                <FooterOrders
                                    isOpen={this.state.btnDropup}
                                    toggle={() => {
                                        this.setState({
                                            btnDropup: !this.state.btnDropup,
                                        });
                                    }}
                                    customer_name={customer_name}
                                    customer_phone={customer_phone}
                                    onChange={this.onChange}
                                    handleSubmit={(payment) =>
                                        this.handleSubmit(payment)
                                    }
                                    btnDropup={btnDropup}
                                    itemsPending={orders}
                                    companyName={companyName}
                                    companyAddress={companyAddress}
                                    companyLogo={companyLogo}
                                    companyWebsite={companyWebsite}
                                    billDate={billDate}
                                    discount={
                                        discount_form.saved
                                            ? discount_form
                                            : null
                                    }
                                    tax_fee={tax_fee.saved ? tax_fee : null}
                                    fee_other={
                                        fee_other.saved ? fee_other : null
                                    }
                                    info_table={info_table}
                                    switchCheckedBill={switchCheckedBill}
                                    switchCheckedBillBar={switchCheckedBillBar}
                                    refund_to_customer={refund_to_customer}
                                    isChecked={isChecked}
                                />
                            </Card>
                        </Colxx>
                    ) : itemsChange.length ? (
                        <Colxx
                            className='d-none d-md-flex flex-column justify-content-between h-82vh position-sticky-custom'
                            lg={isLaptop ? 5 : 4}
                            xl={isLaptop ? 5 : 4}
                        >
                            <Card
                                className='h-100 order-display'
                                data-tut='reatour__1'
                            >
                                <CardHeader className='text-center p-3'>
                                    {info_table
                                        ? `BÀN ${info_table.item.identify} - ĐIỀU CHỈNH ĐƠN HÀNG`
                                        : 'ĐIỀU CHỈNH ĐƠN HÀNG'}
                                </CardHeader>
                                <CardBody className='d-flex flex-column p-0 table-right scroll'>
                                    <PerfectScrollbar
                                        options={{
                                            suppressScrollX: true,
                                            wheelPropagation: false,
                                        }}
                                        id='table-orders'
                                    >
                                        <ListOrders
                                            orders={itemsChange}
                                            cashs={cashs}
                                            cashsPay={cashsPay}
                                            discount_form={discount_form}
                                            tax_fee={tax_fee}
                                            fee_other={fee_other}
                                            isChecked={isChecked}
                                            refund_to_customer={
                                                refund_to_customer
                                            }
                                            itemsChange={itemsChange}
                                            incrementItem={this.incrementItem}
                                            decrementItem={this.decrementItem}
                                            cashFill={this.cashFill}
                                            totalPay={this.totalPay}
                                            decrementCount={this.decrementCount}
                                            totalCustomerPay={
                                                this.totalCustomerPay
                                            }
                                            removeOrders={this.removeOrders}
                                            onRemoveOrder={this.onRemoveOrder}
                                            getDiscountOrderValue={
                                                this.GetDiscountOrderValue
                                            }
                                            getDiscountOrderType={
                                                this.GetDiscountOrderType
                                            }
                                            getDiscountOrderNote={
                                                this.GetDiscountOrderNote
                                            }
                                            getTotalDiscountPrice={
                                                this.GetTotalDiscountPrice
                                            }
                                            getTotalTaxFeePrice={
                                                this.GetTotalTaxFeePrice
                                            }
                                            getTotalFeeOtherPrice={
                                                this.GetTotalFeeOtherPrice
                                            }
                                            calTotalPrice={this.calTotalPrice}
                                            handleToggleModalDiscountTaxFee={
                                                this
                                                    .handleToggleModalDiscountTaxFee
                                            }
                                            onHandlerDeleteOrderPending={
                                                this.onHandlerDeleteOrderPending
                                            }
                                            onHandleChecked={
                                                this.onHandleChecked
                                            }
                                            handleRemoveValueModal={
                                                this.handleRemoveValueModal
                                            }
                                        />
                                    </PerfectScrollbar>
                                </CardBody>
                                <FooterOrders
                                    isOpen={this.state.btnDropup}
                                    toggle={() => {
                                        this.setState({
                                            btnDropup: !this.state.btnDropup,
                                        });
                                    }}
                                    customer_name={customer_name}
                                    customer_phone={customer_phone}
                                    onChange={this.onChange}
                                    handleSubmit={(payment) =>
                                        this.handleSubmit(payment)
                                    }
                                    handleSubmitChange={this.handleSubmitChange}
                                    removeOrders={this.removeOrders}
                                    btnDropup={btnDropup}
                                    itemsPending={itemsChange}
                                    companyName={companyName}
                                    companyAddress={companyAddress}
                                    companyLogo={companyLogo}
                                    companyWebsite={companyWebsite}
                                    billDate={billDate}
                                    discount={
                                        discount_form.saved
                                            ? discount_form
                                            : null
                                    }
                                    tax_fee={tax_fee.saved ? tax_fee : null}
                                    fee_other={
                                        fee_other.saved ? fee_other : null
                                    }
                                    info_table={info_table}
                                    switchCheckedBill={switchCheckedBill}
                                    switchCheckedBillBar={switchCheckedBillBar}
                                    refund_to_customer={refund_to_customer}
                                    isChecked={isChecked}
                                    itemsChange={itemsChange}
                                />
                            </Card>
                        </Colxx>
                    ) : (
                        <Colxx
                            sm='12'
                            md='12'
                            lg={isLaptop ? 5 : 4}
                            xl={isLaptop ? 5 : 4}
                            className='d-none d-md-flex flex-column mb-4 dashboard-right position-sticky-custom h-82vh'
                        >
                            <ListOrderStatus
                                itemsOrderStatus={itemsOrderStatus}
                                itemsPending={itemsPending}
                                itemsPendingBill={itemsPendingBill}
                                itemsDone={itemsDone}
                                onCheckItemPending={(e, id) =>
                                    this.onCheckItemPending(e, id)
                                }
                                onCheckItemRefund={this.onCheckItemRefund}
                                onCheckItemDone={this.onCheckItemDone}
                                onActions={this.onActions}
                                selectedItemsPending={selectedItemsPending}
                                selectedItemsRefund={selectedItemsRefund}
                                selectedItemsDone={selectedItemsDone}
                                companyName={companyName}
                                companyAddress={companyAddress}
                                companyLogo={companyLogo}
                                companyWebsite={companyWebsite}
                                billDate={billDate}
                                handleClickedPrintInvoice={
                                    this.handleClickedPrintInvoice
                                }
                                switchCheckedBillQr={switchCheckedBillQr}
                                itemsTable={itemsTable}
                                switchCheckedOrderTwo={switchCheckedOrderTwo}
                                pin_print={pin_print}
                            />
                            <Card className='text-center text-one mt-auto'>
                                <CardBody
                                    className='p-3 cursor-pointer'
                                    onClick={this.toggleModalFullOrders}
                                >
                                    Tổng hợp trạng thái các sản phẩm
                                </CardBody>
                                <FullModalAllOrders
                                    isOpen={modalFullOrders}
                                    toggle={this.toggleModalFullOrders}
                                    itemsRecentOrder={itemsRecentOrder}
                                    itemsDone={itemsDone}
                                    itemsRefund={itemsRefund}
                                    isHight={true}
                                />
                            </Card>
                        </Colxx>
                    )}
                </Row>
                {scannerVisible ? (
                    <i
                        className='simple-icon-close camera d-md-none'
                        onClick={this.handleButtonClickCamera}
                    />
                ) : (
                    <i
                        className='simple-icon-camera camera d-md-none'
                        onClick={this.handleButtonClickCamera}
                    />
                )}
                {scannerVisible && (
                    <BarcodeScannerComponent
                        width={500}
                        height={500}
                        onUpdate={(err, result) => {
                            if (result)
                                this.setState({ dataScan: result.text });
                            else this.setState({ dataScan: '' });
                        }}
                    />
                )}
            </Fragment>
        );
    }
}
export default injectIntl(DefaultDashboard);
