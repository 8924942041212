import React, { memo } from 'react';

const FooterCustomer = () => {
    return (
        <a
            href='https://docs.vmass.vn/quan-ly/tai-san'
            target='_blank'
            className='mb-0 pl-2'
        >
            Hướng dẫn
        </a>
    );
};

export default memo(FooterCustomer);
