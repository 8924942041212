/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
// export const END_POINT = 'http://localhost:5000';
// export const END_POINT_SOCKET = 'localhost:5000';
export const END_POINT = 'https://server.vmass.vn';
export const END_POINT_SOCKET = 'server.vmass.vn';
export const PASSWORD_DOWNLOAD_REPORT = 'vmass';

export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
    { id: 'en', name: 'English - LTR', direction: 'ltr' },
    { id: 'vi', name: 'Tiếng Việt', direction: 'ltr' },
    { id: 'es', name: 'Español', direction: 'ltr' },
    { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
    apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
    authDomain: 'gogo-react-login.firebaseapp.com',
    databaseURL: 'https://gogo-react-login.firebaseio.com',
    projectId: 'gogo-react-login',
    storageBucket: 'gogo-react-login.appspot.com',
    messagingSenderId: '216495999563',
};

export const searchPath = '/app/pages/search';
export const servicePath = END_POINT + '/product';

/* 
Color Options: (old version)
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const themeColorStorageKey = '__theme_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.blueolympic';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const colors = [
    'bluenavy',
    'blueyale',
    'blueolympic',
    'greenmoss',
    'greenlime',
    'purplemonster',
    'orangecarrot',
    'redruby',
    'yellowgranola',
    'greysteel',
];
