import React, { Component } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Badge,
    TabContent,
    ButtonDropdown,
    CustomInput,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
} from 'reactstrap';
import axios from 'axios';
import { END_POINT } from '../../../constants/defaultValues';
import {
    SetItemsSelected,
    NotificatioErrController,
    NotificatioSucController,
    AddCommaNumber,
} from '../../../helpers/Utils';
import {
    ListItemLeftForm,
    PageHeading,
    ComponentToPrintWrapper,
} from '../../../containers/pages/order';
import { Pagination } from '../../../containers/pages/general';
import { isD, isR } from '../../../helpers/Roles';
import LogsHelper from '../../../helpers/Logs';
import IntlMessages from '../../../helpers/IntlMessages';
import classNames from 'classnames';
import { Separator } from '../../../components/common/CustomBootstrap';

const ROLE_ALIAS = 'order';

class ContentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: {
                pageSizes: [8, 12, 24],
                selectedPageSize: 8,
                currentPage: 1,
                totalPage: 1,
                totalItem: 0,
                search: '',
            },
            selectedItems: [],
            data: [],
            ordersDeleted: [],
            is_loading_data: true,
            show: {
                add: false,
                update: false,
                viewonly: true,
                print: false,
            },
            activeTab: '1',

            // password
            password: '',
            isOpenModalPassword: false,
            passwordFuncCallback: null,
        };
    }

    componentDidMount() {
        this.dataListRender();
        this.getOrderDeleted();
    }

    onChangePage = (page) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    currentPage: page,
                },
            }),
            () => this.dataListRender()
        );
    };

    onSearchKey = (e) => {
        if (!e) return false;

        if (e.target) {
            let {
                target: { value },
            } = e;
            let v = value;
            if (value === undefined) {
                v = e.target.firstElementChild.value; // parent to child

                value = v.toLowerCase();
                this.setState(
                    (prev) => ({
                        ...prev,
                        page: {
                            ...prev.page,
                            search: value,
                        },
                    }),
                    () => this.dataListRender()
                );
            } else {
                value = value.toLowerCase();
                this.setState(
                    (prev) => ({
                        ...prev,
                        page: {
                            ...prev.page,
                            search: value,
                        },
                    }),
                    () => this.dataListRender()
                );
            }
        }

        if (e.key === 'Enter') {
            let value = e.target.value.toLowerCase();
            this.setState(
                (prev) => ({
                    ...prev,
                    page: {
                        ...prev.page,
                        search: value,
                    },
                }),
                () => this.dataListRender()
            );
        }
    };

    onChangePageSize = (size) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    selectedPageSize: size,
                    currentPage: 1,
                },
            }),
            () => this.dataListRender()
        );
    };

    /** Axios func helper */
    dataListRender = () => {
        const { selectedPageSize, currentPage, search } = this.state.page;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(
                `${
                    END_POINT + '/bill/invoice'
                }?pageSize=${selectedPageSize}&currentPage=${currentPage}&search=${search}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((data) => {
                // console.log('data: ', data.data);
                this.setState((prev) => ({
                    ...prev,
                    items: data.data,
                    is_loading_data: false,
                    page: {
                        ...prev.page,
                        totalPage: data.totalPage,
                        totalItem: data.totalItem,
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    getOrderDeleted = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT + '/order/order_deleted'}`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                // console.log('ordersDeleted: ', data);
                this.setState((prev) => ({
                    ...prev,
                    ordersDeleted: data.data,
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onContextMenu = (e, data) => {
        return true;
    };

    getOrdersIdFromSelected = (selected) => {
        let orders = [];
        for (let i = 0; i < selected.length; i++) {
            let bill = this.state.items.find(
                (_item) => _item.pin === selected[i]
            );

            if (bill) {
                let ids = bill.data.map((_order) => _order.id);
                orders = orders.concat(ids);
            }
        }
        return orders;
    };

    isMultiBill(items) {
        if (items.length < 2) {
            return false;
        }

        return true;
    }

    handleChangeSelectAll = (isToggle) => {
        if (this.state.selectedItems.length >= this.state.items.length) {
            if (isToggle) {
                this.setState({
                    selectedItems: [],
                });
            }
        } else {
            this.setState({
                selectedItems: this.state.items.map((x) => x.id),
            });
        }
        document.activeElement.blur();
        return false;
    };
    /** Axios func helper */

    /** State func helper */
    onChangePage = (page) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    currentPage: page,
                },
            }),
            () => this.dataListRender()
        );
    };

    onSearchKey = (e) => {
        if (!e) return false;

        if (e.target) {
            let {
                target: { value },
            } = e;
            let v = value;
            if (value === undefined) {
                v = e.target.firstElementChild.value; // parent to child

                value = v.toLowerCase();
                this.setState(
                    (prev) => ({
                        ...prev,
                        page: {
                            ...prev.page,
                            search: value,
                        },
                    }),
                    () => this.dataListRender()
                );
            } else {
                value = value.toLowerCase();
                this.setState(
                    (prev) => ({
                        ...prev,
                        page: {
                            ...prev.page,
                            search: value,
                        },
                    }),
                    () => this.dataListRender()
                );
            }
        }

        if (e.key === 'Enter') {
            let value = e.target.value.toLowerCase();
            this.setState(
                (prev) => ({
                    ...prev,
                    page: {
                        ...prev.page,
                        search: value,
                    },
                }),
                () => this.dataListRender()
            );
        }
    };

    onChangePageSize = (size) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    selectedPageSize: size,
                    currentPage: 1,
                },
            }),
            () => this.dataListRender()
        );
    };

    onClickedItem = (item, callback, args) => {
        let selectedSet = SetItemsSelected(item.pin, this.state.selectedItems);

        this.setState(
            (prev) => ({
                ...prev,
                show: {
                    ...prev.show,
                    update: true,
                    viewonly: true,
                },
                selectedItems: selectedSet, //[item.id]
            }),
            () => {
                callback(args[0], args[1], args[2]);
            }
        );
    };

    onClickedCheckbox = (id, callback) => {
        let selectedSet = SetItemsSelected(id, this.state.selectedItems);

        this.setState((prev) => ({
            ...prev,
            selectedItems: selectedSet,
        }));
    };
    /** State func helper */

    onDeleteBill = (pin) => {
        const arrayPin = [];
        arrayPin.push(pin);
        console.log('arrayPin: ', arrayPin);

        // const { items } = this.state;
        // const newItems = items.filter((item) => item.pin !== pin);
        // this.setState((prevState) => ({
        //     ...prevState,
        //     items: newItems,
        // }));

        if (!isD(ROLE_ALIAS)) return false;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .delete(`${END_POINT + '/order'}`, {
                data: {
                    arrayPin,
                },
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['bill-delete'],
                });

                this.dataListRender();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    toggleTab = (x) => {
        this.setState({
            activeTab: x,
        });
    };

    render() {
        const {
            // currentPage,
            items,
            ordersDeleted,
            // selectedPageSize,
            selectedItems,
            is_loading_data,
            show,
            page,
        } = this.state;
        console.log('ordersDeleted: ', ordersDeleted);

        if (!isR(ROLE_ALIAS))
            return (
                <Card className='text-muted mh-40vh h-100'>
                    <CardBody className='d-flex justify-content-center align-items-center'>
                        Bạn không có quyền sử dụng tính năng này!
                    </CardBody>
                </Card>
            );

        const { match } = this.props;
        // const startIndex = (currentPage - 1) * selectedPageSize;
        // const endIndex = currentPage * selectedPageSize;

        // const is_multi_bill = this.isMultiBill(selectedItems);

        return is_loading_data ? (
            <div className='loading' />
        ) : (
            <div className='disable-text-selection'>
                <PageHeading
                    heading='menu.invoice'
                    match={match}
                    setting={page}
                    orders_id={this.getOrdersIdFromSelected(selectedItems)}
                    itemsLength={items ? items.length : 0}
                    selectedItemsLength={
                        selectedItems ? selectedItems.length : 0
                    }
                    onSearchKeyFunc={this.onSearchKey}
                    changePageSizeFunc={this.onChangePageSize}
                    handleChangeSelectAllFunc={this.handleChangeSelectAll}
                />
                {items?.length === 0 ? (
                    <Card className='mt-4 mb-4 mh-53vh'>
                        <CardBody>Chưa có dữ liệu...</CardBody>
                    </Card>
                ) : (
                    <>
                        <Nav
                            tabs
                            className='d-flex justify-content-between separator-tabs ml-0 mb-5'
                        >
                            <NavItem>
                                <NavLink
                                    className={classNames({
                                        active: this.state.activeTab === '1',
                                        'nav-link': true,
                                    })}
                                    onClick={() => this.toggleTab('1')}
                                    location={{}}
                                    to='#'
                                >
                                    <IntlMessages id='general.info' />
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classNames({
                                        active: this.state.activeTab === '2',
                                        'nav-link': true,
                                    })}
                                    onClick={() => this.toggleTab('2')}
                                    location={{}}
                                    to='#'
                                >
                                    <span className='mr-2'>
                                        <i className='simple-icon-trash'></i>
                                    </span>
                                    ĐÃ XOÁ
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent
                            activeTab={this.state.activeTab}
                            className='mh-53vh'
                        >
                            <TabPane tabId='1'>
                                <Row className='content-page__row'>
                                    <Col
                                        xs='12'
                                        sm='12'
                                        md='12'
                                        lg={selectedItems.length ? 7 : 12}
                                        xl={selectedItems.length ? 7 : 12}
                                    >
                                        {items?.length &&
                                            items?.map((bill, idx) => {
                                                return (
                                                    <ListItemLeftForm
                                                        onClickedItem={
                                                            this.onClickedItem
                                                        }
                                                        onChangeCheckbox={
                                                            this
                                                                .onClickedCheckbox
                                                        }
                                                        selectedItems={
                                                            selectedItems
                                                        }
                                                        setting={show}
                                                        key={idx}
                                                        idx={idx}
                                                        item={bill}
                                                        isDisabledDelete={false}
                                                        onDeleteBill={
                                                            this.onDeleteBill
                                                        }
                                                    />
                                                );
                                            })}
                                        <Pagination
                                            currentPage={page.currentPage}
                                            totalPage={page.totalPage}
                                            onChangePage={(i) =>
                                                this.onChangePage(i)
                                            }
                                        />
                                    </Col>
                                    {selectedItems.length ? (
                                        <Col
                                            xs='12'
                                            sm='12'
                                            md='12'
                                            lg='5'
                                            xl='5'
                                        >
                                            <ComponentToPrintWrapper
                                                orders_id={this.getOrdersIdFromSelected(
                                                    selectedItems
                                                )}
                                            />
                                        </Col>
                                    ) : null}
                                </Row>
                            </TabPane>
                            <TabPane tabId='2'>
                                {ordersDeleted?.length ? (
                                    ordersDeleted?.map((item) => {
                                        return (
                                            <Card className='mb-3 custom-shadow'>
                                                <div className='d-flex flex-row cursor-pointer'>
                                                    <div
                                                        className={
                                                            'd-flex flex-grow-1 min-width-zero'
                                                        }
                                                    >
                                                        <div
                                                            className={classNames(
                                                                'd-block position-relative',
                                                                {
                                                                    disable:
                                                                        item.softHide
                                                                            ? 'disable'
                                                                            : '',
                                                                }
                                                            )}
                                                        >
                                                            <img
                                                                alt={
                                                                    item.product
                                                                        .name
                                                                }
                                                                src={
                                                                    item.product.image.indexOf(
                                                                        'http'
                                                                    ) >= 0
                                                                        ? item
                                                                              .product
                                                                              .image
                                                                        : `${END_POINT}/public/${item.product.image}`
                                                                }
                                                                onError={(
                                                                    e
                                                                ) => {
                                                                    e.target.onerror =
                                                                        null;
                                                                    e.target.src =
                                                                        'http://via.placeholder.com/320x240';
                                                                }}
                                                                className='list-thumbnail responsive border-0'
                                                            />
                                                            {item.bestter ? (
                                                                <Badge
                                                                    className='position-absolute badge-top-right'
                                                                    color={
                                                                        'success'
                                                                    }
                                                                    pill
                                                                >
                                                                    {
                                                                        'Khuyên dùng'
                                                                    }
                                                                </Badge>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                        <div
                                                            // onClick={() => {
                                                            //     this.onClickedItem(item);
                                                            // }}
                                                            className={classNames(
                                                                'card-body card-body-custom w-100 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center'
                                                            )}
                                                        >
                                                            <div className='list-item-heading mb-1 truncate d-block'>
                                                                <span>
                                                                    {
                                                                        item
                                                                            .product
                                                                            .name
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className='w-20 w-sm-100'>
                                                                <Badge
                                                                    color='danger'
                                                                    pill
                                                                    className='spacer-around-1'
                                                                >
                                                                    Order đã
                                                                    được xoá, có
                                                                    thể phục
                                                                    hồi!
                                                                </Badge>
                                                            </div>
                                                            <div className='w-20 w-sm-100'>
                                                                {item.active_sale ? (
                                                                    <>
                                                                        <Badge
                                                                            color='primary'
                                                                            pill
                                                                            className='spacer-around-1'
                                                                        >
                                                                            {AddCommaNumber(
                                                                                item.price_sale
                                                                            )}
                                                                        </Badge>
                                                                        <Badge
                                                                            color='light'
                                                                            pill
                                                                            className='mb-2 spacer-around-1'
                                                                        >
                                                                            <del>
                                                                                {AddCommaNumber(
                                                                                    item.price
                                                                                )}
                                                                            </del>
                                                                        </Badge>
                                                                    </>
                                                                ) : (
                                                                    <Badge
                                                                        color='primary'
                                                                        pill
                                                                    >
                                                                        {AddCommaNumber(
                                                                            item.price
                                                                        )}
                                                                    </Badge>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className='custom-control custom-checkbox pl-1 align-self-center pr-4'>
                                                            <ButtonDropdown
                                                            // isOpen={dropdownSplitOpen}
                                                            // toggle={this.toggleSplit}
                                                            // onClick={() => {
                                                            //     this.onClickedItem(item);
                                                            // }}
                                                            >
                                                                <div className='cursor-pointer btn btn-lg btn-primary pl-4 pr-0 check-button check-all'>
                                                                    <CustomInput
                                                                        className='custom-checkbox mb-0 d-inline-block'
                                                                        type='checkbox'
                                                                        // id={idx}
                                                                        // checked={selectedItems.includes(
                                                                        //     item.id
                                                                        // )}
                                                                        // onChange={() => {
                                                                        //     onChangeCheckbox(item.id);
                                                                        // }}
                                                                        label={
                                                                            <span
                                                                                className={`custom-control-label`}
                                                                            />
                                                                        }
                                                                    />
                                                                </div>
                                                                <DropdownToggle
                                                                    caret
                                                                    color='primary'
                                                                    className='dropdown-toggle-split'
                                                                />
                                                                <DropdownMenu
                                                                    right
                                                                >
                                                                    <DropdownItem
                                                                    // className={classNames('text-left', {
                                                                    //     disable: isDisabledUpdate,
                                                                    // })}
                                                                    // onClick={() =>
                                                                    //     isDisabledUpdate
                                                                    //         ? null
                                                                    //         : onUpdate(item)
                                                                    // }
                                                                    >
                                                                        <i className='simple-icon-note pr-2' />
                                                                        Phục hồi
                                                                    </DropdownItem>
                                                                    <Separator />
                                                                    <DropdownItem
                                                                    // className={classNames('text-left red', {
                                                                    //     disable: isDisabledDelete,
                                                                    // })}
                                                                    // onClick={() =>
                                                                    //     isDisabledDelete
                                                                    //         ? null
                                                                    //         : onDelete(item)
                                                                    // }
                                                                    >
                                                                        <>
                                                                            <i className='simple-icon-trash pr-2' />
                                                                            <IntlMessages id='pages.delete-forever' />
                                                                        </>
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </ButtonDropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        );
                                    })
                                ) : (
                                    <Card className='mt-4 mb-4 mh-53vh'>
                                        <CardBody>
                                            Chưa có order được điều chỉnh!
                                        </CardBody>
                                    </Card>
                                )}
                            </TabPane>
                        </TabContent>
                    </>
                )}
            </div>
        );
    }
}

export default ContentComponent;
