import React, { Fragment } from 'react';
import { Card, CardBody } from 'reactstrap';
import { getCompanyImage } from '../../../helpers/Utils';
import CustomTooltip from '../../../components/CustomTooltip';
import { ParseContent } from './Utils';

const MessageCard = ({ company, item, idx, pin_id }) => {
    return (
        <Fragment>
            <span
                className={`inline-flex float-${
                    item.sender_id != pin_id
                        ? 'left'
                        : 'right flex-direction-row-reverse'
                }`}
            >
                {item.isShowAvatar ? (
                    <img
                        alt={'#'}
                        src={
                            item.sender_id !== pin_id
                                ? getCompanyImage(company)
                                : '/assets/img/profile-pic-l.jpg'
                        }
                        className='message-avatar img-thumbnail border-0 rounded-circle align-self-center xsmall m-10px'
                    />
                ) : (
                    <span
                        className={`message-avatar border-0 rounded-circle align-self-center xsmall m-10px float-${
                            (item.user_id || item.sender_id) !=
                            localStorage.getItem('b')
                                ? 'left'
                                : 'right'
                        }`}
                    />
                )}
                <Card
                    className={`min-width-150px d-inline-block mb-1 float-${
                        item.sender_id !== pin_id ? 'left' : 'right'
                    }`}
                >
                    <CardBody
                        className='p-3 max-width-250px'
                        id={`Tooltip-${idx}-gsms`}
                    >
                        <div className='mb-0 text-semi-muted'>
                            <ParseContent content={item.message} />
                        </div>
                        <CustomTooltip
                            placement='top'
                            id={`${idx}-gsms`}
                            body={`${item.date + ' - ' + item.time}`}
                            delay={{ show: 2000, hide: 0 }}
                        />
                    </CardBody>
                </Card>
            </span>

            <div className='clearfix' />
        </Fragment>
    );
};

export default MessageCard;
