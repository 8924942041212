import React from 'react'
import {
    Input
} from 'reactstrap'
import { AddCommaNumber } from '../../helpers/Utils';

const ContentComponent = (props) => {
    return (
        <Input
            name={props.name || "undefined"}
            disabled={props.disabled || false}
            value={AddCommaNumber(props.value)}
            onChange={props.onChange}
            type="text"
        />
    )
}

export default ContentComponent;