import React, { Component } from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import AppLayout from '../../layout/AppLayout';
import dashboards from './dashboards';
import calendars from './calendars';
import pages from './pages';
// import faq from './faq';
import breakeven from './breakeven';
import applications from './applications';

class App extends Component {
    render() {
        const { match } = this.props;

        return (
            <AppLayout>
                <Switch>
                    <Redirect
                        exact
                        from={`${match.url}/`}
                        to={`${match.url}/dashboards`}
                    />
                    <Route
                        path={`${match.url}/dashboards`}
                        component={dashboards}
                    />
                    <Route
                        path={`${match.url}/calendars`}
                        component={calendars}
                    />
                    <Route
                        path={`${match.url}/applications`}
                        component={applications}
                    />
                    <Route path={`${match.url}/pages`} component={pages} />
                    <Route
                        path={`${match.url}/breakeven`}
                        component={breakeven}
                    />
                    {/* <Route path={`${match.url}/faq`} component={faq} /> */}
                    <Redirect to='/error' />
                </Switch>
            </AppLayout>
        );
    }
}
const mapStateToProps = ({ menu }) => {
    const { containerClassnames } = menu;
    return { containerClassnames };
};

export default withRouter(connect(mapStateToProps, {})(App));
