import React, { Component } from 'react';
import { Row, Card, CardTitle, Form, Label, Input, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Colxx } from '../../components/common/CustomBootstrap';
import IntlMessages from '../../helpers/IntlMessages';
import axios from 'axios';
import { END_POINT } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            domain: window.location.host.split('.')[0],
            code: '',
            password: '',
            repassword: '',
            isSendCode: false,
        };

        this.handleSubmitGetCode = this.handleSubmitGetCode.bind(this);
        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.handleSubmitChangePassword =
            this.handleSubmitChangePassword.bind(this);
    }

    handleChangeValue(e) {
        const { name, value } = e.target;

        this.setState({
            [name]: value,
        });
    }

    handleSubmitGetCode() {
        let { username, domain } = this.state;
        axios
            .post(`${END_POINT + '/user/forget-password'}`, {
                username,
                domain,
            })
            .then((res) => {
                if (res.status === 200) {
                    NotificationManager.primary(
                        res.data.responseText,
                        'Thành công',
                        3000,
                        null,
                        null,
                        'filled'
                    );

                    this.setState({
                        isSendCode: true,
                    });
                }
            })
            .catch((err) => {
                NotificationManager.error(
                    err.response.data.responseText,
                    'Lỗi !',
                    3000,
                    null,
                    null,
                    'filled'
                );
            });
        return false;
    }

    handleSubmitChangePassword() {
        let { code, password, repassword } = this.state;

        if (code === '' || password === '' || repassword === '') {
            NotificationManager.error(
                'Nhập đầy đủ dữ liệu',
                'Lỗi !',
                3000,
                null,
                null,
                'filled'
            );
            return false;
        }

        if (password !== repassword) {
            NotificationManager.error(
                'Mật khẩu không khớp nhau',
                'Lỗi !',
                3000,
                null,
                null,
                'filled'
            );
            return false;
        }

        axios
            .post(`${END_POINT + '/user/verify-forget-password'}`, {
                code,
                password,
                repassword,
            })
            .then((res) => {
                if (res.status === 200) {
                    NotificationManager.primary(
                        res.data.responseText,
                        'Thành công',
                        3000,
                        null,
                        null,
                        'filled'
                    );

                    this.setState({
                        isSendCode: true,
                    });
                }
            })
            .catch((err) => {
                NotificationManager.error(
                    err.response.data.responseText,
                    'Lỗi !',
                    3000,
                    null,
                    null,
                    'filled'
                );
            });
        return false;
    }

    render() {
        let { username, password, repassword, isSendCode } = this.state;

        return (
            <Row className='h-100'>
                <Colxx xxs='12' md='10' className='mx-auto my-auto'>
                    <Card className='auth-card' autoFocus={false}>
                        <div className='position-relative image-side '>
                            <p className='text-white h2'>QUÊN MẬT KHẨU</p>
                            <p className='white mb-0'>
                                Nhập tài khoản và kiểm tra email để nhận code{' '}
                                {/* <br />
                                Nếu có thể đăng nhập, vui lòng{' '}
                                <NavLink to={`/user/login`} className='white'>
                                    Đăng nhập
                                </NavLink>
                                . */}
                            </p>
                        </div>
                        <div className='form-side'>
                            {/* <NavLink to={`/`} className="white">
                <span className="logo-single" />
              </NavLink> */}
                            <a href='https://vmass.vn/' className='white'>
                                <span className='logo-single' />
                            </a>
                            <CardTitle className='mb-4'>
                                <IntlMessages id='user.forgot-password' />
                            </CardTitle>
                            <Form onSubmit={(e) => e.preventDefault()}>
                                {!isSendCode ? (
                                    <React.Fragment>
                                        <Label className='form-group has-float-label mb-4'>
                                            <Input
                                                autoFocus={true}
                                                onChange={
                                                    this.handleChangeValue
                                                }
                                                type='text'
                                                name='username'
                                                defaultValue={username}
                                            />
                                            <IntlMessages id='user.username' />
                                        </Label>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <NavLink to={`/user/login`}>
                                                Đăng nhập?
                                            </NavLink>
                                            <Button
                                                color='primary'
                                                className='btn-shadow'
                                                size='lg'
                                                onClick={
                                                    this.handleSubmitGetCode
                                                }
                                            >
                                                <IntlMessages id='user.reset-password-button' />
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Label className='form-group has-float-label mb-4'>
                                            <Input
                                                type='text'
                                                name='code'
                                                onChange={
                                                    this.handleChangeValue
                                                }
                                            />
                                            <IntlMessages id='user.code' />
                                        </Label>
                                        <Label className='form-group has-float-label mb-4'>
                                            <Input
                                                name='password'
                                                type='password'
                                                onChange={
                                                    this.handleChangeValue
                                                }
                                                defaultValue={password}
                                            />
                                            <IntlMessages id='user.password' />
                                        </Label>
                                        <Label className='form-group has-float-label mb-4'>
                                            <Input
                                                type='password'
                                                name='repassword'
                                                onChange={
                                                    this.handleChangeValue
                                                }
                                                defaultValue={repassword}
                                            />
                                            <IntlMessages id='user.re-password' />
                                        </Label>

                                        <div className='d-flex justify-content-between align-items-center'>
                                            <NavLink to={`/user/login`}>
                                                Đăng nhập ngay!
                                            </NavLink>
                                            <Button
                                                color='primary'
                                                className='btn-shadow'
                                                size='lg'
                                                onClick={
                                                    this
                                                        .handleSubmitChangePassword
                                                }
                                            >
                                                <IntlMessages id='user.reset-password-button-submit' />
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                )}
                            </Form>
                        </div>
                    </Card>
                </Colxx>
            </Row>
        );
    }
}
