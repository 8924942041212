import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ProductDetailNow from './ProductDetailNow';

const ContentComponent = ({
    product,
    productDetail,
    setProductDetail,
    onToggleDetail,
    onClickImage,
}) => {
    // console.log('product: ', product);
    return (
        <Modal
            isOpen={productDetail !== null}
            toggle={() => setProductDetail(null)}
            wrapClassName='modal-right'
            backdrop={true}
        >
            <ModalHeader>Thêm options</ModalHeader>
            <ModalBody>
                {productDetail && (
                    <ProductDetailNow
                        product={product}
                        onSelected={onToggleDetail}
                        // onToggleDetail={onToggleDetail}
                        // orderDisplay={orderDisplay}
                        // order={order}
                        // isVerify={isVerify}
                    />
                )}
            </ModalBody>
            <ModalFooter>
                <Button
                    color='primary'
                    onClick={() => {
                        // onToggleDetail({ product });
                        onClickImage(product);
                        setProductDetail(null);
                    }}
                >
                    Chọn món này
                </Button>{' '}
            </ModalFooter>
        </Modal>
    );
};

export default ContentComponent;
