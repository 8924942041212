import React, { useState, useEffect } from 'react';
import { Card, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
import CircularProgressbar from 'react-circular-progressbar';
import ListPendingOrdersStatus from './ListPendingOrdersStatus';
import IntlMessages from '../../helpers/IntlMessages';
import classnames from 'classnames';
import { isRorF, isF, isR } from '../../helpers/Roles';
import CustomModalContainer from '../../views/app/ui/modal';
import InvoicePages from '../../views/app/pages/invoice';
import { TableChairGenerateMap } from '../../components/tablechairsTransfer';

const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

function useCurrentWitdh() {
    // save current window width in the state object
    let [width, setWidth] = useState(getWidth());

    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {
        // timeoutId for debounce mechanism
        let timeoutId = null;
        const resizeListener = () => {
            // prevent execution of previous setTimeout
            clearTimeout(timeoutId);
            // change width from the state object after 150 milliseconds
            timeoutId = setTimeout(() => setWidth(getWidth()), 150);
        };
        // set resize listener
        window.addEventListener('resize', resizeListener);

        // clean up function
        return () => {
            // remove resize listener
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    return width;
}

const GradientWithRadialProgressCard = ({
    // icon = 'iconsminds-bell',
    title = 'title',
    detail = 'detail',
    percent = 80,
    progressText = '8/10',
    items = [],
    onCheckItem,
    onActions,
    selectedItems = [],
    popupTitle,
    alias,
    role,
    emptyText,
    itemsPendingBill,
    companyName,
    companyAddress,
    companyLogo,
    companyWebsite,
    billDate,
    pin_print,
    handleClickedPrintInvoice,
    switchCheckedBillQr,
    itemsTable,
    switchCheckedOrderTwo,
}) => {
    const [state, setState] = useState({
        isOpenModal: false,
        isOpenModalInvoice: false,
        isOpenModalNested: false,
        aboutPropsInvoice: {},
    });
    useEffect(() => {
        if (role === 'orders_table_pending_payment') {
            setState({
                ...state,
                isOpenModalInvoice: false,
            });
        }
    }, [items.length]);

    const toggleOpenModal = () => {
        if (role) {
            if (isRorF(role)) {
                setState({
                    ...state,
                    isOpenModal: !state.isOpenModal,
                });
            }
        }
    };

    const toggleModalInvoice = (data = null, discount) => {
        // console.log('data: ', data);
        data.discount = discount ? discount.discount : false;
        setState({
            ...state,
            isOpenModalInvoice: !state.isOpenModalInvoice,
            aboutPropsInvoice: data,
            isOpenModal: false,
        });
    };

    const toggleModalNested = () => {
        setState({
            isOpenModalNested: !state.isOpenModalNested,
        });
    };

    const selectTableTransfer = (item) => {
        if (selectedItems.length < 1) return false;

        onActions(selectedItems, 'transfer', item, 'table');
    };

    const toggleModal = () => {
        setState({
            ...state,
            isOpenModalInvoice: !state.isOpenModalInvoice,
        });
    };

    const onHandlerActions = (selectedItems, action) => {
        if (!isF(role)) return false;

        if (selectedItems.length < 1) return false;

        onActions(selectedItems, action);

        setState({
            ...state,
            isOpenModal: false,
        });
    };

    let width = useCurrentWitdh();
    const isMobile = width <= 980;

    return (
        <Card
            className={classnames('progress-banner', {
                disable: !isRorF(role),
            })}
            onClick={
                // isR
                //     ? null
                //     :
                () => {
                    setState({
                        ...state,
                        isOpenModal: !state.isOpenModal,
                    });
                }
            }
        >
            <CardBody className='justify-content-between d-flex flex-row align-items-center'>
                <div>
                    {/* <i
                        className={`${icon} mr-2 text-white align-text-bottom d-inline-block`}
                    /> */}
                    <div>
                        <p className='lead text-white'>{title}</p>
                        <p className='text-small text-white'>{detail}</p>
                    </div>
                </div>
                <div className='progress-bar-circle progress-bar-banner position-relative'>
                    <CircularProgressbar
                        strokeWidth={4}
                        percentage={percent}
                        text={progressText}
                    />
                </div>
            </CardBody>
            <Modal
                isOpen={state.isOpenModal}
                toggle={toggleOpenModal}
                className={classnames('table-modal cursor-pointer', {
                    'table-bill-desktop': !isMobile,
                })}
            >
                <ModalHeader toggle={toggleOpenModal}>{popupTitle}</ModalHeader>
                <ModalBody>
                    <ListPendingOrdersStatus
                        onActions={onHandlerActions}
                        selectedItems={selectedItems}
                        onCheckItem={onCheckItem}
                        orders={items}
                        isMobile={isMobile}
                        alias={alias}
                        toggleModalInvoice={toggleModalInvoice}
                        emptyText={emptyText}
                        itemsPendingBill={itemsPendingBill}
                        companyName={companyName}
                        companyAddress={companyAddress}
                        companyLogo={companyLogo}
                        companyWebsite={companyWebsite}
                        billDate={billDate}
                        pin_print={pin_print}
                        handleClickedPrintInvoice={handleClickedPrintInvoice}
                        switchCheckedBillQr={switchCheckedBillQr}
                        toggleModalNested={toggleModalNested}
                        switchCheckedOrderTwo={switchCheckedOrderTwo}
                    />
                </ModalBody>
            </Modal>

            {/* TRANSFER TABLE */}
            <CustomModalContainer
                isOpenModal={state.isOpenModalNested}
                toggleModal={toggleModalNested}
                disabledScroll={state.isOpenModalNested}
                title={'Chuyển bàn'}
            >
                {/* <RenderFloorTable
                    maxFloor={maxFloor}
                    selectedFloorTable={selectedFloorTable}
                    onUpdateSelectedFloorTable={
                        onUpdateSelectedFloorTable
                    }
                    floorsActiveId={floorsActiveId}
                /> */}
                <TableChairGenerateMap
                    data={itemsTable}
                    selectTableTransfer={selectTableTransfer}
                />
            </CustomModalContainer>

            {/* INVOICE */}
            {state.isOpenModalInvoice ? (
                <CustomModalContainer
                    isOpenModal={state.isOpenModalInvoice}
                    toggleModal={toggleModalInvoice}
                    disabledScroll={state.isOpenModalInvoice}
                    title={''}
                    isHight={true}
                >
                    <InvoicePages
                        location={{
                            aboutProps: state.aboutPropsInvoice,
                        }}
                        toggleModal={toggleModal}
                    />
                </CustomModalContainer>
            ) : null}
        </Card>
    );
};
export default GradientWithRadialProgressCard;
