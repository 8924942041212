import React, { Component } from 'react';
import {
    Card,
    Badge,
    CardBody,
    CardTitle,
    ButtonDropdown,
    CustomInput,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { connect } from 'react-redux';
import {
    setContainerClassnames,
    clickOnMobileMenu,
} from '../../../redux/actions';
import IntlMessages from '../../../helpers/IntlMessages';
import classnames from 'classnames';
import { END_POINT } from '../../../constants/defaultValues';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { AddCommaNumber } from '../../../helpers/Utils';

class LabelItemLeft extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdownSplitOpen: false,
        };
    }

    toggleSplit = () => {
        this.setState((prevState) => ({
            dropdownSplitOpen: !prevState.dropdownSplitOpen,
        }));
    };

    menuButtonClick = (e, menuClickCount, containerClassnames) => {
        setTimeout(() => {
            var event = document.createEvent('HTMLEvents');
            event.initEvent('resize', false, false);
            window.dispatchEvent(event);
        }, 350);
        this.props.setContainerClassnames(
            menuClickCount,
            containerClassnames,
            this.props.selectedMenuHasSubItems
        );
    };

    onClickedItem = (item) => {
        this.props.onClickedItem(
            // callback
            item,
            this.menuButtonClick,
            [null, 1000, this.props.containerClassnames]
        );
    };

    render() {
        const { dropdownSplitOpen } = this.state;
        const {
            item,
            setting,
            selectedItems,
            idx,
            onChangeCheckbox,
            isDisabledDelete,
            onDeleteBill,
        } = this.props;
        // console.log('item: ', item);

        return (
            item.data.length && (
                <Card
                    className='mb-3 custom-shadow'
                    onClick={() => {
                        this.onClickedItem(this.props.item);
                    }}
                >
                    <div class='position-absolute card-top-buttons custom-inline'>
                        <div className='custom-control custom-checkbox pl-1 align-self-center pr-4'>
                            <ButtonDropdown
                                isOpen={dropdownSplitOpen}
                                toggle={this.toggleSplit}
                                onClick={() => this.onClickedItem(item)}
                            >
                                <div className='cursor-pointer btn btn-lg btn-primary pl-4 pr-0 check-button check-all'>
                                    <CustomInput
                                        className='custom-checkbox mb-0 d-inline-block'
                                        type='checkbox'
                                        id={idx}
                                        checked={selectedItems.includes(
                                            item.pin
                                        )}
                                        onChange={() =>
                                            onChangeCheckbox(item.pin)
                                        }
                                        label={
                                            <span
                                                className={`custom-control-label`}
                                            />
                                        }
                                    />
                                </div>
                                <DropdownToggle
                                    caret
                                    color='primary'
                                    className='dropdown-toggle-split'
                                />
                                <DropdownMenu right>
                                    <DropdownItem
                                        className={classnames('text-left red', {
                                            disable: isDisabledDelete,
                                        })}
                                        onClick={() =>
                                            isDisabledDelete
                                                ? null
                                                : onDeleteBill(item.pin)
                                        }
                                    >
                                        <>
                                            <i className='simple-icon-trash pr-2' />
                                            Xoá bill
                                        </>
                                    </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </div>
                    </div>
                    <CardBody className='p-3'>
                        <CardTitle className='mb-0'>
                            {item.data[0].pin_print ? (
                                <Badge color='primary' pill>
                                    Mã hoá đơn - #{item.data[0].pin_print}
                                </Badge>
                            ) : (
                                <Badge color='secondary' pill>
                                    Chưa in hoá đơn
                                </Badge>
                            )}
                        </CardTitle>

                        {item.data &&
                            item.data.map((order, idx) => {
                                return (
                                    <Colxx
                                        xxs='12'
                                        key={idx}
                                        className='p-0 mt-3'
                                    >
                                        <span
                                            className={classnames(
                                                'd-flex flex-row'
                                            )}
                                        >
                                            <img
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src =
                                                        'http://via.placeholder.com/320x240';
                                                    // e.target.src = `/public/assets/img/coconut-cake.jpg`;
                                                }}
                                                alt={order.name}
                                                src={
                                                    order.product.image.indexOf(
                                                        'http'
                                                    ) >= 0
                                                        ? order.product.image
                                                        : END_POINT +
                                                          '/public/' +
                                                          order.product.image
                                                }
                                                className='list-thumbnail responsive border-0'
                                            />
                                            <div className='pl-2 d-flex flex-grow-1 min-width-zero'>
                                                <div
                                                    className={classnames(
                                                        'card-body p-2 align-items-center d-flex flex-ssm-column justify-content-between min-width-zero',
                                                        {
                                                            'flex-lg-row align-items-lg-center':
                                                                !setting.update,
                                                        }
                                                    )}
                                                >
                                                    <div className='w-75'>
                                                        <p className='w-100 list-item-heading mb-1 truncate d-block'>
                                                            {order.product.name}
                                                        </p>
                                                        <p className='mb-1 text-muted text-small w-100 w-sm-100'>
                                                            {order.date +
                                                                ' - ' +
                                                                order.time}
                                                        </p>
                                                    </div>
                                                    <div className='w-100 w-sm-100'>
                                                        {order.discount ? (
                                                            <Badge
                                                                className='mb-1 mr-1'
                                                                color='secondary'
                                                                pill
                                                            >
                                                                {'Chiết khấu: ' +
                                                                    DiscountRender(
                                                                        order.discount
                                                                    )}
                                                            </Badge>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {order.tax_fee ? (
                                                            <Badge
                                                                className='mb-1 mr-1'
                                                                color='secondary'
                                                                pill
                                                            >
                                                                {'Thuế: ' +
                                                                    DiscountRender(
                                                                        order.tax_fee
                                                                    )}
                                                            </Badge>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {order.discount ? (
                                                            <Badge
                                                                className='mb-1 mr-1'
                                                                color='secondary'
                                                                pill
                                                            >
                                                                {'Phụ phí: ' +
                                                                    DiscountRender(
                                                                        order.fee_other
                                                                    )}
                                                            </Badge>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </Colxx>
                                );
                            })}
                    </CardBody>
                </Card>
            )
        );
    }
}

const DiscountRender = (discount) => {
    return discount?.type === 'price'
        ? AddCommaNumber(Math.round(discount?.value)) + ' đ'
        : discount?.value + ' %';
};

const mapStateToProps = ({ menu }) => {
    const { containerClassnames, menuClickCount, selectedMenuHasSubItems } =
        menu;
    return {
        containerClassnames,
        menuClickCount,
        selectedMenuHasSubItems,
    };
};
export default connect(mapStateToProps, {
    setContainerClassnames,
    clickOnMobileMenu,
})(LabelItemLeft);
