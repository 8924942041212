import React, { Component } from 'react';
import { Row, Card, CardBody, Button, CardTitle, CardFooter } from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import {
    NotificatioErrController,
    NotificatioSucController,
} from '../../../helpers/Utils';
import axios from 'axios';
import { END_POINT } from '../../../constants/defaultValues';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import { ListItemLeftForm } from '../../../containers/pages/qrcode';
import { END_POINT_QRCODE } from '../../../constants/url';
import { isF, isR } from '../../../helpers/Roles';
import classnames from 'classnames';

const ROLE_ALIAS = 'qrcode';

class ContentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            qrDataArray: [],
            data: [],
        };
    }

    componentDidMount() {
        this.dataListRender();
    }

    /**
     * DATA TABLE POSITION
     */
    dataListRender = () => {
        let tokenStr = localStorage.getItem('access_token');

        return axios
            .get(`${END_POINT + '/table/all_identify'}`, {
                headers: {
                    Authorization: `Bearer ${tokenStr}`,
                },
            })
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                this.setState(
                    {
                        data: data.data,
                        loading: false,
                    },
                    () => this.generateQRCodeAutomatically()
                );
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    };

    generateQRCodeAutomatically = () => {
        const { data } = this.state;
        const qrCodes = data.flatMap((floor) => {
            return floor.data.map((item) => {
                const u = item.u;
                const host = window.location.host;
                return `${host}/menu/${u}`;
            });
        });
        // console.log('qrCodes: ', qrCodes);

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/qrcode/generate'}`,
                {
                    qrCodes,
                },
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => {
                // console.log('res.data: ', res.data);
                return res.data;
            })
            .then((data) => {
                this.setState({
                    qrDataArray: data,
                });
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    };

    exportFloor = (floor, filenames) => {
        if (!isF(ROLE_ALIAS)) return false;

        return axios
            .post(`${END_POINT_QRCODE + '/export'}`, {
                filenames,
                floor,
            })
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                window.open(`${END_POINT_QRCODE}/zip/${data}`, '_blank');
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    };

    onClickedItem = (floor, callback, args) => {
        callback(args[0], args[1], args[2]);
    };

    render() {
        let { loading, data, qrDataArray } = this.state;

        if (!isR(ROLE_ALIAS))
            return (
                <Card className='text-muted mh-40vh h-100'>
                    <CardBody className='d-flex justify-content-center align-items-center'>
                        Bạn không có quyền sử dụng tính năng này!
                    </CardBody>
                </Card>
            );

        return loading ? (
            <div className='loading' />
        ) : (
            <React.Fragment>
                <Row>
                    <Colxx xxs='12'>
                        <Breadcrumb
                            heading='menu.qrcode'
                            match={this.props.match}
                        />
                        <Separator className='mb-5' />
                    </Colxx>
                </Row>
                {data.length === 0 ? (
                    <Card className='mt-4 mb-4 mh-53vh'>
                        <CardBody>Chưa có dữ liệu...</CardBody>
                    </Card>
                ) : (
                    <>
                        <ListItemLeftForm
                            data={data}
                            onClickedItem={this.onClickedItem}
                            exportFloor={this.exportFloor}
                            isDisableDownload={!isF(ROLE_ALIAS)}
                        />
                    </>
                )}
                {/* <Row className='mb-4'>
                    <Colxx xxs='12'>
                        <Card>
                            <CardBody>
                                <div
                                    className={classnames(
                                        'position-absolute card-top-buttons custom-inline'
                                    )}
                                >
                                    <Button size='sm' color='primary'>
                                        Tải tất cả
                                    </Button>
                                </div>
                                <CardTitle>{`Khu 1`}</CardTitle>
                                <Row>
                                    {qrDataArray.length
                                        ? qrDataArray.map((qr, idx) => {
                                              return (
                                                  <Colxx
                                                      xxs='12'
                                                      xs='6'
                                                      md='4'
                                                      lg='3'
                                                      key={idx}
                                                  >
                                                      <Card className='mb-4'>
                                                          <div className='qrcode-wrapper'>
                                                              <img
                                                                  src={qr}
                                                                  alt=''
                                                              />
                                                          </div>
                                                          <CardFooter>
                                                              <span>{`Bàn ${
                                                                  idx + 1
                                                              }`}</span>
                                                              <div className='position-absolute card-bottom-buttons custom-inline'>
                                                                  <span className='cursor-pointer'>
                                                                      <i className='simple-icon-cloud-download' />
                                                                  </span>
                                                              </div>
                                                          </CardFooter>
                                                      </Card>
                                                  </Colxx>
                                              );
                                          })
                                        : null}
                                </Row>
                            </CardBody>
                        </Card>
                    </Colxx>
                </Row> */}
            </React.Fragment>
        );
    }
}

export default ContentComponent;
