import React from 'react';
import { Input, Label, Form, FormGroup } from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import DynamicForm from '../general/DynamicForm';

const ContentComponent = (props) => {
    return (
        <Form>
            <FormGroup>
                <Label for='title'>
                    <IntlMessages id='todo.title' />
                </Label>
                <Input
                    type='text'
                    name='title'
                    id='title'
                    value={props.values.title}
                    onChange={props.onChange}
                />
            </FormGroup>
            <FormGroup>
                <Label for='description'>
                    <IntlMessages id='todo.description' />
                </Label>
                <Input
                    type='textarea'
                    name='description'
                    id='description'
                    value={props.values.description}
                    onChange={props.onChange}
                />
            </FormGroup>
            <Label for='momentFormatSelectDateTime'>
                <IntlMessages id='todo.date-time' />
            </Label>
            <DatePicker
                name='momentFormatSelectDateTime'
                id='momentFormatSelectDateTime'
                className='mb-3'
                onChange={props.onChange}
                selected={moment(
                    props.values.momentFormatSelectDateTime,
                    'YYYY-MM-DD HH:mm'
                )}
                showTimeSelect
                timeFormat='HH:mm'
                timeIntervals={5}
                dateFormat='YYYY-MM-DD - HH:mm'
                timeCaption='Giờ'
            />
            {/* 
      <DynamicForm
        values={props.values.dynamic_form}
        data={props.data_dynamic_form}
        onChange={props.onChangeDynamicForm}
        disabled={props.setting.viewonly && !props.setting.add}
      /> */}
        </Form>
    );
};

export default ContentComponent;
