import React, { Component } from 'react';
import {
    Row,
    Button,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Collapse,
} from 'reactstrap';
import { injectIntl } from 'react-intl';

import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import Breadcrumb from '../../navs/Breadcrumb';
import IntlMessages from '../../../helpers/IntlMessages';

class ContentComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { messages } = this.props.intl;
        const {
            heading,
            pageSizes,
            selectedPageSize,
            startIndex,
            endIndex,
            match,
            totalItemCount,
            changePageSize,
            onSearchKey,
        } = this.props;

        return (
            <Row className='list-page-heading__row'>
                <Colxx xxs='12'>
                    <div className='mb-2 text-center uppercase'>
                        <h1 className='w-100'>
                            <IntlMessages id={heading} />
                        </h1>
                    </div>

                    <Colxx xxs='12' className='no-padding'>
                        <div className='mb-2'>
                            <div className='d-block d-md-inline-block'>
                                <div className='search-md float-md-left mr-1 mb-1 align-top'>
                                    <input
                                        type='text'
                                        name='keyword'
                                        id='search'
                                        placeholder={
                                            messages['menu.search'] +
                                            ' theo số bàn'
                                        }
                                        onKeyPress={(e) => onSearchKey(e)}
                                        onChange={(e) => onSearchKey(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </Colxx>
                    {/* <Separator /> */}
                </Colxx>
            </Row>
        );
    }
}

export default injectIntl(ContentComponent);
