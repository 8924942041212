import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import AppLocale from './lang';
import ColorSwitcher from './components/common/ColorSwitcher';
import NotificationContainer from './components/common/react-notifications/NotificationContainer';
import { isMultiColorActive } from './constants/defaultValues';
import main from './views';
import app from './views/app';
import root from './views/root';
import user from './views/user';
import error from './views/error';
import menuGuest from './views/app/pages/product-guest';
import tablePendingPayment from './views/table-pending-payment';
import cashier from './views/cashier';
import kitchen from './views/kitchen';
import { getDirection } from './helpers/Utils';

const AuthRoute = ({ component: Component, authUser, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            authUser ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/user/login',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

class App extends Component {
    componentWillMount() {
        const direction = getDirection();
        if (direction.isRtl) {
            document.body.classList.add('rtl');
            document.body.classList.remove('ltr');
        } else {
            document.body.classList.add('ltr');
            document.body.classList.remove('rtl');
        }
    }

    render() {
        const { locale, loginUser } = this.props;
        const currentAppLocale = AppLocale[locale];

        return (
            <div className='h-100'>
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}
                >
                    <React.Fragment>
                        <NotificationContainer />
                        {isMultiColorActive && <ColorSwitcher />}
                        <Router>
                            <Switch>
                                <Route path='/menu' component={menuGuest} />
                                <AuthRoute
                                    path='/cashier'
                                    authUser={loginUser}
                                    component={cashier}
                                />
                                <AuthRoute
                                    path='/payment'
                                    authUser={loginUser}
                                    component={tablePendingPayment}
                                />
                                <AuthRoute
                                    path='/kitchen'
                                    authUser={loginUser}
                                    component={kitchen}
                                />
                                <AuthRoute
                                    path='/app'
                                    authUser={loginUser}
                                    component={app}
                                />
                                <AuthRoute
                                    path='/root'
                                    authUser={loginUser}
                                    component={root}
                                />
                                <Route path='/user' component={user} />
                                <Route path='/error' exact component={error} />
                                <Route path='/' exact component={main} />
                                <Redirect to='/error' />
                            </Switch>
                        </Router>
                    </React.Fragment>
                </IntlProvider>
            </div>
        );
    }
}

const mapStateToProps = ({ authUser, settings }) => {
    const { user: loginUser } = authUser;
    const { locale } = settings;
    return { loginUser, locale };
};
const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(App);
