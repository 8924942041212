import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Button } from 'reactstrap';
import ReactTable from 'react-table';
import IntlMessages from '../../../helpers/IntlMessages';
import Pagination from '../../../components/DatatablePagination';
import { AddCommaNumber } from '../../../helpers/Utils';
import classnames from 'classnames';

class ContentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    Header: 'Tên',
                    accessor: 'name',
                    Cell: (props) => (
                        <p className='list-item-heading'>{props.value}</p>
                    ),
                },
                {
                    Header: 'Số tiền nhập',
                    accessor: 'price_income',
                    Cell: (props) => (
                        <p className='text-muted'>
                            {AddCommaNumber(props.value)}
                        </p>
                    ),
                },
                {
                    Header: 'Đã thanh toán',
                    accessor: 'price_outcome',
                    Cell: (props) => (
                        <p className='text-muted'>
                            {AddCommaNumber(props.value)}
                        </p>
                    ),
                },
                {
                    Header: 'Còn lại',
                    accessor: 'price_pending',
                    Cell: (props) => (
                        <p className='text-muted'>
                            {AddCommaNumber(props.value)}
                        </p>
                    ),
                },
                {
                    Header: 'Thời gian',
                    accessor: 'date',
                    Cell: (props) => (
                        <p className='text-muted'>{props.value}</p>
                    ),
                },
                {
                    width: 150,
                    Header: 'Hành động',
                    accessor: 'id',
                    Cell: (props) =>
                        props.original.price_income >
                        props.original.price_outcome ? (
                            <Button
                                color='primary'
                                size='xs'
                                className={classnames({
                                    disable: this.props.isDisabledUpdate,
                                })}
                                onClick={() =>
                                    this.props.toggleItemLogFunc(props.original)
                                }
                            >
                                <IntlMessages id='company-provider.title-log-add-more' />
                            </Button>
                        ) : null,
                },
            ],
            items: [],
            isLoading: false,
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
    }

    render() {
        let { columns, isLoading } = this.state;

        let { items, toggleFunc } = this.props;

        return !isLoading ? (
            <div className='loading' />
        ) : (
            <Card className='mb-4'>
                <div className='position-absolute card-top-buttons custom-inline'>
                    <Button
                        className={classnames({
                            disable: this.props.isDisabledUpdate,
                        })}
                        onClick={toggleFunc}
                        size='xs'
                        color='primary'
                    >
                        <IntlMessages id='company-provider.title-log-add' />
                    </Button>
                </div>
                <CardBody>
                    <CardTitle>
                        <IntlMessages id='company-provider.title-log' />
                    </CardTitle>
                    <ReactTable
                        defaultPageSize={6}
                        data={items}
                        columns={columns}
                        minRows={0}
                        showPageJump={false}
                        showPageSizeOptions={true}
                        PaginationComponent={Pagination}
                    />
                </CardBody>
            </Card>
        );
    }
}
export default ContentComponent;
