import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import axios from 'axios';
import { END_POINT, END_POINT_SOCKET } from '../../../constants/defaultValues';
import {
    SetItemsSelected,
    NotificatioErrController,
    NotificatioSucController,
    // CheckConfirmPassword,
} from '../../../helpers/Utils';
import io from 'socket.io-client';
import {
    ContentForm,
    ListItemLeftForm,
} from '../../../containers/pages/form-dynamic';
import {
    Add,
    Update,
    PageHeading,
    Pagination,
} from '../../../containers/pages/general';
import LogsHelper from '../../../helpers/Logs';
// import PasswordBox from '../../../components/PasswordBox'
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classnames from 'classnames';
import ContentDisplay from '../../../containers/pages/form-dynamic/ContentDisplay';
import { isU, isD } from '../../../helpers/Roles';
import ListItemFormMobile from '../../../containers/pages/form-dynamic/ListItemFormMobile';
import AddFormDynamic from '../../../containers/pages/form-dynamic/AddFormDynamic';
import UpdateFormDynamic from '../../../containers/pages/form-dynamic/UpdateFormDynamic';

const DEFAULT_FORM = {
    id: '',
    form_alias: '',
    label: '',
    type: 'text',
    form: '',
};

const ROLE_ALIAS = 'form_dynamic';

class ContentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: {
                pageSizes: [12, 24],
                selectedPageSize: 12,
                currentPage: 1,
                totalPage: 1,
                totalItem: 0,
                search: '',
            },
            selectedItems: [],
            data: [],
            data_forms: [],
            is_loading_data: true,
            show: {
                add: false,
                update: false,
                view: false,
                viewonly: false,
            },
            accordion: [],
            form: DEFAULT_FORM,
        };

        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        this.dataListRender();
        this.dataFormsListRender();
    }

    /** Axios func helper */
    dataListRender = () => {
        const { selectedPageSize, currentPage, search } = this.state.page;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(
                `${
                    END_POINT + '/form-dynamic/json'
                }?pageSize=${selectedPageSize}&currentPage=${currentPage}&search=${search}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((data) => {
                let anyAccordion = [];
                let numeroPerguntas = data.data.length;
                for (var i = 0; i < numeroPerguntas; i++) {
                    // if (!i) {
                    //     anyAccordion.push(true);
                    // }
                    anyAccordion.push(false);
                }
                this.setState(
                    (prev) => ({
                        ...prev,
                        data: data.data,
                        is_loading_data: false,
                        page: {
                            ...prev.page,
                            totalPage: data.totalPage,
                            totalItem: data.totalItem,
                        },
                        accordion: anyAccordion,
                    }),
                    () => {
                        if (this.state.form.id) {
                            const { id, form_alias, label, type, form } =
                                this.state.form;
                            this.setState((prev) => ({
                                ...prev,
                                form: {
                                    ...prev.form,
                                    id,
                                    form_alias,
                                    label,
                                    type,
                                    form,
                                },
                            }));
                        } else {
                            this.setState((prev) => ({
                                ...prev,
                                selectedItems: [],
                                show: {
                                    ...prev.show,
                                    update: false,
                                },
                                form: DEFAULT_FORM,
                            }));
                        }
                    }
                );
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    dataFormsListRender = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(`${END_POINT}/form-dynamic`, {
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                this.setState((prev) => ({
                    ...prev,
                    data_forms: data.data,
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerDelete = () => {
        let tokenStr = localStorage.getItem('access_token');
        axios
            .delete(`${END_POINT + '/form-dynamic'}`, {
                data: {
                    arrayId: this.state.selectedItems,
                },
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['form-dynamic-delete'],
                });

                this.dataListRender();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerUpdate = (e) => {
        const { id, form_alias, label, type, form } = this.state.form;
        e.preventDefault();

        if (!id || !form.value || !label || !type) {
            NotificatioErrController('Dữ liệu không hợp lệ');
            return;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .put(
                `${END_POINT + '/form-dynamic'}`,
                {
                    id,
                    form_alias: form.value,
                    label,
                    type,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['form-dynamic-update'],
                });

                this.dataListRender();
                this.toggle('update');
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerAdd = (e) => {
        e.preventDefault();
        const { form_alias, label, type, form } = this.state.form;

        if (!form.value) {
            NotificatioErrController('Tiêu đề cần được thêm vào');
            return;
        }
        if (!label) {
            NotificatioErrController('Loại Form cần phải chọn');
            return;
        }
        if (!type) {
            NotificatioErrController('Kiểu dữ liệu cần được chọn');
            return;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/form-dynamic'}`,
                {
                    form_alias: form.value,
                    label,
                    type,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['form-dynamic-create'],
                });

                this.dataListRender();
                // this.toggle('add');
                this.setState((prev) => ({
                    ...prev,
                    form: {
                        ...prev.form,
                        form: DEFAULT_FORM,
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };
    /** Axios func helper */

    /** State func helper */
    toggle = (name) => {
        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                update: name === 'add' ? false : prev.show.update,
                view: name === 'add' ? false : prev.show.view,
                [name]: !prev.show[name],
            },
            selectedItems: name === 'add' ? [] : prev.selectedItems,
            form: name === 'add' ? DEFAULT_FORM : prev.form,
        }));
    };

    onChangeForm = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [name]: value,
                },
            }));
        } else {
            let value = e;
            if (_name === 'type') {
                value = e.value;
            }
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [_name]: value,
                },
            }));
        }
    };

    onSubmitForm = (type) => {
        if (type === 'update') {
            this.onHandlerUpdate();
        } else if (type === 'add') {
            this.onHandlerAdd();
        }
    };

    onChangePage = (page) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    currentPage: page,
                },
            }),
            () => this.dataListRender()
        );
    };

    onSearchKey = (e) => {
        if (!e) return false;

        if (e.target) {
            let {
                target: { value },
            } = e;
            let v = value;
            if (value === undefined) {
                v = e.target.firstElementChild.value; // parent to child

                value = v.toLowerCase();
                this.setState(
                    (prev) => ({
                        ...prev,
                        page: {
                            ...prev.page,
                            search: value,
                        },
                    }),
                    () => this.dataListRender()
                );
            } else {
                value = value.toLowerCase();
                this.setState(
                    (prev) => ({
                        ...prev,
                        page: {
                            ...prev.page,
                            search: value,
                        },
                    }),
                    () => this.dataListRender()
                );
            }
        }

        if (e.key === 'Enter') {
            let value = e.target.value.toLowerCase();
            this.setState(
                (prev) => ({
                    ...prev,
                    page: {
                        ...prev.page,
                        search: value,
                    },
                }),
                () => this.dataListRender()
            );
        }
    };

    onChangePageSize = (size) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    selectedPageSize: size,
                    currentPage: 1,
                },
            }),
            () => this.dataListRender()
        );
    };

    onClickedItem = (item, callback, args) => {
        if (this.state.form.id !== item.id) {
            const { id, form_alias, label, type, form } = item;

            this.setState(
                (prev) => ({
                    ...prev,
                    show: {
                        ...prev.show,
                        update: false,
                        view: true,
                        viewonly: false,
                    },
                    form: {
                        ...prev.form,
                        id,
                        form_alias,
                        label,
                        type,
                        form,
                    },
                    selectedItems: [item.id],
                }),
                () => {
                    callback(args[0], args[1], args[2]);
                }
            );
        }
    };

    onClickedUpdate = (item) => {
        const { id, form_alias, label, type, form } = item;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                update: true,
            },
            form: {
                ...prev.form,
                id,
                form_alias,
                label,
                type,
                form,
            },
        }));
    };

    onClickedDelete = (item) => {
        let selectedSet = SetItemsSelected(
            item.id,
            this.state.selectedItems,
            'require'
        );
        if (window.confirm('Bạn chắc chắn muốn xoá Form Dynamic này?')) {
            this.setState(
                (prev) => ({
                    ...prev,
                    selectedItems: selectedSet,
                }),
                () => this.onHandlerDelete()
            );
        }
    };

    onClickedCheckbox = (id, callback) => {
        // let selectedSet = SetItemsSelected(id, this.state.selectedItems);

        this.setState((prev) => ({
            ...prev,
            selectedItems: [id],
        }));
    };
    /** State func helper */

    toggleAccordion = (tab) => {
        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => (tab === index ? !x : false));
        this.setState({
            accordion: state,
        });
    };

    render() {
        const { match } = this.props;
        const {
            page,
            selectedItems,
            data,
            data_forms,
            is_loading_data,
            show,
            form,
            accordion,
        } = this.state;

        // console.log('data: ', data);

        return is_loading_data ? (
            <div className='loading' />
        ) : (
            <div className='disable-text-selection'>
                <PageHeading
                    heading='analytics.form-dynamic'
                    setting={page}
                    match={match}
                    totalItem={page.totalItem}
                    toggleFunc={() => {
                        this.toggle('add');
                    }}
                    changePageSizeFunc={this.onChangePageSize}
                    onSearchKeyFunc={this.onSearchKey}
                />
                <AddFormDynamic
                    title={
                        <IntlMessages id='form-dynamic.add-new-modal-title' />
                    }
                    isOpen={show.add}
                    toggle={() => {
                        this.toggle('add');
                    }}
                    forms={data_forms}
                    values={form}
                    setting={show}
                    onChange={this.onChangeForm}
                    onSubmit={this.onHandlerAdd}
                >
                    <ContentForm />
                </AddFormDynamic>
                <UpdateFormDynamic
                    title={<IntlMessages id='form-dynamic.title' />}
                    isOpen={show.update}
                    toggle={() => this.toggle('update')}
                    forms={data_forms}
                    values={form}
                    setting={show}
                    onChange={this.onChangeForm}
                    onSubmit={this.onHandlerUpdate}
                />
                {data.length === 0 ? (
                    <Card>
                        <CardBody>Chưa có dữ liệu...</CardBody>
                    </Card>
                ) : (
                    <>
                        <Row className='content-page__row d-none d-md-flex'>
                            <Col
                                xs='12'
                                sm='12'
                                md='12'
                                lg={show.view ? 6 : 12}
                                xl={show.view ? 6 : 12}
                            >
                                {data.map((item, idx) => (
                                    <ListItemLeftForm
                                        onClickedItem={this.onClickedItem}
                                        onUpdate={this.onClickedUpdate}
                                        onDelete={this.onClickedDelete}
                                        onChangeCheckbox={
                                            this.onClickedCheckbox
                                        }
                                        selectedItems={selectedItems}
                                        setting={show}
                                        key={idx}
                                        idx={idx}
                                        item={item}
                                    />
                                ))}
                                <Pagination
                                    currentPage={page.currentPage}
                                    totalPage={page.totalPage}
                                    onChangePage={(i) => this.onChangePage(i)}
                                />
                            </Col>
                            {show.view ? (
                                <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                                    <ContentDisplay values={form} />
                                </Col>
                            ) : null}
                        </Row>
                        <Row className='content-page__row d-block d-md-none mb-4'>
                            <Col>
                                {data.map((item, idx) => {
                                    // console.log(item);
                                    return (
                                        <div
                                            className='d-flex mb-3 card'
                                            key={item.id}
                                        >
                                            <ListItemFormMobile
                                                onClickedItem={
                                                    this.onClickedItem
                                                }
                                                onUpdate={this.onClickedUpdate}
                                                onDelete={this.onClickedDelete}
                                                onChangeCheckbox={
                                                    this.onClickedCheckbox
                                                }
                                                isDisabledUpdate={
                                                    !isU(ROLE_ALIAS)
                                                }
                                                isDisabledDelete={
                                                    !isD(ROLE_ALIAS)
                                                }
                                                selectedItems={selectedItems}
                                                key={idx}
                                                idx={idx}
                                                item={item}
                                                form={form}
                                                accordion={accordion}
                                                toggleAccordion={
                                                    this.toggleAccordion
                                                }
                                            />
                                        </div>
                                    );
                                })}
                                <Pagination
                                    currentPage={page.currentPage}
                                    totalPage={page.totalPage}
                                    onChangePage={(i) => this.onChangePage(i)}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        );
    }
}

export default ContentComponent;
