import React, { Component } from "react";
import {
  Input,
  Button,
} from "reactstrap"
import classnames from 'classnames'

class SaySomething extends Component {
  render() {
    const {
      placeholder,
      messageInput,
      handleChatInputPress,
      handleChatInputChange,
      handleSendButtonClick,
      classNames,
      onChangeFile,
      onSelectedFile,
      filesLength,

      isDisabled,
    } = this.props;
    return (
      <div className={classnames(classNames, {
        disable: isDisabled
      })}>
        <div>
          <input
            multiple
            accept=".zip, .rar, .tar, .jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
            id="myInput"
            type="file"
            ref={(ref) => this.upload = ref} style={{ display: 'none' }}
            onChange={onChangeFile} />
          <span className="icon-button large ml-1 icon-file">
            <i className="simple-icon-paper-clip" onClick={(e) => this.upload.click()} />
            {filesLength > 0 ? <sup onClick={onSelectedFile}><b>{filesLength}</b></sup> : null}
          </span>
        </div>
        <Input
          className="form-control flex-grow-1"
          type="text"
          placeholder={placeholder}
          value={messageInput}
          onKeyPress={e => handleChatInputPress(e)}
          onChange={e => handleChatInputChange(e)}
        />
        <div>
          <Button
            color="primary"
            className="icon-button large ml-1"
            onClick={() => handleSendButtonClick()}
          >
            <i className="simple-icon-arrow-right" />
          </Button>
        </div>
      </div>
    );
  }
}
export default SaySomething;
