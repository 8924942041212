import React, { Component } from 'react';
import {
    Card,
    Badge,
    ButtonDropdown,
    CustomInput,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { connect } from 'react-redux';
import {
    setContainerClassnames,
    clickOnMobileMenu,
} from '../../../redux/actions';
import IntlMessages from '../../../helpers/IntlMessages';
import classnames from 'classnames';
import { Separator } from '../../../components/common/CustomBootstrap';

class LabelItemLeft extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdownSplitOpen: false,
        };
    }

    toggleSplit = () => {
        this.setState((prevState) => ({
            dropdownSplitOpen: !prevState.dropdownSplitOpen,
        }));
    };

    menuButtonClick = (e, menuClickCount, containerClassnames) => {
        setTimeout(() => {
            var event = document.createEvent('HTMLEvents');
            event.initEvent('resize', false, false);
            window.dispatchEvent(event);
        }, 350);
        this.props.setContainerClassnames(
            menuClickCount,
            containerClassnames,
            this.props.selectedMenuHasSubItems
        );
    };

    onClickedItem = (item) => {
        this.props.onClickedItem(
            // callback
            item,
            this.menuButtonClick,
            [null, 1000, this.props.containerClassnames]
        );
    };

    render() {
        const { setting } = this.props;
        const { dropdownSplitOpen } = this.state;

        return (
            <Card className='mb-3 custom-shadow'>
                <div className='d-flex flex-row cursor-pointer'>
                    <div className={'d-flex flex-grow-1 min-width-zero'}>
                        <div
                            onClick={() => {
                                this.onClickedItem(this.props.item);
                            }}
                            className={classnames(
                                'card-body align-self-center d-flex flex-column justify-content-between min-width-zero',
                                {
                                    'flex-lg-row align-items-lg-center':
                                        !setting.update,
                                }
                            )}
                        >
                            <div className='w-100 list-item-heading mb-1 truncate d-block'>
                                <span>{this.props.item.label}</span>
                            </div>
                            <div className='w-20'>
                                <Badge
                                    color={
                                        this.props.item.status_id
                                            ? 'primary'
                                            : 'secondary'
                                    }
                                    pill
                                >
                                    {this.props.item.form.name}
                                </Badge>
                            </div>
                        </div>
                        <div className='custom-control custom-checkbox pl-1 align-self-center pr-4'>
                            <ButtonDropdown
                                isOpen={dropdownSplitOpen}
                                toggle={this.toggleSplit}
                                onClick={() => {
                                    this.onClickedItem(this.props.item);
                                }}
                            >
                                <div className='cursor-pointer btn btn-lg btn-primary pl-4 pr-0 check-button check-all'>
                                    <CustomInput
                                        className='custom-checkbox mb-0 d-inline-block'
                                        type='checkbox'
                                        id={this.props.idx}
                                        checked={this.props.selectedItems.includes(
                                            this.props.item.id
                                        )}
                                        onChange={() => {
                                            this.props.onChangeCheckbox(
                                                this.props.item.id
                                            );
                                        }}
                                        label={
                                            <span
                                                className={`custom-control-label`}
                                            />
                                        }
                                    />
                                </div>
                                <DropdownToggle
                                    caret
                                    color='primary'
                                    className='dropdown-toggle-split'
                                />
                                <DropdownMenu right>
                                    <DropdownItem
                                        className={classnames('text-left', {
                                            disable:
                                                this.props.isDisabledUpdate,
                                        })}
                                        onClick={() => {
                                            this.props.onUpdate(
                                                this.props.item
                                            );
                                        }}
                                    >
                                        <i className='simple-icon-note pr-2' />
                                        <IntlMessages id='pages.update' />
                                    </DropdownItem>
                                    <Separator />
                                    <DropdownItem
                                        className={classnames('text-left red', {
                                            disable:
                                                this.props.isDisabledUpdate,
                                        })}
                                        onClick={() => {
                                            this.props.onDelete(
                                                this.props.item
                                            );
                                        }}
                                    >
                                        <i className='simple-icon-trash pr-2' />
                                        <IntlMessages id='pages.delete' />
                                    </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </div>
                    </div>
                </div>
            </Card>
        );
    }
}

const mapStateToProps = ({ menu }) => {
    const { containerClassnames, menuClickCount, selectedMenuHasSubItems } =
        menu;
    return {
        containerClassnames,
        menuClickCount,
        selectedMenuHasSubItems,
    };
};
export default connect(mapStateToProps, {
    setContainerClassnames,
    clickOnMobileMenu,
})(LabelItemLeft);
