import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';

class ListPageHeadingMenuGuest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownSplitOpen: false,
            displayOptionsIsOpen: false,
        };
    }

    toggleDisplayOptions = () => {
        this.setState((prevState) => ({
            displayOptionsIsOpen: !prevState.displayOptionsIsOpen,
        }));
    };
    toggleSplit = () => {
        this.setState((prevState) => ({
            dropdownSplitOpen: !prevState.dropdownSplitOpen,
        }));
    };

    render() {
        const { messages } = this.props.intl;
        const { onSearchKey, onSearchChange, heading } = this.props;

        return (
            <Row>
                <Colxx className='text-center'>
                    <h1>
                        <IntlMessages id={heading} />
                    </h1>
                </Colxx>
                <Colxx xxs='12' lg='12' className='mt-10px'>
                    <div className='search-md d-inline-block float-md-left mr-1 mb-1 align-top search-menu-guest'>
                        <input
                            type='text'
                            name='keyword'
                            id='search'
                            placeholder={messages['menu.search']}
                            onKeyPress={(e) => onSearchKey(e)}
                            onChange={(e) => onSearchChange(e)}
                        />
                    </div>
                </Colxx>
            </Row>
        );
    }
}

export default injectIntl(ListPageHeadingMenuGuest);
