import moment from 'moment';
import React from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Label,
    Row,
    UncontrolledDropdown,
} from 'reactstrap';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import {
    GetDateGetSalary,
    GetHourWorkingOnDay,
    GetSalaryBy,
    GetStatusWorking,
} from '../../../helpers/UserStaff';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentDisplay = (props) => {
    // console.log(props);
    return (
        <Card className='mb-4'>
            <div className='position-absolute card-top-buttons'>
                {props?.data?.length ? (
                    <UncontrolledDropdown>
                        <DropdownToggle
                            color=''
                            className='btn btn-header-light icon-button'
                        >
                            <span className='simple-icon-options' />
                        </DropdownToggle>
                        <DropdownMenu right>
                            {props.data.map((date, idx) => (
                                <DropdownItem
                                    key={idx}
                                    onClick={() => {
                                        props.onClickDownloadSalary({
                                            month: date.month,
                                            year: date.year,
                                        });
                                    }}
                                >
                                    {`Lương tháng ${date.month}/${date.year}`}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                ) : null}
            </div>
            <CardBody>
                <CardTitle>
                    <IntlMessages id='user-staff.title' />
                </CardTitle>
                {props.is_loading_data_detail ? (
                    <div className='loading' />
                ) : (
                    <div>
                        <Row className='mt-3 '>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='user-staff.name' />
                                </p>
                                <p className='mb-3 text-capitalize'>
                                    {props.values.name ? (
                                        props.values.name
                                    ) : (
                                        <span className='text-lowercase'>
                                            (Chưa có thông tin)
                                        </span>
                                    )}
                                </p>
                            </Colxx>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='user-staff.permission_name' />
                                </p>
                                <p className='mb-3 text-capitalize'>
                                    {props.values.permission_name ? (
                                        props.values.permission_name
                                    ) : (
                                        <span className='text-lowercase'>
                                            (Chưa có thông tin)
                                        </span>
                                    )}
                                </p>
                            </Colxx>
                        </Row>
                        <Row>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='phone' />
                                </p>
                                <p className='mb-3 text-capitalize'>
                                    {props.values.phone ? (
                                        props.values.phone
                                    ) : (
                                        <span className='text-lowercase'>
                                            (Chưa có thông tin)
                                        </span>
                                    )}
                                </p>
                            </Colxx>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='email' />
                                </p>
                                <p className='mb-3 text-lowercase'>
                                    {props.values.email ? (
                                        props.values.email
                                    ) : (
                                        <span className='text-lowercase'>
                                            (Chưa có thông tin)
                                        </span>
                                    )}
                                </p>
                            </Colxx>
                        </Row>
                        <Row>
                            <Colxx xxs='12' lg='12'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='address' />
                                </p>
                                <p className='mb-3 text-capitalize'>
                                    {props.values.address ? (
                                        props.values.address
                                    ) : (
                                        <span className='text-lowercase'>
                                            (Chưa có thông tin)
                                        </span>
                                    )}
                                </p>
                            </Colxx>
                        </Row>
                        <Separator className='mb-4' />
                        <Row>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='user-staff.date_join' />
                                </p>

                                {/* {!props.setting.add ? (
                                    <p className='mb-3'>
                                        {props.values.date_join}
                                    </p>
                                ) : (
                                    ''
                                )} */}
                                {/* <p className='mb-3'>{props.values.date_join}</p> */}
                            </Colxx>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='user-staff.date_get_salary' />
                                </p>
                                <p className='mb-3'>
                                    {
                                        GetDateGetSalary(
                                            props.values.date_get_salary
                                        ).value
                                    }
                                </p>
                            </Colxx>
                        </Row>
                        <Row>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='user-staff.hour_working_on_day' />
                                </p>
                                <p className='mb-3'>
                                    {
                                        GetHourWorkingOnDay(
                                            props.values.hour_working_on_day
                                        ).value
                                    }
                                </p>
                            </Colxx>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='user-staff.status_working' />
                                </p>
                                <p className='mb-3 text-capitalize'>
                                    {
                                        GetStatusWorking(
                                            props.values.status_working
                                        ).label
                                    }
                                </p>
                            </Colxx>
                        </Row>
                        <Row>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='user-staff.salary_by' />
                                </p>
                                <p className='mb-3 text-capitalize'>
                                    {GetSalaryBy(props.values.salary_by).label}
                                </p>
                            </Colxx>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='user-staff.salary_value' />
                                </p>
                                <p className='mb-3'>
                                    {AddCommaNumber(props.values.salary_value) +
                                        ' / ' +
                                        GetSalaryBy(props.values.salary_by)
                                            .label}
                                </p>
                            </Colxx>
                        </Row>
                        <Row>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='user-staff.working_hours' />
                                </p>
                                <p className='mb-3 text-capitalize'>
                                    {/* {props.values.working_hours} */}
                                    07:00 - 12:00
                                </p>
                            </Colxx>
                        </Row>
                        <Separator className='mb-4' />
                        <Row>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='user-staff.current_commission' />
                                </p>
                                <p className='mb-3'>
                                    {AddCommaNumber(
                                        props.values.current_commission
                                    )}
                                </p>
                            </Colxx>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='user-staff.current_advence' />
                                </p>
                                <p className='mb-3'>
                                    {AddCommaNumber(
                                        props.values.current_advence
                                    )}
                                </p>
                            </Colxx>
                        </Row>
                        <Row>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='user-staff.current_bonus' />
                                </p>
                                <p className='mb-3'>
                                    {AddCommaNumber(props.values.current_bonus)}
                                </p>
                            </Colxx>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='user-staff.current_fined' />
                                </p>
                                <p className='mb-3'>
                                    {AddCommaNumber(props.values.current_fined)}
                                </p>
                            </Colxx>
                        </Row>
                        <Row>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='user-staff.current_overtime' />
                                </p>
                                <p className='mb-3'>
                                    {AddCommaNumber(
                                        props.values.current_overtime
                                    )}
                                </p>
                            </Colxx>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='user-staff.current_dayoff' />
                                </p>
                                <p className='mb-3'>
                                    {AddCommaNumber(
                                        props.values.current_dayoff
                                    )}
                                </p>
                            </Colxx>
                        </Row>
                        <Separator className='mb-4' />
                        <Row>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='user-staff.current_day_working' />
                                </p>
                                <p className='mb-3'>
                                    {AddCommaNumber(
                                        props.values.current_day_working
                                    )}
                                </p>
                            </Colxx>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='user-staff.current_salary' />
                                </p>
                                <p className='mb-3'>
                                    {AddCommaNumber(
                                        props.values.current_salary
                                    )}
                                </p>
                            </Colxx>
                        </Row>
                        <Separator className='mb-4' />
                        <Row>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='user-staff.total_day_working' />
                                </p>
                                <p className='mb-3'>
                                    {AddCommaNumber(
                                        props.values.total_day_working
                                    )}
                                </p>
                            </Colxx>
                            <Colxx xxs='12' lg='6'>
                                <p className='text-muted text-small mb-2'>
                                    <IntlMessages id='user-staff.total_salary' />
                                </p>
                                <p className='mb-3'>
                                    {AddCommaNumber(props.values.total_salary)}
                                </p>
                            </Colxx>
                        </Row>
                    </div>
                )}
            </CardBody>
        </Card>
    );
};

export default ContentDisplay;
