import React from 'react'
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import IntlMessages from "../../helpers/IntlMessages";
import { GetBooleanType } from "../../helpers/FormDynamic"

const ContentComponent = (props) => {
    return (
        <Select
            isDisabled={props.disabled || false}
            components={{ Input: CustomSelectInput }}
            className="react-select"
            classNamePrefix="react-select"
            name={props.name || "undefined"}
            placeholder={<IntlMessages id="discount.select-one-type" />}
            options={[
                {
                    label: "True",
                    value: "true"
                },
                {
                    label: "False",
                    value: "false"
                },
            ]}
            value={GetBooleanType(props.value) || null}
            onChange={val => props.onChange(val, props.name)}
        />
    )
}

export default ContentComponent;