import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import profile from './profile';
import ref from './ref';
import upgrade from './upgrade';
import setting from './setting';

const Applications = ({ match }) => (
    <div className='dashboard-wrapper'>
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/`} />
            <Route path={`${match.url}/profile`} component={profile} />
            <Route path={`${match.url}/ref`} component={ref} />
            <Route path={`${match.url}/upgrade`} component={upgrade} />
            <Route path={`${match.url}/setting`} component={setting} />
            <Route path={`${match.url}`} component={profile} />
            <Redirect to='/error' />
        </Switch>
    </div>
);
export default Applications;
