import React, { Component } from 'react';
import {
    Row,
    Button,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Collapse,
    ButtonDropdown,
} from 'reactstrap';
import { injectIntl } from 'react-intl';

import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import Breadcrumb from '../../navs/Breadcrumb';
import IntlMessages from '../../../helpers/IntlMessages';
import classnames from 'classnames';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import TooltipItem from '../../../components/common/TooltipItem';

const formatDatasExport = (data) => {
    let custs = [];

    for (let i = 0, j = data?.length; i < j; i++) {
        custs.push({
            Stt: i + 1,
            'Tên nhân viên': data[i].name,
            'Vị trí công việc': data[i].permission_name,
            'Tình trạng công việc':
                data[i].status_working === 'on' ? 'Đang làm' : 'Đã nghỉ việc',
        });
    }
    return custs;
};

const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const exportToCSV = ({ csvData, fileName }) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};

class PageHeading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownSplitOpen: false,
            dropdownBtnOpen: false,
            addLogs: {
                placement: 'top',
                text: '',
                body: 'Là chức năng để thêm Hoa hồng, Thưởng, Tính lương tăng ca, Tạm ứng, Tiền phạt và số giờ nghỉ của nhân viên. Có thể thêm cho 1 hoặc nhiều nhân viên.',
            },
        };
    }

    handleDownloadData = () => {
        const fileName = 'Danh sách Nhân sự';

        exportToCSV({
            csvData: formatDatasExport(this.props.data),
            fileName,
        });
    };

    toggleDisplayOptions = () => {
        this.setState((prevState) => ({
            displayOptionsIsOpen: !prevState.displayOptionsIsOpen,
        }));
    };

    toggleDisplayBtn = () => {
        this.setState((prevState) => ({
            dropdownBtnOpen: !prevState.dropdownBtnOpen,
        }));
    };

    render() {
        const { messages } = this.props.intl;
        const {
            heading,
            setting,
            match,
            totalItem,
            toggleFunc,
            isDisabledAdd,
            isDisabledUpdate,
            toggleLogFunc,
            changePageSizeFunc,
            onSearchKeyFunc,
        } = this.props;

        const startIndex = (setting.currentPage - 1) * setting.selectedPageSize;
        const endIndex = setting.currentPage * setting.selectedPageSize;

        const { displayOptionsIsOpen, dropdownBtnOpen } = this.state;
        return (
            <Row className='list-page-heading__row'>
                <Colxx xxs='12'>
                    <div className='mb-2'>
                        <h1>
                            <IntlMessages id={heading} />
                        </h1>
                        <div className='text-zero top-right-button-container'>
                            <ButtonDropdown
                                isOpen={dropdownBtnOpen}
                                toggle={this.toggleDisplayBtn}
                            >
                                <Button
                                    color='primary'
                                    size='md'
                                    className={classnames('top-right-button', {
                                        disable: isDisabledAdd,
                                    })}
                                    onClick={() => toggleFunc()}
                                >
                                    <IntlMessages id='pages.add-new' />
                                </Button>
                                <DropdownToggle caret color='primary' />
                                <DropdownMenu>
                                    <DropdownItem
                                        className={classnames({
                                            disable: isDisabledUpdate,
                                        })}
                                        onClick={() => toggleLogFunc()}
                                    >
                                        <IntlMessages id='pages.add-new-log' />
                                        <TooltipItem
                                            item={this.state.addLogs}
                                            id={4}
                                        />
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    {/* <DropdownItem className='d-flex align-items-center'>
                                        <i className='simple-icon-cloud-upload pr-2' />
                                        Tải lên từ file excel
                                    </DropdownItem>
                                    <DropdownItem divider /> */}
                                    <DropdownItem
                                        onClick={this.handleDownloadData}
                                        className='d-flex align-items-center'
                                    >
                                        <i className='simple-icon-cloud-download pr-2' />
                                        Tải về file excel
                                    </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                            {/* <ButtonDropdown
                                isOpen={dropdownBtnOpen}
                                toggle={this.toggleDisplayBtn}
                            >
                                <DropdownToggle caret color='primary'>
                                    <IntlMessages id='pages.add-new' />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem
                                        className={classnames({
                                            disable: isDisabledAdd,
                                        })}
                                        onClick={() => toggleFunc()}
                                    >
                                        <IntlMessages id='pages.add-new-user-staff' />
                                    </DropdownItem>
                                    <DropdownItem
                                        className={classnames({
                                            disable: isDisabledUpdate,
                                        })}
                                        onClick={() => toggleLogFunc()}
                                    >
                                        <IntlMessages id='pages.add-new-log' />
                                    </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown> */}
                            {/* <Button
                color="primary"
                size="md"
                className="top-right-button"
                onClick={() => toggleFunc()}
              >
                <IntlMessages id="pages.add-new" />
              </Button> */}
                            {'  '}
                        </div>
                        <Breadcrumb match={match} />
                    </div>

                    <div className='mb-2'>
                        <Button
                            color='empty'
                            className='pt-0 pl-0 d-inline-block d-md-none'
                            onClick={this.toggleDisplayOptions}
                        >
                            <IntlMessages id='pages.display-options' />{' '}
                            <i className='simple-icon-arrow-down align-middle' />
                        </Button>
                        <Collapse
                            isOpen={displayOptionsIsOpen}
                            className='d-md-block'
                            id='displayOptions'
                        >
                            <div className='d-block d-md-inline-block'>
                                <div className='search-sm d-inline-block float-md-left mr-1 mb-1 align-top'>
                                    <input
                                        type='text'
                                        name='keyword'
                                        id='search'
                                        placeholder={
                                            messages['user-staff.search']
                                        }
                                        onKeyPress={(e) => onSearchKeyFunc(e)}
                                        onChange={(e) => onSearchKeyFunc(e)}
                                    />
                                </div>
                            </div>
                            <div className='float-md-right'>
                                <span className='text-muted text-small mr-1'>{`${startIndex}-${endIndex} of ${totalItem} `}</span>
                                <UncontrolledDropdown className='d-inline-block'>
                                    <DropdownToggle
                                        caret
                                        color='outline-dark'
                                        size='xs'
                                    >
                                        {setting.selectedPageSize}
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        {setting.pageSizes.map(
                                            (size, index) => {
                                                return (
                                                    <DropdownItem
                                                        key={index}
                                                        onClick={() =>
                                                            changePageSizeFunc(
                                                                size
                                                            )
                                                        }
                                                    >
                                                        {size}
                                                    </DropdownItem>
                                                );
                                            }
                                        )}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </Collapse>
                    </div>
                    {/* <Separator /> */}
                </Colxx>
            </Row>
        );
    }
}

export default injectIntl(PageHeading);
