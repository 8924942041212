import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { RandomString } from '../../../helpers/Utils';
import ItemSetting from './ItemSetting';

const RenderRowWithColumns = (props) => {
    return (
        <Row>
            {[...new Array(props.col).keys()].map((c) => (
                <Col key={RandomString(5)}>
                    <ItemSetting
                        data={props.data}
                        isLoading={props.isLoading}
                        onActions={props.onActions}
                        orders={props.orders}
                        infoTable={props.infoTable}
                        y={c}
                        x={props.x}
                        position={props.position}
                        billDate={props.billDate}
                        maxFloor={props.maxFloor}
                        selectedFloorTable={props.selectedFloorTable}
                        onUpdateSelectedFloorTable={
                            props.onUpdateSelectedFloorTable
                        }
                        floorsActiveId={props.floorsActiveId}
                    />
                </Col>
            ))}
        </Row>
    );
};

const RenderGridLayout = (props) => {
    return (
        <div className='table__container'>
            {[...new Array(props.row).keys()].map((r) => (
                <RenderRowWithColumns
                    data={props.data}
                    isLoading={props.isLoading}
                    onActions={props.onActions}
                    orders={props.orders}
                    infoTable={props.infoTable}
                    col={props.col}
                    key={r}
                    x={r}
                    position={props.position}
                    billDate={props.billDate}
                    maxFloor={props.maxFloor}
                    selectedFloorTable={props.selectedFloorTable}
                    onUpdateSelectedFloorTable={
                        props.onUpdateSelectedFloorTable
                    }
                    floorsActiveId={props.floorsActiveId}
                />
            ))}
        </div>
    );
};

export default class TableChairGenarateMap extends Component {
    render() {
        const {
            data,
            orders,
            onActions,
            isLoading,
            infoTable,
            billDate,
            maxFloor,
            selectedFloorTable,
            onUpdateSelectedFloorTable,
            floorsActiveId,
        } = this.props;

        const { row, col, position } = data;

        // console.log('data: ', data, maxFloor);

        return row && col ? (
            <RenderGridLayout
                data={data}
                onActions={onActions}
                orders={orders}
                infoTable={infoTable}
                isLoading={isLoading}
                row={parseInt(row)}
                col={parseInt(col)}
                position={position}
                billDate={billDate}
                maxFloor={maxFloor}
                selectedFloorTable={selectedFloorTable}
                onUpdateSelectedFloorTable={onUpdateSelectedFloorTable}
                floorsActiveId={floorsActiveId}
            />
        ) : (
            <></>
        );
    }
}
