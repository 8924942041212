import React from "react";
import {
  Input,
  Label,
  Form, FormGroup,
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import ListRole from './ListRole'

const ContentComponent = (props) => {
  return (
    <Form>
      <FormGroup>
        <Label for="name">
          <IntlMessages id="name" />
        </Label>
        <Input type="text" name="name" id="name"
          disabled={props.setting.viewonly && !props.setting.add}
          value={props.values.name}
          onChange={props.onChange}
        />
      </FormGroup>
      <FormGroup>
        <ListRole
          settings={props.values.settings}
          onChangePermission={props.onChangePermission}
          isChecked={props.isChecked}
          disabled={props.setting.viewonly && !props.setting.add}
        />
      </FormGroup>
    </Form>
  )
}

export default ContentComponent