import classNames from 'classnames';
import React, { Fragment } from 'react';
import { Badge, Button, Card, CardBody, CardTitle, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { AddCommaNumber } from '../../../helpers/Utils';

const ContentDisplay = (props) => {
    // console.log(props);
    return (
        <Card className='mb-4'>
            <CardBody>
                <CardTitle>
                    <IntlMessages id='role.title' />
                </CardTitle>
                <Row className='mt-3 '>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            Form được thêm
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.label ? (
                                props.values.form.label
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )}
                        </p>
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            Tên trường dữ liệu
                        </p>
                        <p className='mb-3 text-capitalize'>
                            {props.values.label ? (
                                props.values.label
                            ) : (
                                <span className='text-lowercase'>
                                    (Chưa có thông tin)
                                </span>
                            )}
                        </p>
                    </Colxx>
                    <Colxx xxs='12' lg='6'>
                        <p className='text-muted text-small mb-2'>
                            Kiểu dữ liệu
                        </p>
                        <p className='mb-3'>{props.values.type}</p>
                    </Colxx>
                </Row>
            </CardBody>
        </Card>
    );
};

export default ContentDisplay;
