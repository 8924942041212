import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { RandomString } from '../../helpers/Utils';
import ItemSetting from './ItemSetting';

const RenderRowWithColumns = (props) => {
    return (
        <Row>
            {[...new Array(props.col).keys()].map((c) => (
                <Col key={RandomString(5)}>
                    <ItemSetting y={c} x={props.x} position={props.position} />
                </Col>
            ))}
        </Row>
    );
};

const RenderGridLayout = (props) => {
    return (
        <div className='container'>
            {[...new Array(props.row).keys()].map((r) => (
                <RenderRowWithColumns
                    col={props.col}
                    key={r}
                    x={r}
                    position={props.position}
                />
            ))}
        </div>
    );
};

export default class TableChairGenarateMap extends Component {
    render() {
        const { data } = this.props;

        const { row, col, position } = data;

        return row && col ? (
            <RenderGridLayout
                row={parseInt(row)}
                col={parseInt(col)}
                position={position}
            />
        ) : (
            <></>
        );
    }
}
