import moment from 'moment';
import React from 'react';
import {
    CustomInput,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Label,
} from 'reactstrap';
import { getAvatar } from '../../helpers/Utils';
import { Colxx } from '../../components/common/CustomBootstrap';
import SingleLightbox from '../../components/pages/SingleLightbox';
import IntlMessages from '../../helpers/IntlMessages';
import DatePicker from 'react-datepicker';

const ContentFormPersonal = (props) => {
    return (
        <>
            <Form>
                <FormGroup row>
                    {/* DESCRIPTION */}
                    <Colxx sm={12}>
                        <FormGroup>
                            <Label for='description'>Mô tả</Label>
                            <Input
                                type='text'
                                name='description'
                                id='description'
                                value={props.description}
                                onChange={props.handleChangeValue}
                            />
                        </FormGroup>
                    </Colxx>

                    {/* FULLNAME */}
                    <Colxx sm={12}>
                        <FormGroup>
                            <Label for='fullname'>
                                <IntlMessages id='profile.fullname' />
                            </Label>
                            <Input
                                type='text'
                                name='fullname'
                                id='fullname'
                                value={props.fullname}
                                onChange={props.handleChangeValue}
                            />
                        </FormGroup>
                    </Colxx>

                    {/* PHONE NUMBER */}
                    <Colxx sm={12}>
                        <FormGroup>
                            <Label for='phone'>
                                <IntlMessages id='profile.phone' />
                            </Label>
                            <Input
                                type='text'
                                name='phone'
                                id='phone'
                                value={props.phone}
                                onChange={props.handleChangeValue}
                            />
                        </FormGroup>
                    </Colxx>

                    {/* ADDRESS */}
                    <Colxx sm={12}>
                        <FormGroup>
                            <Label for='address'>Địa chỉ</Label>
                            <Input
                                type='text'
                                name='address'
                                id='address'
                                value={props.address}
                                onChange={props.handleChangeValue}
                            />
                        </FormGroup>
                    </Colxx>

                    {/* EMAIL */}
                    <Colxx sm={12}>
                        <FormGroup>
                            <Label for='email'>
                                <IntlMessages id='profile.email' />
                            </Label>
                            <Input
                                type='text'
                                name='email'
                                id='email'
                                value={props.email}
                                onChange={props.handleChangeValue}
                            />
                        </FormGroup>
                    </Colxx>

                    {/* BIRTHDAY */}
                    {/* <Colxx sm={12}>
                        <FormGroup>
                            <Label for='birth-day'>
                                <IntlMessages id='profile.birth-day' />
                            </Label>
                            <DatePicker
                                name='birthDay'
                                id='birth-day'
                                className='mb-3'
                                onChange={(date) =>
                                    props.handleChangeBirthDay(date)
                                }
                                selected={moment(props.birthDay)}
                                dateFormat='YYYY-MM-DD'
                            />
                        </FormGroup>
                    </Colxx> */}

                    {/* AVATAR */}
                    {/* <Colxx sm={12}>
                        <FormGroup>
                            <Label for='avatar'>
                                <IntlMessages id='profile.avatar' />{' '}
                                {!props.companyLogoFile && props.avatar ? (
                                    <span
                                        onClick={() => {
                                            props.handleDeleteImage('avatar');
                                        }}
                                        className={
                                            'cursor-pointer glyph-icon simple-icon-pencil'
                                        }
                                    />
                                ) : null}
                            </Label>
                            {props.companyLogoFile || !props.companyLogo ? (
                                <InputGroup
                                    className='mb-3'
                                    encType='multipart/form-data'
                                >
                                    <CustomInput
                                        label={props.companyLogo}
                                        type='file'
                                        id='avatar'
                                        name='avatar'
                                        onChange={props.handleChangeValue}
                                    />
                                </InputGroup>
                            ) : (
                                <SingleLightbox
                                    thumb={getAvatar(props.companyLogo)}
                                    large={getAvatar(props.companyLogo)}
                                    className='responsive border-0 card-img-top mb-3 mxh-700 profile-avatar'
                                />
                            )}
                        </FormGroup>
                    </Colxx> */}
                </FormGroup>
            </Form>
        </>
    );
};

export default ContentFormPersonal;
