import React from 'react';
import { Card, CardBody, Row, Table } from 'reactstrap';
import classnames from 'classnames';
import { Colxx, Separator } from '../../components/common/CustomBootstrap';
import moment from 'moment';

class ComponentToPrint extends React.Component {
    componentWillUnmount() {
        document.body.classList.remove('noscroll');
    }

    render() {
        let {
            items,
            discount,
            cardWrapper,
            billDate,
            pin_print = '',
        } = this.props;

        const fullName = localStorage.getItem('fullname');
        const timeNow = moment(new Date()).format('HH:mm');

        return !items.length ? (
            <div className='loading' />
        ) : (
            <Row className='invoice-react' id='bill-to-print'>
                <Colxx xxs='12' className='mb-4'>
                    <Card
                        className={classnames('mb-5 invoice-contents p-2', {
                            card: !cardWrapper,
                        })}
                    >
                        <CardBody className='d-flex flex-column justify-content-between p-2'>
                            <div
                                className={classnames(
                                    'd-flex flex-column align-self-center order-invoice p-0 w-100',
                                    {
                                        'card-body': !cardWrapper,
                                    }
                                )}
                            >
                                <div className='d-flex flex-row justify-content-between mb-3'>
                                    <div className='d-flex flex-column justify-content-center align-items-start w-55 mr-2 py-2 text-semi-muted bg-area-print'>
                                        <span className='mb-2 text-small text-to-print'>
                                            NV: {fullName}
                                        </span>
                                        <span className='mb-2 text-small text-to-print'>
                                            #{pin_print}
                                        </span>
                                    </div>
                                    <div className=' w-45 d-flex flex-column justify-content-center text-right py-2 text-semi-muted bg-area-print'>
                                        <span className='mb-2 text-small text-to-print'>
                                            {billDate}
                                        </span>
                                        <span className='mb-0 text-small text-to-print'>
                                            {timeNow}
                                        </span>
                                    </div>
                                </div>
                                <Separator className='mb-2' />
                                <Table borderless>
                                    <thead>
                                        <style>{`@media print {.no-print{display: none;}}`}</style>
                                        <tr>
                                            <th className='text-muted pd-light text-extra-small mb-2 text-to-print'>
                                                Số lượng
                                            </th>
                                            <th className='text-left pd-light text-muted text-extra-small mb-2 text-to-print'>
                                                Tên sản phẩm
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items?.length &&
                                            items?.map((item, idx) => (
                                                <React.Fragment key={idx}>
                                                    <tr>
                                                        <td className='text-to-print pd-light text-primary'>
                                                            {item?.count}
                                                        </td>
                                                        <td className='text-to-print pd-light text-left'>
                                                            {item?.product.name}
                                                        </td>
                                                    </tr>
                                                    {(discount &&
                                                        discount.saved &&
                                                        discount.note) ||
                                                    (item?.discount &&
                                                        item?.discount.note) ? (
                                                        <tr>
                                                            <td
                                                                className='discount-note no-print pd-light'
                                                                colSpan='4'
                                                            >
                                                                {`* ${GetDiscountOrderNote(
                                                                    discount,
                                                                    item
                                                                )}`}
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                </React.Fragment>
                                            ))}
                                    </tbody>
                                </Table>
                            </div>
                            {/* <div className='d-flex flex-column'>
                                <span className='text-small text-center'>
                                    Bản quyền thuộc vmass.vn.
                                </span>
                            </div> */}
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>
        );
    }
}

export default ComponentToPrint;

const GetDiscountOrderNote = (discount, item) => {
    let note = '';

    if (discount && discount.saved && discount.note) {
        note = discount.note;
    } else if (item.discount) {
        note = item.discount.note;
    }
    return note;
};
