import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ContentComponent = (props) => {
    return (
        <DatePicker
            name={props.name || 'undefined'}
            disabled={props.disabled || false}
            onChange={(date) => {
                props.onChange(date, props.name);
            }}
            selected={props.value}
            dateFormat='YYYY-MM-DD'
        />
    );
};

export default ContentComponent;
