import React from 'react';
import { Input, Label, Form, FormGroup } from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import Select from 'react-select';
import CustomSelectInput from '../../../components/common/CustomSelectInput';

const ContentComponent = (props) => {
    return (
        <Form>
            <FormGroup>
                <Label for='name'>
                    <IntlMessages id='name' />
                </Label>
                <Input
                    type='text'
                    name='name'
                    id='name'
                    disabled={props.setting.viewonly && !props.setting.add}
                    value={props.values.name}
                    onChange={props.onChange}
                />
            </FormGroup>
            <FormGroup>
                <Label for='username'>
                    <IntlMessages id='profile.username' />
                </Label>
                <Input
                    type='text'
                    name='username'
                    id='username'
                    disabled={props.setting.viewonly && !props.setting.add}
                    value={props.values.username}
                    onChange={props.onChange}
                />
            </FormGroup>
            <FormGroup>
                <Label for='email'>
                    <IntlMessages id='email' />
                </Label>
                <Input
                    type='text'
                    name='email'
                    id='email'
                    disabled={props.setting.viewonly && !props.setting.add}
                    value={props.values.email}
                    onChange={props.onChange}
                />
            </FormGroup>
            <FormGroup>
                <Label for='password'>
                    <IntlMessages id='user.password' />
                </Label>
                <Input
                    type='password'
                    name='password'
                    id='password'
                    placeholder={
                        props.setting.add ? '' : 'Để trống nếu không thay đổi'
                    }
                    disabled={props.setting.viewonly && !props.setting.add}
                    value={props.values.password}
                    onChange={props.onChange}
                />
            </FormGroup>
            <FormGroup>
                <Label for='repassword'>
                    <IntlMessages id='user.re-password' />
                </Label>
                <Input
                    type='password'
                    name='repassword'
                    id='repassword'
                    placeholder={
                        props.setting.add ? '' : 'Để trống nếu không thay đổi'
                    }
                    disabled={props.setting.viewonly && !props.setting.add}
                    value={props.values.repassword}
                    onChange={props.onChange}
                />
            </FormGroup>

            <FormGroup>
                <Label for='user_role_id'>
                    <IntlMessages id='role.title' />
                </Label>
                <Select
                    components={{ Input: CustomSelectInput }}
                    className='react-select'
                    classNamePrefix='react-select'
                    name='user_role_id'
                    value={props.values.user_role_id}
                    isDisabled={props.setting.viewonly && !props.setting.add}
                    placeholder={<IntlMessages id='user-role.select-one' />}
                    options={props.user_roles}
                    onChange={(e) => {
                        props.onChange(e, 'user_role_id');
                    }}
                />
            </FormGroup>
        </Form>
    );
};

export default ContentComponent;
