import React from 'react';
import { AddCommaNumber } from '../../../helpers/Utils';
import classnames from 'classnames';

export const ComponentTitle = (props) => {
    return props.compare ? (
        <div style={{ textAlign: props.align }}> {`${props.title}`}</div>
    ) : (
        <div style={{ textAlign: props.align }}>
            {' '}
            {`${props.title}: ${props.value}`}
        </div>
    );
};

export const ComponentBody = (props) => {
    // console.log(props);
    return props.compare ? (
        <div style={{ textAlign: props.align }}>
            {/* GIÁ TRỊ CŨ */}
            <span className='text-muted'>
                {props.type === 'price'
                    ? AddCommaNumber(props.compare_value.flip_value)
                    : props.compare_value.flip_value}
            </span>
            {/* GIÁ TRỊ HIỆN TẠI */}
            <br />
            <span>
                {props.type === 'price'
                    ? AddCommaNumber(props.value)
                    : props.value}
            </span>
            <br />
            {/* PHẦN TRĂM SO SÁNH */}
            <span
                className={
                    props.compare_value.value == 0
                        ? ''
                        : props.compare_value.increment
                        ? 'green'
                        : 'red'
                }
            >
                {` (${
                    props.compare_value.value != 0 &&
                    props.compare_value.increment
                        ? '+'
                        : ''
                }${props.compare_value.value}%)`}
            </span>
        </div>
    ) : (
        <div style={{ textAlign: props.align }}>
            {/* GIÁ TRỊ HIỆN TẠI */}
            <span>
                {props.type === 'price'
                    ? AddCommaNumber(props.value)
                    : props.value}
            </span>
            {/* PHẦN TRĂM SO SÁNH */}
            {props.all ? null : props.compare_value.value == 0 &&
              !props.compare_value.increment ? (
                <span>{' (0%)'}</span>
            ) : (
                <span
                    className={
                        props.compare_value.value == 0
                            ? ''
                            : props.compare_value.increment
                            ? 'green'
                            : 'red'
                    }
                >
                    {` (${
                        props.compare_value.value != 0 &&
                        props.compare_value.increment
                            ? '+'
                            : ''
                    }${props.compare_value.value}%)`}
                </span>
            )}
        </div>
    );
};

export const ComponentBodyDecrement = (props) => {
    // console.log(props);
    return props.compare ? (
        <div style={{ textAlign: props.align }}>
            {/* GIÁ TRỊ CŨ */}
            <span className='text-muted'>
                {props.type === 'price'
                    ? AddCommaNumber(props.compare_value.flip_value)
                    : props.compare_value.flip_value}
            </span>
            {/* GIÁ TRỊ HIỆN TẠI */}
            <br />
            <span>
                {props.type === 'price'
                    ? AddCommaNumber(props.value)
                    : props.value}
            </span>
            <br />
            {/* PHẦN TRĂM SO SÁNH */}
            <span
                className={
                    props.compare_value.value == 0
                        ? ''
                        : props.compare_value.increment
                        ? 'red'
                        : 'green'
                }
            >
                {` (${
                    props.compare_value.value != 0 &&
                    props.compare_value.increment
                        ? '+'
                        : ''
                }${props.compare_value.value}%)`}
            </span>
        </div>
    ) : (
        <div style={{ textAlign: props.align }}>
            {/* GIÁ TRỊ HIỆN TẠI */}
            <span>
                {props.type === 'price'
                    ? AddCommaNumber(props.value)
                    : props.value}
            </span>
            {/* PHẦN TRĂM SO SÁNH */}
            {props.all ? null : props.compare_value.value == 0 &&
              !props.compare_value.increment ? (
                <span>{' (0%)'}</span>
            ) : (
                <span
                    className={
                        props.compare_value.value == 0
                            ? ''
                            : props.compare_value.increment
                            ? 'red'
                            : 'green'
                    }
                >
                    {` (${
                        props.compare_value.value != 0 &&
                        props.compare_value.increment
                            ? '+'
                            : ''
                    }${props.compare_value.value}%)`}
                </span>
            )}
        </div>
    );
};
