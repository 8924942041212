import React, { memo } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Form,
    FormGroup,
} from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Select from 'react-select';
import CustomSelectInput from '../../../components/common/CustomSelectInput';
import { SetType, GetType } from '../../../helpers/Companies';

const ContentComponent = ({
    title,
    isOpen,
    toggle,
    onSubmit,
    values,
    setting,
    onChange,
    onSearch,
}) => {
    // console.log('render AddComponent');
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            wrapClassName='modal-right'
            backdrop={true}
            autoFocus={false}
        >
            <Form onSubmit={(e) => onSubmit(e)}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='scrool modal-right-add'>
                    <PerfectScrollbar
                        options={{
                            suppressScrollX: true,
                            wheelPropagation: false,
                        }}
                        id='modal-right__body'
                    >
                        {setting.add ? (
                            <FormGroup>
                                <Label for='search' className='text-muted'>
                                    <IntlMessages id='companies.find-company-uuid' />
                                </Label>

                                <InputGroup>
                                    <Input
                                        autoFocus={true}
                                        type='text'
                                        name='search'
                                        id='search'
                                        value={values.search}
                                        onChange={onChange}
                                    />
                                    <InputGroupAddon addonType='append'>
                                        <Button onClick={onSearch}>
                                            <IntlMessages id='menu.search' />
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        ) : null}

                        <FormGroup>
                            <Label for='uuid' className='text-muted'>
                                <IntlMessages id='company-uuid' />
                            </Label>
                            <Input
                                type='text'
                                name='uuid'
                                id='uuid'
                                disabled={true}
                                value={values.uuid}
                                onChange={() => {}}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for='name' className='text-muted'>
                                <IntlMessages id='company-name' />
                            </Label>
                            <Input
                                type='text'
                                name='name'
                                id='name'
                                disabled={true}
                                value={values.name}
                                onChange={() => {}}
                            />
                        </FormGroup>
                        {setting.update ? (
                            <FormGroup>
                                <Label for='namelink' className='text-muted'>
                                    <IntlMessages id='companies.name' />
                                </Label>
                                <Input
                                    type='text'
                                    name='namelink'
                                    id='namelink'
                                    disabled={true}
                                    value={values.namelink}
                                    onChange={() => {}}
                                />
                            </FormGroup>
                        ) : null}

                        <FormGroup>
                            <Label for='type' className='text-muted'>
                                <IntlMessages id='companies.type' />
                            </Label>
                            <Select
                                components={{ Input: CustomSelectInput }}
                                className='react-select'
                                classNamePrefix='react-select'
                                name='type'
                                placeholder={
                                    <IntlMessages id='companies.unset' />
                                }
                                options={SetType}
                                onChange={(e) => {
                                    onChange(e, 'type');
                                }}
                                value={GetType(values.type)}
                            />
                        </FormGroup>
                    </PerfectScrollbar>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary'>
                        <IntlMessages id='companies.btn-add' />
                    </Button>{' '}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default memo(ContentComponent);
