import React, { Component, Fragment } from 'react';
import { Collapse, Button, Row, Card, CardBody } from 'reactstrap';
import axios from 'axios';
import { END_POINT, END_POINT_SOCKET } from '../../constants/defaultValues';
import Pagination from '../../containers/pages/Pagination';
import io from 'socket.io-client';
import {
    NotificatioErrController,
    collect,
    AddCommaNumber,
} from '../../helpers/Utils';
import { NotificationManager } from '../../components/common/react-notifications';
import { Colxx } from '../../components/common/CustomBootstrap';
import {
    PageHeading,
    OrderListView,
} from '../../containers/pages/table-pending-payment';
import IntlMessages from '../../helpers/IntlMessages';
import InvoicePages from '../../views/app/pages/invoice';
import CustomModalContainer from '../app/ui/modal';
import classnames from 'classnames';

import { isF } from '../../helpers/Roles';
const ROLE_ALIAS = 'table_pending_payment';

class ContentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageSize: 12,
            pageSizes: [12, 24],
            currentPage: 1,
            totalPage: 1,

            isLoading: false,
            data: [],
            collapse: false,
            accordion: [],
            items: [1, 2, 3],
            totalItemCount: 0,
            search: '',

            selectedItems: [],

            isOpenModalInvoice: false,

            // store
            store: window.location.host.split('.')[0],
            hash: props.location.pathname.split('/')[2],
        };
        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        // alert mp3
        this.socket.on('alert-mp3', () => {
            try {
                let alert = document.getElementById('alert-mp3');
                alert.play();
            } catch (error) {
                console.log(error);
                console.log('alert mp3 exception');
            }
        });

        // Role
        this.socket.on('update-user-role', (res) => {
            localStorage.setItem('roleSettings', JSON.stringify(res.data));
        });

        // alert notification
        this.socket.on('alert-paymenter', (res) => {
            NotificationManager.info(
                'Có sản phẩm mới được đưa vào danh sách chờ thanh toán!',
                'Lời nhắc',
                3000,
                null,
                null,
                'filled'
            );
            this.dataListRender();
        });

        this.socket.on('refresh-done-order', (res) => {
            this.dataListRender();
        });

        this.dataListRender();
    }

    dataListRender() {
        const { selectedPageSize, currentPage, search } = this.state;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(
                `${
                    END_POINT + '/table/json'
                }?pageSize=${selectedPageSize}&currentPage=${currentPage}&search=${search}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                let anyAccordion = this.state.accordion;
                let lessNumber = data.data.length - anyAccordion.length;
                for (var i = 0; i < lessNumber; i++) {
                    anyAccordion.push(false);
                }

                this.setState({
                    totalPage: data.totalPage,
                    items: data.data,
                    selectedItems: [],
                    totalItemCount: data.totalItem,
                    isLoading: true,
                    accordion: anyAccordion,
                });
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
            });
    }

    toggleAccordion = (tab) => {
        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => (tab === index ? !x : false));
        this.setState({
            accordion: state,
        });
    };

    changePageSize = (size) => {
        this.setState(
            {
                selectedPageSize: size,
                currentPage: 1,
            },
            () => this.dataListRender()
        );
    };

    onChangePage = (page) => {
        this.setState(
            {
                currentPage: page,
            },
            () => this.dataListRender()
        );
    };

    onSearchKey = (e) => {
        if (!e) return false;

        if (e.target) {
            let {
                target: { value },
            } = e;
            let v = value;
            if (value === undefined) {
                v = e.target.firstElementChild.value; // parent to child

                this.setState(
                    {
                        search: v.toLowerCase(),
                    },
                    () => this.dataListRender()
                );
            } else {
                this.setState(
                    {
                        search: value.toLowerCase(),
                    },
                    () => {
                        setTimeout(() => {
                            this.dataListRender();
                        }, 500);
                    }
                );
            }
        }

        if (e.key === 'Enter') {
            this.setState(
                {
                    search: e.target.value.toLowerCase(),
                },
                () => this.dataListRender()
            );
        }
    };

    onCheckItem = (e, id) => {
        let selectedItems = this.state.selectedItems;
        if (selectedItems.includes(id)) {
            selectedItems = selectedItems.filter((x) => x !== id);
        } else {
            selectedItems.push(id);
        }
        this.setState({
            selectedItems: selectedItems,
        });
        document.activeElement.blur();
    };

    toggleModalInvoice = (items) => {
        if (!isF(ROLE_ALIAS)) return false;

        this.setState({
            isOpenModalInvoice: !this.state.isOpenModalInvoice,
            selectedItems:
                this.state.selectedItems.length < 1
                    ? items.map((d) => d.id)
                    : this.state.selectedItems,
        });

        if (this.state.isOpenModalInvoice) {
            // close
            this.dataListRender();
        }
    };

    render() {
        const {
            currentPage,
            items,
            selectedPageSize,
            isLoading,
            totalItemCount,
            pageSizes,
            totalPage,
            selectedItems,
            isOpenModalInvoice,
        } = this.state;
        // console.log('items: ', items);
        const { match } = this.props;
        const startIndex = (currentPage - 1) * selectedPageSize;
        const endIndex = currentPage * selectedPageSize;

        return !isLoading ? (
            <div className='loading' />
        ) : (
            <Fragment>
                <div className='disable-text-selection pending-payment'>
                    <audio
                        id='alert-mp3'
                        preload='auto'
                        src='/assets/sound/alert.mp3'
                    ></audio>

                    <PageHeading
                        heading='menu.payment'
                        pageSizes={pageSizes}
                        selectedPageSize={selectedPageSize}
                        startIndex={startIndex}
                        endIndex={endIndex}
                        match={match}
                        totalItemCount={totalItemCount}
                        changePageSize={this.changePageSize}
                        onSearchKey={this.onSearchKey}
                    />
                    {/* INVOICE */}
                    <CustomModalContainer
                        isOpenModal={isOpenModalInvoice}
                        toggleModal={this.toggleModalInvoice}
                        title={''}
                    >
                        <InvoicePages
                            location={{
                                aboutProps: {
                                    discount: false,
                                    orders: selectedItems,
                                },
                            }}
                        />
                    </CustomModalContainer>

                    <Row className='content-page__row'>
                        <Colxx xxs='12' className='mb-4'>
                            {items.length ? (
                                items?.map((item, idx) => {
                                    return (
                                        <div
                                            className='d-flex mb-3 card'
                                            key={idx}
                                        >
                                            <div
                                                className='d-flex flex-grow-1 min-width-zero'
                                                onClick={() =>
                                                    this.toggleAccordion(idx)
                                                }
                                            >
                                                <Button
                                                    color='link'
                                                    aria-expanded={
                                                        this.state.accordion[
                                                            idx
                                                        ]
                                                    }
                                                    className='card-body btn-empty btn-link list-item-heading text-left text-one btn btn-link pre-wrap'
                                                >
                                                    {item.orders[0]
                                                        .user_identify === null
                                                        ? 'Tại quầy'
                                                        : `Khu ${item.orders[0].floor} - Bàn ${item.orders[0].user_identify}`}
                                                </Button>

                                                <div className='btn-link card-body list-item-heading text-one btn btn-link pre-wrap btn btn-link text-end'>
                                                    {`${AddCommaNumber(
                                                        item.price
                                                    )} đ`}
                                                </div>
                                            </div>
                                            <Collapse
                                                isOpen={
                                                    this.state.accordion[idx]
                                                }
                                            >
                                                <div className='card-body accordion-content pt-0 overflow-hidden'>
                                                    {item.orders.length &&
                                                        item.orders.map(
                                                            (order) => {
                                                                return (
                                                                    <OrderListView
                                                                        id={`table_pending_payment_order_id_${order.id}`}
                                                                        key={`table_pending_payment_order_${order.id}`}
                                                                        order={
                                                                            order
                                                                        }
                                                                        underLine={
                                                                            idx <
                                                                            item
                                                                                .orders
                                                                                .length -
                                                                                1
                                                                        }
                                                                        selectedItems={
                                                                            selectedItems
                                                                        }
                                                                        onCheckItem={
                                                                            this
                                                                                .onCheckItem
                                                                        }
                                                                        isSelect={selectedItems.includes(
                                                                            order.id
                                                                        )}
                                                                        collect={
                                                                            collect
                                                                        }
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                </div>
                                                {/* <div className='center pad-25px'>
                                                <Button
                                                    size='sm'
                                                    className={classnames(
                                                        'btn m-0-auto',
                                                        {
                                                            disable:
                                                                !isF(
                                                                    ROLE_ALIAS
                                                                ),
                                                        }
                                                    )}
                                                    color='success'
                                                    outline
                                                    onClick={() => {
                                                        this.toggleModalInvoice(
                                                            item.orders
                                                        );
                                                    }}
                                                >
                                                    <IntlMessages id='order.to-bill-now' />
                                                </Button>
                                            </div> */}
                                            </Collapse>
                                        </div>
                                    );
                                })
                            ) : (
                                <Card>
                                    <CardBody
                                        className={
                                            'd-flex justify-content-center align-items-center'
                                        }
                                    >
                                        Chưa có bàn nào cần thanh toán
                                    </CardBody>
                                </Card>
                            )}
                        </Colxx>
                        <Pagination
                            currentPage={currentPage}
                            totalPage={totalPage}
                            onChangePage={(i) => this.onChangePage(i)}
                        />
                    </Row>
                </div>
            </Fragment>
        );
    }
}

export default ContentComponent;
