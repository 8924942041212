import React, { Component } from "react";
import {
  Row,
  Button,
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  CustomInput,
} from "reactstrap";
import { injectIntl } from "react-intl";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";

class ListPageHeading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownSplitOpen: false,
    };
  }

  toggleSplit =()=> {
    this.setState(prevState => ({
      dropdownSplitOpen: !prevState.dropdownSplitOpen
    }));
  }

  render() {
    const {
      handleChangeSelectAll,
      selectedItemsLength,
      itemsLength,
      toggleModal,
      onActions
    } = this.props;

    const { dropdownSplitOpen } = this.state;
    return (
      <Row>
        <Colxx xxs="12">
          <div className="mb-2">
            <h1>
              <IntlMessages id={"faq.title"}/>
            </h1>
            <div className="text-zero top-right-button-container">
              <Button
                color="primary"
                size="lg"
                className="top-right-button"
                onClick={()=>toggleModal()}
              >
                <IntlMessages id="pages.add-new" />
              </Button>
              {"  "}
              <ButtonDropdown
                isOpen={dropdownSplitOpen}
                toggle={this.toggleSplit}
              >
                <div className="btn btn-primary btn-lg pl-4 pr-0 check-button check-all">
                    <CustomInput
                      className="custom-checkbox mb-0 d-inline-block"
                      type="checkbox"
                      id="checkAll"
                      checked={selectedItemsLength >= itemsLength}
                      onChange={() => handleChangeSelectAll(true)}
                      label={
                        <span
                          className={`custom-control-label ${
                            selectedItemsLength > 0 &&
                            selectedItemsLength < itemsLength
                              ? "indeterminate"
                              : ""
                          }`}
                        />
                      }
                    />
                </div>
                <DropdownToggle
                  caret
                  color="primary"
                  className="dropdown-toggle-split btn-lg"
                />
                <DropdownMenu right>
                  {selectedItemsLength >= 1 
                  ? 
                  <React.Fragment>
                    <DropdownItem onClick={() => {onActions("delete")}}>
                      <IntlMessages id="todo.delete"/>
                    </DropdownItem>
                  </React.Fragment>
                  : 
                  <DropdownItem>
                    <IntlMessages id="todo.no-selected"/>
                  </DropdownItem>
                  }  
                  {selectedItemsLength === 1 
                  ? 
                  <DropdownItem onClick={() => {onActions("update")}}>
                    <IntlMessages id="todo.update" />
                  </DropdownItem>
                  : <></>}  
                </DropdownMenu>
              </ButtonDropdown>
            </div>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
    );
  }
}

export default injectIntl(ListPageHeading);
