import React, { Component } from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Input
} from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
// import IntlMessages from '../../helpers/IntlMessages';
import { TableChairGenerateMap } from './tablechair';
import STATUS from '../../constants/status';
import classnames from 'classnames';
import { isRorF } from '../../helpers/Roles';
import ListMenu from './ListMenu';
// import { NotificationManager } from '../../components/common/react-notifications';
// import { END_POINT } from '../../constants/defaultValues';
// import Axios from 'axios';
import CategoryMenu from './CategoryMenu';

class TableSetting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: '',
            inputValue: '',
            filteredData: [],
            bills: [],
            orders: props.orders,
            tableData: {},
            isInFullScreen: false,

            listsData: [],
            activeIndex: 99,
            loading: false,

            accordion: [],
        };
        this.searchContainerRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (
            this.searchContainerRef.current &&
            !this.searchContainerRef.current.contains(event.target)
        ) {
            this.setState({ isInputVisible: false });
        }
    };

    toggleInput = () => {
        const { isInputVisible } = this.state
        this.setState({ 
            isInputVisible: !isInputVisible, 
            activeIndex: 99,
            listsData: []
        });
    };

    onSearchKey = (e) => {
        const { menus, onClickImage } = this.props;
        if (e.key === 'Enter') {
            const foundProduct = menus.reduce((found, menu) => {
                if (!found) {
                    return menu.data.find(product => product.sku?.toLowerCase() === e.target.value?.toLowerCase() ||
                            product.barcode?.toLowerCase() === e.target.value?.toLowerCase());
                }
                return found;
            }, null);
            
            if (foundProduct) {
                onClickImage(foundProduct)
                this.setState({ search: '' });
            } else {
                console.log('SKU or BARCODE not found');
                return null;
            }
        }
    };

    removeVietnameseAccent = (str) => {
        // Map of Vietnamese characters with accents to their non-accented counterparts
        const mapAccents = {
            'à': 'a', 'á': 'a', 'ả': 'a', 'ã': 'a', 'ạ': 'a',
            'ă': 'a', 'ằ': 'a', 'ắ': 'a', 'ẳ': 'a', 'ẵ': 'a', 'ặ': 'a',
            'â': 'a', 'ầ': 'a', 'ấ': 'a', 'ẩ': 'a', 'ẫ': 'a', 'ậ': 'a',
            'è': 'e', 'é': 'e', 'ẻ': 'e', 'ẽ': 'e', 'ẹ': 'e',
            'ê': 'e', 'ề': 'e', 'ế': 'e', 'ể': 'e', 'ễ': 'e', 'ệ': 'e',
            'ì': 'i', 'í': 'i', 'ỉ': 'i', 'ĩ': 'i', 'ị': 'i',
            'ò': 'o', 'ó': 'o', 'ỏ': 'o', 'õ': 'o', 'ọ': 'o',
            'ô': 'o', 'ồ': 'o', 'ố': 'o', 'ổ': 'o', 'ỗ': 'o', 'ộ': 'o',
            'ơ': 'o', 'ờ': 'o', 'ớ': 'o', 'ở': 'o', 'ỡ': 'o', 'ợ': 'o',
            'ù': 'u', 'ú': 'u', 'ủ': 'u', 'ũ': 'u', 'ụ': 'u',
            'ư': 'u', 'ừ': 'u', 'ứ': 'u', 'ử': 'u', 'ữ': 'u', 'ự': 'u',
            'ỳ': 'y', 'ý': 'y', 'ỷ': 'y', 'ỹ': 'y', 'ỵ': 'y',
            'đ': 'd',
            'À': 'A', 'Á': 'A', 'Ả': 'A', 'Ã': 'A', 'Ạ': 'A',
            'Ă': 'A', 'Ằ': 'A', 'Ắ': 'A', 'Ẳ': 'A', 'Ẵ': 'A', 'Ặ': 'A',
            'Â': 'A', 'Ầ': 'A', 'Ấ': 'A', 'Ẩ': 'A', 'Ẫ': 'A', 'Ậ': 'A',
            'È': 'E', 'É': 'E', 'Ẻ': 'E', 'Ẽ': 'E', 'Ẹ': 'E',
            'Ê': 'E', 'Ề': 'E', 'Ế': 'E', 'Ể': 'E', 'Ễ': 'E', 'Ệ': 'E',
            'Ì': 'I', 'Í': 'I', 'Ỉ': 'I', 'Ĩ': 'I', 'Ị': 'I',
            'Ò': 'O', 'Ó': 'O', 'Ỏ': 'O', 'Õ': 'O', 'Ọ': 'O',
            'Ô': 'O', 'Ồ': 'O', 'Ố': 'O', 'Ổ': 'O', 'Ỗ': 'O', 'Ộ': 'O',
            'Ơ': 'O', 'Ờ': 'O', 'Ớ': 'O', 'Ở': 'O', 'Ỡ': 'O', 'Ợ': 'O',
            'Ù': 'U', 'Ú': 'U', 'Ủ': 'U', 'Ũ': 'U', 'Ụ': 'U',
            'Ư': 'U', 'Ừ': 'U', 'Ứ': 'U', 'Ử': 'U', 'Ữ': 'U', 'Ự': 'U',
            'Ỳ': 'Y', 'Ý': 'Y', 'Ỷ': 'Y', 'Ỹ': 'Y', 'Ỵ': 'Y',
            'Đ': 'D'
        };
    
        // Replace Vietnamese characters with their non-accented counterparts
        return str.replace(/[àáảãạăắằẳẵặâầấẩẫậèéẻẽẹêềếểễệìíỉĩịòóỏõọôồốổỗộơờớởỡợùúủũụưừứửữựỳýỷỹỵđÀÁẢÃẠĂẮẰẲẴẶÂẦẤẨẪẬÈÉẺẼẸÊỀẾỂỄỆÌÍỈĨỊÒÓỎÕỌÔỒỐỔỖỘƠỜỚỞỠỢÙÚỦŨỤƯỪỨỬỮỰỲÝỶỸỴĐ]/g, function(match) {
            return mapAccents[match];
        });
    }

    filterDataByName = (inputValue) => {
        const  menus  = [...this.props.menus];
        // return menus?.filter((category) =>
        //     category.data?.find((item) =>{

        //         return this.removeVietnameseAccent(item.name).toLowerCase().includes(inputValue.toLowerCase()) 
        //         || item.sku?.toLowerCase() === inputValue.toLowerCase()
        //     }
        // ))
        const foundItems = menus.reduce((found, category) => {
            const foundData = category.data.filter(item =>
                this.removeVietnameseAccent(item.name).toLowerCase().includes(this.removeVietnameseAccent(inputValue).toLowerCase()) ||
                    item.barcode?.toLowerCase().includes(inputValue.toLowerCase()) ||
                    item.sku?.toLowerCase().includes(inputValue.toLowerCase())
            );
            if (foundData.length > 0) {
                found.push({ id: category.id, name: category.name, data: foundData });
            }
            return found;
        }, []);
        
        return foundItems;
    };

    onSearchChange = (e) => {
        const inputValue = e.target.value;
        const filteredData = this.filterDataByName(inputValue);
        // console.log('filteredData: ', filteredData)
        this.setState({
            search: inputValue,
            filteredData: filteredData,
        });
    };

    toggleAccordion = (tab) => {
        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => (tab === index ? !x : false));
        this.setState({
            accordion: state,
        });
    };

    renderCategories() {
        const { menus } = this.props;
        return menus.map((categories, index) => {
            return (
                <span
                    onClick={() => {
                        this.onClickCategory(categories.name, index);
                    }}
                    key={categories.id}
                    id={index}
                    className={
                        this.state.activeIndex === index
                            ? 'active-menu nav_items cursor-pointer py-1 px-3'
                            : 'nav_items cursor-pointer py-1 px-3'
                    }
                >
                    {categories.name}
                </span>
            );
        });
    }

    onClickCategory = (name, index) => {
        let listsData = [...this.props.menus];

        if (name === 'all') {
            // const listsData = [...this.state.menus];
            const removeFirstElement = listsData.shift();
            this.setState((prev) => ({
                ...prev,
                search: '',
                listsData: [],
                activeIndex: 99,
            }));
        } else {
            const listsSort = listsData.find((menu) => menu.name === name);

            const lists = listsSort?.data?.map((d) => d);

            this.setState((prev) => ({
                ...prev,
                listsData: [{ name: name, data: lists }],
                activeIndex: index,
                search: ''
            }));
        }
    };

    render() {
        let {
            menus,
            is_loading_data,
            items,
            isLoading,
            orders,
            onActions,
            onUpdateSelectedFloorTable,
            selectedFloorTable,
            floorsActiveId,
            onToggleDetail,
            onClickImage,
            infoTable,
            billDate,
            dataScan,
        } = this.props;

        const { listsData, activeIndex, loading, isInputVisible, search, filteredData } = this.state;

        // Nhóm các mục theo floor
        const groupedByFloor = items?.position?.reduce((acc, item) => {
            const floor = item.floor;
            if (!acc[floor]) {
                acc[floor] = [];
            }
            acc[floor].push(item);
            return acc;
        }, {});

        // // Tính giá trị lớn nhất cho row, col và maxFloor
        // let maxRow = 0;
        // let maxCol = 0;
        // let maxFloor = 0;

        // Object.values(groupedByFloor).forEach((floorItems) => {
        //     floorItems.forEach((item) => {
        //         maxRow = Math.max(maxRow, item.y + 1);
        //         maxCol = Math.max(maxCol, item.x + 1);
        //         maxFloor = Math.max(maxFloor, item.floor);
        //     });
        // });

        // // Tạo đối tượng mới
        // const transformedData = {
        //     row: maxRow,
        //     col: maxCol,
        //     maxFloor,
        //     getFloor: 1000,
        //     position: groupedByFloor,
        // };

        return is_loading_data ? (
            <div className='loading' />
        ) : (
            <Card
                id='table-chairs'
                className={classnames({
                    disable: !isRorF('orders_table'),
                })}
            >
                <CardBody id='remove-padding'>
                    <CardTitle className='position-relative mb-3'>
                        {/* <IntlMessages id='menu.table-setting' /> */}
                        <RenderFloorTable
                            maxFloor={items.maxFloor}
                            selectedFloorTable={selectedFloorTable}
                            onUpdateSelectedFloorTable={
                                onUpdateSelectedFloorTable
                            }
                            floorsActiveId={floorsActiveId}
                        />
                    </CardTitle>
                    {selectedFloorTable === 100 ? (
                        <Colxx
                            xxs='12'
                            className='table__container__wrapper pl-0 pr-0'
                        >
                            <TabContent activeTab={selectedFloorTable}>
                                <TabPane tabId={100}>
                                    {menus.length > 1 ? (
                                        <div className='d-flex'>
                                            <CategoryMenu
                                                activeIndex={activeIndex}
                                                category={this.renderCategories()}
                                                onClickCategory={(name, num) =>
                                                    this.onClickCategory(name, num)
                                                }
                                            />
                                            <div
                                                ref={this.searchContainerRef}
                                                className='search-container'
                                            >
                                                {!isInputVisible && (
                                                    <div
                                                        onClick={
                                                            this.toggleInput
                                                        }
                                                        className='cursor-pointer'
                                                    >
                                                        <i className='iconsminds-magnifi-glass search-icon' />
                                                    </div>
                                                )}
                                                {isInputVisible && (
                                                    <Input
                                                        autoFocus
                                                        className='input-search'
                                                        placeholder='Tìm kiếm...'
                                                        value={dataScan !== '' ? dataScan : search}
                                                        onChange={
                                                            this.onSearchChange
                                                        }
                                                        onKeyPress={e => this.onSearchKey(e)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    ) : null}
                                    <ListMenu
                                        menus={search !== '' ? filteredData : menus}
                                        ordersParent={orders}
                                        listsData={listsData}
                                        loading={loading}
                                        onClickImage={(product) =>
                                            onClickImage(product)
                                        }
                                        onToggleDetail={onToggleDetail}
                                    />
                                </TabPane>
                            </TabContent>
                        </Colxx>
                    ) : // ) : selectedFloorTable === 1000 ? (
                    //     Object.keys(groupedByFloor).map((floor) => (
                    //         <Colxx
                    //             key={floor}
                    //             xxs='12'
                    //             className='table__container__wrapper'
                    //         >
                    //             <CardComponent
                    //                 floor={floor}
                    //                 items={groupedByFloor[floor]}
                    //                 isLoading={isLoading}
                    //                 orders={orders.filter(
                    //                     (order) =>
                    //                         order.status_id !==
                    //                             STATUS.DESTROY &&
                    //                         order.status_id !== STATUS.CLOSED &&
                    //                         order.status_id !== STATUS.PAYMENTED
                    //                 )}
                    //                 onActions={onActions}
                    //                 infoTable={infoTable}
                    //             />
                    //         </Colxx>
                    //     ))
                    items.row && items.col ? (
                        <Colxx xxs='12' className='table__container__wrapper'>
                            <TableChairGenerateMap
                                isLoading={isLoading}
                                data={items}
                                orders={orders.filter(
                                    (order) =>
                                        order.status_id !== STATUS.DESTROY &&
                                        order.status_id !== STATUS.CLOSED &&
                                        order.status_id !== STATUS.PAYMENTED
                                )}
                                onActions={onActions}
                                infoTable={infoTable}
                                billDate={billDate}
                                maxFloor={items.maxFloor}
                                selectedFloorTable={selectedFloorTable}
                                onUpdateSelectedFloorTable={
                                    onUpdateSelectedFloorTable
                                }
                                floorsActiveId={floorsActiveId}
                            />
                        </Colxx>
                    ) : (
                        <p className='d-flex justify-content-center align-items-center h-75'>
                            Đang tải ...
                        </p>
                    )}
                </CardBody>
            </Card>
        );
    }
}

// const CardComponent = ({
//     floor,
//     items,
//     isLoading,
//     orders,
//     onActions,
//     infoTable,
// }) => {
//     // console.log('items 2: ', items);
//     const data = {
//         col: 6,
//         row: 5,
//         getFloor: 1000,
//         maxFloor: 2,
//         position: items,
//     };
//     return (
//         <div>
//             <h2>Khu {floor}</h2>
//             {data.row && data.col ? (
//                 <TableChairGenerateMap
//                     isLoading={isLoading}
//                     data={data}
//                     orders={orders.filter(
//                         (order) =>
//                             order.status_id !== STATUS.DESTROY &&
//                             order.status_id !== STATUS.CLOSED &&
//                             order.status_id !== STATUS.PAYMENTED
//                     )}
//                     onActions={onActions}
//                     infoTable={infoTable}
//                 />
//             ) : (
//                 <p className='d-flex justify-content-center align-items-center h-75'>
//                     Đang tải ...
//                 </p>
//             )}
//         </div>
//     );
// };

const RenderFloorTable = ({
    onUpdateSelectedFloorTable,
    selectedFloorTable,
    maxFloor,
    floorsActiveId,
}) => {
    return !maxFloor ? (
        <Nav tabs className='w-100'>
            <NavItem>
                <NavLink
                    href='#'
                    className={classnames('', {
                        active: selectedFloorTable === 100,
                        'floor-has-order': floorsActiveId.includes(100),
                    })}
                >
                    Menu
                </NavLink>
            </NavItem>
        </Nav>
    ) : (
        <div className='w-100 floor'>
            <Nav tabs className='w-100'>
                {maxFloor >= 1 ? (
                    <div className='d-flex justify-content-between w-100'>
                        <div className='d-flex'>
                            {/* <NavItem
                                onClick={(e) => {
                                    e.preventDefault();
                                    onUpdateSelectedFloorTable(1000);
                                }}
                            >
                                <NavLink
                                    href='#'
                                    className={classnames('', {
                                        active: selectedFloorTable === 1000,
                                        'floor-has-order':
                                            floorsActiveId.includes(1000),
                                    })}
                                >
                                    Tất cả
                                </NavLink>
                            </NavItem> */}
                            {[...new Array(maxFloor).keys()].map((floor) => (
                                <NavItem
                                    key={floor}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onUpdateSelectedFloorTable(floor + 1);
                                    }}
                                >
                                    <NavLink
                                        href='#'
                                        className={classnames('', {
                                            active:
                                                selectedFloorTable ===
                                                floor + 1,
                                            'floor-has-order':
                                                floorsActiveId.includes(
                                                    floor + 1
                                                ),
                                        })}
                                    >{`Khu ${floor + 1}`}</NavLink>
                                </NavItem>
                            ))}
                        </div>
                        <NavItem
                            onClick={(e) => {
                                e.preventDefault();
                                onUpdateSelectedFloorTable(100);
                            }}
                        >
                            <NavLink
                                href='#'
                                className={classnames('', {
                                    active: selectedFloorTable === 100,
                                    'floor-has-order':
                                        floorsActiveId.includes(100),
                                })}
                            >
                                Menu
                            </NavLink>
                        </NavItem>{' '}
                    </div>
                ) : null}
            </Nav>
        </div>
    );
};

export default TableSetting;
