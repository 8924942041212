import React from 'react';
import { Button, Row, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import classnames from 'classnames';
import { AddCommaNumber } from '../../../helpers/Utils';
import IntlMessages from '../../../helpers/IntlMessages';

const ContentComponent = ({
    product,
    onSelected,
    order,
    orderDisplay,
    onToggleDetail,
    sub,
    plus,
    change,
}) => {
    // console.log('Product: ', product);
    // console.log('onSelected: ', onSelected);
    // console.log('order: ', order);
    // console.log('orderDisplay: ', orderDisplay);
    // console.log('onToggleDetail: ', onToggleDetail);
    // console.log('sub: ', sub);
    // console.log('plus: ', plus);
    // console.log('change: ', change);
    const clsName =
        order && order.count
            ? 'input-order order-number-active'
            : 'input-order';

    return (
        <div className='product-btn w-100'>
            {product.size && product.size.length ? (
                <div className='mb-3'>
                    {product.sizeTitle ? (
                        <span>{product.sizeTitle}</span>
                    ) : (
                        <IntlMessages id='product.size' />
                    )}
                    <SizeAndToppingComponent
                        data={product.size}
                        onSelected={onSelected}
                        selectedId={product.sizeSelectedId || []}
                        name={'sizeSelectedId'}
                        product={product}
                    />
                </div>
            ) : null}
            {product.topping && product.topping.length ? (
                <div className='mb-3'>
                    {product.toppingTitle ? (
                        <span>{product.toppingTitle}</span>
                    ) : (
                        <IntlMessages id='product.topping' />
                    )}
                    <SizeAndToppingComponent
                        data={product.topping}
                        name={'toppingSelectedId'}
                        product={product}
                        selectedId={product.toppingSelectedId || []}
                        onSelected={onSelected}
                    />
                </div>
            ) : null}
            {product.ice && product.ice.length ? (
                <div className='mb-3'>
                    {product.iceTitle ? (
                        <span>{product.iceTitle}</span>
                    ) : (
                        <IntlMessages id='product.ice' />
                    )}
                    <IceAndSugarComponent
                        data={product.ice}
                        name={'iceSelectedValue'}
                        product={product}
                        selectedValue={product.iceSelectedValue}
                        onSelected={onSelected}
                    />
                </div>
            ) : null}
            {product.sugar && product.sugar.length ? (
                <div className='mb-3'>
                    {product.sugarTitle ? (
                        <span>{product.sugarTitle}</span>
                    ) : (
                        <IntlMessages id='product.sugar' />
                    )}
                    <IceAndSugarComponent
                        data={product.sugar}
                        name={'sugarSelectedValue'}
                        product={product}
                        selectedValue={product.sugarSelectedValue}
                        onSelected={onSelected}
                    />
                </div>
            ) : null}
            <Row className='mb-4 mt-3'>
                <Colxx className='col-12 p-0'>
                    <InputGroup size='sm'>
                        <Input
                            onChange={(e) =>
                                onToggleDetail({
                                    product,
                                    name: 'note',
                                    value: e.target.value,
                                })
                            }
                            className='custom-input-underline'
                            placeholder='Ghi chú'
                        />
                    </InputGroup>
                </Colxx>
            </Row>

            {!orderDisplay ? (
                <div className='mb-3 btn-center'>
                    <Button
                        onClick={() =>
                            onToggleDetail({ product, name: 'orderDisplay' })
                        }
                        size='sm'
                        outline
                        color='primary'
                        className='m-center'
                    >
                        Chọn món này
                    </Button>
                </div>
            ) : (
                <Colxx className='col-12 mb-3'>
                    <div className='order-number-center btn-center'>
                        <div>
                            <InputGroup size='md'>
                                <InputGroupAddon addonType='prepend'>
                                    <Button
                                        outline
                                        color='primary'
                                        onClick={() => {
                                            sub(product.id);
                                        }}
                                        // disabled={!isVerify}
                                        className='border-btn-left'
                                    >
                                        {'-'}
                                    </Button>
                                </InputGroupAddon>
                                <Input
                                    placeholder={order ? order.count : 0}
                                    className={clsName}
                                    value={order ? order.count : 0}
                                    onChange={(e) => {
                                        change(e, product.id);
                                    }}
                                    // disabled={!isVerify}
                                />
                                <InputGroupAddon addonType='append'>
                                    <Button
                                        outline
                                        color='primary'
                                        onClick={() => {
                                            plus(product.id);
                                        }}
                                        // disabled={!isVerify}
                                        className='border-btn-right'
                                    >
                                        {'+'}
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </div>
                    </div>
                </Colxx>
            )}
        </div>
    );
};

const SizeAndToppingComponent = ({
    data,
    name,
    product,
    selectedId,
    onSelected,
}) => {
    return (
        <Row>
            {data.map((_item, idx) => (
                <Colxx
                    key={Math.random()}
                    xxs='12'
                    md='12'
                    xl='12'
                    className='mt-1 pad-2px-10px'
                >
                    <Button
                        size='sm'
                        outline
                        block
                        onClick={() =>
                            onSelected({ product, name, value: _item.id })
                        }
                        className={classnames({
                            'btn-selected': selectedId.includes(_item.id),
                            'btn-disabled': !selectedId.includes(_item.id),
                        })}
                    >
                        <span className='left'>{_item.name}</span>
                        <span className='right'>
                            + {AddCommaNumber(_item.value)} đ
                        </span>
                    </Button>
                </Colxx>
            ))}
        </Row>
    );
};

const IceAndSugarComponent = ({
    data,
    product,
    name,
    selectedValue,
    onSelected,
}) => {
    return (
        <Row className='pad-2px-8px'>
            {data.map((_item, idx) => (
                <Colxx key={idx} _id={idx} className='mt-1 pad-2px w-20'>
                    <Button
                        color='secondary'
                        size='sm'
                        outline
                        block
                        onClick={() =>
                            onSelected({ product, name, value: _item.value })
                        }
                        className={classnames({
                            'btn-selected': selectedValue === _item.value,
                            'btn-disabled': selectedValue !== _item.value,
                        })}
                    >
                        {`${_item.name}%`}
                    </Button>
                </Colxx>
            ))}
        </Row>
    );
};

export default ContentComponent;
