import React from 'react'
import moment from 'moment';
import {
  Row, Col,
  Label
} from 'reactstrap'
import DatePicker from "react-datepicker";
import IntlMessages from "../../../helpers/IntlMessages";

const ContentComponent = (props) => {
  return !props.values || !props.values.length ? (
    <AddMoreButton
      onAdd={() => props.onAdd()}
      disabled={props.disabled}
    />
  ) : (
      <>
        {
          props.values.map((timer, idx) =>
            <TimerComponent
              key={idx}
              timer={timer}
              onChange={props.onChange}
              onRemove={props.onRemove}
              disabled={props.disabled}
            />
          )
        }
        <AddMoreButton
          onAdd={() => props.onAdd()}
          disabled={props.disabled}
        />
      </>
    )
}

const TimerComponent = (props) => {
  return (
    <Row className="mb-3">
      <Col sm={4} xs={4} md={4} lg={4}>
        <Label for="working_hours">
          <IntlMessages id="user-staff.working_hours_from" />
        </Label>
        <DatePicker
          selected={moment(`2020/02/02 ${props.timer.start}`)}
          onChange={date => props.onChange(date, 'working_hours_from', props.timer.hash)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={5}
          timeCaption="Làm từ"
          dateFormat="HH:mm"
          timeFormat="HH:mm"
          disabled={props.disabled}
        />
      </Col>
      <Col sm={4} xs={4} md={4} lg={4}>
        <Label for="working_hours">
          <IntlMessages id="user-staff.working_hours_to" />
        </Label>
        <DatePicker
          selected={moment(`2020/02/02 ${props.timer.end}`)}
          onChange={date => props.onChange(date, 'working_hours_to', props.timer.hash)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={5}
          timeCaption="Làm đến"
          dateFormat="HH:mm"
          timeFormat="HH:mm"
          disabled={props.disabled}
        />
      </Col>
      <Col sm={3} xs={3} md={3} lg={3}>
        {props.disabled ? (
          <span className="working_hours_btn disable-text-selection">-</span>
        ) : (
            <span className="working_hours_btn disable-text-selection"
              onClick={() => props.onRemove(props.timer.hash)}
            >-</span>
          )}
      </Col>
    </Row>
  )
}

const AddMoreButton = (props) => {
  return (
    <Row>
      <Col sm={12} xs={12} md={12} lg={12}>
        <span className="working_hours_btn working_hours_btn-zero-top mb-3 disable-text-selection"
          onClick={props.disabled ? null : props.onAdd}
        >+</span>
      </Col>
    </Row>
  )
}

export default ContentComponent