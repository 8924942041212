import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import axios from 'axios';
import { END_POINT, END_POINT_SOCKET } from '../../../constants/defaultValues';
import {
    SetItemsSelected,
    NotificatioErrController,
    NotificatioSucController,
} from '../../../helpers/Utils';
import io from 'socket.io-client';
import PageHeading from '../../../containers/pages/promotions/discount/PageHeading';
import LogsHelper from '../../../helpers/Logs';
// import IntlMessages from '../../../helpers/IntlMessages';
// import PerfectScrollbar from 'react-perfect-scrollbar';
// import classnames from 'classnames';
import { isC, isR, isU, isD } from '../../../helpers/Roles';
import AddDiscount from '../../../containers/pages/promotions/discount/AddDiscount';
import IntlMessages from '../../../helpers/IntlMessages';
import { Pagination } from '../../../containers/pages/general';
import ListItemLeftForm from '../../../containers/pages/promotions/discount/ListItemLeftForm';
import UpdateDiscount from '../../../containers/pages/promotions/discount/UpdateDiscount';

const DEFAULT_FORM = {
    id: '',
    name: '',
    quantity: '',
    code: '',
    description: '',
    momentFormatSelectDateTimeStart: new Date(),
    date_start: '',
    time_start: '',
    momentFormatSelectDateTimeEnd: new Date(),
    date_end: '',
    time_end: '',
    dynamic_form: [],
};

const ROLE_ALIAS = 'promotion';

class ContentComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: {
                pageSizes: [12, 24],
                selectedPageSize: 12,
                currentPage: 1,
                totalPage: 1,
                totalItem: 0,
                search: '',
            },
            selectedItems: [],
            data: [],
            data_dynamic_form: [],
            is_loading_data: true,
            show: {
                add: false,
                update: false,
                viewonly: true,
            },
            displayMode: 'thumblist',

            discountRange: [
                {
                    price_start: '',
                    price_end: '',
                    discount_value: '',
                    discount_type: 1,
                },
            ],
            oneProduct: [
                {
                    product: [
                        {
                            label: '',
                            value: '',
                            quantity: 1,
                        },
                    ],
                    discount_value: '',
                    discount_type: 1,
                },
            ],
            oneTypeProduct: [
                {
                    category: [
                        {
                            label: '',
                            value: '',
                            quantity: 1,
                        },
                    ],
                    quantity: 1,
                    discount_value: '',
                    discount_type: 1,
                },
            ],
            quantityProduct: [
                {
                    quantity_product: 0,
                    discount_value: '',
                    discount_type: 1,
                },
            ],
            quantityTypeProduct: [
                {
                    quantity_type_product: 0,
                    discount_value: '',
                    discount_type: 1,
                },
            ],
            customerPoint: [
                {
                    customer_point: 0,
                    discount_value: '',
                    discount_type: 1,
                },
            ],
            productGetProduct: [
                {
                    pgp_buy: [
                        {
                            label: '',
                            value: '',
                            quantity: 1,
                        },
                    ],
                    pgp_gift: [
                        {
                            label: '',
                            value: '',
                            quantity: 1,
                        },
                    ],
                },
            ],
            orderGetProduct: [
                {
                    price_start: '',
                    price_end: '',
                    ogp_gift: [
                        {
                            label: '',
                            value: '',
                            quantity: 1,
                        },
                    ],
                },
            ],
            customerPointGetProduct: [
                {
                    customer_point: '',
                    cpgp_gift: [
                        {
                            label: '',
                            value: '',
                            quantity: 1,
                        },
                    ],
                },
            ],

            form: DEFAULT_FORM,
        };

        this.socket = io(END_POINT_SOCKET);
    }

    componentDidMount() {
        this.socket.emit('join room', {
            room_id: localStorage.getItem('user_id'),
        });

        this.dataListRender();
    }

    /** Axios func helper */
    dataListRender = () => {
        const { selectedPageSize, currentPage, search } = this.state.page;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .get(
                `${
                    END_POINT + '/promotion/json'
                }?pageSize=${selectedPageSize}&currentPage=${currentPage}&search=${search}`,
                { headers: { Authorization: `Bearer ${tokenStr}` } }
            )
            .then((res) => res.data)
            .then((data) => {
                this.setState(
                    (prev) => ({
                        ...prev,
                        data: data.data,
                        data_dynamic_form: data.dataDynamicForm,
                        is_loading_data: false,
                        page: {
                            ...prev.page,
                            totalPage: data.totalPage,
                            totalItem: data.totalItem,
                        },
                    }),
                    () => {
                        if (this.state.form.id) {
                            const {
                                id,
                                name,
                                code,
                                quantity,
                                description,
                                date_start,
                                time_start,
                                date_end,
                                time_end,
                                dynamic_form,
                            } = this.state.form;

                            let DateTimeStart = date_start + ' ' + time_start;
                            let DateTimeEnd = date_end + ' ' + time_end;
                            this.setState((prev) => ({
                                ...prev,
                                form: {
                                    ...prev.form,
                                    id,
                                    name,
                                    code,
                                    quantity,
                                    description,
                                    momentFormatSelectDateTimeStart:
                                        DateTimeStart,
                                    momentFormatSelectDateTimeEnd: DateTimeEnd,
                                    // date_start,
                                    // date_end,
                                    dynamic_form,
                                },
                            }));
                        } else {
                            this.setState((prev) => ({
                                ...prev,
                                selectedItems: [],
                                show: {
                                    ...prev.show,
                                    update: false,
                                },
                                form: DEFAULT_FORM,
                            }));
                        }
                    }
                );
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerDelete = () => {
        if (!isD(ROLE_ALIAS)) return false;

        let tokenStr = localStorage.getItem('access_token');
        axios
            .delete(`${END_POINT + '/promotion'}`, {
                data: {
                    arrayId: this.state.selectedItems,
                },
                headers: { Authorization: `Bearer ${tokenStr}` },
            })
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['promotion-delete'],
                });

                this.dataListRender();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerUpdate = () => {
        if (!isU(ROLE_ALIAS)) return false;

        const {
            id,
            name,
            code,
            quantity,
            description,
            momentFormatSelectDateTimeStart,
            momentFormatSelectDateTimeEnd,
            // date_start,
            // time_start,
            // date_end,
            // time_end,
            dynamic_form,
        } = this.state.form;

        if (
            !name ||
            !id ||
            !quantity ||
            // !date_start ||
            // !date_end ||
            !momentFormatSelectDateTimeStart ||
            !momentFormatSelectDateTimeEnd
        ) {
            NotificatioErrController('Dữ liệu không hợp lệ');
            return;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .put(
                `${END_POINT + '/category'}`,
                {
                    id,
                    name,
                    code,
                    quantity,
                    description,
                    momentFormatSelectDateTimeStart,
                    momentFormatSelectDateTimeEnd,
                    // date_start,
                    // date_end,
                    dynamic_form,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['promotion-update'],
                });

                this.dataListRender();
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };

    onHandlerAdd = (e) => {
        if (!isC(ROLE_ALIAS)) return false;
        e.preventDefault();

        const {
            name,
            code,
            quantity,
            description,
            momentFormatSelectDateTimeStart,
            momentFormatSelectDateTimeEnd,
            // date_start,
            // time_start,
            // date_end,
            // time_end,
            dynamic_form,
        } = this.state.form;

        if (!name) {
            NotificatioErrController(
                'Tên của chương trình khuyến mại cần được thêm vào'
            );
            return;
        }
        if (!quantity) {
            NotificatioErrController(
                'Số lượng mã khuyến mại cần được thêm vào'
            );
            return;
        }
        // if (!date_start) {
        //     NotificatioErrController(
        //         'Ngày bắt đầu chương trình khuyến mại cần được thêm vào'
        //     );
        //     return;
        // }
        // if (!date_end) {
        //     NotificatioErrController(
        //         'Ngày kết thúc chương trình khuyến mại cần được thêm vào'
        //     );
        //     return;
        // }
        if (!momentFormatSelectDateTimeStart) {
            NotificatioErrController('Thời gian bắt đầu cần được thêm vào');
            return;
        }
        if (!momentFormatSelectDateTimeEnd) {
            NotificatioErrController('Thời gian kết thúc cần được thêm vào');
            return;
        }

        let tokenStr = localStorage.getItem('access_token');
        axios
            .post(
                `${END_POINT + '/promotion'}`,
                {
                    name,
                    code,
                    quantity,
                    description,
                    momentFormatSelectDateTimeStart,
                    momentFormatSelectDateTimeEnd,
                    // date_start,
                    // time_start,
                    // date_end,
                    // time_end,
                    dynamic_form,
                },
                {
                    headers: { Authorization: `Bearer ${tokenStr}` },
                }
            )
            .then((res) => res.data)
            .then((data) => {
                NotificatioSucController('Thành công');

                this.socket.emit('create logs', {
                    user_id: localStorage.getItem('user_id'),
                    message: LogsHelper['promotion-create'],
                });

                this.dataListRender();
                // this.toggle('add');
                this.setState((prev) => ({
                    ...prev,
                    form: {
                        ...prev.form,
                        form: DEFAULT_FORM,
                    },
                }));
            })
            .catch((err) => {
                NotificatioErrController(err.response.data.responseText);
                return false;
            });
    };
    /** Axios func helper */

    /** State func helper */
    toggle = (name) => {
        if (name === 'add' && !isC(ROLE_ALIAS)) return false;
        if (name === 'update' && !isU(ROLE_ALIAS)) return false;

        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                update: name === 'add' ? false : prev.show.update,
                [name]: !prev.show[name],
            },
            selectedItems: name === 'add' ? [] : prev.selectedItems,
            form: name === 'add' ? DEFAULT_FORM : prev.form,
        }));
    };

    onChangeForm = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [name]: value,
                },
            }));
        } else {
            let value = e.value ? e.value : e;
            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    [_name]: value,
                },
            }));
        }
    };
    onChangeDynamicForm = (e, _name) => {
        if (e.target) {
            let {
                target: { name, value },
            } = e;

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    dynamic_form: {
                        ...prev.form.dynamic_form,
                        [name]: value,
                    },
                },
            }));
        } else {
            let value = e.value ? e.value : e;

            this.setState((prev) => ({
                ...prev,
                form: {
                    ...prev.form,
                    dynamic_form: {
                        ...prev.form.dynamic_form,
                        [_name]: value,
                    },
                },
            }));
        }
    };

    // onSubmitForm = (type) => {
    //     if (type === 'update') {
    //         this.onHandlerUpdate();
    //     } else if (type === 'add') {
    //         this.onHandlerAdd();
    //     }
    // };

    onChangePage = (page) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    currentPage: page,
                },
            }),
            () => this.dataListRender()
        );
    };

    onSearchKey = (e) => {
        if (!e) return false;

        if (e.target) {
            let {
                target: { value },
            } = e;
            let v = value;
            if (value === undefined) {
                v = e.target.firstElementChild.value; // parent to child

                value = v.toLowerCase();
                this.setState(
                    (prev) => ({
                        ...prev,
                        page: {
                            ...prev.page,
                            search: value,
                        },
                    }),
                    () => this.dataListRender()
                );
            } else {
                value = value.toLowerCase();
                this.setState(
                    (prev) => ({
                        ...prev,
                        page: {
                            ...prev.page,
                            search: value,
                        },
                    }),
                    () => this.dataListRender()
                );
            }
        }

        if (e.key === 'Enter') {
            let value = e.target.value.toLowerCase();
            this.setState(
                (prev) => ({
                    ...prev,
                    page: {
                        ...prev.page,
                        search: value,
                    },
                }),
                () => this.dataListRender()
            );
        }
    };

    onChangePageSize = (size) => {
        this.setState(
            (prev) => ({
                ...prev,
                page: {
                    ...prev.page,
                    selectedPageSize: size,
                    currentPage: 1,
                },
            }),
            () => this.dataListRender()
        );
    };

    onClickedItem = (item, callback, args) => {
        if (this.state.form.id !== item.id) {
            const {
                id,
                name,
                code,
                quantity,
                description,
                date_start,
                time_start,
                date_end,
                time_end,
                dynamic_form,
            } = item;

            let DateTimeStart = date_start + ' ' + time_start;
            let DateTimeEnd = date_end + ' ' + time_end;
            this.setState(
                (prev) => ({
                    ...prev,
                    show: {
                        ...prev.show,
                        update: false,
                        viewonly: false,
                    },
                    form: {
                        ...prev.form,
                        id,
                        name,
                        code,
                        quantity,
                        description,
                        momentFormatSelectDateTimeStart: DateTimeStart,
                        momentFormatSelectDateTimeEnd: DateTimeEnd,
                        // date_start,
                        // time_start,
                        // date_end,
                        // time_end,
                        dynamic_form,
                    },
                    selectedItems: [item.id],
                }),
                () => {
                    callback(args[0], args[1], args[2]);
                }
            );
        }
    };

    onClickedUpdate = (item) => {
        const {
            id,
            name,
            code,
            quantity,
            description,
            date_start,
            time_start,
            date_end,
            time_end,
            dynamic_form,
        } = item;
        let DateTimeStart = date_start + ' ' + time_start;
        let DateTimeEnd = date_end + ' ' + time_end;
        this.setState((prev) => ({
            ...prev,
            show: {
                ...prev.show,
                viewonly: false,
                update: true,
            },
            form: {
                ...prev.form,
                id,
                name,
                code,
                quantity,
                description,
                momentFormatSelectDateTimeStart: DateTimeStart,
                momentFormatSelectDateTimeEnd: DateTimeEnd,
                dynamic_form,
            },
        }));
    };

    onClickedDelete = (item) => {
        let selectedSet = SetItemsSelected(
            item.id,
            this.state.selectedItems,
            'require'
        );
        if (window.confirm('Bạn chắc chắn muốn xoá danh mục này?')) {
            this.setState(
                (prev) => ({
                    ...prev,
                    selectedItems: selectedSet,
                }),
                () => this.onHandlerDelete()
            );
        }
    };

    onClickedCheckbox = (id, callback) => {
        // let selectedSet = SetItemsSelected(id, this.state.selectedItems);

        this.setState((prev) => ({
            ...prev,
            selectedItems: [id],
        }));
    };
    /** State func helper */

    changeDisplayMode = (mode) => {
        this.setState((prev) => ({
            ...prev,
            displayMode: mode,
            show: {
                ...prev.show,
                view: false,
            },
        }));
        return false;
    };

    onChangeItem = (categories, idx, field, e, index) => {
        const updatedCategories = [...this.state[categories]];

        if (field === 'discount_type') {
            const value = updatedCategories[idx].discount_type === 1 ? 2 : 1;
            updatedCategories[idx] = {
                ...updatedCategories[idx],
                [field]: value,
            };
        } else if (
            field === 'quantity_product' ||
            field === 'quantity_pgp_buy' ||
            field === 'quantity_pgp_gift' ||
            field === 'quantity_ogp_gift' ||
            field === 'quantity_cpgp_gift' ||
            field === 'quantity_category'
        ) {
            const value = [
                ...updatedCategories[idx][
                    field === 'quantity_product'
                        ? 'product'
                        : field === 'quantity_category'
                        ? 'category'
                        : field === 'quantity_pgp_buy'
                        ? 'pgp_buy'
                        : field === 'quantity_pgp_gift'
                        ? 'pgp_gift'
                        : field === 'quantity_ogp_gift'
                        ? 'ogp_gift'
                        : field === 'quantity_cpgp_gift'
                        ? 'cpgp_gift'
                        : null
                ],
            ];
            value[index] = {
                ...value[index],
                quantity: e.target.value,
            };
            updatedCategories[idx] = {
                ...updatedCategories[idx],
                [field === 'quantity_product'
                    ? 'product'
                    : field === 'quantity_category'
                    ? 'category'
                    : field === 'quantity_pgp_buy'
                    ? 'pgp_buy'
                    : field === 'quantity_pgp_gift'
                    ? 'pgp_gift'
                    : field === 'quantity_ogp_gift'
                    ? 'ogp_gift'
                    : field === 'quantity_cpgp_gift'
                    ? 'cpgp_gift'
                    : null]: value,
            };
        } else if (
            field === 'product' ||
            field === 'category' ||
            field === 'pgp_buy' ||
            field === 'pgp_gift' ||
            field === 'ogp_gift' ||
            field === 'cpgp_gift'
        ) {
            const value = [...updatedCategories[idx][field]];
            value[index] = {
                ...value[index],
                label: e.label,
                value: e.value,
            };
            updatedCategories[idx] = {
                ...updatedCategories[idx],
                [field]: value,
            };
        } else {
            const value = e.target.value;
            updatedCategories[idx] = {
                ...updatedCategories[idx],
                [field]: value,
            };
        }

        this.setState({ [categories]: updatedCategories });
        // const updatedCategory = [...this.state[category]];
        // let value;

        // if (field === 'discount_type') {
        //     value = updatedCategory[idx].discount_type === 1 ? 2 : 1;
        // } else if (
        //     field === 'quantity_product' ||
        //     field === 'quantity_category'
        // ) {
        //     let value = [...updatedCategory[idx].product];
        //     value = value.map((item, itemIdx) => {
        //         return itemIdx === index
        //             ? {
        //                   ...item,
        //                   quantity: e.target.value,
        //               }
        //             : item;
        //     });

        //     updatedCategory[idx] = {
        //         ...updatedCategory[idx],
        //         product: value,
        //     };

        //     return this.setState({ [category]: updatedCategory });
        // } else if (
        //     // field === 'category' ||
        //     field === 'product_buy' ||
        //     field === 'product_gift'
        // ) {
        //     value = { label: e.value, value: e.value };
        // } else if (field === 'product' || field === 'category') {
        //     let value = [...updatedCategory[idx].product];
        //     value = value.map((item, itemIdx) => {
        //         return itemIdx === index
        //             ? {
        //                   ...item,
        //                   label: e.label,
        //                   value: e.value,
        //               }
        //             : item;
        //     });

        //     updatedCategory[idx] = {
        //         ...updatedCategory[idx],
        //         product: value,
        //     };

        //     return this.setState({ [category]: updatedCategory });
        // } else {
        //     value = e.target.value;
        // }

        // updatedCategory[idx] = {
        //     ...updatedCategory[idx],
        //     [field]: value,
        // };

        // this.setState({ [category]: updatedCategory });
    };

    onAddItem = (category) => {
        const newItem = this.getNewItem(category);
        this.setState((prevState) => ({
            [category]: [...prevState[category], newItem],
        }));
    };

    getNewItem = (category) => {
        switch (category) {
            case 'discountRange':
                return {
                    price_start: '',
                    price_end: '',
                    discount_value: '',
                    discount_type: 1,
                };
            case 'oneProduct':
                return {
                    product: [{ label: '', value: '', quantity: 1 }],
                    discount_value: '',
                    discount_type: 1,
                };
            case 'oneTypeProduct':
                return {
                    category: [{ label: '', value: '', quantity: 1 }],
                    discount_value: '',
                    discount_type: 1,
                };
            case 'quantityProduct':
                return {
                    quantity_product: 0,
                    discount_value: '',
                    discount_type: 1,
                };
            case 'quantityTypeProduct':
                return {
                    quantity_type_product: 0,
                    discount_value: '',
                    discount_type: 1,
                };
            case 'customerPoint':
                return {
                    customer_point: 0,
                    discount_value: '',
                    discount_type: 1,
                };
            case 'productGetProduct':
                return {
                    pgp_buy: [{ label: '', value: '', quantity: 1 }],
                    pgp_gift: [{ label: '', value: '', quantity: 1 }],
                };
            case 'orderGetProduct':
                return {
                    price_start: '',
                    price_end: '',
                    ogp_gift: [{ label: '', value: '', quantity: 1 }],
                };
            case 'customerPointGetProduct':
                return {
                    customer_point: 0,
                    cpgp_gift: [{ label: '', value: '', quantity: 1 }],
                };
            default:
                return {};
        }
    };

    onAddProduct = (idx, category, field) => {
        const updatedCategory = [...this.state[category]];
        const newProduct = this.getNewProduct();

        let value = [...updatedCategory[idx][field], newProduct];

        updatedCategory[idx] = {
            ...updatedCategory[idx],
            [field]: value,
        };

        this.setState({ [category]: updatedCategory });
    };

    getNewProduct = () => {
        return { label: '', value: '', quantity: 1 };
    };

    onRemoveDiscount = (idx, category) => {
        this.setState((prevState) => {
            const updatedCategory = [...prevState[category]];
            updatedCategory.splice(idx, 1);
            return { [category]: updatedCategory };
        });
    };

    onRemoveProduct = (index, idx_product, category, field) => {
        this.setState((prevState) => {
            const updatedCategory = [...prevState[category]];
            const updatedProduct = [...updatedCategory[index][field]].filter(
                (p, indexx) => indexx !== idx_product
            );
            updatedCategory[index] = {
                ...updatedCategory[index],
                [field]: updatedProduct,
            };
            return { [category]: updatedCategory };
        });
    };

    render() {
        const {
            page,
            selectedItems,
            data,
            discountRange,
            oneProduct,
            oneTypeProduct,
            quantityProduct,
            quantityTypeProduct,
            customerPoint,
            productGetProduct,
            orderGetProduct,
            customerPointGetProduct,
            is_loading_data,
            show,
            form,
            displayMode,
            data_dynamic_form,
        } = this.state;

        console.log('oneProduct: ', oneProduct);

        const lengthData = data.length;

        if (!isR(ROLE_ALIAS))
            return (
                <Card className='text-muted mh-40vh h-100'>
                    <CardBody className='d-flex justify-content-center align-items-center'>
                        Bạn không có quyền sử dụng tính năng này!
                    </CardBody>
                </Card>
            );

        return is_loading_data ? (
            <div className='loading' />
        ) : (
            <div className='disable-text-selection'>
                <PageHeading
                    heading='promotion.thumb-list'
                    setting={page}
                    // match={match}
                    totalItem={page.totalItem}
                    isDisabledAdd={!isC(ROLE_ALIAS)}
                    toggleFunc={() => {
                        this.toggle('add');
                    }}
                    changePageSizeFunc={this.onChangePageSize}
                    onSearchKeyFunc={this.onSearchKey}
                    displayMode={displayMode}
                    changeDisplayMode={this.changeDisplayMode}
                />
                <AddDiscount
                    title={<IntlMessages id='promotion-add' />}
                    isOpen={show.add}
                    toggle={() => {
                        this.toggle('add');
                    }}
                    onSubmit={this.onHandlerAdd}
                    data_dynamic_form={data_dynamic_form}
                    values={form}
                    discountRange={discountRange}
                    oneProduct={oneProduct}
                    oneTypeProduct={oneTypeProduct}
                    quantityProduct={quantityProduct}
                    quantityTypeProduct={quantityTypeProduct}
                    customerPoint={customerPoint}
                    productGetProduct={productGetProduct}
                    orderGetProduct={orderGetProduct}
                    customerPointGetProduct={customerPointGetProduct}
                    lengthData={lengthData}
                    setting={show}
                    onAddItem={this.onAddItem}
                    onAddProduct={this.onAddProduct}
                    onRemoveDiscount={this.onRemoveDiscount}
                    onRemoveProduct={this.onRemoveProduct}
                    onChange={this.onChangeForm}
                    onChangeItem={this.onChangeItem}
                    onChangeDynamicForm={this.onChangeDynamicForm}
                />
                <UpdateDiscount
                    title={<IntlMessages id='category.thumb-list' />}
                    isOpen={show.update}
                    toggle={() => {
                        this.toggle('update');
                    }}
                    setting={show}
                    values={form}
                    data_dynamic_form={data_dynamic_form}
                    onChange={this.onChangeForm}
                    onChangeDynamicForm={this.onChangeDynamicForm}
                    onSubmit={this.onHandlerUpdate}
                />
                {data.length === 0 ? (
                    <Card className='mt-4 mb-4 mh-53vh'>
                        <CardBody>Chưa có dữ liệu...</CardBody>
                    </Card>
                ) : (
                    <>
                        <Row className='content-page__row d-none d-md-flex'>
                            <Col
                                xs='12'
                                sm='12'
                                md='12'
                                lg={
                                    show.view && displayMode === 'thumblist'
                                        ? 6
                                        : 12
                                }
                                xl={
                                    show.view && displayMode === 'thumblist'
                                        ? 6
                                        : 12
                                }
                            >
                                {data?.length ? (
                                    displayMode === 'thumblist' ? (
                                        <>
                                            {data?.map((item, idx) => (
                                                <ListItemLeftForm
                                                    onClickedItem={
                                                        this.onClickedItem
                                                    }
                                                    onUpdate={
                                                        this.onClickedUpdate
                                                    }
                                                    onDelete={
                                                        this.onClickedDelete
                                                    }
                                                    onChangeCheckbox={
                                                        this.onClickedCheckbox
                                                    }
                                                    // showAlertApprove={
                                                    //     this
                                                    //         .showAlertApprove
                                                    // }
                                                    // showAlertReject={
                                                    //     this
                                                    //         .showAlertReject
                                                    // }
                                                    isDisabledUpdate={
                                                        !isU(ROLE_ALIAS)
                                                    }
                                                    isDisabledDelete={
                                                        !isD(ROLE_ALIAS)
                                                    }
                                                    selectedItems={
                                                        selectedItems
                                                    }
                                                    setting={show}
                                                    key={idx}
                                                    idx={idx}
                                                    item={item}
                                                />
                                            ))}
                                            {/* {data?.length <
                                        12 ? null : ( */}
                                            <Pagination
                                                currentPage={page.currentPage}
                                                totalPage={page.totalPage}
                                                onChangePage={(i) =>
                                                    this.onChangePage(i)
                                                }
                                            />
                                            {/* )} */}
                                        </>
                                    ) : (
                                        <div></div>
                                        // <ContentMultiForm
                                        //     data={data_all}
                                        // />
                                    )
                                ) : (
                                    <Card className='d-flex justify-content-center align-items-center'>
                                        <CardBody>
                                            Không có dữ liệu khách hàng nào phù
                                            hợp với lựa chọn của bạn.
                                        </CardBody>
                                    </Card>
                                )}
                            </Col>
                            {show.view ? (
                                <Col xs='12' sm='12' md='12' lg='6' xl='6'>
                                    {/* <ContentDisplay
                                        // data_dynamic_form={data_dynamic_form}
                                        values={form}
                                    /> */}
                                </Col>
                            ) : null}
                        </Row>
                        {/* <Row className='content-page__row d-block d-md-none mb-4'>
                        <Col>
                            {data?.length ? (
                                data?.map((item, idx) => {
                                    // console.log(item);
                                    return (
                                        <div
                                            className='d-flex mb-3 card'
                                            key={item.id}
                                        >
                                            <ListItemFormMobile
                                                onClickedItem={
                                                    this
                                                        .onClickedItem
                                                }
                                                onUpdate={
                                                    this
                                                        .onClickedUpdate
                                                }
                                                onDelete={
                                                    this
                                                        .onClickedDelete
                                                }
                                                onChangeCheckbox={
                                                    this
                                                        .onClickedCheckbox
                                                }
                                                isDisabledUpdate={
                                                    !isU(ROLE_ALIAS)
                                                }
                                                isDisabledDelete={
                                                    !isD(ROLE_ALIAS)
                                                }
                                                selectedItems={
                                                    selectedItems
                                                }
                                                key={idx}
                                                idx={idx}
                                                item={item}
                                                form={form}
                                                accordion={
                                                    accordion
                                                }
                                                toggleAccordion={
                                                    this
                                                        .toggleAccordion
                                                }
                                            />
                                        </div>
                                    );
                                })
                            ) : (
                                <Card className='d-flex justify-content-center align-items-center'>
                                    <CardBody>
                                        Không có dữ liệu khách hàng
                                        nào phù hợp với lựa chọn của
                                        bạn.
                                    </CardBody>
                                </Card>
                            )}
                            <Pagination
                                currentPage={page.currentPage}
                                totalPage={page.totalPage}
                                onChangePage={(i) =>
                                    this.onChangePage(i)
                                }
                            />
                        </Col>
                    </Row> */}
                    </>
                )}
                {/* <Row className='content-page__row'>
                    <Col xs='12' sm='12' md='12' lg='12' xl='12'>
                        {data.map((item, idx) => (
                            <ListItemLeftForm
                                onClickedItem={this.onClickedItem}
                                onUpdate={this.onClickedUpdate}
                                onDelete={this.onClickedDelete}
                                onChangeCheckbox={this.onClickedCheckbox}
                                isDisabledUpdate={!isU(ROLE_ALIAS)}
                                isDisabledDelete={!isD(ROLE_ALIAS)}
                                selectedItems={selectedItems}
                                // active={2}
                                setting={show}
                                key={idx}
                                idx={idx}
                                item={item}
                            />
                        ))}
                        <Pagination
                            currentPage={page.currentPage}
                            totalPage={page.totalPage}
                            onChangePage={(i) => this.onChangePage(i)}
                        />
                    </Col>
                </Row> */}
            </div>
        );
    }
}

export default ContentComponent;
